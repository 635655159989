import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { checkIfEmpty } from "../../Utils/constants";
import moment from "moment";
import { CustomInputClg, CustomInputCross } from "./calenderStyle";

function CommonCalender({
  placeholderText,
  onChange,
  onCross,
  formData,
  setFormData,
  value,
  icon,
}) {
  return (
    <DatePicker
      placeholderText={placeholderText}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      selected={checkIfEmpty(value, moment(value).toDate())}
      onChange={onChange}
      dateFormat="dd MMM yyyy"
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      customInput={
        value ? (
          <CustomInputCross
            properties={onCross}
            formData={formData}
            setFormData={setFormData}
          />
        ) : (
          <CustomInputClg icon={icon} />
        )
      }
    />
  );
}

export default CommonCalender;
