import React, { useState, useEffect } from "react";
import ModalView from "../../commonComponent/ModalView";
import CheckBox from "../component/CheckBox";
import style from "./Filter.module.css";
import Iconionicmdclosecircle from "../../../../assets/Iconionicmdclosecircle.png";
import constants, { employeeId } from "../../../../Utils/constants";
import axios from "axios";
import cookie from "react-cookies";

const Filter = (props) => {
  const [functionalArea, setFunctionalArea] = useState([]);
  const [tabActive, setTabActive] = useState(window.location.pathname);
  const [userLists, setUserLists] = useState(props?.userLists);
const[grouAssignedBy,setGroupAssignBy]=useState([])
const[grouAssignedTo,setGroupAssignTo]=useState([])
  const url2 = constants.apiBaseUrl2;
  if (props.filterData.assignedBy) {
  } else {
  }
  const [newPriority, setNewPriority] = useState(props.filterData.priority);
  const [newStatus, setNewStatus] = useState(props.filterData.status);
  userLists?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );
  const [priority, setPriority] = useState([
    { id: "High", name: "High", isChecked: false },
    { id: "Medium", name: "Medium", isChecked: false },
    { id: "Low", name: "Low", isChecked: false },
  ]);

  const [status, setStatus] = useState([
    { id: "Assigned", name: "Assigned", isChecked: false },
    { id: "Done", name: "Done", isChecked: false },
    { id: "Onhold", name: "On Hold", isChecked: false },
    // { id: "Closed", name: "Closed", isChecked: false },
  ]);
  
  const [status1, setStatus1] = useState([
    { id: "Assigned", name: "Assigned", isChecked: false },
    { id: "Done", name: "Done", isChecked: false },
    { id: "Onhold", name: "On Hold", isChecked: false },
    { id: "Closed", name: "Closed", isChecked: false },
  ]);

  const getFunctionalArea = () => {
    axios
      .get(url2 + "/getAllFunctionalArea", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setFunctionalArea(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getaskAssignedByFilterData = () => {
    if(props?.groupId)
    {axios
      .get(url2 +`/assignedByByGroupId?groupId=${props.groupId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setGroupAssignBy(res.data.responseData);
      })
      .catch(() => {});}
      else return null;
  };

  const getaskAssignedToFilterData = () => {
    if(props?.groupId)
    {axios
      .get(url2 +`/assignedToByGroupId?groupId=${props.groupId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setGroupAssignTo(res.data.responseData);
      })
      .catch(() => {});}
      else return null;
  };
  const data2 = { isChecked: false };
  let list = props.data;
  for (let i = 0; i < list?.length; i++) {
    list[i] = Object.assign(list[i], data2);
  }
  const [AssignBy, setAssignBy] = useState(list);

  const list2 = props.assignTo;
  for (let i = 0; i < list?.length; i++) {
    list[i] = Object.assign(list[i], data2);
  }

  const [AssignTo, setAssignTo] = useState(list2);

  functionalArea?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  useEffect(() => {
    getFunctionalArea();
    getaskAssignedByFilterData()
    getaskAssignedToFilterData()
  }, []);

  return (<>
   {tabActive !== "/GroupWiseList" ?(
    <ModalView>
      <div className={style.container}>
        <div className={style.subContainer}>
          <div className={style.mobileRes}>
            <span
              style={{
                marginLeft: "35%",
                color: "#65A15E",
                fontSize: "18px",
                fontWeight: "500",
                textDecorationLine: "underline",
              }}
            >
              Filters
            </span>
            <img
              style={{ width: "27px" }}
              alt="Iconionicmdclosecircle"
              onClick={props.closeFilter}
              src={Iconionicmdclosecircle}
            />
          </div>
          <div
            style={{
              border: "1px solid #00000033",
              borderRadius: "9px 0px 0px 0px",
            }}
          >
            <header style={{ borderRadius: "9px 0px 0px 0px" }}>
              {tabActive == "/taskTracker/TaskAssignedByMeComponent" && (
                <header>Assigned To</header>
              )}
              {tabActive == "/taskTracker" && <header>Assigned By</header>}
              {tabActive == "/taskTracker/ClosedByMeComponent" && (
                <header>Assigned By</header>
              )}
            </header>
            <ul className={style.addScroll}>
              {AssignBy
                ? AssignBy.map((userList, index1) => {
                    return (props.filterData.assignedBy &&
                      props.filterData.assignedTo) ||
                      props.filterData.assignedBy ? (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={props.filterData.assignedBy}
                        change={props.setassigned}
                        key={index1}
                        type="assigned"
                      />
                    ) : (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={props.filterData.assignedTo}
                        change={props.setassigned}
                        key={index1}
                        type="assigned"
                      />
                    );
                  })
                : null}
            </ul>
           
          </div>

          <div style={{ border: "1px solid #00000033" }}>
            <header>Priority</header>
            <ul>
              {priority.map((userList, index2) => {
                return (
                  <CheckBox
                    filterdata1={props.filterData}
                    userList={userList}
                    value={newPriority}
                    change={props.setpriority}
                    key={index2}
                    type="priority"
                  />
                );
              })}
            </ul>
          </div>

          {tabActive !== "/taskTracker/ClosedByMeComponent" ? (
            <div style={{ border: "1px solid #00000033" }}>
              <header>Functional Area</header>
              <ul className={style.addScroll}>
                {functionalArea.map((userList, index2) => {
                  return (
                    <CheckBox
                      filterdata1={props.filterData}
                      userList={userList}
                      value={props.filterData.functionalAreaId}
                      change={props.setpriority}
                      key={index2}
                      type="functionalArea"
                    />
                  );
                })}
              </ul>
            </div>
          ) : null}

          {props.filterData.assignedBy && props.filterData.assignedTo ? (
            <div
              style={{
                border: "1px solid #00000033",
                borderRadius: "0px 9px 0px 0px",
              }}
            >
              <header style={{ borderRadius: "0px 9px 0px 0px" }}>
                Assigned To
              </header>
              <ul className={style.addScroll}>
                {AssignTo
                  ? AssignTo.map((userList, index5) => {
                      return (
                        <CheckBox
                          filterdata1={props.filterData}
                          userList={userList}
                          value={props.filterData.assignedTo}
                          change={props.setAssignedTo}
                          key={index5}
                          type="assignedTo"
                        />
                      );
                    })
                  : null}
              </ul>
            </div>
          ) : (
            <div
              style={{
                border: "1px solid #00000033",
                borderRadius: "0px 9px 0px 0px",
              }}
            >
              <header style={{ borderRadius: "0px 9px 0px 0px" }}>
                Status
              </header>
              <ul>
                {status.map((userList, index3) => {
                  return (
                    <CheckBox
                      filterdata1={props.filterData}
                      userList={userList}
                      value={newStatus}
                      change={props.setstatus}
                      key={index3}
                      type="status"
                    />
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className={style.buttonSection}>
          <button onClick={props.closeFilter} className={style.clearAll}>
            Clear All
          </button>
          <button
            onClick={props.handleApplied.bind(this)}
            className={style.apply}
          >
            Apply
          </button>
        </div>
      </div>
    </ModalView>):(
     <ModalView>
     <div className={style.container}>
       <div className={style.subContainer}>
         <div className={style.mobileRes}>
           <span
             style={{
               marginLeft: "35%",
               color: "#65A15E",
               fontSize: "18px",
               fontWeight: "500",
               textDecorationLine: "underline",
             }}
           >
             Filters
           </span>
           <img
             style={{ width: "27px" }}
             alt="Iconionicmdclosecircle"
             onClick={props.closeFilter}
             src={Iconionicmdclosecircle}
           />
         </div>
         
         <div style={{ border: "1px solid #00000033" }}>
             <header>Assigned To</header>
             <ul className={style.addScroll}>
               {grouAssignedTo.map((userList, index6) => {
                 return (
                   <CheckBox
                     filterdata1={props.filterData}
                     userList={userList}
                     value={props.filterData.assignedTo}
                     change={props.setpriority}
                     key={index6}
                     type="grouAssignedTo"
                   />
                 );
               })}
             </ul>
           </div>
           <div style={{ border: "1px solid #00000033" }}>
             <header>Assigned By</header>
             <ul className={style.addScroll}>
               {grouAssignedBy.map((userList, index7) => {
                 return (
                   <CheckBox
                     filterdata1={props.filterData}
                     userList={userList}
                     value={props.filterData.assignedBy}
                     change={props.setpriority}
                     key={index7}
                     type="groupAssignBy"
                   />
                 );
               })}
             </ul>
           </div>


         <div style={{ border: "1px solid #00000033" }}>
           <header>Priority</header>
           <ul>
             {priority.map((userList, index2) => {
               return (
                 <CheckBox
                   filterdata1={props.filterData}
                   userList={userList}
                   value={newPriority}
                   change={props.setpriority}
                   key={index2}
                   type="priority"
                 />
               );
             })}
           </ul>
         </div>
         <div
              style={{
                border: "1px solid #00000033",
                borderRadius: "0px 9px 0px 0px",
              }}
            >
              <header style={{ borderRadius: "0px 9px 0px 0px" }}>
                Status
              </header>
              <ul>
                {status1.map((userList, index3) => {
                  return (
                    <CheckBox
                      filterdata1={props.filterData}
                      userList={userList}
                      value={newStatus}
                      change={props.setstatus}
                      key={index3}
                      type="status"
                    />
                  );
                })}
              </ul>
            </div>
       
       </div>
       <div className={style.buttonSection}>
         <button onClick={props.closeFilter} className={style.clearAll}>
           Clear All
         </button>
         <button
           onClick={props.handleApplied.bind(this)}
           className={style.apply}
         >
           Apply
         </button>
       </div>
     </div>
   </ModalView>)}
  </>
   
  );
};

export default Filter;
