import React from "react";
import * as Img from "../../assets/index";

export const CustomInputCross = React.forwardRef((props, ref) => {
  return (
    <>
      <label
        ref={ref}
        style={{
          cursor: "pointer",
        }}
      >
        {props.value || props.placeholder}
      </label>
      <img
        src={Img.cross}
        width="8px"
        height="8px"
        alt="Cross"
        style={{
          position: "absolute",
          right: "3%",
          top: "30%",
          cursor: "pointer",
        }}
        onClick={() => {
          props.setFormData({ ...props.formData, [props.properties]: "" });
        }}
      />
    </>
  );
});

export const CustomInputClg = React.forwardRef((props, ref) => {
  return (
    <div onClick={props.onClick}>
      <label
        ref={ref}
        style={{
          cursor: "pointer",
        }}
      >
        {props.value || props.placeholder}
      </label>
      <img
        src={
          props.icon === "purple"
            ? Img.purple_calender
            : props.icon === "cc"
            ? Img.cc_calender
            : props.icon === "pm"
            ? Img.pm_calender
            : Img.purple_calender
        }
        alt="calender"
        style={
          props.icon === "cc" || "pm"
            ? {
                position: "absolute",
                right: "5%",
                top: "10%",
                cursor: "pointer",
                width: "20px",
              }
            : {
                position: "absolute",
                right: "5%",
                top: "10%",
                cursor: "pointer",
              }
        }
      />
    </div>
  );
});
