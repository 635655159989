import { useState } from "react";
import style from "./Star.module.css";

const StarRating = ({ feedbackRating, setFeedbackRating, item }) => {
  const [stars, setStars] = useState([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    "NS",
    "NE",
  ]);

  const changeRating = (newRating, key) => {
    let array = feedbackRating.map((e) => {
      if (e.value == item?.value) {
        return {
          label: item?.label,
          value: item?.value,
          rating:
            newRating === "NS" ? "NS" : newRating === "NE" ? "NE" : newRating,
        };
      } else {
        return e;
      }
    });

    setFeedbackRating((preData) => ({ ...preData, feedbackSkills: array }));
  };

  return (
    <>
      <div
        style={{
          width: "32rem",
          display: "flex",
          justifyContent: "space-between",
          padding: "0.3rem 0px",
        }}
      >
        {stars.map((star) => {
          return (
            <span
              style={{
                cursor: "pointer",
                color: "#a15e5e",
                width: "50rem",
              }}
              onClick={() => {
                changeRating(star, item?.label);
              }}
            >
              <div
                className={
                  item?.rating === star ? style.circleClick : style.circleDesign
                }
              >
                {star === "NS" ? "NS" : star === "NE" ? "NE" : star}
              </div>
            </span>
          );
        })}
      </div>
      <p className={style.skillRating}>
        {item?.rating > 7
          ? "Excellent"
          : item?.rating < 4
          ? "Poor"
          : item?.rating < 6
          ? "Average"
          : item?.rating < 8
          ? "Good"
          : item?.rating == "NE"
          ? "NE"
          : item?.rating == "NS"
          ? "NS"
          : null}
      </p>
    </>
  );
};

export default StarRating;
