import React, { useState, useEffect } from "react";
import SideBar from "../../Clients/component/SideBar";
import Header from "../../../taskTracker/commonComponent/Header";
import style from "./TaskHistory.module.css";
import Moment from "moment";
import axios from "axios";
import cookie from "react-cookies";
import Pagination from "../../CommonComponent/Pagination";
import IconOfBackButton from "../../../../assets/ionic-md-arrow-back.png";
import milestone from "../../../../assets/milestone.png";
import loaderImg from "../../../../assets/loader.gif";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import constants, { employeeId } from "../../../../Utils/constants";

const arr = [
  // {
  //   a: "20 Jul (Fri) | 11:00",
  //   b: "Vrinda Sharma",
  //   c: "status",
  //   d: "On Hold > Done",
  // }
];

const RMSTaskHistory = (props) => {
  const taskId = props.history.location.state.task.taskId;
  const [histories, setHistories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [newData, setnewData] = useState(props.history.location.state.task);
  const [isLoaded, setIsLoaded] = useState(false);

  let limit = 10;

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  // const handleBack = (data2) => {
  //   if (returnPage==1) {
  //     history.push("/viewTask",data2)
  //   } else {
  //     history.push({
  //       pathname: '/EditTask',
  //       state: { task: newData ,
  //         activePage: 3
  //       }
  //     });
  //   }
  // }
  const handleBack = () => {
    history.push({
      pathname: "/taskAssignedByMeDetails",
      state: {
        task: newData,
        activePage: props.history.location.state.activePage,
      },
    });
  };

  const history = useHistory();
  useEffect(() => {
    handleapidata();
  }, [currentPage]);

  const url2 = constants.apiBaseUrl2;

  function handleapidata() {
    setIsLoaded(true);
    const historyData = axios
      .get(
        url2 +
          "/getTaskHistory?limit=" +
          limit +
          "&page=" +
          currentPage +
          "&taskId=" +
          taskId,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        }
      )
      .then((res) => {
        setTimeout(() => {
          setIsLoaded(false);
        }, 250);
        const responseData = res.data.responseData;
        setHistories(responseData.taskHistoryList);
        setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
      })
      .catch((err) => {
        return err;
      });
  }

  const goBack = () => {
    history.goBack(-1);
  };
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <div className={style.rightSection}>
          <div className={style.taskhistory}>
            <div
              onClick={() => handleBack()}
              className={style.backbutton}
            ></div>
          </div>

          <div className={style.container}>
            <span className={style.sppan}> Task History</span>
            <span
              className={style.back_btn}
              style={{ cursor: "pointer" }}
              onClick={goBack}
            >
              <ArrowBackIcon />
              Back
            </span>

            <div>
              {/* {isLoaded ? <div><img src={loaderImg} alt="loader" className={style.loader}
              />
                <p className={style.loaderPara}>Loading...</p>
              </div> : ""} */}

              {isLoaded ? (
                <div>
                  <img
                    src={loaderImg}
                    alt="loader"
                    style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                    className={style.loader}
                  />
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      paddingLeft: "38%",
                      position: "absolute",
                      color: "#FBBB08",
                      marginTop: "-4px",
                      fontStyle: "italic",
                      marginLeft: "34.5%",
                    }}
                  >
                    Loading...
                  </p>
                </div>
              ) : (
                ""
              )}
              {histories.length > 0 && !isLoaded ? (
                <div>
                  <table>
                    <thead style={{ position: "sticky", top: "0px" }}>
                      <tr>
                        {/* <div style={{width:'10px'}}></div> */}
                        <th style={{ width: "10px" }}></th>
                        <th
                          className={style.taskid}
                          style={{ position: "sticky", top: "0px" }}
                        >
                          Date Modified
                        </th>
                        <th
                          className={style.taskTitle}
                          style={{ position: "sticky", top: "0px" }}
                        >
                          Username
                        </th>
                        <th
                          className={style.taskdesc}
                          style={{ position: "sticky", top: "0px" }}
                        >
                          Fields
                        </th>
                        <th
                          className={style.taskAssigned}
                          style={{ position: "sticky", top: "0px" }}
                        >
                          Change
                        </th>
                        <div style={{ width: "14px" }}></div>
                      </tr>
                    </thead>
                  </table>

                  <div className={style.scrollAdd}>
                    <table>
                      <tbody className={style.addScroll}>
                        {histories &&
                          histories.map((historys, index) => {
                            return (
                              <tr className={style.tabledata} key={index}>
                                {/* <td style={{width:'10px'}}></td> */}
                                <td className={style.tabletaskname}>
                                  {Moment(historys.modifiedDate).format(
                                    "DD MMM (ddd)"
                                  )}
                                </td>
                                <td className={style.tabletaskname}>
                                  <a className={style.ahref}>{historys.name}</a>
                                </td>
                                <td className={style.tabletaskfield}>
                                  {historys.fields}
                                </td>
                                <td className={style.tabletaskdesc}>
                                  {historys.description}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className={style.noTasks}>
                  {!isLoaded ? (
                    <div style={{ display: "block", marginTop: "10%" }}>
                      <img src={milestone} />
                      <h2>No Task History Yet</h2>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}

              <div className={style.mobileContainer}>
                {arr.map((ar, index) => {
                  return (
                    <div
                      style={{
                        display: "block",
                        borderBottom: "1px solid #707070",
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "700",
                            paddingTop: "3px",
                            opacity: ".85",
                          }}
                        >
                          Date Modified :
                        </span>
                        {ar.a}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "700",
                            paddingTop: "3px",
                            opacity: ".85",
                          }}
                        >
                          Username :
                        </span>
                        {ar.b}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "700",
                            paddingTop: "3px",
                            opacity: ".85",
                          }}
                        >
                          Fields :
                        </span>
                        {ar.c}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "700",
                            paddingTop: "3px",
                            opacity: ".85",
                          }}
                        >
                          Change :
                        </span>
                        {ar.d}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {histories.length > 0 && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "1rem", marginRight: "1.5rem" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RMSTaskHistory;
