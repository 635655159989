import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import style from "./ClosedTaskDetail.module.css";
import cookie from "react-cookies";
import axios from "axios";
import constants from "../../../../../Utils/constants";
import Moment from "moment";
const ClosedTaskDetailsById = (props) => {
  const [allClosedTask, setAllClosedTask] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const url2 = constants.apiBaseUrl2;
  const MyClosedTaskDetails = (id) => {
    axios
      .get(
        url2 +
          `/getClosedTasksByEmpIdForRms?empId=${id}&limit=100&page=${currentPage}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setAllClosedTask(res.data.responseData);
      })
      .catch(() => {});
  };
  useEffect(() => {
    MyClosedTaskDetails(props?.id);
  }, []);
  return (
    <>
      <div className={style.description_box1} style={{ marginBottom: "15px" }}>
        <div className={style.span2}>
          <p>Closed Task</p>
        </div>
        <div className={style.maindivContent}></div>
        <div
          className={style.original_table}
          style={{
            backgroundColor: "white",
            minHeight: "3rem",
            marginTop: "7px",
          }}
        >
          {allClosedTask?.getAllData?.length > 0 ? (
            <Table borderless size="sm" style={{ fontSize: "12px" }}>
              <thead
                style={{
                  backgroundColor: "#F0F0F0",
                  position: "sticky",
                  top: "0px",
                }}
              >
                <tr style={{ padding: "100px" }}>
                  <th style={{ padding: "25px" }}>Task Id</th>
                  <th style={{ padding: "25px" }}>Creation Date</th>
                  <th style={{ padding: "25px" }}>Task Title</th>
                  <th style={{ padding: "25px" }}>Assigned To</th>
                  <th style={{ padding: "25px" }}>Assigned By</th>
                  <th style={{ padding: "25px" }}>Status</th>
                  <th style={{ padding: "25px" }}>Priority</th>
                  <th style={{ padding: "25px" }}>Planned End Date</th>
                  <th style={{ padding: "25px" }}>Functional Area</th>
                </tr>
              </thead>
              <tbody>
                {allClosedTask?.getAllData?.map((val) => {
                  return (
                    <tr style={{ textAlign: "start" }}>
                      <td>{val?.taskId}</td>
                      <td>{val?.created}</td>
                      <td>{val?.title}</td>
                      <td>{val?.assignToName}</td>
                      <td>{val?.name}</td>
                      <td>{val?.status}</td>
                      <td>{val?.priority}</td>
                      <td>{Moment(val?.planedEndDate).format("DD MMM YY")}</td>
                      <td>{val?.functionalArea}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p className={style.p1} style={{ textAlign: "center" }}>
              No Task Closed
            </p>
          )}
        </div>
      </div>
    </>
  );
};
export default ClosedTaskDetailsById;
