import ModalView from '../../commonComponent/ModalView';
import style from './Filter.module.css';
import Iconionicmdclosecircle from "../../../../assets/Iconionicmdclosecircle.png";
import { useQuery } from 'react-query';
import { assignedBy, assignedTo, getAllSpaceElements } from '../../taskTrackerAPIs/taskTrackerAPI';
import { useState } from 'react';

const SpaceFilter = (props) => {
    const [filter, setFilter] = useState(props.filterData);
    const priority = [
        { id: 3, name: "Urgent", isChecked: false },
        { id: 2, name: "High", isChecked: false },
        { id: 4, name: "Medium", isChecked: false },
        { id: 1, name: "Low", isChecked: false },
    ];
    const status = [
        { id: "Assigned", name: "Assigned", isChecked: false },
        { id: "Done", name: "Done", isChecked: false },
        { id: "Onhold", name: "On Hold", isChecked: false },
        { id: "Closed", name: "Closed", isChecked: false },
    ];
    const {data:spaceElements} = useQuery(
        ["getAllSpaceElements"],
        getAllSpaceElements,
        {
          refetchOnWindowFocus: false,
        }
    );

    const { data: assignedByList } = useQuery(
        ["assignedBy"],
        ()=>assignedBy(props.isActive, props.groupId),
        {
            refetchOnWindowFocus: false
        }
    );
    const { data: assignedToList } = useQuery(
        ["assignedTo"],
        ()=>assignedTo(props.isActive, props.groupId),
        {
            refetchOnWindowFocus: false
        }
    );

    const handleCheckboxChange = (e, type, id)=>{
        let temp;
        switch(type) {
            case 'to':
                temp = filter?.assignedTo;
                if(e.target.checked) temp.push(id);
                else temp=temp.filter(val=>val!==id);
                setFilter(prev=>({...prev, assignedTo: temp}));
                break;
            case 'by':
                temp = filter?.assignedBy;
                if(e.target.checked) temp.push(id);
                else temp=temp.filter(val=>val!==id);
                setFilter(prev=>({...prev, assignedBy: temp}));
                break;
            case 'prior':
                temp = filter?.priority;
                if(e.target.checked) temp.push(id);
                else temp=temp.filter(val=>val!==id);
                setFilter(prev=>({...prev, priority: temp}));
                break;
            case 'status':
                temp = filter?.status;
                if(e.target.checked) temp.push(id);
                else temp=temp.filter(val=>val!==id);
                setFilter(prev=>({...prev, status: temp}));
                break;
            case 'space':
                temp = filter?.spaceElementId;
                if(e.target.checked) temp.push(id);
                else temp=temp.filter(val=>val!==id);
                setFilter(prev=>({...prev, spaceElementId: temp}));
                break;
        }
    }

  return (
    <ModalView>
        <div className={style.container}>
            <div className={style.subContainer}>
                <div className={style.mobileRes}>
                    <span
                        style={{
                            marginLeft: "35%",
                            color: "#65A15E",
                            fontSize: "18px",
                            fontWeight: "500",
                            textDecorationLine: "underline",
                        }}
                        >
                        Filters
                    </span>
                    <img
                        style={{ width: "27px" }}
                        alt="Iconionicmdclosecircle"
                        onClick={props.closeFilter}
                        src={Iconionicmdclosecircle}
                    />
                </div>
                <div
                    style={{
                    border: "1px solid #00000033",
                    borderRadius: "9px 0px 0px 0px",
                    }}
                >
                    <header style={{ borderRadius: "9px 0px 0px 0px" }}>
                        Assigned To
                    </header>
                    <ul className={style.addScroll}>
                        {assignedToList?.map((ele, index)=>{
                            return <li key={`assignedTo${index}`}>
                                <input
                                    type='checkbox'
                                    style={{
                                        visibility: "visible",
                                        accentColor:"#65A15E",
                                        transform: "translateY(2px)",
                                        margin: "0px 4px 2px 0px"
                                    }}
                                    onChange={(e)=>handleCheckboxChange(e, 'to', ele.id)}
                                    checked={filter?.assignedTo.includes(ele.id)}
                                />
                                <label>{ele?.name}</label>
                            </li>
                        })}
                    </ul>
                </div>
                <div
                    style={{
                    border: "1px solid #00000033",
                    borderRadius: "0px 0px 0px 0px",
                    }}
                >
                    <header style={{ borderRadius: "0px 0px 0px 0px" }}>
                        Assigned By
                    </header>
                    <ul className={style.addScroll}>
                        {assignedByList?.map((ele, index)=>{
                            return <li key={`assignedBy${index}`}>
                                <input
                                    type='checkbox'
                                    style={{
                                        visibility: "visible",
                                        accentColor:"#65A15E",
                                        transform: "translateY(2px)",
                                        margin: "0px 4px 2px 0px"
                                    }}
                                    onChange={(e)=>handleCheckboxChange(e, 'by', ele.id)}
                                    checked={filter?.assignedBy.includes(ele.id)}
                                />
                                <label>{ele?.name}</label>
                            </li>
                        })}
                    </ul>
                </div>
                <div style={{ border: "1px solid #00000033" }}>
                    <header>Priority</header>
                    <ul>
                        {priority.map((ele, index) => {
                            return <li key={`priority${index}`}>
                                <input
                                    type='checkbox'
                                    style={{
                                        visibility: "visible",
                                        accentColor:"#65A15E",
                                        transform: "translateY(2px)",
                                        margin: "0px 4px 2px 0px"
                                    }}
                                    onChange={(e)=>handleCheckboxChange(e, 'prior', ele.id)}
                                    checked={filter?.priority.includes(ele.id)}
                                />
                                <label>{ele?.name}</label>
                            </li>
                        })}
                    </ul>
                </div>
                <div
                    style={{
                        border: "1px solid #00000033",
                        borderRadius: "0px 0px 0px 0px",
                    }}
                >
                    <header style={{ borderRadius: "0px 0px 0px 0px" }}>
                        Status
                    </header>
                    <ul>
                        {status.map((ele, index) => {
                        return <li key={`status${index}`}>
                            <input
                                type='checkbox'
                                style={{
                                    visibility: "visible",
                                    accentColor:"#65A15E",
                                    transform: "translateY(2px)",
                                    margin: "0px 4px 2px 0px"
                                }}
                                onChange={(e)=>handleCheckboxChange(e, 'status', ele.id)}
                                checked={filter?.status.includes(ele.id)}
                            />
                            <label>{ele?.name}</label>
                        </li>
                        })}
                    </ul>
                </div>
                <div
                    style={{
                        border: "1px solid #00000033",
                        borderRadius: "0px 9px 0px 0px",
                    }}
                >
                    <header style={{ borderRadius: "0px 9px 0px 0px" }}>
                        Type
                    </header>
                    <ul className={style.addScroll}>
                        {spaceElements?.map((ele,index)=>{
                        return <li key={`status${index}`}>
                            <input
                                type='checkbox'
                                style={{
                                    visibility: "visible",
                                    accentColor:"#65A15E",
                                    transform: "translateY(2px)",
                                    margin: "0px 4px 2px 0px"
                                }}
                                onChange={(e)=>handleCheckboxChange(e, 'space', ele.id)}
                                checked={filter?.spaceElementId.includes(ele.id)}
                            />
                            <label>{ele?.name}</label>
                        </li>
                        })}
                    </ul>
                </div>
            </div>
            <div className={style.buttonSection}>
                <button onClick={props.closeFilter} className={style.clearAll}>
                    Clear All
                </button>
                <button
                    onClick={()=>{props.setFilterData(filter); props.setShowFilter(false)}}
                    className={style.apply}
                >
                    Apply
                </button>
            </div>
        </div>
    </ModalView>
  )
}

export default SpaceFilter