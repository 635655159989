import React, { useState } from "react";
import style from "./CustomAutocomplet.module.css";
const CustomAutocomplete = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [showOptions, setShowOptions] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setSelectedOption(null);
    setShowOptions(true);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    setInputValue(option?.label);
    setShowOptions("");

    props?.handleSelectOption(option); // Call the handleSelectOption prop with the selected option
  };
  // add new clear data handler
  const handleClear = () => {
    setInputValue("");
    setSelectedOption(null);
    setShowOptions("");
    props?.handleClear();
  };

  const filteredOptions = inputValue
    ? props?.options?.filter(
        (option) =>
          option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
          option?.label === selectedOption?.label
      )
    : [];

  // const result =
  //   filteredOptions.length > 0 ? filteredOptions : "Data not found";

  return (
    <div>
      <div>
        <input
          className={style.input}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          // placeholder="Select an option"
        />
        {inputValue?.length > 0 && (
          <span className={style.crossBtn} onClick={handleClear}>
            ×
          </span>
        )}
      </div>
      {showOptions && filteredOptions?.length > 0 && (
        <ul className={style.listContaine}>
          {filteredOptions?.map((option) => (
            <span>
              <li
                className={style.lists}
                key={option?.value}
                onClick={() => handleSelectOption(option)}
              >
                {option?.label}
              </li>
            </span>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomAutocomplete;
