import React, { useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import style from "./InductionNavigation.module.css";
import ProgressBar from "../progressbar/ProgressBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmployeeList from "../pageLayout/empTimeSheet/EmployeeList";
import EmployeeInductionDetails from "../pageLayout/empTimeSheet/EmployeeInductionDetails";
import EmployeeEdit from "../pageLayout/empTimeSheet/EmployeeEdit";
import EmployeeDashboard from "../pageLayout/empTimeSheet/EmployeeDashboard";
export default function InductionNavigation() {
  const [nextScreen, setNextScreen] = useState("employeeDashboard");
  const [currentPage, setCurrentPage] = useState(1);
  const [roles] = useState([]);
  const [InductionData, setInductionData] = useState("");
  const [prevData, setPrevData] = useState({
    page: "employeeDashboard",
    sidebar: "employeeDashboard",
    page1: "employeeDashboard",
    active: "employeeDashboard",
  });

  const sidebarHandler = () => {
    switch (nextScreen) {
      case "employeeDashboard":
        return (
          <EmployeeDashboard
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "employeeInduction":
        return (
          <EmployeeList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "EmployeeInductionView":
        return (
          <EmployeeInductionDetails
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            setInductionData={setInductionData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "EditEmployee":
        return (
          <EmployeeEdit
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            InductionData={InductionData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      default:
    }
  };

  const setCurrScreen = (val) => {
    setNextScreen(val);
  };

  return (
    <div className={style.ATSNav}>
      <Header />
      <div className={style.mainSection}>
        <div style={{ width: "20%" }}>
          <SideBar
            sidebarHandler={setCurrScreen}
            nextScreen={nextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            roles={roles}
          />
        </div>
        <div className={style.ATScontainer}>
          <ProgressBar prevData={prevData} />
          {sidebarHandler()}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
