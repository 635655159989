import { FormControlLabel } from "@mui/material";
import React from "react";
import { FormGroup } from "react-bootstrap";
import styled from "@emotion/styled";
import { Switch } from "@mui/material";

export const DropSwitchStyles = styled(Switch)(({ checked }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: checked === "light" ? "#2ECA45" : "#00979d",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: checked === "light" ? "#E9E9EA" : "#39393D",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: checked === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: checked === "light" ? "#E9E9EA" : "#d2cbcb",
      opacity: 1,
    },
  }));

const DropSwitchCommPR = ({ isChecked, handleChange, label }) => {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<DropSwitchStyles checked={isChecked} onChange={handleChange} />}
          label={label}
        />
      </FormGroup>
    </>
  );
};

export default DropSwitchCommPR;
