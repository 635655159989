import React, { useEffect, useState } from "react";
import style from "./PoolReportData.module.css";
import { Table } from "react-bootstrap";
import constants from "../../../../../Utils/constants";
import axios from "axios";
import loaderImg from "../../../../../assets/loader.gif";
import cookie from "react-cookies";
import Pagination from "../../../CommonComponent/Pagination";
import milestone from "../../../../../assets/milestone.png";

const PoolReportData = (props) => {
  const baseUrl = constants.apiBaseUrlResource;
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResourceOnsite, setTotalResourceOffShore] = useState(0);
  const [poolData, setPoolData] = useState([]);
  const [clientListing, setClientListing] = useState([]);
  const [empListing, setEmpListing] = useState([]);
  const [onsiteSkills, setOnsiteSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [empId, setEmpID] = useState(0);
  const [AllocationInformation, SetAllocationInformation] = useState([]);

  const url = process.env.REACT_APP_UMS_APP_URL;

  empListing?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const getAddResourceOnsiteData = (client, project, limit, currentPage) => {
    setIsLoaded(true);
    axios
      .get(
        baseUrl +
          `/getListOfPoolDataByTechnologyFromClientOrProjectId?clientId=${client}&limit=${limit}&page=${currentPage}&projectId=${project}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setPoolData(res.data.responseData.getPoolDetailsByTech);
        setTotalResourceOffShore(res.data.responseData.count);
        setpageCount(res.data.responseData.count / 20);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };

  const getClientListing = () => {
    axios
      .get(baseUrl + "/getClientOnSite", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch(() => {});
  };

  const getProjectAllocationByID = (id) => {
    axios
      .get(baseUrl + `/getProjectAllocationByEmpId?empId=${empId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        SetAllocationInformation(res.data.responseData);
      })
      .catch(() => {});
  };

  const getUserData = () => {
    axios
      .get(url + `/updateUsersDetails`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then(() => {})
      .catch(() => {});
  };

  const getEmpListing = () => {
    axios
      .get(baseUrl + "/getPoolResourceEmployeeForFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setEmpListing(res.data.responseData.employees);
      })
      .catch((err) => {});
  };

  const getOnsiteSkills = () => {
    axios
      .get(baseUrl + "/getPoolResourceSkillsForFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOnsiteSkills(res.data.responseData);
      })
      .catch(() => {});
  };

  const limit = 20;
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  useEffect(() => {
    setCurrentPage(props?.page === 2 ? 1 : currentPage);
    getAddResourceOnsiteData(props?.client, props?.project, limit, currentPage);
    getClientListing();
    getEmpListing();
    getOnsiteSkills();
  }, [props, currentPage]);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    empId > 0 && getProjectAllocationByID(empId);
  }, []);

  return (
    <>
      <div className={style.main_client_body}>
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>
          </div>

          <div className={isLoaded ? null : style.poolList}>
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : poolData.length > 0 ? (
              <Table borderless size="sm" style={{ fontSize: "12px" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "15px" }}>Employee Name</th>
                    <th style={{ padding: "15px" }}>Experience</th>
                    <th style={{ padding: "15px" }}>Experience Range</th>
                    <th style={{ padding: "15px" }}> Technology</th>
                    <th style={{ padding: "15px" }}>Salary Range</th>
                    <th style={{ padding: "15px" }}>Project/Client Name</th>
                  </tr>
                </thead>
                <tbody>
                  {poolData?.map((val, index) => {
                    return (
                      <tr
                        key={index}
                        style={{ textAlign: "start", fontSize: "13px" }}
                      >
                        <td>{val?.empName}</td>
                        <td>
                          {~~(val.experience / 12)} Y - {val.experience % 12} M
                        </td>
                        <td>{val?.experienceRange}</td>
                        <td>{val?.skillName}</td>
                        <td>{val?.salaryRange}</td>
                        <td>
                          <div>
                            {val.projects.length >= 1 ? (
                              <>
                                {val?.projects?.map((val, index) => (
                                  <React.Fragment key={val.projectId}>
                                    {index !== 0 && ", "}{" "}
                                    {/* Add comma separator except for the first item */}
                                    {val.projectName}
                                    <span
                                      style={{
                                        color: "rgb(252, 106, 8)",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      O
                                    </span>
                                  </React.Fragment>
                                ))}
                              </>
                            ) : val.projects.length === 2 ? (
                              <>
                                {val?.projects?.map((val) => {
                                  return (
                                    <p key={val.projectId}>
                                      {val.projectName}
                                      <span
                                        style={{
                                          color: "rgb(252, 106, 8)",
                                          paddingLeft: "15px",
                                        }}
                                      >
                                        O
                                      </span>
                                    </p>
                                  );
                                })}
                              </>
                            ) : val.clientName !== null ? (
                              <p>
                                {val.clientName}{" "}
                                <span
                                  style={{ color: "blue", paddingLeft: "15px" }}
                                >
                                  C
                                </span>
                              </p>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} alt="Milestone Image" />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}
          </div>
          {totalResourceOnsite > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PoolReportData;
