import { CreateIsseuSuccessfull } from "../Pages/hrHelpDesk/grievancePortal/DoneIssue/CreateIsseuSuccessfull.js";
import { AllIssue } from "../Pages/hrHelpDesk/hrhelpDesk/allIssue/AllIssue.js";
import { Summary } from "../Pages/hrHelpDesk/hrhelpDesk/Summary/Summary.js";
import { MyIssue } from "../Pages/hrHelpDesk/grievancePortal/MyIssue/MyIssue.js";
import { CreateIssue } from "../Pages/hrHelpDesk/grievancePortal/CreateIssue/CreateIssue.js";
import { ViewIssue } from "../Pages/hrHelpDesk/grievancePortal/ViewIssue/ViewIsse.js";
import { HrHelpDeskViewIssue } from "../Pages/hrHelpDesk/hrhelpDesk/HrHelpDeskViewIssue/HrHelpDeskView.js";
import { EditIssue } from "../Pages/hrHelpDesk/grievancePortal/EditIssue/EditIssue.js";
import { HrHelpDeskDashboard } from "../Pages/hrHelpDesk/mainDashboard/DashBoad.js";

// function HrHelpDesk() {
//   return (
//     <Route>
//       <Route exct path="/SummaryDetails" component={Summary} />
//       <Route exct path="/MyIssue" component={MyIssue} />
//       <Route exct path="/CreateIssue" component={CreateIssue} />
//       <Route exct path="/EditIssue" component={EditIssue} />
//       <Route exct path="/ViewIssue" component={ViewIssue} />
//       <Route exact path="/AllIssue" component={AllIssue} />
//       <Route exact path="/HrHelpDeskAllIssue" component={HrHelpDeskDashboard} />
//       <Route
//         exact
//         path="/HrHelpDeskViewIssue"
//         component={HrHelpDeskViewIssue}
//       />
//       <Route exact path="/DoneIssue" component={CreateIsseuSuccessfull} />
//     </Route>
//   );
// }
const HrHelpDesk = [
  { path: "/SummaryDetails", component: Summary },
  { path: "/MyIssue", component: MyIssue },
  { path: "/CreateIssue", component: CreateIssue },
  { path: "/EditIssue", component: EditIssue },
  { path: "/ViewIssue", component: ViewIssue },
  { path: "/AllIssue", component: AllIssue },
  { path: "/HrHelpDeskAllIssue", component: HrHelpDeskDashboard },
  { path: "/HrHelpDeskViewIssue", component: HrHelpDeskViewIssue },
  { path: "/DoneIssue", component: CreateIsseuSuccessfull },
];


export default HrHelpDesk;
