export const validateTotalExperience = (totalExpYear,totalExpMonths, relevantExpYear,relevantExpMonths) => {
    const tempTotalExp = Number(totalExpYear ? totalExpYear*12 : 0) + Number(totalExpMonths ? totalExpMonths : 0);
    const tempRelExp = Number(relevantExpYear ? relevantExpYear*12 : 0) + Number(relevantExpMonths ? relevantExpMonths : 0);

    if(totalExpMonths > 12 || relevantExpMonths > 12) {
        return false
    }
    if(!tempRelExp && !tempTotalExp) {
        return true
    }
    if(tempTotalExp) {
        if(tempRelExp > tempTotalExp) {
            return false
        } else if(tempRelExp <= tempTotalExp) {
            return true
        }else {
            return true
        }
    } else {
        return false
    }
}