import React from 'react'

const TableRow = () => {
  return (
    <tr style={{borderBottom:"1px solid #f5f5f5", fontSize:"13px"}}>
        <td style={{padding: "7px 0px", color:"rgb(252, 187, 8)"}}><input type="checkbox" style={{marginRight:"10px", visibility:"visible", marginLeft:"12px"}}/>User Name</td>
        <td>Esse excepteur enim officia sunt amet ipsum id esse commodo.</td>
        <td style={{width:"200px", color:"green", paddingLeft:"15px"}}>25%</td>
        <td>Allocate</td>
    </tr>
  )
}

export default TableRow
