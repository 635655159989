import React, { useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../CreateVendor/CreateVendor.module.css";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { InvoiceStyles4 } from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import "react-toastify/dist/ReactToastify.css";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import ClearIcon from "@mui/icons-material/Clear";
import { ToastifyError } from "../../../../App";
import { IoIosAddCircle } from "react-icons/io";

import {
  getCityList,
  getStateList,
  getWorkModeList,
  getTdsSection,
  CreateVendorData,
  addViewCity,
} from "../../../../Utils/axios/PortalManagement";
import axios from "axios";
import { employeeId } from "../../../../Utils/constants";
import { validateEmail } from "../../../atsDashboard/utils/validation";
const CreateVendor = ({ setNextScreen, setPrevData }) => {
  const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;
  const [show, setShow] = useState(false);
  const [validate1, setValidate1] = React.useState(false);
  const [AddCity, setAddCity] = useState("");
  const [formData, setFormData] = useState({
    venderName: "",
    address: "",
    gstin: "",
    tdsSectionId: null,
    tdsApplicable: "",
    country: "",
    cityId: "",
    stateId: "",
    sla: "",
    pan: "",
    pinCode: "",
    pamentDueDays: null,
    gstApplicable: "",
    primaryContactName: "",
    primaryContectEmail: "",
    primaryMobileNumber: "",
    seconderyContactName: "",
    seconderyContectEmail: "",
    seconderyMobileNumber: "",
    empId: Number(employeeId),
  });
  const mutation = useMutation(CreateVendorData, {
    onSuccess: (res) => {
      setPrevData((prevData) => ({
        ...prevData,
        CreateId: res?.id,
      }));
      setNextScreen("ViewCreateVendor");
    },
  });
  const [validate, setValidate] = React.useState(false);
  const handleCreatePartner = () => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (
      formData?.venderName.trim() && (formData?.seconderyContectEmail ?
        validateEmail(formData?.seconderyContectEmail) : true) && (formData?.primaryContectEmail ?
        validateEmail(formData?.primaryContectEmail) : true)
      // formData?.address.trim() &&
      // formData?.country.trim() &&
      // formData?.cityId &&
      // formData?.stateId &&
      // formData?.pinCode.trim() &&
      // formData?.primaryContactName &&
      // formData?.pamentDueDays &&
      // isValidEmail(formData?.primaryContectEmail) &&
      // formData?.primaryMobileNumber.trim() &&
      // formData.primaryMobileNumber.length >= 10
    ) {
      setValidate(false);
      mutation.mutate(formData);
    } else {
      setValidate(true);
    }
  };
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };
  const mutationCity = useMutation(addViewCity, {
    onSuccess: (res) => {
      refetch();
    },
  });

  const handleAdd = () => {
    if (AddCity === "") {
      setValidate1(true);
    } else {
      mutationCity.mutate({ id: AddCity });
      setValidate1(false);
      setShow(false);
    }
  };

  const handleAddCancle = () => {
    setShow(false);
  };
  const handleShow = (expenseId) => {
    setShow(true);
  };
  // const isValidEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  const { data: GetCityTypeData, refetch } = useQuery(
    ["getCityList"],
    getCityList
  );
  GetCityTypeData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const GetCityDataOption = GetCityTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: getTdsSectionData } = useQuery(
    ["getTdsSection"],
    getTdsSection
  );

  const GetTdsDataOption = getTdsSectionData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const TDSOption = [
    {
      value: 1,
      label: "Yes",
    },
    {
      value: 2,
      label: "No",
    },
  ];
  const { data: GetStateTypeData } = useQuery(["getStateList"], getStateList);
  GetStateTypeData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  const { data: GetWorkModeData } = useQuery(
    ["getWorkModeList"],
    getWorkModeList
  );
  GetWorkModeData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const GetStateDataOption = GetStateTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
      default:
        return;
    }
  };


  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Define the list of characters to prevent
    const forbiddenKeys = ["+", "-", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "=", "{", "}", "[", "]", "|", "\\", ":", ";", "'", "\"", "<", ">", ",", ".", "?", "/", "`", "~"];

    if (forbiddenKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  async function handleFileUpload(file) {
    setFormData({ ...formData, sla: file.name });
    const formDataa = new FormData();
    formDataa.append("file", file);

    try {
      const response = await axios.post(baseUrl + "/upload-file", formDataa);
      if (response.status === 200) {
        setFormData({ ...formData, sla: response.data.responseData });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  async function handleFileDeletion1() {
    try {
      const response = await axios.put(
        baseUrl + `/delete-file-url?url=${formData.sla}`
      );
      if (response.status === 200) {
        setFormData({ ...formData, sla: "" });
      } else {
      }
    } catch (error) { }
  }
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "15px", marginLeft: "25px" }}>
              Create Vendor
            </h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col md={4}>
              <p className={classes.label}>
                Vendor Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    venderName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.venderName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Vendor Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>Address</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: e.target.value,
                  });
                }}
              />
              {/*<p
                className={
                  validate && !formData?.address.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address
              </p>*/}
            </Col>
            <Col md={3}>
              <p className={classes.label}>City</p>
              <Select
                className={classes.stateWidth}
                options={GetCityDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    cityId: e?.value,
                  });
                }}
              />
              {/*<p
                className={
                  validate && !formData?.cityId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter City
              </p>*/}
            </Col>
            <Col md={1}>
              <IoIosAddCircle
                className={classes.remove_icon}
                size={30}
                onClick={() => {
                  handleShow();
                }}
              />
            </Col>

            <Col md={4}>
              <p className={classes.label1}>State</p>
              <Select
                className={classes.stateWidth}
                options={GetStateDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    stateId: e?.value,
                  });
                }}
              />
              {/*<p
                className={
                  validate && !formData?.stateId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter State
              </p>*/}
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Pin</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pinCode: e.target.value,
                  });
                }}
              />
              {/*<p
                className={
                  validate && !formData?.pinCode?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Pin
              </p>*/}
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Country</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    country: e.target.value,
                  });
                }}
              />
              {/*<p
                className={
                  validate && !formData?.country?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Country
              </p>*/}
            </Col>
            <Col md={4}>
              <p className={classes.label}>GSTIN</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    gstin: e.target.value,
                  });
                }}
                onKeyDown={handleKeyDown}
                onClick={handleInputClick}
              />
            </Col>

            <Col md={4}>
              <p className={classes.label}>PAN</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pan: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label}>Payment Due Days</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pamentDueDays: e.target.value,
                  });
                }}
              />
              {/*<p
                className={
                  validate && !formData?.pamentDueDays?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Payment Due Days
              </p>*/}
            </Col>

            <Col md={4} className={classes.TestData}>
              <p className={classes.label1}>SLA</p>
              <div className={classes.chooseFile}>
                <div>
                  {formData?.sla ? (
                    <div
                      className={classes.rmpdf}
                      style={{ paddingTop: "3px" }}
                    >
                      {
                        <>
                          {checkfileType(formData?.sla?.split(".")[1])}&nbsp;
                          <span>
                            {formData?.sla && formData?.sla.length > 25
                              ? `${formData?.sla
                                ?.split("@")[1]
                                ?.substring(0, 25)}...`
                              : formData?.sla}
                          </span>
                        </>
                      }
                      <ClearIcon
                        onClick={handleFileDeletion1}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : (
                    <input
                      id="file-upload"
                      accept="image/*, .pdf, .doc, .docx"
                      type="file"
                      className={classes.upload_resume}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const fileSize = file.size / 1024 / 1024; // File size in MB
                          if (fileSize <= 10) {
                            handleFileUpload(file);
                          } else {
                            ToastifyError(
                              "File size exceeds the limit of 10MB."
                            );
                            e.target.value = null; // Reset the file input value
                            return;
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col md={4} className={classes.TestData}>
              <p className={classes.label1}>GST Applicable</p>
              <Select
                className={classes.stateWidth}
                options={TDSOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    gstApplicable: e?.label,
                  });
                }}
              />
            </Col>
            <Col md={4}></Col>
            <Col md={4} className={classes.TestData}>
              <p className={classes.label1}>TDS Applicable</p>
              <Select
                className={classes.stateWidth}
                options={TDSOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    tdsApplicable: e?.label,
                  });
                }}
              />
            </Col>
            {formData?.tdsApplicable === "Yes" ? (
              <Col md={4} className={classes.TestData}>
                <p className={classes.label1}>TDS Section</p>
                <Select
                  className={classes.stateWidth}
                  options={GetTdsDataOption}
                  styles={InvoiceStyles4}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      tdsSectionId: e?.value,
                    });
                  }}
                />
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Primary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Name</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryContactName: e.target.value,
                  });
                }}
              />
              {/* <p
                className={
                  validate && !formData?.primaryContactName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Name
              </p>*/}
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Email ID</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryContectEmail: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                  (formData?.primaryContectEmail &&
                    !validateEmail(formData?.primaryContectEmail))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {validate &&
                (formData?.primaryContectEmail &&
                  !validateEmail(formData?.primaryContectEmail))
                  && "Please Enter a Valid Email"}
                </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Mobile Number</p>
              <input
                type="number"
                className={classes.smallInput}
                maxLength={10}
                minLength={10}
                value={formData?.primaryMobileNumber}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.length > 10) {
                    inputVal = inputVal.slice(0, 10);
                  }
                  setFormData({
                    ...formData,
                    primaryMobileNumber: inputVal,
                  });
                }}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  (e.keyCode === 38 ||
                    e.keyCode === 40 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onKeyPress={(e) =>
                  ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                    e.which > 57 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.prevent
                }
              />
              {/*<p
                className={
                  validate &&
                  (!formData?.primaryMobileNumber ||
                    formData.primaryMobileNumber.length < 10)
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {formData?.primaryMobileNumber &&
                formData.primaryMobileNumber.length < 10
                  ? "Mobile number should have at least 10 digits"
                  : "Please Enter Mobile Number"}
                </p>*/}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Secondary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Name</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContactName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Email ID</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContectEmail: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                  (formData?.seconderyContectEmail &&
                    !validateEmail(formData?.seconderyContectEmail))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {validate &&
                (formData?.seconderyContectEmail &&
                  !validateEmail(formData?.seconderyContectEmail))
                  && "Please Enter a Valid Email"}
                </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Mobile Number</p>
              <input
                type="number"
                className={classes.smallInput}
                maxLength={10}
                minLength={10}
                value={formData?.seconderyMobileNumber}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.length > 10) {
                    inputVal = inputVal.slice(0, 10);
                  }
                  setFormData({
                    ...formData,
                    seconderyMobileNumber: inputVal,
                  });
                }}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  (e.keyCode === 38 ||
                    e.keyCode === 40 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onKeyPress={(e) =>
                  ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                    e.which > 57 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.prevent
                }
              />
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>
      <Modal
        show={show}
        centered
        classNames={{
          modal: "customModal",
        }}
      >
        <h6 style={{ margin: "20px 10px", paddingLeft: "7px" }}>
          Do you want to add City ?
        </h6>
        <p className={classes.label_item1}>
          City Name <span style={{ color: "red" }}>*</span>
        </p>
        <input
          type="text"
          className={classes.smallInput1}
          onChange={(e) => setAddCity(e.target.value)}
        />
        <p
          className={
            validate1 && !AddCity
              ? classes.errorText1
              : classes.errorTextHidden1
          }
        >
          Please Enter City Name
        </p>
        <div className={classes.buttonText}>
          <button className={classes.buttonAdditem} onClick={handleAdd}>
            Yes
          </button>
          <button className={classes.buttonCancle} onClick={handleAddCancle}>
            No
          </button>
        </div>
      </Modal>
      <button className={classes.buttonAdd} onClick={handleCreatePartner}>
        Submit
      </button>
    </>
  );
};
export default CreateVendor;
