import { addHrHeadComment } from "../../../../../../Utils/axios/AppraisalApi";
import style from "./AddComment.module.css";
import { useMutation } from "react-query";
import { employeeId } from "../../../../../../Utils/constants";
import { useState } from "react";

const AddCommentCom=({modalData,setShowComment,refetchComments,obj,setObj,setModalIsOpen,refetchEmployeeList})=> {
  const [commentData,setCommentData] = useState({
    "appraisalCycleId": Number(modalData.cycleId),
    "commentByEmpId": employeeId,
    "commentId":obj?obj.commentId:null,
    "employeeId": modalData.eid,
    "hrHeadComment": obj?obj.Comment:"",
  })
  const handleChange =(e)=>{
    setCommentData((prev)=>{
      return {...prev,hrHeadComment:e.target.value}
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if(commentData.hrHeadComment.trim())
    {
    addHrHeadCommentMutation.mutate(commentData);
    setObj(null);
    }
    else
    console.error("Error")
  setShowComment(false)
  typeof(setModalIsOpen)==="function" && setModalIsOpen(false)
  };
  const addHrHeadCommentMutation = useMutation(
    addHrHeadComment,
    {
      onMutate: () => {
      },
      onSuccess: () => {
    refetchComments();
    typeof(refetchEmployeeList)==="function" && refetchEmployeeList();
      },
      onError: (err) => {
      },
    }
  );
  return (
    <div className={style.main_container}>
      <p className={style.heading}>Add Comment</p>

      <form>
        <textarea
          name="description"
          id="com"
          cols="30"
          rows="10"
          className={style.textArea}
          value={commentData.hrHeadComment}
          onChange={(e)=>handleChange(e)}
        ></textarea>
        <br />
        <button type="submit" className={style.appr_btn} onClick={handleSubmit}>Submit</button>
      </form>
    </div>
  );
}
export default AddCommentCom;
