import React from 'react';
import { employeeId } from './Utils/constants';
import axios from 'axios';


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Extract relevant error information
        const errorDetails = {
            message: error.message,
            name: error.name,
            stack: error.stack,
        };

        const apiKey = process.env.REACT_APP_ATS_APP_URL;
        // Example URL for your backend service endpoint
        const backendURL = `${apiKey}/frontendError/add`;


        axios.post(backendURL, {
            errorMessage: errorDetails.message,
            name: errorDetails.name,
            stack: errorDetails.stack,
            errorInfo: errorInfo?.componentStack,
            employeeId
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                console.log('Error sent successfully to backend:', response.data);
            })
            .catch(err => {
                console.error('Error sending error to backend:', err);
            });
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
