import React, { useState, useEffect } from "react";
import style from "../component/ViewTaskComponent.module.css";
// import style from  '../../createTask/component/CreateTaskComponent.module.css';
import calendar from "../../../../assets/calendar.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import { Redirect } from "react-router-dom";
import Moment from "moment";
import Calendar from "react-calendar";
import constants, { employeeId } from "../../../../Utils/constants";

const currentDate = Moment().toDate();
const ViewTaskComponent = (props) => {
  const history = useHistory();
  const [taskData, setTaskData] = useState(props.taskData);
  const [taskId, settaskId] = useState(props.taskData.taskId);
  const [taskTitle, settaskTitle] = useState(props.taskData.title);
  const [taskDesc, settaskDesc] = useState(props.taskData.description);
  const [taskPEDate, settaskPEDate] = useState(
    Moment(props.taskData.planedEndDate).toDate()
  );
  const [taskAssignedBy, settaskAssignedBy] = useState(
    props.taskData.assignedBy
  );
  const [taskAssignedByName, settaskAssignedByName] = useState(
    props.taskData.name
  );
  const [taskPriority, settaskPriority] = useState(props.taskData.priority);
  const [taskStatus, settaskStatus] = useState(props.taskData.status);
  const [showCal, setShowCal] = useState(false);
  // const [showRes , setshowRes ] = useState(false);
  const [showErr, setShowErr] = useState(false);

  const [employeeList, setEmployeeList] = useState([]);

  employeeList.sort((a, b) => (a.name > b.name ? 1 : -1));

  const changeDate = (e) => {
    settaskPEDate(e);
    setShowCal(false);
  };

  const getHeaders = () => {
    const tokenData = JSON.parse(cookie.load("token"));
    return tokenData;
  };

  const url = constants.apiBaseUrl2;

  const updateData = () => {
    const data = {
      taskId: taskId,
      // "title": taskTitle,
      // "description": taskDesc,
      // "planedEndDate": Moment(taskPEDate.value).format("YYYY-MM-DD hh:mm"),
      // "planedEndDate":null,
      // "assignedBy": taskAssignedBy,
      // "priority": taskPriority,
      status: taskStatus,
      localDateTime: Moment().format("YYYY-MM-DD hh:mm"),
    };
    const oldData = {
      assignedBy: taskAssignedBy,
      description: taskDesc,
      name: taskAssignedByName,
      planedEndDate: props.taskData.planedEndDate,
      priority: taskPriority,
      status: taskStatus,
      taskId: taskId,
      title: taskTitle,
    };

    axios
      .post(url + "/updateTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          setShowErr(true);
        } else {
          setShowErr(false);
          history.push({
            pathname: "/TaskAssignedByMeDetails",
            state: {
              task: oldData,
              activePage: 1,
            },
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  //  useEffect(() => {
  //   async function employeedata(){
  //     const historyData = await axios.get('http://65.1.177.11:8080/employee/v1/getEmployeeList',
  //       {headers
  //         : {
  //           "Access-Control-Allow-Origin": "*",
  //           'Authorization': ` ${cookie.load('token')}`
  //         }
  //       })
  //       .then((res) => {
  //         setEmployeeList(res.data.responseData.employeeList)
  //       })
  //       .catch((err)  => {
  //         return err;
  //       })
  //   }
  //   employeedata();
  // },[])

  return (
    <div className={style.rightSection}>
      <div className={style.container}>
        <span>Edit Task</span>
        {/* {showRes &&
          <div className={style.alert} id="alert_msg">
            Task added successfully!
          </div>
        } */}
        <div className={style.createTask}>
          {showErr && (
            <div className={style.req_msg}>
              <p>* All fields are mandatory.</p>
            </div>
          )}
          <form id="create_task_id" className={style.gridContainer}>
            <label className={style.titleLabel}>
              Task Title
              <input
                readOnly
                name="task_title"
                type="text"
                id="tast_title"
                defaultValue={taskTitle}
              />
            </label>
            {/* </div> */}
            <label className={style.assignLabel}>
              Assigned By
              <input
                readOnly
                name="assignBy"
                type="text"
                id="assignBy"
                defaultValue={taskAssignedByName}
              />
              <select
                className={style.select}
                style={{ top: "40%" }}
                placeholder="assignTo"
                onChange={(e) => settaskAssignedBy(e.target.value)}
                value={taskAssignedBy}
              >
                <option value=""></option>
                {employeeList.map((data) => {
                  return <option value={data.id}> {data.name}</option>;
                })}
              </select>
            </label>

            <label className={style.dateLabel}>
              Planned End Date
              <input
                readOnly
                name="plannedEnd"
                type="input"
                id="plannedEnd"
                defaultValue={Moment(taskPEDate).format("DD MMM")}
              />
              {/* <img onClick={() => setShowCal(!showCal)} style={{position: 'absolute', right: '13%', top: '58%'}} src={calendar} /> */}
            </label>
            {/* <div className={style.calendarBox}>
              {showCal && <Calendar
                onChange={changeDate}
                tileDisabled={({ date }) => date.getDay() === -1}
                minDate={new Date()}
                value={dateState}
              />}
              </div> */}

            <label className={style.descLabel}>
              Task Description
              <textarea
                readOnly
                name="task_description"
                type="number"
                id="task_description"
                rows="7"
                cols="78"
                defaultValue={taskDesc}
              />
            </label>

            <div className={style.priorityLabel}>
              <label className={style.priorityLabel12}>
                Priority <br />
                <input
                  readOnly
                  name="status"
                  type="text"
                  id="status"
                  defaultValue={taskPriority}
                />
                {/* <select onChange={e => settaskPriority(e.target.value)} value={taskPriority}>
                  <option value=""></option>
                  <option value="Urgent">Urgent</option>
                  <option value="High">High</option>
                  <option value="Low">Low</option>
                </select> */}
              </label>
              <label className={style.statusLabel}>
                Status <br />
                {/* <input name="status" type="input" id="status" value="Assigned" /> */}
                <select
                  onChange={(e) => settaskStatus(e.target.value)}
                  value={taskStatus}
                  style={{
                    border: "1px solid #65A15E",
                    backgroundColor: "#FFFFFF",
                    fontSize: "15px",
                  }}
                  className={style.focusdrop}
                >
                  <option value="Assigned">Assigned</option>
                  <option value="Done">Done</option>
                  <option value="Onhold">On Hold</option>
                  <option value="Closed">Closed</option>
                </select>
              </label>
            </div>
          </form>
        </div>
        <button className={style.submitButton} onClick={() => updateData()}>
          Update
        </button>
        <button
          className={style.backButton}
          // onClick={() => history.push("/taskTracker")}
          onClick={() =>
            history.push({
              pathname: "/TaskAssignedByMeDetails",
              state: {
                task: props.taskData,
                activePage: 1,
              },
            })
          }
        >
          {" "}
          Back
        </button>
      </div>
    </div>
  );
};

export default ViewTaskComponent;
