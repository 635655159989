import React, { useEffect, useState } from "react";
import style from "./OnsiteReportData.module.css";
import TableRow from "../../ResourcesOnsite/TableRow";
import { Table } from "react-bootstrap";
import constants from "../../../../../Utils/constants";
import axios from "axios";
import Pagination from "../../../CommonComponent/Pagination";
import loaderImg from "../../../../../assets/loader.gif";
import cookie from "react-cookies";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";

const OnsiteReportData = (props) => {
  const history = useHistory();
  const baseUrl = constants.apiBaseUrlResource;
  const [applied, setApplied] = useState(false);
  const [OnsiteData, setOnsiteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [show, setShow] = useState(false);
  const [ClientInformedReason, setReason] = useState("");
  const [totalResourceOnsite, setTotalResourceOffShore] = useState(0);
  const [clientListing, setClientListing] = useState([]);
  const [empListing, setEmpListing] = useState([]);
  const [onsiteSkills, setOnsiteSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterName] = useState({});
  const [filterEmp] = useState({});
  const [allData, setAllData] = useState({

    action: [1],
    clientId: [],
    employeeId: [],
    skill: [],
    salaryRange: [],
    partners: [],
    startDateMonth: [],
    endDateMonth: [],
    employeeStatus: [null,null],
    quaterlyEndOfDeployment: [],
    quaterlyDeployment: [],
    handleFilter: false

  });

  const url = process.env.REACT_APP_UMS_APP_URL;

  empListing?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const getAddResourceOnsiteData = (clearData, currentPage) => {
    const filterData = clearData?.clientId?.length === 0 ? allData : allData;
    setIsLoaded(true);
    axios
      .post(
        baseUrl +
        "/getAddResourceOnsite?" +
        "limit=" +
        limit +
        "&page=" +
        currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setOnsiteData(res.data.responseData);
        setTotalResourceOffShore(res.data.responseData.totalResources);
        setpageCount(res.data.responseData.totalResources / 20);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };

  const getClientListing = () => {
    axios
      .get(baseUrl + "/getClientOnSite", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch(() => { });
  };

  const getEmpListing = () => {
    axios
      .get(baseUrl + "/getAllOnSiteEmployee", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setEmpListing(res.data.responseData.employees);
      })
      .catch(() => { });
  };

  const getUserData = () => {
    axios
      .get(url + `/updateUsersDetails`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then(() => { })
      .catch(() => { });
  };

  const getOnsiteSkills = () => {
    axios
      .get(baseUrl + "/getOnsiteFilterSkillData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOnsiteSkills(res.data.responseData);
      })
      .catch(() => { });
  };

  const limit = 20;
  // pagination change
  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  useEffect(() => {
    setCurrentPage(props?.page === 2 ? 1 : currentPage);
    getAddResourceOnsiteData(
      setAllData({ ...allData, clientId: [props.client] }),
      currentPage
    );
    getClientListing();
    getEmpListing();
    getOnsiteSkills();
  }, [currentPage, props]);

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <div className={style.main_client_body}>
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "6px 0px" }}>
              {/* <span><span>Dashboard{'>'}</span><span className={style.recent_tab}>Resource Allocation Onsite </span></span> */}
            </div>
          </div>

          <div className={isLoaded ? null : style.onsiteList}>
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : OnsiteData.resources?.length > 0 ? (
              <Table borderless size="sm" style={{ fontSize: "12px" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                    <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "15px" }}>Employee Code</th>
                    <th style={{ padding: "10px" }}>Clients Name</th>
                    <th style={{ padding: "10px" }}></th>
                    <th style={{ padding: "15px" }}>Employee Name</th>
                    <th style={{ padding: "15px" }}>Experience</th>
                    <th style={{ padding: "15px" }}>Salary Range</th>
                    <th style={{ padding: "10px" }}>Technology</th>
                    <th style={{ padding: "10px" }}> Assignment Status</th>
                    <th style={{ padding: "10px" }}>Start Date</th>
                    <th style={{ padding: "10px" }}>Deployment Quarter</th>
                    <th style={{ padding: "10px" }}>End Date</th>
                    <th style={{ padding: "10px" }}> Expected End Date</th>
                    <th style={{ padding: "10px" }}>Last Comment Date</th>
                    <th style={{ padding: "10px" }}>
                      Client Notification Date
                    </th>
                    <th style={{ padding: "10px" }}>Partner Informed</th>
                    <th style={{ padding: "10px" }}>Partner Informed Date</th>
                    <th style={{ padding: "10px" }}>Candidate Informed Date</th>
                    <th style={{ padding: "10px" }}>Client Informed</th>
                    <th style={{ padding: "10px" }}> Estimated Client Informed Date</th>

                  </tr>
                </thead>
                <tbody>
                  {OnsiteData?.resources.map((val) => {
                    return (
                      <TableRow
                      val={val}
                        clientName={val.clientName}
                        experience={val.experience}
                        projectSkill={val.skillName}
                        startDate={val.allocationdate}
                        EndDate={val.deallocationdate}
                        employeeName={val.empName}
                        id={val.id}
                        setShow={setShow}
                        setReason={setReason}
                        statusTypeName={val.statusTypeName}
                        resignStatus={val.resignStatus}
                        lastCommentDate={val.currentCommentDate}
                        empCode={val.empCode}
                        salaryRange={val.salaryRange}
                        applied={applied}
                        currentPage={currentPage}
                        allData={allData}
                        filterName={filterName}
                        filterEmp={filterEmp}
                      />
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div>
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}

            {/* } */}
          </div>
          {totalResourceOnsite > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>{ClientInformedReason}</div>
      </Modal>
    </>
  );
};

export default OnsiteReportData;
