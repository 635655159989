import React, { useState } from "react";
import style from "./ViewProject.module.css";
import CommonDropdown from "../../../../../../GenericComponent/ui/CommonDropdown";
import InvoiceButton from "../../../../CommonComponent/Button/InvoiceButton1";
import { useMutation, useQuery } from "react-query";
import {
  addApplication,
  changeProjectStatus,
  getAllApplications,
  getProjectDetails,
  getSkillsData,
} from "../../../../../../Utils/axios/PortalManagement";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import CommonCalender from "../../../../../../GenericComponent/ui/CommonCalender";
import moment from "moment";
import {
  checkIfEmpty,
  errorToast,
  onChangeHandler,
  successToast,
} from "../../../../../../Utils/constants";
import { Toastify, ToastifyError } from "../../../../../../App";

const ViewProject = ({ prevData, setPrevData, setNextScreen }) => {
  const [application, setApplication] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [required, setRequired] = useState(false);
  const [date1, setDate1] = useState(false)
  const [holdDate, setHoldDate] = useState(false)
  const [required1, setRequired1] = useState(false);
  const [formData, setFormData] = useState({
    skillId: "",
    skillName: "",
    application: "",
    typeStatus: "",
    typeStatsId: "",
    projectId: "",
  });

  const [statusData, setStatusData] = useState({
    holdDate: "",
    projectId: prevData?.projectId,
    reasonForhold: "",
    statusId: "",
    projectEndDate: "",
  });

  const { data: getSkillDataList } = useQuery(["getSkillsData"], () =>
    getSkillsData()
  );

  const technologyOptions = getSkillDataList
    ? getSkillDataList?.map((item) => {
      return {
        id: item?.id,
        label: item?.name,
      };
    })
    : [];

  const { data: getApplicationDataList, refetch: refetchApplicationDataList } =
    useQuery(
      ["getApplicationData"],
      () => prevData?.projectId && getAllApplications(prevData?.projectId)
    );

  const typeOptions = [
    { label: "Web", id: 1 },
    { label: "Mobile", id: 2 },
    { label: "Frontend", id: 3 },
    { label: "Backend", id: 4 },
    { label: "CMS", id: 5 },
  ];

  const { data: getProjectData, refetch: refetchProjectData } = useQuery(
    ["getProjectDetails"],
    () => prevData?.projectId && getProjectDetails(prevData?.projectId)
  );

  const addApplicationMutation = useMutation(addApplication, {
    onSuccess: (res) => {
      if (res?.data?.responseStatus?.statusCode === 1) {
        errorToast(`${res?.data?.responseData?.message}`);
        return;
      }

      successToast("Application Added Successfully");
      refetchApplicationDataList();
      setFormData({
        skillId: "",
        skillName: "",
        application: "",
        typeStatus: "",
        typeStatsId: "",
        projectId: "",
      });
    },

    onError: (err) => {
      errorToast("Something Went Wrong");
    },
  });

  const holdMutation = useMutation(changeProjectStatus, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 0) {
        refetchProjectData();
        setShowModal1(false);
        Toastify(res?.responseData?.changeStatus);
      } else {
        ToastifyError("Something went wrong");
      }
    },
    onError: (err) => {
      ToastifyError("Something went wrong");
    },
  });

  const closeMutation = useMutation(changeProjectStatus, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 0) {
        refetchProjectData();
        setShowModal2(false);
        Toastify(res?.responseData?.changeStatus);
      } else {
        ToastifyError("Something went wrong");
      }
    },
    onError: (err) => {
      ToastifyError("Something went wrong");
    },
  });

  const onApplicationSubmit = () => {
    if (!formData?.application) {
      setRequired(true);
      return;
    }

    if (!formData?.skillId) {
      setRequired(true);
      return;
    }

    if (!formData?.typeStatus) {
      setRequired(true);
      return;
    }

    setRequired(false);
    addApplicationMutation.mutate({
      application: formData?.application,
      projectId: String(prevData?.projectId),
      skillId: String(formData?.skillId),
      typeStatus: formData?.typeStatus,
    });
  };

  const onProjectHold = () => {

    const endDate = new Date(statusData?.holdDate);
    const startDate = new Date(getProjectData.startdate);

    if (!statusData?.holdDate) {
      setRequired1(true);
      return;
    }

    if (!statusData?.reasonForhold) {
      setRequired1(true);
      return;
    }

    if (endDate < startDate) {
      setHoldDate(true)
      return
    }


    setHoldDate(false)
    setRequired1(false);
    holdMutation.mutate({
      holdDate: statusData?.holdDate,
      projectId: statusData?.projectId,
      reasonForhold: statusData?.reasonForhold,
      statusId: 2,
    });
  };

  const onProjectClosed = () => {

    const endDate = new Date(statusData.projectEndDate);
    const startDate = new Date(getProjectData.startdate);

    if (!statusData?.projectEndDate) {
      setRequired1(true);
      setDate1(false)
      return;
    }


    if (endDate < startDate) {
      setDate1(true)
      return
    }

    setDate1(false)
    setRequired(false);
    closeMutation.mutate({
      projectEndDate: statusData?.projectEndDate,
      projectId: statusData?.projectId,
      statusId: 3,
    });
  };

  return (
    <>
      <div className={style.main_div}>
        <div className={style.breadcrum}>
          <span
            onClick={() => {
              setPrevData({
                ...prevData,
                sidebar: "projectManagement",
                page: "projectManagement",
                page1: "projectManagement",
                page2: null,
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                active: "projectManagement",
              });
              setNextScreen("projectManagement");
            }}
            className={style.cursor}
          >
            ProjectManagement
          </span>{" "}
          {">"} View Project{" "}
        </div>
        <div className={style.inner_main_div}>
          <div className={style.div_heading}>Project Information</div>
          <div className={style.new_data_div}>
            <div className={style.first_div}>
              <div className={style.small_div}>
                <p className={style.para}>Client Name</p>
                <p className={style.value}>
                  {getProjectData?.clientName || "NA"}
                </p>
              </div>

              <div className={style.small_div}>
                <p className={style.para}>Project Name</p>
                <p className={style.value}>{getProjectData?.name || "NA"}</p>
              </div>

              <div className={style.small_div}>
                <p className={style.para}>PM/PC</p>
                <p className={style.value}>{getProjectData?.pmpc || "NA"}</p>
              </div>

              <div className={style.small_div}>
                <p className={style.para}>Project Start Date</p>
                <p className={style.value}>
                  {getProjectData?.startdate
                    ? moment(getProjectData?.startdate)?.format("DD MMM YY")
                    : "NA"}
                </p>
              </div>
            </div>

            <div className={`${style.first_div} ${style.margin_top}`}>
              <div className={style.small_div}>
                <p className={style.para}>Type of Project</p>
                <p className={style.value}>
                  {getProjectData?.typeofproject || "NA"}
                </p>
              </div>

              <div className={style.small_div}>
                <p className={style.para}>Status</p>
                <p className={style.value}>{getProjectData?.status || "NA"}</p>
              </div>

              {getProjectData?.holdDate ? (
                <div className={style.small_div}>
                  <p className={style.para}>Hold Date</p>
                  <p className={style.value}>
                    {getProjectData?.holdDate
                      ? moment(getProjectData?.holdDate)?.format("DD MMM YY")
                      : "NA"}
                  </p>
                </div>
              ) : (
                <div className={style.small_div}></div>
              )}

              {getProjectData?.projectEndDate ? (
                <div className={style.small_div}>
                  <p className={style.para}>End Date</p>
                  <p className={style.value}>
                    {getProjectData?.projectEndDate
                      ? moment(getProjectData?.projectEndDate)?.format(
                        "DD MMM YY"
                      )
                      : "NA"}
                  </p>
                </div>
              ) : (
                <div className={style.small_div}></div>
              )}
            </div>

            {
              <div
                style={{ justifyContent: "flex-start", width: "100%" }}
                className={`${style.first_div} ${style.margin_top}`}
              >
                <div className={style.small_div}>
                  <p className={style.para}>Reason For Hold</p>
                  <p className={style.value}>
                    {getProjectData?.holdForReason || "NA"}
                  </p>
                </div>
                <div className={style.small_div}>
                  <p className={style.para}>Billing Model</p>
                  <p className={style.value}>
                    {getProjectData?.billingModelName}
                  </p>
                </div>
                <div className={style.small_div}></div>
                <div className={style.small_div}></div>
              </div>
            }

            <div
              style={{ justifyContent: "flex-start", width: "100%" }}
              className={`${style.first_div} ${style.margin_top}`}
            >
              <div className={style.big_div}>
                <p className={style.para}>Description</p>
                <p className={style.value}>
                  {getProjectData?.projectdescription || "NA"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={style.btn_div}>
          <button
            className={style.btn}
            onClick={() => {
              setNextScreen("milestones");
              setPrevData((prevData) => ({
                ...prevData,
                getProjectData: getProjectData,
                page1: "projectManagement",
                page2: "ViewProject",
                page3: "milestones",
                page4: null,
                active: "milestones",
              }));
            }}
          >
            Milestone
          </button>
          <button
            className={getProjectData?.holdDate ? style.disable_btn : style.btn}
            onClick={() => {
              setShowModal1(!showModal1);
              setStatusData({
                holdDate: "",
                projectId: prevData?.projectId,
                reasonForhold: "",
                statusId: "",
                projectEndDate: "",
              });
            }}
            disabled={getProjectData?.holdDate ? true : false}
          >
            Mark as Hold
          </button>
          <button
            className={
              getProjectData?.projectEndDate ? style.disable_btn : style.btn
            }
            onClick={() => {
              setShowModal2(!showModal2);
              setStatusData({
                holdDate: "",
                projectId: prevData?.projectId,
                reasonForhold: "",
                statusId: "",
                projectEndDate: "",
              });
            }}
            disabled={getProjectData?.projectEndDate ? true : false}
          >
            Mark as Closed
          </button>
          <button
            onClick={() => {
              setNextScreen("EditProject");
              setPrevData({
                ...prevData,
                sidebar: "projectManagement",
                page: "projectManagement",
                page1: "projectManagement",
                page2: null,
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                active: "EditProject",
              });
            }}
            className={`${style.btn} ${style.edit_btn}`}
          >
            Edit
          </button>
        </div>

        <div className={style.inner_main_div}>
          <div className={style.div_heading}>Application</div>
          {getApplicationDataList?.length > 0 ? (
            <div className={style.table_div}>
              <table className={style.table}>
                <thead className={style.table_heading}>
                  <tr>
                    <th className={`${style.table_head}`}>Application</th>
                    <th className={`${style.table_head}`}>Technology</th>
                    <th className={`${style.table_head}`}>Type</th>
                  </tr>
                </thead>
                <tbody style={{ position: "relative" }}>
                  {getApplicationDataList?.map((item, idx) => (
                    <tr key={idx}>
                      <td className={style.table_column}>
                        {item?.application}
                      </td>
                      <td className={style.table_column}>{item?.skillName}</td>
                      <td className={style.table_column}>{item?.typeStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className={style.no_data_para}>No Application</p>
          )}
        </div>

        <div className={style.btn_div}>
          <button
            onClick={() => setApplication(!application)}
            className={style.btn}
          >
            <span style={{ fontWeight: "700" }}>+</span> Add Application
          </button>
        </div>

        {application && (
          <>
            <div className={style.inner_main_div}>
              <div className={style.div_heading}>Application Information</div>
              <div className={style.first_div}>
                <div className={style.small_div2}>
                  <p className={style.para}>
                    Application <span className={style.important_star}>*</span>
                  </p>
                  <div>
                    <input
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          application: e?.target?.value,
                        })
                      }
                      className={style.input_box}
                      value={formData?.application}
                    />
                    {required && !formData?.application && (
                      <p className={style.errorMsg}>
                        Please Enter Application Name
                      </p>
                    )}
                  </div>
                </div>

                <div className={style.small_div2}>
                  <p className={style.para}>
                    Technology <span className={style.important_star}>*</span>
                  </p>
                  <div>
                    <CommonDropdown
                      placeholder="Select Technology"
                      options={technologyOptions}
                      color="#c3b14b"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          skillId: e?.id,
                          skillName: e?.label,
                        })
                      }
                      value={checkIfEmpty(formData?.skillName, {
                        id: formData?.skillId,
                        label: formData?.skillName,
                      })}
                      customInputOnClick={() =>
                        setFormData({
                          ...formData,
                          skillId: "",
                          skillName: "",
                        })
                      }
                    />
                  </div>
                  {required && !formData?.skillId && (
                    <p className={style.errorMsg}>Please Select Technology</p>
                  )}
                </div>

                <div className={style.small_div2}>
                  <p className={style.para}>
                    Type <span className={style.important_star}>*</span>
                  </p>
                  <div>
                    <CommonDropdown
                      placeholder="Select Type"
                      options={typeOptions}
                      color="#c3b14b"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          typeStatsId: e?.id,
                          typeStatus: e?.label,
                        })
                      }
                      value={checkIfEmpty(formData?.typeStatus, {
                        id: formData?.typeStatsId,
                        label: formData?.typeStatus,
                      })}
                      customInputOnClick={() =>
                        setFormData({
                          ...formData,
                          typeStatsId: "",
                          typeStatus: "",
                        })
                      }
                    />
                  </div>
                  {required && !formData?.typeStatus && (
                    <p className={style.errorMsg}>Please Select Type</p>
                  )}
                </div>
              </div>
            </div>

            <div className={style.btn_div}>
              <button
                onClick={() => onApplicationSubmit()}
                className={style.btn}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
      <Modal
        open={showModal1}
        center
        onClose={() => setShowModal1(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal overflow-visible-important",
        }}
      >
        <div className={style.hold_main_div}>
          <div className={style.modal_div}>
            <p className={style.para}>
              Hold Date <span className={style.important_star}>*</span>
            </p>
            <div className={style.date}>
              <CommonCalender
                icon="pm"
                value={statusData?.holdDate}
                formData={statusData}
                setFormData={setStatusData}
                onCross="holdDate"
                onChange={(date) => {
                  onChangeHandler(
                    statusData,
                    setStatusData,
                    date,
                    "holdDate",
                    "date"
                  );
                }}
              />
            </div>
            {required1 && !statusData?.holdDate && (
              <p className={style.errorMsg}>Please Select Date</p>
            )}

            {holdDate && (
              <p className={style.errorMsg}>Hold Date should be greater then Start Date</p>
            )}
          </div>

          <div className={`${style.modal_div} ${style.margin_top}`}>
            <p className={style.para}>
              Reason For Hold <span className={style.important_star}>*</span>
            </p>
            <textarea
              value={statusData?.reasonForhold}
              rows={5}
              className={style.textarea}
              onChange={(e) =>
                setStatusData({
                  ...statusData,
                  reasonForhold: e?.target?.value,
                })
              }
            />
            {required1 && !statusData?.reasonForhold && (
              <p className={style.errorMsg}>Please Enter Reason</p>
            )}
          </div>

          <div className={style.btn_div}>
            <button className={style.btn} onClick={() => onProjectHold()}>
              Done
            </button>
            <button className={style.btn} onClick={() => setShowModal1(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={showModal2}
        center
        onClose={() => setShowModal2(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal overflow-visible-important",
        }}
      >
        <div className={style.hold_main_div}>
          <div className={style.modal_div}>
            <p className={style.para}>
              Choose Project End Date{" "}
              <span className={style.important_star}>*</span>
            </p>
            <div className={style.date}>
              <CommonCalender
                icon="pm"
                value={statusData?.projectEndDate}
                formData={statusData}
                setFormData={setStatusData}
                onCross="projectEndDate"
                onChange={(date) => {
                  onChangeHandler(
                    statusData,
                    setStatusData,
                    date,
                    "projectEndDate",
                    "date"
                  );
                }}
              />
            </div>
            {required1 && !statusData?.projectEndDate && (
              <p className={style.errorMsg}>Please Select Date</p>
            )}

            {date1 && (
              <p className={style.errorMsg}>End Date should be greater then Start Date</p>
            )}
          </div>
          <div style={{ marginTop: "3%" }} className={`${style.btn_div}`}>
            <button className={style.btn} onClick={() => onProjectClosed()}>
              Done
            </button>
            <button className={style.btn} onClick={() => setShowModal2(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewProject;
