import React, { useState, useEffect } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import style from "./EmployeeEdit.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import InvoiceButton from "../../../Employee_Induction/CommonComponent/Button/InvoiceButton";
import calender from "../../../../assets/calblue.png";
import cancel from "../../../../assets/cancell.png";
import { useQuery, useMutation } from "react-query";
import DatePicker from "react-datepicker";
import { Container, Row, Col } from "react-bootstrap";
import {
  EmployeeInduction,
  CreateInduction,
  CreateActivity,
  CreateJoiningStatus,
} from "../../../../Utils/axios/InductionApi";
import moment from "moment";
import { GrPowerReset } from "react-icons/gr";
import { padding } from "@mui/system";

function EmployeeEdit({ setNextScreen, prevData, setPrevData, InductionData }) {
  let { ActivityStatus, idData, JoiningStatus2, overallStatus, checklistEmployee, folderURL } = prevData;

  const [inductionFormData, setInductionFormData] = useState([
    ...checklistEmployee.map((e) => ({ ...e, employeeId: idData })),
  ]);
  const [, setValidate] = React.useState(false);
  const [markNA, setMarkNA] = useState();

  const { data: InductionEditView } = useQuery(
    ["EmployeeInduction"],
    () => EmployeeInduction(prevData?.idData),
    {}
  );

  const [empDetailsCardData, setEmpDetailsCardData] = useState({});

  useEffect(() => {
    if (InductionEditView) {
      setEmpDetailsCardData({
        employeeName: InductionEditView?.employeeName,
        empCode: InductionEditView?.empCode,
        dateOfJoining: InductionEditView?.dateOfJoining,
        designation: InductionEditView?.designation,
        department: InductionEditView?.department,
        recruiter: InductionEditView?.recruiter,
      });
    }
  }, [InductionEditView]);

  const [activityStatusFormData, setActivityStatusFormData] = useState({
    ...ActivityStatus,
    checkListId: 36,
    employeeId: idData,
    delayByDays: InductionEditView?.delayByDays || 0,
  });

  const [joiningStatusFormData, setJoiningStatusFormData] = useState({
    ...JoiningStatus2,
    checkListId: 37,
    employeeId: idData,
    overallStatus: overallStatus,
    folderLink: folderURL
  });

  const createInductionMutation = useMutation(CreateInduction, {});
  const updateActivityMutation = useMutation(CreateActivity, {});
  const updateJoiningStatusMutation = useMutation(CreateJoiningStatus, {});

  const EditInductionHandler = () => {
    setValidate(false);

    const filteredData = inductionFormData?.filter(
      (e) =>
        e.categoryName !== "Employee Details" &&
        e.categoryName !== "Employee Orientation"
    );

    joiningStatusFormData["overallStatus"] = filteredData.every(
      (e) => e.status === "N.A" || e.status === "Done"
    );
    createInductionMutation.mutate(filteredData);
    updateActivityMutation.mutate(activityStatusFormData);
    updateJoiningStatusMutation.mutate(joiningStatusFormData);

    setNextScreen("EmployeeInductionView");
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref} style={{ cursor: "pointer" }}>
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          style={{ position: "absolute", right: "5%", top: "13%", cursor: "pointer" }}
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label ref={ref} style={{ cursor: "pointer" }}>
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{ position: "absolute", right: "5%", top: "16%", cursor: "pointer" }}
          onClick={() =>
            setActivityStatusFormData({
              ...activityStatusFormData,
              [props?.properties]: "",
            })
          }
        />
      </div>
    );
  });

  console.log("InductionData", InductionData);
  const handleRadioChange = (id, status) => {
    console.log("handleRadioChange", id, status);
    setInductionFormData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.checkListId === id ? { ...item, status: status } : item
      );
      return updatedData;
    });
  };

  const commonCardDesign = (categoryId) => {
    return (
      <>
        <div className={style.Container}>
          <div className={style.payroll}>
            <p className={style.label}> Name</p>
          </div>
          <div className={style.payrollStatus}>
            <p className={style.label2}>Status</p>
          </div>
        </div>
        {inductionFormData
          ?.filter((element) => element?.categoryId === categoryId)
          ?.map((element) => (
            <div className={style.Container} key={element.checkListId}>
              <div className={style.payrollName}>
                {<p>{element?.checkListName}</p>}
              </div>
              <div className={style.payrollStatus}>
                <div className={style.statusContainer}>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id={`Done${element.checkListId}`}
                      name={`InductionActivity${element.checkListId}`}
                      value="Done"
                      checked={element.status === "Done"}
                      onChange={() => handleRadioChange(element.checkListId, "Done")}
                    />
                    <label htmlFor={`Done${element.checkListId}`}>Done</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id={`NA${element.checkListId}`}
                      name={`InductionActivity${element.checkListId}`}
                      value="N.A"
                      checked={element.status === "N.A"}
                      onChange={() => handleRadioChange(element.checkListId, "N.A")}
                    />
                    <label htmlFor={`NA${element.checkListId}`}>N.A</label>
                  </div>
                </div>
                <div
                  onClick={() => handleRadioChange(element.checkListId, "")}
                  style={{ paddingLeft: "1rem", cursor: "pointer" }}
                  title="Reset"
                >
                  <GrPowerReset />
                </div>
              </div>
            </div>
          ))}
      </>
    );
  };

  const handleMarkNA = (val) => {
    console.log("handleMarkNA", val);
    setMarkNA(val);
    setInductionFormData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.categoryId === val ? { ...item, status: "N.A" } : item
      );
      return updatedData;
    });
  }

  return (
    <>
      <div>
        <BreadCrum prevData={prevData} setPrevData={setPrevData} setNextScreen={setNextScreen}></BreadCrum>
      </div>
      {/* 1st card */}
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Emp Code</p>
            <p className={style.value}>{empDetailsCardData?.empCode}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Emp Name</p>
            <p className={style.value}>{empDetailsCardData?.employeeName}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Date of Joining</p>
            <p className={style.value}>
              {moment(empDetailsCardData?.dateOfJoining).format("DD MMM YY") ?? ""}
            </p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Designation</p>
            <p className={style.value}>{empDetailsCardData?.designation}</p>
          </div>
        </div>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Department</p>
            <p className={style.value}>{empDetailsCardData?.department}</p>
          </div>
        </div>
      </InvoiceContainer>
      <div style={{ height: "70vh", overflowY: "scroll", overflowX: "clip" }}>
        {/* second card */}
        <div className={style.heading}>Employee Details</div>
        <InvoiceContainer>
          <div className={style.Container}>
            <div className={style.col212}>
              <p className={style.label}>Joining Kit Status</p>
              <div className={style.cu_col1} style={{ marginTop: "13px" }}>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="Done36"
                    name="joiningStatus"
                    value="Done"
                    checked={joiningStatusFormData?.joiningStatus === "Done"}
                    onChange={() => {
                      handleRadioChange(36, "Done");
                      setJoiningStatusFormData((prev) => ({ ...prev, joiningStatus: "Done" }));
                    }}
                  />
                  <label htmlFor="Done36">Done</label>
                </div>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="NA36"
                    name="joiningStatus"
                    value="N.A"
                    checked={joiningStatusFormData?.joiningStatus === "N.A"}
                    onChange={() => {
                      handleRadioChange(36, "N.A");
                      setJoiningStatusFormData((prev) => ({ ...prev, joiningStatus: "N.A" }));
                    }}
                  />
                  <label htmlFor="NA36">N.A</label>
                </div>
              </div>
              <div
                onClick={() => {
                  handleRadioChange(36, "");
                  setJoiningStatusFormData((prev) => ({ ...prev, joiningStatus: "" }));
                }}
                style={{ paddingLeft: "9rem", cursor: "pointer", marginTop: "-22px" }}
                title="Reset"
              >
                <GrPowerReset />
              </div>
            </div>
            <div className={style.col}>
              <p className={`${style.labelFolderlink} `}>Folder Link</p>
              <input readOnly disabled
                value={joiningStatusFormData?.folderLink}
                className={`${style.bigInputFolderLink} ${style.readonly}`}
                onChange={(e) =>
                  setJoiningStatusFormData((prev) => ({ ...prev, folderLink: e.target.value }))
                }
              />
            </div>
          </div>
        </InvoiceContainer>
        {/* 3rd card */}
        <div className={style.headWithBtn}>
          <div className={style.heading}>Employee Induction Activities</div>
          <button className={style.crtBtn} onClick={() => handleMarkNA(6)}>Mark All as N.A.</button>
        </div>
        <InvoiceContainer>{commonCardDesign(6)}</InvoiceContainer>
        {/* 4th card */}
        <div className={style.headWithBtn}>
          <div className={style.heading}>Employee Document Details</div>
          <button className={style.crtBtn} onClick={() => handleMarkNA(3)}>Mark All as N.A.</button>
        </div>
        <InvoiceContainer>{commonCardDesign(3)}</InvoiceContainer>
        {/* 5th card */}
        <div className={style.headWithBtn}>
          <div className={style.heading}>Joining Form</div>
          <button className={style.crtBtn} onClick={() => handleMarkNA(4)}>Mark All as N.A.</button>
        </div>
        <InvoiceContainer>{commonCardDesign(4)}</InvoiceContainer>
        {/* 6th card */}
        <div className={style.heading}>Employee Orientation</div>
        <InvoiceContainer>
          <Container>
            <Row>
              <Col md={3}>
                <p className={style.label}>Activity Date</p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      activityStatusFormData?.date
                        ? moment(activityStatusFormData?.date).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setActivityStatusFormData({
                        ...activityStatusFormData,
                        date: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      activityStatusFormData?.date ? (
                        <CustomInputCross properties="date" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
              </Col>
              <Col md={3} style={{ marginLeft: "29px" }}>
                <p className={style.label}>HR Name</p>
                <input
                  value={activityStatusFormData?.hrName}
                  className={style.bigInput}
                  onChange={(e) =>
                    setActivityStatusFormData({
                      ...activityStatusFormData,
                      hrName: e?.target?.value,
                    })
                  }
                />
              </Col>
              <Col md={3} style={{ marginLeft: "29px" }}>
                <p className={style.label2}>Status</p>
                <div className={style.cu_col1}>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="Done55"
                      name="Status"
                      value="Done"
                      checked={activityStatusFormData?.status === "Done"}
                      onChange={(e) =>
                        setActivityStatusFormData({
                          ...activityStatusFormData,
                          status: e?.target?.value,
                        })
                      }
                    />
                    <label htmlFor="Done55">Done</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="NA55"
                      name="Status"
                      value="N.A"
                      checked={activityStatusFormData?.status === "N.A"}
                      onChange={(e) =>
                        setActivityStatusFormData({
                          ...activityStatusFormData,
                          status: e?.target?.value,
                        })
                      }
                    />
                    <label htmlFor="NA55">N.A</label>
                  </div>
                </div>
                <div
                  onClick={() =>
                    setActivityStatusFormData((prev) => ({
                      ...prev,
                      status: "",
                    }))
                  }
                  style={{ paddingLeft: "9rem", cursor: "pointer", marginTop: "-22px" }}
                  title="Reset"
                >
                  <GrPowerReset />
                </div>
              </Col>
            </Row>
          </Container>
        </InvoiceContainer>
        {/* 7th card */}
        <div className={style.headWithBtn}>
          <div className={style.heading}>Introduction</div>
          <button className={style.crtBtn} onClick={() => handleMarkNA(7)}>Mark All as N.A.</button>
        </div>
        <InvoiceContainer>{commonCardDesign(7)}</InvoiceContainer>
        {/* 8th card */}
        <div className={style.headWithBtn}>
          <div className={style.heading}>Letters</div>
          <button className={style.crtBtn} onClick={() => handleMarkNA(8)}>Mark All as N.A.</button>
        </div>
        <InvoiceContainer>{commonCardDesign(8)}</InvoiceContainer>
        {/* 9th card */}
        <div className={style.headWithBtn}>
          <div className={style.heading}>HRMS</div>
          <button className={style.crtBtn} onClick={() => handleMarkNA(9)}>Mark All as N.A.</button>
        </div>
        <InvoiceContainer>{commonCardDesign(9)}</InvoiceContainer>
        {/* 10th card */}
        <div className={style.headWithBtn}>
          <div className={style.heading}>Payroll</div>
          <button className={style.crtBtn} onClick={() => handleMarkNA(10)}>Mark All as N.A.</button>
        </div>
        <InvoiceContainer>{commonCardDesign(10)}</InvoiceContainer>

        <div style={{ display: 'flex' }}>
          <div className={style.cancelBTN}>
            <InvoiceButton
              onClick={() => {
                setNextScreen("EmployeeInductionView");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: "EmployeeInductionView",
                  page3: null,
                  page4: null,
                  active: "EmployeeInductionView",
                  // idData: item.id,
                }));
              }}
            >
              Cancel
            </InvoiceButton>
          </div>

          <div className={style.SubmitBtn}>
            <InvoiceButton
              onClick={() => {
                EditInductionHandler();
                setNextScreen("EmployeeInductionView");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: "EmployeeInductionView",
                  page3: null,
                  page4: null,
                  active: "EmployeeInductionView",
                }));
              }}
            >
              Submit
            </InvoiceButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeEdit;
