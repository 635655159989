import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ToastContainer } from "react-toastify"
import BreadCrum from "../../breadcrums/BreadCrum"
import style from "../EPR/EPR.module.css";
import { getDashboardList, getProjectLeads, getEmployees } from "../../utils/api";
import { AiOutlineClose } from "react-icons/ai";
import searchIcon from "../../../../assets/Icon-search.png";
import { customSearch } from "../../utils/style";
import Select from "react-select";
import style1 from "./PmsDashboard.module.css"
import moment from "moment";
import ReviewFileDownload from "../ReviewFileDownload/ReviewFileDownload";

import FilterButton from "../../Filterbutton/FilterButton";
import PmsFilter from "./PmsFilter";

const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

const Dashboard = ({
  setNextScreen,
  setPrevData,
  prevData,
  currentPage,
  setCurrentPage
}) => {

  const [filter, setFilter] = useState(
    prevData.DashFilter
      ? prevData.DashFilter
      : {
        empId: null,
        leadId: null,
        limit: 0,
        page: 0,
        year: 2024,
        functionalArea: [],
        employeeType: [],
        location: [],
      }
  );
  const [monthI, setMonthI] = useState(filter?.year >= moment().year() ? moment().month() : 11);
  const [showFilter, setShowFilter] = useState(false);
  const [tableWidth, setTableWidth] = useState(60);

  useEffect(() => {
    setPrevData((prev) => ({
      ...prev,
      DashFilter: {
        leadId: filter?.leadId,
        empId: filter?.empId,
        limit: filter?.limit,
        page: filter?.page,
        year: filter?.year,
        functionalArea: filter?.functionalArea,
        employeeType: filter?.employeeType,
        location: filter?.location,
      }
    }))
  }, [filter])

  const { data: getProjectLead } = useQuery(
    ["getProjectLeads"],
    getProjectLeads
  );
  const getLeadListOption =
    getProjectLead?.length > 1 &&
    getProjectLead?.map((i) => ({
      value: i?.id,
      label: i?.label,
    })
    );
  const { data: getEmployeeLists } = useQuery(
    ["getEmployeeList"],
    // getAllEmployeeList
    getEmployees
  );
  const getEmployeeNameOptions = getEmployeeLists?.employeeList?.map((item) => {
    return {
      id: item?.id,
      label: `${item?.name} (${item?.employeeCode})`,
      label1: item?.name,
      employeeCode: item?.employeeCode,
    };
  });

  const { data: getDashboard } = useQuery(
    ["getDashboardList", filter],
    () =>
      getDashboardList({
        location: filter?.location?.map((item) =>
          item === 0 ? "All" : item === 1 ? "Onsite" : "Offshore"
        ),
        empId: filter?.empId,
        employeeType: [...filter?.employeeType],
        functionalArea: [...filter?.functionalArea],
        leadId: filter?.leadId,
        year: filter?.year,
      }, filter?.limit, filter?.page),
    {
      retry: false,
    }
  );

  const clearFilterAll = () => {
    setFilter((prev) => ({
      ...prev,
      functionalArea: [],
      employeeType: [],
      location: []
    }))
  }



  const onSearchHandler = (e, option) => {

    setCurrentPage(1);
    if (e || e === "") {
      switch (option) {

        case "leadId":
          setFilter({ ...filter, leadId: e?.value });
          break;
        case "empId":
          setFilter({ ...filter, empId: e?.id });
          break;
        case "year":
          if (moment().year() > e?.value) setMonthI(11)
          else setMonthI(moment().month)
          setFilter({ ...filter, year: e?.value })

        default:
      }
    } else {
      setFilter({
        leadId: 0,
        empId: "",
        limit: "",
        page: "",
        year: 2024
      });
    }
  };

  const handleClick = (id) => {
    setNextScreen("EmployeeFeedbackList");
    setPrevData((prevData) => ({
      ...prevData,
      empId: id,
      page: "EmployeeFeedbackList",
      page2: "EmployeeFeedbackList",
      page3: null,
      active: "EmployeeFeedbackList",
    }));
  }

  useEffect(() => {
    if (monthI > 6) setTableWidth(60 + (monthI - 6) * 5);
  }, [monthI]);
  return (
    <>
      <ToastContainer />
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
        countComponent={
          <div
            className={style.count}
          >
            <p>{getDashboard?.count}</p>
          </div>
        }
        filterComponent={
          <FilterButton setShowFilter={setShowFilter} filterData={filter} />
        }
      >

        <div className={style.topBar}>
          <div className={style.s1}>
            {filter?.leadId === null ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilter({
                    ...filter,
                    leadId: null,
                  });
                }}
              >
                <AiOutlineClose />
              </span>
            )}
            <Select
              styles={customSearch}
              classNamePrefix={"create_position"}
              placeholder="Lead Name"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={
                filter?.leadId
                  ? getLeadListOption?.find(
                    (i) => i.value === filter?.leadId
                  )
                  : null
              }
              onChange={(e) => onSearchHandler(e, "leadId")}
              options={getLeadListOption}
            />

            <div>
              <p className={style.download}>
                <span
                  className={style.backColor1}
                  style={{
                    display: "flex",
                    position: "absolute",
                    top: "1px",
                    right: "214px",
                    fontSize: "2rem",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <ReviewFileDownload filter={filter} />
                </span>
              </p>
            </div>

          </div>
          <div className={style.s1}>
            {filter?.empId === null ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilter({
                    ...filter,
                    empId: null,
                  });
                }}
              >
                <AiOutlineClose />
              </span>
            )}
            <Select
              styles={customSearch}
              classNamePrefix={"create_position"}
              placeholder="Employee Name"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={
                filter?.empId
                  ? {
                    value: filter?.empId,
                    label: getEmployeeNameOptions?.find(obj => obj.id === filter?.empId)?.label1,
                  }
                  : null
              }
              onChange={(e) => onSearchHandler(e, "empId")}
              options={getEmployeeNameOptions}
            />
          </div>
          <div className={style.s1}>
            <span className={style.cp_serchIcon}>
              <img src={searchIcon} />
            </span>

            <Select
              styles={customSearch}
              classNamePrefix={"create_position"}
              placeholder="Year"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={
                filter?.year
                  ? {
                    value: filter?.year,
                    label: filter?.year,
                  }
                  : null
              }
              onChange={(e) => onSearchHandler(e, "year")}
              options={[{ label: 2024, value: 2024 }, { label: 2023, value: 2023 }]}
            />
          </div>
        </div>
      </BreadCrum>

      <div className={style.PositionList} style={{ padding: 0 }}>
        <div className={style1.card}>
          <div className={style1.th} style={{ width: `${tableWidth}rem`, zIndex: 2 }}>
            <p className={style.tableContent}>Code</p>
            <p className={style.tableContent}>Name</p>
            <p className={style.tableContent}>DOJ</p>
            {monthArr.map((ele, index) =>
              index <= monthI ? (<p className={style.tableContent} style={{ width: "5rem" }}>{monthArr[index]}</p>) : null
            )}
          </div>

          <div style={{ zIndex: 1 }}>
            {getDashboard?.response?.map((ele, index) => {
              const dojDate = ele.doj ? moment(ele.doj) : null;
              const dojMonth = dojDate ? dojDate.month() + 1 : null; // DOJ month (1-12)
              const dojYear = dojDate ? dojDate.year() : null; // DOJ year

              return (
                <div className={style1.td} key={index} style={{ width: `${tableWidth}rem` }}>
                  <p onClick={() => handleClick(ele.empId)}>
                    {ele.empCode}
                  </p>
                  <p className={style.tableContent}>
                    {ele.empName}
                  </p>
                  <p className={style.tableContent}>
                    {ele.doj && moment(ele.doj).format("DD MMM YY")}
                  </p>

                  {monthArr.map((ele1, index1) => {
                    const currentMonth = index1 + 1;
                    const currentYear = filter.year;

                    const isBeforeDOJ =
                      dojDate &&
                      (currentYear < dojYear || (currentYear === dojYear && currentMonth < dojMonth));

                    const ratingCriteria = ele.monthWishResponse.find(obj => obj.month === currentMonth)?.ratingCriteria === "1 to 5";
                    const rating = ele.monthWishResponse.find(obj => obj.month === currentMonth)?.rating;

                    const isRed = ratingCriteria && rating !== "N/A" && !isBeforeDOJ;

                    return (
                      index1 <= monthI &&
                      <p
                        className={style.tableContent}
                        style={{
                          color: isRed ? "blue" : "inherit", width: "5rem"
                        }}
                        key={index1}
                      >
                        {isBeforeDOJ ? "N/A" : (
                          <>
                            {rating || ""}

                            {ele?.monthWishResponse?.find(obj => obj.month === currentMonth)?.status &&
                              ["PIP", "Reward", "Appreciation", "Feedback"].includes(
                                ele.monthWishResponse.find(obj => obj.month === currentMonth)?.status
                              ) ? (
                              <span
                                style={{ fontWeight: "400", fontSize: "0.9rem" }}
                                className={style.ratingWrapper}
                                title={ele?.monthWishResponse?.find(obj => obj.month === currentMonth)?.status}
                              >
                                &nbsp;(
                                {ele.monthWishResponse.find(obj => obj.month === currentMonth)?.status === "Rejected"
                                  ? "RJ"
                                  : ele.monthWishResponse.find(obj => obj.month === currentMonth)?.status.charAt(0)}
                                )
                              </span>
                            ) : null}
                          </>
                        )}
                      </p>
                    );
                  })}
                </div>
              );
            })}
          </div>


          <PmsFilter
            filterData={filter}
            setFilterData={setFilter}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            clearFilterAll={clearFilterAll}
          />
        </div>
      </div>
    </>

  )
}

export default Dashboard