import React, { useEffect, useState } from "react";
import style from "./SideBar.module.css";
import one from "../../../assets/businessman-gear-icon.png";
import oneG from "../../../assets/businessman-gear-icon_GREEN.png";
import ats from "./../../../assets/ats.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import { getCheckEmpRole } from "../../../Utils/axios/NewTimeSheetApi";
import { useQuery } from "react-query";
import { validateEmployeeAccess } from "../../../Utils/axios/api";
import { employeeId } from "../../../Utils/constants";

const SideBar = ({
  sidebarHandler,
  nextScreen,
  setPrevData,
  prevData,
  setCurrentPage,
  roles,
  getTeamMembers,
  setNextScreen,
}) => {
  let history = useHistory();
  const [entity, setEntity] = useState("");
  const apiKey = process.env.REACT_APP_NEW_TIMESHEET_URL;

  const { data: getCheckEmpRoleId } = useQuery(["getCheckEmpRole"], () =>
    getCheckEmpRole(Number(employeeId))
  );

  const { data: getValidateEmp, refetch } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(employeeId)),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));

  useEffect(() => {
    refetch();
  }, [prevData?.partnerId]);

  useEffect(() => {
    if (entity.length === 0) {
      const url = `${apiKey}/get-team-members?leadId=${employeeId}`;
      axios
        .get(url)
        .then((res) => {
          setEntity(res.data.responseData);
        })
        .catch((err) => {});
    }
  }, []);

  return (
    <>
      {getCheckEmpRoleId && getCheckEmpRoleId === true ? (
        <div className={style.leftSection}>
          {/* 0 */}
          <img
            alt="DashBoardImage"
            src={InnovationMLogo}
            className={style.dashboardImage}
            onClick={() => history.push("/dashboard")}
          />
          <button className={style.taskTracker}>
            <img src={ats} alt="img" height="40px" />
            <span>Employee Timesheet</span>
          </button>

          {/* 1 */}

          <button
            onClick={() => {
              sidebarHandler("empTimeSheet");
              setCurrentPage(1);
              setPrevData({
                ...prevData,
                sidebar: "empTimeSheet",
                page: "empTimeSheet",
                page1: "empTimeSheet",
                page2: null,
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                active: "empTimeSheet",
              });
            }}
            className={
              prevData?.sidebar === "empTimeSheet"
                ? style.selected
                : style.assignedByMe
            }
          >
            <div className={style.sb0}>
              {prevData?.sidebar === "empTimeSheet" ? (
                <img src={oneG} alt="img" />
              ) : (
                <img src={one} alt="img" />
              )}
            </div>
            <div className={style.sb}>
              <span>My Timesheet</span>
            </div>
          </button>

          {getVData?.some((e) => {
            return e?.label === "828V2698P78209VB";
          }) && (
            <button
              onClick={() => {
                sidebarHandler("TeamTimeSheets");
                setCurrentPage(1);
                setPrevData({
                  ...prevData,
                  sidebar: "TeamTimeSheets",
                  page: "TeamTimeSheets",
                  page1: "TeamTimeSheets",
                  page2: null,
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  active: "TeamTimeSheets",
                });
              }}
              className={
                prevData?.sidebar === "TeamTimeSheets"
                  ? style.selected
                  : style.assignedByMe
              }
            >
              <div className={style.sb0}>
                {prevData?.sidebar === "TeamTimeSheets" ? (
                  <img src={oneG} alt="img" />
                ) : (
                  <img src={one} alt="img" />
                )}
              </div>
              <div className={style.sb}>
                <span>Team Timesheet</span>
              </div>
            </button>
          )}
        </div>
      ) : (
        <div className={style.leftSection}>
          <img
            alt="DashBoardImage"
            src={InnovationMLogo}
            className={style.dashboardImage}
            onClick={() => history.push("/dashboard")}
          />
          <button className={style.taskTracker}>
            <img src={ats} alt="img" height="40px" />
            <span>Employee Timesheet</span>
          </button>

          <button
            onClick={() => {
              sidebarHandler("empTimeSheet");
              setCurrentPage(1);
              setPrevData({
                ...prevData,
                sidebar: "empTimeSheet",
                page: "empTimeSheet",
                page1: "empTimeSheet",
                page2: null,
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                active: "empTimeSheet",
              });
            }}
            className={
              prevData?.sidebar === "empTimeSheet"
                ? style.selected
                : style.assignedByMe
            }
          >
            <div className={style.sb0}>
              {prevData?.sidebar === "empTimeSheet" ? (
                <img src={oneG} alt="img" />
              ) : (
                <img src={one} alt="img" />
              )}
            </div>
            <div className={style.sb}>
              <span>My Timesheet</span>
            </div>
          </button>
        </div>
      )}
    </>
  );
};

export default SideBar;
