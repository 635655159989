import React, { useState } from "react";
import { FaRegComments } from "react-icons/fa";
import style from "./AppraisalHistory.module.css";
import Modal from "react-responsive-modal";
import AppraisalHistoryComment from "./AppraisalHistoryComment/AppraisalHistoryComment";
import { addCommas } from "../../utils/AddCommas";

function AppraisalHistoryCom({ empAppraisalHistory }) {
  const [modalIsOpen,setModalIsOpen]=useState(false);
  const [modalData,setModalData]=useState(null);
  const closeModal=()=>{
    setModalIsOpen(false)
  }
  const openModal=()=>{
    setModalIsOpen(true)
  }
  const titles = [
    "Cycle",
    "Type",
    "Reward",
    "Promotion",
    "Prev Core CTC(M)",
    "Prev Core CTC(A)",
    "Prev Full CTC(M)",
    "Prev Full CTC(A)",
    "Increment Amount",
    "Increment %",
    "New Core CTC(M)",
    "New Core CTC(A)",
    "New Full CTC(M)",
    "New Full CTC(A)",
  ];
  const details = empAppraisalHistory.map(appraisalHistory=>{
    return[
    appraisalHistory.appraisalCycleTitle,
    appraisalHistory.action,
    appraisalHistory.reward,
    appraisalHistory.promotion,
    addCommas(appraisalHistory.prevMonthlyCoreCTC),
    addCommas(appraisalHistory.prevYearlyCoreCTC),
    addCommas(appraisalHistory.prevMonthlyFullCTC),
    addCommas(appraisalHistory.prevYearlyFullCTC),
    addCommas(appraisalHistory.incrementAmount),
    appraisalHistory.incrementPercentage,
    addCommas(appraisalHistory.newMonthlyCoreCTC),
    addCommas(appraisalHistory.newYearlyCoreCTC),
    addCommas(appraisalHistory.newMonthlyFullCTC),
    addCommas(appraisalHistory.newYearlyFullCTC),
    ]
  })

  return (
<div>
      <div className={style.container}>
        <div className={style.title}>Appraisal History</div>
        <hr className={style.hr}></hr>
        {empAppraisalHistory.length>0?
        <div className={style.completeSection}>
          <table className={style.table}>
            <thead>
              <tr className={style.headRow}>
                {titles?.map((title,index)=>(
                  <th className={style.th} key={index}>{title}</th>
                ))}
                  <th className={style.th}>Comments</th>
              </tr>
            </thead>
            <tbody>
              
              {details?.map(((detail,index)=>{
               return <tr className={style.bodyRow} key={index}>
                  {detail.map(item=>{
                    return <td className={style.td}>{item}</td>
                  })}
                  <td className={`${style.td} `}><p className={`${style.comment}`}><FaRegComments 
                  onClick={()=>{
                        setModalData(empAppraisalHistory[index].hrHeadCommentobj)
                        openModal()}
                        }
                        /></p></td>
                </tr>
              }))}
              
            </tbody>
          </table>
          </div>
        :
        <div className={style.noHistory}>
          No History Available
        </div>
        }
      </div>
      <Modal
        open={modalIsOpen}
        onRequestClose={closeModal}
        center
        onClose={() => setModalIsOpen(false)}
        showCloseIcon={false}
        className={style.modal}
      >
        <div className={style.commentModal}>
        <AppraisalHistoryComment modalData={modalData}/>
        </div>
      </Modal>
    </div>

    
  );
}

export default AppraisalHistoryCom;
