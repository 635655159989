import React, { useState } from "react";
import style from "./ResourceDetails.module.css";
import Header from "../component/Header/Header";
import Sidebar from "../component/Sidebar/Sidebar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Nodata from "../component/Nodata/Nodata";
import AddPricing from "../component/AddPricing/AddPricing";
import PriceList from "../component/PriceList/PriceList";
import { decrypted_value, employeeId } from "../../../Utils/constants";
import {
  createAdditionalInformation,
  getResourceDetails,
} from "../../../Utils/axios/priceRevisionApi";
import { validateEmployeeAccess } from "../../../Utils/axios/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import moment from "moment";
import Loading from "../component/Loading/Loading";
import { Toastify, ToastifyError } from "../../../App";

const ResourceDetails = (props) => {
  const [recentDetails, setRecentDetails] = useState({});
  const history = useHistory();
  const queryClient = useQueryClient();
  const [id, setId] = useState(1);
  const [formData, setFormData] = useState({
    lastClientRate: "",
    currentClientRate: "",
    isRevised: "",
    approveBy: "",
    approvedById: "",
    // contractType: "",
    // contractTypeId: "",
    reason: "",
    lastRevisionDate: "",
    reviewDateManually: "",
  });

  const { data, isLoading } = useQuery(
    [
      "getResourceDetails",
      decrypted_value(history?.location?.search, "clientId"),
      decrypted_value(history?.location?.search, "resourceId"),
    ],
    () =>
      getResourceDetails(
        decrypted_value(history?.location?.search, "clientId"),
        decrypted_value(history?.location?.search, "resourceId")
      )
  );

  const createPricingMutation = useMutation(createAdditionalInformation, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 1) {
        ToastifyError(res.responseData.message);
      } else {
        Toastify("Price Added Successfully");
        queryClient.invalidateQueries("getResourceDetails");
        setId(3);
        setFormData({
          lastClientRate: "",
          currentClientRate: "",
          isRevised: "",
          approveBy: "",
          approvedById: "",
          // contractType: "",
          // contractTypeId: "",
          reason: "",
          lastRevisionDate: "",
          reviewDateManually: "",
        });
      }
    },

    onError: (err) => {
      ToastifyError("Something Went Wrong");
    },
  });

  const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(employeeId)),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const getVData = getValidateEmp?.map((i) => ({
    label: i?.elementCode,
    value: i?.empId,
  }));

  return (
    <>
      {isLoading && <Loading />}
      <Header />
      <div className={style.resource_details_main_div}>
        <div className={style.sidebar_div}>
          <Sidebar />
        </div>
        <div className={style.main_div}>
          <div className={style.breadcrum}>
            <span
              onClick={() =>
                history.push({
                  pathname: "/price_listing",
                  state: { allData: props?.history?.location?.state },
                })
              }
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  history.push({
                    pathname: "/price_listing",
                    state: { allData: props?.history?.location?.state },
                  });
                }
              }}
              tabIndex={0}
              className={style.first_breadcrum}
            >
              Resource Pricing List
            </span>
            <span className={style.arrow}>{" > "}</span>
            <span className={style.second_breadcrum}>Resource Detail</span>
          </div>

          <div className={style.details_div}>
            <p className={style.details_heading}>Employee Details</p>
            <div className={style.second_div}>
              <div className={style.single_div}>
                <p className={style.resource_head}>Resource Name</p>
                <p className={style.resource_para}>
                  {data?.resourceName || "-"}
                </p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>Resource Start Date</p>
                <p className={style.resource_para}>
                  {data?.resourceStartDate
                    ? moment(data?.resourceStartDate)?.format("DD-MMM-YY")
                    : "-"}
                </p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>Start Month</p>
                <p className={style.resource_para}>{data?.startMonth || "-"}</p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>Technology</p>
                <p className={style.resource_para}>{data?.skill || "-"}</p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>Experience</p>
                <p className={style.resource_para}>
                  {data?.experience
                    ? `${~~(data?.experience / 12)} Year ${
                        data?.experience % 12
                      } Month`
                    : "-"}
                </p>
              </div>
            </div>

            <div className={style.second_div}>
              <div className={style.single_div}>
                <p className={style.resource_head}>Sales Rep</p>
                <p className={style.resource_para}>{data?.salesRep || "-"}</p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>Client Name</p>
                <p className={style.resource_para}>{data?.clientName || "-"}</p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>Contract Start Date</p>
                <p className={style.resource_para}>
                  {data?.contractStartDate
                    ? moment(data?.contractStartDate)?.format("DD-MMM-YY")
                    : "-"}
                </p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>Contract End Date</p>
                <p className={style.resource_para}>
                  {data?.contractEndDate
                    ? moment(data?.contractEndDate)?.format("DD-MMM-YY")
                    : "-"}
                </p>
              </div>

              <div className={style.single_div}>
                <p className={style.resource_head}>End Month</p>
                <p className={style.resource_para}>{data?.endMonth || "-"}</p>
              </div>
            </div>

            <div className={style.second_div}>
              <div className={style.single_div}>
                <p className={style.resource_head}>Contract Type</p>
                <p className={style.resource_para}>
                  {data?.contractType || "-"}
                </p>
              </div>
            </div>
          </div>

          <div className={style.button_div}>
            {getVData?.some((e) => {
              return e?.label === "359T2710D98693HX";
            }) && (
              <button
                onClick={() => {
                  setId(2);
                  data?.completeInfoResponse?.length > 0 &&
                    setRecentDetails({ ...data?.completeInfoResponse[0] });
                }}
                className={style.btn}
              >
                Add Pricing
              </button>
            )}
          </div>

          <div className={style.additional_div}>
            {id === 2 ? (
              <AddPricing
                formData={formData}
                setFormData={setFormData}
                recentDetails={recentDetails}
              />
            ) : data?.completeInfoResponse?.length === 0 ? (
              <Nodata />
            ) : id === 3 || data?.completeInfoResponse?.length > 0 ? (
              <PriceList data={data?.completeInfoResponse} />
            ) : null}
          </div>

          {id === 2 && (
            <div className={style.button_div}>
              <button
                onClick={() => {
                  setId(3);
                  setFormData({
                    lastClientRate: "",
                    currentClientRate: "",
                    isRevised: "",
                    approveBy: "",
                    approvedById: "",
                    // contractType: "",
                    // contractTypeId: "",
                    reason: "",
                    lastRevisionDate: "",
                    reviewDateManually: "",
                  });
                }}
                className={style.btn2}
              >
                Cancel
              </button>

              <button
                onClick={() => {
                  createPricingMutation.mutate({
                    approvedById: formData?.approvedById,
                    clientId: decrypted_value(
                      history?.location?.search,
                      "clientId"
                    ),
                    contractEndDate: formData?.contractEndDate,
                    contractStartDate: formData?.contractStartDate,
                    // contractTypeId: formData?.contractTypeId,
                    currentClientRate: formData?.currentClientRate,
                    isRevised:
                      formData?.isRevised === "Done"
                        ? true
                        : formData?.isRevised === "Defer"
                        ? false
                        : null,
                    lastClientRate: formData?.lastClientRate,
                    reason: formData?.reason,
                    lastRevisionDate: formData?.lastRevisionDate,
                    reviewDateManually: formData?.reviewDateManually,
                    resourceId: decrypted_value(
                      history?.location?.search,
                      "resourceId"
                    ),
                  });
                }}
                className={style.btn}
              >
                Done
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResourceDetails;
