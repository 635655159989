import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes/Routes";
import { Provider } from "react-redux";
import store from "./redux/store";
import { NavigationProvider } from "./Pages/Projects_and_Resource_Master/Resources/PoolResources/ResourceOnsiteDescription/NavigationContext";

function App() {
  const queryClient = new QueryClient();

  return (
    <>
      <NavigationProvider>
        <ToastContainer />
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <Routes />
          </QueryClientProvider>
        </Provider>
      </NavigationProvider>
    </>
  );
}

export default App;

export const Toastify = (res) => {
  toast.success(res, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    closeButton: false,
  });
  return (<ToastContainer />);
};
export const ToastifyError = (res) => {
  toast.error(res, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    closeButton: false,
  });
};
