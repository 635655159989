import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import BreadCrum from "../../breadcrums/BreadCrum";
import FilterButton from "../Filterbutton/FilterButton";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import Filter from "../../filter/Filter";
import style from "./Expense.module.css";
import { InvoiceStylesVendorName } from "../../utils/style";
import Select from "react-select";
import calender from "../../../../assets/calblue.png";
import cancel from "../../../../assets/cancell.png";
import dropdownIcon from "../../../../assets/downIcon.png";
import DatePicker from "react-datepicker";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useQuery, useMutation } from "react-query";
import delete1 from "../../../../assets/delete-icon.png";
import {
  expenseList,
  getPaymentStatus,
  DeleteExpense,
  updateDateStatus,
} from "../../../../Utils/axios/expenceApi";
import { getShortMonth, getShortYear } from "../../../../Utils/constants";
import ExcelFileDownload from "./ExcelFileDownload/ExcelFileDownload";

const Expense = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [deleteId, setdeleteId] = useState();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [Data, setData] = useState("");

  const [mData, setMdata] = useState();

  const { data: PaymentStatusData } = useQuery(
    ["getPaymentStatus"],
    getPaymentStatus
  );

  const PaymentStatus = PaymentStatusData?.map((e) => ({
    value: e.id,
    label: e.status,
  }));

  const handlePaymentStatus = (e) => {
    setMdata((preData) => ({
      ...preData,
      statusId: e.value,
    }));
  };

  const [filterData, setFilterData] = useState({
    expenseMonth: [],
    expenseYear: "",
    category: [],
    vendor: [],
    status: [],
    paymentDate: [],
    approveBy: [],
    paymentMode: [],
    download: false,
  });

  const filterUpdateFuc = () => {
    setCurrentPage(1);
    setData(filterData);
    setShowFilter(false);
    sessionStorage.setItem("expenseFilter", JSON.stringify(filterData));
  };

  const mutation1 = useMutation(DeleteExpense, {
    onSuccess: (res) => {
      refetch();
      setNextScreen("expense");
    },
  });

  const mutationPS = useMutation(updateDateStatus, {
    onSuccess: (res) => {
      refetch();
      setNextScreen("expense");
    },
  });

  const handleShow = (expenseId) => {
    setShow(true);
    setdeleteId(expenseId);
  };

  const handleShowEdit = (expenseId) => {
    setShow2(true);
  };
  const handleDelete = () => {
    setShow(false);
    mutation1.mutate(deleteId);
  };

  const handleEdit = () => {
    let data = {
      date: moment(mData.date).toISOString(),
      statusId: mData?.statusId,
      expenseId: mData?.expenseId,
    };
    mutationPS.mutate(data);
    setShow2(false);
  };

  const handleDeleteCancle = () => {
    setShow(false);
  };
  const handleEditCancle = () => {
    setShow2(false);
    setMdata(null);
  };

  const filterClearAllFuc = () => {
    setData("");
    setShowFilter(false);
    setFilterData((preData) => ({
      ...preData,
      expenseMonth: [],
      category: [],
      vendor: [],
      status: [],
      paymentDate: [],
      approveBy: [],
      paymentMode: [],
      expenseYear: ""
    }));
    sessionStorage.removeItem("expenseFilter");
    return;
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const { data, isLoading, refetch } = useQuery(
    ["expenseList", { currentPage, Data }],
    () => expenseList(20, currentPage, Data),
    { refetchOnWindowFocus: true }
  );

  useEffect(() => {
    if (sessionStorage.getItem("expenseFilter")) {
      setFilterData(JSON.parse(sessionStorage.getItem("expenseFilter")));
      setData(JSON.parse(sessionStorage.getItem("expenseFilter")));
    }
  }, [sessionStorage.getItem("expenseFilter")]);

  const CustomInputCross2 = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
            fontWeight: "400",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() =>
            setMdata({
              ...mData,
              [props?.properties]: "",
            })
          }
          alt=""
        />
      </div>
    );
  });
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
          PlusComponent={
            <div className={style.count_div}>
              <div>
                <p className={style.count_para}>Total</p>
                <div className={style.small_count}>
                  {data?.responseData?.expenses?.length > 0
                    ? data?.responseData?.totalExpense
                    : 0}
                </div>
              </div>

              <div>
                <p className={style.count_para}>Amount (Billed)</p>
                <div className={style.small_count}>
                  <CurrencyRupeeIcon className={style.rupee_icon} />
                  {data?.responseData?.expenses?.length > 0
                    ? data?.responseData?.totalBilled
                    : 0}
                </div>
              </div>

              <div>
                <p className={style.count_para}>Amount (Payable)</p>
                <div className={style.small_count}>
                  <CurrencyRupeeIcon className={style.rupee_icon} />{" "}
                  {data?.responseData?.expenses?.length > 0
                    ? data?.responseData?.totalPayable
                    : 0}
                </div>
              </div>
            </div>
          }
          filterComponent={
            <div className={style.filter_div}>
              <ExcelFileDownload
                filterData={filterData}
                setFilterData={setFilterData}
                Data={Data}
              />
              <FilterButton
                setShowFilter={setShowFilter}
                filterData={filterData}
              />
            </div>
          }
        >
          <InvoiceButton
            type={"plus"}
            onClick={() => {
              setNextScreen("createExpense");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "createExpense",
                page3: null,
                page4: null,
                active: "createExpense",
              }));
            }}
          >
            Create Expense
          </InvoiceButton>
        </BreadCrum>
      </div>

      <div
        style={{
          padding: "0px",
          boxShadow: "0 0px 5px 1px rgb(0 0 0 / 20%)",
          width: "95%",
          margin: "10px auto",
          borderRadius: "5px",
          backgroundColor: "#ffffff",
          fontSize: "14px",
          zIndex: "0",
        }}
      >
        {data?.responseData?.totalExpense > 0 ? (
          <div className={style.table_div}>
            <table style={{ fontSize: "12px", border: "none" }}>
              <thead
                style={{
                  backgroundColor: "#F0F0F0",
                  position: "sticky",
                  top: "0px",
                }}
              >
                <tr style={{ padding: "100px" }}>
                  <th
                    style={{
                      padding: "10px",
                      position: "sticky",
                      top: "0px",
                      left: "0px",
                      backgroundColor: "#F0F0F0",
                    }}
                  >
                    Invoice No.
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      position: "sticky",
                      top: "0px",
                      left: "5.61rem",
                      backgroundColor: "#F0F0F0",
                    }}
                  >
                    Invoice Date
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      position: "sticky",
                      top: "0px",
                      left: "10.6rem",
                      backgroundColor: "#F0F0F0",
                    }}
                  >
                    Bill Month
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      position: "sticky",
                      top: "0px",
                      left: "15.6rem",
                      backgroundColor: "#F0F0F0",
                    }}
                  >
                    Vendor Name
                  </th>
                  <th style={{ padding: "10px" }}>Service Mon/ Year</th>
                  <th style={{ padding: "10px" }}>Category</th>
                  <th style={{ padding: "10px" }}>
                    Amount
                    <br />
                    (Billed)
                  </th>
                  <th style={{ padding: "10px" }}>
                    Amount
                    <br />
                    (Payable)
                  </th>
                  <th style={{ padding: "10px" }}>
                    Payable 1
                  </th>
                  <th style={{ padding: "10px" }}>
                    Payable 2
                  </th>
                  <th style={{ padding: "10px" }}>
                    Payment
                    <br /> Status
                  </th>
                  <th style={{ padding: "10px" }}>
                    Payment Due
                    <br /> Date
                  </th>
                  <th style={{ padding: "10px" }}>
                    Payment
                    <br /> Date
                  </th>
                  <th style={{ padding: "10px" }}>
                    Payment
                    <br /> Mode
                  </th>
                  <th style={{ padding: "10px" }}>
                    Approved
                    <br /> By
                  </th>
                  <th style={{ padding: "10px" }}>Actions</th>
                </tr>
              </thead>

              {isLoading && <p className={style.no_data}>Loading .....</p>}
              <tbody>
                {data?.responseData?.expenses?.map((item, idx) => (
                  <tr>
                    <td
                      style={{
                        padding: "0px 10px",
                        position: "sticky",
                        top: "0px",
                        left: "0px",
                        backgroundColor: "white",
                      }}
                    >
                      {item?.invoice || "-"}
                    </td>
                    <td
                      style={{
                        padding: "0px 10px",
                        position: "sticky",
                        top: "0px",
                        left: "5.61rem",
                        backgroundColor: "white",
                      }}
                    >
                      {item?.dateOfExpense && item?.dateOfExpense
                        ? moment(item?.dateOfExpense).format("DD MMM YY")
                        : "-"}
                    </td>
                    <td
                      style={{
                        padding: "0px 10px",
                        position: "sticky",
                        top: "0px",
                        left: "10.6rem",
                        backgroundColor: "white",
                      }}
                    >
                      {item?.expenseMonth || "-"}
                    </td>
                    <td
                      style={{
                        padding: "0px 10px",
                        position: "sticky",
                        top: "0px",
                        left: "15.6rem",
                        backgroundColor: "white",
                        color: "#855ea1",
                      }}
                    >
                      <p
                        title={item?.vendorName || "-"}
                        className={style.vendor_name}
                      >
                        <span
                          onClick={() => {
                            setNextScreen("viewExpense");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "viewExpense",
                              page3: null,
                              page4: null,
                              active: "viewExpense",
                              idData: item.id,
                            }));
                          }}
                          style={{ fontSize: "14px", cursor: "pointer" }}
                        >
                          {item?.vendorName}
                        </span>
                      </p>
                    </td>
                    <td style={{ padding: "0px 10px" }}>
                      {getShortMonth(item?.serviceMonth)} /{" "}
                      {getShortYear(item?.serviceYear)}
                    </td>
                    <td style={{ padding: "0px 10px" }}>
                      {item?.categoryName}
                    </td>
                    <td style={{ padding: "0px 10px" }}>
                      &#8377; {item.totalAmount}
                    </td>
                    <td style={{ padding: "0px 10px" }}>
                      &#8377; {item.amountToBePaid}
                    </td>
                    <td style={{ padding: "0px 10px" }}>
                      &#8377; {(Number(item?.amountToBePaid) - Number(item?.gst)).toFixed(2)}
                    </td>
                    <td style={{ padding: "0px 10px" }}>
                      &#8377; {item.gst && (item.gst).toFixed(2)}
                    </td>
                    <td
                      className={
                        item?.paymentStatus === "Done"
                          ? style.done
                          : item?.paymentStatus === "Approval Pending"
                            ? style.pending
                            : item?.paymentStatus === "Due"
                              ? style.due
                              : item?.paymentStatus === "Hold"
                                ? style.hold
                                : style.NA
                      }
                      style={{ padding: "0px 10px" }}
                    >
                      {item.paymentStatus ? item.paymentStatus : "-"}
                    </td>
                    <td style={{ padding: "0px 10px" }}>
                      {item.paymentDueDate && item.paymentDueDate
                        ? moment(item.paymentDueDate).format("DD MMM YY")
                        : "-"}
                    </td>
                    <td style={{ padding: "0px 10px" }}>
                      {item.paymentDate && item.paymentDate
                        ? moment(item.paymentDate).format("DD MMM YY")
                        : "-"}
                    </td>
                    <td style={{ padding: "0px 10px" }}>
                      {item?.paymentMode || "-"}
                    </td>
                    <td style={{ padding: "0px 10px" }}>
                      {item?.approveBy || "-"}
                    </td>
                    <td
                      style={{
                        padding: "0px 10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5%",
                      }}
                    >
                      <EditIcon
                        style={{ cursor: "pointer", color: "#855ea1" }}
                        onClick={() => {
                          setMdata({
                            ...mData,
                            date: item?.dateOfExpense,
                            statusId: item?.statusId,
                            expenseId: item?.id,
                          });
                          handleShowEdit(item?.id);
                        }}
                      />
                      <figure>
                        <img
                          style={{
                            cursor: "pointer",
                            marginRight: "11px",
                            marginTop: "13px",
                          }}
                          src={delete1}
                          alt=""
                          onClick={() => {
                            handleShow(item?.id);
                          }}
                        />
                      </figure>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className={style.no_data_found}>No data found</p>
        )}

        <>
          <Filter
            filterData={filterData}
            setFilterData={setFilterData}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            nineCard="Expense Year"
            firstCard="Expense Month"
            secondCard="Vendor"
            forthCard="Category"
            fifthCard="Status"
            sixthCard="Payment Date"
            sevenCard="Approved By"
            eightCard="Payment Mode"
            filterUpdateFuc={filterUpdateFuc}
            filterClearAllFuc={filterClearAllFuc}
          />
        </>
      </div>
      <div className={style.pagination}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagesCount={data?.responseData?.totalExpense / 20}
          color="#855ea1"
          color2="white"
        />
      </div>
      <Modal show={show} centered dialogClassName='deleteModal'>
        <h6 className={style.md1}>Do you want to delete</h6>

        <div className={style.btnModal}>
          <button className={style.buttonAdd1} onClick={handleDelete}>
            Yes
          </button>
          <button className={style.buttonCancle} onClick={handleDeleteCancle}>
            No
          </button>
        </div>

        <style type="text/css">
          {`
          .deleteModal{
            width: 400px;
          }`}
        </style>
      </Modal>

      <Modal
        show={show2}
        centered
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div>
          <div className={style.editmodal}>
            <div className={style.col2}>
              <p className={style.label}>Payment Status</p>
              <Select
                styles={InvoiceStylesVendorName}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={PaymentStatus?.find((e) => {
                  return e.value === mData?.statusId ? mData?.statusId : null;
                })}
                onChange={(e) => handlePaymentStatus(e)}
                options={PaymentStatus}
              />
            </div>
            <div className={style.col2}>
              <p className={style.label}>Payment Date</p>
              <div className={style.cu_field23Last}>
                <DatePicker
                  placeholder=" DD MM YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selected={mData?.date ? moment(mData?.date).toDate() : null}
                  onChange={(date) => {
                    setMdata({
                      ...mData,
                      date: moment(date).format(),
                    });
                  }}
                  dateFormat="dd MMM yyyy"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  customInput={
                    mData?.date ? (
                      <CustomInputCross2 properties="date" />
                    ) : (
                      <CustomInputClg />
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.btnModal}>
          <button className={style.buttonAdd1} onClick={handleEdit}>
            Yes
          </button>
          <button className={style.buttonCancle} onClick={handleEditCancle}>
            No
          </button>
        </div>

        <style type="text/css">
          {`
      .modal-content {
        width: 73%;
      }
    `}
        </style>
      </Modal>
    </>
  );
};

export default Expense;
