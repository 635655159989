import React from 'react';
import { Route } from "react-router-dom";
import Header from "../commonComponent/Header";
import SideBar from "../commonComponent/SideBar";
// import TaskAssignedByMeDetails from './component/TaskAssignedByMeDetails'; 
import TaskAssignedByMeComponent from './component/TaskAssignedByMeComponent';
import style from "./TaskAssignedByMe.module.css";

const TaskAssignedByMe = () => {

  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <TaskAssignedByMeComponent />
      </div>
    </div>
  );
}

export default TaskAssignedByMe