import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import BreadCrum from "../../../breadcrums/BreadCrum";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import pdf from '../../../../../assets/pdf.png';
import word from '../../../../../assets/word.png';
import file from '../../../../../assets/file.png';
import Select from "react-select";
import calender from "../../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import { InvoiceStyles } from "../../../utils/style";
import dropdownIcon from "../../../../../assets/downIcon.png";
import { FaTrash } from "react-icons/fa";
import cancel from "../../../../../assets/cancell.png";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import style from "./ViewInvoice.module.css";
import { getInvoiceId } from "../../../../../Utils/axios/expenceApi.js";
import { useQuery } from "react-query";
import moment from "moment";

const ViewInvoice = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const {
    data: InvoiceDataById,
    isLoading,
    isError,
  } = useQuery(["getInvoiceId"], () => getInvoiceId(prevData?.invoiceId));
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <img src={pdf} alt="pdf" />;
      case "docx":
        return <img src={word} alt="word" />;
      default:
        return <img src={file} alt="other" />;
    }
  };
  const handleDivClick = (url) => {
    window.open(url, '_blank');
  };
  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      {/*Copy Invoice*/}
      <InvoiceContainer>
        {isLoading && <p className={style.no_data}>Loading .....</p>}

        <div className={style.copy_expence}>Copy Invoice </div>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>Copy Invoice?</div>
            <div className={style.radioitem}>
              <p className={style.values}>
                {InvoiceDataById?.copyInvoice === true ? "Yes" : "NO"}
              </p>
            </div>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Client Name</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.clientName}
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>Service Month</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.serviceMonth}
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>Service Year</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.serviceYear}
            </p>
          </div>
          <div className={style.col4}></div>
        </div>
      </InvoiceContainer>
      {/*Invoice Details*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Invoice Details</div>
        {/*row 1*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Project Type</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.serviceTypeName}
            </p>
          </div>

          <div className={style.col12}>
            <p className={style.label}>Invoice Type</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.invoiceTypeName}
            </p>
          </div>

          {InvoiceDataById && InvoiceDataById?.serviceTypeId === 2 && (
            <>
              <div className={style.col12}>
                <p className={style.label}>Project Name</p>
                <p className={style.values}>
                  {InvoiceDataById && InvoiceDataById.projectName}
                </p>
              </div>

              <div className={style.col12}>
                <p className={style.label}>Milestone</p>
                <p className={style.values}>
                  {InvoiceDataById &&
                    InvoiceDataById.invoiceLineItemDetails[0]?.milestoneName}
                </p>
              </div>
            </>
          )}
          {false ? (
            <>
              {" "}
              <div className={style.col3}>
                <p className={style.label}>Project Name</p>
                <p className={style.values}>Demo Project</p>
              </div>
              <div className={style.col4}>
                <p className={style.label}>Milestone</p>
                <p className={style.values}>Demo Milestone</p>
              </div>
            </>
          ) : null}
        </div>
        {/*row 2*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Invoice No</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.invoiceNumber}
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>Invoice Date </p>
            <p className={style.values}>
              {moment(InvoiceDataById && InvoiceDataById.invoiceDate).format(
                "DD MMM YY"
              )}
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>Due Date</p>
            <p className={style.values}>
              {moment(InvoiceDataById && InvoiceDataById.dueDate).format(
                "DD MMM YY"
              )}
            </p>
          </div>
          <div className={style.col4}>
            <p className={style.label}>Advance Status</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.advanceStatus}
            </p>
          </div>
        </div>
        {/*row 3*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Currency</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.currency}
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>Conversion Rate </p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.conversionRate}
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>GSTN</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.gstNumber}
            </p>
          </div>
          <div className={style.col4}></div>
        </div>
      </InvoiceContainer>
      {/* Line Item Details Container */}
      {InvoiceDataById?.serviceTypeId === 2 ? (
        <>
          <InvoiceContainer>
            <div className={style.copy_expence}>Milestone</div>
            <div className={style.table}>
              <div className={style.th}>
                <p className={style.tableContent}>Name</p>
                <p className={style.tableContent}>Time Frame</p>
                <p className={style.tableContent}>Percentage</p>
                <p className={style.tableContent}>Amount</p>
                <p className={style.tableContent}>Converted Amount</p>
              </div>
              <div>
                {isLoading && <p className={style.no_data}>Loading .....</p>}

                {!isLoading && !isError ? (
                  InvoiceDataById?.invoiceLineItemDetails?.length > 0 ? (
                    typeof data !== "string" &&
                    InvoiceDataById?.invoiceLineItemDetails?.map((item) => {
                      return (
                        <div className={style.td} key={item?.positionId}>
                          <p className={style.tableContent}>
                            {item?.milestoneName}
                          </p>
                          <p className={style.tableContent}>
                            {item.milestoneTimeFrame}
                          </p>

                          <p className={style.tableContent}>
                            {item.milestoneProjectPercentage}
                          </p>

                          <p className={style.tableContent}>
                            {item.milestoneAmount}
                          </p>
                          <p className={style.tableContent}>
                            {item.calculativeBaseAmount}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={style.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            </div>
          </InvoiceContainer>

          <InvoiceContainer>
            <div className={style.Container}>
              <div className={style.col1}>
                <div className={style.heading}>GST Applicable</div>
                <div className={style.radioitem}>
                  <p className={style.values}>
                    {InvoiceDataById?.gstApplicable === true ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            </div>

            {InvoiceDataById?.gstApplicable ? (
              <>
                <div className={style.Container}>
                  <div className={style.col12}>
                    <p className={style.label}>Base Amount</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.baseAmountInvoice}
                    </p>
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>IGST</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.igst}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>CGST</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.cgst}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>SGST</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.sgst}
                    </p>
                  </div>
                </div>

                <div className={style.Container}>
                  <div className={style.col12}>
                    <p className={style.label}>GST(TOTAL)</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.totalGst}
                    </p>
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>Total Billed</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.totalBilled}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>TDS</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.tds}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>Other Charges</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.otherCharges}
                    </p>
                  </div>
                </div>
                <div className={style.Container}>
                  <div className={style.col3}>
                    <p className={style.label}>Discount</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.discount}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>Amount to be received</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.amountToBeReceived}
                    </p>
                  </div>
                  <div className={style.col3}></div>
                  <div className={style.col3}></div>
                </div>
              </>
            ) : (
              <div className={style.Container}>
                <div className={style.col3}>
                  <p className={style.label}>Other Charges</p>
                  <p className={style.values}>
                    {InvoiceDataById && InvoiceDataById.otherCharges}
                  </p>
                </div>
                <div className={style.col3}>
                  <p className={style.label}>Discount</p>
                  <p className={style.values}>
                    {InvoiceDataById && InvoiceDataById.discount}
                  </p>
                </div>
                <div className={style.col3}>
                  <p className={style.label}>Amount to be received</p>
                  <p className={style.values}>
                    {InvoiceDataById && InvoiceDataById.amountToBeReceived}
                  </p>
                </div>
                <div className={style.col3}></div>
              </div>
            )}
          </InvoiceContainer>
          <InvoiceContainer>
            <div className={style.file_container}>
              <div className={style.heading}>Files Uploaded</div>
              {InvoiceDataById?.documentResponses?.map((file, index) => (
                <div key={index} title={file.documentURL.split('@')[1]} className={`${style.file_name}`}
                  onClick={() => handleDivClick(file.documentURL)}
                >
                  <span>{checkfileType(file.documentURL.substr(-3))}&nbsp;</span>
                  <p className={style.text}>{file.documentURL.split('@')[1]}</p>
                </div>
              ))}
            </div>
          </InvoiceContainer>
          <div className={style.lastBtn}>
            <InvoiceButton
              type={"cancel"}
              onClick={() => {
                setNextScreen("invoiceList");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: null,
                  page3: null,
                  page4: null,
                  active: "invoiceList",
                }));
              }}
            >
              Back
            </InvoiceButton>
            <InvoiceButton
              onClick={() => {
                setNextScreen("editInvoice");
                setPrevData((prevData) => ({
                  id: prevData?.invoiceId,
                  ...prevData,
                  page2: "editInvoice",
                  page3: null,
                  page4: null,
                  active: "editInvoice",
                  EditinvoiceId: prevData,
                  clientAcknowledgementStatus:
                    InvoiceDataById &&
                    InvoiceDataById.clientAcknowledgementStatus,
                  bankReferenceId:
                    InvoiceDataById && InvoiceDataById.bankReferenceId,
                  amountReceivedInBank:
                    InvoiceDataById && InvoiceDataById.amountReceivedInBank,
                  reimbursableExpenses:
                    InvoiceDataById && InvoiceDataById.reimbursableExpenses,
                }));
              }}
            >
              Edit
            </InvoiceButton>
          </div>
        </>
      ) : InvoiceDataById?.serviceTypeId === 3 ? (
        <>
          <InvoiceContainer>
            <div className={style.copy_expence}>Product</div>
            <div className={style.table}>
              <div className={style.th}>
                <p
                  style={{ textAlign: "start" }}
                  className={style.tableContent}
                >
                  S.N.
                </p>
                <p
                  style={{ textAlign: "start" }}
                  className={style.tableContent}
                >
                  Description
                </p>
                <p
                  style={{ textAlign: "start" }}
                  className={style.tableContent}
                >
                  Amount
                </p>
                <p
                  style={{ textAlign: "start" }}
                  className={style.tableContent}
                >
                  Converted Amount
                </p>
              </div>
              <div>
                {isLoading && <p className={style.no_data}>Loading .....</p>}
                {!isLoading && !isError ? (
                  InvoiceDataById?.invoiceLineItemDetails?.length > 0 ? (
                    typeof data !== "string" &&
                    InvoiceDataById?.invoiceLineItemDetails?.map((item) => {
                      return (
                        <div className={style.td} key={item?.positionId}>
                          <p
                            style={{ textAlign: "start" }}
                            className={style.tableContent}
                          >
                            {item?.sno}
                          </p>
                          <p
                            style={{ textAlign: "start" }}
                            className={style.tableContent}
                          >
                            {item.description}
                          </p>
                          <p
                            style={{ textAlign: "start" }}
                            className={style.tableContent}
                          >
                            {item.description}
                          </p>
                          <p
                            style={{ textAlign: "start" }}
                            className={style.tableContent}
                          >
                            {item.calculativeBaseAmount}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={style.no_data}>No Data Found</p>
                  )
                ) : null}
                <div className={style.total_invoice_amount_div}>
                  <span style={{ fontWeight: "600" }}>
                    Total Invoice Amount :
                  </span>{" "}
                  <span className={style.values}>
                    {InvoiceDataById?.totalInvoiceAmount}
                  </span>
                </div>
              </div>
            </div>
          </InvoiceContainer>

          <InvoiceContainer>
            <div className={style.Container}>
              <div className={style.col1}>
                <div className={style.heading}>GST Applicable</div>
                <div className={style.radioitem}>
                  <p className={style.values}>
                    {InvoiceDataById?.gstApplicable === true ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            </div>

            {InvoiceDataById?.gstApplicable ? (
              <>
                <div className={style.Container}>
                  <div className={style.col12}>
                    <p className={style.label}>Base Amount</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.baseAmountInvoice}
                    </p>
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>IGST</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.igst}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>CGST</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.cgst}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>SGST</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.sgst}
                    </p>
                  </div>
                </div>

                <div className={style.Container}>
                  <div className={style.col12}>
                    <p className={style.label}>GST(TOTAL)</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.totalGst}
                    </p>
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>Total Billed</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.totalBilled}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>TDS</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.tds}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>Other Charges</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.otherCharges}
                    </p>
                  </div>
                </div>

                <div className={style.Container}>
                  <div className={style.col3}>
                    <p className={style.label}>Discount</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.discount}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>Amount to be received</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.amountToBeReceived}
                    </p>
                  </div>
                  <div className={style.col3}></div>
                  <div className={style.col3}></div>
                </div>
              </>
            ) : (
              <div className={style.Container}>
                <div className={style.col3}>
                  <p className={style.label}>Other Charges</p>
                  <p className={style.values}>
                    {InvoiceDataById && InvoiceDataById.otherCharges}
                  </p>
                </div>
                <div className={style.col3}>
                  <p className={style.label}>Discount</p>
                  <p className={style.values}>
                    {InvoiceDataById && InvoiceDataById.discount}
                  </p>
                </div>
                <div className={style.col3}>
                  <p className={style.label}>Amount to be received</p>
                  <p className={style.values}>
                    {InvoiceDataById && InvoiceDataById.amountToBeReceived}
                  </p>
                </div>
                <div className={style.col3}></div>
              </div>
            )}
          </InvoiceContainer>
          <InvoiceContainer>
            <div className={style.file_container}>
              <div className={style.heading}>Files Uploaded</div>
              {InvoiceDataById?.documentResponses?.map((file, index) => (
                <div key={index} title={file.documentURL.split('@')[1]} className={`${style.file_name}`}
                  onClick={() => handleDivClick(file.documentURL)}
                >
                  <span>{checkfileType(file.documentURL.substr(-3))}&nbsp;</span>
                  <p className={style.text}>{file.documentURL.split('@')[1]}</p>
                </div>
              ))}
            </div>
          </InvoiceContainer>

          <div className={style.lastBtn}>
            <InvoiceButton
              type={"cancel"}
              onClick={() => {
                setNextScreen("invoiceList");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: null,
                  page3: null,
                  page4: null,
                  active: "invoiceList",
                }));
              }}
            >
              Back
            </InvoiceButton>
            <InvoiceButton
              onClick={() => {
                setNextScreen("editInvoice");
                setPrevData((prevData) => ({
                  id: prevData?.invoiceId,
                  ...prevData,
                  page2: "editInvoice",
                  page3: null,
                  page4: null,
                  active: "editInvoice",
                  EditinvoiceId: prevData,
                  clientAcknowledgementStatus:
                    InvoiceDataById &&
                    InvoiceDataById.clientAcknowledgementStatus,
                  bankReferenceId:
                    InvoiceDataById && InvoiceDataById.bankReferenceId,
                  amountReceivedInBank:
                    InvoiceDataById && InvoiceDataById.amountReceivedInBank,
                  reimbursableExpenses:
                    InvoiceDataById && InvoiceDataById.reimbursableExpenses,
                }));
              }}
            >
              Edit
            </InvoiceButton>
          </div>
        </>
      ) : (
        <>
          <InvoiceContainer>
            <div className={style.copy_expence}>Line Item Details</div>
            <div className={style.table}>
              <div className={style.th}>
                <p
                  style={{ textAlign: "start" }}
                  className={style.tableContent}
                >
                  Candidate Name
                </p>
                <p
                  style={{ textAlign: "start" }}
                  className={style.tableContent}
                >
                  Billing Rate
                </p>
                <p
                  style={{ textAlign: "start" }}
                  className={style.tableContent}
                >
                  Working Days
                </p>
                <p
                  style={{ textAlign: "start" }}
                  className={style.tableContent}
                >
                  Max Working Days
                </p>
                <p
                  style={{ textAlign: "start" }}
                  className={style.tableContent}
                >
                  Amount
                </p>
                <p
                  style={{ textAlign: "start" }}
                  className={style.tableContent}
                >
                  Converted Amount
                </p>
              </div>
              <div>
                {isLoading && <p className={style.no_data}>Loading .....</p>}

                {!isLoading && !isError ? (
                  InvoiceDataById?.invoiceLineItemDetails?.length > 0 ? (
                    typeof data !== "string" &&
                    InvoiceDataById?.invoiceLineItemDetails?.map((item) => {
                      return (
                        <div className={style.td} key={item?.positionId}>
                          <p
                            style={{ textAlign: "start" }}
                            className={style.tableContent}
                          >
                            {item?.candidateName}
                          </p>
                          <p
                            style={{ textAlign: "start" }}
                            className={style.tableContent}
                          >
                            {item.billingRate}
                          </p>
                          <p
                            style={{ textAlign: "start" }}
                            className={style.tableContent}
                          >
                            {item.workingDays}
                          </p>
                          <p
                            style={{ textAlign: "start" }}
                            className={style.tableContent}
                          >
                            {item.maxWorkingDay}
                          </p>
                          <p
                            style={{ textAlign: "start" }}
                            className={style.tableContent}
                          >
                            {item.baseAmount}
                          </p>
                          <p
                            style={{ textAlign: "start" }}
                            className={style.tableContent}
                          >
                            {item.calculativeBaseAmount}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={style.no_data}>No Data Found</p>
                  )
                ) : null}
                <div className={style.total_invoice_amount_div}>
                  <span style={{ fontWeight: "600" }}>
                    Total Invoice Amount :
                  </span>{" "}
                  <span className={style.values}>
                    {InvoiceDataById?.totalInvoiceAmount}
                  </span>
                </div>
              </div>
            </div>
          </InvoiceContainer>

          <InvoiceContainer>
            <div className={style.Container}>
              <div className={style.col1}>
                <div className={style.heading}>GST Applicable</div>
                <div className={style.radioitem}>
                  <p className={style.values}>
                    {InvoiceDataById?.gstApplicable === true ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            </div>

            {InvoiceDataById?.gstApplicable ? (
              <>
                <div className={style.Container}>
                  <div className={style.col12}>
                    <p className={style.label}>Base Amount</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.baseAmountInvoice}
                    </p>
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>IGST</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.igst}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>CGST</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.cgst}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>SGST</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.sgst}
                    </p>
                  </div>
                </div>

                <div className={style.Container}>
                  <div className={style.col12}>
                    <p className={style.label}>GST(TOTAL)</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.totalGst}
                    </p>
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>Total Billed</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.totalBilled}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>TDS</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.tds}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>Other Charges</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.otherCharges}
                    </p>
                  </div>
                </div>

                <div className={style.Container}>
                  <div className={style.col3}>
                    <p className={style.label}>Discount</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.discount}
                    </p>
                  </div>
                  <div className={style.col3}>
                    <p className={style.label}>Amount to be received</p>
                    <p className={style.values}>
                      {InvoiceDataById && InvoiceDataById.amountToBeReceived}
                    </p>
                  </div>
                  <div className={style.col3}></div>
                  <div className={style.col3}></div>
                </div>
              </>
            ) : (
              <div className={style.Container}>
                <div className={style.col3}>
                  <p className={style.label}>Other Charges</p>
                  <p className={style.values}>
                    {InvoiceDataById && InvoiceDataById.otherCharges}
                  </p>
                </div>
                <div className={style.col3}>
                  <p className={style.label}>Discount</p>
                  <p className={style.values}>
                    {InvoiceDataById && InvoiceDataById.discount}
                  </p>
                </div>
                <div className={style.col3}>
                  <p className={style.label}>Amount to be received</p>
                  <p className={style.values}>
                    {InvoiceDataById && InvoiceDataById.amountToBeReceived}
                  </p>
                </div>
                <div className={style.col3}></div>
              </div>
            )}
          </InvoiceContainer>
          <InvoiceContainer>
            <div className={style.file_container}>
              <div className={style.heading}>Files Uploaded</div>
              {InvoiceDataById?.documentResponses?.map((file, index) => (
                <div key={index} title={file.documentURL.split('@')[1]} className={`${style.file_name}`}
                  onClick={() => handleDivClick(file.documentURL)}
                >
                  <span>{checkfileType(file.documentURL.substr(-3))}&nbsp;</span>
                  <p className={style.text}>{file.documentURL.split('@')[1]}</p>
                </div>
              ))}
            </div>
          </InvoiceContainer>

          <div className={style.lastBtn}>
            <InvoiceButton
              type={"cancel"}
              onClick={() => {
                setNextScreen("invoiceList");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: null,
                  page3: null,
                  page4: null,
                  active: "invoiceList",
                }));
              }}
            >
              Back
            </InvoiceButton>
            <InvoiceButton
              onClick={() => {
                setNextScreen("editInvoice");
                setPrevData((prevData) => ({
                  id: prevData?.invoiceId,
                  ...prevData,
                  page2: "editInvoice",
                  page3: null,
                  page4: null,
                  active: "editInvoice",
                  EditinvoiceId: prevData,
                  clientAcknowledgementStatus:
                    InvoiceDataById &&
                    InvoiceDataById.clientAcknowledgementStatus,
                  bankReferenceId:
                    InvoiceDataById && InvoiceDataById.bankReferenceId,
                  amountReceivedInBank:
                    InvoiceDataById && InvoiceDataById.amountReceivedInBank,
                  reimbursableExpenses:
                    InvoiceDataById && InvoiceDataById.reimbursableExpenses,
                }));
              }}
            >
              Edit
            </InvoiceButton>
          </div>
        </>
      )}
    </>
  );
};

export default ViewInvoice;
