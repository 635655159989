import React, { useEffect } from "react";
import SideBar from "../CommonComponents/Sidebar/SideBar";
import { ToastContainer } from "react-toastify";
import style from "./Dashboard.module.css"
import * as Img from "../../../assets/index";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDashboardCount } from "../../../Utils/axios/efAPI";
import { useQuery } from "react-query";

const Dashboard =()=>{
  useEffect(()=>{
    localStorage.setItem('cp',1);
  })
  const { data } = useQuery(
    ["getDashboardCount"],
    () => getDashboardCount()
  );
    const history = useHistory();

    const onInProgress =(statusId)=>{
        history.push('/ef_employeeExit',{statusId:Number(statusId)})
    }
    const onDone =(statusId)=>{
        history.push('/ef_employeeExit',{statusId:Number(statusId)});
    }
    return<div className={style.ATSNav}>
    <div className={style.mainSection}>
      <div style={{ width: "20%" }}>
        <SideBar />
      </div>
      <div className={style.ATScontainer}>
        <div className={style.flex}>
        <div className={style.card} onClick={()=>onInProgress(data?.[1]?.statusId)}
        onKeyDown={()=>onInProgress(data?.[1]?.statusId)}
        tabIndex="0">
          <img src={Img.inProgressActive} />
          <p>{data?.[1]?.count}</p>
          <p style={{ color: "gray" }}>In-Progress</p>
        </div>
        <div className={style.card} onClick={()=>onDone(data?.[0]?.statusId)}
        onKeyDown={()=>onDone(data?.[0]?.statusId)}
        tabIndex="0">
          <img src={Img.doneActive} />
          <p>{data?.[0]?.count}</p>
          <p style={{ color: "gray" }}>Done</p>
        </div>
      </div>
      </div>
    </div>
    <ToastContainer />
  </div>
}
export default Dashboard;