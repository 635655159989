import React, { useEffect, useRef, useState } from "react";
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
// import { filterData } from "../../helper/utils";
// import iconDropDown from "../../icons/icon-dropdown.png";
import "./AutoComplete.css";

function CustomAutoComplete({
    disabled,
    required,
    placeholder = "Search Something...",
    dataList,
    onSelect,
    keyToAccess = "name",
    onChange = () => null,
    classes,
    defaultValue,
    value,
    multiSelect,
    selectedValues,
    autocomplete
}) {
    const filterData = (searchKey, data) => {
        // if (!data) {
        //     return toast.error("List not found!");
        // }
        if (searchKey.length === 0) return data;
        let filteredData = [];
        data.forEach((element) => {
            if (JSON.stringify(Object.values(element)).toLowerCase().includes(searchKey.toLowerCase())) {
                filteredData.push(element);
            }
        });

        return filteredData;
    };
    const [filteredData, setFilteredData] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
    const [listOpen, setListOpen] = useState(false);
    const [keyId, setKeyId] = useState(null);

    useEffect(() => {
        if (multiSelect) {
            if (dataList[0]?.userId) {
                setKeyId("userId");
            } else if (dataList[0]?.teamId) {
                setKeyId("teamId");
            } else {
                setKeyId("id");
            }
        }
    }, [dataList]);

    const isChecked = (id) => {
        if (selectedValues.includes(id)) return true;
        return false;
    };

    const inputRef = useRef();
    const wrapperRef = useRef(null);

    let list = document.querySelector("#scroller");
    let scrollMeDown = () => null;
    let scrollMeUp = () => null;
    if (keyId === "userId") {
        if (activeSuggestionIndex > 4) {
            scrollMeDown = () => {
                list.scrollBy({ top: 60, left: 0 });
            };
            scrollMeUp = () => {
                list.scrollBy({ top: -55, left: 0 });
            };
        }
    } else {
        if (activeSuggestionIndex > 9) {
            scrollMeDown = () => {
                list.scrollBy({ top: 36, left: 0 });
            };
            scrollMeUp = () => {
                list.scrollBy({ top: -32, left: 0 });
            };
        }
    }

    const handleTextChange = (e) => {
        onChange(e);
        const filter = filterData(e.target.value, dataList);
        setFilteredData(filter);
        setActiveSuggestionIndex("");
        if (!listOpen) setListOpen(true);
    };

    const onValueSelect = (e, obj) => {
        if (!multiSelect) {
            setFilteredData([]);
            onSelect(obj);
            if (listOpen) setListOpen(!listOpen);
        } else {
            onSelect(obj);
        }
    };

    const keyCaptureHandler = (e) => {
        const dataLen = filteredData.length;
        setActiveSuggestionIndex(0);

        if (e.keyCode === 40) {
            scrollMeDown();
            setActiveSuggestionIndex(0);
            if (dataLen === 1) {
                setActiveSuggestionIndex(0);
            } else {
                const value = Number(activeSuggestionIndex + 1);
                if (value === dataLen) {
                    setActiveSuggestionIndex(value - 1);
                } else {
                    setActiveSuggestionIndex(value);
                }
            }
        }
        if (e.keyCode === 38) {
            scrollMeUp();
            if (activeSuggestionIndex === null) setActiveSuggestionIndex(0);
            if (dataLen === 1) {
                setActiveSuggestionIndex(0);
            } else {
                const value = Number(activeSuggestionIndex - 1);
                if (value === -1) {
                    setActiveSuggestionIndex(value + 1);
                } else {
                    setActiveSuggestionIndex(value);
                }
            }
        }

        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            setFilteredData([]);
            onSelect(filteredData[activeSuggestionIndex]);
            if (listOpen) setListOpen(!listOpen);
        }
    };

    const listOpenHandler = () => {
        if (listOpen) {
            setListOpen(true);
            setFilteredData([...dataList]);
        } 
        else {
            if (!dataList) {
                setFilteredData([]);
                return;
            }
            setFilteredData([...dataList]);
            setListOpen(true);
            setActiveSuggestionIndex(null);
            inputRef.current.focus();
        }
    };

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setListOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);
   

    return (
        <div className="AutoCompleteText" ref={wrapperRef}>
            <InputGroup  style={{ width:autocomplete}}>
                <Input
                    onChange={handleTextChange}
                    value={defaultValue || value}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    onFocus={() => setListOpen(true)}
                    onKeyDown={keyCaptureHandler}
                    disabled={disabled}
                    required={required}
                    innerRef={inputRef}
                    onClick={() => listOpenHandler()}
                />
                {/* <InputGroupText style={{ backgroundColor: "white" }} onClick={!disabled && listOpenHandler}>
                    <img src={iconDropDown} alt="" className={listOpen ? "inverted" : ""} />
                </InputGroupText> */}
            </InputGroup>
            {listOpen && (
                <ul className={"backdrop " + classes} id="scroller" style={{width:autocomplete=="90%"?"85%":autocomplete}}>
                    {filteredData.map((item, index) => {
                        let className;
                        if (index === activeSuggestionIndex) {
                            className = "suggestion-active";
                        }
                        return (
                            <div key={index}>
                                <li
                                    className={className}
                                    onMouseEnter={() => setActiveSuggestionIndex(index)}
                                    onMouseLeave={() => setActiveSuggestionIndex(null)}
                                    onClick={(e) => onValueSelect(e, item)}
                                >
                                    <Row>
                                        {multiSelect && (
                                            <Col xs="1">
                                                <input
                                                    type={"checkbox"}
                                                    checked={isChecked(item[keyId])}
                                                    style={{ width: "12px" }}
                                                    onChange={() => { }}
                                                />
                                            </Col>
                                        )}
                                        <Col xs="11">
                                            <h6>{item[keyToAccess]}</h6>
                                            <p>{item.email}</p>
                                            <p>{item.contact}</p>
                                        </Col>
                                    </Row>
                                </li>
                            </div>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}

export default CustomAutoComplete;
