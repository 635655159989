import * as XLSX from "xlsx";
import React from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import moment from "moment";

const DownloadClientContractList = ({data }) => {
       
  function generateExcelData(data) {

    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Contract Type",
        "Client Name",
        "Sales Representative",
        "Business Type",
        "Engagement Status",
        "Executed By",
        "Contact Grouping",
        "Contract Terms (Month)",
        "Applicable Status",
        "Contract Status",
        "Contract Start Date",
        "Contract End Date",
        "Contract Reminder Date",
        "Resource Name",
        "File URL",
        "File Folder",
        "Ongoing Contract",
        "Notes"
      
      ], // Header row
      ...data?.map((val) => [
        val.contractType,
        val?.clientName,
        val?.salesRepresentative,
        val?.businessType,
        val?.engagementStatus,
        val?.executedBy,
        val?.contractGrouping,
        val?.contractTerm,
        val?.applicableStatus,
        val?.clientContractStatus,
        val.startDate&& moment(val.startDate).format("DD MM YYYY"),
        val.endDate&& moment(val.endDate).format("DD MM YYYY"),
        val.reminderDate&& moment(val.reminderDate).format("DD MM YYYY"),
         val?.resourceName,
         val?.fileUrl,
         val?.folderUrl,
         val?.ongoing,
         val?.notes?"YES":"NO"
      ])
       // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Client Contract List${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);

    downloadExcelFile(workbook);
  }

  return (
    <div>
      <FiDownload
        style={{ verticalAlign: "baseline" }}
        onClick={() => handleDownloadButtonClick(data)}
      />
    </div>
  );
};

export default DownloadClientContractList;
