import React, { useEffect, useState } from "react";
import SideBar from "../sidebar/SideBar";
import style from "./PartnerNavigation.module.css";
import ProgressBar from "../progressbar/ProgressBar";
import "react-toastify/dist/ReactToastify.css";
import PartnerList from "../pageLayout/PartnerList/PartnerList";
import ProjectLead from "../pageLayout/ProjectLead/ProjectLead";
import CreatePartner from "../pageLayout/CreatePartner/CreatePartner";
import CreatePartnerByLead from "../pageLayout/CreatePartnerByLead/CreatePartnerByLead"
import CreateList from "../pageLayout/CreateList/CreateList";
import ViewPartner from "../pageLayout/ViewPartner/ViewPartner";
import EditPartner from "../pageLayout/EditPartner/EditPartner";
import OnBoarding from "../pageLayout/OnBoarding/OnBoarding";
import OnBoardingStatus from "../pageLayout/OnBoardingStatus/OnBoardingStatus";
import ViewOnBoardingStatus from "../pageLayout/OnBoardingStatus/ViewOnBoardingStatus";
import Agreement from "../pageLayout/Agreement/Agreement";
import TDS from "../pageLayout/TDS/TDS";
import Specialization from "../pageLayout/Specialization/Specialization";
import ViewCreatePartner from "../pageLayout/ViewCreatePartner/ViewCreatePartner";
import ViewCreateProject from "../pageLayout/ViewCreateProject/ViewCreateProject";
import EditViewPartner from "../pageLayout/ViewCreatePartner/EditViewPartner";
import EditViewProject from "../pageLayout/ViewCreateProject/EditViewProject";
import ChangeOnBoardingStatus from "../pageLayout/ChangeOnBoardingStatus/ChangeOnBoardingStatus";
import ReportCard from "../pageLayout/report/reportCard/ReportCard";
import EmpanelmentReport from "../pageLayout/report/empanelmentReport/EmpanelmentReport";
import ProjectLeadReport from "../pageLayout/report/projectLeadreport/ProjectLeadReport";
import CreateVendor from "../pageLayout/CreateVendor/CreateVendor";
import ViewVendor from "../pageLayout/ViewVendor/ViewVendor";
import EditVendor from "../pageLayout/EditVendor/EditVendor";
import ViewCreateVendor from "../pageLayout/ViewCreateVendor/ViewCreateVendor";
import ViewEditVendor from "../pageLayout/ViewEditVendor/ViewEditVendor";
import CreateClientList from "../pageLayout/ClientManagement/CreateClient/CreateClientList";
import ViewClientList from "../pageLayout/ClientManagement/ViewClient/ViewClientList";
import ClientList from "../pageLayout/ClientManagement/ClientList/ClientList";
import Milestones from "../pageLayout/ClientManagement/Milestones/Milestones";
import ActivePartnerList from "../pageLayout/ActivePartnerList/ActivePartnerList";
import ProjectManagement from "../pageLayout/ProjectManagement/ProjectManagement";
import CreateProject from "../pageLayout/ProjectManagement/component/CreateProject/CreateProject";
import ViewProject from "../pageLayout/ProjectManagement/component/ViewProject/ViewProject";
export default function PartnerNavigation() {
  const [nextScreen, setNextScreen] = useState(
    sessionStorage.getItem("nextScreen")
      ? sessionStorage.getItem("nextScreen")
      : "PartnerList"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [prevData, setPrevData] = useState(
    sessionStorage.getItem("prevData")
      ? JSON?.parse(sessionStorage.getItem("prevData"))
      : {
        sidebar: "PartnerList",
        page: "PartnerList",
        page1: "PartnerList",
        page2: null,
        page3: null,
        page4: null,
        page5: null,
        page6: null,
        active: "PartnerList",
      }
  );

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("prevData");
      sessionStorage.removeItem("nextScreen");
    };
  }, []);

  const sidebarHandler = () => {
    sessionStorage.setItem("nextScreen", nextScreen);
    JSON?.stringify(
      sessionStorage.setItem("prevData", JSON?.stringify(prevData))
    );

    switch (nextScreen) {
      case "PartnerList":
        return (
          <PartnerList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "projectLead":
        return (
          <ProjectLead
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "CreatePartner":
        return (
          <CreatePartner
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "CreatePartnerByLead":
        return (
          <CreatePartnerByLead
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "CreateList":
        return (
          <CreateList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "CreateVendor":
        return (
          <CreateVendor
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "CreateClientList":
        return (
          <CreateClientList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewPartner":
        return (
          <ViewPartner
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewVendor":
        return (
          <ViewVendor
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewCreateVendor":
        return (
          <ViewCreateVendor
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "clientList":
        return (
          <ClientList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "viewClient":
        return (
          <ViewClientList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "milestones":
        return (
          <Milestones
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewEditVendor":
        return (
          <ViewEditVendor
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "EditPartner":
        return (
          <EditPartner
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "EditVendor":
        return (
          <EditVendor
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "editClient":
        return (
          <CreateClientList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "OnBoarding":
        return (
          <OnBoarding
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "OnBoardingStatus":
        return (
          <OnBoardingStatus
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewOnBoardingStatus":
        return (
          <ViewOnBoardingStatus
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "Agreement":
        return (
          <Agreement
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "TDS":
        return (
          <TDS
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "Specialization":
        return (
          <Specialization
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewCreatePartner":
        return (
          <ViewCreatePartner
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "ViewCreateProject":
        return (
          <ViewCreateProject
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "EditViewPartner":
        return (
          <EditViewPartner
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "EditViewProject":
        return (
          <EditViewProject
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "ChangeOnBoardingStatus":
        return (
          <ChangeOnBoardingStatus
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "report":
        return (
          <ReportCard
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "empanelmentReport":
        return (
          <EmpanelmentReport
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "partnerLeadReport":
        return (
          <ProjectLeadReport
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "activePartnerDeployment":
        return (
          <ActivePartnerList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "projectManagement":
        return (
          <ProjectManagement
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "createProject":
      case "EditProject":
        return (
          <CreateProject
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "ViewProject":
        return (
          <ViewProject
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      default:
    }
  };

  const setCurrScreen = (val) => {
    setNextScreen(val);
  };

  return (
    <div className={style.ATSNav}>
      {/* <Header /> */}
      <div className={style.mainSection}>
        <div
          style={{
            width: "20%",
            backgroundColor: "#172a3e",
          }}
        >
          <SideBar
            sidebarHandler={setCurrScreen}
            nextScreen={nextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <div className={style.ATScontainer}>
          <ProgressBar prevData={prevData} />
          {sidebarHandler()}
        </div>
      </div>
    </div>
  );
}
