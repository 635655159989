import React from "react";
import style from "./ViewQuestionnaireBySkill.module.css"
import { ViewQuestionnaireBySkillsCom } from "./component/ViewQuestionnaireBySkillsCom";
import QuestionnaireSidebar from "../sidebar/SideBar";
export const ViewQuestionnaireBySkill =()=>{
    return(<>
       <div className={style.mainSection}>
         <QuestionnaireSidebar />
         <ViewQuestionnaireBySkillsCom />
       </div>
     </>)
 }


