import React from "react";
import { useMutation } from "react-query";
import Loader from "../../../../GenericComponent/Loader/Loader";
import { getEditById, updateNote } from "../../../../Utils/axios/api";
import style from "./NoteCand.module.css";
import moment from "moment";
import { employeeId } from "../../../../Utils/constants";
const NoteCandidate = ({
  showNote,
  setShowNote,
  setFormData,
  formData,
  showUpdate,
  showNotePosition,
  refetchData,
}) => {
  const [disabled, setDisabled] = React.useState(false);
  const updateNoteMutation = useMutation(updateNote, {
    onSuccess: () => {
      setDisabled(false);
    },
  });
  const notesPositionMutation = useMutation(getEditById, {
    onSuccess: () => {
      setDisabled(false);
      refetchData();
    },
  });
  const onUpdateHandler = () => {
    updateNoteMutation.mutate({
      id: formData?.id,
      note: formData?.note,
      empId: employeeId,
    });
    setDisabled(true);
    setShowNote(!showNote);
  };
  const onAddNotesPosition = () => {
    notesPositionMutation.mutate({
      maxBudget: parseFloat(formData.maxBudget),
      minBudget: parseFloat(formData?.minBudget),
      maxExperience: parseFloat(formData?.maxExperience),
      minExperience: parseFloat(formData?.minExperience),
      positionId: formData?.id,
      roleId: formData?.role_Id,
      coreSkill_Id: formData?.coreSkillId,
      positionDescription: formData.positionDescription,
      mandatorySkill_id: formData?.mandatorySkill?.map((i) => {
        return i.id;
      }),
      preferedSkill_id: formData?.preferredSkill?.map((e) => {
        return e.id;
      }),
      educationId: formData?.education.map((i) => {
        return i.id;
      }),
      clientId: formData?.clientId,
      positionType: formData?.positionType,
      noOfPositions: formData?.noOfPositions,
      positionOwnerId: formData?.positionOwnerId,
      positionOpenedOn: moment(formData?.positionOpenedOn).format(),
      priorityId: formData?.priorityId,
      workModeId: formData?.workModeId,
      employmentModeId: formData?.employmentModeId,
      projectDuration: formData?.projectDuration,
      note: formData?.note,
    });
    setDisabled(true);
    setShowNote(!showNote);
  };
  return (
    <>
      {disabled ? <Loader /> : null}
      <div className={style.main_div}>
        <textarea
          type="text"
          className={style.note_textarea}
          value={formData?.note}
          onChange={(e) => setFormData({ ...formData, note: e.target.value })}
        />
      </div>
      <div className={style.last_btn}>
        <button
          className={style.cancel_btn}
          onClick={() => {
            setShowNote(!showNote);
          }}
        >
          Cancel
        </button>

        {showUpdate && (
          <button className={style.create_btn} onClick={onUpdateHandler}>
            Update
          </button>
        )}
        {showNotePosition && (
          <button className={style.create_btn} onClick={onAddNotesPosition}>
            Update
          </button>
        )}
      </div>
    </>
  );
};

export default NoteCandidate;
