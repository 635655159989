import * as XLSX from "xlsx";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
// import { getEmployeeListDownload } from "../../../../../Utils/axios/InductionApi";
import axios from 'axios'
import moment from "moment";
import { useQuery } from "react-query";
import constants from "../../../Utils/constants";


const PoolResourcesDownload = ({ total,allData }) => {
    const baseUrl = constants.apiBaseUrlResource;

    const [reportStatus, setReportStatus] = useState(true);
    const [data, setData] = useState(null)


    // const [allData, setAllData] = useState(
    //     {
    //         action: [1],
    //         clientId: [],
    //         employeeId: [],
    //         skill: [],
    //         salaryRange: [],
    //         partners: [],
    //         startDateMonth: [],
    //         endDateMonth: [],
    //         employeeStatus: [null, null],
    //         quaterlyEndOfDeployment: [],
    //         quaterlyDeployment: [],
    //         handleFilter: false,
    //         partnerId: [],
    //         salesPersonId: [],
    //         isForcedAddedToPool: null
    //     }
    // );


    function generateExcelData(data) {

        const sheetName = "SheetPoolResources";
        const sheetData = [
            [
                "Code",
                "Name",
                "Sales Person",
                "project",
                "Exp",
                "Salary Range",
                "Technology",
                "Start Date",
                "End Date",
                "Last Comment Date",
                "Partner Name",
                "Status"
            ], // Header row
            ...data?.map((val) => [
                val.empCode,
                val?.empName,
                val?.salesPersonName,
                val?.projectName,
                `${~~(val.experience / 12)} Y - ${val.experience % 12} M`,
                val?.salaryRange,
                val?.skillName,
                val.allocationdate && moment(val.allocationdate).format("DD-MM-YYYY"),
                val.deallocationdate && moment(val.deallocationdate).format("DD-MM-YYYY"),
                val.currentCommentDate && moment(val.currentCommentDate).format("DD-MM-YYYY"),
                val?.partnerName,
                val.allocationTypeName
            ])
            // Data rows
        ];

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        return workbook;
    }
    function downloadExcelFile(workbook) {
        const fileExtension = ".xlsx";
        const fileName = `Users${fileExtension}`;

        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });

        const blob = new Blob([excelBuffer], {
            type: "application/octet-stream",
        });
        saveAs(blob, fileName);
    }


    const handleDownloadButtonClick1 = () => {
        axios
            .post(
                baseUrl +
                "/getAddResourceOffshore?" +
                "limit=" +
                total +
                "&page=" +
                1,
                allData,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        Authorization: null,
                    },
                }
            )
            .then((res) => {
                // setData(res.data.responseData.resources);
                handleDownloadButtonClick(res.data.responseData.resources)
            })
            .catch(() => {
            });
    };


    function handleDownloadButtonClick(data) {
        if (!data) {
            return;
        }
        const workbook = data && generateExcelData(data);

        downloadExcelFile(workbook);
    }

    return (
        <div>
            <FiDownload
                style={{ verticalAlign: "baseline" }}
                onClick={() => handleDownloadButtonClick1(data)}
            />
        </div>
    );
};

export default PoolResourcesDownload;
