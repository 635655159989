import React, { useEffect, useState } from "react";
import SideBar from "../CommonComponents/Sidebar/SideBar";
import style from "./EmployeeExit.module.css";
import InvoiceContainer from "../../Employee_Induction/CommonComponent/InvoiceContainer/InvoiceContainer";
import FilterButton from "../CommonComponents/Filter/Filter";
import Filter from "../Filter/Filter";
import Pagination from "../CommonComponents/Pagination";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import { getDashboardEmployee, getEFCountByFnfStatus, getEmployeeListDropdown } from "../../../Utils/axios/efAPI";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import FilterSearchSelect from "../CommonComponents/FilterSearch/FilterSearchSelect";
import { CgNotes } from "react-icons/cg";
import ConfirmPopup from "../../userManegment/pageLayout/viewUser/ConfirmPopup";
const EmployeeExit = () => {
  
  const [filterApplied, setFilterApplied] = useState(false);
  const location = useLocation();
  const statusId = location.state?.statusId;
  const [currentPage, setCurrentPage] = useState();
  useEffect(()=>{
    setCurrentPage(localStorage.getItem('cp'));
  })
  const [filterData, setFilterData] = useState({
    empId: "",
    deptId: "",
    deptName: "",
    statusId: statusId ? statusId : "",
    month: "",
    year: "",
    fnfStatusId: "",
  });
  const [data, setData] = useState(filterData)
  const [showFilter, setShowFilter] = useState(false);
  const [empListDropdown, setEmpListDropdown] = useState([])
  const [showNotes, setShowNotes] = useState(false)
  const [notes, setNotes] = useState("");

  const { data: employees, } = useQuery(["getDashboardEmployee", { currentPage, data }], () => getDashboardEmployee(10, currentPage, data))
  const { data: employeeList } = useQuery(["getEmployeeListDropdown", { currentPage, data }], () => getEmployeeListDropdown())
  const { data: fnfStatusCount } = useQuery(
    ["getCountByFnfStatus"],
    () => getEFCountByFnfStatus(),
    {}
  );
  useEffect(() => {
    setEmpListDropdown(() => {
      return employeeList;
    })
  }, [employeeList]);
  const filterUpdateFuc = () => {
    setCurrentPage(1);
    localStorage.setItem('cp',1);
    setData(filterData);
    setShowFilter(false);
    setFilterApplied(true);
  };
  const filterClearAllFuc = () => {
    setData("");
    setShowFilter(false);
    setFilterApplied(false);
    setFilterData((preData) => ({
      ...preData,
      empId: "",
      deptId: "",
      statusId: "",
      month: "",
      year: "",
      fnfStatusId: "",
    }));
  };
  const handlePageChange = (page, status) => {
    if (status === "next") {
      localStorage.setItem('cp',Number(page)+1)
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      localStorage.setItem('cp',page-1)
      setCurrentPage(page - 1);
    }
  };

  function formatDate(dateStr) {
    // Parse the date string into a Date object (in UTC)
    let date = new Date(dateStr);
    date.setHours(date.getHours());
    date.setMinutes(date.getMinutes());
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let day = date.getDate();
    let month = months[date.getMonth()];
    let year = date.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year}`;
  }

  return (
    <div className={style.mainSection}>
      <div style={{ width: "20%" }}>
        <SideBar />
      </div>
      <div className={style.rightSide}>
        <div className={style.upper}>
          <div className={style.countI}>
            <span>Employee Exit </span>
            <span style={{ width: "14%", position: "absolute", left: "25rem" }}>
              <p className={style.count}>
                <span
                  className={style.backColor1}
                  style={{ fontSize: "1.235rem", fontWeight: "500" }}
                >
                  {employees?.totalCount}
                </span>
              </p>
            </span>
          </div>
          <FilterButton
            setShowFilter={setShowFilter}
            filterData={filterData}
          />
          <div style={{ marginRight: "26px" }}>
            <FilterSearchSelect
              placeholder="Select Employee"
              value={data?.empId}
              options={empListDropdown}
              onChange={(e) => setData({ ...data, empId: e.value })}
              onClose={() => setData({ ...data, empId: null })}
            />
          </div>
          {/* <div className={style.search_bar_div}>
              <input
                className={style.search_bar_input}
                // value={inputData}
                placeholder="Please Enter Emp Code / Name"
                // onChange={(e) => setInputData(e.target.value)}
              />
              <div
            //    onClick={handleSearch}
               className={style.search_icon}>
                <img
                  src={SearchIcon}
                  alt="search_icon"
                  className={style.search_icon_img}
                />
              </div>
            </div> */}
        </div>
        <div className={style.fnf_count_container}>
          {fnfStatusCount?.map(item => (
            <p className={style.empDetails}>{item.label} FnF : <span className={style.countCircle}> {item.count}</span></p>)
          )}
        </div>
        <div className={style.invoicecontainer} style={{ overflowX: "scroll" }}>
          {/* <InvoiceContainer> */}
          <div className={style.table} >
            <div className={style.th}>
              <p >Emp Code</p>
              <p >Emp Name</p>
              {/* <p >Designation</p> */}
              <p >Resignation Date</p>
              <p >Planned <br />Exit Date</p>
              <p >LWD</p>
              <p >Exit Status</p>
              <p >F&F Due <br />Date</p>
              <p >FnF Status</p>
              <p >Delay By</p>
              <p >Exit Note</p>
            </div>
            <div>
              {/* {!isLoading && !isError ? ( */}
              {/* data?.empDetails?.length > 0 ? ( */}
              {/* typeof data?.empDetails !== "string" && */}
              {/* data?.empDetails?.map((item, index) => { */}
              {/* return ( */}

              {employees?.dashboardEmployeeResponses?.map(emp => {
                return <div className={style.td} key={emp.empId}>
                  <NavLink
                    to={`/ef_employeeExit/${emp.empId}/employeeDetails`}
                  >
                    <p
                      className={`${style.tableContent1} ${style.link}`}
                    >
                      {emp.empCode}
                    </p>
                  </NavLink>
                  <p title={emp?.empName?.length > 22 ? emp.empName : null}>{emp.empName}</p>
                  {/* <p>{emp.designation}</p> */}
                  <p>{emp.resignationDate ? formatDate(emp.resignationDate) : null}</p>
                  <p>{emp.exitPlanDate ? formatDate(emp.exitPlanDate) : null}</p>
                  <p>{emp.lastWorkingDay ? formatDate(emp.lastWorkingDay) : null}</p>
                  <p>{emp.exitStatus}</p>
                  <p>{emp.fnfDueDate ? formatDate(emp.fnfDueDate) : null}</p>
                  <p>{emp.fnfStatus}</p>
                  <p>{emp.delayBy}</p>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowNotes(true);
                      setNotes(emp?.exitComment);
                      // setEmpId(e?.empId);
                    }}>
                    {emp?.exitComment && <CgNotes style={{ color: "#289e98" }} />}
                  </p>
                </div>
              })}
            </div>
          </div>
          {/* <>
            <Filter
              filterData={filterData}
              setFilterData={setFilterData}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              firstCard="Department Filter"
              secondCard="Status"
              filterUpdateFuc={filterUpdateFuc}
              filterClearAllFuc={filterClearAllFuc}
            />
          </> */}
          {/* </InvoiceContainer> */}
        </div>
        <div className={style.pagination}>
          {console.log(currentPage)}
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pagesCount={Math.ceil(employees?.totalCount / 10)}
            color="##855ea1"
            color2="white"
          />
        </div>

      </div>
      <Filter
        filterData={filterData}
        setFilterData={setFilterData}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        firstCard="Department Filter"
        secondCard="Exit Status"
        thirdCard="Month & Year"
        fourthCard="FnF Status"
        filterUpdateFuc={filterUpdateFuc}
        filterClearAllFuc={filterClearAllFuc}
        setFilterApplied={setFilterApplied}
        filterApplied={filterApplied}
      />
      <ConfirmPopup open={showNotes}>
        <div className={style.confirmPopup}>
          <div>
            <p>{notes}</p>
          </div>
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <button
              className={style.delete}
              onClick={() => {
                setShowNotes(false)
                setNotes("")
              }}
            >
              Done
            </button>

          </div>
        </div>
      </ConfirmPopup>
    </div>

  );
};

export default EmployeeExit;
