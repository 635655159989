import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { notifyError, notifySuccess } from '../../../../utils/notify';
import { getAllPriorityList } from './../../../../../../Utils/axios/api';
import { useQuery } from "react-query";
import style from "./CandidatePriorityModal.module.css";

const CandidatePriorityModal = ({ isOpen, onClose, candidateId, priorityId, refetchReport, refetchCandidateData, status }) => {
    const apiKey = process.env.REACT_APP_ATS_APP_URL;
    const [selectedOption, setSelectedOption] = useState(null);
    const [filter, setFilter] = useState({ priority: null });
    useEffect(() => {
        setFilter({ priority: priorityId });
        if (priorityId) {
            const selected = priorityOptions.find(option => option.value === priorityId);
            setSelectedOption(selected ? selected.label : null);
        }
    }, [priorityId]);

    const { data: getAllPriorityData } = useQuery(
        ["getAllPriorityList"],
        () => getAllPriorityList()
    );

    const priorityOptions =
        getAllPriorityData?.length > 0
            ? getAllPriorityData.map(e => ({ value: e?.id, label: e?.name }))
            : [];

    const submitPriority = async () => {
        if (!filter.priority) {
            notifyError("Please select a priority");
            return;
        }

        try {
            const apiUrl = `${apiKey}/add-candidate-priority?candidateId=${candidateId}&priorityId=${filter.priority}`;
            const response = await axios.post(apiUrl);

            if (response.status === 200) {
                notifySuccess("Priority submitted successfully");
                onClose();
                if (status === "PHR no status") {
                  refetchReport();
                } else if (status === "candidate profile") {
                  refetchCandidateData();
                }

                setFilter({ priority: null });
                setSelectedOption(null);
            } else {
                notifyError("Failed to submit priority");
                setFilter({ priority: null });
                setSelectedOption(null);
            }
        } catch (error) {
            notifyError("Error submitting priority");
            setFilter({ priority: null });
            setSelectedOption(null);
        }
    };

    const handleButtonClick = (value, label) => {
        setFilter({ priority: value });
        setSelectedOption(label);
    };

    return (
        <div className={`${style.modal} ${isOpen ? style.open : style.closed}`}>
            <div className={style['modal-content']}>
                <span className={style.close} onClick={() => {
                    onClose();
                    setFilter({ priority: null });
                    setSelectedOption(null);
                }}>&times;</span>
                <div style={{ marginLeft: '10px', marginBottom: '20px' }}>
                    <p style={{ fontWeight: '700' }}>Select Priority</p>
                </div>

                <div className={style.s1} style={{ width: '16rem' }}>
                    {priorityOptions.map((option) => (
                        <button
                            key={option.value}
                            className={`${style.priorityButton} ${filter.priority === option.value ? style.selected : ''}`}
                            onClick={() => handleButtonClick(option.value, option.label)}
                            style={filter.priority === option.value ? {
                                backgroundColor: option.label === 'Urgent' ? 'darkred' :
                                    option.label === 'High' ? 'red' :
                                        option.label === 'Medium' ? 'blue' :
                                            option.label === 'Low' ? 'grey' : 'white',
                                color: 'white'
                            } : {}}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
                <button className={style['submit-button']} onClick={submitPriority}>
                    Submit
                </button>
            </div>
        </div>
    );
};

export default CandidatePriorityModal;
