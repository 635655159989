export const customStylesforApp = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    margin: "4px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),
  placeholder: () => ({
    marginTop:"-30px"
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#562679",
      color:"white"
    },
    backgroundColor: state.isSelected ? "#855ea1" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

export const customStyles = {
  control: (style, state) => ({
      ...style,
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: state.isDisabled ? "#999" : "#f5f6fa", 
      border: "none",
      margin: "8px 1.5% 0px 1.7%",
      minHeight: "38px",
      outline: "none",
      width: "100%",
      cursor: "pointer",
  }),
  placeholder: () => ({
      display: "none",
  }),
  clearIndicator: (base) => ({
      ...base,
      display: "none",
  }),
  option: (provided, state) => ({
      ...provided,
      "&:hover": {
          backgroundColor: "rgba(50, 205, 50, 0.5)",
      },
      backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  menu: (provided) => ({
      ...provided,
      zIndex: "999",
  }),
};

export const inlineCustomStylesforApp = {
  control: (style, state) => ({
    ...style,
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    outline: "none",
    // width: "200px",
    cursor: "pointer",
    height: "5px",
    width: "100px"
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    
    "&:hover": {
      backgroundColor: "#562679",
    },
    backgroundColor: state.isSelected ? "#562679" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};
