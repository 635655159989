
import ClientContractList from "../Pages/ClientContract/pages/ClientContractList";
import ClientContractEdit from "../Pages/ClientContract/pages/ClientContractEdit";
import ClientContractView from "../Pages/ClientContract/pages/ClientContractView";
import ClientContractListAll from "../Pages/ClientContract/pages/ClientContractListAll";
import { path } from "./PathNames";
import ClientContractListIms from "../Pages/ClientContract/pages/ClientContractListIms";
import ClientContractListMissingInprogress from "../Pages/ClientContract/pages/ClientMissingInprogress";
import MissingInprogressContractView from "../Pages/ClientContract/pages/MissingInprogressContractView";

// function ClientContractRoute() {
//   return (
//     <Route>
//       <Route exact path={path.cc_create} component={ClientContractEdit} />
//       <Route exact path={path.cc_edit} component={ClientContractEdit} />
//       <Route exact path={path.cc_viewById} component={ClientContractView} />
//       <Route exact path={path.cc_list} component={ClientContractList} />
//       <Route exact path={path.cc_listById} component={ClientContractListAll} />
//       <Route
//         exact
//         path={path.cc_contract_ims}
//         component={ClientContractListIms}
//       />
//     </Route>
//   );
// }


const  ClientContractRoute = [
  {
    path: path.cc_create,
    component: ClientContractEdit
  },

  {
    path: path.cc_edit,
    component: ClientContractEdit
  },

  {
    path: path.cc_viewById,
    component: ClientContractView
  },

  {
    path: path.cc_listMissingInprogressById,
    component: MissingInprogressContractView
  },
  {
    path: path.cc_list,
    component: ClientContractList
  },

  {
    path: path.cc_listById,
    component: ClientContractListAll
  },
  

  {
    path: path.cc_contract_ims,
    component: ClientContractListIms
  },

  {
    path: path.cc_missing_inprogress,
    component: ClientContractListMissingInprogress
  },
  ]
export default ClientContractRoute;

