import React, { useEffect, useState } from "react";
import Header from "../../../GenericComponent/header/CommonHeader";
import SideBar from "../sidebar/SideBar";
import style from "./ClientContractList.module.scss";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import {
  getAllUpcomingEndingContract,
  getClientListFilter,
  getEmployeeList,
  getEngagementList,
} from "../../../Utils/axios/clientContractApi";
import { useQuery } from "react-query";
import Pagination from "../../ClientQuestionnaire/CommonComponent/Pagination";
import constants, {
  checkIfEmpty,
  clientContractCode,
  employeeId,
  renderTitle,
  renderValue,
} from "../../../Utils/constants";
import CommonDropdown from "../../../GenericComponent/ui/CommonDropdown";
import { path } from "../../../routes/PathNames";
import * as Img from "../../../assets/index";
import pdf from "../../../assets/pdf.png";
import useValidationAccess from "../../custumHooks/useValidationAccess";
import axios from "axios";

function ClientContractList() {
  const history = useHistory();
  const location = useLocation();
  const [guideUrl, setGuideUrl] = useState("");
  const { checkValidateAccess } = useValidationAccess();

  const [filter, setFilter] = useState(
    location?.state?.cclFilter
      ? location?.state?.cclFilter
      : {
          clientId: null,
          statusId: null,
          limit: 10,
          page: 1,
          arrow: false,
        }
  );
  const baseUrl = constants.apiBaseUrl;

  const getUserInfo = (empId) => {
    axios
      .get(baseUrl + `/getUserApplications?userId=${empId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        let data = res.data.responseData?.applications;
        data.map((item) => {
          if (item.applicationId === 13) {
            setGuideUrl(item.guideUrl);
          }
        });
      })
      .catch((err) => {
        return err;
      });
  };

  const { data: getAllUpcomingEndingContracts } = useQuery(
    ["getAllUpcomingEndingContract", filter],
    () =>
      getAllUpcomingEndingContract(
        filter?.clientId,
        filter?.statusId,
        filter?.limit,
        filter?.page,
        filter?.salesRepresentativeEmpId,
        filter?.arrow
      ),
    {}
  );
  useEffect(() => {
    getUserInfo(employeeId);
  }, []);

  useEffect(() => {
    setFilter({ ...filter, page: 1 });
  }, [
    filter?.clientId,
    filter?.statusId,
    filter?.limit,
    filter?.salesRepresentativeEmpId,
    filter?.arrow,
  ]);

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setFilter((prev) => ({ ...prev, page: filter.page + 1 }));
    } else if (status === "prev") {
      setFilter((prev) => ({ ...prev, page: filter.page - 1 }));
    }
  };
  const getStatusColor = (status) => {
    if (status === "Active") {
      return "green";
    } else if (status === "Inactive") {
      return "red";
    } else {
      return null;
    }
  };

  const { data: getClientListOptions } = useQuery(
    ["getClientListFilter"],
    () => getClientListFilter(),
    {}
  );

  const { data: getEngagementListOptions } = useQuery(
    ["getEngagementList"],
    () => getEngagementList(),
    {}
  );

  const { data: getEmployeeListOptions } = useQuery(
    ["getEmployeeList"],
    () => getEmployeeList(),
    {}
  );

  const handleGuideClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <div style={{ width: "20vw" }}>
          <SideBar />
        </div>
        <div style={{ width: "80vw", padding: "1rem" }}>
          <div className={style.titleBtn}>
            <div className={style.titleBtnLeft}>
              <p className={style.title}>Expiring Contracts</p>
              <button>{getAllUpcomingEndingContracts?.totalDataCount}</button>
            </div>

            <div className={style.titleBtnRight}>
              {/* filter 1 */}
              <div
                className={style.guideIcon}
                title="Guide"
                onClick={() => handleGuideClick(guideUrl)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleGuideClick(guideUrl);
                  }
                }}
                tabIndex={0}
              >
                <img src={pdf} alt="guide" />
              </div>
              <div className={style.dropdownContainer16}>
                <CommonDropdown
                  color="#562679"
                  placeholder="Sales Representative"
                  value={checkIfEmpty(filter?.salesRepresentativeEmpId, {
                    id: filter?.salesRepresentativeEmpId,
                    label: filter?.salesRepresentative,
                  })}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      salesRepresentativeEmpId: e.id,
                      salesRepresentative: e.label,
                    });
                  }}
                  options={getEmployeeListOptions}
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      salesRepresentativeEmpId: null,
                      salesRepresentative: null,
                    })
                  }
                />
              </div>
              {/* filter 2 */}
              <div className={style.dropdownContainer16}>
                <CommonDropdown
                  color="#562679"
                  placeholder="Client"
                  value={checkIfEmpty(filter?.clientId, {
                    id: filter?.clientId,
                    label: filter?.clientName,
                  })}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      clientId: e.id,
                      clientName: e.label,
                    });
                  }}
                  options={getClientListOptions}
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      clientId: null,
                      clientName: null,
                    })
                  }
                />
              </div>
              {/* filter 3 */}
              <div className={style.dropdownContainer8}>
                <CommonDropdown
                  color="#562679"
                  value={checkIfEmpty(filter?.statusId, {
                    id: filter?.statusId,
                    label: filter?.statusName,
                  })}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      statusId: e.id,
                      statusName: e.label,
                    });
                  }}
                  options={getEngagementListOptions}
                  placeholder="Status"
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      statusId: null,
                      statusName: null,
                    })
                  }
                />
              </div>
              {checkValidateAccess(clientContractCode.CreateContract) && (
                <button
                  onClick={() =>
                    history.push({
                      pathname: path.cc_create.replace(
                        ":name",
                        "expiring-contracts"
                      ),
                      state: {
                        ...location.state,
                        page1: "Expiring Contracts",
                        cclFilter: filter,
                        selectedClient: false,
                        clientId: false,
                      },
                    })
                  }
                  title="Create Contract"
                >
                  +
                </button>
              )}
            </div>
          </div>
          <div className={style.table}>
            <div className={style.th}>
              <p>Client Name</p>
              <p>Sales Representative</p>
              <p>
                Contract End Date
                <span
                  className={style.arrow}
                  onClick={() => setFilter({ ...filter, arrow: !filter.arrow })}
                >
                  {filter?.arrow ? (
                    <img src={Img.upArrow} alt="upArrow" />
                  ) : (
                    <img src={Img.downArrow} alt="downArrow" />
                  )}
                </span>
              </p>
              <p>Contract Status</p>
            </div>
            <div>
              {getAllUpcomingEndingContracts &&
                (getAllUpcomingEndingContracts?.clientDataResponse?.length >
                0 ? (
                  getAllUpcomingEndingContracts.clientDataResponse.map(
                    (item) => {
                      return (
                        <div
                          className={style.td}
                          key={item?.id}
                          style={{
                            position: "relative",
                          }}
                        >
                          <p
                            className={style.link}
                            onClick={() => {
                              history.push({
                                pathname: path.cc_listById
                                  .replace(":id", item?.clientId)
                                  .replace(":name", "expiring-contracts"),
                                state: {
                                  ...location.state,
                                  page1: "Expiring Contracts",
                                  cclFilter: filter,
                                  selectedClient: item,
                                },
                              });
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                history.push({
                                  pathname: path.cc_listById
                                    .replace(":id", item?.clientId)
                                    .replace(":name", "expiring-contracts"),
                                  state: {
                                    ...location.state,
                                    page1: "Expiring Contracts",
                                    cclFilter: filter,
                                    selectedClient: item,
                                  },
                                });
                              }
                            }}
                            tabIndex={0}
                            title={renderTitle(item?.clientName, 30)}
                          >
                            {renderValue(item?.clientName, 30)}
                          </p>
                          <p title={renderTitle(item?.salesRepresentative, 30)}>
                            {renderValue(item?.salesRepresentative, 30)}
                          </p>
                          <p>
                            {checkIfEmpty(
                              item?.endDate,
                              moment(item?.endDate).format("DD MMM YY")
                            )}
                          </p>
                          <p
                            style={{
                              color: getStatusColor(item?.clientContractStatus),
                            }}
                          >
                            <span
                              style={{
                                padding: "10px",
                                fontSize: "12px",
                                backgroundColor:
                                  item?.clientContractStatus === "Expired"
                                    ? "#ef53509e"
                                    : null,
                              }}
                            >
                              {item?.clientContractStatus}
                            </span>
                          </p>
                        </div>
                      );
                    }
                  )
                ) : (
                  <p className={style.no_data}>No Data Found</p>
                ))}
            </div>
          </div>

          <div className={style.pagination}>
            <Pagination
              onPageChange={handlePageChange}
              currentPage={filter?.page}
              pagesCount={getAllUpcomingEndingContracts?.totalDataCount / 10}
              color="#855ea1"
              color2="white"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientContractList;
