
import InductionNavigation from "../Pages/Employee_Induction/navigation/InductionNavigation.js";

// function EmployeeInductionRoute() {
//   return (
//     <Route>
//       <Route exact path="/Employee-Induction" component={InductionNavigation} />
//     </Route>
//   );
// }
const EmployeeInductionRoute = [
  { path: '/Employee-Induction', component: InductionNavigation },
  
];
export default EmployeeInductionRoute;
