import React, { useState } from "react";
import SideBar from "../../sidebar/SideBar";
import style from "./exitemployee.module.css";
import { useMutation, useQuery } from "react-query";
import {
  editExitComment,
  getAllType,
  getPendingExit,
} from "../../../../Utils/axios/umsApi";
import moment from "moment";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import { useHistory } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import ConfirmPopup from "../viewUser/ConfirmPopup";
import { toast, ToastContainer } from "react-toastify";
import { path } from "../../../../routes/PathNames";
import TotalCountUMS from "../commonComponent/TotalCountUMS";
import CommonDropdown from "../../../../GenericComponent/ui/CommonDropdown";

const ExitEmpolyee = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [empId, setEmpId] = useState(null);
  const [filter, setFilter] = useState({
    typeId: "",
  });
  const { data, isLoading, isError } = useQuery(
    ["getPendingExit", currentPage,filter],
    () => getPendingExit(10, currentPage,filter?.typeId)
  );
  const history = useHistory();
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push(path.ums_userDescription, { id });
    }, 0);
  };
  const mutation = useMutation(editExitComment, {
    onSuccess: (res) => {
      notifySuccess(res);
    },
    onError: () => {
      notify("Somethin went wrong");
    },
  });
  const notifySuccess = (success) =>
    toast.success(success, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const notify = (error) =>
    toast.error(error, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
    const { data: getAllTypeData } = useQuery(["getAllType"], getAllType,{
      refetchOnWindowFocus:false
    });
    const getAllTypeOption = getAllTypeData?.map((e) => ({
      value: e.id,
      label: e.name,
    })) ?? [];
  return (
    <>
      <ToastContainer />
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.Exit}>
          <div className={style.upcoming}>
            <div>Upcoming Exits</div> <TotalCountUMS totalCount={data?.count} />
            </div>
            <div className={style.dropdownContainer1}>
              <CommonDropdown
                color="#289e98"
                value={
                  getAllTypeOption.find((e) => e.value === filter?.typeId) ?? ""
                }
                onChange={(selectedOption) =>
                  setFilter({
                    ...filter,
                    typeId: selectedOption.value,
                    currentPage: 1,
                  })
                }
                options={getAllTypeOption}
                placeholder="Select Type"
                customInputOnClick={() =>
                  setFilter({
                    ...filter,
                    typeId: "",
                  })
                }
              
              />
            </div>
          </div>
          <div className={style.UsersList}>
            <div className={style.th}>
              <p>Emp Code</p>
              <p>Name</p>
              <p>Resignation Date</p>
              <p>Exit Date</p>
              <p></p>
            </div>
            {isLoading && <div style={{ textAlign: "center" }}>Loading...</div>}
            {isError && (
              <div style={{ textAlign: "center" }}>Something Went Wrong</div>
            )}
            {!isLoading && !isError ? (
              data?.getPendingExits?.length > 0 ? (
                data?.getPendingExits?.map((e, key) => {
                  return (
                    <div className={style.td} key={key}>
                      <p onClick={() => handleRedirect(e.empId)}>
                        {e?.empCode}
                      </p>
                      <p>{e?.empName}</p>
                      <p>
                        {e?.resignDate &&
                          moment(e?.resignDate).format("DD MMM YY")}
                      </p>
                      <p>
                        {e?.exitPlanDate &&
                          moment(e?.exitPlanDate).format("DD MMM YY")}
                      </p>
                      <p
                        onClick={() => {
                          setShowNotes(true);
                          setNotes(e?.exitComments);
                          setEmpId(e?.empId);
                        }}
                      >
                        {e?.exitComments && (
                          <CgNotes style={{ color: "#289e98" }} />
                        )}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div style={{ textAlign: "center" }}>No Data</div>
              )
            ) : null}
          </div>

          <ConfirmPopup open={showNotes}>
            <div className={style.confirmPopup}>
              <div>
                <textarea
                  type="textarea"
                  className={style.textarea}
                  value={notes}
                  onChange={(e) => setNotes(e?.target.value)}
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <button
                  className={style.delete_btn}
                  onClick={() => setShowNotes(false)}
                >
                  Cancel
                </button>
                <button
                  className={style.delete}
                  onClick={() => {
                    mutation.mutate({
                      empId: empId,
                      exitComments: notes,
                    });
                    setShowNotes(false);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </ConfirmPopup>
          <div style={{ marginRight: "29px" }}>
            <Pagination
              pagesCount={data?.count / 10}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              color="#289e98"
              color2="white"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExitEmpolyee;
