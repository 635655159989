import React from "react";
import SummaryCom from "./component/summaryCom";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import style from "./Summary.module.css"
export const Summary =()=>{
    return(<>
       <Header />
      <div className={style.mainSection}>
        <SideBar />
        <SummaryCom />
      </div>
    </>)
}