import React from "react";
import UserIcon from "./../../../../../assets/chatbot/Im User.svg"

export default function UserMessage({ text }) {
    return (
        <div className="message-container">
          <div className="usermessageShow">
            <div className="userImg">
              <img src={UserIcon} alt="User Icon" className="user-icon" />
            </div>
            <span className="user-message">{text}</span>
          </div>
        </div>
      );
    }
