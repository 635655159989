import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "../privateRoutes.js";
import { ViewQuestionnaire } from "../Pages/ClientQuestionnaire/ViewQuestionnaire/ViewQuestionnaire.js";
import DashBoard from "../Pages/dashboard/dashboard.jsx";
import SignIn from "../Pages/SignIn/SignIn.js";
import Feedback from "../Pages/atsDashboard/pageLayout/emailComp/Feedback.jsx";
import TaskTrackerRoute from "./TaskTrackerRoute.js";
import AtsRoute from "./AtsRoute.js";
import EmployeeInductionRoute from "./EmployeeInductionRoute.js";
import RmsRoute from "./RmsRoute.js";
import HrHelpDesk from "./HrHelpDesk.js";
import ClientQuestionRoute from "./ClientQuestionRoute.js";
import UmsRoute from "./UmsRoute.js";
import ExpenseInvoiceRoute from "./ExpenseInvoiceRoute.js";
import EmployeeTimeSheetRoute from "./EmployeeTimeSheetRoute.js";
import PortalManagementRoute from "./PortalManagementRoute.js";
import EmployeePerformanceReviewRoute from "./EmployeePerformanceReviewRoute.js";
import GeneRateLink from "../Pages/ClientQuestionnaire/CommonComponent/GenerateLink/GenerateLink.js";
import ClientContractRoute from "./ClientContractRoute.js";
import efRoutes from "./efRoutes.js";
import secureLocalStorage from "react-secure-storage";
import PriceRevisionRoute from "./PriceRevisionRoute.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.js";
import axios from "axios";
import constants, { employeeId } from "../Utils/constants.js";
import { DataContextProvider } from "../Pages/Projects_and_Resource_Master/CommonComponent/useContext/DataContext.js";
import AppraisalSystemRoutes from "./AppraisalSystemDashboard.js";
import PcmRoute from "./PcmRoute.js"

export default function Routes() {
  const path = useLocation();
  const baseUrl = constants.apiBaseUrl;

  function processJSONObjects(jsonArray) {
    const cacheKey = JSON.stringify(jsonArray);
    const data = secureLocalStorage?.getItem("cacheKey");
    if (data === cacheKey) {
      return;
    }
    return secureLocalStorage?.setItem("cacheKey", jsonArray);
  }
  const getUserInfo = () => {
    axios
      .get(baseUrl + `/getUserApplications?userId=${employeeId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        let data = res.data.responseData.applications;

        processJSONObjects(data?.map((item) => item.applicationCode));
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const accessData = secureLocalStorage?.getItem("cacheKey");

  const protectedRouter = [
    {
      key: "ATS",
      value: AtsRoute?.map((e, key) => {
        return (
          <Route
            key={`ats${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    {
      key: "UMS",
      value: UmsRoute?.map((e, key) => {
        return (
          <Route
            key={`ums${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    // {
    //   key: "UMS",
    //   value: <UMSProtectedRoute>{UmsRoute()}</UMSProtectedRoute>,
    // },
    //  {
    //   key: "ATS",
    //   value: (
    //     <ApplicationProtectedRoute> {AtsRoute()}</ApplicationProtectedRoute>
    //   ),
    // },
    // {
    //   key: "TT",
    //   value: (
    //     <TaskTrackerProtectedRoute>
    // {TaskTrackerRoute()}
    //     </TaskTrackerProtectedRoute>
    //   ),
    // },
    {
      key: "TT",
      value: TaskTrackerRoute?.map((e, key) => {
        return (
          <Route
            key={`TTT${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    // {
    //   key: "IND",
    //   value: (
    //     <EmployeeInductionProtectedRoute>
    //       {EmployeeInductionRoute()}
    //     </EmployeeInductionProtectedRoute>
    //   ),
    // },
    {
      key: "IND",
      value: EmployeeInductionRoute?.map((e, key) => {
        return (
          <Route
            key={`IND${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    // {
    //   key: "RMS",
    //   value: <RMSProtectedRoute>{RmsRoute()}</RMSProtectedRoute>,
    // },
    {
      key: "RMS",
      value: RmsRoute?.map((e, key) => {
        return (
          <Route
            key={`RMS${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    // {
    //   key: "HRHD",
    //   value: <HRHelpProtectedRoute>{HrHelpDesk()}</HRHelpProtectedRoute>,
    // },
    {
      key: "HRHD",
      value: HrHelpDesk?.map((e, key) => {
        return (
          <Route
            key={`HRHD${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    // {
    //   key: "QUES",
    //   value: (
    //     <ClientQuestionProtectedRoute>
    //       {ClientQuestionRoute()}
    //     </ClientQuestionProtectedRoute>
    //   ),
    // },
    {
      key: "QUES",
      value: ClientQuestionRoute?.map((e, key) => {
        return (
          <Route
            key={`QUES${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    // {
    //   key: "FE",
    //   value: <FEProtectedRoute>{ExpenseInvoiceRoute()}</FEProtectedRoute>,
    // },
    {
      key: "FE",
      value: ExpenseInvoiceRoute?.map((e, key) => {
        return (
          <Route
            key={`FE${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    // {
    //   key: "TS",
    //   value: <TSProtectedRoute>{EmployeeTimeSheetRoute()}</TSProtectedRoute>,
    // },
    {
      key: "TS",
      value: EmployeeTimeSheetRoute?.map((e, key) => {
        return (
          <Route
            key={`TS${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    // {
    //   key: "PM",
    //   value: <PMProtectedRoute>{PortalManagementRoute()}</PMProtectedRoute>,
    // },
    {
      key: "PM",
      value: PortalManagementRoute?.map((e, key) => {
        return (
          <Route
            key={`PM${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    {
      key: "EPR",
      value: EmployeePerformanceReviewRoute?.map((e, key) => {
        return (
          <Route
            key={`EPR${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    {
      key: "CC",
      value: ClientContractRoute?.map((e, key) => {
        return (
          <Route
            key={`CC${key}`}
            exact
            path={e?.path}
            component={e?.component}
          />
        );
      }),
    },
    // {
    //   key: "EPR",
    //   value: (
    //     <EPRProtectedRoute>
    //       {EmployeePerformanceReviewRoute()}
    //     </EPRProtectedRoute>
    //   ),
    // },
  ];

  const data = protectedRouter
    ?.map((e) => (accessData?.includes(e?.key) ? e?.value : null))
    .filter((e) => e);

  let newArray = [];
  const flatting = (array) => {
    array.forEach((item) => {
      if (Array.isArray(item)) {
        flatting(item);
      } else {
        newArray.push(item);
      }
    });
  };
  flatting(data);

  return (
    <DataContextProvider>
      <Switch>
        <Route exact path="/" component={SignIn} />
        <Route exact path="/FeedbackByEmail/:id" component={Feedback} />
        <Route
          exact
          path="/viewQuestionDetailsById/:token"
          component={GeneRateLink}
        />
        <Route
          path="/ViewQuestionnaire/:clientId/:minExperience/:maxExperience/:skillId/:seniorityId"
          component={ViewQuestionnaire}
        />
        {/* {protectedRouter
          ?.map((e) => (accessData?.includes(e?.key) ? e?.value : null))
          .filter((e) => e)} */}

        {/* {ItAssets()}
        {GrevienceRoute()} */}
        {/* {ClientContractRoute()} */}

        {/* other */}
        {newArray}

        {PriceRevisionRoute?.map((e, key) => {
          return (
            <Route
              key={`PRR${key}`}
              exact
              path={e?.path}
              component={e?.component}
            />
          );
        })}

        {AppraisalSystemRoutes?.map((e, key) => {
          return (
            <Route
              key={`AS${key}`}
              exact
              path={e?.path}
              component={e?.component}
            />
          );
        })}

        {efRoutes?.map((e, key) => {
          return (
            <Route
              key={`ef${key}`}
              exact
              path={e?.path}
              component={e?.component}
            />
          );
        })},
        {PcmRoute?.map((e, key) => {
          return (
            <Route
              key={`ef${key}`}
              exact
              path={e?.path}
              component={e?.component}
            />
          );
        })},

        <ProtectedRoute>
          <Route exact path="/dashboard" component={DashBoard} />
          {path?.pathname === "/dashboard" ||
          path?.pathname === "/dashboard/" ? null : (
            <Route path="*" component={DashBoard} />
          )}
        </ProtectedRoute>
      </Switch>
    </DataContextProvider>
  );
}
