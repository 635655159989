
import ResourcesJourney from "../Pages/Projects_and_Resource_Master/ResourcesJourney/BlockResourcesList/ResourcesJourney.js";
import RmsTaskDetalis from "../Pages/Projects_and_Resource_Master/Resources/ViewTask/TaskAssignedByMeDetails.js";
import EditTaskRMS from "../Pages/Projects_and_Resource_Master/Resources/EditTask/EditTask.js";
import CreateTaskRMSComponent from "../Pages/Projects_and_Resource_Master/Resources/createTask/CreateTask.js";
import Clients from "../Pages/Projects_and_Resource_Master/Clients/Clients.js";
import PartialPoolDescription from "../Pages/Projects_and_Resource_Master/Resources/partialPoolDescription/partialPoolDescription.js";
import RMSTaskHistory from "../Pages/Projects_and_Resource_Master/Resources/TaskHistory/TaskHistory.js";
import EditProject from "../Pages/Projects_and_Resource_Master/Projects/component/Editproject/EditProject.js";
import RMSReport from "../Pages/Projects_and_Resource_Master/Resources/Report/Report.js";
import EditResourcesOffshore from "../Pages/Projects_and_Resource_Master/Resources/EditResourceOffShore/EditResourceOffShore.js";
import OffShoreHistory from "../Pages/Projects_and_Resource_Master/Resources/component/OffshoreHistory/OffshoreHistory.js";
import EditCommentsRMS from "../Pages/Projects_and_Resource_Master/Resources/EditComments/EditComments.js";
import EditResourcesAllocationOnsite from "../Pages/Projects_and_Resource_Master/Resources/ResourcesOnsite/EditResourceOnsite.js";
import ResourcesOffshoreDescription from "../Pages/Projects_and_Resource_Master/Resources/OffShoreDescription/OffShoreDesCription.js";
import ResourceMaster from "../Pages/Projects_and_Resource_Master/Dashboard/Dashboard.js";
import Clientdescription from "../Pages/Projects_and_Resource_Master/Clients/clientdescription/Clientdescription.js";
import Projectdescription from "../Pages/Projects_and_Resource_Master/Projects/component/projecDescription/projectDescription.js";
import availableResources from "../Pages/Projects_and_Resource_Master/Projects/component/availableResources/availableResources.js";
import AllocateResources from "../Pages/Projects_and_Resource_Master/Projects/component/allocateResources/AllocateResources.js";
import Projects from "../Pages/Projects_and_Resource_Master/Projects/createProject.js";
import OnsiteHistory from "../Pages/Projects_and_Resource_Master/Resources/component/OnsiteHistory/OnsiteHistory.js";
import AllocationOnsiteDescription from "../Pages/Projects_and_Resource_Master/Resources/component/ResourceOnsiteDescription/OnsiteDesCription.js";
import EditClient from "../Pages/Projects_and_Resource_Master/Clients/EditClients/EditClient.js";
import OnsiteRecordData from "../Pages/Projects_and_Resource_Master/Resources/Report/OnSiteRecord/OnsiteRecord.js";
import OffshoreRecordData from "../Pages/Projects_and_Resource_Master/Resources/Report/OffShoreRecord/OffshoreRecord.js";
import PoolRecordData from "../Pages/Projects_and_Resource_Master/Resources/Report/PoolRecordData/PoolRecordData.js";
import OffshoreReport from "../Pages/Projects_and_Resource_Master/Reports/OffshoreReport/OffshoreReport.js";
import OnsiteReportDashoBoard from "../Pages/Projects_and_Resource_Master/OnsiteReport/OnsoteReportDashboard.js";
import { DeploymentsFromInnovationM } from "../Pages/Projects_and_Resource_Master/OnsiteReport/DeploymentsFromInnovationM/DeploymentsFromInnovationM.js";
import { ActiveDeployementsReport } from "../Pages/Projects_and_Resource_Master/OnsiteReport/ActiveDeployementReport/ActiveDeployemnets.js";
import { DeploymentFromPartner } from "../Pages/Projects_and_Resource_Master/OnsiteReport/DeploymentFromPartner/DeploymentFromPartner.js";
import { WeekLyMonthLyDeploye } from "../Pages/Projects_and_Resource_Master/OnsiteReport/WeekLyMonthLyDeploye/WeekLyMonthLyDeploye.js";
import { MonthlyWeeklyRampDown } from "../Pages/Projects_and_Resource_Master/OnsiteReport/MonthlyWeeklyRampDown/MonthlyWeeklyRampDown.js";
import { WeeklyMonthlyNotification } from "../Pages/Projects_and_Resource_Master/OnsiteReport/WeeklyMonthlyNotification/WeeklyMonthlyNotification.js";
import { ExpectedClientInformed } from "../Pages/Projects_and_Resource_Master/OnsiteReport/ExpectedClientInformed/ExpectedClientInformed.js";
import { UpcomingRampDownReport } from "../Pages/Projects_and_Resource_Master/OnsiteReport/UpComingRampDownReport/UpComingRampDownReport.js";
import AddPoolResources from "../Pages/Projects_and_Resource_Master/Resources/PoolResources/AddPollResources.js";
import PoolResourcesDescription from "../Pages/Projects_and_Resource_Master/Resources/PoolResources/ResourceOnsiteDescription/PoolResourcesDescription.js";
import PoolResources from "../Pages/Projects_and_Resource_Master/Resources/PoolResources/PoolResources.js";
import EditPoolResource from "../Pages/Projects_and_Resource_Master/Resources/PoolResources/EditPollResources.js";
import resourceManagement from "../Pages/Projects_and_Resource_Master/Resources/resourceManagement.js";
import AddResourcesAllocationOnsite from "../Pages/Projects_and_Resource_Master/Resources/ResourcesOnsite/AddResourcesAllocationOnsite.js";
import createProjectMilestone from "../Pages/Projects_and_Resource_Master/Projects/component/milestone/createProjectMilestone.js";
import Resource from "../Pages/Projects_and_Resource_Master/Projects/component/Resource/Resource.js";
import PoolHistory from "../Pages/Projects_and_Resource_Master/Resources/component/PoolHistory/PoolHistory.js";
import projectandResource from "../Pages/Projects_and_Resource_Master/Dashboard/Dashboard.js";
import ResourcesAllocationOnsite from "../Pages/Projects_and_Resource_Master/Resources/ResourcesOnsite/ResourcesAllocationOnsite.js";
import createClient from "../Pages/Projects_and_Resource_Master/Clients/component/createClient/CreateClient.js";
import AddResourcesOffshore from "../Pages/Projects_and_Resource_Master/Resources/AddResourcesOffshore/AddResourcesOffShore.js";
import ProjectDetails from "../Pages/Projects_and_Resource_Master/Projects/ProjectDetails.js";
import BlockResourcesList from "../Pages/Projects_and_Resource_Master/Block Resources/BlockResourcesList/BlockResourcesList.js";
import AddBlockResources from "../Pages/Projects_and_Resource_Master/Block Resources/AddBlockResources/AddBlockResources.js";
import BlockResourcesView from "../Pages/Projects_and_Resource_Master/Block Resources/BlockResourcesView/BlockResourcesView.js";
import EditBlockResources from "../Pages/Projects_and_Resource_Master/Block Resources/EditBlockResources/EditBlockResources.js";
import ResourceHistory from "../Pages/Projects_and_Resource_Master/ResourceHistory/ResourceHistory.jsx";
import WatchList from "../Pages/Projects_and_Resource_Master/MyWatchList/MyWatchlistt.js";
import OfferAcceptedCandidate from "../Pages/Projects_and_Resource_Master/OfferAcceptedCandidate/OfferAcceptedCandidate.js";
import DashboardNew from "../Pages/Projects_and_Resource_Master/DashboardNew/DashboardNew.js";
import ResourceBySkill from "../Pages/Projects_and_Resource_Master/ResourceBySkill/ResourceBySkill.js";
// function RmsRoute() {
//   return (
//     <Route>
//       <DataContextProvider>
//         <Route exact path="/ResourceMaster" component={ResourceMaster} />
//         <Route exact path="/Clients" component={Clients} />
//         <Route
//           exact
//           path="/projectandResource"
//           component={projectandResource}
//         />
//         <Route exact path="/AddPollResources" component={AddPoolResources} />
//         <Route exact path="/createClient" component={createClient} />
//         <Route exact path="/PoolResources" component={PoolResources} />
//         <Route exact path="/PoolResourcesHistory" component={PoolHistory} />
//         <Route
//           exact
//           path="/PoolResourcesDescription"
//           component={PoolResourcesDescription}
//         />
//         <Route exact path="/EditPoolResource" component={EditPoolResource} />
//         {/* onsite report route */}
//         <Route
//           exact
//           path="/ActiveDeployementsReport"
//           component={ActiveDeployementsReport}
//         />
//         <Route
//           exact
//           path="/DeploymentsFromInnovationM"
//           component={DeploymentsFromInnovationM}
//         />
//         <Route
//           exact
//           path="/onSiteReportDashBoard"
//           component={OnsiteReportDashoBoard}
//         />
//         <Route
//           exact
//           path="/DeploymentFromPartner"
//           component={DeploymentFromPartner}
//         />
//         <Route
//           exact
//           path="/WeekLyMonthLyDeploye/:reportType/:tabId"
//           component={WeekLyMonthLyDeploye}
//         />
//         <Route
//           exact
//           path="/MonthlyWeeklyRampDown"
//           component={MonthlyWeeklyRampDown}
//         />
//         <Route
//           exact
//           path="/WeeklyMonthlyNotification"
//           component={WeeklyMonthlyNotification}
//         />
//         <Route
//           exact
//           path="/ExpectedClientInformed"
//           component={ExpectedClientInformed}
//         />
//         <Route
//           exact
//           path="/UpcomingRampDownReport"
//           component={UpcomingRampDownReport}
//         />

//         <Route
//           exact
//           path="/AddResourcesOffshore"
//           component={AddResourcesOffshore}
//         />
//         <Route exact path="/editClient" component={EditClient} />
//         <Route
//           exact
//           path="/AllocationOnsiteDescription"
//           component={AllocationOnsiteDescription}
//         />
//         <Route exact path="/clientdescription" component={Clientdescription} />
//         <Route
//           exact
//           path="/Projectdescription"
//           component={Projectdescription}
//         />
//         <Route exact path="/projects" component={Projects} />
//         <Route exact path="/ProjectDetails" component={ProjectDetails} />
//         <Route
//           exact
//           path="/availableResources"
//           component={availableResources}
//         />
//         <Route
//           exact
//           path="/ResourcesOffshoreDescription"
//           component={ResourcesOffshoreDescription}
//         />
//         <Route exact path="/AllocateResources" component={AllocateResources} />
//         <Route exact path="/milestone" component={createProjectMilestone} />
//         <Route
//           exact
//           path="/resourceManagement"
//           component={resourceManagement}
//         />
//         <Route
//           exact
//           path="/ResourcesAllocationOnsite"
//           component={ResourcesAllocationOnsite}
//         />
//         <Route exact path="/RMSReport" component={RMSReport} />
//         <Route exact path="/OffshoreReport" component={OffshoreReport} />
//         <Route
//           exact
//           path="/OffshoreChangeHistory"
//           component={OffShoreHistory}
//         />
//         <Route exact path="/OnSiteChangeHistory" component={OnsiteHistory} />

//         <Route
//           exact
//           path="/AddResourcesAllocationOnsite"
//           component={AddResourcesAllocationOnsite}
//         />
//         <Route exact path="/resource" component={Resource} />
//         <Route
//           exact
//           path="/EditResourcesOffshore"
//           component={EditResourcesOffshore}
//         />
//         <Route
//           exact
//           path="/EditResourcesAllocationOnsite"
//           component={EditResourcesAllocationOnsite}
//         />
//         <Route exact path="/EditProject" component={EditProject} />

//         <Route exact path="/CreateTask" component={CreateTaskRMSComponent} />
//         <Route exact path="/Pool/TaskEdit" component={EditTaskRMS} />
//         <Route exact path="/Offshore/TaskEdit" component={EditTaskRMS} />
//         <Route exact path="/Onsite/TaskEdit" component={EditTaskRMS} />
//         <Route exact path="/Pool/TaskDetails" component={RmsTaskDetalis} />
//         <Route exact path="/OffShore/TaskDetails" component={RmsTaskDetalis} />
//         <Route exact path="/OffShore" component={OffshoreRecordData} />
//         <Route exact path="/OnSite" component={OnsiteRecordData} />
//         <Route exact path="/Pool" component={PoolRecordData} />
//         <Route exact path="/OnSiteRecord" component={OnsiteRecordData} />
//         <Route exact path="/OffShoreRecord" component={OffshoreRecordData} />
//         <Route exact path="/PoolRecord" component={PoolRecordData} />
//         <Route exact path="/OnSite/TaskDetails" component={RmsTaskDetalis} />
//         <Route exact path="/RMSTaskDetails" component={RmsTaskDetalis} />
//         <Route exact path="/EditRMSTask" component={EditCommentsRMS} />
//         <Route exact path="/RMSTaskHistory" component={RMSTaskHistory} />
//         {/* Resources Journey */}
//         <Route exact path="/ResourceJourney" component={ResourcesJourney} />
//         {/* Block Resources  */}
//         <Route
//           exact
//           path="/BlockResourcesList"
//           component={BlockResourcesList}
//         />
//         <Route exact path="/AddBlockResources" component={AddBlockResources} />
//         <Route
//           exact
//           path="/BlockResourcesView"
//           component={BlockResourcesView}
//         />
//         <Route
//           exact
//           path="/EditBlockResources"
//           component={EditBlockResources}
//         />
//         <Route
//           exact
//           path="/PartialPoolDescription"
//           component={PartialPoolDescription}
//         />
//         <Route exact path="/resourceHistory" component={ResourceHistory} />
//       </DataContextProvider>
//     </Route>
//   );
// }
const RmsRoute = [
  { path: "/ResourceMaster", component: ResourceMaster },
  { path: "/Clients", component: Clients },
  { path: "/projectandResource", component: projectandResource },
  { path: "/AddPollResources", component: AddPoolResources },
  { path: "/createClient", component: createClient },
  { path: "/PoolResources", component: PoolResources },
  { path: "/PoolResourcesHistory", component: PoolHistory },
  { path: "/PoolResourcesDescription", component: PoolResourcesDescription },
  { path: "/EditPoolResource", component: EditPoolResource },
  { path: "/ActiveDeployementsReport", component: ActiveDeployementsReport },
  { path: "/DeploymentsFromInnovationM", component: DeploymentsFromInnovationM },
  { path: "/onSiteReportDashBoard", component: OnsiteReportDashoBoard },
  { path: "/DeploymentFromPartner", component: DeploymentFromPartner },
  { path: "/WeekLyMonthLyDeploye/:reportType/:tabId", component: WeekLyMonthLyDeploye },
  { path: "/MonthlyWeeklyRampDown", component: MonthlyWeeklyRampDown },
  { path: "/WeeklyMonthlyNotification", component: WeeklyMonthlyNotification },
  { path: "/ExpectedClientInformed", component: ExpectedClientInformed },
  { path: "/UpcomingRampDownReport", component: UpcomingRampDownReport },
  { path: "/AddResourcesOffshore", component: AddResourcesOffshore },
  { path: "/editClient", component: EditClient },
  { path: "/AllocationOnsiteDescription", component: AllocationOnsiteDescription },
  { path: "/clientdescription", component: Clientdescription },
  { path: "/Projectdescription", component: Projectdescription },
  { path: "/projects", component: Projects },
  { path: "/ProjectDetails", component: ProjectDetails },
  { path: "/availableResources", component: availableResources },
  { path: "/ResourcesOffshoreDescription", component: ResourcesOffshoreDescription },
  { path: "/AllocateResources", component: AllocateResources },
  { path: "/milestone", component: createProjectMilestone },
  { path: "/resourceManagement", component: resourceManagement },
  { path: "/ResourcesAllocationOnsite", component: ResourcesAllocationOnsite },
  { path: "/RMSReport", component: RMSReport },
  { path: "/OffshoreReport", component: OffshoreReport },
  { path: "/OffshoreChangeHistory", component: OffShoreHistory },
  { path: "/OnSiteChangeHistory", component: OnsiteHistory },
  { path: "/AddResourcesAllocationOnsite", component: AddResourcesAllocationOnsite },
  { path: "/resource", component: Resource },
  { path: "/EditResourcesOffshore", component: EditResourcesOffshore },
  { path: "/EditResourcesAllocationOnsite", component: EditResourcesAllocationOnsite },
  { path: "/EditProject", component: EditProject },
  { path: "/CreateTask", component: CreateTaskRMSComponent },
  { path: "/Pool/TaskEdit", component: EditTaskRMS },
  { path: "/Offshore/TaskEdit", component: EditTaskRMS },
  { path: "/Onsite/TaskEdit", component: EditTaskRMS },
  { path: "/Pool/TaskDetails", component: RmsTaskDetalis },
  { path: "/OffShore/TaskDetails", component: RmsTaskDetalis },
  { path: "/OffShore", component: OffshoreRecordData },
  { path: "/OnSite", component: OnsiteRecordData },
  { path: "/Pool", component: PoolRecordData },
  { path: "/OnSiteRecord", component: OnsiteRecordData },
  { path: "/OffShoreRecord", component: OffshoreRecordData },
  { path: "/PoolRecord", component: PoolRecordData },
  { path: "/OnSite/TaskDetails", component: RmsTaskDetalis },
  { path: "/RMSTaskDetails", component: RmsTaskDetalis },
  { path: "/EditRMSTask", component: EditCommentsRMS },
  { path: "/RMSTaskHistory", component: RMSTaskHistory },
  { path: "/ResourceJourney", component: ResourcesJourney },
  { path: "/BlockResourcesList", component: BlockResourcesList },
  { path: "/AddBlockResources", component: AddBlockResources },
  { path: "/BlockResourcesView", component: BlockResourcesView },
  { path: "/EditBlockResources", component: EditBlockResources },
  { path: "/PartialPoolDescription", component: PartialPoolDescription },
  { path: "/resourceHistory", component: ResourceHistory },
  { path: "/MyWatchList", component: WatchList },
  { path: "/FinalStages", component: OfferAcceptedCandidate },
  { path: "/TechnologyDashboard", component: DashboardNew },
  { path: "/ResourceBySkill", component: ResourceBySkill },
  { path: "/OfferAcceptedCandidate", component: OfferAcceptedCandidate }
];

// Now, you have an array of JSON objects representing your routes

export default RmsRoute;
