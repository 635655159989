import React from 'react';
import style from "./AppraisalHistoryComment.module.css"

const AppraisalHistoryComment = ({modalData}) => {
    return (
        <div>
            <div className={style.title_Box}>
        <h5 className={style.title}>Comment Details</h5>
      </div>
      <table className={style.container}>
        <thead>
          <tr>
          <td className={style.td_style}>
            Comment Date
          </td>
          <td className={style.td_style}>Username</td>
          <td className={style.td_style}>Comment</td>
          </tr>
        </thead>
        <tbody>
          {modalData?.map((obj) => {
            return (
                <tr>
                  <td className={style.td_style2}>{obj.createdOn}</td>
                  <td className={style.td_style2}>{obj.commentByEmpName}</td>
                  <td className={`${style.text_overflow} ${style.td_style2}`}>{obj.hrHeadComment}</td>
                </tr>
            );
          })}
        </tbody>
      </table>
        </div>
    );
}

export default AppraisalHistoryComment;
