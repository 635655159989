import React, { useState } from "react";
import { Table } from "react-bootstrap";
import style from "./CommentHistory.module.css";
import AddCommentPartnerLead from "../AddComment/AddCommentPartnerLead";
import { MdDelete } from "react-icons/md";
import { BsFillPencilFill } from "react-icons/bs";
import {
  getPartnerLeadCommentByLeadId,
  editPartnerLeadComment,
  deletePartnerLeadCommentByLeadId
} from "../../../../../Utils/axios/api";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { notifySuccess } from "../../../utils/notify";
import { employeeId } from "../../../../../Utils/constants";
import { Modal } from "react-responsive-modal";

function CommentHistoryPartnerLead({ candidateId, setShowCommentModal, partnerId, partnerName }) {
  const [obj, setObj] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const {
    data: CommentData,
    isLoading: commentLoading,
    refetch: refetchComments,
  } = useQuery(
    "getPartnerLeadCommentByLeadId",
    () => getPartnerLeadCommentByLeadId(partnerId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  
  const deleteCommentPartnerLead = useMutation(deletePartnerLeadCommentByLeadId, {
    onSuccess: () => {
        notifySuccess("Comment Deleted Successfully");
        refetchComments();
      },
      onError: (err) => { },
  })
  const [showAddComment, setShowAddComment] = useState(false);

  const handleAddComments = () => {
    setShowAddComment(true);
  };
  const handleEditComment = (comment) => {
    setShowAddComment(true);
    setObj(comment);
  };

  const deleteComment = () => {
    console.log(selectedComment)
    if (selectedComment) {
      deleteCommentPartnerLead.mutate(selectedComment.id);
      setShowDeleteModal(false);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedComment(null);
  };

  const handleDeleteComment = (comment) => {
    setShowDeleteModal(true);
    setSelectedComment(comment);
  };

  return (
    <div>
      <DeleteConfirmationModel
        showDeleteModal={showDeleteModal}
        deleteComment={deleteComment}
        cancelDelete={cancelDelete}
      />
      <div className={style.description_box1}>
        <div className={style.span2}>
          <p>Comment History (Partner - {partnerName})</p>
        </div>
        <div className={style.maindivContent}></div>
        <div
          className={style.original_table}
          style={{
            backgroundColor: "white",
            minHeight: "3rem",
            marginTop: "7px",
          }}
        >
          <Table borderless size="sm" style={{ fontSize: "14.5px" }}>
            <thead
              style={{
                backgroundColor: "#F0F0F0",
                position: "sticky",
                top: "0px",
              }}
            >
              <tr>
                <th style={{ padding: "10px" }}>Comment Date</th>
                <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
                <th style={{ padding: "10px" }}>Comment</th>
                <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {!commentLoading && CommentData?.length > 0 ? (
                CommentData.map((comment, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ paddingLeft: "10px" }}>
                        {comment?.createdAt
                          ? moment(comment.createdAt).format("DD MMM YYYY")
                          : null}
                      </td>
                      <td style={{ paddingLeft: "10px", minWidth: "100px" }}>
                        {comment?.commentByName}
                      </td>
                      <td style={{ paddingLeft: "10px", whiteSpace: "pre-wrap" }}>
                        {comment?.comment}
                      </td>
                      {comment.commentBy === employeeId ? (
                        <td style={{ paddingLeft: "10px", minWidth: "75px" }}>
                          <BsFillPencilFill
                            alt="edit"
                            style={{ cursor: "pointer",color:"#C3B14B",fontSize:'19px' }}
                            title="Edit Comment"
                            onClick={() => handleEditComment(comment)}
                            onKeyDown={() => handleEditComment(comment)}
                            tabIndex={0}
                          />
                          <MdDelete
                            alt="delete"
                            style={{ cursor: "pointer", paddingLeft: "10px",fontSize:'34px',color:'#C3B14B' }}
                            title="Delete Comment"
                            onClick={() => {
                              handleDeleteComment(comment);
                            }}
                            onKeyDown={() => handleDeleteComment(comment)}
                            tabIndex={0}
                          />
                        </td>
                      ) : (
                        <td
                          style={{ paddingLeft: "10px", minWidth: "75px" }}
                        ></td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <p
                  className={style.p1}
                  style={{
                    position: "relative",
                    left: "26rem",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  No Comments Found
                </p>
              )}
            </tbody>
          </Table>
        </div>
        <div className={style.addButton}>
          {!showAddComment && (
            <button className={style.add_btn} onClick={handleAddComments}>
              Add Comment
            </button>
          )}
        </div>
      </div>
      {showAddComment && (
        <AddCommentPartnerLead
          candidateId={candidateId}
          showAddComment={showAddComment}
          setShowAddComment={setShowAddComment}
          obj={obj}
          setObj={setObj}
          setShowCommentModal={setShowCommentModal}
          partnerId={partnerId}
        />
      )}
    </div>
  );
}

const DeleteConfirmationModel = ({
  showDeleteModal,
  cancelDelete,
  deleteComment,
}) => {
  return (
    <Modal
      open={showDeleteModal}
      onClose={() => {
        showDeleteModal(false);
      }}
      showCloseIcon={false}
      center
    >
      <div className={style.deleteModal}>
        <div> Do you want to delete this comment ?</div>
        <div className={style.btnRow}>
          <button className={style.yes_btn} onClick={deleteComment}>
            Yes
          </button>
          <button className={style.no_btn} onClick={cancelDelete}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CommentHistoryPartnerLead;
