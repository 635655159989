import React from "react";
import { HrViewIssueComponent } from "./HrHelpDeskViewcomp/HrHelpDeskViewCom";
import style from "./HrHelpDeskView.module.css"
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
export const HrHelpDeskViewIssue =()=>{
    return(<>
        <Header />
       <div className={style.mainSection}>
         <SideBar />
         <HrViewIssueComponent />
       </div>
     </>)
 }
