import React, { useEffect, useContext, useState } from "react";
import SideBar from "../../Clients/component/SideBar";
import style from "./OffshoreReport.module.css";
import OffshorReportTable from "./offshoreReportTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import loaderImg from "../../../../assets/loader.gif";
import constants from "../../../../Utils/constants";
import axios from "axios";
import Moment from "moment";
import { api } from "../../CommonApi/api";
import { get } from "../../CommonApi/axiosCall";
import Select from "react-select";
import { DataContext } from "../../CommonComponent/useContext/DataContext";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import cookie from "react-cookies";
import { customSearch } from "../../Clients/component/style";
import Pagination from "../../CommonComponent/Pagination";
import milestone from "../../../../assets/milestone.png";
import rightTickPRM from "../../../../assets/rightTickPRM.png";
import Filter from "../../components/Filter";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const OffshoreReport = (props) => {
  const history = useHistory();
  const { data, setData } = useContext(DataContext);
  const baseUrl = constants.apiBaseUrlResource;
  const [applied, setApplied] = useState(
    props.history?.location.state?.allData?.applied
      ? props.history.location.state?.allData?.applied
      : false
  );
  const [showFilter, setShowFilter] = useState(false);
  const [OffShoreData, setOffshoreData] = useState();
  const [currentPage, setCurrentPage] = useState(
    props.history?.location?.state?.allData?.currentPage
      ? props.history.location?.state?.allData?.currentPage
      : 1
  );
  const [totalResourceOffShore, setTotalResourceOffShore] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [offshoreEmp, setOffshoreEmp] = useState([]);
  const [offshoreProject, setOffshoreProject] = useState([]);
  const [offshoreSkills, setOffshoreSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [partnerName, setParnername] = useState([]);
  const [ExitDate, setExitDate] = useState();
  const [show, setShow] = useState(false);
  const [, setAction] = useState([]);
  const [, setBandwidths] = useState([]);
  const [, setEmployeeId] = useState([]);
  const [filterName, setFilterName] = useState(
    props.history.location.state?.allData?.filterName
      ? props.history.location.state?.allData?.filterName
      : {}
  );
  const [, setProjectId] = useState([]);
  const [, setSkills] = useState([]);
  const [allData, setAllData] = useState(
    data?.partialoffshore
      ? data?.partialoffshore
      : {
          bandwidths: [],
          empId: [],
          experienceRange: [],
          salaryRange: [],
          skillId: [],
          partnerId: [],
        }
  );
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  offshoreEmp?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const url = process.env.REACT_APP_UMS_APP_URL;
  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, empId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "Code2":
          setAllData({ ...allData, partnerId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        default:
      }
    }
  };
  const getResourceOffShoreData = (clearData, currentPage) => {
    const filterData = allData;

    setIsLoaded(true);

    axios
      .post(
        baseUrl +
          "/getPartialPoolResources?" +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setOffshoreData(res.data.responseData);
        setTotalResourceOffShore(res.data.responseData.totalResources);
        setpageCount(res.data.responseData.totalResources / 10);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };

  const getOffshorePartner = () => {
    let baseURL = api.getPartialPoolResources;
    get(baseURL)
      .then((res) => {
        setParnername(res.data.responseData);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (data?.partialoffshore?.handleFilter) {
      setApplied(true);
    }
    getOffshoreProject();
    getOffshoreEmp();
    getOffshoreSkills();
  }, []);
  useEffect(() => {
    getResourceOffShoreData(allData, currentPage);
  }, [currentPage, allData]);
  const getOffshoreEmp = () => {
    axios
      .get(baseUrl + "/getAllocatedOffShoreEmployee", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreEmp(res.data.responseData.employees);
      })
      .catch(() => {});
  };

  const getOffshoreProject = () => {
    axios
      .get(baseUrl + "/getProjectOffShore", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreProject(res.data.responseData);
      })
      .catch(() => {});
  };

  const getUserData = () => {
    axios
      .get(url + `/updateUsersDetails`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then(() => {})
      .catch(() => {});
  };

  const getOffshoreSkills = () => {
    axios
      .get(baseUrl + "/getOffshoreEmployeeSkillForFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreSkills(res.data.responseData);
      })
      .catch(() => {});
  };

  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setCurrentPage(1);
    setAllData((preValue) => {
      return preValue
        ? {
            ...preValue,
            bandwidths: [],
            empId: [],
            experienceRange: [],
            salaryRange: [],
            skillId: [],
            handleFilter: false,
          }
        : null;
    });

    setAction([]);
    setBandwidths([]);

    setProjectId([]);
    setSkills([]);
    setShowFilter(false);
    setApplied(false);
  };
  useEffect(() => {
    setData({ ...data, partialoffshore: allData });
  }, [allData]);

  const handleApplied = (arg) => {
    setAllData({ ...allData, handleFilter: true });
    if (
      allData?.bandwidths?.length !== 0 ||
      allData?.skillId?.length !== 0 ||
      allData?.salaryRange?.length !== 0
    ) {
      setApplied(true);
    }
    setShowFilter(false);
    setCurrentPage(1);
    getResourceOffShoreData(allData, 1);
  };

  const getPositionCodeForFilterOption = offshoreEmp?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  useEffect(() => {
    getUserData();
    getOffshorePartner();
  }, []);

  const getPartnerList = partnerName?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const value = allData?.empId[0]
    ? getPositionCodeForFilterOption?.find((e) => e.value === allData.empId[0])
    : null;

    const value3 = allData?.partnerId && allData.partnerId[0]
    ? getPartnerList?.find((e) => e.value === allData.partnerId[0])
    : null;

  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "6px 0px" }}>
              {/* <span><span>Dashboard{'>'}</span><span className={style.recent_tab}>Resource Allocation Offshore </span></span> */}
            </div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "600", width: "20%" }}
              >
                Resource (Partial Pool)
              </div>
              <div style={{ width: "12%" }}>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {totalResourceOffShore}
                  </span>
                </p>
              </div>
              <div style={{ width: "25%", paddingLeft: "7%" }}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img
                      className={style.appliedcheck}
                      src={rightTickPRM}
                      alt="img"
                    />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>

              <div
                className={style.s1}
                style={{ width: "29%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.empId?.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        empId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Employee Name/Code"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value}
                  onChange={(e) => {
                    onSearchHandler(e, "Code");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getPositionCodeForFilterOption}
                />
              </div>
              <div
                className={style.s1}
                style={{ width: "20%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.partnerId?.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        partnerId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Partner"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value3}
                  onChange={(e) => {
                    onSearchHandler(e, "Code2");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getPartnerList}
                />
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.offshorelist}
            style={{
              backgroundColor: "white",
              minHeight: "28rem",
              overflow: "auto",
            }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : OffShoreData?.resources.length > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "10px" }}>Code</th>
                    <td style={{padding: "10px" }}></td>
                    <th style={{ padding: "10px" }}>Name</th>
                    <th style={{ padding: "10px" }}>Exp</th>
                    <th style={{ padding: "10px" }}>Salary Range</th>
                    <th style={{ padding: "10px" }}>Technology</th>
                    <th style={{ textAlign: "center", padding: "10px" }}>
                      % Available <br /> Allocation
                    </th>
                    <th style={{ padding: "10px" }}>Start Date</th>
                    <th style={{ padding: "10px" }}></th>
                    <th style={{ padding: "10px" }}>Partner Name</th>
                  </tr>
                </thead>
                <tbody>
                  {OffShoreData?.resources.map((val) => {
                    return (
                      <OffshorReportTable
                        val={val}
                        setExitDate={setExitDate}
                        setShow={setShow}
                        allData={allData}
                        currentPage={currentPage}
                        filterName={filterName}
                        applied={applied}
                      />
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} alt="img" />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}
          </div>
          {totalResourceOffShore > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          type={"partialoffshoreReport"}
          setAction={setAction}
          setBandwidths={setBandwidths}
          employeeId={offshoreEmp}
          setEmployeeId={setEmployeeId}
          projectList={offshoreProject}
          setProject={setProjectId}
          offshoreSkills={offshoreSkills}
          setSkills={setSkills}
        />
      )}
      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
          {ExitDate
            ? Moment(ExitDate).format("DD MMM YY")
            : "No Date Available"}
        </div>
      </Modal>
    </>
  );
};

export default OffshoreReport;
