import React from "react";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Ricon from "./../../../../assets/RIcon.png";
import Eicon from "./../../../../assets/EIcon.png";
import { BsInfoSquareFill } from "react-icons/bs";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import ShowStatus from "../../CommonComponent/showStatus/ShowStatus";
import { FaPlusCircle } from "react-icons/fa";
import { CiCircleRemove } from "react-icons/ci";

const TableRow = (props) => {
  const BlackTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "black",
      color: "#fff",
      fontSize: "1rem",
      whiteSpace: "pre-line",
      padding: "0.5rem",
    },
  }))(Tooltip);

  const history = useHistory();

  const GoToDetails = () => {
    const url = `/AllocationOnsiteDescription?id=${props.val.id}`;
    history.push(url);
  };

  const getBackgroundColor = (status) => {
    if (status === "Ongoing") {
      return "green";
    }
    return "red";
  };
  const rowStyle = {
    backgroundColor: "red",
  };
  const ReginationData = (value) => {
    return (
      <>
        <div>Resignation Date</div>
        {value ? (
          <div>{moment(value).format("DD MMM YY")}</div>
        ) : (
          <div>No Date Available</div>
        )}
      </>
    );
  };
  const ExitDate = (value) => {
    return (
      <>
        <div>Exit Date</div>
        {value ? (
          <div>{moment(value).format("DD MMM YY")}</div>
        ) : (
          <div>No Date Available</div>
        )}
      </>
    );
  };
  const BackGroundColor = (status) => {
    if (status === "Resigned") {
      return "#f5bbc5";
    }
    return null;
  };
  return (
    <tr
      style={{
        borderBottom: "2px solid #F0F0F0",
        textAlign: "start",
        fontSize: "13px",
        position: "relative",
        backgroundColor: BackGroundColor(props.val.employeeStatus),
      }}
    >
      <td
        style={{
          paddingLeft: "10px",
          position: "sticky",
          left: "0px",
          backgroundColor: "white",
        }}
      >
        {props.val.empCode}
      </td>
      <td
        style={{
          paddingLeft: "10px",
          position: "sticky",
          left: "49px",
          backgroundColor: "white",
        }}
      >
        <p
          style={{
            width: "24ch",
            textAlign: "start",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {props.val.clientName}
        </p>
      </td>
      <td
        style={{
          padding: "10px",
          position: "sticky",
          left: "227px",
          backgroundColor: "white",
        }}
      >
        <span
          style={{
            cursor: "pointer",
            textTransform: "capitalize",
            textDecorationLine: "underline",
            fontWeight: "bolder",
            fontSize: "0.8rem",
          }}
          onClick={GoToDetails}
        >
          {props.val.empName}
        </span>
      </td>

      <td
        style={{
          padding: "4px",
          backgroundColor: "white",
        }}
      >
        {props?.val.employeeStatus === "Exit" ? (
          <ShowStatus
            index={`R${props?.val.id}`}
            component={ExitDate(props?.val.exitDate)}
            circleColor={{}}
          >
            <img
              src={Eicon}
              style={{ height: "1.5rem", maxWidth: "2rem" }}
              alt="Exit Icon"
            />
          </ShowStatus>
        ) : props?.val.employeeStatus === "Resigned" ? (
          <ShowStatus
            index={`R${props?.val.id}`}
            component={ReginationData(props?.val.resignDate)}
            circleColor={{}}
          >
            <img
              src={Ricon}
              style={{ height: "1.5rem", maxWidth: "2rem" }}
              alt="Resigned Icon"
            />
          </ShowStatus>
        ) : null}
      </td>

      <td style={{ padding: "10px" }}>{props?.val.employer}</td>
      <td style={{ padding: "10px" }}>{props?.val?.salesPersonName}</td>
      {props.val.experience ? (
        <td style={{ paddingLeft: "10px" }}>
          {~~(props.val.experience / 12)} Y -{props.val.experience % 12} M
        </td>
      ) : (
        <td style={{ paddingLeft: "10px" }}>0 Y - 0 M</td>
      )}
      <td style={{ paddingLeft: "10px" }}>{props.val.salaryRange}</td>
      <td style={{ paddingLeft: "10px" }}>{props.val.skillName}</td>
      <td
        style={{
          color: getBackgroundColor(props.val.statusTypeName),
          padding: "10px",
        }}
      >
        {props.val.statusTypeName === "In-Active"
          ? "Inactive"
          : props.val.statusTypeName}{" "}
      </td>
      <td style={{ paddingLeft: "10px" }}>
        {Moment(props.val.allocationdate).format("DD MMM YY")}
      </td>
      <td style={{ paddingLeft: "10px" }}>{props?.val.deploymentQuarter}</td>
      {props.val.deallocationdate ? (
        <td style={{ paddingLeft: "10px" }}>
          {Moment(props.val.deallocationdate).format("DD MMM YY")}
        </td>
      ) : (
        <td style={{ paddingLeft: "10px" }}>{props.val.deallocationdate}</td>
      )}
      {props.val.expectedEndDate ? (
        <td style={{ paddingLeft: "10px" }}>
          {Moment(props.val.expectedEndDate).format("DD MMM YY")}
        </td>
      ) : (
        <td style={{ paddingLeft: "10px" }}>{props.val.expectedEndDate}</td>
      )}
      {props?.val.currentCommentDate ? (
        <td style={{ paddingLeft: "10px" }}>
          {Moment(props.val.currentCommentDate).format("DD MMM YY")}
        </td>
      ) : (
        <td style={{ paddingLeft: "10px" }}></td>
      )}
      <td style={{ paddingLeft: "10px" }}>
        {props?.val.clientNotificationDate &&
          Moment(props?.val.clientNotificationDate).format("DD MMM YY")}
        <span>
          {props?.val?.clientInformedReason ? (
            <BlackTooltip title={props.val?.clientInformedReason}>
              <p>{<BsInfoSquareFill />}</p>
            </BlackTooltip>
          ) : null}
        </span>
      </td>
      <td style={{ paddingLeft: "10px" }}>
        {props?.val.isPartnerInformed === true
          ? "Yes"
          : props?.val.isPartnerInformed === false
          ? "No"
          : ""}
      </td>
      <td style={{ paddingLeft: "10px" }}>
        {" "}
        {props?.val.partnerInformedDate &&
          Moment(props?.val.partnerInformedDate).format("DD MMM YY")}
      </td>
      <td style={{ paddingLeft: "10px" }}>
        {props?.val.candidateInformedDate &&
          Moment(props?.val.candidateInformedDate).format("DD MMM YY")}
      </td>
      <td style={{ paddingLeft: "10px" }}>
        {props?.val.isClientInformed === true
          ? "Yes"
          : props?.val.isClientInformed === false
          ? "No"
          : ""}
      </td>

      <td style={{ paddingLeft: "10px" }}>
        {props?.val.clientInformedDate &&
          Moment(props?.val.clientInformedDate).format("DD MMM YY")}
      </td>

      <td style={{ paddingLeft: "10px" }}>
        {props?.val.clientNotificationReason || "-"}
      </td>
      <td style={{ textAlign: "center" }}>
        {props?.val.isForcedAddedToPool ? (
          <BlackTooltip title={"Forcefully Remove From Pool"}>
            <p
              onClick={() => {
                props?.addToPoolResourceMutate.mutate({
                  id: props?.val?.id,
                  resourceType: "onsite",
                  status: !props?.val.isForcedAddedToPool,
                });
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  props?.addToPoolResourceMutate.mutate({
                    id: props?.val?.id,
                    resourceType: "offshore",
                    status: !props?.val.isForcedAddedToPool,
                  });
                }
              }}
              tabIndex={0}
            >
              <CiCircleRemove size={18} style={{ cursor: "pointer" }} />
            </p>
          </BlackTooltip>
        ) : (
          <BlackTooltip title={"Forcefully Add To Pool"}>
            <p
              onClick={() => {
                props?.addToPoolResourceMutate.mutate({
                  id: props?.val?.id,
                  resourceType: "onsite",
                  status: !props?.val.isForcedAddedToPool,
                });
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  props?.addToPoolResourceMutate.mutate({
                    id: props?.val?.id,
                    resourceType: "offshore",
                    status: !props?.val.isForcedAddedToPool,
                  });
                }
              }}
              tabIndex={0}
            >
              <FaPlusCircle size={18} style={{ cursor: "pointer" }} />
            </p>
          </BlackTooltip>
        )}
      </td>
    </tr>
  );
};

export default TableRow;
