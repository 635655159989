import axios from "axios";
import cookie from "react-cookies";
import { employeeId } from "../constants";
const apiKey = process.env.REACT_APP_Expence_URL;
const apiKey2 = process.env.REACT_APP_RESOURCEMASTER_APP_URL;
const apiKey3 = process.env.REACT_APP_PARTNER_MANAGEMENT;
const url = process.env.REACT_APP_HRHELPDESK_APP_URL;

export const createExpense = async (data) => {
  const response = await axios.post(`${apiKey}/createExpense`, data);
  return response.data.responseData;
};

export const expenseListDownload = async (limit, page, Data) => {
  const queryParams = [];

  if (Data?.expenseMonth?.length > 0) {
    Data?.expenseMonth?.forEach((month) => {
      queryParams?.push(`expenseMonth=${month?.label}`);
    });
  }

  if (Data?.category?.length > 0) {
    Data?.category?.forEach((category) => {
      queryParams?.push(`categoryId=${category?.value}`);
    });
  }

  if (Data?.vendor?.length > 0) {
    Data?.vendor?.forEach((vendor) => {
      queryParams?.push(`vendorId=${vendor?.value}`);
    });
  }

  if (Data?.status?.length > 0) {
    Data?.status?.forEach((status) => {
      queryParams?.push(`paymentStatus=${status?.value}`);
    });
  }

  if (Data?.paymentDate?.length > 0) {
    Data?.paymentDate?.forEach((date) => {
      queryParams?.push(`paymentDate=${date?.label}`);
    });
  }

  if (Data?.approveBy?.length > 0) {
    Data?.approveBy?.forEach((approve) => {
      queryParams?.push(`approvedBy=${approve?.label}`);
    });
  }

  if (Data?.paymentMode?.length > 0) {
    Data?.paymentMode?.forEach((mode) => {
      queryParams?.push(`paymentModeId=${mode?.value}`);
    });
  }

  queryParams?.push(`download=${true}`);

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (page) {
    queryParams.push(`page=${page}`);
  }

  const queryString = queryParams.join("&");

  const apiUrl = `${apiKey}/expenseList${queryString ? `?${queryString}` : ""}`;

  const response = await axios.get(apiUrl);
  return response.data;
};

export const expenseList = async (limit, page, Data) => {
  const queryParams = [];

  if (Data?.expenseMonth?.length > 0) {
    Data?.expenseMonth?.forEach((month) => {
      queryParams?.push(`expenseMonth=${month?.label}`);
    });
  }

  if (Data?.expenseYear?.label !== undefined) {
    if (Data?.expenseYear?.label?.length > 0) {
      queryParams?.push(`expenseYear=${Data?.expenseYear?.label}`);
    }
  }



  if (Data?.category?.length > 0) {
    Data?.category?.forEach((category) => {
      queryParams?.push(`categoryId=${category?.value}`);
    });
  }

  if (Data?.vendor?.length > 0) {
    Data?.vendor?.forEach((vendor) => {
      queryParams?.push(`vendorId=${vendor?.value}`);
    });
  }

  if (Data?.status?.length > 0) {
    Data?.status?.forEach((status) => {
      queryParams?.push(`paymentStatus=${status?.value}`);
    });
  }

  if (Data?.paymentDate?.length > 0) {
    Data?.paymentDate?.forEach((date) => {
      queryParams?.push(`paymentDate=${date?.label}`);
    });
  }

  if (Data?.approveBy?.length > 0) {
    Data?.approveBy?.forEach((approve) => {
      queryParams?.push(`approvedBy=${approve?.label}`);
    });
  }

  if (Data?.paymentMode?.length > 0) {
    Data?.paymentMode?.forEach((mode) => {
      queryParams?.push(`paymentModeId=${mode?.value}`);
    });
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (page) {
    queryParams.push(`page=${page}`);
  }

  const queryString = queryParams.join("&");

  const apiUrl = `${apiKey}/expenseList${queryString ? `?${queryString}` : ""}`;

  const response = await axios.get(apiUrl);
  return response.data;
};

export const getVendorName = async () => {
  const response = await axios.get(`${apiKey}/getVendorName`);
  return response.data.responseData;
};

export const getMonth = async () => {
  const response = await axios.get(`${apiKey}/getMonth`);
  return response.data.responseData;
};
export const getYear = async () => {
  const response = await axios.get(`${apiKey}/getYear`);
  return response.data.responseData;
};
export const getExpenseCategory = async (data) => {
  const response = await axios.get(`${apiKey}/getExpenseCategory`, data);
  return response.data.responseData;
};

export const createInvoice = async (data) => {
  const response = await axios.post(`${apiKey}/createInvoice`, data);
  return response.data.responseData;
};

export const getClientName = async () => {
  const response = await axios.get(`${apiKey}/getClientName`);
  return response.data.responseData;
};

export const invoiceListData = async (limit, page, Data) => {
  const queryParams = [];

  if (Data?.projectType?.length > 0) {
    Data?.projectType?.forEach((type) => {
      queryParams.push(`projectId=${type?.value}`);
    });
  }

  if (Data?.amountToBeReceived?.length > 0) {
    Data?.amountToBeReceived?.forEach((amount) => {
      queryParams.push(`amountToBeReceived=${amount?.label}`);
    });
  }

  if (Data?.clientName?.length > 0) {
    Data?.clientName?.forEach((name) => {
      queryParams.push(`clientId=${name?.value}`);
    });
  }

  if (Data?.advanceStatus?.length > 0) {
    Data?.advanceStatus?.forEach((status) => {
      queryParams.push(`advanceStausId=${status?.value}`);
    });
  }

  if (Data?.paymentReceivedDate?.length > 0) {
    Data?.paymentReceivedDate?.forEach((date) => {
      queryParams.push(`paymentDate=${date?.label}`);
    });
  }

  if (Data?.paymentStatus?.length > 0) {
    Data?.paymentStatus?.forEach((status) => {
      queryParams.push(`paymentStatus=${status?.value}`);
    });
  }

  const queryString = queryParams.join("&");

  const apiUrl = `${apiKey}/invoiceList?limit=${limit}&page=${page}${queryString ? `&${queryString}` : ""
    }`;

  const response = await axios.get(apiUrl);
  return response.data.responseData;
};
export const getServiceType = async () => {
  const response = await axios.get(`${apiKey}/getServiceType`);
  return response.data.responseData;
};

export const getCurrencyType = async () => {
  const response = await axios.get(`${apiKey}/getCurrency`);
  return response.data.responseData;
};

export const getAdvanceStatus = async () => {
  const response = await axios.get(`${apiKey}/getAdvanceStatus`);
  return response.data.responseData;
};
export const getExpenseId = async (id) => {
  const response = await axios.get(`${apiKey}/viewExpense/${id}`);
  return response.data.responseData;
};

export const getInvoiceId = async (id) => {
  const response = await axios.get(`${apiKey}/viewInvoice/${id}`);
  return response.data.responseData;
};

export const EditInvoiceId = async (data) => {
  const response = await axios.post(`${apiKey}/createInvoice`, data.formData);
  return response.data.responseData;
};

export const getAmountToBeReceived = async () => {
  const response = await axios.get(`${apiKey}/getAmountToBeReceived`);
  return response.data.responseData;
};

export const getPaymentStatus = async () => {
  const response = await axios.get(`${apiKey}/getPaymentStatus`);
  return response.data.responseData;
};

export const getPaymentDate = async () => {
  const response = await axios.get(`${apiKey}/getPaymentDate`);
  return response.data.responseData;
};

export const getcopyInvoice = async (id) => {
  const response = await axios.get(`${apiKey}/copyInvoice/${id}`);
  return response.data.responseData;
};
export const getcopyExpense = async (Id) => {
  const response = await axios.get(`${apiKey}/copyExpense/${Id}`);
  return response.data.responseData;
};
export const DeleteExpense = async (id) => {
  const response = await axios.put(`${apiKey}/deleteExpense/${id}`);
  return response?.data?.responseData;
};
export const DeleteInvoice = async (id) => {
  const response = await axios.put(`${apiKey}/deleteInvoice/${id}`);
  return response?.data?.responseData;
};

export const createvendor = async (data) => {
  const response = await axios.post(`${apiKey}/createvendor`, data);
  return response.data.responseData;
};

export const createcategory = async (data) => {
  const response = await axios.post(`${apiKey}/createcategory`, data);
  return response.data.responseData;
};

export const createClient = async (data) => {
  const response = await axios.post(`${apiKey}/createclient`, data);
  return response.data.responseData;
};

// hr help Desk
export const getUserAccess = async () => {
  const response = await axios.get(`${url}/getUserAccess?empId=${employeeId}`);
  return response.data.responseData?.roles;
};

export const getYearData = async () => {
  const response = await axios.get(`${apiKey}/getYear`);
  return response.data.responseData;
};

export const gettdsdetailsData = async (id) => {
  const response = await axios.get(`${apiKey}/gettdsdetails?partnerId=${id}`);
  return response.data.responseData;
};

export const GetgstnumberData = async (id) => {
  const response = await axios.get(`${apiKey}/getgstdetails?partnerId=${id}`);
  return response.data.responseData;
};

export const GetpannumberData = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-pan-by-partner-id?partnerId=${id}`
  );
  return response.data.responseData;
};

export const getresourcedetails = async (id, monthId, yearId) => {
  const response = await axios.get(
    `${apiKey}/getresourcedetails?partnerId=${id}&serviceMonthId=${monthId}&serviceYearId=${yearId}`
  );
  return response.data.responseData;
};

export const gettdsdetails = async (id) => {
  const response = await axios.get(`${apiKey}/gettdsdetails?partnerId=${id}`);
  return response.data.responseData;
};

export const updateDateStatus = async (data) => {
  const response = await axios.put(`${apiKey}/update-date-status`, data);
  return response.data.responseData;
};

/* **************  invoice api list  ***************  */
export const getClientList = async () => {
  const response = await axios.get(`${apiKey2}/getClientForFilter`, {
    headers: {
      Authorization: null,
    },
  });
  return response.data.responseData;
};

export const getProjectsByClientId = async (data) => {
  const response = await axios.post(`${apiKey2}/getProjects`, data, {
    headers: {
      Authorization: null,
    },
  });
  return response?.data?.responseData?.projects;
};

export const getMilestoneByProjectId = async (id) => {
  const response = await axios.get(
    `${apiKey3}/get-milestone-by-project?projectId=${id}`
  );
  return response?.data?.responseData;
};

export const getCandidateListInvoice = async (id, monthid, yearid) => {
  const response = await axios.get(
    `${apiKey}/get-candidate-on-client?clientId=${id}&serviceMonthId=${monthid}&serviceYearId=${yearid}`
  );
  return response?.data?.responseData;
};

export const getClientGst = async (id) => {
  const response = await axios.get(`${apiKey3}/get-client-gst?clientId=${id}`);
  return response?.data?.responseData;
};

export const copyInvoice = async (id) => {
  const response = await axios.get(`${apiKey}/copyInvoice/${id}`);
  return response?.data?.responseData;
};

export const getInvoicePaymentDate = async () => {
  const response = await axios.get(`${apiKey}/get-invoice-payment-dates`);
  return response?.data?.responseData;
};

export const getGstPercentage = async () => {
  const response = await axios.get(`${apiKey}/get-gst-percentage`);
  return response?.data?.responseData;
};

export const getPaymentMode = async () => {
  const response = await axios.get(`${apiKey}/get-payment-modes`);
  return response?.data?.responseData;
};

export const getApproveBy = async () => {
  const response = await axios.get(`${apiKey}/get-approved-by`);
  return response?.data?.responseData;
};

export const getInvoiceTypeList = async () => {
  const response = await axios.get(`${apiKey}/get-invoice-type`);
  return response?.data?.responseData;
};
export const uploadExpInvFile = async (files) => {
  let formData = new FormData();
  for (let index in files) {
    formData.append(`files`, files[index]);
  }
  const response = await axios.post(`${apiKey}/uploadFiles`, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      Authorization: null,
    },
  });
  return response.data.responseData;
};
export const deleteExpInvFile = async (url) => {
  const response = await axios.put(`${apiKey3}/delete-file-url?url=${url}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data.responseData;
};