import React from "react";
import style from "./Filter.module.css";
import FilterListIcon from "@mui/icons-material/FilterList";
const FilterButton = ({ setShowFilter, filterData }) => {
  return (
      <button
        className={
          filterData?.status?.length > 0 ||
          filterData?.allocated?.length > 0 ||
          filterData?.createdBy?.length > 0
            ? style.filterActive
            : style.filter
        }
        onClick={() => setShowFilter(true)}
      >
        <FilterListIcon />
        Filter
      </button>
  );
};

export default FilterButton;
