import React from "react";
import style from "./totalCount.module.css";
const TotalCount = ({ totalCount, styles,onClick,title }) => {
  return (
    <div style={styles} className={style.totalCount} onClick={onClick} title={title}>
      <span className={style.count}>{totalCount ? totalCount : 0}</span>
    </div>
  );
};

export default TotalCount;
