import * as XLSX from "xlsx";
import React from "react";
import { saveAs } from "file-saver";
import { BsDownload } from "react-icons/bs";
import { useQuery } from "react-query";
import { ProjectLeadsData } from "../../../../Utils/axios/PortalManagement";
const ExcelFileDownloadPartner = ({ totalCount, filter, ml }) => {
  console.log("total", totalCount);
  const { refetch } = useQuery(["downloadData"], () => ProjectLeadsData(totalCount, 1, filter), {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (res) => {
      handleDownloadButtonClick(res?.partnerLeads);
    },
  });

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Partner Name",
        "Week",
        "Source Of Lead",
        "T.A.",
        "Company Size",
        "Status",
        "Source",
        "Location",
        "LinkedIn",
      ], // Header row
      ...data.map((val) => [
        val?.partnerName,
        val?.week,
        val?.sourceOfLead,
        val?.talentAcquisitionName,
        val?.companySize,
        val?.statusName,
        val?.sourceName,
        val?.locationName,
        val?.linkedInUrl,
      ]), // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);
    downloadExcelFile(workbook);
  }
  return (
    <div>
      <BsDownload
        style={{
          fontSize: "1.5rem",
          color: "#C3B14B",
          fontWeight: "bold",
          marginLeft: ml ? ml : "2rem",
          cursor: "pointer",
        }}
        onClick={() => refetch()}
      />
    </div>
  );
};

export default ExcelFileDownloadPartner;
