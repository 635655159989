import ExInNavigation from "../Pages/Expense_Invoice/navigation/ExInNavigation.js";

const ExpenseInvoiceRoute = [
  {
    path: "/exin-navigation",
    component: ExInNavigation,
  },
  // Add more route configurations here as needed
];

export default ExpenseInvoiceRoute;
