import React, { useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import style from "./PriceList.module.css";
import ReasonImg from "../../../../assets/Reason.png";
import { Modal } from "react-bootstrap";

const PriceList = ({ data }) => {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");
  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className={style.main_div}>
      <p className={style.heading}>Additional Information</p>
      <div className={style.table_div}>
        <div className={style.table}>
          <div className={style.th}>
            <p className={style.tableContent}>Last Client Rate</p>
            <p className={style.tableContent}>Current Client Rate</p>
            <p className={style.tableContent}>Revised</p>
            <p className={style.tableContent}>Approved By</p>
            <p className={style.tableContent}>Reason</p>
          </div>
        </div>
        {data?.map((item) => (
          <div className={style.td}>
            <p className={style.para}>
              <CurrencyRupeeIcon className={style.rupee_icon} />
              {item?.lastClientRate || "NA"}
            </p>
            <p className={style.para}>
              {" "}
              <CurrencyRupeeIcon className={style.rupee_icon} />
              {item?.currentClientRate || "NA"}
            </p>
            <p className={style.para}>
              {item?.isRevised === true
                ? "Done"
                : item?.isRevised === false
                ? "Defer"
                : "NA"}
            </p>
            <p className={style.para}>{item?.approvedByName}</p>
            <p className={style.para}>
              <div className={style.reason_img_div}>
                <img
                  onClick={() => {
                    setShow(true);
                    setReason(item?.reason);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      setShow(true);
                      setReason(item?.reason);
                    }
                  }}
                  tabIndex={0}
                  className={style.reason_img}
                  src={ReasonImg}
                  alt="img"
                />
              </div>
            </p>
          </div>
        ))}
      </div>

      <Modal show={show} centered size="md" onHide={handleClose}>
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#00979d" }}>Reason</h6>
        </Modal.Header>
        <div className={style.modal_para_div}>
          {reason
            ? reason?.split("\n")?.map((line, index) => (
                <>
                  {line}
                  {index !== reason?.split("\n")?.length - 1 && <br />}
                </>
              ))
            : ""}
        </div>
      </Modal>
    </div>
  );
};

export default PriceList;
