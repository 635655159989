import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import rubbishBinImg from "../../../../../assets/deleteIcon.png";
import rubbishBinImg1 from "../../../../../assets/rubbish-bin (2).png";
import pencilEditButtonImg from "../../../../../assets/editIcon.png";
import pencilEditButtonImg1 from "../../../../../assets/pencil-edit-button (2).png";
import Moment from "moment";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import constants, { employeeId } from "../../../../../Utils/constants";
import ConfirmPopup from "../../../../taskTracker/taskAssignedByMe/component/ConfirmPopup";
import style from "./TableRow.module.css";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const TableRow = (props) => {
  const history = useHistory();
  const [confirmModal, setConfirmModal] = useState(false);
  const [taskCommentId, setTaskCommentId] = useState();
  function handleConfirmCommentPopupOpen(commentId) {
    setTaskCommentId(commentId);
    setConfirmModal(true);
  }
  const profile = cookie.load("profile");
  const baseUrl = constants.apiBaseUrlResource;

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const handleRedirect = (historyy) => {
    {
      historyy === "pool"
        ? setTimeout(() => {
            history.push(`/PoolResourcesDescription`);
          }, 2000)
        : historyy === "offshore"
        ? setTimeout(() => {
            history.push(`/ResourcesOffshoreDescription`);
          }, 2000)
        : setTimeout(() => {
            history.push(`/AllocationOnsiteDescription`);
          }, 2000);
    }
  };
  function handleConfirmPopupClose() {
    setConfirmModal(false);
  }
  const deleteData = () => {
    axios
      .delete(
        baseUrl + `/deletePoolResourceComment?poolCommentId=${taskCommentId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        }
      )
      .then((res) => {
        if (res.data.responseStatus.statusCode == 1) {
          notify1("You cannot delete others' comments");
        } else {
          // handleRedirect(props?.historyy);
          notify("Comment Deleted Successfully");
          props.setShow(!props.show);
          setConfirmModal(false);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const AddTaskhandler = () => {
    history.push({
      pathname: "/CreateTask",
      state: {
        task: props.OfShoreData,
        comment: props.data.comment,
      },
    });
  };

  const notify = (message) =>
    toast(message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const notify1 = (message) =>
    toast.error(message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const commentChange = (comment) => {
    let data = comment.split("\n");
    let newData = data.map((val) => {
      return (
        <p>
          {val}
          <br></br>
        </p>
      );
    });
    return newData;
  };
  return (
    <tr style={{ borderBottom: "2px solid #F0F0F0", textAlign: "start" }}>
      <td
        style={{
          padding: "7px 6px",
          width: "10%",
          fontSize: "13px",
          paddingLeft : "25px",
        }}
      >
        {Moment(props.data.poolModifiedDate).format("DD MMM YY")}
      </td>
      <td style={{ fontSize: "initial", fontSize: "13px", width: "17%", paddingLeft: "25px" }}>
        {props.data.name}
      </td>
      <td style={{ fontSize: "initial", fontSize: "13px", width: "60%", paddingLeft: "25px" }}>
        {commentChange(props.data.comment)}
      </td>

      <td>
        {props.data.name === profile.name ? (
          <div>
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                props.clickHandler(props.data.poolCommentId, "edit")
              }
              src={pencilEditButtonImg}
            />
            <img
              onClick={() =>
                handleConfirmCommentPopupOpen(props.data.poolCommentId)
              }
              style={{
                paddingLeft: "11px",
                cursor: "pointer",
              }}
              src={rubbishBinImg}
            />
            {props?.hide!==true?<BootstrapTooltip title="Add Task">
              <span onClick={AddTaskhandler} className={style.plus}>
                <span className={style.count}>
                  <ControlPointIcon />
                </span>
              </span>
            </BootstrapTooltip>:null}
          </div>
        ) : (
          <div>
            <img src={pencilEditButtonImg1} alt="img" />

            <img
              style={{
                paddingLeft: "11px",
              }}
              src={rubbishBinImg1}
              alt="img"
            />
            {props?.hide!==true? <BootstrapTooltip title="Add Task">
              <span onClick={AddTaskhandler} className={style.plus}>
                <span className={style.count}>
                  <ControlPointIcon />
                </span>
              </span>
            </BootstrapTooltip>:null}
          </div>
        )}
      </td>

      <ConfirmPopup open={confirmModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to delete?
          </h5>
          <div className={style.btnCont}>
            <button className={style.yesBtn} onClick={deleteData}>
              Yes
            </button>
            <button className={style.noBtn} onClick={handleConfirmPopupClose}>
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </tr>
  );
};

export default TableRow;
