import React, { useState } from "react";
import style from "../../candidateOffer/CandidateOffer.module.css";
import style1 from "../createSalary/CreateSalary.module.css"
import classNames from "classnames";
import editIcon from "../../../../../assets/edit_icon.png";
import { BsFillInfoCircleFill } from "react-icons/bs";
import classes from "./CurrExpSalary.module.css";
import visible from "../../../../../assets/visible.svg"

import {
  getEstimateByCandidateId,
  getCandidateById,
  checkEligibilityForCreateOffer,
  getCandidateSalaryAssById,
  updateCurrSalary,
  getBreakDowns,
  revokeOffer,
  cancelOffer,
  approveCompensationBreakdown
} from "../../../../../Utils/axios/api";
import { useQuery, useMutation } from "react-query";
import Select from "react-select";
import calender from "../../../../../assets/calblue.png";
// import { customStyles5 } from "../../utils/style";
import moment from "moment";
import BreadCrum from "../../../breadcrums/BreadCrum";
import { toast } from "react-toastify";
import CustomTooltip from "../../../customTooltip/CustomTooltip";
// import ConfirmPopup from "../../../userManegment/pageLayout/viewUser/ConfirmPopup";
import dropdownIcon from "../../../../../assets/downIcon.png";
import { useEffect } from "react";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
// import ATSButton from "./../../customTooltip/ATSButton";
import { accessCode, employeeId } from "../../../../../Utils/constants";
import ValidationEmpAccess from "../../validationEmpAccess/ValidationEmpAccess";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { notifyError, notifySuccess } from "../../../utils/notify";
import ATSButton from "../../../customTooltip/ATSButton";
import FixedSalaryDistribution from "../offerProcessModals/FixedSalaryDistribution";
import ConfirmModalOffer from "../offerProcessModals/ConfirmModalOffer";

function CurrExpSalary({
  setNextScreen,
  setPrevData,
  prevData,
  validateAccess,
}) {
  const [show2, setShow2] = useState(false);

  const [candidateSalary, setCandidateSalary] = useState();
  const [editSalary, setEditSalary] = useState(false);
  const [showFSDModal, setShowFSDModal] = useState(false);
  const [currentOfferId, setCurrentOfferId] = useState();
  const [showConfirmModal1, setShowConfirmModal1] = useState(false);
  const [showConfirmModal2, setShowConfirmModal2] = useState(false);
  const [action, setAction] = useState("");
  const [showingDetail, setShowingDetail] = useState([]);

  const { data: candidateData, refetch: refetchCandidate } = useQuery(
    ["getCandidateById"],
    () => getCandidateById(prevData?.candidateId),
    { refetchOnWindowFocus: false }
  );
 
  const { data: breakdownData, refetch: refetchBreakdown} = useQuery(
    ["getBreakDowns"],
    ()=>getBreakDowns(prevData?.candidateId),
    { refetchOnWindowFocus: false }
  )

  const { refetch } = useQuery(
    ["checkEligibilityForCreateOffer"],
    () => checkEligibilityForCreateOffer(prevData?.candidateId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        if (res === true) {
          setShow2(true);
        } else {
          if(!candidateSalary){
          setNextScreen("CreateSalary");
          setPrevData((prevData) => {
            const pages = [
              prevData.page1,
              prevData.page2,
              prevData.page3,
              prevData.page4,
              prevData.page5,
              prevData.page6,
            ].filter(page => page !== null);
            const newPageKey = `page${pages.length + 1}`;
            return {
              ...prevData,
              sidebar: prevData?.sidebar,
              candidateId: candidateData?.id,
              [newPageKey]: "CreateSalary",
              active: "CreateSalary",
            };
          })}
          else{
            setNextScreen("CreateBreakDown");
          setPrevData((prevData) => {
            const pages = [
              prevData.page1,
              prevData.page2,
              prevData.page3,
              prevData.page4,
              prevData.page5,
              prevData.page6,
            ].filter(page => page !== null);
            const newPageKey = `page${pages.length + 1}`;
            return {
              ...prevData,
              sidebar: prevData?.sidebar,
              candidateId: candidateData?.id,
              [newPageKey]: "CreateBreakDown",
              active: "CreateBreakDown",
            };
          })
          }

        }
      },
    }
  );

  const { data } = useQuery(
    ["candidateId"],
    ()=> getEstimateByCandidateId(prevData.candidateId),
    { refetchOnWindowFocus: false }
  );

  const cancelMutation = useMutation(cancelOffer, {
    onSuccess: (res) => {
      setShowConfirmModal2(false)
      if (res?.data?.responseStatus?.statusCode === 1) {
        notifySuccess(res?.data?.responseData);
        setNextScreen("CreateBreakDown");
        setPrevData((prevData) => {
          const pages = [
            prevData.page1,
            prevData.page2,
            prevData.page3,
            prevData.page4,
            prevData.page5,
            prevData.page6,
          ].filter(page => page !== null);
          const newPageKey = `page${pages.length + 1}`;
          return {
            ...prevData,
            sidebar: prevData?.sidebar,
            candidateId: candidateData?.id,
            [newPageKey]: "CreateBreakDown",
            active: "CreateBreakDown",
          };
        })
      } else {
        notifyError("Something went wrong");
      }
      refetchCandidate();
      refetchBreakdown();
    },
    onError: (err) => {
      notifyError(err);
    },
  });

  const changeOfferStatusToCancel = () => {
    cancelMutation.mutate({
      offerId: breakdownData[0]?.offerResponse?.offerId,
      empId: employeeId,
      cancelRevokeDate: moment(new Date())
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      offerStatus: "Offer Cancel",
    });
  };

  useEffect(()=>{
    let l = data?.data?.responseData?.length;
    setCandidateSalary(data?.data?.responseData[l-1])
  }, [data]);
  
  const updateSavedSalary = useMutation(updateCurrSalary, {
    onSuccess: (res) => {
      setEditSalary(false);
      }
    },
  );


  const updateSalary = ()=>{
    updateSavedSalary.mutate({
      "id": candidateSalary?.id,
      "candidateId": prevData?.candidateId,
      "ctcNote": candidateSalary?.ctcNote,
      "expectedCtcNote": candidateSalary?.expectedCtcNote,
      "factoredNote": candidateSalary?.factoredNote,
      "monthlyCtc": candidateSalary?.monthlyCtc,
      "monthlyExpectedCtc": candidateSalary?.monthlyExpectedCtc,
      "monthlyFactored": candidateSalary?.monthlyFactored,
      "isPercentHike": candidateSalary?.isPercentHike,
      "hike": candidateSalary?.hike
    })
  }

  const approveBreakDown = useMutation(approveCompensationBreakdown, {
    onSuccess: (res)=>{
      notifySuccess(res)
      setShowConfirmModal1(false);
      refetchCandidate();
      refetchBreakdown();
    }
  })

  const approveBreakDownOnClick = ()=>{
    approveBreakDown.mutate({
      approveBy: employeeId,
      offerId: breakdownData[0]?.offerResponse?.offerId
    })
  }
  
  const handleApproveRevise = (act)=>{
    setAction(act)
    if(act=="approve"){
      setShowConfirmModal1(true)
    } else{
      setShowConfirmModal2(true)
    }
  }

  useEffect(() => {
    if(candidateData?.isPercentHike===undefined){}
    else if(candidateSalary?.isPercentHike){
      setCandidateSalary(prev =>({
          ...prev,
          monthlyExpectedCtc: Number(candidateSalary?.monthlyCtc) + candidateSalary?.hike/100 * Number(candidateSalary?.monthlyCtc),
      }))
    }
    else if(!candidateSalary?.isPercentHike){
      setCandidateSalary(prev =>({
          ...prev,
          monthlyExpectedCtc: Number(candidateSalary?.hike)
      }))
    }

  }, [candidateSalary?.hike, candidateSalary?.isPercentHike, candidateSalary?.monthlyCtc, candidateSalary?.monthlyFactored])

  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      >
        {!candidateSalary &&
          <>
            {ValidationEmpAccess(validateAccess, accessCode?.create_offer) && (
              <button
                className={style.createOfferBtn}
                onClick={() => {
                  refetch();
                }}
              >
                <span className={style.plus}> +</span>
                Create Salary
              </button>
            )}
          </>
        }
        {(breakdownData?.length<1 && candidateSalary) || (breakdownData ? breakdownData[0]?.offerResponse?.status?.id===10 : null) ?
          <>
            {ValidationEmpAccess(validateAccess, accessCode?.create_offer) && (
              <button
                className={style.createOfferBtn}
                onClick={() => {
                  refetch();
                }}
              >
                <span className={style.plus}> +</span>
                Create BreakDown
              </button>
            )}
          </> : null
        }       
          
      </BreadCrum>
      <div className={style.candidateOffer}>
        {/* card */}
        <div className={style.card}>
          <div className={style.th1}>
            <p>Candidate Details</p>
            <hr />
          </div>
          <div className={style.td1}>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Name</p>
                {
                  prevData?.page1 === "ViewCandidate" || prevData?.page2 === "ViewCandidate" || prevData?.page3 === "ViewCandidate" || prevData?.page4 === "ViewCandidate" || prevData?.page5 === "ViewCandidate" ?
                    <p className={`${style.cu_field2}`} >{candidateData?.name}</p> :
                    <p className={`${style.cu_field2} ${style.link}`}
                      onClick={() => {
                        setNextScreen("ViewCandidate");
                        setPrevData((prevData) => {
                          const pages = [
                            prevData.page1,
                            prevData.page2,
                            prevData.page3,
                            prevData.page4,
                            prevData.page5,
                            prevData.page6,
                          ].filter(page => page !== null);
                          const newPageKey = `page${pages.length + 1}`;
                          return {
                            ...prevData,
                            sidebar: prevData?.sidebar,
                            candidateId: prevData?.candidateId,
                            [newPageKey]: "ViewCandidate",
                            active: "ViewCandidate",
                          };
                        });
                      }}
                    >{candidateData?.name}</p>
                }
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Email Id</p>
                <CustomTooltip
                  value={candidateData?.email}
                  styles={{
                    padding: "7px 5px 10px 4px",
                    marginTop: "10px",
                    color: "#666",
                  }}
                  limit={33}
                  index={"email"}
                />
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Technology</p>
                <CustomTooltip
                  value={candidateData?.coreSkill}
                  styles={{
                    padding: "7px 5px 10px 4px",
                    marginTop: "10px",
                    color: "#666",
                  }}
                  limit={30}
                  index={"Technology"}
                />
              </div>
              <div className={style.cu_col4}>
                <p className={style.cu_label}>Last Company Name</p>
                <p className={style.cu_field2}>
                  {candidateData?.workExperiences?.[0]?.companyName}
                </p>
              </div>
            </div>
          </div>
        </div>
        {data && candidateSalary && <div className={style.card} id='current_ctc'>
                <div className={style.th1} style={{marginBottom: "1%", display: "flex", justifyContent: "space-between", padding: "0 0.5%"}}>
                  <p></p>
                    <p>Salary Details</p>
                    <div onClick={()=>setEditSalary(true)} style={{cursor: "pointer"}}><img
                        src={editIcon}
                        alt="Edit"
                        width="20rem"
                        height="20rem"
                      /></div>
                </div>
                {!editSalary && <div className={style.td1}><table className={style1.salTab}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>CTC</th>
                                <th colSpan="2" className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>Factorised</th>
                                <th rowSpan="2" colSpan="2" className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>Hike Type</th>
                                <th colSpan="4" className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}} >Expected CTC</th>
                            </tr>
                            <tr>
                                <th className={classNames(style1.salTab, style1.salMinTH)} style={{width: "12.5%"}}>Monthly</th>
                                <th className={classNames(style1.salTab, style1.salMinTH)} style={{width: "12.5%"}}>Annual</th>
                                <th className={classNames(style1.salTab, style1.salMinTH)} style={{width: "12.5%"}}>Monthly</th>
                                <th className={classNames(style1.salTab, style1.salMinTH)} style={{width: "12.5%"}}>Annual</th>
                                <th colSpan="2" className={classNames(style1.salTab, style1.salTH)}  style={{width: "12.5%"}}>Monthly</th>
                                <th colSpan="2" className={classNames(style1.salTab, style1.salTH)}  style={{width: "12.5%"}}>Annual</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={classNames(style1.salTab, style1.salMinTH)} >
                                    {candidateSalary?.monthlyCtc ? candidateSalary?.monthlyCtc : ""}
                                </td>
                                <td className={classNames(style1.salTab, style1.salMinTH)} >
                                    <div className={style1.annSal}>
                                      {candidateSalary?.monthlyCtc ? candidateSalary?.monthlyCtc*12 : ""}
                                    </div>
                                </td>
                                <td className={classNames(style1.salTab, style1.salMinTH)} >
                                    {candidateSalary?.monthlyFactored ? candidateSalary?.monthlyFactored : ""}
                                </td>
                                <td className={classNames(style1.salTab, style1.salMinTH)} >
                                    <div className={style1.annSal}>
                                      {candidateSalary?.monthlyFactored ? candidateSalary?.monthlyFactored*12 : ""}
                                    </div>
                                </td>

                                <td rowSpan="2" colSpan="2" className={classNames(style1.salTab, style1.salMinTH)}>
                                    <div>Hike Type : {candidateSalary ? candidateSalary?.isPercentHike ? "Percentage" : "Package" : ""}</div>
                                    <div>Value : {candidateSalary?.hike}</div>
                                </td>

                                <td colSpan="2" className={classNames(style1.salTab, style1.salMinTH)} >
                                    {candidateSalary?.monthlyExpectedCtc ? Number(candidateSalary?.monthlyExpectedCtc).toFixed(2) : ""}
                                </td>
                                <td colSpan="2" className={classNames(style1.salTab, style1.salMinTH)} >
                                    {candidateSalary?.monthlyExpectedCtc ? Math.round(Number(candidateSalary?.monthlyExpectedCtc*12)) : ""}
                                </td>
                            </tr>
                            <tr>
                                <td className={classNames(style1.salTab, style1.salMinTH, style1.salNoottes)} colSpan="2">
                                  <div style={{height: "3rem", overflowY: "auto"}}>{candidateSalary?.ctcNote}</div>
                                </td>
                                <td className={classNames(style1.salTab, style1.salMinTH)} colSpan="2">
                                  <div style={{height: "3rem", overflowY: "auto"}}>{candidateSalary?.factoredNote}</div>
                                </td>
                                <td className={classNames(style1.salTab, style1.salMinTH)} colSpan="4">
                                  <div style={{height: "3rem", overflowY: "auto"}}>{candidateSalary?.expectedCtcNote}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    </div>}
                    {editSalary && <div className={style.td1}><table className={style1.salTab}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>CTC</th>
                                <th colSpan="2" className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>Factorised</th>
                                <th rowSpan="2" colSpan="2" className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>Hike Type</th>
                                <th colSpan="4" className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}} >Expected CTC</th>
                            </tr>
                            <tr>
                                <th className={classNames(style1.salTab, style1.salMinTH)} style={{width: "12.5%"}}>Monthly</th>
                                <th className={classNames(style1.salTab, style1.salMinTH)} style={{width: "12.5%"}}>Annual</th>
                                <th className={classNames(style1.salTab, style1.salMinTH)} style={{width: "12.5%"}}>Monthly</th>
                                <th className={classNames(style1.salTab, style1.salMinTH)} style={{width: "12.5%"}}>Annual</th>
                                <th colSpan="2" className={classNames(style1.salTab, style1.salTH)}  style={{width: "12.5%"}}>Monthly</th>
                                <th colSpan="2" className={classNames(style1.salTab, style1.salTH)}  style={{width: "12.5%"}}>Annual</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={classNames(style1.salTab, style1.salMinTH)} >
                                    <input 
                                        className={style1.salary} 
                                        type="text" name="ctc-monthly" 
                                        placeholder="CTC" 
                                        onChange={(e)=>{setCandidateSalary(prev=>({...prev, monthlyCtc: Number(e.target.value)}))}} 
                                        value={candidateSalary?.monthlyCtc} 
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 38 || e.keyCode === 40) {
                                            e.preventDefault();
                                            }
                                        }}
                                        onKeyPress={(e) =>
                                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                            e.which > 57) &&
                                        e.preventDefault()
                                        }/>
                                </td>
                                <td className={classNames(style1.salTab, style1.salMinTH)} >
                                    <div className={style1.annSal}>{candidateSalary?.monthlyCtc*12}</div>
                                </td>
                                <td className={classNames(style1.salTab, style1.salMinTH)} >
                                    <input className={style1.salary} 
                                        type="text" 
                                        name="factorised-monthly" 
                                        placeholder="Factorised"  
                                        onChange={(e)=>{setCandidateSalary(prev=>({...prev, monthlyFactored: Number(e.target.value)}))}} 
                                        value={candidateSalary?.monthlyFactored} 
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 38 || e.keyCode === 40) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onKeyPress={(e) =>
                                            ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                                e.which > 57) &&
                                            e.preventDefault()
                                        }/>
                                </td>
                                <td className={classNames(style1.salTab, style1.salMinTH)} >
                                    <div className={style1.annSal}>{candidateSalary?.monthlyFactored*12}</div>
                                </td>

                                <td rowSpan="2" colSpan="2" className={classNames(style1.salTab, style1.salMinTH)}>
                                    <div className={style1.hikeOpts}>
                                    <div><input type='radio' name='hikeType' id='percentHike' checked={candidateSalary?.isPercentHike} onChange={()=>{setCandidateSalary(prev=>({...prev, isPercentHike: true}))}}/>
                                    <label htmlFor="percentHike">Percentage</label></div>

                                    <div><input type='radio' name='hikeType' id='PackageHike' checked={!candidateSalary?.isPercentHike} onChange={()=>{setCandidateSalary(prev=>({...prev, isPercentHike: false}))}}/>
                                    <label htmlFor="Package">Package</label></div>
                                    </div>
                                    <div style={{marginTop: "1rem"}}>
                                        <input 
                                            type='text'
                                            className={style1.hike}
                                            placeholder='Enter Hike' 
                                            onChange={(e)=>setCandidateSalary(prev=>({...prev, hike: e?.target?.value}))}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 38 || e.keyCode === 40) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onKeyPress={(e) =>
                                                ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                                    e.which > 57) &&
                                                e.preventDefault()
                                            }
                                            value={candidateSalary?.hike}
                                            />
                                    </div>
                                </td>

                                <td colSpan="2" className={classNames(style1.salTab, style1.salMinTH)} >
                                    {Number(candidateSalary?.monthlyExpectedCtc).toFixed(2)}
                                </td>
                                <td colSpan="2" className={classNames(style1.salTab, style1.salMinTH)} >
                                    {Number(candidateSalary?.monthlyExpectedCtc*12).toFixed(2)}
                                </td>
                            </tr>
                            <tr>
                                <td className={classNames(style1.salTab, style1.salMinTH)} colSpan="2">
                                    <textarea className={style1.salNote} name="ctc-notes" placeholder="Notes for CTC" onChange={(e)=>{setCandidateSalary(prev=>({...prev, ctcNote: e.target.value}))}} value={candidateSalary?.ctcNote}></textarea>
                                </td>
                                <td className={classNames(style1.salTab, style1.salMinTH)} colSpan="2">
                                    <textarea className={style1.salNote} name="factorised-notes" placeholder="Notes for Factorised" onChange={(e)=>{setCandidateSalary(prev=>({...prev, factoredNote: e.target.value}))}} value={candidateSalary?.factoredNote}></textarea>
                                </td>
                                <td className={classNames(style1.salTab, style1.salMinTH)} colSpan="4">
                                    <textarea className={style1.salNote} name="factorised-notes" placeholder="Notes for Expected" onChange={(e)=>{setCandidateSalary(prev=>({...prev, expectedCtcNote: e.target.value}))}} value={candidateSalary?.expectedCtcNote}></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                      <button className={style.createOfferBtn} onClick={()=>updateSalary()}>Update Salary</button>
                      <button className={style.createOfferBtn} onClick={()=>setEditSalary(false)}>Cancel</button>
                    </div>
                    </div>}
            </div>}
          {breakdownData?.length>0 && <div className={style.card}><div className={style.td1}>
            <div className={style.th1}>
              <p style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}><span></span>Offer Details <BsFillInfoCircleFill onClick={()=>{setShowFSDModal(true); setCurrentOfferId(breakdownData[0]?.offerResponse?.offerId)}}/></p>
            </div>
            <table className={style1.salTab}>
              <thead>
                <tr>
                  <th className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>Component</th>
                  <th className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>Monthly</th>
                  <th className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>Annual</th>
                  <th className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}} >Comment</th>
                </tr>
              </thead>
              <tbody>
                {breakdownData[0]?.component?.slice().reverse().map((item, index)=>{
                  return <tr key={`break${index}`}>
                    <td style={{minWidth: "25%", width: "25%"}} className={classNames(style1.salTab, style1.salTH)}>
                      {item?.componentName}
                    </td>
                    <td style={{minWidth: "25%", width: "25%"}} className={classNames(style1.salTab, style1.salTH)}>
                      {item?.monthly}
                    </td>
                    <td style={{minWidth: "25%", width: "25%"}} className={classNames(style1.salTab, style1.salTH)}>
                      {item?.annually}
                    </td>
                    <td style={{minWidth: "25%", width: "25%"}} className={classNames(style1.salTab, style1.salTH)}>
                      {item?.comment}
                    </td>
                  </tr>
                })}
                
              </tbody>
            </table>
            <div className={style1.Cu_row1} style={{marginTop: "2rem"}}>
              <div className={style1.cu_col1}>
                <p className={style1.cu_label}>Offer Number</p>
                <p className={style1.cu_field4}>{breakdownData[0]?.offerResponse?.offerNumber}</p>
              </div>
              <div className={style1.cu_col2}>
                <p className={style1.cu_label}>Release Date</p>
                <p className={style1.cu_field4}>{breakdownData[0]?.offerResponse?.offerReleaseDate
                  ? moment(breakdownData[0]?.offerResponse?.offerReleaseDate).format(
                    "DD MMM YY"
                  )
                  : "-"}</p>
              </div>
              <div className={style1.cu_col3}>
                <p className={style1.cu_label}>Offered CTC</p>
                <p className={style1.cu_field4}>{breakdownData[0]?.offerResponse?.offeredCtc}</p>
              </div>
              <div className={style1.cu_col4}>
                <p className={style1.cu_label}>Bond</p>
                <p className={style1.cu_field4}>{`${breakdownData[0]?.offerResponse?.bond === true ? "Yes" : "No"}`}</p>
              </div>
              <div className={style1.cu_col5}>
                <p className={style1.cu_label}>Prepared By</p>
                <p className={style1.cu_field4}>{breakdownData[0]?.offerResponse?.preparedBy}</p>
              </div>
            </div>
            <div className={style1.Cu_row1}>
              <div className={style1.cu_col1}>
                <p className={style1.cu_label}>Reviewed By</p>
                <p className={style1.cu_field4}>{breakdownData[0]?.offerResponse?.reviewedBy ? breakdownData[0]?.offerResponse?.reviewedBy : "_"}</p>
              </div>
              <div className={style1.cu_col2}>
                <p className={style1.cu_label}>Status</p>
                <p className={style1.cu_field4}>{breakdownData[0]?.offerResponse?.status?.status}</p>
              </div>
              <div className={style1.cu_col3}>
                <p className={style1.cu_label}>Expected DOJ</p>
                <p className={style1.cu_field4}>{breakdownData[0]?.offerResponse?.expectedJoiningDate &&
                  moment(breakdownData[0]?.offerResponse?.expectedJoiningDate).format(
                    "DD MMM YY"
                  )}</p>
              </div>
              <div className={style1.cu_col4}>
                <p className={style1.cu_label}>Employment Type</p>
                <p className={style1.cu_field4}>{breakdownData[0]?.offerResponse?.employmentType}</p>
              </div>
              <div className={style1.cu_col5}>
                <p className={style1.cu_label}>Offer Decline/ <br />Accepted Date</p>
                <p className={style1.cu_field4}>{breakdownData[0]?.offerResponse?.acceptDeclineDate
                  ? moment(breakdownData[0]?.offerResponse?.acceptDeclineDate).format(
                    "DD MMM YY"
                  )
                  : "-"}</p>
              </div>
            </div>
            
            </div>
            
          </div>}
          {breakdownData?.length>0 ? (((!breakdownData[0]?.offerResponse?.isApproved) && (breakdownData[0]?.offerResponse?.status?.id===10 ? false : true)) && <div className={style1.twobuttons}>
              <ATSButton 
                  onClick={()=> handleApproveRevise("approve")}
                >Approve</ATSButton> 
              <ATSButton
                onClick={() => handleApproveRevise("revise")}
              >Revise</ATSButton>
            </div>) : null
          }

          {breakdownData?.length>1 && <div className={style.card}>
            <div className={style.th1}>
              <p>Previous Offers</p>
            </div>
          <div className={classes.table}>
              <div className={classes.th}>
                <p className={classes.tableContent1}>Offer Number</p>
                <p className={classes.tableContent1}>Offer Release Date</p>
                <p className={classes.tableContent1}>CTC</p>
                <p className={classes.tableContent1}>Reviewed By</p>

                <p className={classes.tableContent1}>Status </p>
                <p className={classes.tableContent1} ></p>
              </div>
            <div>
              {breakdownData && breakdownData?.length > 0 ? (
                breakdownData?.map((item, index) => {
                  return (index!==0 && <div className={classes.hideContainer} style={{height: showingDetail===item?.offerResponse?.offerNumber ? `${258 + 33*(item?.component.length)}px`: ""}}>
                    <div className={classes.td} key={index}>
                      <p
                        className={`${classes.tableContent}`}
                      >
                        {item?.offerResponse?.offerNumber}
                      </p>

                      <p className={classes.tableContent}>
                        {item?.offerResponse?.offerReleaseDate
                          ? moment(item?.offerReleaseDate).format("DD MMM YY")
                          : "-"}
                      </p>

                      <p className={classes.tableContent}>{item?.offerResponse?.offeredCtc}</p>

                      <p className={classes.tableContent}>
                        {item?.offerResponse?.reviewedBy ? item?.offerResponse?.reviewedBy : "-"}
                      </p>

                      <p className={classes.tableContent}>{item?.offerResponse?.status?.status}</p>
                      <p className={classes.tableContent}>

                        <img style={{ cursor: "pointer" }} title="View Offer" src={visible} alt="SVG Icon"
                          onClick={() => {
                            if(showingDetail!==item?.offerResponse?.offerNumber) {console.log("vfdbn"); setShowingDetail(item?.offerResponse?.offerNumber)}
                            else {console.log("dfg"); setShowingDetail(null)}
                          }}
                        >
                        </img>
                      </p>
                      
                    </div>
                    <div style={{display: "flex", justifyContent: "flex-end", paddingBottom: "4px", color: "rgb(44, 116, 44)"}}><BsFillInfoCircleFill onClick={()=>{setShowFSDModal(true); setCurrentOfferId(item?.offerResponse?.offerId)}}/></div>
                    <table className={style1.salTab}>
              <thead>
                <tr>
                  <th className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>Component</th>
                  <th className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>Monthly</th>
                  <th className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}}>Annual</th>
                  <th className={classNames(style1.salTab, style1.salTH)} style={{minWidth: "25%", width: "25%"}} >Comment</th>
                </tr>
              </thead>
              <tbody>
                {breakdownData[index]?.component?.slice().reverse().map((item1, index)=>{
                  return <tr key={`break${index}`}>
                    <td style={{minWidth: "25%", width: "25%"}} className={classNames(style1.salTab, style1.salTH)}>
                      {item1?.componentName}
                    </td>
                    <td style={{minWidth: "25%", width: "25%"}} className={classNames(style1.salTab, style1.salTH)}>
                      {item1?.monthly}
                    </td>
                    <td style={{minWidth: "25%", width: "25%"}} className={classNames(style1.salTab, style1.salTH)}>
                      {item1?.annually}
                    </td>
                    <td style={{minWidth: "25%", width: "25%"}} className={classNames(style1.salTab, style1.salTH)}>
                      {item1?.comment}
                    </td>
                  </tr>
                })}
                
              </tbody>
            </table>
            <div className={style1.Cu_row1} style={{marginTop: "2rem"}}>
              <div className={style1.cu_col1}>
                <p className={style1.cu_label}>Offer Number</p>
                <p className={style1.cu_field4}>{item?.offerResponse?.offerNumber}</p>
              </div>
              <div className={style1.cu_col2}>
                <p className={style1.cu_label}>Release Date</p>
                <p className={style1.cu_field4}>{item?.offerResponse?.offerReleaseDate
                  ? moment(item?.offerResponse?.offerReleaseDate).format(
                    "DD MMM YY"
                  )
                  : "-"}</p>
              </div>
              <div className={style1.cu_col3}>
                <p className={style1.cu_label}>Offered CTC</p>
                <p className={style1.cu_field4}>{item?.offerResponse?.offeredCtc}</p>
              </div>
              <div className={style1.cu_col4}>
                <p className={style1.cu_label}>Bond</p>
                <p className={style1.cu_field4}>{`${item?.offerResponse?.bond === true ? "Yes" : "No"}`}</p>
              </div>
              <div className={style1.cu_col5}>
                <p className={style1.cu_label}>Prepared By</p>
                <p className={style1.cu_field4}>{item?.offerResponse?.preparedBy}</p>
              </div>
            </div>
            <div className={style1.Cu_row1}>
              <div className={style1.cu_col1}>
                <p className={style1.cu_label}>Reviewed By</p>
                <p className={style1.cu_field4}>{item?.offerResponse?.reviewedBy ? item?.offerResponse?.reviewedBy : "_"}</p>
              </div>
              <div className={style1.cu_col2}>
                <p className={style1.cu_label}>Status</p>
                <p className={style1.cu_field4}>{item?.offerResponse?.status?.status}</p>
              </div>
              <div className={style1.cu_col3}>
                <p className={style1.cu_label}>Expected DOJ</p>
                <p className={style1.cu_field4}>{item?.offerResponse?.expectedJoiningDate &&
                  moment(item?.offerResponse?.expectedJoiningDate).format(
                    "DD MMM YY"
                  )}</p>
              </div>
              <div className={style1.cu_col4}>
                <p className={style1.cu_label}>Employment Type</p>
                <p className={style1.cu_field4}>{item?.offerResponse?.employmentType}</p>
              </div>
              <div className={style1.cu_col5}>
                <p className={style1.cu_label}>Offer Decline/ <br />Accepted Date</p>
                <p className={style1.cu_field4}>{item?.offerResponse?.acceptDeclineDate
                  ? moment(item?.offerResponse?.acceptDeclineDate).format(
                    "DD MMM YY"
                  )
                  : "-"}</p>
              </div>
            </div>
                    </div>
                  );
                })
              ) : (
                <p className={classes.no_data}>No Offer Found</p>
              )}
            </div>
          </div>
          </div>}
          <FixedSalaryDistribution showFSDModal={showFSDModal} setShowFSDModal={setShowFSDModal} currentOfferId={currentOfferId}/>
          <ConfirmModalOffer showConfirmModal={showConfirmModal1} setShowConfirmModal={setShowConfirmModal1} action={action} callFunction={approveBreakDownOnClick}/>
          <ConfirmModalOffer showConfirmModal={showConfirmModal2} setShowConfirmModal={setShowConfirmModal2} action={action} callFunction={changeOfferStatusToCancel }/>
      </div>
    </>
  );
}
export default CurrExpSalary;