import React, { useEffect, useState } from "react";
import SideBar from "../component/SideBar";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./Clientdescription.module.css";
import ConfirmPopup from "./ConfirmPopup";
import { api } from "../../CommonApi/api";
import { del, get, post } from "../../CommonApi/axiosCall";
import { Toastify } from "../../../../App";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { rmsAccessCode } from "../../../../Utils/constants";

const Clientdescription = () => {
  const { checkValidateAccess } = useValidationAccess();

  const [clientData, setClientData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function handleConfirmPopupClose() {
    setConfirmModal(false);
  }
  const query = useQuery();
  const id = query.get("id");
  const history = useHistory();

  const editClient = () => {
    history.push(`/EditClient?id=${id}`);
  };

  const deleteClientPopup = () => {
    setConfirmModal(true);
  };
  const deleteClient = (id) => {
    setConfirmModal(true);

    let baseURL = api.deleteClient;
    if (id) {
      baseURL += `?clientid=${id}`;
    }

    del(baseURL)
      .then((res) => {
        Toastify("Client deleted successfully");
        handleRedirect();
        setDisable(true);
      })
      .catch((err) => {});
  };

  const changeStatus = (id, status) => {
    let baseURL = api.changeClientStatus;

    post(baseURL, {
      clientID: id,
      statusTypeId: clientData?.statusId == 1 ? 2 : 1,
    })
      .then((res) => {
        Toastify("Status Changed Successfully");
        handleRedirect();
        setDisable(true);
      })
      .catch((err) => {});
  };

  const getClientDetails = (id) => {
    let baseURL = api.getClientDetails;
    if (id) {
      baseURL += `?id=${id}`;
    }

    get(baseURL)
      .then((res) => {
        setClientData(res.data.responseData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getClientDetails(id);
  }, [id]);

  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/clients");
    }, 2000);
  };

  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.center_div}>
          <div className={style.breadscrumb} style={{ padding: "10px 0px" }}>
            <span style={{ fontWeight: "700" }}>
              View <span className={style.arrow}> </span>Client
              <span className={style.arrow}> {">"} </span>
              <span className={style.recent_tab} style={{ fontWeight: "700" }}>
                {clientData?.name}
              </span>
            </span>
          </div>

          <div className={style.description_box}>
            <div className="container">
              <div className="row">
                <div className="col-3" style={{ width: "40%" }}>
                  <h6>Client Name</h6>
                  <p style={{ textTransform: "capitalize" }}>
                    {clientData?.name}
                  </p>
                </div>
                <div className="col-3" style={{ marginLeft: "35px" }}>
                  <h6>Client Domain</h6>
                  <p style={{ textTransform: "capitalize" }}>
                    {clientData?.domain}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={style.container}>
            {clientData?.status === "Active" ? (
              <>
              {checkValidateAccess(rmsAccessCode?.mark_as_inactive) && <button
                className={style.inActive_btn}
                onClick={() => changeStatus(clientData.clientid, "Active")}
                disabled={disable}
              >
                Mark as Inactive
              </button>}
              </>
            ) : (
              <>
              {checkValidateAccess(rmsAccessCode?.mark_as_inactive) && 
              <button
                className={style.active_btn}
                onClick={() => changeStatus(clientData.clientid, "In_Active")}
                disabled={disable}
              >
                Mark as Active
              </button>}</>
            )}
            {checkValidateAccess(

rmsAccessCode?.delete_client
) && (   <button
              className={style.delete_btn}
              onClick={() => deleteClientPopup()}
              disabled={disable}
            >
              Delete
            </button>)}
            {checkValidateAccess(

rmsAccessCode?.edit_client
) && (  <button
              className={style.edit_btn}
              onClick={editClient}
              disabled={disable}
            >
              Edit
            </button>)}
          </div>
        </div>
        <ConfirmPopup open={confirmModal}>
          <div className={style.confirmPopup}>
            <h5 className={style.confirmPopupTitle}>
              Are you sure you want to delete?
            </h5>
            <div className={style.btnCont}>
              <button
                className={style.yesBtn}
                onClick={() => deleteClient(clientData.clientid)}
              >
                Yes
              </button>
              <button className={style.noBtn} onClick={handleConfirmPopupClose}>
                No
              </button>
            </div>
          </div>
        </ConfirmPopup>

        <ToastContainer />
      </div>
    </>
  );
};

export default Clientdescription;
