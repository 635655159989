import React, { Children } from "react";
import { useHistory } from "react-router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import style from "./BreadCrum.module.css";
import "./style.scss";

function BreadCrum({
  header,
  prevData,
  children,
  setPrevData,
  setNextScreen,
  PlusComponent,
  countComponent,
  filterComponent,
}) {
  const handleHeading1 = (data) => {
    switch (data) {
      case "nonClientCandidateList":
        return "Candidate List";

      default:
        return data;
    }
  };

  const handleHeading = (item) => {
    let data =
      handleHeading1(item)?.charAt(0).toUpperCase() +
      handleHeading1(item)
        ?.slice(1)
        .replace(/([A-Z])/g, " $1")
        .trim();
    return data;
  };

  return (
    <div className={style.header}>
      <div className={style.BreadCrum}>
        <Breadcrumb className={style.BreadCrumBootstrap}>
          {prevData?.page1 && (
            <Breadcrumb.Item
              onClick={() => {
                setNextScreen(prevData?.page1);
                setPrevData((prevData) => ({
                  ...prevData,
                  sidebar: prevData?.page1,
                  page2: null,
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  page: prevData?.page1,
                  active: prevData?.page1,
                }));
              }}
            >
              <span
                className={
                  prevData?.page1 === prevData?.active
                    ? style.blackColor
                    : style.decoration
                }
              >
                {handleHeading(prevData?.page1)}
              </span>
            </Breadcrumb.Item>
          )}
          {prevData?.page2 && (
            <Breadcrumb.Item
              onClick={() => {
                setNextScreen(prevData?.page2);
                setPrevData((prevData) => ({
                  ...prevData,
                  sidebar: prevData?.page1,
                  page3: null,
                  page4: null,
                  page5: null,
                  page6: null,
                  active: prevData?.page2,
                }));
              }}
            >
              <span
                className={
                  prevData?.page2 === prevData?.active
                    ? style.blackColor
                    : style.decoration
                }
              >
                {prevData?.page2.includes("-")
                  ? prevData?.page2
                  : handleHeading(prevData?.page2)}
              </span>
            </Breadcrumb.Item>
          )}
          {prevData?.page3 && (
            <Breadcrumb.Item
              onClick={() => {
                !prevData?.page3.includes("-") &&
                  setNextScreen(prevData?.page3);
                setPrevData((prevData) => ({
                  ...prevData,
                  sidebar: prevData?.page1,
                  page4: null,
                  page5: null,
                  page6: null,
                  active: prevData?.page3,
                }));
              }}
            >
              <span
                className={
                  prevData?.page3 === prevData?.active
                    ? style.blackColor
                    : style.decoration
                }
              >
                {prevData?.page3.includes("-")
                  ? prevData?.page3
                  : handleHeading(prevData?.page3)}
              </span>
            </Breadcrumb.Item>
          )}
          {prevData?.page4 && (
            <Breadcrumb.Item
              onClick={() => {
                setNextScreen(prevData?.page4);
                setPrevData((prevData) => ({
                  ...prevData,
                  sidebar: prevData?.page1,
                  page5: null,
                  page6: null,
                  active: prevData?.page4,
                }));
              }}
            >
              <span
                className={
                  prevData?.page4 === prevData?.active
                    ? style.blackColor
                    : style.decoration
                }
              >
                {handleHeading(prevData?.page4)}
              </span>
            </Breadcrumb.Item>
          )}
          {prevData?.page5 && (
            <Breadcrumb.Item
              onClick={() => {
                setNextScreen(prevData?.page5);
                setPrevData((prevData) => ({
                  ...prevData,
                  sidebar: prevData?.page1,
                  page6: null,
                  active: prevData?.page5,
                }));
              }}
            >
              <span
                className={
                  prevData?.page5 === prevData?.active
                    ? style.blackColor
                    : style.decoration
                }
              >
                {handleHeading(prevData?.page5)}
              </span>
            </Breadcrumb.Item>
          )}
          {prevData?.page6 && (
            <Breadcrumb.Item
              onClick={() => {
                setNextScreen(prevData?.page6);
                setPrevData((prevData) => ({
                  ...prevData,
                  active: prevData?.page6,
                }));
              }}
            >
              <span
                className={
                  prevData?.page6 === prevData?.active
                    ? style.blackColor
                    : style.decoration
                }
              >
                {handleHeading(prevData?.page6)}
              </span>
            </Breadcrumb.Item>
          )}
          {countComponent}
          {PlusComponent}
        </Breadcrumb>
        {filterComponent}
        <div className={style.otherChild}>{children}</div>
      </div>
    </div>
  );
}

export default BreadCrum;
