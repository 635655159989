import React, { useState } from "react";
import style from "./QuestionByPositionCom.module.css";
import { customStyles } from "../../CommonComponent/style";
import Select from "react-select";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory } from "react-router";
import dropdownIcon from "../../../../assets/downIcon.png";
import { useEffect } from "react";
import constants, { employeeId } from "../../../../Utils/constants";

export const QuestionByPositionCom = () => {
  const [clientListing, setClientListing] = useState([]);
  const [technologyListing, setTechnologyListing] = useState([]);
  const [experienceRange, setExperienceRange] = useState([]);
  const [clientDropDownOpen, setClientDropdownOpen] = useState(true);
  const [skillDropDownOpen, setSkillDropdownOpen] = useState(true);
  const [experienceDropDownOpen, setExperienceDropdownOpen] = useState(true);
  const [skillName, setSkillName] = useState();
  const [formData, setFormData] = useState({
    clientId: 0,
    empId: employeeId,
    questions: "",
    technologyId: 0,
    maxExperience: 0,
    minExperience: 0,
  });

  const handleChange = (field, value) => {
    if (field === "clientId") {
      setFormData({
        ...formData,
        [field]: value,
        technologyId: 0,
      });
    } else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  // base URL
  const baseUrl = constants.apiBaseUrlResource;
  const url = process.env.REACT_APP_QUESTIONNAIRE_URL;
  const url1 = process.env.REACT_APP_ATS_APP_URL;

  const history = useHistory();

  if (formData?.minExperience) {
    history.push({
      pathname: "/ViewByPosition",
      state: formData,
    });
  }
  // client listing api ointegrate
  const getClientListing = () => {
    axios
      .get(url + "/getClientForDashboard", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch((err) => {});
  };
  // technology listing api ointegrate
  // technology listing api ointegrate
  const getTechnologyListing = (id) => {
    axios
      .get(url1 + `/get-client-skills?clientId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setTechnologyListing(res.data.responseData);
      })
      .catch((err) => {});
  };
  const getExperiencerange = (clientId, skillId) => {
    axios
      .get(
        url1 +
          `/get-experience-by-client-id?clientId=${clientId}&skillId=${skillId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setExperienceRange(res.data.responseData);
      })
      .catch((err) => {});
  };
  const getClientList = clientListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getTechList = technologyListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getExpRange = experienceRange?.map((i) => ({
    value: `${i.minExperience}-${i.maxExperience}`,
    label: `${i.minExperience} - ${i.maxExperience} Years`,
  }));

  useEffect(() => {
    getClientListing();
    if (formData?.clientId) {
      getTechnologyListing(formData?.clientId);
    }
    if (formData?.technologyId) {
      getExperiencerange(formData?.clientId, formData?.technologyId);
    }
  }, [formData?.clientId, formData?.technologyId]);

  // raisedToList?.sort((a, b) =>
  //   a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  // );
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="amg"
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  return (
    <>
      <div className={style.main}>
        <div>
          <div className={style.card}>
            <div className={style.Cu_row1}>
              {!formData?.expRangeId ? (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>Select Client</p>
                  <Select
                    styles={customStyles}
                    menuIsOpen={clientDropDownOpen}
                    onMenuOpen={() => setClientDropdownOpen(true)}
                    onMenuClose={() => setClientDropdownOpen(false)}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    options={getClientList}
                    onChange={(e) => {
                      handleChange("clientId", e?.value);
                      setSkillDropdownOpen(true);
                    }}
                  ></Select>
                </div>
              ) : null}

              {!formData?.expRangeId ? (
                <div
                  className={style.cu_col1}
                  style={{
                    visibility: formData?.clientId ? "visible" : "hidden",
                  }}
                >
                  <p className={style.cu_label}>Select Skill</p>
                  <Select
                    styles={customStyles}
                    onMenuOpen={() => setSkillDropdownOpen(true)}
                    menuIsOpen={skillDropDownOpen}
                    onMenuClose={() => setSkillDropdownOpen(false)}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      formData?.technologyId
                        ? {
                            value: formData?.technologyId,
                            label: skillName,
                          }
                        : null
                    }
                    options={getTechList}
                    onChange={(e) => {
                      handleChange("technologyId", e?.value);
                      setSkillName(e?.label);
                    }}
                  ></Select>
                </div>
              ) : null}
              {!formData?.expRangeId ? (
                <div
                  className={style.cu_col3}
                  style={{
                    visibility: formData?.technologyId ? "visible" : "hidden",
                  }}
                >
                  <p className={style.cu_label}>Select Experience Range</p>
                  <Select
                    menuIsOpen={experienceDropDownOpen}
                    onMenuClose={() => setExperienceDropdownOpen(false)}
                    styles={customStyles}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    options={getExpRange}
                    onChange={(e) => {
                      const selectedValue = e?.value; // Assuming the selected value is "3-5"
                      const [minExp, maxExp] = selectedValue.split("-"); // Splitting the value into min and max
                      setFormData({
                        ...formData,
                        minExperience: minExp,
                        maxExperience: maxExp,
                      });
                    }}
                  ></Select>
                </div>
              ) : null}

              <div
                className={style.cu_col3}
                style={{
                  visibility: formData?.technologyId ? "visible" : "hidden",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
