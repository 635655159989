import React from "react";
import style from "./filterSearch.module.scss";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { customSearch } from "../../utils/style";
import Select from "react-select";
import { IoSearchOutline } from "react-icons/io5";

const FilterSearchSelect = ({
  options,
  onClose,
  value,
  onChange,
  placeholder,
  width,
  mL
}) => {
  console.log("options",options)
  return (
    <div className={style.search} style={{ width: width }}>
      {value ? (
        <span className={style.cp_serchIcon} onClick={onClose}
          onKeyDown={onclose}
          tabIndex={0}
          style={{ marginLeft: mL }}>
          <AiOutlineClose />
        </span>
      ) : (
        <span className={style.cp_serchIcon} style={{ marginLeft: mL }}>
          <IoSearchOutline />
        </span>
      )}
      <Select
        styles={customSearch}
        classNamePrefix={"create_position"}
        placeholder={placeholder}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        options={options}
        value={value ? options?.find((e) => e?.value === value) : null}
        onChange={onChange}
      />
    </div>
  );
};

export default FilterSearchSelect;
