import React, { useState, useEffect } from "react";
import style from "./ResourcesJourney.module.css";
import Sidebar from "../../Clients/component/SideBar";
import cookie from "react-cookies";
import { customSearch } from "../../Clients/component/style";
import axios from "axios";
import moment from "moment";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import constants, { employeeId } from "../../../../Utils/constants";
import Select from "react-select";

const ResourcesJourney = (props) => {
  const baseUrl = constants.apiBaseUrlResource;
  const [allData, setAllData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [ResourcesJourneyData, setResourcesJourneyData] = useState([]);

  const url = constants.apiBaseUrl;

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData([e?.value]);
          setFilterName({ ...filterName, positionCode: e?.label });
          break;

        default:
      }
    }
  };
  const getEmployeeList = () => {
    axios
      .get(url + "/getEmployeeList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setEmployeeList(res.data.responseData.employeeList);
      })
      .catch((err) => {
        return err;
      });
  };

  const getEmployeeListForFilter = employeeList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const MyTaskDetails = (id) => {
    axios
      .get(baseUrl + `/view-resource-hisory?empId=${allData}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setResourcesJourneyData(res.data.responseData);
      })
      .catch((err) => {
      });
  };
  useEffect(() => {
    getEmployeeList();
    MyTaskDetails();
  }, [allData]);

  const value1 = allData[0]
    ? getEmployeeListForFilter?.find((e) => e.value === allData[0])
    : null;
  return (
    <>
      <div className={style.main_client_body}>
        <Sidebar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ marginTop: "22px" }}>
              <span style={{ fontWeight: "700" }}>
                View{" "}
                <span className={style.arrow} style={{ fontWeight: "700" }}>
                  {" "}
                  {">"} Candidate Journey
                </span>{" "}
              </span>
            </div>
            <div
              className={style.s1}
              style={{ width: "20%", position: "relative" }}
            >
              {allData?.length === 0 ? (
                <span className={style.cp_serchIcon}>
                  <img src={searchIcon} alt="img" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon}
                  onClick={() => {
                    setAllData([]);
                    setFilterName({ ...filterName, positionCode: null });
                  }}
                >
                  <AiOutlineClose />
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Resources Journey"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={value1}
                onChange={(e) => {
                  onSearchHandler(e, "Code");
                }}
                options={getEmployeeListForFilter}
              />
            </div>
            <div className={style.form_div}>
              <div id="create_task_id" className={style.gridContainer}>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label className={style.desc}>Emp Code </label> <br />
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {ResourcesJourneyData?.empCode}
                  </p>
                </div>
                <div className={style.assignLabel} style={{ padding: "6px" }}>
                  <label>Employee Name </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {ResourcesJourneyData?.empName}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label>Project Type</label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {ResourcesJourneyData.projectType ? "OffShore " : "Onsite"}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label>Project Name </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {ResourcesJourneyData?.projectName}
                  </p>
                </div>
                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label>Client Name </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {ResourcesJourneyData?.clientName}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label>Start Date </label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {ResourcesJourneyData.allocationdate &&
                      moment(ResourcesJourneyData.allocationdate).format(
                        "DD MMM YY"
                      )}
                  </p>
                </div>

                <div className={style.proSecton} style={{ padding: "6px" }}>
                  <label>End Date</label>
                  <p style={{ marginTop: "9px", marginLeft: "11px" }}>
                    {ResourcesJourneyData.deallocationdate &&
                      moment(ResourcesJourneyData.deallocationdate).format(
                        "DD MMM YY"
                      )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourcesJourney;
