import React, { useEffect, useState } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import style from "./EmployeeList.module.css";
import FilterButton from "../Filterbutton/FilterButton";
import Filter from "../../filter/Filter";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import { useMutation, useQuery } from "react-query";
import { InductionNotes, deleteFile, employeeList, uploadFile } from "../../../../Utils/axios/InductionApi";
import Popup from 'reactjs-popup'
import moment from "moment";
import Pagination from "../../../Employee_Induction/CommonComponent/Pagination";
import ReviewFileDownload from "../ReviewFileDownload/ReviewFileDownload";
import SearchIcon from "../../../../assets/white_search_icon.png";
import Tooltip from '@mui/material/Tooltip';
import { validateEmployeeAccess } from "../../../../Utils/axios/api";
import { employeeId, validateLink } from "../../../../Utils/constants";
import UploadFile from "../../uploadFile/UploadFile";
import { CgNotes } from "react-icons/cg";
import Table from 'react-bootstrap/Table';
import { FaFileAlt } from "react-icons/fa";
import { notifyError } from "../../../atsDashboard/utils/notify";


const EmployeeList = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [inputData, setInputData] = useState("");
  const [searchData, setSearchData] = useState("");
  const [Data, setData] = useState({
    inductionStatusValue:
      prevData?.status === "DONE"
        ? "true"
        : prevData?.status === "INP"
          ? "false"
          : "",
  });
  const [filterData, setFilterData] = useState({
    empId: "",
    EmpValue: "",
    inductionStatus:
      prevData?.status === "DONE"
        ? "true"
        : prevData?.status === "INP"
          ? "false"
          : "",
    inductionStatusValue:
      prevData?.status === "DONE"
        ? "true"
        : prevData?.status === "INP"
          ? "false"
          : "",
  });
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [notesModalData, setNotesModalData] = useState('');
  const [fileURL, setFileURL] = useState('');
  const filterUpdateFuc = () => {
    setCurrentPage(1);
    setData(filterData);
    setShowFilter(false);
  };
  const filterClearAllFuc = () => {
    setData("");
    setShowFilter(false);
    setFilterData((preData) => ({
      ...preData,
      empId: "",
      EmpValue: "",
      inductionStatus: "",
      inductionStatusValue: "",
    }));
    setSearchData("");
    setInputData("");
    return;
  };

  const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(employeeId)),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );
  const fetchData = async (idData) => {
    try {
      // Assuming you have a function InductionNotes that fetches data
      const response = await InductionNotes(idData);
      setNotesModalData(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));

  const handleSearch = () => {
    setCurrentPage(1);
    setSearchData(inputData);
    refetch();
  };

  const { data, isLoading, isError, refetch } = useQuery(
    ["employeeList", { currentPage, Data, searchData }],
    () =>
      employeeList(
        10,
        currentPage,
        Data.empId,
        Data.inductionStatusValue,
        searchData
      )
  );
  useEffect(() => {
    setFileURL(data?.hrManualFileURL);
  }, [data])

  useEffect(() => {
    if (searchData === "") {
      refetch();
    }
  }, [searchData, refetch]);

  useEffect(() => {
    if (inputData === "") {
      setSearchData("");
    }
  }, [inputData]);

  useEffect(() => {
    refetch();
  }, [prevData?.partnerId]);
  const uploadFileMutation = useMutation(uploadFile, {
    onSuccess: (url) => {
      setFileURL(url);
    },
    onError: () => {
      notifyError('File Upload failed')
    },
  });
  const handleFileChange = (data) => {
    uploadFileMutation.mutate(data);
  }
  const removeFile = () => {
    deleteFile();
    refetch();
  }
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const statusColor = (value) => {
    if (value) {
      return { color: "green" };
    } else {
      return { color: "red" };
    }
  };
  return (
    <>
      <div>
        <div>
          <BreadCrum
            prevData={prevData}
            setPrevData={setPrevData}
            setNextScreen={setNextScreen}
            downloadComponent={
              getVData?.some((e) => {
                return e?.label === "382O3704M41882DW";
              }) && (<>
                <span style={{ width: "14%", position: "absolute", left: "28rem" }}>
                  <p className={style.count}>
                    <span
                      className={style.backColor1}
                      style={{ fontSize: "1.235rem", fontWeight: "500" }}
                    >
                      {data?.totalEmployee}
                    </span>
                  </p>
                </span>
                <div style={{ width: "4%" }} aria-id="PM0001">
                  <p className={style.download}>
                    <span
                      className={style.backColor1}
                      style={{
                        fontSize: "2rem",
                        fontWeight: "500",
                        cursor: "pointer",
                        color: "#a15e5e",
                      }}
                    >

                      <ReviewFileDownload limit="10" page={currentPage} />
                    </span>
                  </p>
                </div>
              </>)
            }

            filterComponent={
              <FilterButton
                setShowFilter={setShowFilter}
                filterData={filterData}
              />
            }
          // uploadComponent={
          //   <UploadFile
          //     type='file'
          //     name='uploadFile'
          //     placeholder={"no file selected"}
          //     fileURL={fileURL}
          //     handleChange={handleFileChange}
          //     removeFile={removeFile}
          //   />
          // }
          >
            <div className={style.search_bar_div}>
              <input
                className={style.search_bar_input}
                value={inputData}
                placeholder="Please Enter Emp Code / Name"
                onChange={(e) => setInputData(e.target.value)}
              />
              <div onClick={handleSearch} className={style.search_icon}>
                <img
                  src={SearchIcon}
                  alt="search_icon"
                  className={style.search_icon_img}
                />
              </div>
            </div>
          </BreadCrum>
        </div>

        <InvoiceContainer>
          <div className={style.table}>
            <div className={style.th}>
              <p >Emp Code</p>
              <p >Employee Name</p>
              <p >Date of Joining</p>
              <p >Designation</p>
              <p >Department</p>
              <p >Folder Link</p>
              <p >Induction Status</p>
              <p>Delay By Days</p>
              <p>Notes</p>
            </div>
            <div>
              {!isLoading && !isError ? (
                data?.empDetails?.length > 0 ? (
                  typeof data?.empDetails !== "string" &&
                  data?.empDetails?.map((item, index) => {
                    return (
                      <div className={style.td}>
                        <p
                          className={`${style.tableContent1} ${style.link}`}
                          onClick={() => {
                            setNextScreen("EmployeeInductionView");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "EmployeeInductionView",
                              page3: null,
                              page4: null,
                              active: "EmployeeInductionView",
                              idData: item.id,
                              status: "",
                            }));
                          }
                          }
                        >
                          {item?.empCode}{" "}
                        </p>
                        <p>{item?.empName}</p>
                        <p>{moment(item?.dateOfJoining).format("DD MMM YY")}</p>
                        <p>{item?.designation}</p>
                        <p>{item?.department}</p>

                        <p className={style.link}>
                          <a
                            href={item?.folderLink}
                            target="_blank"
                            title="Click Here"
                            rel="noreferrer"
                          >
                            {validateLink(item?.folderLink) && "Link"}
                          </a>
                        </p>
                        <p style={statusColor(item?.overallStatus)}>
                          {item?.overallStatus ? "Done" : "In-Progress"}
                        </p>
                        {item.overallStatus ? <p>{item?.delayByDaysDone ? item?.delayByDaysDone : null}</p> : <p>{item?.delayByDate ? item?.delayByDate : null}</p>
                        }
                        {item.isNotesExist ? <p><CgNotes
                          style={{ fontSize: '20px', color: 'blue', cursor: 'pointer' }}
                          title="Induction Notes"
                          onClick={() => {
                            setShowNotesModal(true)
                            fetchData(item.id);

                          }}
                        /></p> : <p style={{ paddingLeft: '7px' }}> - </p>}
                      </div>
                    );
                  })
                ) : (
                  <p className={style.no_data}>No Data Found</p>
                )
              ) : null}
            </div>
          </div>
          <>
            <Filter
              filterData={filterData}
              setFilterData={setFilterData}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              firstCard="Department Filter"
              secondCard="Status"
              filterUpdateFuc={filterUpdateFuc}
              filterClearAllFuc={filterClearAllFuc}
            />
          </>
        </InvoiceContainer>
        {/* Notes Modal */}
        <div>
          <Popup
            open={showNotesModal}
            closeOnDocumentClick={false}
          // onClose={props.closeModal}
          >
            <div className={style.modal_notes_container}>
              <div className={style.heading}>
                Induction Notes
              </div>
              <div className={style.modal_table_container}>
                {notesModalData?.count === 0 ? <p className={style.no_notes}>No Notes</p> :
                  <Table className={style.modal_table} borderless hover responsive >
                    <thead>
                      <tr>
                        <th style={{ width: '8rem' }}>Reported Date</th>
                        <th style={{ width: '10rem' }}>Username</th>
                        <th>Note</th>
                        {/* <th style={{ width: '5rem' }}>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {false ? <tr>
                        <td colSpan={3} style={{ textAlign: 'center' }}>No Data Found</td>
                      </tr> : (
                        notesModalData?.noteList?.map(item => (
                          <tr>
                            <td style={{ width: '8rem' }}>{item.createdOn}</td>
                            <td style={{ width: '10rem' }}>{item.noteByEmpName}</td>
                            {item?.note?.length > 30 ? <td title={item.note}>{item.note.substr(0, 30)}...</td> : <td>{item.note}</td>}
                          </tr>
                        ))

                      )}
                    </tbody>
                  </Table>
                }
              </div>
              <div className={style.modal_btn_container}>
                <button
                  className={style.modal_btn}
                  onClick={() => {
                    setShowNotesModal(false)
                    setNotesModalData({})
                  }}>Done</button>
              </div>
            </div>
          </Popup>
        </div>
        <div className={style.pagination}>
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pagesCount={data?.totalEmployee / 10}
            color="##855ea1"
            color2="white"
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeList;
