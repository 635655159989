import Modal from "react-responsive-modal";
import { getFixedDistribution } from "../../../../../Utils/axios/api";
import { useQuery } from "react-query";
import style from "./FixedSalaryDistribution.module.css"
const FixedSalaryDistribution = ({showFSDModal, setShowFSDModal, currentOfferId}) => {
    const {data} = useQuery(
        ["getFixedDistribution", currentOfferId],
        ()=> getFixedDistribution(currentOfferId),
        { refetchOnWindowFocus: false, retry: false}
    )
    return (
        <Modal
            open={showFSDModal}
            onClose={()=>setShowFSDModal(false)}
        >
            <div>
            <table className={style.table}>
      <thead>
        <tr>
          <th colSpan="2" className={style.salTab}>Fixed Salary Distribution</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{fontWeight: "700"}}>
          <td className={style.salTab} colSpan="2">Fixed Salary : {data?.fixedSalary}</td>
        </tr>
      {data?.basic !== 0 && (
          <tr>
            <td className={style.salTab}>Basic</td>
            <td className={style.salTab}>{data?.basic}</td>
          </tr>
        )}
        {data?.hra !== 0 && (
          <tr>
            <td className={style.salTab}>HRA</td>
            <td className={style.salTab}>{data?.hra}</td>
          </tr>
        )}
        {data?.ca !== 0 && (
          <tr>
            <td className={style.salTab}>Conveyance Allowance</td>
            <td className={style.salTab}>{data?.ca}</td>
          </tr>
        )}
        {data?.bonus !== 0 && (
          <tr>
            <td className={style.salTab}>Bonus</td>
            <td className={style.salTab}>{data?.bonus}</td>
          </tr>
        )}
        {data?.oa !== 0 && (
          <tr>
            <td className={style.salTab}>Other Allowance</td>
            <td className={style.salTab}>{data?.oa}</td>
          </tr>
        )}
        {data?.gross !== 0 && (
          <tr>
            <td className={style.salTab}>Gross Salary</td>
            <td className={style.salTab}>{data?.gross}</td>
          </tr>
        )}
        {data?.ePf !== 0 && (
          <tr>
            <td className={style.salTab}>Employer PF</td>
            <td className={style.salTab}>{data?.ePf}</td>
          </tr>
        )}
        {data?.incentive !== 0 && (
          <tr>
            <td className={style.salTab}>Incentives</td>
            <td className={style.salTab}>{data?.incentive}</td>
          </tr>
        )}
        {data?.sa1 !== 0 && (
          <tr>
            <td className={style.salTab}>Special Allowance 1</td>
            <td className={style.salTab}>{data?.sa1}</td>
          </tr>
        )}
        {data?.sa2 !== 0 && (
          <tr>
            <td className={style.salTab}>Special Allowance 2</td>
            <td className={style.salTab}>{data?.sa2}</td>
          </tr>
        )}
        {data?.ctc !== 0 && (
          <tr>
            <td className={style.salTab}>CTC</td>
            <td className={style.salTab}>{data?.ctc}</td>
          </tr>
        )}
        {data?.gratuity !== 0 && (
          <tr>
            <td className={style.salTab}>Gratuity</td>
            <td className={style.salTab}>{data?.gratuity}</td>
          </tr>
        )}
        {data?.travelReimbursement !== 0 && (
          <tr>
            <td className={style.salTab}>Travel Reimbursement</td>
            <td className={style.salTab}>{data?.travelReimbursement}</td>
          </tr>
        )}
        {data?.reallocationBonus !== 0 && (
          <tr>
            <td className={style.salTab}>Reallocation Bonus</td>
            <td className={style.salTab}>{data?.reallocationBonus}</td>
          </tr>
        )}
        {data?.quarterlyPerformanceBonus !== 0 && (
          <tr>
            <td className={style.salTab}>Quarterly Performance Bonus</td>
            <td className={style.salTab}>{data?.quarterlyPerformanceBonus}</td>
          </tr>
        )}
        {data?.other !== 0 && (
          <tr>
            <td className={style.salTab}>Other</td>
            <td className={style.salTab}>{data?.other}</td>
          </tr>
        )}

      </tbody>
    </table>
                    
    </div>
    </Modal>
    )
}

export default FixedSalaryDistribution