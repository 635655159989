import { useState } from "react";
import style from "./CurrentDetailsComponent.module.css";
import { FaRegComments } from "react-icons/fa";
import Modal from "react-responsive-modal";
import { addCommas } from "../../utils/AddCommas";
function CurrentDetailsComponent({ currentDetails }) {
  const [open,setOpen]=useState(false);
  const openModal =()=>{
    setOpen(true);
  }
  const closeModal =()=>{
    setOpen(false);
  }
  const currentData = [
    addCommas(currentDetails.currentMonthlyCoreCTC),
    addCommas(currentDetails.currentYearlyCoreCTC),
    addCommas(currentDetails.currentMonthlyFullCTC),
    addCommas(currentDetails.currentYearlyFullCTC),
    addCommas(currentDetails.sa1),
    addCommas(currentDetails.sa2),
    addCommas(currentDetails.incentives),
    addCommas(currentDetails.joiningBonus),
    addCommas(currentDetails.variableBonus),
    addCommas(currentDetails.retentionBonus),
    addCommas(currentDetails.others),
    <FaRegComments
    style={{cursor:"pointer",marginLeft:"30px",color:"#562679"}}
                          onClick={() => {
                            // setModalData({
                            //   cycleId,
                            //   eid: emp.empInfo.id,
                            //   cycleStatus: emp.empInfo.appraisalCycleStatus,
                            // });
                            openModal();
                          }}
                        />
  ];
  const titles = [
    "Full CTC (Monthly)",
    "Full CTC (Annual)",
    "Core CTC (Monthly)",
    "Core CTC (Annual)",
    "SA1",
    "SA2",
    "Incentives",
    "Joining Bonus",
    "Variable Bonus",
    "Retention Bonus",
    "Others",
    "Comments"
  ];
  return (
    <>
      <div>
        <div className={style.container}>
          <div className={style.title}>Current Details</div>
          <hr className={style.hr}></hr>
          <div className={style.EmpBox_row}>
            {titles.map((title, index) => {
              return (
                <div className={style.EmpBox_col} key={index}>
                  <p className={style.EmpBox_label}>{title}</p>
                  <p
                    className={
                      title === "Client Name"
                        ? style.EmpBox_Client
                        : style.EmpBox_field
                    }
                  >
                    {currentData[index]
                      ? currentData[index]
                      : currentData[index] === 0
                      ? 0
                      : "-"}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>



      <Modal
        open={open}
        onRequestClose={closeModal}
        center
        onClose={() => setOpen(false)}
        showCloseIcon={false}
      >
        <div>
          {currentDetails?.salaryNote?currentDetails.salaryNote:"No Salary Note Present"}
        </div>
      </Modal>
    </>
  );
}
export default CurrentDetailsComponent;
