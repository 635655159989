import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import { useMutation, useQuery } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineFileWord } from "react-icons/ai";
import ClearIcon from "@mui/icons-material/Clear";
import { urlValidation } from "../../../atsDashboard/utils/validation";
import { employeeId } from "../../../../Utils/constants";
import {
  getViewPartner,
  createPartner,
  getViewProject,
  updateLead,
} from "../../../../Utils/axios/PortalManagement";
import classes from "../ViewCreatePartner/EditViewPartner.module.css";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { InvoiceStyles4, InvoiceStyles5 } from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import { getLeadLocationList, getLeadSourceList, getLeadStatusList, getPartnerRecruiterList1 } from "../../../../Utils/axios/api";
import { notifySuccess } from "../../../atsDashboard/utils/notify";
const EditViewPartner = ({ setNextScreen, prevData }) => {
  const { data: PartnerDataById, refetch } = useQuery(
    ["getViewProject"],
    () => getViewProject(prevData?.CreateId),
    {}
  );

  const [formData, setFormData] = useState({
    partnerName: PartnerDataById?.partnerName,
    linkedInUrl: PartnerDataById?.linkedInUrl,
    locationId: PartnerDataById?.locationId,
    sourceId: PartnerDataById?.sourceId,
    statusId: PartnerDataById?.statusId,
    talentAcquisitionId: PartnerDataById?.talentAcquisitionId,
    companySize: PartnerDataById?.companySize,
    id: prevData?.CreateId,
  });
  const [validate, setValidate] = React.useState(false);

  useEffect(() => {
    setFormData((prevValue) => ({
      ...prevValue,
      partnerName: PartnerDataById?.partnerName,
      linkedInUrl: PartnerDataById?.linkedInUrl,
      locationId: PartnerDataById?.locationId,
      sourceId: PartnerDataById?.sourceId,
      statusId: PartnerDataById?.statusId,
      talentAcquisitionId: PartnerDataById?.talentAcquisitionId,
      companySize: PartnerDataById?.companySize
    }));
  }, [PartnerDataById]);

  const mutation = useMutation(updateLead, {
    onSuccess: (res) => {
      notifySuccess("Partner Lead Updated Successfully")
    },
  });

  const HandlerEditPartner = () => {
    if (
      formData?.partnerName.trim() &&
      formData?.companySize &&
      formData?.linkedInUrl.trim() &&
      formData?.locationId &&
      formData?.sourceId &&
      formData?.statusId &&
      formData?.talentAcquisitionId
    ) {
      setValidate(false);
      refetch();
      mutation.mutate(formData);
      setNextScreen("ViewCreateProject");
    } else {
      setValidate(true);
    }
  };

  const { data: statusList } = useQuery(
    ["getLeadStatusList"],
    getLeadStatusList
  );

  const statusOptions = statusList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const { data: sourceList } = useQuery(
    ["getLeadSourceList"],
    getLeadSourceList
  );

  const sourceOptions = sourceList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));


  const { data: locationList } = useQuery(
    ["getLeadLocationList"],
    getLeadLocationList
  );

  const locationOptions = locationList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));


  const { data: recruiterList } = useQuery(
    ["getPartnerRecruiterList1"],
    getPartnerRecruiterList1
  );

  const recruiterOptions = recruiterList?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));



  const linkendInUrl = (value) => {
    if (!value) {
      return true;
    } else if (urlValidation(value)) {
      return true;
    } else {
      return false;
    }
  };



  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };





  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "15px", marginLeft: "25px" }}>
              Edit Partner Lead
            </h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col md={4}>
              <p className={classes.label}>
                Partner Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                value={formData?.partnerName}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    partnerName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.partnerName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Partner Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                TA <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={recruiterOptions}
                styles={InvoiceStyles5}
                classNamePrefix={"create_position"}
                value={recruiterOptions?.find(
                  (e) => e.value === formData?.talentAcquisitionId
                )}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    talentAcquisitionId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.talentAcquisitionId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select T.A.
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Status <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={statusOptions}
                styles={InvoiceStyles5}
                classNamePrefix={"create_position"}
                value={statusOptions?.find(
                  (e) => e.value === formData?.statusId
                )}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    statusId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.statusId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Status
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Location <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={locationOptions}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                value={locationOptions?.find(
                  (e) => e.value === formData?.locationId
                )}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    locationId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.locationId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Location
              </p>
            </Col>


            <Col md={4}>
              <p className={classes.label1}>
                Source <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={sourceOptions}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                value={sourceOptions?.find(
                  (e) => e.value === formData?.sourceId
                )}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    sourceId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.sourceId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Source
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                LinkedIn Url <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                value={formData?.linkedInUrl}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    linkedInUrl: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                    (!formData?.linkedInUrl.trim() ||
                      !linkendInUrl(formData?.linkedInUrl))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter LinkedIn Url
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Company Size
                <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                value={formData?.companySize}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companySize: e.target.value,
                  });
                }}
                onKeyDown={handleKeyDown}
                onClick={handleInputClick}
              />
              <p
                className={
                  validate && !formData?.companySize
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Company Size
              </p>
            </Col>


            <Col md={4}>

            </Col>
          </Row>

        </InvoiceContainer>
      </Container>
      <button className={classes.buttonAdd} onClick={HandlerEditPartner}>
        Update
      </button>
    </>
  );
};
export default EditViewPartner;
