import style from "./SideBar.module.scss";
import * as Img from "../../../assets/index";
import { Link, useLocation, useHistory } from "react-router-dom";

import { path } from "../../../routes/PathNames";
import {  useState } from "react";
import useValidationAccess from "../../custumHooks/useValidationAccess";
import { clientContractCode } from "../../../Utils/constants";

const SideBar = ({
  sidebarHandler,
  nextScreen,
  setPrevData,
  prevData,
  setCurrentPage,
  roles,
}) => {
  const location = useLocation();
  const [buttonHover, setButtonHover] = useState(false);

  const { checkValidateAccess } = useValidationAccess();

  const menuItems = [
    {
      path: path.cc_list,
      imgSrc: Img.cc_sidebar_one,
      imgSrcSelected: Img.cc_sidebar_one_selected,
      heading: "Expiring Contracts",
      validPaths: ["/expiring-contracts"],
      isAccess: checkValidateAccess(clientContractCode.ExpiringContracts),
    },
    {
      path: path.cc_missing_inprogress,
      imgSrc: Img.cc_sidebar_one,
      imgSrcSelected: Img.cc_sidebar_one_selected,
      heading: "Missing/Inprogress contracts",
      validPaths: ["/missing-inprogress-contracts"],
      isAccess: checkValidateAccess(clientContractCode.ClientContracts),
    },
    {
      path: path.cc_contract_ims,
      imgSrc: Img.cc_sidebar_one,
      imgSrcSelected: Img.cc_sidebar_one_selected,
      heading: "Client Contracts",
      validPaths: ["/client-contracts"],
      isAccess: checkValidateAccess(clientContractCode.ClientContracts),
    },
  ];

  const isActiveMenuItem = (validPaths) => {
    return location.pathname.includes(validPaths);
  };
  return (
    <div className={style.leftSection}>
      <button className={style.btn}>
        <div>
          <img src={Img.cc_logo} alt="img" />
        </div>

        <span>Client Contract</span>
      </button>
      <div className={style.side_buttons_div}>
        {menuItems.map((i, index) => {
          if (i.isAccess) {
            return (
              <Link
                to={{ pathname: i.path, state: {} }}
                style={{ textDecoration: "none" }}
                key={i.heading}
              >
                <div
                  className={
                    // check if path matches or index match to hover one
                    isActiveMenuItem(i.validPaths) || buttonHover === index
                      ? style.active_btn
                      : style.deactivate_btn
                  }
                  // set index on hover
                  onMouseEnter={() => setButtonHover(index)}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  <img
                    src={
                      isActiveMenuItem(i.validPaths) || buttonHover === index
                        ? i.imgSrcSelected
                        : i.imgSrc
                    }
                    alt={i.heading}
                    className={style.icon}
                  />
                  <div
                    className={style.heading}
                    dangerouslySetInnerHTML={{ __html: i.heading }}
                  />
                </div>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SideBar;
