import React, { useEffect, useState } from "react";
import EmployDetail from "../../EmployeeDetails_apprHistory/EmployDetails/EmployDetail";
import AppraisalHistoryCom from "../../EmployeeDetails_apprHistory/AppraisalHistory/AppraisalHistoryCom";
import AppraisalSystemSidebar from "../../Sidebar/AppraisalSystemSidebar";
import style from "./ShowEmployeeDetails.module.css";
import { NavLink } from "react-router-dom";
import CurrentDetailsComponent from "../../EmployeeDetails_apprHistory/CurrentProposedHR/CurrentDetailsComponent";
import ProposedDetailsComponent from "../../EmployeeDetails_apprHistory/ProposedDetails/ProposedDetailsComponent";
import HrHeadCom from "../../EmployeeDetails_apprHistory/CurrentProposedHR/HrHeadComments/HrHeadCom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "react-query";
import { getCombinationFilterList, getEmpAppraisalHistoryByAppraisalCycle, getEmployeeDetailById } from "../../../../../Utils/axios/AppraisalApi";
import AppraisalInputForm from "../../InputForms/AppraisalInputForm/AppraisalInputForm";
import PromotionInputForm from "../../InputForms/PromotionInputForm/PromotionInputForm";
import RewardInputForm from "../../InputForms/RewardInputForm/RewardInputForm";
import { FaEdit } from "react-icons/fa";
const ShowEmployeeDetails = () => {
  const [showAppraisalHistory, setShowAppraisalHistory] = useState(false);
  const { empId, cycleId, cycleName } = useParams();
  const modalData = { eid: empId, cycleId }

  const [showForm, setShowForm] = useState(false);
  const handleShowForm = () => {
    setShowForm(!showForm);
  }

  // Employee + Current Details
  const { data: employeeDetails, isLoading: employeeDetailsLoading, refetch: refetchEmployeeDetails } = useQuery(["getAllEmployees"], () =>
    getEmployeeDetailById(cycleId, empId)
  );
  let employeeDetailsObj;
  let projectDetailsComments;
  let currentDetailsObj;
  let cycleStatus = null;
  if (!employeeDetailsLoading) {
    employeeDetailsObj = {
      "employeeCode": employeeDetails.employeeCode,
      "empId": employeeDetails.id,
      "name": employeeDetails.name,
      "dateOfJoining": employeeDetails.dateOfJoining,
      "totalExperience": employeeDetails.totalExperience,
      "bond": employeeDetails.bond,
      "bondEndDate": employeeDetails.bondEndDate,
      "technology": employeeDetails.technology,
      "department": employeeDetails.department,
      "location": employeeDetails.location,
      "clientName": employeeDetails.clientName,
      "projectDetailObj": employeeDetails.projectDetailObj,
      "performanceDetailsUrl": employeeDetails.performanceDetailsUrl,
      "rating": employeeDetails.rating,
      "ratingOutOf": employeeDetails.ratingOutOf,
      "lastAppraisalCycle": employeeDetails.lastAppraisalCycle,
      "lastIncrementPercentage": employeeDetails.lastIncrementPercenatage,
      "lastIncrementAmount": employeeDetails.lastIncrementAmount,
      "lastPRMonth": employeeDetails.lastPRMonth,
      "lastReward": employeeDetails.lastReward,
      "lastPromotion": employeeDetails.lastPromotion,
      "lastRewardCycle": employeeDetails.lastRewardCycle,
      "lastPromotionCycle": employeeDetails.lastPromotionCycle,
      "designation": employeeDetails.designation,
      "empType": employeeDetails.empType,
      "totalExpWithIM": employeeDetails.totalExpWithIM,
      "reportingManager": employeeDetails.reportingManager,
      "lastApplicableFrom": employeeDetails.lastApplicableFrom
    }
    currentDetailsObj = {
      "incentives": employeeDetails.incentives,
      "joiningBonus": employeeDetails.joiningBonus,
      "variableBonus": employeeDetails.variableBonus,
      "sa1": employeeDetails.sa1,
      "sa2": employeeDetails.sa2,
      "others": employeeDetails.others,
      "salaryNote": employeeDetails.salaryNote,
      "retentionBonus": employeeDetails.retentionBonus,
      "currentMonthlyCoreCTC": employeeDetails.currentMonthlyCoreCTC,
      "currentMonthlyFullCTC": employeeDetails.currentMonthlyFullCTC,
      "currentYearlyCoreCTC": employeeDetails.currentYearlyCoreCTC,
      "currentYearlyFullCTC": employeeDetails.currentYearlyFullCTC,
    }
    projectDetailsComments = employeeDetails.projectCommentsObj;
    cycleStatus = employeeDetails.appraisalCycleStatus;
  }

  // EmployeeAppraisalHistory
  const { data: empAppraisalHistory, isLoading: empAppraisalHistoryLoading } = useQuery(["getEmpAppraisalHistoryByAppraisalCycle"], () =>
    getEmpAppraisalHistoryByAppraisalCycle(cycleId, empId)
  );

  // Getting Reward List
  const {
    data: combinationFilterList,
  } = useQuery(["getCombinationFilterList"], () => getCombinationFilterList());

  const [rewardList, setRewardList] = useState([]);
  const [promotionList, setPromotionList] = useState([]);


  useEffect(() => {
    setRewardList(combinationFilterList?.reward);
    setPromotionList(combinationFilterList?.promotion);
  }, [combinationFilterList]);

  return (
    <div className={style.mainSection}>
      <AppraisalSystemSidebar />
      {!employeeDetailsLoading && !empAppraisalHistoryLoading && <div className={style.container}>
        <div className={style.button}>
          <p className={style.pp}>
            <NavLink to="/ASDashboard">
              <span className={style.breadscrum}>Dashboard </span>
            </NavLink>
            &gt; <NavLink to={`/ASDashboard/${cycleName}/${cycleId}/CycleEmployeeList`} > <span className={style.breadscrum}> Appraisal Cycles </span></NavLink>
            <span> &gt; Employee Detail </span>
          </p>

          {/* <div className={style.type}>
            {employeeDetails?.action}
          </div> */}

          <div className={style.historyAction}>
            {employeeDetails?.appraisalCycleStatus !== "Closed" ? <button className={style.history}
              onClick={() => handleShowForm()}
            >
              {employeeDetails.status === "Pending" ?
                (employeeDetails.action === "Appraisal" && "+") ||
                (employeeDetails.action === "Reward" && "R") ||
                (employeeDetails.action === "Promotion" && "P")
                : <FaEdit />}
            </button> : null}
            <button className={style.history}
              onClick={() => setShowAppraisalHistory(!showAppraisalHistory)}
            >

              {showAppraisalHistory ? "X" : "H"}
            </button>
          </div>
        </div>

        {showForm && employeeDetails && employeeDetails.action === "Appraisal" && employeeDetails.appraisalCycleStatus !== "Closed" &&
          <AppraisalInputForm rewardList={rewardList} promotionList={promotionList} currentDetailsObj={currentDetailsObj}
            modalData={modalData} setShowForm={setShowForm} refetchEmployeeDetails={refetchEmployeeDetails}
          />}

        {showForm && employeeDetails && employeeDetails.action === "Promotion" && employeeDetails.appraisalCycleStatus !== "Closed" &&
          <PromotionInputForm rewardList={rewardList} promotionList={promotionList} currentDetailsObj={currentDetailsObj}
            modalData={modalData} setShowForm={setShowForm} refetchEmployeeDetails={refetchEmployeeDetails}
          />}
        {showForm && employeeDetails && employeeDetails.action === "Reward" && employeeDetails.appraisalCycleStatus !== "Closed" &&
          <RewardInputForm rewardList={rewardList} currentDetailsObj={currentDetailsObj}
            modalData={modalData} setShowForm={setShowForm} refetchEmployeeDetails={refetchEmployeeDetails}
          />}

        <EmployDetail employeeDetails={employeeDetailsObj} projectDetailsComments={projectDetailsComments} />
        <CurrentDetailsComponent currentDetails={currentDetailsObj} />
        {employeeDetails && employeeDetails.appraisalCycleStatus == "Open" && <ProposedDetailsComponent empId={empId} />}
        {showAppraisalHistory && <AppraisalHistoryCom empAppraisalHistory={empAppraisalHistory} />}
        <HrHeadCom modalData={{ ...modalData, cycleStatus }} />

      </div>}
    </div>
  );
};

export default ShowEmployeeDetails;
