import * as Img from "../../../assets/index";
import { path } from "../../../routes/PathNames";
import { umsAccessCode } from "../../../Utils/constants";
import UpcomingIcon from '@mui/icons-material/Upcoming';
export const menuItems = [
  {
    path: path.ums_dashboard,
    imgSrc: Img.ums_dashboard,
    imgSrcSelected: Img.ums_dashboard_selected,
    heading: "Dashboard",
    validPaths: [path.ums_dashboard],
    accessCode: umsAccessCode?.dashboard_menu,
  },
  {
    path: path.ums_newEmployee,
    imgSrc: Img.ums_upcoming,
    imgSrcSelected: Img.ums_upcoming_selected,
    heading: "Upcoming (Joinees + Celebrations)",
    validPaths: [path.ums_newEmployee],
    accessCode: umsAccessCode?.dashboard_menu,
  },
  {
    path: path.ums_companyHierarchy,
    imgSrc: Img.ums_hierarchy,
    imgSrcSelected: Img.ums_hierarchy_selected,
    heading: "Reporting Hierarchy",
    validPaths: [path.ums_companyHierarchy],
    accessCode: umsAccessCode?.dashboard_menu,
  },
  {
    path: path.ums_userList,
    imgSrc: Img.ums_users,
    imgSrcSelected: Img.ums_users_selected,
    heading: "Users",
    accessCode: umsAccessCode?.users_menu,
    validPaths: [
      path.ums_userList,
      path.ums_createUser,
      path.ums_userDescription,
      path.ums_editUser,
      path.ums_createconfirm,
    ],
  },
  {
    path: path.ums_salaryMaster,
    imgSrc: Img.ums_salary_master_selected,
    imgSrcSelected: Img.ums_salary_master,
    heading: "Salary Master",
    validPaths: [path.ums_salaryMaster],
    accessCode: umsAccessCode?.dashboard_menu,
  },
  {
    path: path.ums_report,
    imgSrc: Img.ums_reports,
    imgSrcSelected: Img.ums_reports_selected,
    heading: "Report",
    validPaths: [path.ums_report],
    accessCode: umsAccessCode?.report_menu,
  },
  {
    path: path.ums_incentive_report,
    imgSrc: Img.ums_reports,
    imgSrcSelected: Img.ums_reports_selected,
    heading: "Incentive Report",
    validPaths: [path.ums_incentive_report],
    accessCode: umsAccessCode?.report_menu,
  },
  {
    path: path.ums_exitEmployee,
    imgSrc: Img.ums_exit,
    imgSrcSelected: Img.ums_exit_selected,
    heading: "Upcoming Exits ",
    validPaths: [path.ums_exitEmployee],
    accessCode: umsAccessCode?.exit_employee_list_menu,
  },
  {
    path: path.ums_confirmationEmployee,
    imgSrc: Img.ums_confirm,
    imgSrcSelected: Img.ums_confirm_selected,
    heading: "Confirmation Lists",
    validPaths: [path.ums_confirmationEmployee],
    accessCode: umsAccessCode?.confirmation_list_menu,
  },
  {
    path: path.ums_newJoinee,
    imgSrc: Img.ums_new_joinee,
    imgSrcSelected: Img.ums_new_joinee_select,
    heading: "New Joinees<br />(Last 3 Months)",
    validPaths: [path.ums_newJoinee],
    accessCode: umsAccessCode?.new_joinees_menu,
  },
  {
    path: path.ums_exitedEmployee,
    imgSrc: Img.ums_exited,
    imgSrcSelected: Img.ums_exited_selected,
    heading: "Exited Employee <br/> (Last 3 Months)",
    validPaths: [path.ums_exitedEmployee],
    accessCode: umsAccessCode?.exited_employee_menu,
  },
  {
    path: path.ums_allExitedEmployee,
    imgSrc: Img.ums_exited,
    imgSrcSelected: Img.ums_exited_selected,
    heading: "All Exited Employee",
    validPaths: [path.ums_allExitedEmployee],
    accessCode: umsAccessCode?.exited_employee_menu,
  },
  {
    path: path.ums_bgvReport,
    imgSrc: Img.ums_exited,
    imgSrcSelected: Img.ums_exited_selected,
    heading: "BGV Report",
    validPaths: [path.ums_bgvReport],
    accessCode: umsAccessCode?.exited_employee_menu,
  },
];
