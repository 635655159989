import SideBar from "../sidebar/sidebar";
import { useLocation, useHistory } from "react-router-dom";
import style from "./invoices.module.css";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CreateIcon from "@mui/icons-material/Create";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { path } from "../../../../routes/PathNames";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  deleteInvoice,
  getINvoiceByProjectId,
} from "../../../../Utils/axios/pcm_Api";
import Moment from "moment/moment";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify";
import Loading from "../loading/loading";
import DeleteIcon from "@mui/icons-material/Delete";
import { addCommasPCM, addCommasWithDecimal } from "../../../Appraisal_System/commonComponent/utils/AddCommas";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { projectCostingAndMarginAccessCode } from "../../../../Utils/constants";

export default function InvoiceList() {
  const { checkValidateAccess } = useValidationAccess();
  return (
    <div style={{ display: "flex" }}>
      {checkValidateAccess(
        projectCostingAndMarginAccessCode?.invoices_dashboard
      ) && (
        <>
          <SideBar />
          <Body />
        </>
      )}
    </div>
  );
}

function Body() {
  const location = useLocation();
  const history = useHistory();
  let id = location?.state?.id;
  const projectName = location?.state?.projectName;
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(
    location?.state?.currentPage ? location?.state?.currentPage : 1
  );

  const { checkValidateAccess } = useValidationAccess();
  const edit_invoice_access = checkValidateAccess(
    projectCostingAndMarginAccessCode?.edit_invoice
  );
  const delete_invoice_access = checkValidateAccess(
    projectCostingAndMarginAccessCode?.delete_invoice
  );

  const { data, isLoading, refetch } = useQuery(
    ["getInvoiceByProjectId", id, currentPage],
    () => getINvoiceByProjectId({ limit, page: currentPage, projectId: id }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err) => notifyError("Something went wrong!"),
    }
  );
  const deleteInvoiceMutation = useMutation(deleteInvoice, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 1) {
        setCurrentPage(
          currentPage > 1
            ? data?.invoicesCount % limit === 1
              ? currentPage - 1
              : currentPage
            : 1
        );
        notifySuccess(res?.responseData);
        refetch();
      } else {
        notifyError(res);
      }
    },
    onError: (err) => {
      notifyError(err);
    },
  });
  const [pageCount, setpageCount] = useState(0);

  const handleRedirectEditInvoice = (invoiceData) => {
    setTimeout(() => {
      history.push(path.pcm_editInvoice, {
        id,
        edit: true,
        preFillData: invoiceData,
        currentPage,
        projectName,
        pccurrentPage: location?.state?.pccurrentPage,
      });
    }, 0);
  };

  const handleRedirectAddInvoice = () => {
    setTimeout(() => {
      history.push(path.pcm_addInvoice, {
        id,
        add: true,
        currentPage,
        projectName,
        pccurrentPage: location?.state?.pccurrentPage,
      });
    }, 0);
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const handleDeleteInvoice = (item) => {
    if (item && item?.invoiceId && item?.projectId) {
      deleteInvoiceMutation.mutate({
        invoiceId: item?.invoiceId,
        projectId: item?.projectId,
      });
    }
  };

  const tableBody =
    data?.response?.length > 0 ? (
      data?.response?.map((item) => (
        <Row
          key={item?.invoiceId}
          {...item}
          delete_invoice_access={delete_invoice_access}
          edit_invoice_access={edit_invoice_access}
          handleRedirectEditInvoice={() => handleRedirectEditInvoice(item)}
          handleDeleteInvoice={() => handleDeleteInvoice(item)}
        />
      ))
    ) : (
      <tr className={style.tRow}>
        <td colSpan={7} style={{ textAlign: "center" }}>
          No Invoice Raised
        </td>
      </tr>
    );

  const handleRedirectProjectsList = () => {
    setTimeout(() => {
      history.push(path.pcm_dashboard, {
        id,
        pccurrentPage: location?.state?.pccurrentPage,
      });
    }, 0);
  };

  const handleRedirectProjectCosting = () => {
    setTimeout(() => {
      history.push(path.pcm_projectCostingInformation, { id, projectName });
    }, 0);
  };

  useEffect(() => {
    setpageCount(
      data?.invoicesCount % limit === 0
        ? data?.invoicesCount / limit
        : Math.floor(data?.invoicesCount / limit) + 1
    );
  }, [data]);

  return (
    <div className={style.body}>
      <div className={style.header}>
        <div className={`${style.heading} ${style.gap1rem}`}>
          <div>
            <span
              className={style.breadCrumb}
              onClick={handleRedirectProjectsList}
            >
              All Projects&nbsp;
            </span>
            <span className={style.arrowbreadCrum}>&gt;</span>{" "}
            <span
              className={style.breadCrumb}
              onClick={handleRedirectProjectCosting}
            >
              {projectName}
            </span>{" "}
            <span className={style.arrowbreadCrum}>&gt;</span>{" "}
            <span className={`${style.breadCrumb2}`}>
              Invoices & Money Received
            </span>
          </div>
          <div>
            <p className={style.countPCM}>
              <span style={{ fontSize: "1.235rem", fontWeight: "500" }}>
                {data?.invoicesCount}
              </span>
            </p>
          </div>
        </div>{" "}
        {checkValidateAccess(
          projectCostingAndMarginAccessCode?.create_invoice
        ) && (
          <OverlayTrigger
            placement="auto"
            overlay={<Tooltip id="tooltip-example">Add Invoice</Tooltip>}
          >
            <div onClick={handleRedirectAddInvoice}>
              <AddCircleRoundedIcon className={style.plusIcon} />
            </div>
          </OverlayTrigger>
        )}
      </div>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={style.tableContainer}>
            <table>
              <tbody>
                <tr className={style.tHeading}>
                  <th>Invoice Date</th>
                  <th>Invoice No.</th>
                  <th>Invoice Amount</th>
                  <th className={style.ppc}>Money Received Amount</th>
                  <th className={style.ppc}>Money Received Date</th>
                  <th></th>
                </tr>
                {tableBody}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {!isLoading && (
        <div className={style.pagination} style={{ marginRight: "29px" }}>
          <Pagination
            pagesCount={pageCount}
            pageSize={20}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            color="#954B00"
            color2="white"
          />
        </div>
      )}
    </div>
  );
}

function Row({
  invoiceAmount,
  moneyReceived,
  invoiceRaisedDate,
  moneyReceivedDate,
  invoiceNumber,
  handleRedirectEditInvoice,
  handleDeleteInvoice,
  edit_invoice_access,
  delete_invoice_access,
}) {
  function roundToZeroDecimalPlaces(number) {
    const tempVal = addCommasPCM(number.toFixed(0));
    return tempVal === "0" ? "-" : tempVal;
  }
  return (
    <tr className={style.tRow}>
      <td>{Moment(invoiceRaisedDate).format("DD MMM YY")}</td>
      <td>{invoiceNumber}</td>
      <td>{invoiceAmount ? roundToZeroDecimalPlaces(invoiceAmount) : ""}</td>
      <td>{moneyReceived ? roundToZeroDecimalPlaces(moneyReceived) : ""}</td>
      <td>
        {moneyReceivedDate && Moment(moneyReceivedDate).format("DD MMM YY")}
      </td>
      <td style={{ display: "flex" }}>
        {edit_invoice_access && (
          <div style={{ marginRight: "0.2rem" }}>
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-example">Edit Invoice</Tooltip>}
            >
              <CreateIcon
                className={style.editInvoice}
                onClick={handleRedirectEditInvoice}
              />
            </OverlayTrigger>
          </div>
        )}
        {delete_invoice_access && (
          <div>
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-example">Delete Invoice</Tooltip>}
            >
              <DeleteIcon
                className={style.editInvoice}
                onClick={handleDeleteInvoice}
              />
            </OverlayTrigger>
          </div>
        )}
      </td>
    </tr>
  );
}
