import React, { useState, useEffect } from "react";
import Header from "./commonComponent/Header";
import SideBar from "./commonComponent/SideBar";
import style from "./RecurringTaskHistory.module.css";
import Moment from "moment";
import axios from "axios";
import cookie from "react-cookies";
import Pagination from "./commonComponent/Pagination";
import IconOfBackButton from "../../assets/ionic-md-arrow-back.png";
import milestone from "../../assets/milestone.png";
import loaderImg from "../../assets/loader-green2.gif";
import { useHistory } from "react-router-dom";
import constants, { employeeId } from "../../Utils/constants";

const arr = [
    // {
    //   a: "20 Jul (Fri) | 11:00",
    //   b: "Vrinda Sharma",
    //   c: "status",
    //   d: "On Hold > Done",
    // }
];

const TaskHistory = (props) => {
    const taskId = props.history.location.state.task.taskId;
    const [myTaskDetail, setMyTaskDetailByID] = useState();
    const [histories, setHistories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setpageCount] = useState(0);
    const [newData, setnewData] = useState(props.history.location.state.task);
    const [isLoaded, setIsLoaded] = useState(false);

    let limit = 10;

    const handlePageChange = (page, status) => {
        if (status == "next") {
            setCurrentPage(page + 1);
        } else if (status == "prev") {
            setCurrentPage(page - 1);
        }
    };

    // const handleBack = (data2) => {
    //   if (returnPage==1) {
    //     history.push("/viewTask",data2)
    //   } else {
    //     history.push({
    //       pathname: '/EditTask',
    //       state: { task: newData ,
    //         activePage: 3
    //       }
    //     });
    //   }
    // }
    const handleBack = () => {
        history.push({
            pathname: "/taskAssignedByMeDetails",
            state: {
                task: newData,
                activePage: props.history.location.state.activePage,
            },
        });
    };

    const getSingleDetailsById = (id) => {
        axios
            .get(url2 + `/getRECURRINGTaskDetailsByTaskId?empId=${employeeId}&taskId=${id}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: ` ${cookie.load("token")}`,
                },
            })
            .then((res) => {
                setMyTaskDetailByID(res.data.responseData);
            })
            .catch((err) => { });
    };



    const history = useHistory();
    useEffect(() => {
        handleapidata();
        getSingleDetailsById(taskId)
    }, [currentPage]);

    const url2 = constants.apiBaseUrl2;

    function handleapidata() {
        setIsLoaded(true);
        const historyData = axios
            .get(
                url2 +
                "/getAllTaskForRecurringTask?recurringTaskId=" +
                // limit=" +
                // limit +
                // "&page=" +
                // currentPage +
                // "&taskId=" +
                taskId,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        Authorization: ` ${cookie.load("token")}`,
                        employeeId: employeeId,
                    },
                }
            )
            .then((res) => {
                setTimeout(() => {
                    setIsLoaded(false);
                }, 250);
                const responseData = res.data.responseData;
                setHistories(responseData.taskResponse);
                setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
            })
            .catch((err) => {
                return err;
            });
    }

    const goBack = () => {
        history.goBack(-1);
    };
    return (
        <div>
            <Header />
            <div className={style.mainSection}>
                <SideBar />
                <div className={style.rightSection}>
                    <div className={style.taskhistory}>
                        <div
                            onClick={() => handleBack()}
                            className={style.backbutton}
                        ></div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{ fontWeight: '600', width: '100%', fontSize: '20px', marginTop: '-17px', display: 'flex' }} className={style.span}>
                            <div style={{ width: '50%' }}> <span
                                style={{ color: 'green', cursor: 'pointer', fontSize: '16px', marginLeft: '30px', fontSize: '17px' }}
                                onClick={() => history.push('/taskTracker/recurringtask')}
                            >Recurring Tasks</span><span> &gt; </span><span
                                style={{ color: 'green', cursor: 'pointer', fontSize: '16px', fontSize: '17px' }}
                                onClick={goBack}
                            >Task Details</span><span> &gt; </span><span style={{ fontSize: '16px', fontSize: '17px' }}>Task Logs</span> </div>
                            <div style={{ width: '10%' }}> <span style={{ fontSize: '15px', color: 'green', fontWeight: '600' }}>Task ID :</span> <span style={{ fontSize: '15px' }}>{taskId}</span> </div>
                            <div style={{ width: '4%' }}> <span style={{ fontSize: '15px', color: 'green', fontWeight: '600' }}>Title :</span> </div>
                            <div style={{ width: '36%' }}> <span style={{ fontSize: '15px' }}>{myTaskDetail?.title}</span> </div>
                        </div>
                        {/* <div
                            style={{ padding: '15px', cursor: "pointer" }}
                            className={style.back_btn}
                            onClick={goBack}
                        >
                            <img className={style.back_btn_img} src={IconOfBackButton} />
                            Back
                        </div> */}
                    </div>

                    <div className={style.container}>

                        <div>
                            {isLoaded ? (
                                <div>
                                    <img src={loaderImg} alt="loader" className={style.loader} />
                                    <p className={style.loaderPara}>Loading...</p>
                                </div>
                            ) : (
                                ""
                            )}
                            {histories.length > 0 && !isLoaded ? (
                                <div>
                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                        <thead>
                                            <tr className={style.th} style={{ borderBottom: '1px solid #ddd', backgroundColor: "#f5f7f9" }}>
                                                <th
                                                    style={{
                                                        width: '10rem',
                                                        fontSize: '14px',
                                                        padding: '8px'
                                                    }}
                                                >
                                                    Creation Date
                                                </th>
                                                <th
                                                    style={{
                                                        width: '55rem',
                                                        fontSize: '14px',
                                                        padding: '8px'
                                                    }}
                                                >
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div>
                                        <table style={{ width: '100%' }}>
                                            <tbody style={{ backgroundColor: "#F5F6FA" }}>
                                                {histories &&
                                                    histories.map((historys, index) => {
                                                        return (
                                                            <tr className={style.th} key={index} style={{ borderBottom: '1px solid #ddd', backgroundColor: "white" }}>
                                                                <td
                                                                    style={{
                                                                        width: '10rem',
                                                                        fontSize: '14px',
                                                                        fontWeight: '400',
                                                                        padding: '8px',
                                                                        backgroundColor: "white"
                                                                    }}
                                                                >
                                                                    {Moment(historys.created).format("DD MMM")}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: '55rem',
                                                                        fontSize: '14px',
                                                                        fontWeight: '400',
                                                                        padding: '8px',
                                                                        backgroundColor: "white"
                                                                    }}
                                                                >
                                                                    {historys.status}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : (
                                <div className={style.noTasks}>
                                    {!isLoaded ? (
                                        <div style={{ display: "block", marginTop: "10%" }}>
                                            <img src={milestone} />
                                            <h2>No Task History Yet</h2>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            )}


                            <div className={style.mobileContainer}>
                                {arr.map((ar, index) => {
                                    return (
                                        <div
                                            style={{
                                                display: "block",
                                                borderBottom: "1px solid #707070",
                                                marginBottom: "10px",
                                                paddingBottom: "10px",
                                            }}
                                        >
                                            <p>
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "700",
                                                        paddingTop: "3px",
                                                        opacity: ".85",
                                                    }}
                                                >
                                                    Date Modified :
                                                </span>
                                                {ar.a}
                                            </p>
                                            <p>
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "700",
                                                        paddingTop: "3px",
                                                        opacity: ".85",
                                                    }}
                                                >
                                                    Username :
                                                </span>
                                                {ar.b}
                                            </p>
                                            <p>
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "700",
                                                        paddingTop: "3px",
                                                        opacity: ".85",
                                                    }}
                                                >
                                                    Fields :
                                                </span>
                                                {ar.c}
                                            </p>
                                            <p>
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "700",
                                                        paddingTop: "3px",
                                                        opacity: ".85",
                                                    }}
                                                >
                                                    Change :
                                                </span>
                                                {ar.d}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {histories.length > 0 && pageCount > 0 && (
                        <div
                            className={style.paginationmobileContainer}
                            style={{ marginTop: "1rem", marginRight: "1.5rem" }}
                        >
                            <Pagination
                                pagesCount={pageCount}
                                pageSize={limit}
                                onPageChange={handlePageChange}
                                currentPage={currentPage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TaskHistory;
