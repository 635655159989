import React from "react";
import style from "./elementCommon.module.scss";
const CheckBoxCustom = ({ key, item, checked, onChange, value }) => {
  
  return (
    <div className={style.list} key={key}>
      <input
        type="checkbox"
        className={style.vh}
        id={item}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label
        htmlFor={item}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={style.label}
      >
        {item}
      </label>
    </div>
  );
};
export default CheckBoxCustom;
