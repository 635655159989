import React, { useContext, useEffect, useState } from "react";
import style from "./dashboard.module.css";
import { useQuery } from "react-query";
import {
  getAddCountsForDashboard,
  getfaSkillDashboard,
} from "../../../../Utils/axios/umsApi";
import { useHistory } from "react-router-dom";
import { path } from "../../../../routes/PathNames";
import { DataContext } from "../../../Projects_and_Resource_Master/CommonComponent/useContext/DataContext";
import * as Img from "../../../../assets/index";
import Sidebar from "../sidebar/sidebar";
import AllProjects from "../allProjectsLists/allProjectsLists";
const Dashboard = () => {
  return (
    <div className={style.main_client_body}>
      <Sidebar />
      <AllProjects />
    </div>
  );
};
export default Dashboard;
