import React from "react";
import { EditissueComp } from "./EditIssueCom/EditIssueComp";
import style from "./EditIssue.module.css"
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
export const EditIssue =()=>{
    return(<>
        <Header />
       <div className={style.mainSection}>
         <SideBar />
         <EditissueComp />
       </div>
     </>)
 }
