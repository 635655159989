import React from "react";
import QuestionListCom from "./QuestionListComponent/QuestionListCom";
import QuestionnaireSidebar from "../sidebar/SideBar";
import style from"./QuestionList.module.css"
export const QuestionListt =()=>{
    return(<>
      <div className={style.mainSection} >
        <QuestionnaireSidebar />
        <QuestionListCom />
      </div>
    </>)
}
