import axios from "axios";
import cookie from "react-cookies";
import { ToastifyError } from "../../../App";

const api = axios.create({
  baseURL: process.env.REACT_APP_RESOURCEMASTER_APP_URL,
});
// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = cookie.load("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] =
      process.env.REACT_APP_TRUSTED_DOMAINS;
    if (config.url.includes("/getClientOnSite")) {
      config.headers["Authorization"] = null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status >= 500) {
      ToastifyError("Internal Server Error");
      return Promise.reject(error);
    }

    if (error.response.status >= 400 && error.response.status < 500) {
      ToastifyError("Something Went Wrong");
    }
    return Promise.reject(error);
  }
);

// for hitting get api
export const get = async (url) => {
  try {
    const response = await api.get(url);

    return response;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// for hitting post api
export const post = async (url, data) => {
  try {
    const response = await api.post(url, data);

    return response;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// for hitting put api
export const put = async (url, data) => {
  try {
    const response = await api.put(url, data);

    return response;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// for hitting patch api
export const patch = async (url, data) => {
  try {
    const response = await api.patch(url, data);

    return response;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// for hitting delete api
export const del = async (url) => {
  try {
    const response = await api.delete(url);

    return response;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
