import React, { useEffect, useState } from "react";
import Header from "../../../GenericComponent/header/CommonHeader";

import SideBar from "../sidebar/SideBar";
import style from "./ClientContractListIms.module.scss";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getApplicableContractStatusList,
  getClientContractIms,
  getClientListFilter,
  getEmployeeList,
  getExecutedByList,
  getDownloadClientContractList
} from "../../../Utils/axios/clientContractApi";
import DownloadClientContractList from "./downloadClientContractListims";
import { path } from "../../../routes/PathNames";
import { checkIfEmpty, clientContractCode, renderTitle, renderValue } from "../../../Utils/constants";
import Pagination from "../../ClientQuestionnaire/CommonComponent/Pagination";
import CommonDropdown from "../../../GenericComponent/ui/CommonDropdown";
import useValidationAccess from "../../custumHooks/useValidationAccess";

function ClientContractListIms() {
  const history = useHistory();
  const location = useLocation();

  const { checkValidateAccess } = useValidationAccess();

  const [filter, setFilter] = useState(
    location?.state?.ccListIms
      ? location?.state?.ccListIms
      : {
          limit: 10,
          page: 1,
          clientId: null,
          statusId: null,
          executedById: null,
        }
  );

  const { data: getAllClientContract } = useQuery(
    ["getClientContractIms", filter],
    () =>
      getClientContractIms(
        filter.clientId,
        filter.statusId,
        filter.salesRepresentativeId,
        filter.executedById,
        filter.limit,
        filter.page
      ),
    {}
  );
  const { data: getAllClientListForDownload } = useQuery(
    ["getDownloadClientContractList"],
    () =>
    getDownloadClientContractList(),
    {}
  );

  const { data: getApplicableContractStatusOptions } = useQuery(
    ["getApplicableContractStatusList"],
    () => getApplicableContractStatusList()
  );

  useEffect(() => {
    setFilter({ ...filter, page: 1 });
  }, [
    filter.clientId,
    filter.statusId,
    filter.salesRepresentativeId,
    filter.executedById,
    filter.limit,
  ]);

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setFilter((prev) => ({ ...prev, page: filter.page + 1 }));
    } else if (status === "prev") {
      setFilter((prev) => ({ ...prev, page: filter.page - 1 }));
    }
  };

  const { data: getClientListOptions } = useQuery(
    ["getClientListFilter"],
    () => getClientListFilter(),
    {}
  );

  const { data: getEmployeeListOptions } = useQuery(
    ["getEmployeeList"],
    () => getEmployeeList(),
    {}
  );

  const { data: getExecutedByListOptions } = useQuery(
    ["getExecutedByList"],
    () => getExecutedByList()
  );

  const getStatusColor = (status) => {
    if (status === "Active") {
      return "green";
    } else if (status === "Inactive") {
      return "red";
    } else {
      return null;
    }
  };

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <div style={{ width: "20vw" }}>
          <SideBar />
        </div>
        <div style={{ width: "80vw", padding: "1rem" }}>
          <div className={style.titleBtn}>
            <div className={style.titleBtnLeft}>
              <p className={style.title}>Client Contracts</p>
              <button>{getAllClientContract?.totalDataCount}</button>
            </div>
            <div style={{ width: "8%" }} aria-label="PM0001">
                <p className={style.download}>
                  <span
                    className={style.backColor1}
                    style={{
                      fontSize: "2rem",
                      fontWeight: "500",
                      cursor: "pointer",
                      color: "#562679",
                      display:"flex",
                      justifyContent:"center"

                    }}
                  >
                    <DownloadClientContractList data={getAllClientListForDownload} />
                  </span>
                </p>
              </div>
            <div className={style.titleBtnRight}>
              {/* filter 1 */}
              <div className={style.dropdownContainer16}>
                <CommonDropdown
                  color="#562679"
                  placeholder="Sales Representative"
                  value={checkIfEmpty(filter?.salesRepresentativeId, {
                    id: filter?.salesRepresentativeId,
                    label: filter?.salesRepresentative,
                  })}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      salesRepresentativeId: e.id,
                      salesRepresentative: e.label,
                    });
                  }}
                  options={getEmployeeListOptions}
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      salesRepresentativeId: null,
                      salesRepresentative: null,
                    })
                  }
                />
              </div>
              {/* filter 2 */}
              <div className={style.dropdownContainer16}>
                <CommonDropdown
                  color="#562679"
                  placeholder="Client"
                  value={checkIfEmpty(filter?.clientId, {
                    id: filter?.clientId,
                    label: filter?.clientName,
                  })}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      clientId: e.id,
                      clientName: e.label,
                    });
                  }}
                  options={getClientListOptions}
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      clientId: null,
                      clientName: null,
                    })
                  }
                />
              </div>

              {/* filter 4 */}
              <div className={style.dropdownContainer12}>
                <CommonDropdown
                  color="#562679"
                  value={checkIfEmpty(filter?.executedById, {
                    id: filter?.executedById,
                    label: filter?.executedStatus,
                  })}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      executedById: e.id,
                      executedStatus: e.label,
                    });
                  }}
                  options={getExecutedByListOptions}
                  placeholder="Executed By"
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      executedById: null,
                      executedStatus: null,
                    })
                  }
                />
              </div>
              {/* filter 3 */}
              <div className={style.dropdownContainer8}>
                <CommonDropdown
                  color="#562679"
                  value={checkIfEmpty(filter?.statusId, {
                    id: filter?.statusId,
                    label: filter?.statusName,
                  })}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      statusId: e.id,
                      statusName: e.label,
                    });
                  }}
                  options={[
                    getApplicableContractStatusOptions?.[0],
                    getApplicableContractStatusOptions?.[1],
                    getApplicableContractStatusOptions?.[2],
                    getApplicableContractStatusOptions?.[3],
                  ]}
                  placeholder="Status"
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      statusId: null,
                      statusName: null,
                    })
                  }
                />
              </div>
              {checkValidateAccess(clientContractCode.CreateContract) && (
                <button
                  onClick={() =>
                    history.push({
                      pathname: path.cc_create.replace(
                        ":name",
                        "client-contracts"
                      ),
                      state: {
                        ...location.state,
                        page1: "Client Contracts",
                        ccListIms: filter,
                        selectedClient: false,
                      },
                    })
                  }
                  title="Create Contract"
                >
                  +
                </button>
              )}
            </div>
          </div>
          <div className={style.table}>
            <div className={style.th}>
              <p>Client Name</p>
              <p>Sales Representative</p>
              <p>Business Type</p>
              <p>Engagement Status</p>
            </div>
            <div>
              {getAllClientContract &&
                (getAllClientContract?.clientDataResponse?.length > 0 ? (
                  getAllClientContract.clientDataResponse?.map(
                    (item, index) => {
                      return (
                        <div
                          className={style.td}
                          key={item?.id}
                          style={{ position: "relative" }}
                        >
                          <p
                            className={style.link}
                            onClick={() => {
                              history.push({
                                pathname: path.cc_listById
                                  .replace(":id", item?.clientId)
                                  .replace(":name", "client-contracts"),
                                state: {
                                  ...location.state,
                                  page1: "Client Contracts",
                                  ccListIms: filter,
                                  selectedClient: false,
                                },
                              });
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                history.push({
                                  pathname: path.cc_listById
                                    .replace(":id", item?.clientId)
                                    .replace(":name", "client-contracts"),
                                  state: {
                                    ...location.state,
                                    page1: "Client Contracts",
                                    ccListIms: filter,
                                    selectedClient: false,
                                  },
                                });
                              }
                            }}
                            tabIndex={0}
                            title={renderTitle(item?.clientName, 30)}
                          >
                            {renderValue(item?.clientName, 30)}
                          </p>
                          <p title={renderTitle(item?.salesRepresentative, 30)}>
                            {renderValue(item?.salesRepresentative, 30)}
                          </p>
                          <p>{item?.businessType}</p>

                          <p style={{ color: getStatusColor(item?.engagementStatus) }}>{item?.engagementStatus}</p>
                        </div>
                      );
                    }
                  )
                ) : (
                  <p className={style.no_data}>No Data Found</p>
                ))}
            </div>
          </div>
          <div className={style.pagination}>
            <Pagination
              onPageChange={handlePageChange}
              currentPage={filter?.page}
              pagesCount={getAllClientContract?.totalDataCount / 10}
              color="#855ea1"
              color2="white"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientContractListIms;
