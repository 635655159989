import { path } from "./PathNames";

import LazyResourcePriceListing from "../Pages/PriceRevisionApplication/pages/ResourcePriceListing";

import LazyResourceDetails from "../Pages/PriceRevisionApplication/pages/ResourceDetails";

import LazyRevisionDues from "../Pages/PriceRevisionApplication/pages/RevisionDuesListing";

const PriceRevisionRoute = [
  {
    path: path.price_listing,
    component: LazyResourcePriceListing,
  },
  {
    path: path.resource_details,
    component: LazyResourceDetails,
  },
  {
    path: path.revision_dues,
    component: LazyRevisionDues,
  },
];
export default PriceRevisionRoute;
