import React, { useEffect, useState } from 'react';
import style from './UploadFile.module.css';
import { useMutation } from 'react-query';
import { uploadExpInvFile } from '../../../Utils/axios/expenceApi';
import { FaFileUpload } from "react-icons/fa";

const UploadMutliFile = ({ handleFileChange, handleFileDelete, documentResponses }) => {
    let documentURL=[];
    if(documentResponses && documentResponses?.every(item => typeof item === 'object' && item !== null && !Array.isArray(item))){
        documentResponses.map(obj=>documentURL.push(obj.documentURL));
    }
    else{
        documentURL = documentResponses;
    }
    
    return (
        <div style={{ display: 'flex', margin: '5px' }}>
            <div>
                <input
                    type="file"
                    multiple
                    onChange={(e) => handleFileChange(e)}
                    style={{ display: 'none' }}
                    id="fileInput"
                />
                <label htmlFor="fileInput" style={labelStyle}>
                    <FaFileUpload />
                </label>
                <div style={{ marginTop: '10px' }}>
                    {documentURL?.length > 0 && (
                        <>
                            <p className={style.text}>Selected Files: </p>
                            {documentURL?.map((url, index) => (
                                <div key={index} title={url.split('@')[1]} className={`${style.file_name}`}>
                                    <p className={style.text}>{url.split('@')[1]}</p>
                                    <span onClick={() => handleFileDelete(url.trim())}>x</span>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const labelStyle = {
    fontSize: '30px',
    color: '#855ea1',
    cursor: 'pointer',
    display: 'inline-block'
};


export default UploadMutliFile;
