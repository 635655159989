import React, { useState } from "react";
import SideBar from "../commonComponent/SideBar";
import Header from "../commonComponent/Header";
import style from "./EditTask.module.css";
import EditTaskComponent from "./component/EditTaskComponent";

const EditTask = (props) => {
  const [taskData, settaskData] = useState(props.history.location.state.task);
  const [returnPage, setReturnPage] = useState(
    props.history.location.state.activePage
  );

  return (
    <div>
      <Header />

      <div className={style.mainSection}>
        <SideBar />

        <div className={style.rightSection}>
          <EditTaskComponent taskData={taskData} returnPage={returnPage} />
          {/* <Filter /> */}
        </div>
      </div>
    </div>
  );
};

export default EditTask;
