import React, { useState, useEffect } from "react";
import style from "./RevisionDuesListing.module.css";
import Header from "../component/Header/Header";
import Sidebar from "../component/Sidebar/Sidebar";
import { useQuery } from "react-query";
import { getRevisionDuesList } from "../../../Utils/axios/priceRevisionApi";
import Loading from "../component/Loading/Loading";
import Pagination from "../../ClientQuestionnaire/CommonComponent/Pagination";
import moment from "moment";
import ReasonImg from "../../../assets/Reason.png";
import { Modal } from "react-bootstrap";
import pdf from "../../../assets/pdf.png";
import constants, { employeeId } from "../../../Utils/constants";
import axios from "axios";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";

const RevisionDuesListing = () => {
  const [page, setPage] = useState(1);
  const [reason, setReason] = useState("");
  const [guideUrl, setGuideUrl] = useState("");
  const [show, setShow] = useState(false);
  const baseUrl = constants.apiBaseUrl;

  const toggleChange = () => {
    setFilterToggle(!filterToggle);
  };

  const [filterToggle, setFilterToggle] = useState(false);

  const getUserInfo = (empId) => {
    axios
      .get(baseUrl + `/getUserApplications?userId=${empId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        let data = res.data.responseData?.applications;
        data.map((item) => {
          if (item.applicationId === 14) {
            setGuideUrl(item.guideUrl);
          }
        });
      })
      .catch((err) => {
        return err;
      });
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const { data: revisionDuesList, isLoading } = useQuery(
    ["getRevisionDuesList", page, filterToggle],
    () => getRevisionDuesList(10, page, filterToggle)
  );

  const handleClose = () => {
    setShow(false);
  };
  const handleGuideClick = (url) => {
    window.open(url, "_blank");
  };
  useEffect(() => {
    getUserInfo(employeeId);
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <Header />
      <div className={style.revision_dues_main_div}>
        <div className={style.sidebar_div}>
          <Sidebar />
        </div>
        <div className={style.main_div}>
          <div className={style.main_div_heading}>
            <div className={style.main_left_div}>
              <h1 className={style.price_heading}>Revision Dues List</h1>
            </div>
            <p className={style.price_count}>
              {revisionDuesList?.resourcesCount}
            </p>
            <div
              className={style.guideIcon}
              title="Guide"
              onClick={() => handleGuideClick(guideUrl)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleGuideClick(guideUrl);
                }
              }}
              tabIndex={0}
            >
              <img src={pdf} alt="guide" />
            </div>
          </div>

          {revisionDuesList?.resourcesCount > 0 ? (
            <div className={style.table_div}>
              <table className={style.table}>
                <thead className={style.table_heading}>
                  <tr>
                    <th
                      className={`${style.table_head} ${style.sticky_head_code}`}
                    >
                      Emp Code
                    </th>
                    <th
                      className={`${style.table_head} ${style.sticky_head_col1}`}
                    >
                      Emp Name
                    </th>
                    <th
                      className={`${style.table_head} ${style.sticky_head_col2}`}
                    >
                      Emp <br />
                      Start Date
                    </th>
                    <th className={`${style.table_head}`}>Start Month</th>
                    <th className={`${style.table_head}`}>Client</th>
                    <th className={`${style.table_head}`}>
                      Contract <br />
                      (Start Date)
                    </th>
                    <th className={`${style.table_head}`}>
                      Contract <br />
                      (End Date)
                    </th>
                    <th className={`${style.table_head}`}>
                      Contract <br />
                      (End Month)
                    </th>
                    <th className={`${style.table_head}`}>
                      Last Revision
                      <br /> Date
                    </th>
                    <th className={`${style.table_head}`}>
                      Last Client
                      <br /> Rate
                    </th>
                    <th className={`${style.table_head}`}>
                      Current Client
                      <br /> Rate
                    </th>
                    <th className={`${style.table_head}`}>Revised</th>
                    <th className={`${style.table_head}`}>Reason</th>
                    <th className={`${style.table_head}`}>Approved By</th>
                    <th className={style.table_head}>
                      Rate Revision <br /> Due/Review Date
                    </th>
                    <th
                      className={style.table_head}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        onClick={() => toggleChange()}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            toggleChange();
                          }
                        }}
                        tabIndex={0}
                      >
                        {filterToggle ? (
                          <BiUpArrowAlt
                            onClick={() => {
                              setFilterToggle(false);
                            }}
                            style={{
                              fontSize: "1.1rem",
                              cursor: "pointer",
                              color: "blue",
                            }}
                          />
                        ) : (
                          <BiDownArrowAlt
                            onClick={() => setFilterToggle(true)}
                            style={{
                              fontSize: "1.1rem",
                              cursor: "pointer",
                              color: "green",
                            }}
                          />
                        )}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ position: "relative" }}>
                  {revisionDuesList?.resources?.map((item, index) => (<TableRow key={"pRevision"+index} item={item} setShow={setShow} setReason={setReason} />))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={style.no_data_div}>No Data Found</div>
          )}

          {revisionDuesList?.resourcesCount > 10 && (
            <div className={style.pagination_div}>
              <Pagination
                onPageChange={handlePageChange}
                currentPage={page}
                pagesCount={revisionDuesList?.resourcesCount / 10}
                color="#00979D"
                color2="white"
              />
            </div>
          )}
        </div>
      </div>

      <Modal show={show} centered size="md" onHide={handleClose}>
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#00979d" }}>Reason</h6>
        </Modal.Header>
        <div className={style.modal_para_div}>
          {reason
            ? reason?.split("\n")?.map((line, index) => (
                <>
                  {line}
                  {index !== reason?.split("\n")?.length - 1 && <br />}
                </>
              ))
            : ""}
        </div>
      </Modal>
    </>
  );
};

const TableRow = ({item, setShow, setReason}) => {
  return (<tr>
    <td
      className={`${style.table_column}  ${style.sticky_column_code}`}
    >
      <p
        title={item?.resourceCode}
        className={style.code_para}
      >
        {item?.resourceCode}
      </p>
    </td>
    <td
      className={`${style.table_column} ${style.sticky_column1}`}
    >
      <p
        title={item?.resourceName}
        className={style.resource_para}
      >
        <span style={{ fontSize: "0.8rem" }}>
          {item?.resourceName}
        </span>
      </p>
    </td>
    <td
      className={`${style.table_column} ${style.sticky_column2}`}
    >
      {item?.resourceStartDate
        ? moment(item?.resourceStartDate)?.format("DD MMM YY")
        : "NA"}
    </td>
    <td className={`${style.table_column}`}>
      {item?.startMonth || "NA"}
    </td>
    <td className={`${style.table_column}`}>
      {item?.clientName || "NA"}
    </td>
    <td className={`${style.table_column}`}>
      {item?.contractStartDate
        ? moment(item?.contractStartDate)?.format("DD MMM YY")
        : "NA"}
    </td>
    <td className={`${style.table_column}`}>
      {item?.ongoingStatus
        ? "Ongoing"
        : item?.contractEndDate
        ? moment(item?.contractEndDate)?.format("DD MMM YY")
        : "NA"}
    </td>
    <td className={`${style.table_column}`}>
      {item?.contractEndDate
        ? moment(item?.contractEndDate)?.format("MMM")
        : "NA"}
    </td>
    <td className={`${style.table_column}`}>
      {item?.lastRevisionDate
        ? moment(item?.lastRevisionDate)?.format("DD MMM YY")
        : "NA"}
    </td>
    <td className={`${style.table_column}`}>
      {item?.lastClientRate}
    </td>
    <td className={`${style.table_column}`}>
      {item?.currentClientRate || "NA"}
    </td>
    <td className={`${style.table_column}`}>
      {item?.isRevised !== null
        ? item?.isRevised === true
          ? "Done"
          : "Defer"
        : "NA"}
    </td>
    <td className={`${style.table_column}`}>
      {item?.reason ? (
        <img
          onClick={() => {
            setShow(true);
            setReason(item?.reason);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setShow(true);
              setReason(item?.reason);
            }
          }}
          tabIndex={0}
          className={style.reason_img}
          src={ReasonImg}
          alt="img"
        />
      ) : (
        "NA"
      )}
    </td>
    <td className={`${style.table_column}`}>
      {item?.approvedByName || "NA"}
    </td>
    <td
      className={style.table_column}
      style={
        item?.isRateReviewDateManually
          ? { color: "#00979d", fontWeight: "500" }
          : null
      }
    >
      {item?.rateRevisionDueDate
        ? moment(item?.rateRevisionDueDate)?.format(
            "DD MMM YY"
          )
        : "NA"}
    </td>
  </tr>)
}

export default RevisionDuesListing;
