import React, { useState } from "react";
import SideBar from "../../Clients/component/SideBar";
import Header from "../../../taskTracker/commonComponent/Header";
import style from "./Report.module.css";
import { Tooltip } from "@material-ui/core";
import AppsIcon from "@mui/icons-material/Apps";
import SubjectIcon from "@mui/icons-material/Subject";

import { useHistory } from "react-router";

function RMSReport() {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState(null);
  // radio button handler
  function handleOptionChange(event) {
    setSelectedValue(event.target.value);
  }

  const DataHandler = () => {
    switch (selectedValue) {
      case "Pool":
        history.push("/Pool");
        break;
      case "OffShore":
        history.push("/OffShore");
        break;
      case "OnSite":
        history.push("/OnSite");
        break;
      default:
        // Code to handle any other location not covered by the previous cases
        break;
    }
  };
  const Recordhandler = () => {
    switch (selectedValue) {
      case "Pool":
        history.push("/PoolRecord");
        break;
      case "OffShore":
        history.push("/OffShoreRecord");
        break;
      case "OnSite":
        history.push("/OnSiteRecord");
        break;
      default:
        // Code to handle any other location not covered by the previous cases
        break;
    }
  };
  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.secondDiv}>
          <label>
            <input
              type="radio"
              name="location"
              value="OffShore"
              checked={selectedValue === "OffShore"}
              onChange={handleOptionChange}
            />
            Offshore
          </label>
          <label>
            <input
              type="radio"
              name="location"
              value="OnSite"
              checked={selectedValue === "OnSite"}
              onChange={handleOptionChange}
            />
            Onsite
          </label>

          <label>
            <input
              type="radio"
              name="location"
              value="Pool"
              checked={selectedValue === "Pool"}
              onChange={handleOptionChange}
            />
            Pool
          </label>

          <span className={style.reporticon}>
            {" "}
            <Tooltip title="Report" placement="bottom">
              <AppsIcon className={style.Icon} onClick={Recordhandler} />
            </Tooltip>{" "}
            <Tooltip title="Listing" placement="bottom">
              <SubjectIcon
                className={style.Icon}
                onClick={() => DataHandler()}
              />
            </Tooltip>
          </span>
        </div>
      </div>
    </>
  );
}

export default RMSReport;
