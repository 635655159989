import React, { useState, useEffect } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Toastify, ToastifyError } from "../../../../App";
import {
  getEmployeeDetailsByEmployeeId,
  viewFeedback,
  deletePerformanceReview,
  viewALlFeedbackOfEmp,
  downloadPerformanceReviewInformation,
} from "../../utils/api";
import style from "./ViewFeedback.module.css";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { EprAccessCode } from "../../../../Utils/constants";
import { FiDownload } from "react-icons/fi";
import { notifySuccess } from "../../../atsDashboard/utils/notify";


function ViewFeedback({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) {
  const [show, setShow] = useState(false);
  const [allData, setAllData] = useState({});
  const { data: profileData } = useQuery(
    ["  getEmployeeDetailsByEmployeeId"],
    () => getEmployeeDetailsByEmployeeId(prevData?.empId),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );
  const { data: profileData1, refetch } = useQuery(
    ["viewFeedback"],
    () => viewFeedback(prevData?.pReId),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );
  
  const { data: allFeedbackOfEmp } = useQuery(
    ["viewALlFeedbackOfEmp"],
    () => viewALlFeedbackOfEmp(prevData?.empId),
    {}
  );
  useEffect(() => {
    setAllData({
      empCode: profileData?.empCode,
      empName: profileData?.name,
      feedbackRequests: [],
    })
  }, [profileData, allFeedbackOfEmp])
  function convertOverallRating(data) {
    if (Number(data.statusIdRating) === 1) {
      switch (data.overallRatingValue) {
        case '1': return '1 Poor';
        case '2': return '2 Good';
        case '3': return '3 Excellent';
        case 'NE': return 'NE Not Evaluated';
        case 'NS': return 'NS Not Skill';
        default: return data.overallRatingValue;
      }
    }
    else if (Number(data.statusIdRating) === 2) {
      switch (data.overallRatingValue) {
        case '1': return '1 Poor';
        case '2': return '2 Average';
        case '3': return '3 Good';
        case '4': return '4 Very Good';
        case '5': return '5 Excellent';
        case 'NE': return 'NE Not Evaluated';
        case 'NS': return 'NS Not Skill';
        default: return data.overallRatingValue;
      }
    }
    else if (Number(data.statusIdRating) === 3) {
      if (data.overallRatingValue === 'NE') {
        return 'NE Not Evaluated';
      }
      else if (data.overallRatingValue === 'NS') {
        return 'NS Not Skill';
      }
      else if (data.overallRatingValue === ""){
        return `${data.overallRatingValue} `
      }
      else if (data.overallRatingValue < 4) {
        return `${data.overallRatingValue} Poor`;
      }
      else if (data.overallRatingValue < 6) {
        return `${data.overallRatingValue} Average`;
      }
      else if (data.overallRatingValue < 8) {
        return `${data.overallRatingValue} Good`;
      }
      else if (data.overallRatingValue >= 8) {
        return `${data.overallRatingValue} Excellent`;
      }
      else {
        return data.overallRatingValue;
      }

    }
    else {
      return data.overallRatingValue;
    }
  }
  const handleDownloadClick = () => {
    downloadPerformanceReview.mutate(allData)
    notifySuccess('Download started...');
  }
  useEffect(() => {
    console.log(allFeedbackOfEmp)
    if(allFeedbackOfEmp!==undefined){
    if (Array.isArray(allFeedbackOfEmp)) {
      allFeedbackOfEmp?.map((feedbackOfEmp) => {
        const overallRatingValueString = convertOverallRating(feedbackOfEmp);
        const feedbackSkills = [];
        feedbackOfEmp.feedbackSkill?.length && feedbackOfEmp.feedbackSkill?.map(skill => {
          feedbackSkills.push(skill);
        })
        setAllData(prevAllData => {

          return {
            ...prevAllData,
            feedbackRequests: [
              ...prevAllData.feedbackRequests,
              {
                clientName: feedbackOfEmp.clientName,
                feedbackDescription: feedbackOfEmp.feedbackDescription,
                feedbackSkills: feedbackSkills,
                feedbackTypeId: feedbackOfEmp.feedbackTypeId,
                file: feedbackOfEmp.filePath,
                valueNS: "NS - Candidate does not have skill",
                valueNE: "NE - Skill not evaluated by interviewer",
                overallFeedback: feedbackOfEmp.overallFeedback,
                overallRatingValue: overallRatingValueString,
                platform: feedbackOfEmp.onlinePlatform,
                projectName: feedbackOfEmp.projectName,
                reviewDate: moment(feedbackOfEmp.reviewDate).format("DD MMM YY"),
                reviewSource: feedbackOfEmp.reviewSourceType,
                reviewType: feedbackOfEmp.reviewType,
                reviewerName: feedbackOfEmp.reviewedBy,
                status: feedbackOfEmp.status
              }
            ]
          }
        })
      })
    }}
  }, [allFeedbackOfEmp])

  const mutation = useMutation(deletePerformanceReview, {
    onSuccess: (res) => {
      Toastify("Deleted Successfully.");
      setNextScreen("EmployeeFeedbackList");
      setPrevData((prevData) => ({
        ...prevData,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeeFeedbackList",
        page1: "EmployeePerformanceReview",
        page2: "EmployeeFeedbackList",
        page3: null,
        active: "EmployeeFeedbackList",
      }));
    },
    onError: () => {
      ToastifyError("Something went wrong.");
      setShow(false);
    },
  });
  const downloadPerformanceReview = useMutation(downloadPerformanceReviewInformation, {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute(
        "download",
        `InnovationM - ${profileData.name} - All_Perfomance_Reviews.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  });
  useEffect(() => {
    refetch();
  }, [profileData1]);
  const { checkValidateAccess } = useValidationAccess();
  return (
    <>
      <div className={style.breadcrumContainer}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        >
          <span
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              cursor: "pointer",
              color: "#a15e5e",
            }}
          >
            <FiDownload
              style={{ verticalAlign: "baseline" }}
              onClick={handleDownloadClick}
            />
          </span>
        </BreadCrum>
      </div>
      {/* first */}
      <div className={style.card}>
        <p className={style.title}>Employee Details</p>
        <div className={style.Cu_row1}>
          <div className={style.cu_col1}>
            <p className={style.cu_label}>Employee Name</p>
            <p>
              <p>{` ${profileData?.name} (${profileData?.empCode})`}</p>
            </p>
          </div>
        </div>
      </div>
      {profileData1 && !prevData?.showAllFeedback && (
        <>
          {" "}
          {/* second */}
          <div className={style.card}>
            <p className={style.title}>Reviewer</p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col11}>
                <p className={style.cu_label}>Review Date</p>
                <p>
                  {moment(profileData1?.reviewDateView).format("DD MMM YY")}
                </p>
              </div>
              <div className={style.cu_col11}>
                <p className={style.cu_label}>Review Source</p>
                <p>{profileData1?.reviewSourceType}</p>
              </div>
              <div className={style.cu_col11}>
                <p className={style.cu_label}>Review Type</p>
                <p>{profileData1?.reviewType}</p>
              </div>
              {profileData1?.reviewSourceType !== "Client" &&
                profileData1?.reviewSourceType !== "Online" ? (
                <div className={style.cu_col11}>
                  <p className={style.cu_label}>Reviewed By</p>
                  <p>{profileData1?.reviewedBy}</p>
                </div>
              ) : null}
              {profileData1?.reviewSourceType === "Client" ? (
                <div className={style.cu_col11}>
                  <p className={style.cu_label}>Client Name</p>
                  <p>{profileData1?.clientName}</p>
                </div>
              ) : null}
              {profileData1?.reviewSourceType === "Online" ? (
                <div className={style.cu_col11}>
                  <p className={style.cu_label}>Online Platform</p>
                  <p>{profileData1?.onlinePlatform}</p>
                </div>
              ) : null}
            </div>
            <div className={style.Cu_row1}>
              {profileData1?.projectName != null ? (
                <>
                  <div className={style.cu_col11}>
                    <p className={style.cu_label}>Project Name</p>
                    <p>{profileData1?.projectName}</p>
                  </div>
                </>
              ) : null}
              {profileData1?.reviewSourceType === "Client" ? (
                profileData1?.reviewedByName !== "" ? (
                  <div className={style.cu_col11}>
                    <p className={style.cu_label}>Reviewed By</p>
                    <p>{profileData1?.reviewedBy}</p>
                  </div>
                ) : null
              ) : null}
              {/* {profileData1?.reviewSourceType == "Online" ? (
            <div className={style.cu_col11}>
              <p className={style.cu_label}>Online Platform</p>
              <p>{profileData1?.onlinePlatform}</p>
            </div>
          ) : null} */}

              <div className={style.cu_col11}></div>
              <div className={style.cu_col11}></div>
            </div>
          </div>
          {/* third */}
          <div className={style.card2}>
            <p className={style.title}>Feedback</p>
            {/* Rating */}
            {Number(profileData1?.feedbackTypeId) === 1 ? (
              <div>
                <div className={style.rating}>
                  <p>
                    <span className={style.cu_label}>Skills Rating(1-10):</span>{" "}
                    1-3 Poor, 4-5 Average, 6-7 Good, 8-10 Excellent
                  </p>
                  <p></p>
                  <div className={style.box}>
                    {profileData1?.feedbackSkill?.map((i) => {
                      return (
                        <div className={style.boxitem}>
                          <p style={{ width: "25%" }}>{i?.label}</p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gridColumnGap: "50%",
                            }}
                          >
                            <div className={style.ratingAlign}>
                              <p style={{ width: "11vw" }}>
                                {i?.rating >= 1 && i?.rating <= 3
                                  ? "Poor"
                                  : i?.rating >= 4 && i?.rating <= 5
                                    ? "Average"
                                    : i?.rating >= 6 && i?.rating <= 7
                                      ? "Good"
                                      : i?.rating >= 8 && i?.rating <= 10
                                        ? "Excellent"
                                        : null}
                              </p>
                              <p style={{ width: "1vw" }}>{i?.rating}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={style.feedback}>
                  <p className={style.cu_label}>
                    Overall Feedback
                  </p>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {profileData1?.overallFeedback}
                  </p>
                </div>
                <div className={style.other}>
                  <p>
                    <span className={style.mand}>*</span>NS - Candidate doest
                    not have skill
                  </p>
                  <p>
                    <span className={style.mand}>*</span>NE - Skill not
                    evaluated by interviewer
                  </p>
                </div>

                {Number(profileData1?.statusIdRating) === 1 ? (
                  <>
                    <p
                      className={style.rating}
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      Overall Rating :
                      <span
                        style={{
                          // color:
                          //   profileData1?.overallRatingValue === 1
                          //     ? "Poor"
                          //     : profileData1?.overallRatingValue === 2
                          //     ? "Good"
                          //     : profileData1?.overallRatingValue === 3
                          //     ? "Excellent"
                          //     : profileData1?.overallRatingValue == "NE"
                          //     ? "Not Evaluated"
                          //     : profileData1?.overallRatingValue == "NS"
                          //     ? "Not Skill"
                          //     : null,
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {profileData1?.overallRatingValue}{" "}
                        {profileData1?.overallRatingValue === "1" ? (
                          <span style={{ color: "red" }}>Poor</span>
                        ) : profileData1?.overallRatingValue === "2" ? (
                          <span style={{ color: "yello" }}>Good</span>
                        ) : profileData1?.overallRatingValue === "3" ? (
                          <span style={{ color: "green" }}>Excellent</span>
                        ) : profileData1?.overallRatingValue === "NE" ? (
                          <span style={{ color: "red" }}>Not Evaluated</span>
                        ) : profileData1?.overallRatingValue === "NS" ? (
                          <span style={{ color: "red" }}>Not Skill</span>
                        ) : null}
                      </span>
                    </p>
                  </>
                ) : null}
                {Number(profileData1?.statusIdRating) === 2 ? (
                  <>
                    <p
                      className={style.rating}
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      Overall Rating :
                      <span
                        style={{
                          // color:
                          //   profileData1?.overallRatingValue == 1
                          //     ? "Poor"
                          //     : profileData1?.overallRatingValue == 2
                          //     ? "Average"
                          //     : profileData1?.overallRatingValue == 3
                          //     ? "Good"
                          //     : profileData1?.overallRatingValue == 4
                          //     ? "Very Good"
                          //     : profileData1?.overallRatingValue == 5
                          //     ? "Excellent"
                          //     : profileData1?.overallRatingValue == "NE"
                          //     ? "Not Evaluated"
                          //     : profileData1?.overallRatingValue == "NS"
                          //     ? "Not Skill"
                          //     : null,
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {profileData1?.overallRatingValue}{" "}
                        {Number(profileData1?.overallRatingValue) === 1 ? (
                          <span style={{ color: "red" }}>Poor</span>
                        ) : Number(profileData1?.overallRatingValue) === 2 ? (
                          <span style={{ color: "green" }}>Average</span>
                        ) : Number(profileData1?.overallRatingValue) === 3 ? (
                          <span style={{ color: "green" }}>Good</span>
                        ) : Number(profileData1?.overallRatingValue) === 4 ? (
                          <span style={{ color: "green" }}>Very Good</span>
                        ) : Number(profileData1?.overallRatingValue) === 5 ? (
                          <span style={{ color: "green" }}>Excellent</span>
                        ) : profileData1?.overallRatingValue === "NE" ? (
                          <span style={{ color: "red" }}>Not Evaluated</span>
                        ) : profileData1?.overallRatingValue === "NS" ? (
                          <span style={{ color: "red" }}>Not Skill</span>
                        ) : null}
                      </span>
                    </p>
                  </>
                ) : null}
                {Number(profileData1?.statusIdRating) === 3 ? (
                  <>
                    <p
                      className={style.rating}
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      Overall Rating :
                      <span
                        style={{
                          // color:
                          //   profileData1?.overallRatingValue > 7
                          //     ? "Excellent"
                          //     : profileData1?.overallRatingValue < 4
                          //     ? "Poor"
                          //     : profileData1?.overallRatingValue < 6
                          //     ? "Average"
                          //     : profileData1?.overallRatingValue < 8
                          //     ? "Good"
                          //     : profileData1?.overallRatingValue == "NE"
                          //     ? "Not Evaluated"
                          //     : profileData1?.overallRatingValue == "NS"
                          //     ? "Not Skill"
                          //     : null,
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {profileData1?.overallRatingValue}{" "}
                        {profileData1?.overallRatingValue > 7 ? (
                          <span style={{ color: "green" }}>Excellent</span>
                        ) : profileData1?.overallRatingValue === "" ? (
                          <span style={{ color: "red" }}></span>
                        ) : profileData1?.overallRatingValue < 4 ? (
                          <span style={{ color: "red" }}>Poor</span>
                        ) : profileData1?.overallRatingValue < 6 ? (
                          <span style={{ color: "green" }}>Average</span>
                        ) : profileData1?.overallRatingValue < 8 ? (
                          <span style={{ color: "green" }}>Good</span>
                        ) : profileData1?.overallRatingValue === "NE" ? (
                          <span style={{ color: "red" }}>Not Evaluated</span>
                        ) : profileData1?.overallRatingValue === "NS" ? (
                          <span style={{ color: "red" }}>Not Skill</span>
                        ) : null}
                      </span>
                    </p>
                  </>
                ) : null}

                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600" }}
                >
                  Status :
                  <span
                    style={{
                      color:
                        Number(profileData1?.statusId) === 1
                          ? "green"
                          : Number(profileData1?.statusId) === 2
                            ? "red"
                            : Number(profileData1?.statusId) === 3
                              ? "orange"
                              : Number(profileData1?.statusId) === 4
                                ? "brown"
                                : null,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {profileData1?.status}
                  </span>
                </p>
              </div>
            ) : null}
            {/* fileupload */}
            {Number(profileData1?.feedbackTypeId) === 3 ? (
              <div>
                <div>
                  <p
                    className={style.rating}
                    style={{ color: "black", fontWeight: "600" }}
                  >
                    Uploaded Feedback
                  </p>
                  <p className={style.rating}>
                    <a
                      href={profileData1?.filePath}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p
                        style={{
                          color: "#a15f5f",
                          textDecoration: "underline",
                          width: "34rem",
                        }}
                      >
                        {profileData1?.filePath?.split("@")[1]}
                      </p>
                    </a>
                  </p>
                  {Number(profileData1?.statusIdRating) === 1 ? (
                    <>
                      <p
                        className={style.rating}
                        style={{ color: "black", fontWeight: "600" }}
                      >
                        Overall Rating :
                        <span
                          style={{
                            // color:
                            //   profileData1?.overallRatingValue === 1
                            //     ? "Poor"
                            //     : profileData1?.overallRatingValue === 2
                            //     ? "Good"
                            //     : profileData1?.overallRatingValue === 3
                            //     ? "Excellent"
                            //     : profileData1?.overallRatingValue == "NE"
                            //     ? "Not Evaluated"
                            //     : profileData1?.overallRatingValue == "NS"
                            //     ? "Not Skill"
                            //     : null,
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {profileData1?.overallRatingValue}{" "}
                          {profileData1?.overallRatingValue === "1" ? (
                            <span style={{ color: "red" }}>Poor</span>
                          ) : profileData1?.overallRatingValue === "2" ? (
                            <span style={{ color: "yello" }}>Good</span>
                          ) : profileData1?.overallRatingValue === "3" ? (
                            <span style={{ color: "green" }}>Excellent</span>
                          ) : profileData1?.overallRatingValue === "NE" ? (
                            <span style={{ color: "red" }}>Not Evaluated</span>
                          ) : profileData1?.overallRatingValue === "NS" ? (
                            <span style={{ color: "red" }}>Not Skill</span>
                          ) : null}
                        </span>
                      </p>
                    </>
                  ) : null}
                  {Number(profileData1?.statusIdRating) === 2 ? (
                    <>
                      <p
                        className={style.rating}
                        style={{ color: "black", fontWeight: "600" }}
                      >
                        Overall Rating :
                        <span
                          style={{
                            // color:
                            //   profileData1?.overallRatingValue == 1
                            //     ? "Poor"
                            //     : profileData1?.overallRatingValue == 2
                            //     ? "Average"
                            //     : profileData1?.overallRatingValue == 3
                            //     ? "Good"
                            //     : profileData1?.overallRatingValue == 4
                            //     ? "Very Good"
                            //     : profileData1?.overallRatingValue == 5
                            //     ? "Excellent"
                            //     : profileData1?.overallRatingValue == "NE"
                            //     ? "Not Evaluated"
                            //     : profileData1?.overallRatingValue == "NS"
                            //     ? "Not Skill"
                            //     : null,
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {profileData1?.overallRatingValue}{" "}
                          {Number(profileData1?.overallRatingValue) === 1 ? (
                            <span style={{ color: "red" }}>Poor</span>
                          ) : Number(profileData1?.overallRatingValue) === 2 ? (
                            <span style={{ color: "green" }}>Average</span>
                          ) : Number(profileData1?.overallRatingValue) === 3 ? (
                            <span style={{ color: "green" }}>Good</span>
                          ) : Number(profileData1?.overallRatingValue) === 4 ? (
                            <span style={{ color: "green" }}>Very Good</span>
                          ) : Number(profileData1?.overallRatingValue) === 5 ? (
                            <span style={{ color: "green" }}>Excellent</span>
                          ) : profileData1?.overallRatingValue === "NE" ? (
                            <span style={{ color: "red" }}>Not Evaluated</span>
                          ) : profileData1?.overallRatingValue === "NS" ? (
                            <span style={{ color: "red" }}>Not Skill</span>
                          ) : null}
                        </span>
                      </p>
                    </>
                  ) : null}
                  {Number(profileData1?.statusIdRating) === 3 ? (
                    <>
                      <p
                        className={style.rating}
                        style={{ color: "black", fontWeight: "600" }}
                      >
                        Overall Rating :
                        <span
                          style={{
                            // color:
                            //   profileData1?.overallRatingValue > 7
                            //     ? "Excellent"
                            //     : profileData1?.overallRatingValue < 4
                            //     ? "Poor"
                            //     : profileData1?.overallRatingValue < 6
                            //     ? "Average"
                            //     : profileData1?.overallRatingValue < 8
                            //     ? "Good"
                            //     : profileData1?.overallRatingValue == "NE"
                            //     ? "Not Evaluated"
                            //     : profileData1?.overallRatingValue == "NS"
                            //     ? "Not Skill"
                            //     : null,
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {profileData1?.overallRatingValue}{" "}
                          {profileData1?.overallRatingValue > 7 ? (
                            <span style={{ color: "green" }}>Excellent</span>
                          ) : profileData1?.overallRatingValue === "" ? (
                            <span style={{ color: "red" }}></span>
                          ) : profileData1?.overallRatingValue < 4 ? (
                            <span style={{ color: "red" }}>Poor</span>
                          ) : profileData1?.overallRatingValue < 6 ? (
                            <span style={{ color: "green" }}>Average</span>
                          ) : profileData1?.overallRatingValue < 8 ? (
                            <span style={{ color: "green" }}>Good</span>
                          ) : profileData1?.overallRatingValue === "NE" ? (
                            <span style={{ color: "red" }}>Not Evaluated</span>
                          ) : profileData1?.overallRatingValue === "NS" ? (
                            <span style={{ color: "red" }}>Not Skill</span>
                          ) : null}
                        </span>
                      </p>
                    </>
                  ) : null}
                  <p
                    className={style.rating}
                    style={{ color: "black", fontWeight: "600" }}
                  >
                    Status :{" "}
                    <span
                      style={{
                        color:
                          profileData1?.statusId === 1
                            ? "green"
                            : profileData1?.statusId === 2
                              ? "red"
                              : profileData1?.statusId === 3
                                ? "orange"
                                : profileData1?.statusId === 4
                                  ? "brown"
                                  : null,
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      {profileData1?.status}
                    </span>
                  </p>
                </div>
              </div>
            ) : null}
            {/* test */}
            {Number(profileData1?.feedbackTypeId) === 2 ? (
              <div className={style.rating}>
                <div className={style.h}>
                  <label className={style.h_name}>Skills</label>
                  <div className={style.h1}>
                    <label className={style.h_name}>Score Obtained</label>
                    <label className={style.h_name}>Maximum Score</label>
                  </div>
                </div>
                {profileData1?.feedbackSkill?.map((item, index) => {
                  return (
                    <>
                      {/* <div key={index} className={style.h}>
                    <label>{item.label}</label>
                    <div className={style.h2}>
                      <p className={style.mg}>{item.scoreObtained}</p>
                      <p className={style.mg1}>{item.maxScore}</p>
                    </div>
                  </div> */}
                      <div key={index}>
                        <div className={style.table}>
                          <div className={style.th2}>
                            <p>{item.label}</p>
                            <p>{item.scoreObtained}</p>
                            <p>{item.maxScore}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                {Number(profileData1?.statusIdRating) === 1 ? (
                  <>
                    <p
                      className={style.rating}
                      style={{
                        color: "black",
                        fontWeight: "600",
                        padding: "0",
                      }}
                    >
                      Overall Rating :
                      <span
                        style={{
                          // color:
                          //   profileData1?.overallRatingValue === 1
                          //     ? "Poor"
                          //     : profileData1?.overallRatingValue === 2
                          //     ? "Good"
                          //     : profileData1?.overallRatingValue === 3
                          //     ? "Excellent"
                          //     : profileData1?.overallRatingValue == "NE"
                          //     ? "Not Evaluated"
                          //     : profileData1?.overallRatingValue == "NS"
                          //     ? "Not Skill"
                          //     : null,
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {profileData1?.overallRatingValue}{" "}
                        {profileData1?.overallRatingValue === "1" ? (
                          <span style={{ color: "red" }}>Poor</span>
                        ) : profileData1?.overallRatingValue === "2" ? (
                          <span style={{ color: "yello" }}>Good</span>
                        ) : profileData1?.overallRatingValue === "3" ? (
                          <span style={{ color: "green" }}>Excellent</span>
                        ) : profileData1?.overallRatingValue === "NE" ? (
                          <span style={{ color: "red" }}>Not Evaluated</span>
                        ) : profileData1?.overallRatingValue === "NS" ? (
                          <span style={{ color: "red" }}>Not Skill</span>
                        ) : null}
                      </span>
                    </p>
                  </>
                ) : null}
                {Number(profileData1?.statusIdRating) === 2 ? (
                  <>
                    <p
                      className={style.rating}
                      style={{
                        color: "black",
                        fontWeight: "600",
                        padding: "0",
                      }}
                    >
                      Overall Rating :
                      <span
                        style={{
                          // color:
                          //   profileData1?.overallRatingValue == 1
                          //     ? "Poor"
                          //     : profileData1?.overallRatingValue == 2
                          //     ? "Average"
                          //     : profileData1?.overallRatingValue == 3
                          //     ? "Good"
                          //     : profileData1?.overallRatingValue == 4
                          //     ? "Very Good"
                          //     : profileData1?.overallRatingValue == 5
                          //     ? "Excellent"
                          //     : profileData1?.overallRatingValue == "NE"
                          //     ? "Not Evaluated"
                          //     : profileData1?.overallRatingValue == "NS"
                          //     ? "Not Skill"
                          //     : null,
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {profileData1?.overallRatingValue}{" "}
                        {Number(profileData1?.overallRatingValue) === 1 ? (
                          <span style={{ color: "red" }}>Poor</span>
                        ) : Number(profileData1?.overallRatingValue) === 2 ? (
                          <span style={{ color: "green" }}>Average</span>
                        ) : Number(profileData1?.overallRatingValue) === 3 ? (
                          <span style={{ color: "green" }}>Good</span>
                        ) : Number(profileData1?.overallRatingValue) === 4 ? (
                          <span style={{ color: "green" }}>Very Good</span>
                        ) : Number(profileData1?.overallRatingValue) === 5 ? (
                          <span style={{ color: "green" }}>Excellent</span>
                        ) : profileData1?.overallRatingValue === "NE" ? (
                          <span style={{ color: "red" }}>Not Evaluated</span>
                        ) : profileData1?.overallRatingValue === "NS" ? (
                          <span style={{ color: "red" }}>Not Skill</span>
                        ) : null}
                      </span>
                    </p>
                  </>
                ) : null}
                {Number(profileData1?.statusIdRating) === 3 ? (
                  <>
                    <p
                      className={style.rating}
                      style={{
                        color: "black",
                        fontWeight: "600",
                        padding: "0",
                      }}
                    >
                      Overall Rating :
                      <span
                        style={{
                          // color:
                          //   profileData1?.overallRatingValue > 7
                          //     ? "Excellent"
                          //     : profileData1?.overallRatingValue < 4
                          //     ? "Poor"
                          //     : profileData1?.overallRatingValue < 6
                          //     ? "Average"
                          //     : profileData1?.overallRatingValue < 8
                          //     ? "Good"
                          //     : profileData1?.overallRatingValue == "NE"
                          //     ? "Not Evaluated"
                          //     : profileData1?.overallRatingValue == "NS"
                          //     ? "Not Skill"
                          //     : null,
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {profileData1?.overallRatingValue}{" "}
                        {profileData1?.overallRatingValue > 7 ? (
                          <span style={{ color: "green" }}>Excellent</span>
                        ) : profileData1?.overallRatingValue === "" ? (
                          <span style={{ color: "red" }}></span>
                        ) : profileData1?.overallRatingValue < 4 ? (
                          <span style={{ color: "red" }}>Poor</span>
                        ) : profileData1?.overallRatingValue < 6 ? (
                          <span style={{ color: "green" }}>Average</span>
                        ) : profileData1?.overallRatingValue < 8 ? (
                          <span style={{ color: "green" }}>Good</span>
                        ) : profileData1?.overallRatingValue === "NE" ? (
                          <span style={{ color: "red" }}>Not Evaluated</span>
                        ) : profileData1?.overallRatingValue === "NS" ? (
                          <span style={{ color: "red" }}>Not Skill</span>
                        ) : null}
                      </span>
                    </p>
                  </>
                ) : null}
              </div>
            ) : null}
            <div></div>
            {/* subjective */}
            {Number(profileData1?.feedbackTypeId) === 4 ? (
              <div>
                <div>
                  <p
                    className={style.rating}
                    style={{
                      color: "black",
                      fontWeight: "600",
                      paddingBottom: "0px",
                    }}
                  >
                    Subjective Feedback
                  </p>
                  <p
                    className={style.rating}
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {profileData1?.feedbackDescription}
                  </p>
                </div>
                {Number(profileData1?.statusIdRating) === 1 ? (
                  <>
                    <p
                      className={style.rating}
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      Overall Rating :
                      <span
                        style={{
                          // color:
                          //   profileData1?.overallRatingValue === 1
                          //     ? "Poor"
                          //     : profileData1?.overallRatingValue === 2
                          //     ? "Good"
                          //     : profileData1?.overallRatingValue === 3
                          //     ? "Excellent"
                          //     : profileData1?.overallRatingValue == "NE"
                          //     ? "Not Evaluated"
                          //     : profileData1?.overallRatingValue == "NS"
                          //     ? "Not Skill"
                          //     : null,
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {profileData1?.overallRatingValue}{" "}
                        {profileData1?.overallRatingValue === "1" ? (
                          <span style={{ color: "red" }}>Poor</span>
                        ) : profileData1?.overallRatingValue === "2" ? (
                          <span style={{ color: "yello" }}>Good</span>
                        ) : profileData1?.overallRatingValue === "3" ? (
                          <span style={{ color: "green" }}>Excellent</span>
                        ) : profileData1?.overallRatingValue === "NE" ? (
                          <span style={{ color: "red" }}>Not Evaluated</span>
                        ) : profileData1?.overallRatingValue === "NS" ? (
                          <span style={{ color: "red" }}>Not Skill</span>
                        ) : null}
                      </span>
                    </p>
                  </>
                ) : null}
                {Number(profileData1?.statusIdRating) === 2 ? (
                  <>
                    <p
                      className={style.rating}
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      Overall Rating :
                      <span
                        style={{
                          // color:
                          //   profileData1?.overallRatingValue == 1 ? (
                          //     <span style={{ color: "red" }}>Poor</span>
                          //   ) : profileData1?.overallRatingValue == 2 ? (
                          //     <span style={{ color: "green" }}>Average</span>
                          //   ) : profileData1?.overallRatingValue == 3 ? (
                          //     <span style={{ color: "green" }}>Good</span>
                          //   ) : profileData1?.overallRatingValue == 4 ? (
                          //     <span style={{ color: "green" }}>Very Good</span>
                          //   ) : profileData1?.overallRatingValue == 5 ? (
                          //     <span style={{ color: "green" }}>Excellent</span>
                          //   ) : profileData1?.overallRatingValue == "NE" ? (
                          //     <span style={{ color: "red" }}>Not Evaluated</span>
                          //   ) : profileData1?.overallRatingValue == "NS" ? (
                          //     <span style={{ color: "red" }}>Not Skill</span>
                          //   ) : null,
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {profileData1?.overallRatingValue}{" "}
                        {Number(profileData1?.overallRatingValue) === 1 ? (
                          <span style={{ color: "red" }}>Poor</span>
                        ) : Number(profileData1?.overallRatingValue) === 2 ? (
                          <span style={{ color: "green" }}>Average</span>
                        ) : Number(profileData1?.overallRatingValue) === 3 ? (
                          <span style={{ color: "green" }}>Good</span>
                        ) : Number(profileData1?.overallRatingValue) === 4 ? (
                          <span style={{ color: "green" }}>Very Good</span>
                        ) : Number(profileData1?.overallRatingValue) === 5 ? (
                          <span style={{ color: "green" }}>Excellent</span>
                        ) : profileData1?.overallRatingValue === "NE" ? (
                          <span style={{ color: "red" }}>Not Evaluated</span>
                        ) : profileData1?.overallRatingValue === "NS" ? (
                          <span style={{ color: "red" }}>Not Skill</span>
                        ) : null}
                      </span>
                    </p>
                  </>
                ) : null}
                {profileData1?.statusIdRating == 3 ? (
                  <>
                    <p
                      className={style.rating}
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      Overall Rating :
                      <span
                        style={{
                          // color:
                          //   profileData1?.overallRatingValue > 7
                          //     ? "Excellent"
                          //     : profileData1?.overallRatingValue < 4
                          //     ? "Poor"
                          //     : profileData1?.overallRatingValue < 6
                          //     ? "Average"
                          //     : profileData1?.overallRatingValue < 8
                          //     ? "Good"
                          //     : profileData1?.overallRatingValue == "NE"
                          //     ? "Not Evaluated"
                          //     : profileData1?.overallRatingValue == "NS"
                          //     ? "Not Skill"
                          //     : null,
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {profileData1?.overallRatingValue}{" "}
                        {profileData1?.overallRatingValue > 7 ? (
                          <span style={{ color: "green" }}>Excellent</span>
                        ) : profileData1?.overallRatingValue === "" ? (
                          <span style={{ color: "red" }}></span>
                        ) : profileData1?.overallRatingValue < 4 ? (
                          <span style={{ color: "red" }}>Poor</span>
                        ) : profileData1?.overallRatingValue < 6 ? (
                          <span style={{ color: "green" }}>Average</span>
                        ) : profileData1?.overallRatingValue < 8 ? (
                          <span style={{ color: "green" }}>Good</span>
                        ) : profileData1?.overallRatingValue === "NE" ? (
                          <span style={{ color: "red" }}>Not Evaluated</span>
                        ) : profileData1?.overallRatingValue === "NS" ? (
                          <span style={{ color: "red" }}>Not Skill</span>
                        ) : null}
                      </span>
                    </p>
                  </>
                ) : null}
                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600" }}
                >
                  Status :
                  <span
                    style={{
                      color:
                        profileData1?.statusId === 1
                          ? "green"
                          : profileData1?.statusId === 2
                            ? "red"
                            : profileData1?.statusId === 3
                              ? "orange"
                              : profileData1?.statusId === 4
                                ? "brown"
                                : null,
                      fontWeight: "600",
                    }}
                  >
                    {profileData1?.status}
                  </span>
                </p>
              </div>
            ) : null}
          </div>{" "}
          {/* btn */}
          <div className={style.button}>
            {checkValidateAccess(EprAccessCode.delete) ? (
              <button
                className={style.btn3}
                onClick={() => {
                  setShow(true);
                }}
              >
                Delete
              </button>
            ) : null}
            {checkValidateAccess(EprAccessCode.edit_feedback) ? (
              <button
                className={style.btn1}
                onClick={() => {
                  setNextScreen("editEmployeeFeedback");
                  setPrevData((prevData) => ({
                    ...prevData,
                    empId: prevData?.empId,
                    sidebar: "EmployeePerformanceReview",
                    page: "editEmployeeFeedback",
                    page1: "EmployeePerformanceReview",
                    page2: "EmployeeFeedbackList",
                    page3: "viewFeedback",
                    page4: "editEmployeeFeedback",
                    page5: null,
                    active: "editEmployeeFeedback",
                  }));
                }}
              >
                Edit Feedback
              </button>
            ) : null}

            {checkValidateAccess(EprAccessCode.done) ? (
              <button
                className={style.btn2}
                onClick={() => {
                  setNextScreen("EmployeeFeedbackList");
                  setPrevData((prevData) => ({
                    ...prevData,
                    // empId: item?.employeeId,
                    sidebar: "EmployeePerformanceReview",
                    page: "EmployeeFeedbackList",
                    page1: "EmployeePerformanceReview",
                    page2: "EmployeeFeedbackList",
                    page3: null,
                    active: "EmployeeFeedbackList",
                  }));
                }}
              >
                Done
              </button>
            ) : null}
          </div>
        </>
      )}
      {Array.isArray(allFeedbackOfEmp) &&
        prevData?.showAllFeedback &&
        allFeedbackOfEmp?.map((i) => {
          return (
            <>
              {" "}
              {/* second */}
              <div className={style.card}>
                <p className={style.title}>Feedback</p>
                <div className={style.Cu_row1}>
                  <div className={style.cu_col11}>
                    <p className={style.cu_label}>Review Date</p>
                    <p>{moment(i?.reviewDateView).format("DD MMM YY")}</p>
                  </div>
                  <div className={style.cu_col11}>
                    <p className={style.cu_label}>Review Source</p>
                    <p>{i?.reviewSourceType}</p>
                  </div>
                  <div className={style.cu_col11}>
                    <p className={style.cu_label}>Review Type</p>
                    <p>{i?.reviewType}</p>
                  </div>
                  {i?.reviewSourceType !== "Client" &&
                    i?.reviewSourceType !== "Online" ? (
                    <div className={style.cu_col11}>
                      <p className={style.cu_label}>Reviewed By</p>
                      <p>{i?.reviewedBy}</p>
                    </div>
                  ) : null}
                  {i?.reviewSourceType === "Client" ? (
                    <div className={style.cu_col11}>
                      <p className={style.cu_label}>Client Name</p>
                      <p>{i?.clientName}</p>
                    </div>
                  ) : null}
                  {i?.reviewSourceType === "Online" ? (
                    <div className={style.cu_col11}>
                      <p className={style.cu_label}>Online Platform</p>
                      <p>{i?.onlinePlatform}</p>
                    </div>
                  ) : null}
                </div>
                <div className={style.Cu_row1}>
                  {i?.projectName != null ? (
                    <>
                      <div className={style.cu_col11}>
                        <p className={style.cu_label}>Project Name</p>
                        <p>{i?.projectName}</p>
                      </div>
                    </>
                  ) : null}
                  {i?.reviewSourceType === "Client" ? (
                    i?.reviewedByName !== "" ? (
                      <div className={style.cu_col11}>
                        <p className={style.cu_label}>Reviewed By</p>
                        <p>{i?.reviewedBy}</p>
                      </div>
                    ) : null
                  ) : null}

                  <div className={style.cu_col11}></div>
                  <div className={style.cu_col11}></div>
                </div>
                <div style={{ marginLeft: "-10px" }}>
                  {/* Rating */}
                  {Number(i?.feedbackTypeId) === 1 ? (
                    <div>
                      <div className={style.rating}>
                        <p>
                          <span className={style.cu_label}>
                            Skills Rating(1-10):
                          </span>{" "}
                          1-3 Poor, 4-5 Average, 6-7 Good, 8-10 Excellent
                        </p>
                        <p></p>
                        <div className={style.box}>
                          {i?.feedbackSkill?.map((i) => {
                            return (
                              <div className={style.boxitem}>
                                <p style={{ width: "25%" }}>{i?.label}</p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gridColumnGap: "50%",
                                  }}
                                >
                                  <div className={style.ratingAlign}>
                                    <p style={{ width: "11vw" }}>
                                      {i?.rating >= 1 && i?.rating <= 3
                                        ? "Poor"
                                        : i?.rating >= 4 && i?.rating <= 5
                                          ? "Average"
                                          : i?.rating >= 6 && i?.rating <= 7
                                            ? "Good"
                                            : i?.rating >= 8 && i?.rating <= 10
                                              ? "Excellent"
                                              : null}
                                    </p>
                                    <p style={{ width: "1vw" }}>{i?.rating}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className={style.feedback}>
                        <p className={style.cu_label}>
                          Overall Feedback <span className={style.mand}>*</span>
                        </p>
                        <p style={{ whiteSpace: "pre-line" }}>
                          {i?.overallFeedback}
                        </p>
                      </div>
                      <div className={style.other}>
                        <p>
                          <span className={style.mand}>*</span>NS - Candidate
                          doest not have skill
                        </p>
                        <p>
                          <span className={style.mand}>*</span>NE - Skill not
                          evaluated by interviewer
                        </p>
                      </div>

                      {Number(i?.statusIdRating) === 1 ? (
                        <>
                          <p
                            className={style.rating}
                            style={{ color: "black", fontWeight: "600" }}
                          >
                            Overall Rating :
                            <span
                              style={{
                                // color:
                                //   i?.overallRatingValue === 1
                                //     ? "Poor"
                                //     : i?.overallRatingValue === 2
                                //     ? "Good"
                                //     : i?.overallRatingValue === 3
                                //     ? "Excellent"
                                //     : i?.overallRatingValue == "NE"
                                //     ? "Not Evaluated"
                                //     : i?.overallRatingValue == "NS"
                                //     ? "Not Skill"
                                //     : null,
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              {i?.overallRatingValue}{" "}
                              {i?.overallRatingValue === "1" ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : i?.overallRatingValue === "2" ? (
                                <span style={{ color: "yello" }}>Good</span>
                              ) : i?.overallRatingValue === "3" ? (
                                <span style={{ color: "green" }}>
                                  Excellent
                                </span>
                              ) : i?.overallRatingValue === "NE" ? (
                                <span style={{ color: "red" }}>
                                  Not Evaluated
                                </span>
                              ) : i?.overallRatingValue === "NS" ? (
                                <span style={{ color: "red" }}>Not Skill</span>
                              ) : null}
                            </span>
                          </p>
                        </>
                      ) : null}
                      {Number(i?.statusIdRating) === 2 ? (
                        <>
                          <p
                            className={style.rating}
                            style={{ color: "black", fontWeight: "600" }}
                          >
                            Overall Rating :
                            <span
                              style={{
                                // color:
                                //   i?.overallRatingValue == 1
                                //     ? "Poor"
                                //     : i?.overallRatingValue == 2
                                //     ? "Average"
                                //     : i?.overallRatingValue == 3
                                //     ? "Good"
                                //     : i?.overallRatingValue == 4
                                //     ? "Very Good"
                                //     : i?.overallRatingValue == 5
                                //     ? "Excellent"
                                //     : i?.overallRatingValue == "NE"
                                //     ? "Not Evaluated"
                                //     : i?.overallRatingValue == "NS"
                                //     ? "Not Skill"
                                //     : null,
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              {i?.overallRatingValue}{" "}
                              {Number(i?.overallRatingValue) === 1 ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : Number(i?.overallRatingValue) === 2 ? (
                                <span style={{ color: "green" }}>Average</span>
                              ) : Number(i?.overallRatingValue) === 3 ? (
                                <span style={{ color: "green" }}>Good</span>
                              ) : Number(i?.overallRatingValue) === 4 ? (
                                <span style={{ color: "green" }}>
                                  Very Good
                                </span>
                              ) : Number(i?.overallRatingValue) === 5 ? (
                                <span style={{ color: "green" }}>
                                  Excellent
                                </span>
                              ) : i?.overallRatingValue === "NE" ? (
                                <span style={{ color: "red" }}>
                                  Not Evaluated
                                </span>
                              ) : i?.overallRatingValue === "NS" ? (
                                <span style={{ color: "red" }}>Not Skill</span>
                              ) : null}
                            </span>
                          </p>
                        </>
                      ) : null}
                      {Number(i?.statusIdRating) === 3 ? (
                        <>
                          <p
                            className={style.rating}
                            style={{ color: "black", fontWeight: "600" }}
                          >
                            Overall Rating :
                            <span
                              style={{
                                // color:
                                //   i?.overallRatingValue > 7
                                //     ? "Excellent"
                                //     : i?.overallRatingValue < 4
                                //     ? "Poor"
                                //     : i?.overallRatingValue < 6
                                //     ? "Average"
                                //     : i?.overallRatingValue < 8
                                //     ? "Good"
                                //     : i?.overallRatingValue == "NE"
                                //     ? "Not Evaluated"
                                //     : i?.overallRatingValue == "NS"
                                //     ? "Not Skill"
                                //     : null,
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              {i?.overallRatingValue}{" "}
                              {i?.overallRatingValue > 7 ? (
                                <span style={{ color: "green" }}>
                                  Excellent
                                </span>
                              ) : i?.overallRatingValue === "" ? (
                                <span style={{ color: "red" }}></span>
                              ) : i?.overallRatingValue < 4 ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : i?.overallRatingValue < 6 ? (
                                <span style={{ color: "green" }}>Average</span>
                              ) : i?.overallRatingValue < 8 ? (
                                <span style={{ color: "green" }}>Good</span>
                              ) : i?.overallRatingValue === "NE" ? (
                                <span style={{ color: "red" }}>
                                  Not Evaluated
                                </span>
                              ) : i?.overallRatingValue === "NS" ? (
                                <span style={{ color: "red" }}>Not Skill</span>
                              ) : null}
                            </span>
                          </p>
                        </>
                      ) : null}

                      <p
                        className={style.rating}
                        style={{ color: "black", fontWeight: "600" }}
                      >
                        Status :
                        <span
                          style={{
                            color:
                              Number(i?.statusId) === 1
                                ? "green"
                                : Number(i?.statusId) === 2
                                  ? "red"
                                  : Number(i?.statusId) === 3
                                    ? "orange"
                                    : Number(i?.statusId) === 4
                                      ? "brown"
                                      : null,
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {i?.status}
                        </span>
                      </p>
                    </div>
                  ) : null}
                  {/* fileupload */}
                  {Number(i?.feedbackTypeId) === 3 ? (
                    <div>
                      <div>
                        <p
                          className={style.rating}
                          style={{ color: "black", fontWeight: "600" }}
                        >
                          Uploaded Feedback
                        </p>
                        <p className={style.rating}>
                          <a
                            href={i?.filePath}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p
                              style={{
                                color: "#a15f5f",
                                textDecoration: "underline",
                                width: "34rem",
                              }}
                            >
                              {i?.filePath?.split("@")[1]}
                            </p>
                          </a>
                        </p>
                        {Number(i?.statusIdRating) === 1 ? (
                          <>
                            <p
                              className={style.rating}
                              style={{ color: "black", fontWeight: "600" }}
                            >
                              Overall Rating :
                              <span
                                style={{
                                  // color:
                                  //   i?.overallRatingValue === 1
                                  //     ? "Poor"
                                  //     : i?.overallRatingValue === 2
                                  //     ? "Good"
                                  //     : i?.overallRatingValue === 3
                                  //     ? "Excellent"
                                  //     : i?.overallRatingValue == "NE"
                                  //     ? "Not Evaluated"
                                  //     : i?.overallRatingValue == "NS"
                                  //     ? "Not Skill"
                                  //     : null,
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                {i?.overallRatingValue}{" "}
                                {i?.overallRatingValue === "1" ? (
                                  <span style={{ color: "red" }}>Poor</span>
                                ) : i?.overallRatingValue === "2" ? (
                                  <span style={{ color: "yello" }}>Good</span>
                                ) : i?.overallRatingValue === "3" ? (
                                  <span style={{ color: "green" }}>
                                    Excellent
                                  </span>
                                ) : i?.overallRatingValue === "NE" ? (
                                  <span style={{ color: "red" }}>
                                    Not Evaluated
                                  </span>
                                ) : i?.overallRatingValue === "NS" ? (
                                  <span style={{ color: "red" }}>
                                    Not Skill
                                  </span>
                                ) : null}
                              </span>
                            </p>
                          </>
                        ) : null}
                        {Number(i?.statusIdRating) === 2 ? (
                          <>
                            <p
                              className={style.rating}
                              style={{ color: "black", fontWeight: "600" }}
                            >
                              Overall Rating :
                              <span
                                style={{
                                  // color:
                                  //   i?.overallRatingValue == 1
                                  //     ? "Poor"
                                  //     : i?.overallRatingValue == 2
                                  //     ? "Average"
                                  //     : i?.overallRatingValue == 3
                                  //     ? "Good"
                                  //     : i?.overallRatingValue == 4
                                  //     ? "Very Good"
                                  //     : i?.overallRatingValue == 5
                                  //     ? "Excellent"
                                  //     : i?.overallRatingValue == "NE"
                                  //     ? "Not Evaluated"
                                  //     : i?.overallRatingValue == "NS"
                                  //     ? "Not Skill"
                                  //     : null,
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                {i?.overallRatingValue}{" "}
                                {Number(i?.overallRatingValue) === 1 ? (
                                  <span style={{ color: "red" }}>Poor</span>
                                ) : Number(i?.overallRatingValue) === 2 ? (
                                  <span style={{ color: "green" }}>
                                    Average
                                  </span>
                                ) : Number(i?.overallRatingValue) === 3 ? (
                                  <span style={{ color: "green" }}>Good</span>
                                ) : Number(i?.overallRatingValue) === 4 ? (
                                  <span style={{ color: "green" }}>
                                    Very Good
                                  </span>
                                ) : Number(i?.overallRatingValue) === 5 ? (
                                  <span style={{ color: "green" }}>
                                    Excellent
                                  </span>
                                ) : i?.overallRatingValue === "NE" ? (
                                  <span style={{ color: "red" }}>
                                    Not Evaluated
                                  </span>
                                ) : i?.overallRatingValue === "NS" ? (
                                  <span style={{ color: "red" }}>
                                    Not Skill
                                  </span>
                                ) : null}
                              </span>
                            </p>
                          </>
                        ) : null}
                        {Number(i?.statusIdRating) === 3 ? (
                          <>
                            <p
                              className={style.rating}
                              style={{ color: "black", fontWeight: "600" }}
                            >
                              Overall Rating :
                              <span
                                style={{
                                  // color:
                                  //   i?.overallRatingValue > 7
                                  //     ? "Excellent"
                                  //     : i?.overallRatingValue < 4
                                  //     ? "Poor"
                                  //     : i?.overallRatingValue < 6
                                  //     ? "Average"
                                  //     : i?.overallRatingValue < 8
                                  //     ? "Good"
                                  //     : i?.overallRatingValue == "NE"
                                  //     ? "Not Evaluated"
                                  //     : i?.overallRatingValue == "NS"
                                  //     ? "Not Skill"
                                  //     : null,
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                {i?.overallRatingValue}{" "}
                                {i?.overallRatingValue > 7 ? (
                                  <span style={{ color: "green" }}>
                                    Excellent
                                  </span>
                                ) : i?.overallRatingValue === "" ? (
                                  <span style={{ color: "red" }}>Poor</span>
                                ) : i?.overallRatingValue < 4 ? (
                                  <span style={{ color: "red" }}>Poor</span>
                                ) : i?.overallRatingValue < 6 ? (
                                  <span style={{ color: "green" }}>
                                    Average
                                  </span>
                                ) : i?.overallRatingValue < 8 ? (
                                  <span style={{ color: "green" }}>Good</span>
                                ) : i?.overallRatingValue === "NE" ? (
                                  <span style={{ color: "red" }}>
                                    Not Evaluated
                                  </span>
                                ) : i?.overallRatingValue === "NS" ? (
                                  <span style={{ color: "red" }}>
                                    Not Skill
                                  </span>
                                ) : null}
                              </span>
                            </p>
                          </>
                        ) : null}
                        <p
                          className={style.rating}
                          style={{ color: "black", fontWeight: "600" }}
                        >
                          Status :{" "}
                          <span
                            style={{
                              color:
                                i?.statusId === 1
                                  ? "green"
                                  : i?.statusId === 2
                                    ? "red"
                                    : i?.statusId === 3
                                      ? "orange"
                                      : i?.statusId === 4
                                        ? "brown"
                                        : null,
                              fontWeight: "600",
                            }}
                          >
                            {" "}
                            {i?.status}
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {/* test */}
                  {Number(i?.feedbackTypeId) === 2 ? (
                    <div className={style.rating}>
                      <div className={style.h}>
                        <label className={style.h_name}>Skills</label>
                        <div className={style.h1}>
                          <label className={style.h_name}>Score Obtained</label>
                          <label className={style.h_name}>Maximum Score</label>
                        </div>
                      </div>
                      {i?.feedbackSkill?.map((item, index) => {
                        return (
                          <>
                            {/* <div key={index} className={style.h}>
                    <label>{item.label}</label>
                    <div className={style.h2}>
                      <p className={style.mg}>{item.scoreObtained}</p>
                      <p className={style.mg1}>{item.maxScore}</p>
                    </div>
                  </div> */}
                            <div key={index}>
                              <div className={style.table}>
                                <div className={style.th2}>
                                  <p>{item.label}</p>
                                  <p>{item.scoreObtained}</p>
                                  <p>{item.maxScore}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                      {Number(i?.statusIdRating) === 1 ? (
                        <>
                          <p
                            className={style.rating}
                            style={{
                              color: "black",
                              fontWeight: "600",
                              padding: "0",
                            }}
                          >
                            Overall Rating :
                            <span
                              style={{
                                // color:
                                //   i?.overallRatingValue === 1
                                //     ? "Poor"
                                //     : i?.overallRatingValue === 2
                                //     ? "Good"
                                //     : i?.overallRatingValue === 3
                                //     ? "Excellent"
                                //     : i?.overallRatingValue == "NE"
                                //     ? "Not Evaluated"
                                //     : i?.overallRatingValue == "NS"
                                //     ? "Not Skill"
                                //     : null,
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              {i?.overallRatingValue}{" "}
                              {i?.overallRatingValue === "1" ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : i?.overallRatingValue === "2" ? (
                                <span style={{ color: "yello" }}>Good</span>
                              ) : i?.overallRatingValue === "3" ? (
                                <span style={{ color: "green" }}>
                                  Excellent
                                </span>
                              ) : i?.overallRatingValue === "NE" ? (
                                <span style={{ color: "red" }}>
                                  Not Evaluated
                                </span>
                              ) : i?.overallRatingValue === "NS" ? (
                                <span style={{ color: "red" }}>Not Skill</span>
                              ) : null}
                            </span>
                          </p>
                        </>
                      ) : null}
                      {Number(i?.statusIdRating) === 2 ? (
                        <>
                          <p
                            className={style.rating}
                            style={{
                              color: "black",
                              fontWeight: "600",
                              padding: "0",
                            }}
                          >
                            Overall Rating :
                            <span
                              style={{
                                // color:
                                //   i?.overallRatingValue == 1
                                //     ? "Poor"
                                //     : i?.overallRatingValue == 2
                                //     ? "Average"
                                //     : i?.overallRatingValue == 3
                                //     ? "Good"
                                //     : i?.overallRatingValue == 4
                                //     ? "Very Good"
                                //     : i?.overallRatingValue == 5
                                //     ? "Excellent"
                                //     : i?.overallRatingValue == "NE"
                                //     ? "Not Evaluated"
                                //     : i?.overallRatingValue == "NS"
                                //     ? "Not Skill"
                                //     : null,
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              {i?.overallRatingValue}{" "}
                              {Number(i?.overallRatingValue) === 1 ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : Number(i?.overallRatingValue) === 2 ? (
                                <span style={{ color: "green" }}>Average</span>
                              ) : Number(i?.overallRatingValue) === 3 ? (
                                <span style={{ color: "green" }}>Good</span>
                              ) : Number(i?.overallRatingValue) === 4 ? (
                                <span style={{ color: "green" }}>
                                  Very Good
                                </span>
                              ) : Number(i?.overallRatingValue) === 5 ? (
                                <span style={{ color: "green" }}>
                                  Excellent
                                </span>
                              ) : i?.overallRatingValue === "NE" ? (
                                <span style={{ color: "red" }}>
                                  Not Evaluated
                                </span>
                              ) : i?.overallRatingValue === "NS" ? (
                                <span style={{ color: "red" }}>Not Skill</span>
                              ) : null}
                            </span>
                          </p>
                        </>
                      ) : null}
                      {Number(i?.statusIdRating) === 3 ? (
                        <>
                          <p
                            className={style.rating}
                            style={{
                              color: "black",
                              fontWeight: "600",
                              padding: "0",
                            }}
                          >
                            Overall Rating :
                            <span
                              style={{
                                // color:
                                //   i?.overallRatingValue > 7
                                //     ? "Excellent"
                                //     : i?.overallRatingValue < 4
                                //     ? "Poor"
                                //     : i?.overallRatingValue < 6
                                //     ? "Average"
                                //     : i?.overallRatingValue < 8
                                //     ? "Good"
                                //     : i?.overallRatingValue == "NE"
                                //     ? "Not Evaluated"
                                //     : i?.overallRatingValue == "NS"
                                //     ? "Not Skill"
                                //     : null,
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              {i?.overallRatingValue}{" "}
                              {i?.overallRatingValue > 7 ? (
                                <span style={{ color: "green" }}>
                                  Excellent
                                </span>
                              ) : i?.overallRatingValue === "" ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : i?.overallRatingValue < 4 ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : i?.overallRatingValue < 6 ? (
                                <span style={{ color: "green" }}>Average</span>
                              ) : i?.overallRatingValue < 8 ? (
                                <span style={{ color: "green" }}>Good</span>
                              ) : i?.overallRatingValue === "NE" ? (
                                <span style={{ color: "red" }}>
                                  Not Evaluated
                                </span>
                              ) : i?.overallRatingValue === "NS" ? (
                                <span style={{ color: "red" }}>Not Skill</span>
                              ) : null}
                            </span>
                          </p>
                        </>
                      ) : null}
                    </div>
                  ) : null}
                  <div></div>
                  {/* subjective */}
                  {Number(i?.feedbackTypeId) === 4 ? (
                    <div>
                      <div>
                        <p
                          className={style.rating}
                          style={{
                            color: "black",
                            fontWeight: "600",
                            paddingBottom: "0px",
                          }}
                        >
                          Subjective Feedback
                        </p>
                        <p
                          className={style.rating}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {i?.feedbackDescription}
                        </p>
                      </div>
                      {Number(i?.statusIdRating) === 1 ? (
                        <>
                          <p
                            className={style.rating}
                            style={{ color: "black", fontWeight: "600" }}
                          >
                            Overall Rating :
                            <span
                              style={{
                                // color:
                                //   i?.overallRatingValue === 1
                                //     ? "Poor"
                                //     : i?.overallRatingValue === 2
                                //     ? "Good"
                                //     : i?.overallRatingValue === 3
                                //     ? "Excellent"
                                //     : i?.overallRatingValue == "NE"
                                //     ? "Not Evaluated"
                                //     : i?.overallRatingValue == "NS"
                                //     ? "Not Skill"
                                //     : null,
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              {i?.overallRatingValue}{" "}
                              {i?.overallRatingValue === "1" ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : i?.overallRatingValue === "2" ? (
                                <span style={{ color: "yello" }}>Good</span>
                              ) : i?.overallRatingValue === "3" ? (
                                <span style={{ color: "green" }}>
                                  Excellent
                                </span>
                              ) : i?.overallRatingValue === "NE" ? (
                                <span style={{ color: "red" }}>
                                  Not Evaluated
                                </span>
                              ) : i?.overallRatingValue === "NS" ? (
                                <span style={{ color: "red" }}>Not Skill</span>
                              ) : null}
                            </span>
                          </p>
                        </>
                      ) : null}
                      {Number(i?.statusIdRating) === 2 ? (
                        <>
                          <p
                            className={style.rating}
                            style={{ color: "black", fontWeight: "600" }}
                          >
                            Overall Rating :
                            <span
                              style={{
                                // color:
                                //   i?.overallRatingValue == 1 ? (
                                //     <span style={{ color: "red" }}>Poor</span>
                                //   ) : i?.overallRatingValue == 2 ? (
                                //     <span style={{ color: "green" }}>Average</span>
                                //   ) : i?.overallRatingValue == 3 ? (
                                //     <span style={{ color: "green" }}>Good</span>
                                //   ) : i?.overallRatingValue == 4 ? (
                                //     <span style={{ color: "green" }}>Very Good</span>
                                //   ) : i?.overallRatingValue == 5 ? (
                                //     <span style={{ color: "green" }}>Excellent</span>
                                //   ) : i?.overallRatingValue == "NE" ? (
                                //     <span style={{ color: "red" }}>Not Evaluated</span>
                                //   ) : i?.overallRatingValue == "NS" ? (
                                //     <span style={{ color: "red" }}>Not Skill</span>
                                //   ) : null,
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              {i?.overallRatingValue}{" "}
                              {Number(i?.overallRatingValue) === 1 ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : Number(i?.overallRatingValue) === 2 ? (
                                <span style={{ color: "green" }}>Average</span>
                              ) : Number(i?.overallRatingValue) === 3 ? (
                                <span style={{ color: "green" }}>Good</span>
                              ) : Number(i?.overallRatingValue) === 4 ? (
                                <span style={{ color: "green" }}>
                                  Very Good
                                </span>
                              ) : Number(i?.overallRatingValue) === 5 ? (
                                <span style={{ color: "green" }}>
                                  Excellent
                                </span>
                              ) : i?.overallRatingValue === "NE" ? (
                                <span style={{ color: "red" }}>
                                  Not Evaluated
                                </span>
                              ) : i?.overallRatingValue === "NS" ? (
                                <span style={{ color: "red" }}>Not Skill</span>
                              ) : null}
                            </span>
                          </p>
                        </>
                      ) : null}
                      {i?.statusIdRating == 3 ? (
                        <>
                          <p
                            className={style.rating}
                            style={{ color: "black", fontWeight: "600" }}
                          >
                            Overall Rating :
                            <span
                              style={{
                                // color:
                                //   i?.overallRatingValue > 7
                                //     ? "Excellent"
                                //     : i?.overallRatingValue < 4
                                //     ? "Poor"
                                //     : i?.overallRatingValue < 6
                                //     ? "Average"
                                //     : i?.overallRatingValue < 8
                                //     ? "Good"
                                //     : i?.overallRatingValue == "NE"
                                //     ? "Not Evaluated"
                                //     : i?.overallRatingValue == "NS"
                                //     ? "Not Skill"
                                //     : null,
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              {i?.overallRatingValue}{" "}
                              {i?.overallRatingValue > 7 ? (
                                <span style={{ color: "green" }}>
                                  Excellent
                                </span>
                              ) : i?.overallRatingValue === "" ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : i?.overallRatingValue < 4 ? (
                                <span style={{ color: "red" }}>Poor</span>
                              ) : i?.overallRatingValue < 6 ? (
                                <span style={{ color: "green" }}>Average</span>
                              ) : i?.overallRatingValue < 8 ? (
                                <span style={{ color: "green" }}>Good</span>
                              ) : i?.overallRatingValue === "NE" ? (
                                <span style={{ color: "red" }}>
                                  Not Evaluated
                                </span>
                              ) : i?.overallRatingValue === "NS" ? (
                                <span style={{ color: "red" }}>Not Skill</span>
                              ) : null}
                            </span>
                          </p>
                        </>
                      ) : null}
                      <p
                        className={style.rating}
                        style={{ color: "black", fontWeight: "600" }}
                      >
                        Status :
                        <span
                          style={{
                            color:
                              i?.statusId === 1
                                ? "green"
                                : i?.statusId === 2
                                  ? "red"
                                  : i?.statusId === 3
                                    ? "orange"
                                    : i?.statusId === 4
                                      ? "brown"
                                      : null,
                            fontWeight: "600",
                          }}
                        >
                          {i?.status}
                        </span>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          );
        })}

      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
          <div>
            <p className={style.textM}>Do you want to delete this Feedback?</p>
          </div>
          <div className={style.M_button}>
            <div className={style.button1}>
              <button
                className={style.w_inactive}
                onClick={() => setShow(false)}
              >
                No
              </button>
              <button
                className={style.b_inactive}
                // onClick={() => setShow(false)}
                onClick={() => {
                  mutation.mutate({
                    // recruiterId: employeeId,
                    performanceReviewId: prevData?.pReId,
                  });
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ViewFeedback;
