export const path = {
  // ums
  ums_dashboard: "/ums-dashboard",
  ums_userList: "/UsersList",
  ums_createUser: "/CreateUser",
  ums_createconfirm: "/CreateCanfirmation",
  ums_userDescription: "/UserDescription",
  ums_userDescription2: "/UserDescription/:id",
  ums_editUser: "/EditUser",
  ums_newEmployee: "/new-employee",
  ums_exitEmployee: "/exit-employee",
  ums_exitedEmployee: "/exited-employees",
  ums_allExitedEmployee: "/all-exited-employee",
  ums_confirmationEmployee: "/confirmation-employee",
  ums_report: "/report",
  ums_incentive_report: "/incentive-report",
  ums_newJoinee: "/new-joinees",
  ums_bgvReport: "/bgv-report",
  ums_salaryMaster: "/salary-master",
  ums_companyHierarchy : "/hierarchy",

  // client contract
  cc_list: "/expiring-contracts",
  cc_contract_ims: "/client-contracts",
  cc_missing_inprogress:"/missing-inprogress-contracts",
  cc_listById: "/:name/contract-list/:id",
  cc_viewById: "/:name/contract-view/:id",
  cc_listMissingInprogressById: "/:name/contract-missing-inprogress-view/:id",
  cc_edit: "/:name/edit-contract",
  cc_create: "/:name/create-contract",

  // portal management
  portalManagement: "/PortalManagement",
  projectManagement: "/PortalManagement/ProjectManagement",
  //price revision application
  price_listing: "/price_listing",
  resource_details: "/resource_details",
  revision_dues: "/revision_dues",
  
  // APPRAISAL SYSTEM
  ASDashboard:"/ASDashboard",
  cycleEmployeeList:`/ASDashboard/:cycleName/:cycleId/CycleEmployeeList`,
  ASEmployeeList:"/ASEmployeeList",
  EmployeeDetails:"/ASDashboard/:cycleName/:cycleId/CycleEmployeeList/EmployeeDetails/:empId",
  EmployeeInformation:"/ASEmployeeList/EmployeeInformation/:empId",
  
  // Exit Formalities
  ef_dashboard:"/ef_dashboard",
  ef_employeeExit:"/ef_employeeExit",
  ef_employeeDetails:`/ef_employeeExit/:empId/employeeDetails`,
  ef_employeeEdit:`/ef_employeeExit/:empId/employeeEdit`,

  //Project Costing And Margin
  pcm_dashboard:"/pcm_dashboard",
  pcm_editProject: "/editProjectCosting",
  pcm_overheads: "/pcm_overheads",
  pcm_invoices: "/pcm_invoices",
  pcm_editInvoice: "/pcmEditInvoice",
  pcm_addInvoice: "/pcmAddInvoice",
  pcm_projectCostingInformation: "/pcmProjectCostingInformation",
  pcm_projectCostBreakUp: "/pcmProjectCostBreakUp",
  pcm_resourceMargin: "/pcmResourceMargin"
};
