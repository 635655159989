import React from "react";
import { QuestionBySkillsCom } from "./component/QuestionBySkillsCom";
import style from "./QuestionBySkills.module.css"
import QuestionnaireSidebar from "../sidebar/SideBar";
export const QuestionBySkills =()=>{
    return(<>
       <div className={style.mainSection}>
         <QuestionnaireSidebar />
         <QuestionBySkillsCom />
       </div>
     </>)
 }
