import React from "react";
import loaderImg from "./../../../../assets/pcm/loader.gif";
import style from "./loading.module.css";

const Loading = () => {
  return (
    <div>
      <img src={loaderImg} alt="loader" className={style.loaderImg} />
      <p className={style.loadingText}>Loading...</p>
    </div>
  );
};

export default Loading;
