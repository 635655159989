import Dashboard from "../Pages/ExitFormalities/Dashboard/Dashboard";
import EmployeeExit from "../Pages/ExitFormalities/EmployeeExit/EmployeeExit";
import EmployeeExitView from "../Pages/ExitFormalities/EmployeeDetail/EmployeeExitView";
import { path } from "./PathNames";
import EmployeeExitEdit from "../Pages/ExitFormalities/EmployeeDetail/EmployeeExitEdit/EmployeeExitEdit";

const efRoutes = [
    {
        path:path.ef_dashboard,
        component:Dashboard
    },
    {
        path:path.ef_employeeExit,
        component:EmployeeExit
    },
    {
        path:path.ef_employeeDetails,
        component:EmployeeExitView
    },
    {
        path:path.ef_employeeEdit,
        component:EmployeeExitEdit
    },
]
export default efRoutes;