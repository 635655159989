import React, { useState, useEffect, useRef } from "react";
import style from "./RecurringTaskDetails.module.css";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import pencilEditButtonImg from "../../../../assets/pencil-edit-button.png";
import rubbishBinImg from "../../../../assets/rubbish-bin.png";
import { useHistory } from "react-router-dom";

import Moment from "moment";
import axios from "axios";
import cookie from "react-cookies";
import Modal from "react-responsive-modal";
import constants, {
  employeeId,
  taskTrackerAccessCode,
} from "../../../../Utils/constants";
import ConfirmPopup from "./ConfirmPopup";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify";
import loaderImg from '../../../../assets/loader-green2.gif';

const arr = [
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit amet, consectetur adipisci",
  },
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit amet, co consectetur adipisci",
  },
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit ametamet, consectetur adipisci",
  },
];

const TaskAssignedByMeDetails = (props) => {
  const { checkValidateAccess } = useValidationAccess();
  const commentText = useRef(null);
  const taskId = props?.history.location.state?.task?.taskId;
  const [data, setData] = useState("");
  const [commentHistory, setcommentHistory] = useState([]);
  const [editComment, seteditComment] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [newData, setnewData] = useState(props?.history.location.state.task);
  const [isdeleted, setIsdeleted] = useState(false);
  const [commentToEdit, setcommentToEdit] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showErr, setShowErr] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmCommentModal, setConfirmCommentModal] = useState(false);
  const [taskCommentId, setTaskCommentId] = useState();
  const [myTaskDetail, setMyTaskDetailByID] = useState();
  const [returnPage, setreturnPage] = useState(
    props?.history.location.state.activePage
  );
  const profile = cookie.load("profile");
  const [openAddCloseNote, setOpenCloseNote] = useState(false);
  const [closeNote, setCloseNote] = useState("");
  const [doneReviewNote, setDoneReviewNote] = useState("");
  const [openDoneRevNote, setOpenDoneRevNote] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const limit = 3;

  const handleCommentEdit1 = (comment) => {
    setcommentToEdit(comment);
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  // function notifySuccess() {
  //   toast("Updated Successfully", {
  //     position: "top-center",
  //     autoClose: 1000,
  //     hideProgressBar: false,
  //     closeOnClick: false,
  //     pauseOnHover: false,
  //     draggable: false,
  //     progress: undefined,
  //     closeButton: false,
  //   });
  // }

  const EditComment = () => {
    history.push("./taskTracker/Models/EditComment");
  };

  const url2 = constants.apiBaseUrl2;

  const deleteTaskComment = () => {
    axios
      .delete(url2 + `/deleteTaskComment?taskCommentId=${taskCommentId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then(() => {
        setTaskCommentId(undefined);
        handleConfirmCommentPopupClose();
        pageCount > 0 && currentPage > 1 && commentHistory.length === 1
          ? setCurrentPage(currentPage - 1)
          : setCurrentPage(currentPage);
        handleapidata();
      })
      .catch((err) => {
        return err;
      });
  };



  const getSingleDetailsById = (id) => {
    setIsLoading(true);
    axios
      .get(url2 + `/getRECURRINGTaskDetailsByTaskId?empId=${employeeId}&taskId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setMyTaskDetailByID(res.data.responseData);
        setIsLoading(false);
      })
      .catch((err) => { });
  };

  const handleTaskEdit = (comment) => {
    history.push({
      pathname: "/recurringTask/EditComments",
      state: {
        comment: comment,
        newData: myTaskDetail,
        activePage: 2,
      },
    });
  };

  const directEdit2 = (data2) => {
    // if (returnPage == 1) {
    //   history.push("/viewTask", data2);
    // } else {
    history.push({
      pathname: "/taskTracker/editRecurringTask",
      state: {
        task: data2,
        activePage: 3,
      },
    });
    // }
  };

  const deleteData = () => {
    setreturnPage(1);
    axios
      .delete(url2 + `/deleteRecurringTaks/${taskId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        notifySuccess("Deleted Successfully !");
        handleConfirmPopupClose()
        history.push("/taskTracker/recurringtask");
      })
      .catch((err) => {
        return err;
      });

    // }, 10000);
    // setundoo(d1);
  };

  const postData = (e) => {
    setData(e.target.value);
  };

  var dateTime1 = Moment(Moment().toDate()).format("YYYY-MM-DD hh:mm");

  const Data = {
    localDateTime: dateTime1,
    taskId: taskId,
    comment: data?.trim(),
  };

  const AddComment = () => {
    if (Number(Data.comment.length) === 0) {
      setShowErr(true);
      return false;
    }
    axios
      .post(url2 + "/addRecurringTaskComment", Data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          setShowErr(true);
        } else {
          setShowErr(false);
          commentText.current.value = "";
          setData("");
        }
        handleapidata();
      })
      .catch((err) => {
        return err;
      });
  };

  const redirectHistory = () => {
    history.push({
      pathname: "/taskTracker/recurringTaskHistory",
      state: {
        task: myTaskDetail,
        activePage: returnPage,
      },
    });
  };
  const ClosedTask = (closed) => {
    // const data = {
    //   taskId: newData.taskId,
    //   title: newData.title,
    //   description: newData.description,
    //   planedEndDate: Moment(newData.planedEndDate).format("YYYY-MM-DD hh:mm"),
    //   assignedTo: myTaskDetail.assignedTo,
    //   priority: newData.priority,
    //   status: closed,
    //   employeeRelatedToTask: myTaskDetail?.taskRelatedToEmployeeId,
    //   functionalFieldId: myTaskDetail?.functionalFieldAreaId,
    //   projectName: myTaskDetail?.projectName,
    //   localDateTime: Moment(newData.planedEndDate).format("YYYY-MM-DD hh:mm"),
    //   closingNote: closeNote,
    // };
    // axios
    //   .post(url2 + `/updateRecurringTaks/${newData.taskId}`, data, {
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       Authorization: ` ${cookie.load("token")}`,
    //       employeeId: employeeId,
    //     },
    //   })
    axios
      .patch(url2 + `/updateRecurringTaks/${myTaskDetail?.taskId}`)
      .then((res) => {
        if (res.data.responseData) {
          notifySuccess("Closed Task Successfully !");
          setShowErr(true);
          history.push({
            pathname: "/taskTracker/recurringtask",
          });
        } else {
          history.push({
            pathname: "/taskTracker/ClosedByMeComponent",
          });
        }
        setOpenCloseNote(false);
      })
      .catch((err) => {
        notifyError("Something went wrong!");
        return err;
      });
  };

  // const DesCriptChanges = (comment) => {
  //   let data = comment?.split("\n");
  //   //padding: 10px 1rem !important;
  //   let newData = data?.map((val) => {
  //     return (
  //       <p>
  //         {val}
  //         <br></br>
  //       </p>
  //     );
  //   });
  //   return newData;
  // };

  function DesCriptChanges(description) {
    if (!description) return "";

    // Updated regex pattern to match "http", "https" and "www" URLs
    const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;

    // Split the description by newline characters
    const newlineSplit = description.split('\n').map((line, index) => {
      // Split the line by URLs and replace URLs with anchor tags
      const parts = line.split(urlPattern).map((part, subIndex) => {
        if (urlPattern.test(part)) {
          const url = part.startsWith('www') ? `http://${part}` : part;
          return (
            <a href={url} key={`${index}-${subIndex}`} target="_blank" rel="noopener noreferrer" className={style.blueLink}>
              {part}
            </a>
          );
        }
        return part;
      });

      return (
        <React.Fragment key={index}>
          {parts}
          {index < description.split('\n').length - 1 && <br />}
        </React.Fragment>
      );
    });

    return newlineSplit;
  }



  const MarkDone = () => {
    const data = {
      taskId: myTaskDetail?.taskId,
      title: myTaskDetail?.title,
      description: myTaskDetail?.description,
      planedEndDate: Moment(myTaskDetail?.plannedEndDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: myTaskDetail?.assignedTo,
      priority: myTaskDetail?.priority,
      status: "Done",
      employeeRelatedToTask: myTaskDetail?.taskRelatedToEmployeeId,
      functionalFieldId: myTaskDetail?.functionalFieldAreaId,
      projectName: myTaskDetail?.projectName,
      localDateTime: Moment(myTaskDetail?.plannedEndDate).format("YYYY-MM-DD hh:mm"),
      doneReviewNote,
    };
    axios
      .post(url2 + "/editTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          setShowErr(true);
          history.push({
            pathname: "/taskTracker",
          });
        } else {
          history.push({
            pathname: "/taskTracker",
          });
        }
        setOpenDoneRevNote(false);
      })
      .catch((err) => {
        return err;
      });
  };

  const handleapidata = () => {
    const commentList = axios
      .get(
        url2 +
        "/getRecurringTaskComment?limit=" +
        limit +
        "&page=" +
        currentPage +
        "&taskId=" +
        taskId,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        }
      )
      .then((res) => {
        const responseData = res.data.responseData;
        setcommentHistory(responseData.comments);
        setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
      })
      .catch((err) => {
        return err;
      });
  };

  const history = useHistory();
  useEffect(() => {
    handleapidata();
    getSingleDetailsById(props?.history.location.state.task?.taskId);
  }, [currentPage]);

  function handleConfirmPopupClose() {
    setConfirmModal(false);
  }

  function handleConfirmCommentPopupClose() {
    setConfirmCommentModal(false);
  }
  function handleConfirmCommentPopupOpen(commentId) {
    setTaskCommentId(commentId);
    setConfirmCommentModal(true);
  }
  return (
    <div>

      <AddClosingNote
        openAddCloseNote={openAddCloseNote}
        setOpenCloseNote={setOpenCloseNote}
        note={closeNote}
        setNote={setCloseNote}
        addCloseNote={() => ClosedTask("Closed")}
      />
      <AddDoneRevNote
        doneReviewNote={doneReviewNote}
        setDoneReviewNote={setDoneReviewNote}
        openDoneRevNote={openDoneRevNote}
        setOpenDoneRevNote={setOpenDoneRevNote}
        addDoneRevNote={() => MarkDone("Done")}
      />
      <Header />
      <div className={style.mainSection}>
        <SideBar />

        <div className={style.rightSection}>
          <div style={{ marginLeft: '14px', marginTop: '10px' }}>
            <span
              style={{ color: 'green', cursor: 'pointer', fontSize: '17px' }}
              onClick={() => history.push('/taskTracker/recurringtask')}
            >Recurring Tasks</span><span> &gt; </span><span style={{ fontSize: '17px' }}>Task Details</span>
          </div>
          <div className={style.taskAssigned}>
            {isLoading ? <div>
                  <img src={loaderImg} alt="loader" className={style.loader} />
                  <p className={style.loaderPara}>Loading...</p>
                </div> : <div style={{ padding: "16px" }}>
              <div className={style.container}>
                <div className={style.subcontainer}>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "100%" }}
                  >
                    <h5>Task Title</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.title}
                    </p>
                  </div>

                  <div
                    className={style.subcontainerinner}
                    style={{ width: "100%" }}
                  >
                    <h5>Task Description</h5>
                    <p>
                      {isdeleted ? "" : DesCriptChanges(myTaskDetail?.description)}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Creation Date</h5>
                    <p>{isdeleted ? "" : myTaskDetail?.creationDate}</p>
                    {/* <p>{myTaskDetail.created}</p> */}
                    {/* <p>{Moment(newData.planedEndDate).format("DD MMM (ddd)")}</p> */}
                  </div>
                  {/* <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Planned End Date</h5>
                    <p>
                      {isdeleted
                        ? ""
                        : Moment(newData?.planedEndDate).format("DD MMM YY")}
                    </p>
                  </div> */}
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>
                      Assigned To
                    </h5>
                    <p>
                      {isdeleted
                        ? ""
                        : myTaskDetail?.assignedToName}
                    </p>
                  </div>
                  {/* <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                    >
                    <h5>Status</h5>
                    <p
                      style={{ fontSize: "12px" }}
                      className={
                        newData?.status === "Assigned"
                          ? style.asigned_color
                          : newData?.status === "Done"
                            ? style.done_color
                            : newData?.status === "Onhold"
                              ? style.onhold_color
                              : style.Closed_color
                      }
                    >
                      {newData?.status === "Closed"
                        ? "Closed"
                        : newData?.status === "Onhold"
                          ? "On Hold"
                          : newData?.status}
                    </p>
                  </div> */}

                  {/* <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Project</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.projectName}
                    </p>
                  </div> */}

                  {/* {typeof newData.assignedBy === "string" ? (
                    <div
                      className={style.subcontainerinner}
                      style={{ width: "25%" }}
                    >
                      <h5>Assigned By</h5>
                      <p className={style.titlee}>{newData?.assignedBy}</p>
                    </div>
                  ) : null} */}
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Space</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.groupName}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Frequency</h5>
                    <p className={style.titlee}>

                      {myTaskDetail?.frequency}{" "}
                      <span style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        marginLeft: '1px'
                      }}>{
                          myTaskDetail?.frequency === "Weekly" ? `(${myTaskDetail?.frequencyValue?.substring(0, 3)})` :
                            myTaskDetail?.frequency === "Monthly" ? `(${myTaskDetail?.frequencyValue?.split('-')[0]})` :
                              myTaskDetail?.frequency === "Fortnightly" ? `(${myTaskDetail?.frequencyValue.split(' ').join('')})` :
                                myTaskDetail?.frequency === "Quarterly" ? `(${myTaskDetail?.frequencyValue?.split('-')[0]})` : null
                        }</span>
                    </p>
                  </div>
                </div>
                <br />
              </div>



              <div className={style.tabl}>
              </div>
              <div className={style.mobileContainer}>
                {arr.map((ar, indexar) => {
                  return (
                    <div
                      key={indexar}
                      style={{
                        display: "block",
                        position: "relative",
                        borderBottom: "1px solid #707070",
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Reported Date :
                        </span>
                        {ar.a}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Username :
                        </span>
                        {ar.b}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Comment :
                        </span>
                        {ar.c}
                      </p>
                      <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <img
                          alt="img"
                          style={{ cursor: "pointer" }}
                          onClick={handleCommentEdit1}
                          src={pencilEditButtonImg}
                        />
                        <img
                          alt="img"
                          style={{ paddingLeft: "10px" }}
                          src={rubbishBinImg}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>}

          </div>
          <div className={style.rr} style={{ textAlign: "right" }}>
            {/* {employeeId === newData.assignedBy ||
                    newData.status === "Done"
                    ? null
                    : checkValidateAccess(
                      taskTrackerAccessCode?.done_Review_MyTask
                    ) && (
                      <button
                        onClick={() => {
                          setOpenDoneRevNote(true);
                          setDoneReviewNote("");
                        }}
                        className={style.TaskDone}
                      >
                        Done (Review)
                      </button>
                    )} */}

            {
              !myTaskDetail?.closeButtonVisibility ? null : (
                checkValidateAccess(taskTrackerAccessCode?.close_Task_TaskAssigned) && (
                  <button
                    onClick={() => setOpenCloseNote(true)}
                    className={style.closed}
                    disabled={myTaskDetail?.status === "Closed"}
                  >
                    Close Task
                  </button>
                )
              )
            }


            {checkValidateAccess(
              taskTrackerAccessCode?.view_Task_History_TaskAssigned
            ) && (
                <button
                  onClick={() => redirectHistory()}
                  className={style.taskHistory}
                >
                  View Task Logs
                </button>
              )}

            {
              // returnPage !== 1 &&
              checkValidateAccess(
                taskTrackerAccessCode?.delete_TaskAssigned
              ) && myTaskDetail?.deleteTaskButtonVisibility && (
                <button onClick={()=>setConfirmModal(true)} className={style.delete}>
                  Delete
                </button>
              )}

            {isdeleted && (
              <button
                className={style.edit}
                onClick={() => {
                  setIsdeleted(false);
                  setreturnPage(props.history.location.state.activePage);
                }}
              >
                Undo
              </button>
            )}
            {
              myTaskDetail?.status === 'Closed' && myTaskDetail?.editTaskButtonVisibility ? null : (
                props?.history.location.pathname === "/MyTaskDetailsDetails"
                  ? null
                  : (
                    checkValidateAccess(taskTrackerAccessCode?.edit_TaskAssigned) && (
                      <button
                        onClick={() => directEdit2(myTaskDetail)}
                        className={style.edit}
                      >
                        Edit
                      </button>
                    )
                  )
              )
            }


          </div>
        </div>
      </div>
      <ConfirmPopup open={confirmModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Do you want to delete?
          </h5>
          <div className={style.btnCont}>
            <button className={style.yesBtn} onClick={deleteData}>
              Yes
            </button>
            <button className={style.noBtn} onClick={handleConfirmPopupClose}>
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
      <ConfirmPopup open={confirmCommentModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to delete?
          </h5>
          <div className={style.btnCont}>
            <button className={style.yesBtn} onClick={deleteTaskComment}>
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={handleConfirmCommentPopupClose}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </div>
  );
};

const AddClosingNote = ({
  openAddCloseNote,
  setOpenCloseNote,
  note,
  setNote,
  addCloseNote,
}) => {
  const [validate, setValidate] = useState(false);
  const handleAddNote = () => {
    if (!note) {
      setValidate(true);
    } else {
      setValidate(false);
      addCloseNote();
    }
  };
  return (
    <Modal
      open={openAddCloseNote}
      center
      onClose={() => setOpenCloseNote(false)}
      showCloseIcon={false}
      className={style.modal}
    >
      <div className={style.modalHead}>
        Close Task Note<span style={{ color: "red" }}>*</span>
      </div>
      <div className={style.modalInput}>
        <textarea
          className={style.inpBox}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        {validate && !note && (
          <div style={{ color: "red", fontSize: "15px" }}>
            Please Enter Closing Note
          </div>
        )}
      </div>
      <div className={style.btnROW}>
        <button
          className={style.ttcancelBtn}
          onClick={() => {
            setNote("");
            setOpenCloseNote(false);
            setValidate(false);
          }}
        >
          Cancel
        </button>
        <button className={style.ttAddBtn} onClick={() => handleAddNote()}>
          Done
        </button>
      </div>
    </Modal>
  );
};

const AddDoneRevNote = ({
  openDoneRevNote,
  setOpenDoneRevNote,
  doneReviewNote,
  setDoneReviewNote,
  addDoneRevNote,
}) => {
  const [validate, setValidate] = useState(false);
  const handleAddDoneRevNote = () => {
    if (!doneReviewNote) {
      setValidate(true);
    } else {
      setValidate(false);
      addDoneRevNote();
    }
  };
  return (
    <Modal
      open={openDoneRevNote}
      center
      onClose={() => setOpenDoneRevNote(false)}
      showCloseIcon={false}
      className={style.modal}
    >
      <div className={style.modalHead}>
        Done(Review) Note<span style={{ color: "red" }}>*</span>
      </div>
      <div className={style.modalInput}>
        <textarea
          className={style.inpBox}
          value={doneReviewNote}
          onChange={(e) => setDoneReviewNote(e.target.value)}
        />
        {validate && !doneReviewNote && (
          <div style={{ color: "red", fontSize: "15px" }}>
            Please Enter Done(Review) Note
          </div>
        )}
      </div>
      <div className={style.btnROW}>
        <button
          className={style.ttcancelBtn}
          onClick={() => {
            setDoneReviewNote("");
            setOpenDoneRevNote(false);
            setValidate(false);
          }}
        >
          Cancel
        </button>
        <button
          className={style.ttAddBtn}
          onClick={() => handleAddDoneRevNote()}
        >
          Done
        </button>
      </div>
    </Modal>

  );
};

export default TaskAssignedByMeDetails;
