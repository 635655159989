import React, { useEffect, useContext, useState } from "react";
import Header from "../../../taskTracker/commonComponent/Header";
import SideBar from "../../Clients/component/SideBar";
import style from "./UpComingRampDownReport.module.css";
import constants from "../../../../Utils/constants";
import axios from "axios";
import loaderImg from "../../../../assets/loader.gif";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { customSearch } from "../../Clients/component/style";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import milestone from "../../../../assets/milestone.png";
import Pagination from "../../CommonComponent/Pagination";
import { api } from "../../CommonApi/api";
import { get, post } from "../../CommonApi/axiosCall";
import cookie from "react-cookies";
import { useQuery } from "react-query";
import sortDownarrowImg from "../../../../assets/Down.png";
import sortUparrowImg from "../../../../assets/Up.png";
import { getEmployeeList } from "../../../../Utils/axios/clientContractApi";

export const UpcomingRampDownReport = (props) => {
  const baseUrl = constants.apiBaseUrlResource;
  const [showFilter, setShowFilter] = useState(false);
  const [sortOrder, setSortOrder] = useState("");
  const [OnsiteData, setOnsiteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [totalResourceOnsite, setTotalResourceOffShore] = useState(0);
  const [clientListing, setClientListing] = useState([]);
  const [months, setMonths] = useState([]);
  const [empListing, setEmpListing] = useState([]);
  const [onsiteSkills, setOnsiteSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [show, setShow] = useState(false);
  const [ClientInformedReason, setReason] = useState("");
  const [filterName, setFilterName] = useState({});
  const [filterEmp, setFilterEmp] = useState({});
  const [allData, setAllData] = useState({
    action: [1],
    clientId: [],
    employeeId: [],
    skill: [],
    salesPersonId: [],
    salaryRange: [],
    partners: [],
    startDateMonth: [],
    endDateMonth: [],
    employeeStatus: [null, null],
    quaterlyEndOfDeployment: [],
    quaterlyDeployment: [],
    handleFilter: false,
    clientId: [],
  });

  const history = useHistory();

  const { data: getEmployeeListOptions } = useQuery(["getEmployeeList"], () =>
    getEmployeeList()
  );

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, skill: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "Code1":
          setAllData({ ...allData, employeeId: [e?.value] });
          setFilterEmp({ ...filterEmp, positionCode: e?.label });
          break;
        case "Code2":
          setAllData({ ...allData, salesPersonId: [e?.value] });
          setFilterEmp({ ...filterEmp, positionCode: e?.label });
          break;
        case "Code3":
          setAllData({ ...allData, clientId: [e?.value] });
          setFilterEmp({ ...filterEmp, positionCode: e?.label });
          break;
        default:
      }
    }
  };

  empListing?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const getOnsiteSkillsList = onsiteSkills?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getemployee = empListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getSalpersonList = getEmployeeListOptions?.map((i) => ({
    value: i.id,
    label: i.label,
  }));

  const HistoryHandler1 = () => {
    history.push("/onSiteReportDashBoard");
  };
  const handleSorting = () => {
    setSortOrder(sortOrder === "" ? true : sortOrder === true ? false : true);
  };
  useEffect(() => {
    setAllData({ ...allData, expectedEndDate: sortOrder });
  }, [sortOrder]);

  const getAddResourceOnsiteData = (clearData, currentPage) => {
    const filterData = clearData?.clientId?.length == 0 ? allData : allData;

    let url = api.upcomingRampDownChanges;

    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}`;
    }
    if (allData?.employeeId) {
      url += `&empId=${allData?.employeeId}`;
    }
    if (allData?.skill) {
      url += `&skill=${allData?.skill}`;
    }
    if (allData?.salesPersonId) {
      url += `&salesPersonId=${allData?.salesPersonId}`;
    }
    if (allData?.clientId) {
      url += `&clientId=${allData?.clientId}`;
    }
    url+=`&expectedEndDate=${allData.expectedEndDate}`


    setIsLoaded(true);
    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setIsLoaded(false);
        setOnsiteData(res.data.responseData);
        setTotalResourceOffShore(res.data.responseData.totalResources);
        setpageCount(res.data.responseData.totalResources / 10);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };

  const getClientListing = () => {
    let url = api.getOnsiteClientList;

    get(url)
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch(() => {});
  };

  const getMonths = () => {
    axios
      .get(baseUrl + "/getMonthDetails", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setMonths(res.data.responseData);
      })
      .catch(() => {});
  };

  const getEmpListing = () => {
    let url = api.getOnsiteEmployeeList;

    get(url)
      .then((res) => {
        setEmpListing(res.data.responseData.employees);
      })
      .catch(() => {});
  };

  const getOnsiteSkills = () => {
    let url = api.getOnsiteFilterSkillData;
    get(url)
      .then((res) => {
        setOnsiteSkills(res.data.responseData);
      })
      .catch(() => {});
  };

  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  useEffect(() => {
    getAddResourceOnsiteData(allData, currentPage);
  }, [currentPage, allData]);

  useEffect(() => {
    // getUserData();
    getMonths();
    getClientListing();
    getEmpListing();
    getOnsiteSkills();
  }, []);
  const getClientList = clientListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const value1 = allData?.employeeId[0]
    ? getemployee?.find((e) => e.value === allData.employeeId[0])
    : null;
  const value3 = allData?.salesPersonId[0]
    ? getEmployeeListOptions?.find((e) => e.value === allData.salesPersonId[0])
    : null;
  const value2 = allData?.skill[0]
    ? getOnsiteSkillsList?.find((e) => e.value === allData.skill[0])
    : null;
  const value4 = allData?.clientId[0]
    ? getClientList?.find((e) => e.value === allData.clientId[0])
    : null;
  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>

            <div className={style.second_child}>
              <div className={style.title}>
                Upcoming Ramp Down
                <span className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {totalResourceOnsite}
                  </span>
                </span>
              </div>
              <div className={style.twoFilter}>
                <div
                  className={style.s2}
                  // style={{ width: "26%" }}
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  {allData?.clientId?.length == 0 ? (
                    <span className={style.cp_serchIcon}>
                      <img src={searchIcon} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon}
                      onClick={() => {
                        setAllData({
                          ...allData,
                          clientId: [],
                        });
                        setFilterEmp({ ...filterEmp, positionCode: null });
                      }}
                    >
                      {allData?.clientId?.length != 0 ? (
                        <AiOutlineClose />
                      ) : null}
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Client Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={value4}
                    onChange={(e) => {
                      onSearchHandler(e, "Code3");
                      setCurrentPage(1);
                      setShowFilter(false);
                    }}
                    options={getClientList}
                  />
                </div>
                <div
                  className={style.s2}
                  // style={{ width: "26%" }}
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  {allData?.salesPersonId?.length == 0 ? (
                    <span className={style.cp_serchIcon}>
                      <img src={searchIcon} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon}
                      onClick={() => {
                        setAllData({
                          ...allData,
                          salesPersonId: [],
                        });
                        setFilterEmp({ ...filterEmp, positionCode: null });
                      }}
                    >
                      {allData?.salesPersonId?.length != 0 ? (
                        <AiOutlineClose />
                      ) : null}
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Sales Person"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={value3}
                    onChange={(e) => {
                      onSearchHandler(e, "Code2");
                      setCurrentPage(1);
                      setShowFilter(false);
                    }}
                    options={getSalpersonList}
                  />
                </div>
                <div
                  className={style.s1}
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  {allData?.skill?.length == 0 ? (
                    <span className={style.cp_serchIcon}>
                      <img src={searchIcon} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon}
                      onClick={() => {
                        setAllData({
                          ...allData,
                          skill: [],
                        });
                        setFilterName({ ...filterName, positionCode: null });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Technology"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={value2}
                    onChange={(e) => {
                      onSearchHandler(e, "Code");
                      setShowFilter(false);
                      setCurrentPage(1);
                    }}
                    options={getOnsiteSkillsList}
                  />
                </div>
                <div
                  className={style.s22}
                  // style={{ width: "26%" }}
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  {allData?.employeeId?.length == 0 ? (
                    <span className={style.cp_serchIcon2}>
                      <img src={searchIcon} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon2}
                      onClick={() => {
                        setAllData({
                          ...allData,
                          employeeId: [],
                        });
                        setFilterEmp({ ...filterEmp, positionCode: null });
                      }}
                    >
                      {allData?.employeeId?.length != 0 ? (
                        <AiOutlineClose />
                      ) : null}
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Employee Name/Code"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={value1}
                    onChange={(e) => {
                      onSearchHandler(e, "Code1");
                      setCurrentPage(1);
                      setShowFilter(false);
                    }}
                    options={getemployee}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.onsiteList}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : OnsiteData.resources?.length > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "10px" }}>Code</th>
                    <th style={{ padding: "10px" }}>Name</th>
                    <th style={{ padding: "10px" }}>Client Name</th>
                    <th style={{ padding: "10px" }}>Employer</th>
                    {/* <th style={{ padding: "10px" }}>Resource Source</th> */}
                    <th style={{ padding: "10px" }}>Sales Person</th>
                    <th style={{ padding: "10px" }}>Technology</th>
                    <th style={{ padding: "10px" }}>
                      Total Year
                      <br /> of Exp
                    </th>
                    <th style={{ padding: "10px" }}>
                      Deployment
                      <br /> Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Expected
                      <br />End Date
                      <img
                        alt=""
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          bottom: "0px",
                          left: "5px",
                        }}
                        src={
                          sortOrder === true ? sortUparrowImg : sortDownarrowImg
                        }
                        onClick={handleSorting}
                      />
                    </th>
                    <th style={{ padding: "10px" }}>Resign Date</th>

                    <th style={{ padding: "10px" }}>
                      Client Notification
                      <br /> Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Estimated Client
                      <br /> Informed Date
                    </th>
                    <th style={{ padding: "10px" }}>
                      Client
                      <br /> Informed
                    </th>
                    <th style={{ padding: "10px" }}>
                      Client Notification
                      <br /> Reason
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {OnsiteData?.resources.map((val) => {
                    return (
                      <tr
                        key={val.id}
                        style={{
                          borderBottom: "2px solid #F0F0F0",
                          textAlign: "start",
                          fontSize: "13px",
                        }}
                      >
                        <td style={{ padding: "10px" }}>{val.empCode}</td>
                        <td style={{ padding: "10px" }}>{val.empName}</td>
                        <td style={{ padding: "10px" }}>{val.clientName}</td>
                        <td style={{ padding: "10px" }}>{val.employer}</td>
                        <td style={{ padding: "10px" }}>
                          {val.salesPersonName}
                        </td>
                        {/* <td style={{ padding: "10px" }}>{val.resourceSource===false?"Self":val.resourceSource===true?"Handover":null}</td>                                                <td style={{ padding: "10px" }}>{val.salesPersonName}</td> */}
                        <td style={{ padding: "10px" }}>{val.skillName}</td>
                        <td style={{ padding: "10px" }}>
                          {~~(val.experience / 12)} Y -{val.experience % 12} M
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.allocationdate
                            ? moment(val.allocationdate).format("DD MMM YY")
                            : null}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.expectedEndDate
                            ? moment(val.expectedEndDate).format("DD MMM YY")
                            : null}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.resignDate &&
                            moment(val.resignDate).format("DD MMM YY")}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.clientNotificationDate &&
                            moment(val.clientNotificationDate).format(
                              "DD MMM YY"
                            )}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.clientInformedDate &&
                            moment(val.clientInformedDate).format("DD MMM YY")}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.isClientInformed !== null
                            ? val.isClientInformed
                              ? "Yes"
                              : "No"
                            : "NA"}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.clientNotificationReason || "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}

            {/* } */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <button className={style?.gotoListBtn} onClick={HistoryHandler1}>
              Back
            </button>
            {totalResourceOnsite > limit && pageCount > 0 && (
              <div
                className={style.paginationmobileContainer}
                style={{ marginTop: "10px" }}
              >
                <Pagination
                  pagesCount={pageCount}
                  pageSize={limit}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>{ClientInformedReason}</div>
      </Modal>
    </>
  );
};
