import React from "react";
import style from "./PlBtn.module.css";

const PlBtn = ({ children, onClick, type,disabled }) => {
  switch (type) {
    case "plus":
      return (
        <button className={style.createBtn} onClick={onClick}>
          <span> + </span>
          {children}
        </button>
      );
    case "cancel":
      return (
        <button className={style.cancelBtn} onClick={onClick} disabled={disabled}>
          {children}
        </button>
      );
    default:
      return (
        <button className={style.createBtn} onClick={onClick} disabled={disabled}>
          {children}
        </button>
      );
  }
};

export default PlBtn;


