import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import style from "./sideBar.module.css";
import InnovationMLogo from "../../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import { menuItems } from "./data";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import * as img from "./../../../../assets/index";

const SideBar = () => {
  const location = useLocation();
  const { checkValidateAccess } = useValidationAccess();
  let history = useHistory();
  const [buttonHover, setButtonHover] = useState(false);

  const isActiveMenuItem = (validPaths) => {
    return validPaths.some((path) => location.pathname.includes(path));
  };
  return (
    <div className={style.leftSection}>
      <img
        alt="DashBoardImage"
        src={InnovationMLogo}
        className={style.dashboardImage}
        onClick={() => history.push("/dashboard")}
      />
      <button className={style.btn}>
        <div>
          <img src={img.pcm_icon} alt="img" />
        </div>
        <span>Project Costing & Margin</span>
      </button>

      <div className={style.side_buttons_div}>
        {/* coming from data.js */}
        {menuItems.map((i, index) => {
            if (checkValidateAccess(i?.accessCode)) {
          return (
            <Link to={i.path} style={{ textDecoration: "none" }} key={i.path}>
              <div
                className={
                  // check if path matches or index match to hover one
                  isActiveMenuItem(i.validPaths) || buttonHover === index
                    ? style.active_btn
                    : style.deactivate_btn
                }
                // set index on hover
                onMouseEnter={() => setButtonHover(index)}
                onMouseLeave={() => setButtonHover(false)}
              >
                <img
                  src={
                    isActiveMenuItem(i.validPaths) || buttonHover === index
                      ? i.imgSrcSelected
                      : i.imgSrc
                  }
                  alt={i.heading}
                  className={style.icon}
                />
                <div
                  className={style.heading}
                  dangerouslySetInnerHTML={{ __html: i.heading }}
                />
              </div>
            </Link>
          );
            } else return null;
        })}
      </div>
    </div>
  );
};

export default SideBar;
