import React, { useEffect, useState } from "react";
import style from "./summaryCom.module.css";
import { useHistory } from "react-router";
import Select from "react-select";
import dropdownIcon from "../../../../../assets/downIcon.png";
import { customStyles } from "../../../commonComponent/style";
import axios from "axios";
import cookie from "react-cookies";
import { employeeId } from "../../../../../Utils/constants";

const SummaryCom = () => {
  const [currentPage] = useState(1);
  const [monthlyOverView, setMonthlyOverview] = useState();
  const [summaryList, setSummary] = useState();
  const [months, setMonths] = useState();
  const [year, setYear] = useState();
  const [monthslabel, setMonthslabel] = useState();
  const [yearslabel, setYearslabel] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const monthsSet = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentYear = selectedDate.getFullYear();
  const [allData, setAllData] = useState({
    monthId: 0,
    yearId: 0,
  });
  const [allData1, setAllData1] = useState({
    yearId: 0,
  });
  const history = useHistory();
  const limit = 10;

  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;
  const CustomInput = () => {
    return (
      <span>
        <img
          alt=""
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  // DataClickeHandler
  const DataClickHandler = (categaryId, statusId) => {
    history.push({
      pathname: "/AllIssue",
      state: {
        categaryId: categaryId,
        statusId: statusId,
        monthId: allData?.monthId,
      },
    });
  };

  // getSummary Api Integrate

  const fetMonthlyOverView = () => {
    axios
      .post(url + `/getMonthlySummary?yearId=${allData?.yearId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          return;
        } else {
          setMonthlyOverview(res.data.responseData);
        }
      })
      .catch(() => {});
  };
  const getSummary = () => {
    axios
      .post(
        url +
          "/getIssueSummaryList?" +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        allData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          return;
        } else {
          setSummary(res.data.responseData);
        }
      })
      .catch(() => {});
  };

  const getMonthsList = () => {
    axios
      .get(url + "/getMonthsList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          return;
        } else {
          setMonths(res.data.responseData);
        }
      })
      .catch(() => {});
  };
  const getYearlist = () => {
    axios
      .get(url + "/get-year", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          return;
        } else {
          setYear(res.data.responseData);
        }
      })
      .catch(() => {});
  };

  const getAllmonthsList = months?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getAllYearsList = year?.map((i) => ({
    value: i.name,
    label: i.name,
  }));
  const defaultOption = getAllmonthsList?.find(
    (option) => option.value === new Date().getMonth() + 1
  );

  const defautlYear = getAllYearsList?.find(
    (option) => option.value == currentYear
  );

  useEffect(() => {
    getSummary();
    fetMonthlyOverView();
  }, [allData, allData1]);
  useEffect(() => {
    getYearlist();
    getMonthsList();
  }, []);

  const currentDate = new Date();
  const currentMonthIndex =
    allData?.yearId && allData?.yearId < currentYear
      ? 12
      : currentDate.getMonth();
  return (
    <div className={style.main}>
      <div className={style?.setFilter}>
        <p className={style.pp}>Yearly Overview</p>
        <div style={{ width: "20%", paddingRight: "1rem" }}>
          <Select
            styles={customStyles}
            classNamePrefix={"create_position"}
            components={{
              DropdownIndicator: () => CustomInput(),
              IndicatorSeparator: () => null,
            }}
            value={allData?.yearId ? yearslabel : defautlYear}
            options={getAllYearsList}
            onChange={(e) => {
              setAllData({ ...allData, yearId: e?.value });
              setYearslabel(e);
            }}
          ></Select>
        </div>
      </div>
      <div className={style.IssueList}>
        <div className={style.tableContainer}>
          <table className={style.table}>
            <thead>
              <tr className={style.th}>
                <th className={style.tableContent}>Status</th>
                {monthsSet.map(
                  (month, index) =>
                    // Display data in <th> elements from January to the current month
                    index <= currentMonthIndex && (
                      <th className={style.tableContent} key={index}>
                        {month}
                      </th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {monthlyOverView?.map((row, rowIndex) => (
                <tr key={rowIndex} className={style.td}>
                  <td className={style.tableContent}>{row.status}</td>
                  {row.months.map(
                    (month, monthIndex) =>
                      monthIndex <= currentMonthIndex && (
                        <td key={monthIndex} className={style.tableContent}>
                          {month.count}
                        </td>
                      )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className={style.div}>
        <span className={style.pp}>Monthly Issue Summary </span>{" "}
        <div style={{ width: "20%", paddingRight: "1rem" }}>
          <Select
            styles={customStyles}
            classNamePrefix={"create_position"}
            components={{
              DropdownIndicator: () => CustomInput(),
              IndicatorSeparator: () => null,
            }}
            value={allData?.monthId ? monthslabel : defaultOption}
            options={getAllmonthsList}
            onChange={(e) => {
              setAllData({ ...allData, monthId: e?.value });
              setMonthslabel(e);
            }}
          ></Select>
        </div>
      </div>

      <div className={style.IssueList1}>
        <table>
          <thead>
            <tr className={style.th1}>
              <th className={style.tableContent1}>Category</th>
              <th className={style.tableContent2}>Open</th>
              <th className={style.tableContent2}>In Progress</th>
              <th className={style.tableContent2}>Closed</th>
              <th className={style.tableContent2}>Total</th>
            </tr>
          </thead>
          <tbody>
            {summaryList?.summary.map((item) => (
              <tr className={style.td1} key={item.categoryId}>
                <td className={style.td1}>{item.category}</td>
                <td
                  className={`${style.td1} ${style.link}`}
                  onClick={() => DataClickHandler(item.categoryId, 1)}
                  title="Click here"
                >
                  {item.openCount}
                </td>
                <td
                  className={`${style.td1} ${style.link}`}
                  onClick={() => DataClickHandler(item.categoryId, 2)}
                >
                  {item.inprogressCount}
                </td>
                <td
                  className={`${style.td1} ${style.link}`}
                  onClick={() => DataClickHandler(item.categoryId, 3)}
                >
                  {item.closedCount}
                </td>
                <td className={`${style.td1}`}>
                  {item.openCount + item.inprogressCount + item.closedCount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SummaryCom;
