// NavigationContext.js

import React, { createContext, useContext, useState } from 'react';

export const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [nextScreen, setNextScreen] = useState(
    sessionStorage.getItem('nextScreen')
      ? sessionStorage.getItem('nextScreen')
      : null
  );
  const [prevData, setPrevData] = useState(
    sessionStorage.getItem('prevData')
      ? JSON?.parse(sessionStorage.getItem('prevData'))
      : {}
  );

  const [poolCondition, setPoolCondition] = useState(
    localStorage.getItem('prevData')
      ? JSON?.parse(localStorage.getItem('prevData'))
      : {}
  );

  return (
    <NavigationContext.Provider value={{ nextScreen, setNextScreen, prevData, setPrevData,poolCondition, setPoolCondition }}>
      {children}
    </NavigationContext.Provider>
  );
};
