import { path } from "./PathNames";
import Dashboard from "../Pages/Appraisal_System/Dashboard/Dashboard";
import AppraisalCycleEmployeeList from "../Pages/Appraisal_System/Dashboard/AppraisalCycles/AppraisalCycleEmployeeList/AppraisalCycleEmployeeList";
import ASEmployeeList from "../Pages/Appraisal_System/EmployeeList/ASEmployeeList";
import ShowEmployeeDetails from "../Pages/Appraisal_System/commonComponent/EmployeeDetails/OverallDetails/ShowEmployeeDetails";
import ShowEmployeeDetails2 from "../Pages/Appraisal_System/commonComponent/EmployeeDetails/OverallDetails/ShowEmployeeDetails2";
const AppraisalSystemRoutes = [
    {
        path:path.ASDashboard,
        component: Dashboard
    },
    {
        path:path.cycleEmployeeList,
        component: AppraisalCycleEmployeeList
    },
    {
        path:path.ASEmployeeList,
        component: ASEmployeeList
    },
    {
        path:path.EmployeeDetails,
        component: ShowEmployeeDetails
    },
    {
        path:path.EmployeeInformation,
        component: ShowEmployeeDetails2
    },
    
]
export default AppraisalSystemRoutes;