import React, { useEffect, useState } from "react";
import style from "./EmployeeExitView.module.css";
import SideBar from "../CommonComponents/Sidebar/SideBar";
import { NavLink } from "react-router-dom";
import EmployeeDetails from "./EmployeeExitDetails/EmployeeDetails";
import InvoiceContainer from "../../Employee_Induction/CommonComponent/InvoiceContainer/InvoiceContainer";
import { useQuery } from "react-query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getFormalitiesByEmployee } from "../../../Utils/axios/efAPI";
import moment from "moment";
import { CgNotes } from "react-icons/cg";
import ConfirmPopup from "../../userManegment/pageLayout/viewUser/ConfirmPopup";
import { notifyError } from "../../atsDashboard/utils/notify";

const EmployeeExitView = () => {
  const history = useHistory();
  const { empId } = useParams();
  const [data, setData] = useState(null)

  const isLoading = true;
  const isError = false;


  // const { data, isError, isLoading } = useQuery("getFormalitiesByEmployee", () => {
  //   return getFormalitiesByEmployee(empId);
  // }, {
  //   onError: () => notifyError("Something went wrong"),
  //   retry: false,
  //   refetchOnWindowFocus: false,
  // });

  useEffect(() => {
    getFormalitiesByEmployee(empId).then((item) => {
      setData(item)
    })

  }, [])

  const [checklistEmployee, setChecklistEmployee] = useState([]);
  const [emp, setEmp] = useState({});
  const [formality, setFormality] = useState({});
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState("");
  useEffect(() => {

    setChecklistEmployee(data?.employeeFormalitiesResponses);
    setEmp(data?.dashboardEmployeeResponse);
    setFormality(() => {
      return {
        hrManualExitPolicy: data?.dashboardEmployeeResponse?.hrManualExitPolicy,
        hrManualExitPolicyStatusId:
          data?.dashboardEmployeeResponse?.hrManualExitPolicyStatusId,
        resignationDate: data?.dashboardEmployeeResponse.resignationDate,
        resignationAcceptanceMailDate:
          data?.dashboardEmployeeResponse?.resignationAcceptanceMailDate,
        fnfStatus:
          data?.dashboardEmployeeResponse?.fnfStatus,
        fnfStatusId:
          data?.dashboardEmployeeResponse?.fnfStatusId,
        fnfStatusUpdatedOn:
          data?.dashboardEmployeeResponse?.fnfStatusUpdatedOn,
        exitComment:
          data?.dashboardEmployeeResponse?.exitComment,
      };
    });
    return () => { };
  }, [data]);
  const commonCheckListContainer = (categoryId) => {
    let obj = data?.employeeFormalitiesResponses.filter(item => item.categoryId == categoryId && item.value) ?? []

    return (
      <div className={style.Container}>
        <div className={style.col212}>
          <p className={style.label}>Name</p>
          {obj?.map((element, index) => {
            return (
              <p className={style.values} key={index}>
                {element?.subCategoryName}
              </p>
            );
          })}
        </div>
        <div className={style.col213}>
          <p className={style.label}>Status</p>
          {obj.map((element) => {
            return (
              <p className={style.values} key={element?.id}>
                {element.value}
              </p>
            );
          })}
        </div>
      </div>
    );
  };
  const commonFormalities = (formality) => {
    return (
      <div className={style.Container}>
        <div className={style.col212}>
          <p className={style.label}>General</p>
          <p className={style.values}>Resignation Received</p>
          <p className={style.values}>Resignation Acceptance Mail with LWD</p>
          <p className={style.values}>Share HR Manual - Exit Policy</p>
          <p className={style.values}>FnF Status</p>
          <p className={style.values}>Exit Note</p>
        </div>
        <div className={style.col213}>
          <p className={style.label}>Status</p>
          <p className={style.values}>
            {formality?.resignationDate
              ? moment(formality.resignationDate).format("DD MMM YYYY")
              : "-"}
          </p>
          <p className={style.values}>
            {formality?.resignationAcceptanceMailDate
              ? moment(formality.resignationAcceptanceMailDate).format(
                "DD MMM YYYY"
              )
              : "-"}
          </p>
          <p className={style.values}>{formality?.hrManualExitPolicy ? formality.hrManualExitPolicy : "-"}</p>
          <p className={style.values}>{formality?.fnfStatus ? formality.fnfStatus : "-"}</p>
          <p
            className={style.values}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowNotes(true);
              setNotes(formality?.exitComment);
              // setEmpId(e?.empId);
            }}
          >
            {formality?.exitComment && <CgNotes style={{ color: "#289e98" }} />}
          </p>
        </div>
      </div>
    );
  };
  return (
    <div className={style.mainSection}>
      <div style={{ width: "20%" }}>
        <SideBar />
      </div>
      <div className={style.rightSide}>
        <div className={style.upper}>
          <span>
            <NavLink to={`/ef_employeeExit`}>
              {" "}
              <span style={{ color: "blue" }}>Employee Exit </span>
            </NavLink>{" "}
            {">"} Employee Exit View
          </span>
        </div>
        {!isError && <div className={style.body}>
          <EmployeeDetails emp={emp} />
          <div className={style.editables}>
            <div className={style.employeeExitProcess}>
              {/* <div className={style.heading}>Employee Exit Process</div> */}
              <InvoiceContainer>
                {commonFormalities(formality)}
              </InvoiceContainer>
            </div>
            <div className={style.employeeExitProcess}>
              <div className={style.heading}>IT Team</div>
              <InvoiceContainer>{commonCheckListContainer(1)}</InvoiceContainer>
            </div>
            <div className={style.employeeExitProcess}>
              <div className={style.heading}>Hr Operations ( On Exit Day )</div>
              <InvoiceContainer>{commonCheckListContainer(2)}</InvoiceContainer>
            </div>
            <div className={style.employeeExitProcess}>
              <div className={style.heading}>Hr Operations After Exit ( After Exit )</div>
              <InvoiceContainer>{commonCheckListContainer(3)}</InvoiceContainer>
            </div>
            <div className={`${style.employeeExitProcess} ${style.action}`}>
              <button
                className={style.button}
                onClick={() =>
                  history.push(`/ef_employeeExit/${empId}/employeeEdit/`, {
                    checklistEmployee,
                    formality,
                    emp,
                  })
                }
              >
                Edit
              </button>
            </div>
          </div>
        </div>}
      </div>
      <ConfirmPopup open={showNotes}>
        <div className={style.confirmPopup}>
          <div>
            <p>{notes}</p>
          </div>
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <button
              className={style.delete}
              onClick={() => setShowNotes(false)}
            >
              Done
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </div>
  );
};

export default EmployeeExitView;
