import { useState } from "react";
import style from "./Star.module.css";

const OverallRating = ({ formDataSubjective, setFormDataSubjective }) => {
  const [stars, setStars] = useState(["1", "2", "3", "NS", "NE"]);

  const changeRating = (newRating) => {
    setFormDataSubjective((preData) => ({
      ...preData,
      overallRatingValue: newRating,
    }));
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "32rem",
            display: "flex",
            justifyContent: "space-between",
            margin: "-15px 0 24px 0px",
          }}
        >
          {stars.map((star) => {
            return (
              <span
                style={{
                  cursor: "pointer",
                  color: "#a15e5e",
                  width: "50rem",
                }}
                onClick={() => {
                  changeRating(star);
                }}
              >
                <div
                  className={
                    formDataSubjective === star
                      ? style.circleClick
                      : style.circleDesign
                  }
                >
                  {star === "NS" ? "NS" : star === "NE" ? "NE" : star}
                </div>
              </span>
            );
          })}
        </div>
        <p className={style.skillRating}>
          {formDataSubjective == 3
            ? "Excellent"
            : formDataSubjective == 1
            ? "Poor"
            : formDataSubjective == 2
            ? "Good"
            : formDataSubjective == "NE"
            ? "Not Evaluated"
            : formDataSubjective == "NS"
            ? "Not Skill"
            : null}
        </p>
      </div>
    </>
  );
};

export default OverallRating;
