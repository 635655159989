import axios from "axios";
import React, { useState, useEffect } from "react";
import Style from "./PoolComment.module.css";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import constants, { employeeId } from "../../../../../Utils/constants";
import { Row } from "react-bootstrap";
import Moment from "moment";

export default function ApplicationInformation(props) {
  const [skillList, setSkillList] = useState([]);
  const [commentstate, setcommentState] = useState();
  const [comments, setComments] = useState(
    props?.applicationDetail?.comment == undefined
      ? ""
      : props?.applicationDetail?.comment
  );

  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (props?.applicationDetail?.comment != undefined) {
      setComments(props?.applicationDetail?.comment);
    }
    setcommentState(props?.applicationDetail?.comment);
  }, [props?.applicationDetail]);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get("id");
  const history = useHistory();
  const baseUrl = constants.apiBaseUrlResource;

  const handleRedirect = (historyy) => {
    {
      historyy === "poolcomment"
        ? setTimeout(() => {
            history.push(`/PoolResourcesDescription`);
          }, 2000)
        : historyy === "offshorecomment"
        ? setTimeout(() => {
            history.push(`/ResourcesOffshoreDescription`);
          }, 2000)
        : setTimeout(() => {
            history.push(`/AllocationOnsiteDescription`);
          }, 2000);
    }
  };

  const onInputChange = (e) => {
    if (e.target.name === "description") {
      setComments(e.target.value);
    }
  };

  const onInputChange1 = (e) => {
    if (e.target.name === "description") {
      setcommentState(e.target.value);
    }
  };

  const getSkills = () => {
    axios
      .get(baseUrl + "/getAllSkills", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setSkillList(res.data.responseData);
      })
      .catch(() => {});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (comments?.trim().length == 0) {
      setError(true);
      return false;
    }
    if (props.applicationDetail?.comment) {
      const data = {
        comment: commentstate.trim(),
        localDateTime: Moment(props.applicationDetail?.poolModifiedDate).format(
          "YYYY-MM-DD hh:mm"
        ),
        empId: props.id,
        employeeId: employeeId,
        id: props.applicationDetail?.poolCommentId,
      };
      axios
        .post(baseUrl + "/addPoolResourceComments", data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          notify("Comment Edited Successfully");
          // handleRedirect(props?.historyy);
          setDisable(true);
          props.setShow(!props.show);
          setComments("");
        })
        .catch(() => {});
    } else {
      const data = {
        comment: comments.trim(),
        localDateTime: Moment(new Date()).format("YYYY-MM-DD hh:mm"),
        empId: props.id,
        employeeId:employeeId
      };
      axios
        .post(baseUrl + "/addPoolResourceComments", data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          notify("Comment Added Successfully");
          // handleRedirect(props?.historyy);
          setDisable(true);
          props.setShow(!props?.show);
          setComments("");
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    getSkills();
  }, []);

  const notify = (message) =>
    toast(message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  return (
    <>
      <div className={Style.projectresource_mainsection}>
        <div className={Style.maindiv}>
          <p className={Style.span2}>
            {" "}
            {props?.editHandler ? "Edit Comment" : "Add Comment"}
          </p>
          <div className={Style.maindivContent}></div>
          <form>
            {props?.editHandler && (
              <>
                <textarea
                  autoComplete="off"
                  onChange={onInputChange1}
                  className={Style.text}
                  name="description"
                  value={commentstate}
                  // defaultValue={props?.commenttype==="edit"? props.applicationDetail.comment:""}
                  style={{ backgroundColor: "#F5F6FA" }}
                ></textarea>
                {error && !comments?.trim().length ? (
                  <p className={Style.error}>Please Enter comment</p>
                ) : null}
              </>
            )}
            {props?.addhandler && (
              <>
                <textarea
                  autoComplete="off"
                  onChange={onInputChange}
                  className={Style.text}
                  name="description"
                  // value={commentstate}
                  // defaultValue={props?.commenttype==="edit"? props.applicationDetail.comment:""}
                  style={{ backgroundColor: "#F5F6FA" }}
                ></textarea>
                {error && !comments?.trim().length ? (
                  <p className={Style.error}>Please Enter comment</p>
                ) : null}
              </>
            )}
          </form>
          <br />
        </div>
      </div>

      <div className={Style.container}>
        <div className={Style.btn_div}>
          {props?.editHandler && (
            <button
              className={Style.editbtnn}
              onClick={() => props.BackHandler()}
            >
              Back
            </button>
          )}
          <button
            type="submit"
            onClick={onSubmit}
            className={Style.submitbtn}
            disabled={disable}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
