import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../sidebar/SideBar";
import style from "./Dashboard.module.css";
import { useQuery } from "react-query";
import { getAddCountsForDashboard, getfaSkillDashboard } from "../../../../Utils/axios/umsApi";
import { useHistory } from "react-router-dom";
import { path } from "../../../../routes/PathNames";
import { DataContext } from "../../../Projects_and_Resource_Master/CommonComponent/useContext/DataContext";
import * as Img from "../../../../assets/index";
const Dashboard = () => {
    const history = useHistory();
    const { data, setData } = useContext(DataContext);
    const [countData, setCountData] = useState({});
    const [activeCard, setActiveCard] = useState(data?.activeCard ? data?.activeCard : '');
    const [displayData, setDisplayData] = useState({});
    const [cards, setCards] = useState([]);
    const { data: dataCount, isLoading, isError } = useQuery(
        ["getAddCountsForDashboard"],
        getAddCountsForDashboard
    );
    const { data: cardData, isLoading: isCardLoading, isError: isCardError } = useQuery(
        ["getAddCountsForDashboard", activeCard],
        () => {
            if (activeCard !== '')
                return getfaSkillDashboard(activeCard)
            else
                return [];
        }
    );
    useEffect(() => {
        setCountData(dataCount);
    }, [dataCount])
    useEffect(() => {
        setCards(cardData);
    }, [cardData])
    const handleCountCardClick = (cardName) => {
        if (activeCard === cardName) {
            setActiveCard('')
        }
        else {
            setActiveCard(cardName)
        }
    }
    const handleActiveEmployeeClick = () => {
        history.push(path.ums_userList);
    }
    const handleCardClick = (id, keyName) => {
        const filter = {
            employeeCode: "",
            nameEmail: "",
            status: [],
            technology: [],
            employeeType: [],
            salaryRange: [],
            functionalArea: [],
            leadId: [],
            clientId: [],
            experienceRange: [],
            pfApplicable: ""
        }
        const filterData = {
            status: [],
            allocated: [],
            employeeType: [],
            salaryRange: [],
            functionalArea: [],
            leadId: [],
            clientId: [],
            experienceRange: [],
            pfApplicable: ""
        }
        if (keyName === 'technology') {
            filter[keyName] = [id];
            filterData.allocated = [id];
            filter.functionalArea = [1];
        }
        else {
            filter[keyName] = [id];
            filterData[keyName] = [id];
        }
        history.push(path.ums_userList, { 'filter': filter, 'filterData': filterData });
    }
    useEffect(() => {
        setData({ ...data, activeCard: activeCard });
        if (activeCard === 'functional') {
            setDisplayData({
                heading: 'Functional Area',
                name: 'functionalArea'
            })
        }
        else if (activeCard === 'skill') {
            setDisplayData({
                heading: 'Technology',
                name: 'technology'
            })
        }
        else {
            setDisplayData({})
        }
    }, [activeCard]);
    return (
        <div className={style.main_client_body}>
            <SideBar />
            <div className={style.main_create}>
                <div className={style.dashboard_container}>
                    <div>
                        <p className={style.heading}>Dashboard</p>
                        <div className={style.count_card_container}>
                            <div className={style.count_card}
                                onClick={handleActiveEmployeeClick}>
                                <div className={style.count_card_upper}>
                                    <img src={Img.ums_employees} className={style.count_card_img} alt='Active Employees' />
                                    <h1>{countData?.countActiveEmployees}</h1>
                                </div>
                                <div className={style.count_card_lower}>
                                    <span>Active Employees</span>
                                </div>
                            </div>
                            <div className={`${style.count_card} ${activeCard === 'functional' ? style.count_card_active : ''}`}
                                onClick={() => handleCountCardClick('functional')}>
                                <div className={style.count_card_upper}>
                                    <img src={Img.ums_department} className={style.count_card_img} alt='Functional Area' />
                                    <h1>{countData?.countFunctionalArea}</h1>
                                </div>
                                <div className={style.count_card_lower}>
                                    <span>Functional Area</span>
                                </div>
                            </div>
                            <div className={`${style.count_card} ${activeCard === 'skill' ? style.count_card_active : ''}`}
                                onClick={() => handleCountCardClick('skill')}>
                                <div className={style.count_card_upper}>
                                    <img src={Img.ums_technology} className={style.count_card_img} alt='Technlogy' />
                                    <h1>{countData?.countDeliverySkills}</h1>
                                </div>
                                <div className={style.count_card_lower}>
                                    <span>Technology</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        activeCard !== '' &&
                        <div className={style.dashboard_container_section}>
                            <p className={style.heading}>{displayData.heading}</p>
                            {isCardLoading ?
                                <div className={style.loading_container}>
                                    <p>Loading...</p>
                                </div> : isCardError ?
                                    <div className={style.error_container}>
                                        <h5>Oops! Something went wrong</h5>
                                    </div> :
                                    <div className={style.card_container}>
                                        {cards?.map(card => (
                                            <div className={style.card}
                                                onClick={() => handleCardClick(card.id, displayData.name)}>
                                                <div className={style.card_upper}>
                                                    <h1>{card.count}</h1>
                                                </div>
                                                <div className={style.card_lower}>
                                                    <span>{card.label}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
export default Dashboard;
