import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import cookie from "react-cookies";
import { toast } from "react-toastify";

const TaskTable = (props) => {
  return (
    <tr style={{ borderBottom: "2px solid #F0F0F0", textAlign: "start" }}>
      <td
        // onClick={() => props.clickHandler(props.data.poolCommentId)}
        style={{
          padding: "7px 0px",
          paddingLeft: "25px",
          fontSize: "13px",
        }}
      >
        {props?.data.taskId}
      </td>
      <td style={{ fontSize: "initial", fontSize: "13px",paddingLeft: "25px" }}>
        {props?.data?.created}
      </td>
      <td
        onClick={() => props.clickHandler(props?.data?.taskId)}
        style={{
          fontSize: "initial",
          fontSize: "13px",
          maxWidth: "18%",
          padding: "10px 0px",
          textTransform: "capitalize",
          fontWeight: "bolder",
          textDecorationLine: "underline",
          color: "rgb(252, 187, 8)",
          cursor: "pointer",
          paddingLeft: "25px",
        }}
      >
        {props?.data.title}
      </td>
      <td style={{paddingLeft: "25px"}}>{props?.data.assignToName}</td>
      <td style={{paddingLeft: "25px"}}>{props?.data.name}</td>
      <td style={{paddingLeft: "25px"}}>{props?.data.status}</td>
      <td style={{paddingLeft: "25px"}}>{props?.data.priority}</td>
      <td style={{paddingLeft: "25px"}}>{Moment(props?.data.planedEndDate).format("DD MMM YY")}</td>
      <td style={{paddingLeft: "25px"}}> {props?.data.functionalArea}</td>
    </tr>
  );
};

export default TaskTable;
