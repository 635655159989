import React from "react";
import { CreateIssueComponent } from "./component/CreateIssueCom";
import style from "./CreateIssue.module.css"
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
export const CreateIssue =()=>{
    return(<>
        <Header />
       <div className={style.mainSection}>
         <SideBar />
         <CreateIssueComponent />
       </div>
     </>)
 }
