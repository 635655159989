import axios from "axios";
const url = process.env.REACT_APP_EXIT_FORMALITIES_URL;

export const getDashboardCount= async () => {
    const response = await axios.get(url + `/get-dashboard-count`);
    return response.data.responseData;
  };
export const getDashboardEmployee= async (limit,page,data) => {
    const response = await axios.get(url + `/get-dashboard-employee?limit=${limit}&page=${page}${data.deptId?`&departmentId=${data.deptId}`:""}${data.empId?`&empId=${data.empId}`:""}${data.statusId?`&statusId=${data.statusId}`:""}${data.fnfStatusId?`&fnfStatusId=${data.fnfStatusId}`:""}${data.empId?`&empId=${data.empId}`:""}${(data.month && data.year)?`&monthYear=${data.month}${data.year}`:""}`);
    return response.data.responseData;
  };
  export const getStatus = async () => {
    const response = await axios.get(url+`/get-status`);
    return response.data.responseData;
  };
  export const getEmployeeListDropdown = async () => {
    const response = await axios.get(url+`/get-employee-list-dropdown`);
    return response.data.responseData;
  };
  export const getFormalitiesByEmployee = async (empId) => {
    const response = await axios.get(url+`/get-formalities-by-employee?empId=${empId}`);
    return response.data.responseData;
  };
  export const getEFCountByFnfStatus = async (empId) => {
    const response = await axios.get(url+`/get-count-by-fnf-status`);
    return response.data.responseData;
  };
  export const updateSubcategoriesStatus = async (data) => {
    const response = await axios.put(url + `/update-subcategories-status`, data);
    return response?.data;
  };