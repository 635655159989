
import { urlValidation } from "../../utils/validation";

export const onTotalExp = (formData) => {
  if (
    (formData?.totalExperienceMonth || formData?.totalExperienceMonth === 0) &&
    (formData?.totalExperienceYear || formData?.totalExperienceYear === 0)
  ) {
    return true;
  } else {
    return false;
  }
};
export const onRelevantExp = (formData) => {
  if (
    (formData?.relevantExperienceMonth ||
      formData?.relevantExperienceMonth === 0) &&
    (formData?.relevantExperienceYear || formData?.relevantExperienceYear === 0)
  ) {
    return true;
  } else {
    return false;
  }
};
export const linkendInUrl = (value) => {
  if (!value) {
    return true;
  } else if (urlValidation(value)) {
    return true;
  } else {
    return false;
  }
};
export const companyWebsiteInUrl = (value,year,month) => {
  if(year===0 && month===0){
    return true
  }
  else if (!value) {
    return false;
  }
  else if (urlValidation(value)) {
    return true;
  } else {
    return false;
  }
};
export const academicValidation = (formData) => {
  let status = false;
  for (let value of formData?.candidateAcademics) {
    if (value?.degree && value?.passingYear && value?.board) {
      status = true;
    } else {
      status = false;
      break;
    }
  }

  return status;
};
export const academicValidationEdit = (formData) => {
  let status = false;
  for (let value of formData?.candidateAcademics) {
    if (value?.degreeId && value?.passingYear && value?.board) {
      status = true;
    } else {
      status = false;
      break;
    }
  }
  return status;
};

export const workExperienceValidation = (presentWork, formData) => {
  let status = false;
  for (let value of formData) {
    if (value?.present === presentWork) {
      if (
        value?.companyName &&
        value?.startDate &&
        value?.role &&
        value?.reasonForChange
      ) {
        status = true;
      } else {
        status = false;
        break;
      }
    } else {
      status = true;
    }
  }
  if (formData?.length === 0) {
    status = true;
  }
  return status;
};
export const options = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];
export const negotiableOptions = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
export const checkMobileNumber = (formData) => {
  if (formData?.mobileNo) {
    if (formData?.mobileNo?.length >= 10 && formData?.mobileNo?.length <= 12) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
export const checkAlternetMobileNumber = (formData) => {
  if (formData?.alternateMobileNo) {
    if (
      formData?.alternateMobileNo?.length >= 10 &&
      formData?.alternateMobileNo?.length <= 12
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
export const checkNegotiable = (formData) => {
  return true;
  if (formData?.buyOut) {
    if (formData?.buyOutNegotiable) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const experienceInDescendingOrder = (data) => {
  if (Array.isArray(data)) {
    data.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB - dateA;
    });
  } else {
    return [];
  }

  return data;
};


export const validateExp  = (data) => {
  if(data && typeof data === 'object'){
    const totalExp = (data?.totalExperienceYear ? data?.totalExperienceYear*12 : 0) +  (data?.totalExperienceMonth ? data?.totalExperienceMonth : 0);
    const relevantExp = (data?.relevantExperienceYear ? data?.relevantExperienceYear*12 : 0) +  (data?.relevantExperienceMonth ? data?.relevantExperienceMonth : 0);
   if(!totalExp && !relevantExp){
    return true
   }
   else if(totalExp){
    if(!relevantExp) {
      return true
    }
    else if(relevantExp && totalExp >= relevantExp){
      return true
    }
   }
  }
  else{
    return true
  }
} 