import React, {  useEffect, useState } from "react";
import SideBar from "../Clients/component/SideBar";
import style from "./ResourceBySkill.module.css";
import { useQuery } from "react-query";
import loaderImg from "../../../assets/loader.gif";
import {
  getResourceBySkill,
} from "../../../Utils/axios/rmsApi";
import searchIcon from "../../../assets/Icon-search.png";
import constants, { employeeId } from "../../../Utils/constants";
import cookie from "react-cookies";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import { customSearch } from "../Clients/component/style";

const ResourceBySkill = () => {
  const [filterName, setFilterName] = useState({
    skillId: null,
    skillLabel: null
  });
  const [skillList, setSkillList] = useState([]);
  const baseUrl = constants.apiBaseUrlResource;
  const getSkills = () => {
    axios
      .get(baseUrl + "/getAllSkills", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setSkillList(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getskillList = skillList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  useEffect(() => {
    getSkills();
  }, []);

  const { data: resourceBySkillData, isLoading, refetch } = useQuery(
    ["getResourceBySkill", filterName?.skillId],
    () => getResourceBySkill(filterName?.skillId),
    { refetchOnWindowFocus: false, enabled: false }
  );

  useEffect(() => {
    if(filterName?.skillId) {
      refetch()
    }
  }, [filterName?.skillId])

  return (
    <div className={style.main_client_body}>
      <SideBar />
      <div className={style.main_create}>
        <div className={style.s1} style={{ width: "30%", margin: "1rem", cursor: "pointer" }}>
          {!filterName?.skillId ? (
            <span className={style.cp_serchIcon}>
              <img src={searchIcon} alt="img" />
            </span>
          ) : (
            <span
              className={style.cp_serchIcon}
              onClick={() => {
                setFilterName({ ...filterName, skillId: null, skillLabel: null });
              }}
            >
              <AiOutlineClose />
            </span>
          )}
          <Select
            styles={customSearch}
            classNamePrefix={"create_position"}
            placeholder="Select Skill"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={filterName?.skillId ? {value: filterName?.skillId, label: filterName?.skillLabel} : null }
            onChange={(e) => {
              setFilterName({
                ...filterName,
                skillId: e?.value,
                skillLabel: e?.label
              });
            }}
            options={getskillList}
          />
        </div>
        {isLoading ? (
          <div>
            <img
              src={loaderImg}
              alt="loader"
              style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
            />
            <p
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                paddingLeft: "38%",
                position: "absolute",
                color: "#FBBB08",
                marginTop: "-4px",
                fontStyle: "italic",
                marginLeft: "34.5%",
              }}
            >
              Loading...
            </p>
          </div>
        ) : (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {resourceBySkillData && (
              <>
                
                {resourceBySkillData?.poolCount > 0 && (
                  <>
                    <div style={{ width: "98%" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p className={style.projectHeading}>Resources (Pool)</p>
                        <span className={style.count} style={{ flexShrink: 0 }}>
                          {resourceBySkillData?.poolCount}
                        </span>
                      </div>
                      <div className={style.div}>
                        {resourceBySkillData?.poolData && (
                          <table>
                            <thead className={style.thead}>
                              <tr style={{ fontSize: "12px" }}>
                                <th className={style.th}>Name</th>
                                <th className={style.th}>Exp</th>
                                <th className={style.td21}>Salary Range</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resourceBySkillData?.poolData?.map((emp) => (
                                <tr
                                  key={emp?.name}
                                  style={{
                                    borderBottom: "1px solid #ebe5e5",
                                    fontSize: "14px",
                                    height: "2.3rem",
                                  }}
                                >
                                  <td className={style.td1} style={emp?.partialPool ? {backgroundColor: "#AAA7CC"} : null}>{emp?.empName}</td>
                                  <td className={style.td2} style={emp?.partialPool ? {backgroundColor: "#AAA7CC"} : null}>
                                    {~~(emp?.experience / 12)} Y -{" "}
                                    {emp?.experience % 12} M
                                  </td>
                                  <td
                                    className={style.td21}
                                    style={
                                      emp?.salaryRange === "Very High"
                                        ? (emp?.partialPool ? {backgroundColor:"#AAA7CC", color: "red" } : { color: "red" })
                                        : (emp?.partialPool ? {backgroundColor:"#AAA7CC"} : null)
                                    }
                                  >
                                    {emp?.salaryRange}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {resourceBySkillData?.finalStageCandidateCount > 0 && (
                  <>
                    <div style={{ width: "98%" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p className={style.projectHeading}>Final Stages</p>
                        <span className={style.count} style={{ flexShrink: 0 }}>
                          {resourceBySkillData?.finalStageCandidateCount}
                        </span>
                      </div>
                      <div className={style.div}>
                        {resourceBySkillData.finalStageCandidateData && (
                          <table>
                            <thead className={style.thead}>
                              <tr style={{ fontSize: "12px" }}>
                                <th className={style.th}>Name</th>
                                <th className={style.th}>Exp</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resourceBySkillData.finalStageCandidateData?.map(
                                (emp) => (
                                  <tr
                                    key={emp?.name}
                                    style={{
                                      borderBottom: "1px solid #ebe5e5",
                                      fontSize: "14px",
                                      height: "2.3rem",
                                    }}
                                  >
                                    <td className={style.td1}>
                                      {emp?.empName}
                                    </td>
                                    <td className={style.td2}>
                                      {~~(emp?.experience / 12)} Y -{" "}
                                      {emp?.experience % 12} M
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {resourceBySkillData?.offshoreCount > 0 && (
                  <>
                    <div style={{ width: "98%" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p className={style.projectHeading}>
                          Resources (Offshore)
                        </p>
                        <span className={style.count} style={{ flexShrink: 0 }}>
                          {resourceBySkillData?.offshoreCount}
                        </span>
                      </div>
                      <div className={style.div}>
                        {resourceBySkillData?.offshoreData && (
                          <table>
                            <thead className={style.thead}>
                              <tr style={{ fontSize: "12px" }}>
                                <th className={style.th}>Name</th>
                                <th className={style.th}>Exp</th>
                                <th className={style.td21}>Salary Range</th>
                                <th className={style.th}>Project Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resourceBySkillData?.offshoreData?.map((emp) => (
                                <tr
                                  key={emp?.name}
                                  style={{
                                    borderBottom: "1px solid #ebe5e5",
                                    fontSize: "14px",
                                    height: "2.3rem",
                                  }}
                                >
                                  <td className={style.td1} style={emp?.partialPool ? {backgroundColor: "#AAA7CC"} : null}>{emp?.empName}</td>
                                  <td className={style.td2} style={emp?.partialPool ? {backgroundColor: "#AAA7CC"} : null}>
                                    {~~(emp?.experience / 12)} Y -{" "}
                                    {emp?.experience % 12} M
                                  </td>
                                  <td
                                    className={style.td21}
                                    style={
                                      emp?.salaryRange === "Very High"
                                        ? (emp?.partialPool ? {backgroundColor:"#AAA7CC", color: "red" } : { color: "red" })
                                        : (emp?.partialPool ? {backgroundColor:"#AAA7CC"} : null)
                                    }
                                  >
                                    {emp?.salaryRange}
                                  </td>
                                  <td className={style.th} style={emp?.partialPool ? {backgroundColor: "#AAA7CC"} : null}>
                                    {emp?.projectName}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {resourceBySkillData?.onsiteCount > 0 && (
                  <>
                    <div style={{ width: "98%" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p className={style.projectHeading}>Resources (Onsite)</p>
                        <span className={style.count} style={{ flexShrink: 0 }}>
                          {resourceBySkillData?.onsiteCount}
                        </span>
                      </div>
                      <div className={style.div}>
                        {resourceBySkillData?.onsiteData && (
                          <table>
                            <thead className={style.thead}>
                              <tr style={{ fontSize: "12px" }}>
                                <th className={style.th}>Name</th>
                                <th className={style.th}>Exp</th>
                                <th className={style.td21}>Salary Range</th>
                                <th className={style.th}>Client Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resourceBySkillData?.onsiteData?.map((emp) => (
                                <tr
                                  key={emp.name}
                                  style={{
                                    borderBottom: "1px solid #ebe5e5",
                                    fontSize: "14px",
                                    height: "2.3rem",
                                  }}
                                >
                                  <td className={style.td1} style={emp?.partialPool ? {backgroundColor: "#AAA7CC"} : null}>{emp?.empName}</td>
                                  <td className={style.td2} style={emp?.partialPool ? {backgroundColor: "#AAA7CC"} : null}>
                                    {~~(emp?.experience / 12)} Y -{" "}
                                    {emp?.experience % 12} M
                                  </td>
                                  <td
                                    className={style.td21}
                                    style={
                                      emp?.salaryRange === "Very High"
                                        ? (emp?.partialPool ? {backgroundColor:"#AAA7CC", color: "red" } : { color: "red" })
                                        : (emp?.partialPool ? {backgroundColor:"#AAA7CC"} : null)
                                    }
                                  >
                                    {emp?.salaryRange}
                                  </td>
                                  <td className={style.th} style={emp?.partialPool ? {backgroundColor: "#AAA7CC"} : null}>
                                    {emp?.projectName}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default ResourceBySkill;
