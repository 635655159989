import React, { useEffect, useState } from "react";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import Pagination from "../../commonComponent/Pagination/Pagination";
import style from "./ASEmployeeListComponent.module.css";
import AppraisalButton from "../../commonComponent/Button/AppraisalButton/AppraisalButton";
import moment from "moment";
import Modal from "react-responsive-modal";
import { customStylesforApp } from "../../commonComponent/style";
import searchIcon from "../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import {
  addEmployeesToAppraisalCycle,
  getAllEmployees,
  getDepartmentList,
  getEmployeeList,
  getOpenAppraisalCycle,
  addEmployeeToAppraisalCycle,
  getCurrentAndAllPastCycle,
} from "../../../../Utils/axios/AppraisalApi";
import { useMutation, useQuery } from "react-query";
import { customSearchACEL } from "./style";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify";
import AppraisalLoader from "../../commonComponent/Loader/AppraisalLoader";

const ASEmployeeListComponent = () => {
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [render, setRender] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openCycles, setOpenCycles] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [cycleList, setCycleList] = useState([]);
  const [employeeListFilterObj, setEmployeeListFilterObj] = useState([]);
  const [appraisalCycleId, setAppraisalCycleId] = useState(null);
  const [checkedEmployeeIds, setCheckedEmployeeIds] = useState([]);
  const [filter, setFilter] = useState({
    deptId: null,
    employeeId: null,
    appraisalCycleId: null,
    dojfilter: true,
  });
  const [totalCount, setTotalCount] = useState(0);

  const [selectedEmp, setSelectedEmp] = useState(null);
  const [selectedDept, setSelectedDept] = useState(null);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [activeEmp, setActiveEmp] = useState(false);
  const [activeDept, setActiveDept] = useState(false);
  const [activeCycle, setActiveCycle] = useState(false);
  const [filterToggle, setFilterToggle] = useState(false);

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const {
    data,
    isLoading,
    refetch: refetchEmployees,
  } = useQuery(
    ["getAllEmployees", filter, currentPage],
    () => getAllEmployees(50, currentPage, filter),
    {
      onError: () => {
        notifyError("Something went wrong");
      },
      retry: false,
      refetchOnMount: true,
    }
  );

  const { data: openCyclesList } = useQuery(["openCycleList"], () =>
    getOpenAppraisalCycle()
  );
  const { data: departments } = useQuery(
    ["departments"],
    () => getDepartmentList(),
    { refetchOnWindowFocus: false }
  );
  const { data: employeeListFilter } = useQuery(
    ["employeeListFilter"],
    () => getEmployeeList(),
    { refetchOnWindowFocus: false }
  );
  const { data: cycles } = useQuery(
    ["getAllAppraisalCycles"],
    () => getCurrentAndAllPastCycle(),
    { refetchOnWindowFocus: false }
  );
  useEffect(() => {
    setEmployees(
      data?.employees.map((emp) => {
        return { ...emp, showDropdown: false };
      })
    );
    setPageCount(data?.totalCount / 50);
    setTotalCount(data?.totalCount && data.totalCount);
  }, [data]);
  useEffect(() => {
    setOpenCycles(
      openCyclesList?.length > 0 &&
      openCyclesList.map((openCycle) => {
        return {
          value: openCycle.id,
          label: `${openCycle.appraisalCycleTitle} ${openCycle?.cycleType && `(${openCycle.cycleType})`
            }`,
        };
      })
    );
  }, [openCyclesList]);
  useEffect(() => {
    setDepartmentList(
      departments?.map((department) => {
        return { value: department.id, label: department.name };
      })
    );
  }, [departments]);
  useEffect(() => {
    setCycleList(
      cycles?.map((cycle) => {
        return { value: cycle.id, label: cycle.appraisalCycleTitle };
      })
    );
  }, [cycles]);
  useEffect(() => {
    setEmployeeListFilterObj(
      employeeListFilter?.map((employee) => {
        return {
          value: employee.id,
          label: `${employee.name} (${employee.employeeCode})`,
        };
      })
    );
  }, [employeeListFilter]);
  const type = showCheckbox ? "submit" : "plus";

  const handleAppraisalButton = (type) => {
    switch (type) {
      case "submit":
        if (checkedEmployeeIds.length === 0) return;
        setShowCheckbox(false);
        openModal();
        break;
      case "showCheckbox":
        setShowCheckbox(true);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (employeeId) => {
    if (checkedEmployeeIds.includes(employeeId)) {
      setCheckedEmployeeIds(
        checkedEmployeeIds.filter((id) => id !== employeeId)
      );
    } else {
      setCheckedEmployeeIds([...checkedEmployeeIds, employeeId]);
    }
  };

  const handleIndividaulSubmit = (e, action) => {
    let eid = Number(e.target.id);
    addEmployeeToAppraisalCycleMutation.mutate({
      action: action,
      id: eid,
    });
  };
  const handleCancel = (e) => {
    let eid = Number(e.target.id);

    employees.map((emp) => {
      if (emp.id == eid) {
        emp.showDropdown = false;
        return emp;
      }
      return emp;
    });
    setRender(!render);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCheckedEmployeeIds([]);
  };
  const handleSubmit = () => {
    if (!appraisalCycleId?.value) return;
    addEmployeesToAppraisalCycleMutation.mutate({
      action: 1,
      appraisalCycleId: appraisalCycleId.value,
      employeeIds: checkedEmployeeIds,
    });
    closeModal();
  };
  // All Post APIs
  const addEmployeeToAppraisalCycleMutation = useMutation(
    addEmployeeToAppraisalCycle,
    {
      onMutate: () => { },
      onSuccess: (res) => {
        // Refetch the cycles data after a successful mutation
        refetchEmployees();
        if (res.responseStatus.statusCode === 0) {
          notifySuccess(res.responseData);
        } else {
          notifyError(res.responseData.message);
        }
      },
      onError: () => {
        notifyError("Something went wrong");
      },
    }
  );
  const addEmployeesToAppraisalCycleMutation = useMutation(
    addEmployeesToAppraisalCycle,
    {
      onMutate: () => { },
      onSuccess: (res) => {
        // Refetch the cycles data after a successful mutation
        refetchEmployees();
        if (res.responseStatus.statusCode === 0) {
          notifySuccess(res.responseData);
        } else {
          notifyError(res.responseData.message);
        }
      },
      onError: (err) => { },
    }
  );

  const handleChangeEmp = (selectedOption, type) => {
    setSelectedEmp(selectedOption);
    switch (type) {
      case "search":
        setActiveEmp(true);
        setCurrentPage(1);
        setFilter({ ...filter, employeeId: selectedOption.value });
        break;
      case "close":
        setActiveEmp(false);
        setFilter({ ...filter, employeeId: null });
        break;
      default:
        break;
    }
  };
  const handleChangeDepartment = (selectedOption, type) => {
    setSelectedDept(selectedOption);
    switch (type) {
      case "search":
        setActiveDept(true);
        setCurrentPage(1);
        setFilter({ ...filter, deptId: selectedOption.value });
        break;
      case "close":
        setActiveDept(false);
        setFilter({ ...filter, deptId: null });
        break;
      default:
        break;
    }
  };
  const handleChangeCycle = (selectedOption, type) => {
    setSelectedCycle(selectedOption);
    switch (type) {
      case "search":
        setActiveCycle(true);
        setCurrentPage(1);
        setFilter({ ...filter, appraisalCycleId: selectedOption.value });
        break;
      case "close":
        setActiveCycle(false);
        setFilter({ ...filter, appraisalCycleId: null });
        break;
      default:
        break;
    }
  };
  const toggleChange = () => {
    setFilterToggle(!filterToggle);
    setFilter((prev) => {
      return { ...prev, dojfilter: !filter.dojfilter };
    });
  };

  return (
    <>
      <div>
        <div className={style.upper}>
          <div className={style.pp}>
            Employees List
            <div className={style.totalCount}>{totalCount}</div>
          </div>
          <div className={style.filterBox}>
            <div className={style.searchContainer}>
              {!activeEmp ? (
                <span className={style.cp_serchIcon}>
                  <img src={searchIcon} alt="searchIcon" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon}
                  onClick={() => {
                    handleChangeEmp(null, "close");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Space") {
                      handleChangeEmp(null, "close");
                    }
                  }}
                  tabIndex={0} // Make the <span> focusable
                  aria-label="Close Search" // Provide an accessible name
                >
                  <AiOutlineClose />
                </span>
              )}
              <Select
                styles={customSearchACEL}
                classNamePrefix={"create_position"}
                placeholder="EmpName/Code"
                value={selectedEmp}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={employeeListFilterObj}
                onChange={(selectedOption) =>
                  handleChangeEmp(selectedOption, "search")
                }
              />
            </div>

            <div className={style.searchContainer}>
              {!activeDept ? (
                <span className={style.cp_serchIcon}>
                  <img src={searchIcon} alt="searchIcon" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon}
                  onClick={() => {
                    handleChangeDepartment(null, "close");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Space") {
                      handleChangeDepartment(null, "close");
                    }
                  }}
                  tabIndex={0} // Make the <span> focusable
                  aria-label="Close Search" // Provide an accessible name
                >
                  <AiOutlineClose />
                </span>
              )}
              <Select
                styles={customSearchACEL}
                // className="custom-select"
                classNamePrefix={"create_position"}
                placeholder="Department"
                value={selectedDept}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={departmentList}
                onChange={(selectedOption) =>
                  handleChangeDepartment(selectedOption, "search")
                }
              />
            </div>
            <div className={style.searchContainer}>
              {!activeCycle ? (
                <span className={style.cp_serchIcon}>
                  <img src={searchIcon} alt="searchIcon" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon}
                  onClick={() => {
                    handleChangeCycle(null, "close");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Space") {
                      handleChangeCycle(null, "close");
                    }
                  }}
                  tabIndex={0} // Make the <span> focusable
                  aria-label="Close Search" // Provide an accessible name
                >
                  <AiOutlineClose />
                </span>
              )}
              <Select
                styles={customSearchACEL}
                // className="custom-select"
                classNamePrefix={"create_position"}
                placeholder="Cycle"
                value={selectedCycle}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={cycleList}
                onChange={(selectedOption) =>
                  handleChangeCycle(selectedOption, "search")
                }
              />
            </div>
          </div>

          <div className={style.filteralign}>
            <div title="Add to Appraisal Cycle">
              <AppraisalButton
                type={type}
                onClick={() =>
                  handleAppraisalButton(
                    showCheckbox ? "submit" : "showCheckbox"
                  )
                }
              >
                {!showCheckbox ? "Add To Appraisal" : "Submit"}
              </AppraisalButton>
            </div>
          </div>
        </div>
        {isLoading ? (
          <AppraisalLoader />
        ) : (
          <div className={style.EmpList}>
            <table className={style.scrollable}>
              <thead className={style.tableHeader}>
                <tr className={style.th1}>
                  {showCheckbox && (
                    <th className={` ${style.tableContent2}`}>Select</th>
                  )}
                  <th className={style.tableContent2}>Code</th>
                  <th className={style.tableContent2}></th>
                  <th className={style.tableContent2}>Name</th>
                  <th className={style.tableContent2}>Department</th>
                  <th className={style.tableContent2}>
                    {" "}
                    <div style={{ display: "inline" }}>DOJ</div>
                    <div
                      style={{
                        display: "inline",
                        marginLeft: "1px",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleChange()}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Space") {
                          toggleChange();
                        }
                      }}
                      tabIndex={0} // Make the <span> focusable
                      aria-label="Sorting By DOJ" // Provide an accessible name
                    >
                      {filterToggle ? (
                        <BiUpArrowAlt
                          style={{
                            fontSize: "1.1rem",
                            cursor: "pointer",
                            color: "blue",
                          }}
                        />
                      ) : (
                        <BiDownArrowAlt
                          style={{
                            fontSize: "1.1rem",
                            cursor: "pointer",
                            color: "blue",
                          }}
                        />
                      )}
                    </div>
                  </th>
                  <th className={style.tableContent2}>Last Cycle</th>
                  <th className={style.tableContent2}>Applicable From</th>
                  <th className={style.tableContent2}>Current Cycle</th>
                  <th className={style.tableContent2}>Add to Cycle</th>
                </tr>
              </thead>
              <tbody>
                {employees?.map((emp) => {
                  const rowClass = `${style.inputRow} ${emp.nextAppraisalCycle && style.present
                    }`;

                  //  Checkbox
                  const renderCheckbox = () => {
                    if (emp.nextAppraisalCycle) {
                      return null;
                    }

                    return (
                      <input
                        className={style.input_checkbox}
                        type="checkbox"
                        name="addToAppraisal"
                        id={emp.id}
                        onChange={() => handleCheckboxChange(emp.id)}
                      />
                    );
                  };

                  // Employee Name
                  const renderEmployeeName = () => {
                    if (emp.name && emp.name.length > 20) {
                      const abbreviatedName = `${emp.name.slice(0, 18)}...`;
                      return <p title={emp.name}>{abbreviatedName}</p>;
                    } else {
                      return <p>{emp.name}</p>;
                    }
                  };


                  return (
                    <tr className={rowClass} key={emp.id}>
                      {showCheckbox && (
                        <td className={style.showCheckbox}>
                          {renderCheckbox()}
                        </td>
                      )}
                      <td className={style.code}>
                        <NavLink
                          to={`/ASEmployeeList/EmployeeInformation/${emp.id}`}
                        >
                          <p id={emp.id} className={style.code}>
                            {emp.employeeCode}
                          </p>
                        </NavLink>
                      </td>
                      <td>
                        {
                          emp?.resigned ? <span
                            style={{
                              color: "rgb(168, 50, 50)",
                              border: "2px solid rgb(168, 50, 50)",
                              borderRadius: "999px",
                              padding: "2px 8px",
                              marginLeft: "5px",
                            }}
                          >
                            R
                          </span> : <span></span>
                        }
                      </td>
                      <td>{renderEmployeeName()}</td>
                      <td>{emp.department}</td>
                      <td>{emp?.dateOfJoining && moment(emp?.dateOfJoining).format("DD MMM YY")}</td>
                      <td>
                        <div className={style.nextCycle}>
                          {emp.lastAppraisalCycle
                            ? emp.lastAppraisalCycle
                            : "-"}
                          {emp.lastAppraisalCycle && (
                            <div className={style.typeOfPastAppraisal}>
                              {emp.lastCycleAction === "Reward"
                                ? "R"
                                : emp.lastCycleAction === "Promotion"
                                  ? "P"
                                  : "A"}
                            </div>
                          )}
                        </div>
                      </td>
                      <td>{emp?.applicableDate && moment(emp?.applicableDate).format("DD MMM YY")}</td>
                      <td>
                        <div className={style.nextCycle}>
                          {emp.nextAppraisalCycle
                            ? emp.nextAppraisalCycle
                            : "-"}
                          {emp.nextAppraisalCycle && (
                            <div className={style.typeOfAppraisal}>
                              {emp.nextCycleAction === "Reward"
                                ? "R"
                                : emp.nextCycleAction === "Promotion"
                                  ? "P"
                                  : "A"}
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        {emp.nextAppraisalCycle && null}
                        {!emp.nextAppraisalCycle ? (
                          !emp.showDropdown ? (
                            <div className={style.actionButtons}>
                              <button
                                id={emp.id}
                                className={style.appr_btn}
                                onClick={(e) =>
                                  handleIndividaulSubmit(e, "Appraisal")
                                }
                                title="Nominate For Appraisal"
                              >
                                +
                              </button>
                              <button
                                id={emp.id}
                                className={style.appr_btn}
                                onClick={(e) =>
                                  handleIndividaulSubmit(e, "Reward")
                                }
                                title="Nominate For Reward"
                              >
                                R
                              </button>
                              <button
                                id={emp.id}
                                className={style.appr_btn}
                                onClick={(e) =>
                                  handleIndividaulSubmit(e, "Promotion")
                                }
                                title="Nominate For Promotion"
                              >
                                P
                              </button>
                            </div>
                          ) : (
                            <div className={style.inlineDropdown}>
                              <div className={style.select}>
                                <select
                                  name="Add to Cycle DropDown"
                                  onChange={(e) =>
                                    setAppraisalCycleId(e.target.value)
                                  }
                                >
                                  {openCycles?.map((item) => (
                                    <option
                                      value={item?.value}
                                      key={item.value}
                                    >
                                      {item.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <button id={emp.id} className={style.appr_btn}>
                                Submit
                              </button>
                              <button
                                id={emp.id}
                                className={style.appr_btn}
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                            </div>
                          )
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {!isLoading && (
          <div className={style.pagination}>
            <Pagination
              pagesCount={pageCount}
              pageSize="50"
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>
      {/* Modal For Adding to Cycle */}
      <Modal
        open={modalIsOpen}
        onRequestClose={closeModal}
        center
        onClose={closeModal}
        showCloseIcon={false}
        className={style.modal}
      >
        <div className={style.modal}>
          <h6 style={{ textAlign: "center" }}>Add To Cycle</h6>
          <div className={style.dropdown}>
            <div className={style.select}>
              <Select
                styles={customStylesforApp}
                options={openCycles}
                maxMenuHeight={200}
                value={appraisalCycleId}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={(appraisalCycleId) =>
                  setAppraisalCycleId(appraisalCycleId)
                }
              />
            </div>

            <div className={style.button}>
              <AppraisalButton type="submit" onClick={handleSubmit}>
                Submit
              </AppraisalButton>
            </div>
          </div>
        </div>

        {/* </div> */}
      </Modal>
    </>
  );
};

export default ASEmployeeListComponent;
