import React from "react";
import SideBar from "../commonComponent/SideBar";
import Header from "../commonComponent/Header";
import { DashboardCom } from "./component/DashboardCom";
import style from "./DashBoad.module.css"
export const HrHelpDeskDashboard =()=>{
    return(<>
       <Header />
      <div className={style.mainSection}>
        <SideBar />
        <DashboardCom />
      </div>
    </>)
}