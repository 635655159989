
import QuestionnaireSidebar from "../Pages/ClientQuestionnaire/sidebar/SideBar.js";
import { QuestionListt } from "../Pages/ClientQuestionnaire/QuestionList/QuestionList.js";
import { QuestionAdd } from "../Pages/ClientQuestionnaire/CreateQuestionnaire/CreateQuestionnaire.js";
import { QuestionEdit } from "../Pages/ClientQuestionnaire/EditQuestionnaire/EditQuestionnaire.js";
import { QuestionByPosition } from "../Pages/ClientQuestionnaire/QuestionByPosition/QuestioByPosition.js";
import { QuestionBySkills } from "../Pages/ClientQuestionnaire/QuestionBySkills/QuestionBySkills.js";
import { ViewQuestionnaireBySkill } from "../Pages/ClientQuestionnaire/ViewQuestionnaireBySkills/ViewQuestionnaireBySkill.js";
import { ViewQuestionnaireByPosition } from "../Pages/ClientQuestionnaire/ViewQuestionnaireByPosition/ViewQuestionnaireByPosition.js";

// function ClientQuestionRoute() {
//   return (
//     <Route>
//       <Route exact path="/ViewQuestionnaire" component={ViewQuestionnaire} />
//       <Route exact path="/Questionnaire" component={QuestionnaireSidebar} />
//       <Route exact path="/QuestionList" component={QuestionListt} />
//       <Route exact path="/EditQuestionnaire" component={QuestionEdit} />
//       <Route exact path="/QuestionByPostion" component={QuestionByPosition} />
//       <Route exact path="/QuestionBySkill" component={QuestionBySkills} />
//       <Route
//         exact
//         path="/ViewByPosition"
//         component={ViewQuestionnaireByPosition}
//       />
//       <Route exact path="/ViewBySkills" component={ViewQuestionnaireBySkill} />
//       <Route exact path="/AddQuestionnaire" component={QuestionAdd} />
//     </Route>
//   );
// }
const ClientQuestionRoute = [
  // {
  //   path: "/ViewQuestionnaire",
  //   component: ViewQuestionnaire
  // },
  {
    path: "/Questionnaire",
    component: QuestionnaireSidebar
  },
  {
    path: "/QuestionList",
    component: QuestionListt
  },
  {
    path: "/EditQuestionnaire",
    component: QuestionEdit
  },
  {
    path: "/QuestionByPostion",
    component: QuestionByPosition
  },
  {
    path: "/QuestionBySkill",
    component: QuestionBySkills
  },
  {
    path: "/ViewByPosition",
    component: ViewQuestionnaireByPosition
  },
  {
    path: "/ViewBySkills",
    component: ViewQuestionnaireBySkill
  },
  {
    path: "/AddQuestionnaire",
    component: QuestionAdd
  }
];

export default ClientQuestionRoute;
