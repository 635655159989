import React, { useState, useEffect } from "react";
import style from "./NewJoinee.module.scss";
import SideBar from "../../sidebar/SideBar";
import { getAllType, getNewJoiners } from "../../../../Utils/axios/umsApi";
import { useQuery } from "react-query";
import moment from "moment";
import CustomTooltip from "../../../atsDashboard/customTooltip/CustomTooltip";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { getLastThreeMonths } from "../../../../Utils/constants";
import CommonDropdown from "../../../../GenericComponent/ui/CommonDropdown";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { path } from "../../../../routes/PathNames";
import TotalCountUMS from "../commonComponent/TotalCountUMS";

function NewJoinee() {
  const [filter, setFilter] = useState({
    month: "",
    currentPage: 1,
    type: "",
  });
  const [newJoinees, setNewJoinees] = useState([]);
  const [sortActive, setSortActive] = useState("Priority");
  const [empCode, setEmpCode] = useState(false);

  const history = useHistory();

  const { data, isLoading, isError } = useQuery(["getNewJoiners", filter], () =>
    getNewJoiners(filter?.currentPage, filter?.month, filter?.type)
  );
  useEffect(() => {
    setNewJoinees(data?.employeeResponseModels);
  }, [data]);
  const { data: getAllTypeData } = useQuery(["getAllType"], () => getAllType());
  const getAllTypeOption =
    getAllTypeData?.length > 0
      ? getAllTypeData?.map((e) => ({
          value: e.id,
          label: e.name,
        }))
      : [];
  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push(path.ums_userDescription, { id });
    }, 0);
  };
  const renderTooltip = (value, index, limit = 15) => (
    <CustomTooltip
      value={value}
      index={index}
      limit={limit}
      tooltipstyle={{ top: "-20px", left: "10px" }}
    />
  );

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setFilter({ ...filter, currentPage: filter?.currentPage + 1 });
    } else if (status === "prev") {
      setFilter({ ...filter, currentPage: filter?.currentPage - 1 });
    }
  };
  const empCodeDescending = () => {
    let newItem = [...data?.employeeResponseModels];
    newItem = newItem.sort((a, b) => {
      if (a?.technology === undefined) {
        return 1;
      } else if (b?.technology === undefined) {
        return -1;
      } else {
        return a?.technology - b?.technology;
      }
    });
    newItem = newItem?.sort((a, b) => {
      if (a.technology < b.technology) {
        return -1;
      }
    });
    setNewJoinees(newItem);
    setEmpCode(true);
  };
  const empCodeAscending = () => {
    let newItem = [...data?.employeeResponseModels];
    newItem = newItem.sort((a, b) => {
      if (a?.technology === undefined) {
        return 1;
      } else if (b.technology === undefined) {
        return -1;
      } else {
        return b.technology - a?.technology;
      }
    });
    setNewJoinees(newItem);
    setEmpCode(false);
  };

  const selectedMonth = getLastThreeMonths().find(
    (month) => month.id === parseInt(filter.month)
  );

  return (
    <div className={style.main_client_body}>
      <SideBar />
      <div className={style.main_create}>
        <div className={style.topFlex}>
          <div className={style.title}>
            <div>New Joinees</div>
            <TotalCountUMS totalCount={data?.allCount} />
          </div>
          <div className={style.dropdownContainer}>
            <div className={style.dropdownContainer1}>
              <CommonDropdown
                color="#289e98"
                value={
                  getAllTypeOption.find((e) => e.value === filter.type) ?? ""
                }
                onChange={(selectedOption) =>
                  setFilter({
                    ...filter,
                    type: selectedOption.value,
                    currentPage: 1,
                  })
                }
                options={getAllTypeOption}
                placeholder="Select Type"
                customInputOnClick={() =>
                  setFilter({
                    ...filter,
                    type: "",
                  })
                }
              />
            </div>
            <div className={style.dropdownContainer1}>
              <CommonDropdown
                color="#289e98"
                value={selectedMonth ?? ""}
                onChange={(selectedOption) =>
                  setFilter({
                    ...filter,
                    month: `${selectedOption.id}`,
                    currentPage: 1,
                  })
                }
                options={getLastThreeMonths()}
                placeholder="Select Month"
                customInputOnClick={() =>
                  setFilter({
                    ...filter,
                    month: "",
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className={style.UsersList}>
          <div className={style.th}>
            <p>Emp Code</p>
            <p>Type</p>
            <p>Name</p>
            <p>
              Technology
              {/* {empCode ? (
                <BiUpArrowAlt
                  onClick={() => {
                    empCodeAscending();
                    setSortActive("empCode");
                  }}
                  style={
                    sortActive === "empCode"
                      ? {
                          fontSize: "1.1rem",
                          cursor: "pointer",
                          color: "blue",
                        }
                      : {
                          fontSize: "1.1rem",
                          cursor: "pointer",
                        }
                  }
                />
                ) : (
                <BiDownArrowAlt
                  onClick={() => {
                    empCodeDescending();
                    setSortActive("empCode");
                  }}
                  style={
                    sortActive === "empCode"
                      ? {
                          fontSize: "1.1rem",
                          cursor: "pointer",
                          color: "blue",
                        }
                      : {
                          fontSize: "1.1rem",
                          cursor: "pointer",
                        }
                  }
                />
              )} */}
            </p>
            <p>Additional Skill</p>
            <p>Date of Joining</p>
          </div>
          {isLoading && <div style={{ textAlign: "center" }}>Loading...</div>}
          {isError && (
            <div style={{ textAlign: "center" }}>Something Went Wrong</div>
          )}
          {!isLoading &&
            !isError &&
            (newJoinees?.length > 0 ? (
              newJoinees?.map(
                ({
                  id,
                  type,
                  code,
                  name,
                  technology,
                  additionalSkill,
                  dateOfJoining,
                }) => {
                  return (
                    <div
                      className={style.td}
                      key={id}
                      style={{ position: "relative" }}
                    >
                      <p
                        onClick={() => handleRedirect(id)}
                        style={{
                          color: "#289e98",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        {code}
                      </p>

                      {renderTooltip(type, `type${id}`, 20)}
                      {renderTooltip(name, `name${id}`, 25)}
                      <p>{technology}</p>

                      {/* additionalSkill -> {additionSkillId, additionalSkillName} */}
                      {renderTooltip(
                        additionalSkill?.length > 0 &&
                          additionalSkill
                            ?.map(
                              ({ additionalSkillName }) => additionalSkillName
                            )
                            .join(", "),
                        `additionalSkill${id}`,
                        30
                      )}

                      <p>
                        {dateOfJoining &&
                          moment(dateOfJoining).format("DD MMM YY")}
                      </p>
                    </div>
                  );
                }
              )
            ) : (
              <div style={{ textAlign: "center" }}>No Data</div>
            ))}
        </div>

        <div style={{ marginRight: "29px" }}>
          <Pagination
            pagesCount={data?.allCount / 10}
            onPageChange={handlePageChange}
            currentPage={filter?.currentPage}
            color="#289e98"
            color2="white"
          />
        </div>
      </div>
    </div>
  );
}

export default NewJoinee;
