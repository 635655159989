import React from "react";
import style from "./Checkbox.module.css";

const CheckBox = (props) => {
  console.log("propsjshdgj", props);
  function removeA(arr) {
    var what,
      a = arguments,
      L = a?.length,
      ax;
    while (L > 1 && arr?.length) {
      what = a[--L];
      while ((ax = arr?.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }

  const handleCheckbox = (arg) => {
    if (props.type === "clientStatus") {
      props.filterdata1.status.includes(arg) === false
        ? props.filterdata1.status.push(arg)
        : removeA(props.filterdata1.status, arg);
      props.change(props.value);
    } else if (props.type === "clientName") {
      props.filterdata1.clientId.includes(arg) === false
        ? props.filterdata1.clientId.push(arg)
        : removeA(props.filterdata1.clientId, arg);
      props.change(props.value);
    } else if (props.type === "projectClientName") {
      props.filterdata1.clientId.includes(arg) === false
        ? props.filterdata1.clientId.push(arg)
        : removeA(props.filterdata1.clientId, arg);
      props.change(props.value);
    } else if (props.type === "projectId") {
      props.filterdata1.statusTypeId.includes(arg) === false
        ? props.filterdata1.statusTypeId.push(arg)
        : removeA(props.filterdata1.statusTypeId, arg);
      props.change(props.value);
    } else if (props.type === "projectCategory") {
      props.filterdata1.catogaryprojectId.includes(arg) === false
        ? props.filterdata1.catogaryprojectId.push(arg)
        : removeA(props.filterdata1.catogaryprojectId, arg);
      props.change(props.value);
    } else if (props.type === "empOffshore") {
      props.filterdata1.empId.includes(arg) === false
        ? props.filterdata1.empId.push(arg)
        : removeA(props.filterdata1.empId, arg);
      props.change(props.value);
    } else if (props.type === "offShoreSkill") {
      props.filterdata1.skillId.includes(arg) === false
        ? props.filterdata1.skillId.push(arg)
        : removeA(props.filterdata1.skillId, arg);
      props.change(props.value);
    } else if (props.type === "partialOffshoreAllocation") {
      props.filterdata1.bandwidths.includes(arg) === false
        ? props.filterdata1.bandwidths.push(arg)
        : removeA(props.filterdata1.bandwidths, arg);
      props.change(props.value);
    } else if (props.type === "partialOffshoreSalaryRange") {
      props.filterdata1.salaryRange.includes(arg) === false
        ? props.filterdata1.salaryRange.push(arg)
        : removeA(props.filterdata1.salaryRange, arg);
      props.change(props.value);
    } else if (props.type === "partialOffshoreTechnology") {
      props.filterdata1.skillId.includes(arg) === false
        ? props.filterdata1.skillId.push(arg)
        : removeA(props.filterdata1.skillId, arg);
      props.change(props.value);
    } else if (props.type === "offShoreProject") {
      props.filterdata1.projectId.includes(arg) === false
        ? props.filterdata1.projectId.push(arg)
        : removeA(props.filterdata1.projectId, arg);
      props.change(props.value);
    } else if (props.type === "offShoreAllocation") {
      props.filterdata1.bandwidths.includes(arg) === false
        ? props.filterdata1.bandwidths.push(arg)
        : removeA(props.filterdata1.bandwidths, arg);
      props.change(props.value);
    } else if (props.type === "offShoreAction1") {
      props.filterdata1?.action.includes(arg) === false
        ? props.filterdata1?.action?.push(arg)
        : removeA(props.filterdata1?.action, arg);
      props.change(props.value);
    } else if (props.type === "deployendMonths") {
      props.filterdata1?.endDateMonth.includes(arg) === false
        ? props.filterdata1?.endDateMonth.push(arg)
        : removeA(props.filterdata1?.endDateMonth, arg);
      props.change(props.value);
    } else if (props.type === "deploymentstartMonths") {
      props.filterdata1?.startDateMonth.includes(arg) === false
        ? props.filterdata1?.startDateMonth.push(arg)
        : removeA(props.filterdata1?.startDateMonth, arg);
      props.change(props.value);
    } else if (props.type === "employeeStatus") {
      props.filterdata1?.employeeStatus?.includes(arg) === false
        ? props.filterdata1?.employeeStatus?.splice(0, 1, arg)
        : props.filterdata1?.employeeStatus?.splice(0, 1, null);
      props.change(props.value);
    } else if (props.type === "offshoreemployeeStatus") {
      props.filterdata1?.employeeStatus?.includes(arg) === false
        ? props.filterdata1?.employeeStatus?.splice(0, 1, arg)
        : props.filterdata1?.employeeStatus?.splice(0, 1, null);
      props.change(props.value);
    } else if (props.type === "offshoreemployeeStatus1") {
      props.filterdata1?.employeeStatus?.includes(arg) === false
        ? props.filterdata1?.employeeStatus?.splice(1, 1, arg)
        : props.filterdata1?.employeeStatus?.splice(1, 1, null);
      props.change(props.value);
    } else if (props.type === "employeeStatus1") {
      props.filterdata1?.employeeStatus?.includes(arg) === false
        ? props.filterdata1?.employeeStatus?.splice(1, 1, arg)
        : props.filterdata1?.employeeStatus?.splice(1, 1, null);
      props.change(props.value);
    } else if (props.type === "offShoreAction") {
      props.filterdata1?.action.includes(arg, 1) === false
        ? props.filterdata1?.action?.push(arg)
        : removeA(props.filterdata1.action, arg);
      props.change(props.value);
    } else if (props.type === "poolStatus") {
      props.filterdata1.statusId.includes(arg) === false
        ? props.filterdata1.statusId.push(arg)
        : removeA(props.filterdata1.statusId, arg);
      props.change(props.value);
    } else if (props.type === "onSiteClient") {
      props.filterdata1.clientId.includes(arg) === false
        ? props.filterdata1.clientId.push(arg)
        : removeA(props.filterdata1.clientId, arg);
      props.change(props.value);
    } else if (props.type === "onSiteEmp") {
      props.filterdata1.employeeId.includes(arg) === false
        ? props.filterdata1.employeeId.push(arg)
        : removeA(props.filterdata1.employeeId, arg);
      props.change(props.value);
    } else if (props.type === "employeeType") {
      props.filterdata1.partners.includes(arg) === false
        ? props.filterdata1.partners.push(arg)
        : removeA(props.filterdata1.partners, arg);
      props.change(props.value);
    } else if (props.type === "offShoreEmptype") {
      props.filterdata1.partners.includes(arg) === false
        ? props.filterdata1.partners.push(arg)
        : removeA(props.filterdata1.partners, arg);
      props.change(props.value);
    } else if (props.type === "onSiteEmpSkills") {
      props.filterdata1.skill.includes(arg) === false
        ? props.filterdata1.skill.push(arg)
        : removeA(props.filterdata1.skill, arg);
      props.change(props.value);
    } else if (props.type === "deploymentQuester") {
      props.filterdata1?.quaterlyDeployment?.includes(arg) === false
        ? props.filterdata1?.quaterlyDeployment?.push(arg)
        : removeA(props.filterdata1.quaterlyDeployment, arg);
      props.change(props.value);
    } else if (props.type === "deploymentEndMonths") {
      props.filterdata1?.quaterlyEndOfDeployment?.includes(arg) === false
        ? props.filterdata1?.quaterlyEndOfDeployment?.push(arg)
        : removeA(props.filterdata1.quaterlyEndOfDeployment, arg);
      props.change(props.value);
    } else if (props.type === "salespersonFilter") {
      props.filterdata1?.salesPersonId?.includes(arg) === false
        ? props.filterdata1?.salesPersonId?.push(arg)
        : removeA(props.filterdata1.salesPersonId, arg);
      props.change(props.value);
    } else if (props.type === "poolTechnology") {
      props.filterdata1.skillId.includes(arg) === false
        ? props.filterdata1.skillId.push(arg)
        : removeA(props.filterdata1.skillId, arg);
      props.change(props.value);
    } else if (props.type === "poolEmp") {
      props.filterdata1.empId.includes(arg) === false
        ? props.filterdata1.empId.push(arg)
        : removeA(props.filterdata1.empId, arg);
      props.change(props.value);
    } else if (props.type === "poolSalaryRange") {
      props.filterdata1.salaryRange.includes(arg) === false
        ? props.filterdata1.salaryRange.push(arg)
        : removeA(props.filterdata1.salaryRange, arg);
      props.change(props.value);
    } else if (props.type === "OffshorSalaryRange") {
      props.filterdata1.salaryRange.includes(arg) === false
        ? props.filterdata1.salaryRange.push(arg)
        : removeA(props.filterdata1.salaryRange, arg);
      props.change(props.value);
    } else if (props.type === "OnsiteSalaryRange") {
      props.filterdata1.salaryRange.includes(arg) === false
        ? props.filterdata1.salaryRange.push(arg)
        : removeA(props.filterdata1.salaryRange, arg);
      props.change(props.value);
    } else if (props.type === "poolExpRange") {
      props.filterdata1.experienceRange.includes(arg) === false
        ? props.filterdata1.experienceRange.push(arg)
        : removeA(props.filterdata1.experienceRange, arg);
      props.change(props.value);
    }
  };
  return (
    <li
      style={{
        paddingTop: "10px",
        fontSize: "18px",
        color:
          props.userList?.skillId === "Assigned"
            ? "#FCB902"
            : props.userList?.skillId === "Done"
              ? "#65A15E"
              : props.userList?.skillId === "Onhold"
                ? "#F94244"
                : props.userList?.skillId === "Inprogress"
                  ? "#163bbe"
                  : "#000000",
        opacity: ".7",
        fontWeight: "600",
      }}
    >
      <label className={style.main}>
        <input
          type="checkbox"
          className={style.one}
          defaultChecked={props?.value?.includes(props?.userList.skillId)}
          onClick={() => handleCheckbox(props.userList?.skillId)}
        />
        {props.userList.coreSkillName?.length > 23
          ? props.userList.coreSkillName?.slice(0, 23) + "..."
          : props.userList.coreSkillName}
        <span className={style.geekmark}></span>
      </label>
    </li>
  );
};
export default CheckBox;
