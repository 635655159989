import Modal from "react-responsive-modal";
import style from "./ConfirmModalOffer.module.css"

const ConfirmModalOffer = ({showConfirmModal, setShowConfirmModal, callFunction, action}) => {
  return (
    <Modal
        open={showConfirmModal}
        onClose={()=>setShowConfirmModal(false)}
        showCloseIcon={false}
        center
    >
        <div className={style.confirmHead}>Are you sure you want to {action} this offer?</div>

        <div className={style.confirmBtns}>
            <button className={style.confirmNoBtn} onClick={()=>setShowConfirmModal(false)}>No</button>
            <button className={style.confirmYesBtn} onClick={()=>callFunction()}>Yes</button>
        </div>
    </Modal>
  )
}

export default ConfirmModalOffer