import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import BreadCrum from "../../../breadcrums/BreadCrum";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import Select from "react-select";
import calender from "../../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import { InvoiceStyles, InvoiceStylesVendorName } from "../../../utils/style";
import dropdownIcon from "../../../../../assets/downIcon.png";
import { FaTrash } from "react-icons/fa";
import cancel from "../../../../../assets/cancell.png";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import style from "./CreateInvoice.module.css";
import moment from "moment/moment";
import { Col, Modal, Row } from "react-bootstrap";
import UploadMultiFile from "../../../uploadMultipleFile/UploadMultiFile.js";

import {
  createInvoice,
  getServiceType,
  getCurrencyType,
  getAdvanceStatus,
  getMonth,
  getYearData,
  createClient,
  getProjectsByClientId,
  getMilestoneByProjectId,
  getCandidateListInvoice,
  getClientGst,
  getInvoiceId,
  copyInvoice,
  getInvoiceTypeList,
  getClientName,
  uploadExpInvFile,
  deleteExpInvFile,
} from "../../../../../Utils/axios/expenceApi.js";
import { useQuery, useMutation } from "react-query";
import { notifyError } from "../../../../atsDashboard/utils/notify.js";
const CreateInvoice = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [lineItemArray, setLineItemArray] = useState([1]);

  const { data: invoiceTypeList } = useQuery(
    ["getServiceType"],
    getServiceType
  );

  const invoiceTypeOption = invoiceTypeList?.map((e) => ({
    id: e.id,
    label: e.serviceType,
    value: e?.serviceType,
  }));

  const { data: currencyData } = useQuery(["getCurrencyType"], getCurrencyType);
  const currencyOption = currencyData?.map((e) => ({
    value: e.id,
    label: e.currencyName,
  }));

  const { data: invoiceListData } = useQuery(
    ["getInvoiceTypeList"],
    getInvoiceTypeList
  );
  const invoiceListOption = invoiceListData?.map((e) => ({
    id: e?.id,
    label: e?.name,
    value: e?.name,
  }));

  const [formData, setFormData] = useState({
    copyOfInvoice: false,
    clientName: "",
    clientId: "",
    serviceMonth: "",
    serviceMonthId: "",
    otherCharges: "",
    discount: "",
    documentResponses: [],
    serviceYear: "",
    serviceYearId: "",
    serviceTypeId: invoiceTypeOption ? invoiceTypeOption[0]?.id : "",
    projectTypeName: invoiceTypeOption ? invoiceTypeOption[0]?.label : "",
    invoiceTypeId: invoiceListOption ? invoiceListOption[0]?.id : "",
    invoiceTypeName: invoiceListOption ? invoiceListOption[0]?.label : "",
    projectId: "",
    projectName: "",
    milestoneId: "",
    milestoneName: "",
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    advanceStatusId: "",
    advanceStatusName: "",
    currencyId: 2,
    currencyName: "Indian Rupees",
    conversionRate: 1,
    gstNumber: "",
    invoiceLineItemDetails: [
      {
        id: "",
        candidateName: "",
        billingRate: "",
        workingDays: "",
        maxWorkingDay: "",
        amount: "",
        milestoneName: "",
        milestoneTimeFrame: "",
        milestoneProjectPercentage: "",
        sno: 1,
        description: "",
        calculativeBaseAmount: "",
      },
    ],
    gstApplicable: false,
    baseAmountInvoice: "",
    baseAmountINR: "",
    totalInvoiceAmount: "",
    igst: "",
    cgst: "",
    sgst: "",
    gstTotal: "",
    totalBill: "",
    tds: "",
    amountToBeReceived: "",
  });

  const { data: invoiceDataByEditId } = useQuery(
    ["invoiceDataByEditId", prevData?.invoiceId],
    () => {
      if (prevData?.page2 === "editInvoice") {
        return getInvoiceId(prevData?.invoiceId);
      }
    }
  );

  useEffect(() => {
    if (invoiceDataByEditId && prevData?.page2 === "editInvoice") {
      setFormData((prevVal) => ({
        ...prevVal,
        clientId: invoiceDataByEditId?.clientId,
        clientName: invoiceDataByEditId?.clientName,
        serviceMonth: invoiceDataByEditId?.serviceMonth,
        serviceMonthId: invoiceDataByEditId?.serviceMonthId,
        serviceYear: invoiceDataByEditId?.serviceYear,
        otherCharges: invoiceDataByEditId?.otherCharges,
        discount: invoiceDataByEditId?.discount,
        documentResponses: invoiceDataByEditId?.documentResponses?.map(item=>item?.documentURL),
        serviceYearId: invoiceDataByEditId?.serviceYearId,
        serviceTypeId: invoiceDataByEditId?.serviceTypeId,
        projectTypeName: invoiceDataByEditId?.projectTypeName,
        invoiceTypeId: invoiceDataByEditId?.invoiceTypeId,
        invoiceTypeName: invoiceDataByEditId?.invoiceTypeName,
        projectId: invoiceDataByEditId?.projectId,
        milestoneId: invoiceDataByEditId?.milestoneId,
        projectName: invoiceDataByEditId?.projectName,
        invoiceNumber: invoiceDataByEditId?.invoiceNumber,
        invoiceDate: invoiceDataByEditId?.invoiceDate,
        dueDate: invoiceDataByEditId?.dueDate,
        advanceStatusId: invoiceDataByEditId?.advanceStatusId,
        advanceStatusName: invoiceDataByEditId?.advanceStatus,
        currencyId: invoiceDataByEditId?.currencyId,
        currencyName: invoiceDataByEditId?.currency,
        conversionRate: invoiceDataByEditId?.conversionRate,
        gstNumber: invoiceDataByEditId?.gstNumber,
        invoiceLineItemDetails: invoiceDataByEditId?.invoiceLineItemDetails
          ? invoiceDataByEditId?.invoiceLineItemDetails?.map((item) => {
            return {
              id: item?.candidateId,
              candidateName: item?.candidateName,
              billingRate: item?.billingRate,
              workingDays: item?.workingDays,
              maxWorkingDay: item?.maxWorkingDay,
              amount: item?.baseAmount,
              milestoneName: item?.milestoneName,
              milestoneAmount: item?.milestoneAmount,
              milestoneTimeFrame: item?.milestoneTimeFrame,
              milestoneProjectPercentage: item?.milestoneProjectPercentage,
              sno: item?.sno,
              description: item?.description,
              calculativeBaseAmount: item?.calculativeBaseAmount,
            };
          })
          : [
            {
              id: "",
              candidateName: "",
              billingRate: "",
              workingDays: "",
              maxWorkingDay: "",
              amount: "",
              milestoneAmount: "",
              milestoneName: "",
              milestoneTimeFrame: "",
              milestoneProjectPercentage: "",
              sno: 1,
              description: "",
              calculativeBaseAmount: "",
            },
          ],
        totalInvoiceAmount: invoiceDataByEditId?.totalInvoiceAmount,
        amountToBeReceived: invoiceDataByEditId?.amountToBeReceived,
        gstApplicable: invoiceDataByEditId?.gstApplicable,
        baseAmountInvoice: invoiceDataByEditId?.baseAmountInvoice,
        igst: invoiceDataByEditId?.igst,
        cgst: invoiceDataByEditId?.cgst,
        sgst: invoiceDataByEditId?.sgst,
        gstTotal: invoiceDataByEditId?.totalGst,
        totalBill: invoiceDataByEditId?.totalBilled,
        tds: invoiceDataByEditId?.tds,
      }));
    }
  }, [prevData?.page2, invoiceDataByEditId]);
  const [validate, setValidate] = React.useState(false);
  const [show, setShow] = useState(false);
  const [ClientAdd, setAddClient] = useState();

  const { data: GetCategoryYear, refetch } = useQuery(
    ["getYearData"],
    getYearData
  );

  const GetCategoryYearOption = GetCategoryYear?.map((e) => ({
    value: e.id,
    label: e.year,
    year1: e?.year1,
    year2: e?.year2,
  }));

  const { data: copyInvoiceData } = useQuery(
    ["copyInvoiceById", formData?.clientId],
    () => {
      if (prevData?.page2 === "createInvoice" && formData?.clientId) {
        return copyInvoice(formData?.clientId);
      }
    }
  );

  useEffect(() => {
    if (
      formData?.copyOfInvoice === true &&
      prevData?.page2 === "createInvoice" &&
      copyInvoiceData
    ) {
      setFormData((prevVal) => ({
        ...prevVal,
        copyOfInvoice: prevVal?.copyOfInvoice,
        clientId: copyInvoiceData?.clientId,
        clientName: copyInvoiceData?.clientName,
        serviceMonth: copyInvoiceData?.serviceMonth,
        serviceMonthId: copyInvoiceData?.serviceMonthId,
        serviceYear: copyInvoiceData?.serviceYear,
        otherCharges: copyInvoiceData?.otherCharges,
        discount: copyInvoiceData?.discount,
        documentResponses: copyInvoiceData?.documentResponses?.map(item=>item?.documentURL),
        serviceYearId: copyInvoiceData?.serviceYearId,
        serviceTypeId: copyInvoiceData?.serviceTypeId,
        projectTypeName: copyInvoiceData?.projectTypeName,
        invoiceTypeId: copyInvoiceData?.invoiceTypeId,
        invoiceTypeName: copyInvoiceData?.invoiceTypeName,
        projectId: copyInvoiceData?.projectId,
        milestoneId: copyInvoiceData?.milestoneId,
        projectName: copyInvoiceData?.projectName,
        invoiceNumber: copyInvoiceData?.invoiceNumber,
        invoiceDate: copyInvoiceData?.invoiceDate,
        dueDate: copyInvoiceData?.dueDate,
        advanceStatusId: copyInvoiceData?.advanceStatusId,
        advanceStatusName: copyInvoiceData?.advanceStatus,
        currencyId: copyInvoiceData?.currencyId,
        currencyName: copyInvoiceData?.currency,
        conversionRate: copyInvoiceData?.conversionRate,
        gstNumber: copyInvoiceData?.gstNumber,
        invoiceLineItemDetails: copyInvoiceData?.invoiceLineItemDetails
          ? copyInvoiceData?.invoiceLineItemDetails?.map((item) => {
            return {
              id: item?.candidateId,
              candidateName: item?.candidateName,
              billingRate: item?.billingRate,
              workingDays: item?.workingDays,
              maxWorkingDay: item?.maxWorkingDay,
              amount: item?.baseAmount,
              milestoneName: item?.milestoneName,
              milestoneAmount: item?.milestoneAmount,
              milestoneTimeFrame: item?.milestoneTimeFrame,
              milestoneProjectPercentage: item?.milestoneProjectPercentage,
              sno: item?.sno,
              description: item?.description,
              calculativeBaseAmount: item?.calculativeBaseAmount,
            };
          })
          : [
            {
              id: "",
              candidateName: "",
              billingRate: "",
              workingDays: "",
              maxWorkingDay: "",
              amount: "",
              milestoneAmount: "",
              milestoneName: "",
              milestoneTimeFrame: "",
              milestoneProjectPercentage: "",
              sno: 1,
              description: "",
              calculativeBaseAmount: "",
            },
          ],
        totalInvoiceAmount: copyInvoiceData?.totalInvoiceAmount,
        amountToBeReceived: copyInvoiceData?.amountToBeReceived,
        gstApplicable: copyInvoiceData?.gstApplicable,
        baseAmountInvoice: copyInvoiceData?.baseAmountInvoice,
        igst: copyInvoiceData?.igst,
        cgst: copyInvoiceData?.cgst,
        sgst: copyInvoiceData?.sgst,
        gstTotal: copyInvoiceData?.totalGst,
        totalBill: copyInvoiceData?.totalBilled,
        tds: copyInvoiceData?.tds,
      }));
    }
  }, [copyInvoiceData, formData?.copyOfInvoice]);

  useEffect(() => {
    refetch();
  }, [formData?.clientId, refetch]);

  const { data: clientData } = useQuery(["getClientList"], getClientName);
  const clientOption = clientData?.map((e) => ({
    value: e?.id,
    label: e?.clientName,
  }));

  const { data: statusData } = useQuery(["getAdvanceStatus"], getAdvanceStatus);
  const statusOption = statusData?.map((e) => ({
    value: e.id,
    label: e.status,
  }));

  const { data: GetMonthData } = useQuery(["getMonth"], getMonth);
  const GetMonthoption = GetMonthData?.map((e) => ({
    value: e.id,
    label: e.month,
  }));

  const { data: clientGst } = useQuery(
    ["clientGst", formData?.clientId],
    () => {
      if (formData?.clientId) {
        return getClientGst(formData?.clientId);
      }
    }
  );

  useEffect(() => {
    if (clientGst?.gstNumber) {
      setFormData((prev) => ({
        ...prev,
        gstNumber: clientGst?.gstNumber,
      }));
    }
  }, [clientGst?.gstNumber]);

  const { data: projectList } = useQuery(
    ["getProjectList", formData?.clientId],
    () => {
      if (formData?.clientId) {
        return getProjectsByClientId({
          clientId: [formData?.clientId],
        });
      }
      return;
    }
  );

  const getProjectOption = projectList?.map((e) => ({
    id: e?.projectid,
    value: e?.projectName,
    label: e?.projectName,
  }));

  const { data: milestoneList } = useQuery(
    ["getMilestoneList", formData?.projectId],
    () => {
      if (formData?.projectId) {
        return getMilestoneByProjectId(formData?.projectId);
      }
      return;
    }
  );

  const milestoneOption = milestoneList?.map((e) => ({
    id: e?.id,
    label: e?.name,
    value: e?.name,
    timeframe: e?.timeFrame,
    percentage: e?.projectPercentage,
    amount: e?.amount,
  }));

  const fullWidthSelect = {
    control: (style, state) => ({
      ...style,
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: state.isDisabled ? "#999" : "f5f6fa",
      backgroundColor: state.isDisabled ? "#999" : "#f5f6fa",
      opacity: state.isDisabled ? "0.2" : "1",
      border: "none",
      minHeight: "38px",
      outline: "none",
      width: "100%",
      cursor: "pointer",
    }),
    singleValue: () => ({
      maxWidth: "80%",
      color: "hsl(0, 0%, 20%)",
      gridArea: "1/1/2/3",
      marginLeft: "2px",
      marginRight: "2px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      boxSizing: "border-box",
    }),

    placeholder: () => ({
      display: "none",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#855ea1",
      },
      backgroundColor: state.isSelected ? "#855ea1" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: "999",
    }),
  };

  const mutation = useMutation(createInvoice, {});

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
          alt=""
        />
      </div>
    );
  });
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const mutation1 = useMutation(createClient, {});
  const uploadFileMutation = useMutation(uploadExpInvFile, {
    onSuccess: (data) => {
      setFormData({
        ...formData,
        documentResponses: [...formData.documentResponses, ...data],
      })
    },
    onError: () => {
      notifyError('File Upload failed');
    },
  });
  const handleFileChange = (e) => {
    uploadFileMutation.mutate(e.target.files);
  };
  const handleFileDelete = (fileURL) => {
    deleteExpInvFile(fileURL);
    setFormData({
      ...formData,
      documentResponses: formData.documentResponses.filter(selectedURL => selectedURL !== fileURL),
    })
  }

  const AddClient = {
    companyName: ClientAdd,
    partnerName: "",
  };

  const handleDelete = () => {
    setShow(false);
    mutation1.mutate(AddClient);
  };

  const handleDeleteCancle = () => {
    setShow(false);
  };

  const lineItemDetailsFn = (item) => {
    let status = true;

    if (formData?.serviceTypeId === 1) {
      for (let val of item) {
        if (
          val?.candidateName === "" ||
          val?.billingRate === "" ||
          val?.workingDays === "" ||
          val?.maxWorkingDay === "" ||
          val?.amount === ""
        ) {
          status = true;
          break;
        } else {
          status = false;
          break;
        }
      }
    } else if (formData?.serviceTypeId === 3) {
      for (let val of item) {
        if (
          val?.sno === "" ||
          val?.amount === null ||
          val?.description === ""
        ) {
          status = true;
          break;
        } else {
          status = false;
          break;
        }
      }
    }

    if (status) {
      setValidate(true);
      return false;
    } else {
      return true;
    }
  };

  const CreateInvoiceHandler = () => {
    if (
      formData?.clientId &&
      formData?.serviceMonthId &&
      formData?.serviceYearId &&
      formData?.serviceTypeId &&
      formData?.invoiceTypeId &&
      formData?.invoiceNumber &&
      formData?.invoiceDate &&
      formData?.dueDate &&
      formData?.advanceStatusId &&
      formData?.currencyId &&
      formData?.conversionRate &&
      formData?.gstNumber
    ) {
      if (formData?.serviceTypeId === 1) {
        if (lineItemDetailsFn(formData?.invoiceLineItemDetails)) {
          let payload = {
            copyOfInvoice: formData?.copyOfInvoice,
            clientId: formData?.clientId,
            serviceMonthId: formData?.serviceMonthId,
            serviceMonth: formData?.serviceMonth,
            serviceYear: formData?.serviceYear,
            serviceYearId: formData?.serviceYearId,
            serviceTypeId: formData?.serviceTypeId,
            otherCharges: formData?.otherCharges,
            discount: formData?.discount,
            documentURL: formData?.documentResponses,
            invoiceTypeId: formData?.invoiceTypeId,
            invoiceTypeName: formData?.invoiceTypeName,
            invoiceNumber: formData?.invoiceNumber,
            invoiceDate: formData?.invoiceDate,
            dueDate: formData?.dueDate,
            totalInvoiceAmount: formData?.totalInvoiceAmount,
            advanceStatusId: formData?.advanceStatusId,
            currencyId: formData?.currencyId,
            conversionRate: formData?.conversionRate,
            gstNumber: formData?.gstNumber,
            invoiceLineItemDetails: formData?.invoiceLineItemDetails?.map(
              (item) => {
                return {
                  candidateId: item?.id,
                  billingRate: Number(item?.billingRate),
                  workingDays: Number(item?.workingDays),
                  maxWorkingDay: item?.maxWorkingDay,
                  amount: Number(item?.amount),
                  calculativeBaseAmount: Number(item?.calculativeBaseAmount),
                };
              }
            ),
            gstApplicable: formData?.gstApplicable,
            amountToBeReceived: Number(formData?.amountToBeReceived),
          };
          if (formData?.gstApplicable === true) {
            payload.baseAmountInvoice = Number(formData?.baseAmountInvoice);
            payload.igst = Number(formData?.igst);
            payload.cgst = Number(formData?.cgst);
            payload.sgst = Number(formData?.sgst);
            payload.totalGst = Number(formData?.gstTotal);
            payload.totalBilled = Number(formData?.totalBill);
            payload.tds = Number(formData?.tds);
          }
          if (prevData?.page2 === "editInvoice") {
            payload.id = prevData?.invoiceId;
          }
          setNextScreen("invoiceList");
          setValidate(false);
          mutation.mutate(payload);
        } else {
          setValidate(true);
        }
      } else if (formData?.serviceTypeId === 2) {
        if (formData?.projectId && formData?.milestoneId) {
          let payload = {
            copyOfInvoice: formData?.copyOfInvoice,
            clientId: formData?.clientId,
            serviceMonth: formData?.serviceMonth,
            serviceYear: formData?.serviceYear,
            serviceMonthId: formData?.serviceMonthId,
            serviceYearId: formData?.serviceYearId,
            serviceTypeId: formData?.serviceTypeId,
            invoiceTypeId: formData?.invoiceTypeId,
            otherCharges: formData?.otherCharges,
            discount: formData?.discount,
            documentURL: formData?.documentResponses,
            invoiceTypeName: formData?.invoiceTypeName,
            invoiceNumber: formData?.invoiceNumber,
            invoiceDate: formData?.invoiceDate,
            totalInvoiceAmount: formData?.totalInvoiceAmount,
            dueDate: formData?.dueDate,
            projectId: formData?.projectId,
            projectName: formData?.projectName,
            milestoneId: formData?.milestoneId,
            advanceStatusId: formData?.advanceStatusId,
            currencyId: formData?.currencyId,
            conversionRate: formData?.conversionRate,
            gstNumber: formData?.gstNumber,
            invoiceLineItemDetails: formData?.invoiceLineItemDetails?.map(
              (item) => {
                return {
                  milestoneName: item?.milestoneName,
                  milestoneTimeFrame: item?.milestoneTimeFrame,
                  milestoneProjectPercentage: item?.milestoneProjectPercentage,
                  milestoneAmount: Number(item?.milestoneAmount),
                  calculativeBaseAmount: Number(item?.calculativeBaseAmount),
                };
              }
            ),
            gstApplicable: formData?.gstApplicable,
            amountToBeReceived: Number(formData?.amountToBeReceived),
          };
          if (formData?.gstApplicable === true) {
            payload.baseAmountInvoice = Number(formData?.baseAmountInvoice);
            payload.igst = Number(formData?.igst);
            payload.cgst = Number(formData?.cgst);
            payload.sgst = Number(formData?.sgst);
            payload.totalGst = Number(formData?.gstTotal);
            payload.totalBilled = Number(formData?.totalBill);
            payload.tds = Number(formData?.tds);
          }
          if (prevData?.page2 === "editInvoice") {
            payload.id = prevData?.invoiceId;
          }
          setNextScreen("invoiceList");
          setValidate(false);
          mutation.mutate(payload);
        } else {
          setValidate(true);
        }
      } else if (formData?.serviceTypeId === 3) {
        if (lineItemDetailsFn(formData?.invoiceLineItemDetails)) {
          let payload = {
            copyOfInvoice: formData?.copyOfInvoice,
            clientId: formData?.clientId,
            serviceMonth: formData?.serviceMonth,
            serviceYear: formData?.serviceYear,
            serviceMonthId: formData?.serviceMonthId,
            serviceYearId: formData?.serviceYearId,
            serviceTypeId: formData?.serviceTypeId,
            invoiceTypeId: formData?.invoiceTypeId,
            otherCharges: formData?.otherCharges,
            discount: formData?.discount,
            invoiceTypeName: formData?.invoiceTypeName,
            totalInvoiceAmount: formData?.totalInvoiceAmount,
            invoiceNumber: formData?.invoiceNumber,
            invoiceDate: formData?.invoiceDate,
            dueDate: formData?.dueDate,
            advanceStatusId: formData?.advanceStatusId,
            currencyId: formData?.currencyId,
            conversionRate: formData?.conversionRate,
            gstNumber: formData?.gstNumber,
            invoiceLineItemDetails: formData?.invoiceLineItemDetails?.map(
              (item) => {
                return {
                  sno: item?.sno,
                  description: item?.description,
                  amount: Number(item?.amount),
                  calculativeBaseAmount: Number(item?.calculativeBaseAmount),
                };
              }
            ),
            gstApplicable: formData?.gstApplicable,
            amountToBeReceived: Number(formData?.amountToBeReceived),
          };
          if (formData?.gstApplicable === true) {
            payload.baseAmountInvoice = Number(formData?.baseAmountInvoice);
            payload.igst = Number(formData?.igst);
            payload.cgst = Number(formData?.cgst);
            payload.sgst = Number(formData?.sgst);
            payload.totalGst = Number(formData?.gstTotal);
            payload.totalBilled = Number(formData?.totalBill);
            payload.tds = Number(formData?.tds);
          }
          if (prevData?.page2 === "editInvoice") {
            payload.id = prevData?.invoiceId;
          }
          setNextScreen("invoiceList");
          setValidate(false);
          mutation.mutate(payload);
        } else {
          setValidate(true);
        }
      }
    } else {
      setValidate(true);
    }
  };

  const HandlingData = (data) => {
    switch (data) {
      case "January":
        return 1;
      case "February":
        return 2;
      case "March":
        return 3;
      case "April":
        return 4;
      case "May":
        return 5;
      case "June":
        return 6;
      case "July":
        return 7;
      case "August":
        return 8;
      case "September":
        return 9;
      case "October":
        return 10;
      case "November":
        return 11;
      case "December":
        return 12;
      default:
        return data;
    }
  };

  const getDaysInMonth = (year, month) => {
    month = HandlingData(month);
    year = year && year?.split("-")[0];
    const date = new Date(year, month, 0);
    return date.getDate();
  };

  const handleServiceMonthChange = (e, index) => {
    let day = null;
    let temp = [...formData?.invoiceLineItemDetails];
    if (e?.label && formData?.serviceYear) {
      day = getDaysInMonth(formData?.serviceYear, e?.label);
      for (let i = 0; i < index; i++) {
        temp[i].maxWorkingDay = day;
        temp[i].workingDays = "";
      }
    }
    formData.serviceMonth = e?.label;
    formData.serviceMonthId = e?.value - 1;
    formData.serviceYear = "";
    formData.serviceYearId = "";
    setFormData({
      ...formData,
      invoiceLineItemDetails: temp,
    });
  };

  const handleServiceYearChange = (e, index) => {
    let temp = [...formData?.invoiceLineItemDetails];
    let day = null;
    if (formData?.serviceMonth && e?.label) {
      day = getDaysInMonth(e?.label, formData?.serviceMonth);
      for (let i = 0; i < index; i++) {
        temp[i].maxWorkingDay = day;
        temp[i].workingDays = "";
      }
    }
    formData.serviceYear = e?.label;
    if ([0, 1, 2].includes(formData.serviceMonthId)) {
      formData.serviceYearId = e?.year2;
    } else {
      formData.serviceYearId = e?.year1;
    }
    setFormData({ ...formData, invoiceLineItemDetails: temp });
  };

  const { data: getCandidateList } = useQuery(
    [
      "getCandidateList",
      formData?.clientId,
      formData?.serviceMonthId,
      formData?.serviceYearId,
    ],
    () => {
      if (
        formData?.clientId &&
        formData?.serviceMonthId &&
        formData?.serviceYearId
      ) {
        return getCandidateListInvoice(
          formData?.clientId,
          formData?.serviceMonthId,
          formData?.serviceYearId
        );
      }
    }
  );

  const getCandidateOption = getCandidateList?.map((e) => ({
    id: e?.candidateId,
    label: e?.candidateName,
  }));

  const calculateAmount = (e, idx) => {
    if (
      formData?.invoiceLineItemDetails[idx]?.billingRate &&
      formData?.invoiceLineItemDetails[idx]?.workingDays &&
      formData?.invoiceLineItemDetails[idx]?.maxWorkingDay
    ) {
      const temp = [...formData?.invoiceLineItemDetails];
      const workingDays = formData?.invoiceLineItemDetails[idx]?.workingDays;
      const maxWorkingDay =
        formData?.invoiceLineItemDetails[idx]?.maxWorkingDay;
      const billingRate = formData?.invoiceLineItemDetails[idx]?.billingRate;
      const totalAmount = (workingDays / maxWorkingDay) * billingRate;

      temp[idx].amount = Number(totalAmount)?.toFixed(2);

      temp[idx].calculativeBaseAmount = Number(
        temp[idx]?.amount * formData?.conversionRate
      )?.toFixed(2);

      calculateGst(temp);
    }
  };

  const calculateGst = (temp) => {
    let gstNo = clientGst?.gstNumber;
    const getConvertedAmount = temp?.map((item) =>
      Number(item?.calculativeBaseAmount)
    );

    const totalInvoiceAmount = getConvertedAmount?.reduce(
      (acc, currValue) => acc + currValue
    );

    setFormData({
      ...formData,
      totalInvoiceAmount: Number(totalInvoiceAmount)?.toFixed(2),
      baseAmountInvoice: Number(totalInvoiceAmount)?.toFixed(2),
    });
    formData.totalInvoiceAmount = Number(totalInvoiceAmount)?.toFixed(2);
    formData.baseAmountInvoice = Number(totalInvoiceAmount)?.toFixed(2);

    if (!gstNo?.startsWith("09")) {
      setFormData({
        ...formData,
        igst: Number((formData?.baseAmountInvoice * 18) / 100)?.toFixed(2),
        cgst: 0,
        sgst: 0,
        gstTotal: Number((formData?.baseAmountInvoice * 18) / 100)?.toFixed(2),
      });

      formData.igst = Number((formData?.baseAmountInvoice * 18) / 100)?.toFixed(
        2
      );
      formData.cgst = 0;
      formData.sgst = 0;
      formData.gstTotal = Number(
        (formData?.baseAmountInvoice * 18) / 100
      )?.toFixed(2);
    } else {
      setFormData({
        ...formData,
        igst: 0,
        cgst: Number((formData?.baseAmountInvoice * 9) / 100)?.toFixed(2),
        sgst: Number((formData?.baseAmountInvoice * 9) / 100)?.toFixed(2),
        gstTotal: Number(
          ((formData?.baseAmountInvoice * 9) / 100) * 2
        )?.toFixed(2),
      });

      formData.igst = 0;
      formData.cgst = Number((formData?.baseAmountInvoice * 9) / 100)?.toFixed(
        2
      );
      formData.sgst = Number((formData?.baseAmountInvoice * 9) / 100)?.toFixed(
        2
      );
      formData.gstTotal = Number(
        ((formData?.baseAmountInvoice * 9) / 100) * 2
      )?.toFixed(2);
    }

    setFormData({
      ...formData,
      totalBill: (
        Number(formData?.baseAmountInvoice) + Number(formData?.gstTotal)
      )?.toFixed(2),
    });
    formData.totalBill = (
      Number(formData?.baseAmountInvoice) + Number(formData?.gstTotal)
    )?.toFixed(2);

    setFormData({
      ...formData,
      tds: Number((formData?.baseAmountInvoice * 10) / 100)?.toFixed(2),
    });
    formData.tds = Number((formData?.baseAmountInvoice * 10) / 100)?.toFixed(2);

    if (formData?.gstApplicable === false) {
      setFormData({
        ...formData,
        amountToBeReceived: (
          Number(totalInvoiceAmount) +
          (formData?.otherCharges ? Number(formData?.otherCharges) : 0) -
          (formData?.discount ? Number(formData?.discount) : 0)
        )?.toFixed(2),
      });
      formData.amountToBeReceived = (
        Number(totalInvoiceAmount) +
        (formData?.otherCharges ? Number(formData?.otherCharges) : 0) -
        (formData?.discount ? Number(formData?.discount) : 0)
      )?.toFixed(2);
    } else {
      setFormData({
        ...formData,
        amountToBeReceived: (
          Number(formData?.totalBill) -
          Number(formData?.tds) +
          (formData?.otherCharges ? Number(formData?.otherCharges) : 0) -
          (formData?.discount ? Number(formData?.discount) : 0)
        )?.toFixed(2),
      });
      formData.amountToBeReceived = (
        Number(formData?.totalBill) -
        Number(formData?.tds) +
        (formData?.otherCharges ? Number(formData?.otherCharges) : 0) -
        (formData?.discount ? Number(formData?.discount) : 0)
      )?.toFixed(2);
    }
  };

  useEffect(() => {
    if (
      GetMonthoption &&
      prevData?.page2 === "createInvoice" &&
      formData?.serviceMonth === ""
    ) {
      const currentMonth = new Date().getMonth() + 1;
      const defaultServiceMonth = GetMonthoption.find(
        (month) => month.value === currentMonth
      );

      setFormData({
        ...formData,
        serviceMonth: defaultServiceMonth?.label || "",
        serviceMonthId: defaultServiceMonth?.value - 1 || "",
      });
    }
  }, [GetMonthoption]);

  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>

      {/*Copy Invoice*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Copy Invoice </div>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>Copy Invoice?</div>
            {prevData?.page2 === "editInvoice" ? (
              <p className={style.values}>
                {formData?.copyOfInvoice === true ? "Yes" : "No"}
              </p>
            ) : (
              <>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="Yes"
                    name="radio-group"
                    checked={formData?.copyOfInvoice}
                    onChange={() =>
                      setFormData({ ...formData, copyOfInvoice: true })
                    }
                    value={formData?.copyOfInvoice}
                  />
                  <label for="Yes">Yes</label>
                </div>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="no"
                    name="radio-group"
                    checked={!formData?.copyOfInvoice}
                    onChange={() =>
                      setFormData({ ...formData, copyOfInvoice: false })
                    }
                    value={formData?.copyOfInvoice}
                  />
                  <label for="no">No</label>
                </div>
              </>
            )}
          </div>

          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        <Row>
          <Col md={3} style={{ marginLeft: "26px" }}>
            <p className={style.label}>
              Client Name<span style={{ color: "red" }}>*</span>
            </p>
            {prevData?.page2 === "editInvoice" ? (
              <input
                value={formData?.clientName}
                className={style.bigInput}
                readOnly
              />
            ) : (
              <Select
                styles={fullWidthSelect}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    clientId: e?.value,
                    clientName: e?.label,
                    projectId: null,
                    projectName: "",
                    milestoneId: null,
                    milestoneName: "",
                    milestoneTimeFrame: "",
                    milestoneProjectPercentage: "",
                  });
                }}
                options={clientOption}
                value={clientOption?.find((e) => {
                  return e?.value === formData?.clientName;
                })}
              />
            )}
            <p
              className={
                validate && !formData?.clientId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Client Name
            </p>
          </Col>

          <Col md={3}>
            <p className={style.label}>
              Service Month <span style={{ color: "red" }}>*</span>
            </p>

            <Select
              styles={InvoiceStylesVendorName}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              options={GetMonthoption}
              onChange={(e) =>
                handleServiceMonthChange(
                  e,
                  formData?.invoiceLineItemDetails?.length
                )
              }
              value={GetMonthoption?.find((e) => {
                return e?.label === formData?.serviceMonth;
              })}
            />

            <p
              className={
                validate && !formData?.serviceMonth
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Month
            </p>
          </Col>

          <Col md={3}>
            <p className={style.label}>
              Service Year <span style={{ color: "red" }}>*</span>
            </p>

            <Select
              styles={InvoiceStylesVendorName}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              options={GetCategoryYearOption}
              onChange={(e) => {
                handleServiceYearChange(
                  e,
                  formData?.invoiceLineItemDetails?.length
                );
              }}
              value={GetCategoryYearOption?.find((e) => {
                return e?.label === formData?.serviceYear;
              })}
            />

            <p
              className={
                validate && !formData?.serviceYear
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Month
            </p>
          </Col>
        </Row>
      </InvoiceContainer>
      {/*Invoice Details*/}
      {
        <>
          {" "}
          <InvoiceContainer>
            <div className={style.copy_expence}>Invoice Details</div>
            {/*row 1*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Project Type <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={fullWidthSelect}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      serviceTypeId: e?.id,
                      projectTypeName: e?.label,
                      projectId: null,
                      projectName: "",
                      milestoneId: null,
                      milestoneName: "",
                      milestoneTimeFrame: "",
                      milestoneProjectPercentage: "",
                      invoiceLineItemDetails: [
                        {
                          candidateName: "",
                          invoiceMonth: "",
                          invoiceYear: "",
                          billingRate: "",
                          workingDays: null,
                          maxWorkingDay: null,
                          amount: null,
                          milestoneName: "",
                          milestoneTimeFrame: "",
                          milestoneProjectPercentage: "",
                          sno: 1,
                          description: "",
                        },
                      ],
                    });
                  }}
                  options={invoiceTypeOption}
                  value={invoiceTypeOption?.find((e) => {
                    return e?.value === formData?.projectTypeName;
                  })}
                />
                <p
                  className={
                    validate && !formData?.serviceTypeId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Project Type
                </p>
              </div>

              <div className={style.col12}>
                <p className={style.label}>
                  Invoice Type <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={fullWidthSelect}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      invoiceTypeId: e?.id,
                      invoiceTypeName: e?.label,
                    });
                  }}
                  options={invoiceListOption}
                  value={invoiceListOption?.find((e) => {
                    return e?.label === formData?.invoiceTypeName;
                  })}
                />
                <p
                  className={
                    validate && !formData?.invoiceTypeId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Invoice Type
                </p>
              </div>
              {formData?.serviceTypeId === 2 && (
                <>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Project Name <span style={{ color: "red" }}>*</span>
                    </p>
                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          projectId: e?.id,
                          projectName: e?.value,
                          milestoneId: null,
                          milestoneName: "",
                          milestoneTimeFrame: "",
                          milestoneProjectPercentage: "",
                        });
                      }}
                      options={getProjectOption}
                      value={
                        formData?.projectName
                          ? getProjectOption?.find((e) => {
                              return e?.value === formData?.projectName;
                            })
                          : null
                      }
                    />
                    <p
                      className={
                        validate && !formData?.projectId
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Project Name
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Milestone <span style={{ color: "red" }}>*</span>
                    </p>
                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        let temp = [...formData?.invoiceLineItemDetails];

                        temp[0].milestoneName = e?.label;
                        temp[0].milestoneProjectPercentage = e?.percentage;
                        temp[0].milestoneTimeFrame = e?.timeframe;
                        temp[0].milestoneAmount = e?.amount;
                        temp[0].calculativeBaseAmount = (
                          Number(e?.amount) * Number(formData?.conversionRate)
                        )?.toFixed(2);

                        setFormData({
                          ...formData,
                          milestoneId: e?.id,
                          invoiceLineItemDetails: temp,
                        });

                        formData.milestoneId = e?.id;

                        calculateGst(temp);
                      }}
                      options={milestoneOption}
                      value={
                        formData?.invoiceLineItemDetails[0]?.milestoneName
                          ? milestoneOption?.find((e) => {
                              return (
                                e?.value ===
                                formData?.invoiceLineItemDetails[0]
                                  ?.milestoneName
                              );
                            })
                          : null
                      }
                    />
                    <p
                      className={
                        validate && !formData?.milestoneId
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Milestone
                    </p>
                  </div>
                </>
              )}
            </div>
            {/*row 2*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Invoice No <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      invoiceNumber: e.target.value,
                    });
                  }}
                  value={formData?.invoiceNumber}
                />
                <p
                  className={
                    validate && !formData?.invoiceNumber
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice No
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Invoice Date <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData?.invoiceDate
                        ? moment(formData?.invoiceDate).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        invoiceDate: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      formData?.invoiceDate ? (
                        <CustomInputCross properties="invoiceDate" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
                <p
                  className={
                    validate && !formData?.invoiceDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice Date
                </p>
              </div>
              <div className={style.col3}>
                <p className={style.label}>
                  Due Date <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData?.dueDate
                        ? moment(formData?.dueDate).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        dueDate: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      formData?.dueDate ? (
                        <CustomInputCross properties="dueDate" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
                <p
                  className={
                    validate && !formData?.dueDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Due Date
                </p>
              </div>
              <div className={style.col4}>
                <p className={style.label}>
                  Advance Status <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={fullWidthSelect}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advanceStatusId: e?.value,
                      advanceStatusName: e?.label,
                    });
                  }}
                  options={statusOption}
                  value={statusOption?.find((e) => {
                    return e?.label === formData?.advanceStatusName;
                  })}
                />
                <p
                  className={
                    validate && !formData?.advanceStatusId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Advance Status
                </p>
              </div>
            </div>
            {/*row 3*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Currency <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={fullWidthSelect}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      currencyId: e?.value,
                      currencyName: e?.label,
                      conversionRate: e?.value === 2 ? 1 : "",
                    });
                  }}
                  options={currencyOption}
                  value={currencyOption?.find((e) => {
                    return e?.label === formData?.currencyName;
                  })}
                />
                <p
                  className={
                    validate && !formData?.currencyId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Currency
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Conversion Rate <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <input
                  type="number"
                  value={formData?.conversionRate}
                  className={style.smallInput}
                  onChange={(e) => {
                    const temp = [...formData?.invoiceLineItemDetails];

                    if (formData?.serviceTypeId === 2) {
                      for (let i = 0; i < temp?.length; i++) {
                        const amountValue = temp[i]?.milestoneAmount;
                        temp[i].calculativeBaseAmount = Number(
                          amountValue * e?.target?.value
                        )?.toFixed(2);
                      }
                    } else {
                      for (let i = 0; i < temp?.length; i++) {
                        const amountValue = temp[i]?.amount;
                        temp[i].calculativeBaseAmount = Number(
                          amountValue * e?.target?.value
                        )?.toFixed(2);
                      }
                    }

                    calculateGst(temp);

                    setFormData({
                      ...formData,
                      conversionRate: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData?.conversionRate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Conversion Rate
                </p>
              </div>
              <div className={style.col3}>
                <p className={style.label}>
                  GSTN <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  value={formData?.gstNumber}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      gstNumber: e.target.value,
                    });
                  }}
                  readOnly={formData?.currencyId === 2 ? false : true}
                />
                <p
                  className={
                    validate && !formData?.gstNumber
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter GSTN
                </p>
              </div>
              <div className={style.col4}></div>
            </div>

            {/*row 6*/}
          </InvoiceContainer>
          {/* Line Item Details Container */}
          {formData?.serviceTypeId === 2 ? (
            <InvoiceContainer>
              <div className={style.copy_expence}>Milestone</div>
              {formData?.invoiceLineItemDetails?.map((item, index) => {
                return (
                  <div className={style.Container} key={index}>
                    <div className={style.col12_item}>
                      <p className={style.label_item}>Name</p>
                      <input
                        type="text"
                        readOnly
                        className={style.smallInput}
                        value={
                          formData?.invoiceLineItemDetails[index]?.milestoneName
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col3_item}>
                      <p className={style.label_item}>Time Frame</p>
                      <input
                        type="text"
                        className={style.smallInput}
                        value={
                          formData?.invoiceLineItemDetails[index]
                            ?.milestoneTimeFrame
                        }
                        readOnly
                      />
                    </div>
                    <div className={style.col4_item}>
                      <p className={style.label_item}>Percentage</p>
                      <input
                        type="text"
                        className={style.smallInput}
                        value={
                          formData?.invoiceLineItemDetails[index]
                            ?.milestoneProjectPercentage
                        }
                        readOnly
                      />
                    </div>
                    <div className={style.col6_item}>
                      <p className={style.label_item}>Amount</p>
                      <input
                        type="text"
                        readOnly
                        className={style.smallInput}
                        value={
                          formData?.invoiceLineItemDetails[index]
                            ?.milestoneAmount
                        }
                      />
                    </div>
                    <div className={style.col6_item}>
                      <p className={style.label_item}>Converted Amount</p>
                      <input
                        type="text"
                        readOnly
                        className={style.smallInput}
                        value={
                          formData?.invoiceLineItemDetails[index]
                            ?.calculativeBaseAmount
                        }
                      />
                    </div>
                    <div className={style.col7_item}></div>
                    <div className={style.col8_item}>
                      {formData?.invoiceLineItemDetails?.length > 1 && (
                        <FaTrash
                          className={style.remove_icon}
                          size={20}
                          onClick={(e) => {
                            setLineItemArray(
                              lineItemArray.filter((_, i) => {
                                return i !== index;
                              })
                            );
                            setFormData({
                              ...formData,
                              invoiceLineItemDetails:
                                formData?.invoiceLineItemDetails?.filter(
                                  (_, i) => i !== index
                                ),
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </InvoiceContainer>
          ) : formData?.serviceTypeId === 3 ? (
            <InvoiceContainer>
              <div className={style.copy_expence}>Product</div>
              {/*row 1*/}
              {formData?.invoiceLineItemDetails?.map((item, index) => {
                return (
                  <>
                    <div className={style.Container} key={index}>
                      <div className={style.col12_item}>
                        {index === 0 && (
                          <p className={style.label_item}>
                            S.N. <span style={{ color: "red" }}>*</span>
                          </p>
                        )}
                        <input
                          type="number"
                          className={style.smallInput}
                          value={formData?.invoiceLineItemDetails[index]?.sno}
                          readOnly
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={(e) =>
                            (e.keyCode === 38 ||
                              e.keyCode === 40 ||
                              e.key === "e" ||
                              e.key === "-") &&
                            e.preventDefault()
                          }
                          onKeyPress={(e) =>
                            ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                              e.which > 57 ||
                              e.key === "e" ||
                              e.key === "-") &&
                            e.prevent
                          }
                        />
                        {validate &&
                          !formData?.invoiceLineItemDetails[index]?.sno && (
                            <p
                              className={
                                validate &&
                                  !formData?.invoiceLineItemDetails[index]?.sno
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter S.N.
                            </p>
                          )}
                      </div>

                      <div className={style.full_col}>
                        {index === 0 && (
                          <p className={style.label_item}>
                            Description <span style={{ color: "red" }}>*</span>
                          </p>
                        )}
                        <input
                          type="text"
                          className={style.bigInput}
                          onChange={(e) => {
                            let temp = [...formData?.invoiceLineItemDetails];
                            temp[index].description = e?.target?.value;
                            setFormData({
                              ...formData,
                              invoiceLineItemDetails: temp,
                            });
                          }}
                          value={
                            formData?.invoiceLineItemDetails[index]?.description
                          }
                        />
                        {validate &&
                          !formData?.invoiceLineItemDetails[index]
                            ?.description && (
                            <p
                              className={
                                validate &&
                                  !formData?.invoiceLineItemDetails[index]
                                    ?.description
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Description
                            </p>
                          )}
                      </div>

                      <div className={style.col4_item}>
                        {index === 0 && (
                          <p className={style.label_item}>
                            Amount <span style={{ color: "red" }}>*</span>
                          </p>
                        )}
                        <input
                          type="text"
                          className={style.smallInput}
                          onChange={(e) => {
                            let temp = [...formData?.invoiceLineItemDetails];
                            temp[index].amount = e?.target?.value;
                            temp[index].calculativeBaseAmount = (
                              Number(e?.target?.value) *
                              Number(formData?.conversionRate)
                            )?.toFixed(2);

                            calculateGst(temp);

                            setFormData({
                              ...formData,
                              invoiceLineItemDetails: temp,
                            });
                          }}
                          value={
                            formData?.invoiceLineItemDetails[index]?.amount
                          }
                        />
                        {validate &&
                          !formData?.invoiceLineItemDetails[index]?.amount && (
                            <p
                              className={
                                validate &&
                                  !formData?.invoiceLineItemDetails[index]?.amount
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Amount
                            </p>
                          )}
                      </div>

                      <div className={style.col4_item}>
                        {index === 0 && (
                          <p className={style.label_item}>
                            Converted Amount{" "}
                            <span
                              style={{ color: "red", visibility: "hidden" }}
                            >
                              *
                            </span>
                          </p>
                        )}
                        <input
                          type="text"
                          className={style.smallInput}
                          readOnly
                          onChange={(e) => {
                            let temp = [...formData?.invoiceLineItemDetails];
                            temp[index].calculativeBaseAmount =
                              e?.target?.value;
                            setFormData({
                              ...formData,
                              invoiceLineItemDetails: temp,
                            });
                          }}
                          value={
                            formData?.invoiceLineItemDetails[index]
                              ?.calculativeBaseAmount
                          }
                        />
                      </div>

                      <div
                        style={
                          index === 0
                            ? {
                              display: "flex",
                              alignItems: "center",
                              marginTop: "2%",
                            }
                            : null
                        }
                        className={style.col4_item}
                      >
                        {formData?.invoiceLineItemDetails?.length > 1 && (
                          <>
                            <FaTrash
                              className={style.remove_icon}
                              size={20}
                              onClick={(e) => {
                                setLineItemArray(
                                  lineItemArray.filter((_, i) => {
                                    return i !== index;
                                  })
                                );

                                const updatedDetails =
                                  formData?.invoiceLineItemDetails?.filter(
                                    (_, i) => i !== index
                                  );

                                const updatedDetailsWithSno =
                                  updatedDetails.map((item, newIndex) => ({
                                    ...item,
                                    sno: newIndex + 1,
                                  }));

                                setFormData({
                                  ...formData,
                                  invoiceLineItemDetails: updatedDetailsWithSno,
                                });
                                formData.invoiceLineItemDetails =
                                  updatedDetailsWithSno;

                                calculateGst([
                                  ...formData?.invoiceLineItemDetails,
                                ]);
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

              <div className={style.lastBtn_item}>
                <p style={{ paddingTop: "1%", fontWeight: "bold" }}>
                  Total Invoice Amount
                </p>
                <div>
                  <input
                    readOnly
                    className={style.smallInputDes}
                    value={formData?.totalInvoiceAmount}
                  />
                </div>
                <InvoiceButton
                  style={{ width: "9%", height: "30px", paddingTop: "2px" }}
                  onClick={() => {
                    setLineItemArray([
                      ...lineItemArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setFormData({
                      ...formData,
                      invoiceLineItemDetails: [
                        ...formData?.invoiceLineItemDetails,
                        {
                          candidateName: "",
                          invoiceMonth: "",
                          invoiceYear: "",
                          billingRate: "",
                          workingDays: null,
                          maxWorkingDay: null,
                          amount: null,
                          milestoneName: "",
                          milestoneTimeFrame: "",
                          milestoneProjectPercentage: "",
                          sno: formData?.invoiceLineItemDetails?.length + 1,
                          description: "",
                        },
                      ],
                    });
                  }}
                >
                  Add
                </InvoiceButton>
              </div>
            </InvoiceContainer>
          ) : (
            <InvoiceContainer>
              <div className={style.copy_expence}>Line Item Details</div>
              {/*row 1*/}
              <div className={style.Container}>
                <div className={style.col12_item}>
                  <p className={style.label_item}>
                    Candidate Name <span style={{ color: "red" }}>*</span>
                  </p>
                </div>

                <div className={style.col12_item}>
                  <p className={style.label_item}>
                    Billing Rate <span style={{ color: "red" }}>*</span>
                  </p>
                </div>

                <div className={style.col12_item}>
                  <p className={style.label_item}>
                    Working Days <span style={{ color: "red" }}>*</span>
                  </p>
                </div>

                <div className={style.col12_item}>
                  <p className={style.label_item}>
                    Max Working Days <span style={{ color: "red" }}>*</span>
                  </p>
                </div>

                <div className={style.col12_item}>
                  <p className={style.label_item}>
                    Amount <span style={{ color: "red" }}>*</span>
                  </p>
                </div>

                <div className={style.col12_item}>
                  <p className={style.label_item}>
                    Converted Amount
                    <span style={{ color: "red", visibility: "hidden" }}>
                      *
                    </span>
                  </p>
                </div>
              </div>
              {formData?.invoiceLineItemDetails?.map((item, index) => {
                return (
                  <>
                    <div className={style.Container} key={index}>
                      <div className={style.col12_item}>
                        <Select
                          styles={InvoiceStylesVendorName}
                          classNamePrefix={"create_position"}
                          components={{
                            DropdownIndicator: () => CustomInput(),
                            IndicatorSeparator: () => null,
                          }}
                          options={getCandidateOption}
                          onChange={(e) => {
                            let temp = [...formData?.invoiceLineItemDetails];
                            temp[index].candidateName = e?.label;
                            temp[index].id = e?.id;
                            formData.invoiceLineItemDetails = temp;
                            setFormData({
                              ...formData,
                              invoiceLineItemDetails: temp,
                            });
                          }}
                          value={
                            formData?.invoiceLineItemDetails[index]
                              ?.candidateName
                              ? getCandidateOption?.find(
                                (e) =>
                                  e.label ===
                                  formData?.invoiceLineItemDetails[index]
                                    ?.candidateName
                              )
                              : null
                          }
                        />
                        {validate &&
                          !formData?.invoiceLineItemDetails[index]
                            ?.candidateName && (
                            <p
                              className={
                                validate &&
                                  !formData?.invoiceLineItemDetails[index]
                                    ?.candidateName
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Name
                            </p>
                          )}
                      </div>

                      <div className={style.col4_item}>
                        <input
                          type="number"
                          className={style.smallInput}
                          onChange={(e) => {
                            let temp = [...formData?.invoiceLineItemDetails];
                            temp[index].billingRate = e.target.value;
                            setFormData({
                              ...formData,
                              invoiceLineItemDetails: temp,
                            });
                            calculateAmount(e, index);
                          }}
                          value={
                            formData?.invoiceLineItemDetails[index]?.billingRate
                          }
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={(e) =>
                            (e.keyCode === 38 ||
                              e.keyCode === 40 ||
                              e.key === "e" ||
                              e.key === "-") &&
                            e.preventDefault()
                          }
                          onKeyPress={(e) =>
                            ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                              e.which > 57 ||
                              e.key === "e" ||
                              e.key === "-") &&
                            e.prevent
                          }
                        />
                        {validate &&
                          !formData?.invoiceLineItemDetails[index]
                            ?.billingRate && (
                            <p
                              className={
                                validate &&
                                  !formData?.invoiceLineItemDetails[index]
                                    ?.billingRate
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Rate
                            </p>
                          )}
                      </div>
                      <div className={style.col5_item}>
                        <input
                          type="number"
                          className={style.smallInput}
                          onChange={(e) => {
                            let temp = [...formData?.invoiceLineItemDetails];
                            if (e.target.value > temp[index]?.maxWorkingDay) {
                              return;
                            }
                            temp[index].workingDays = e.target.value;
                            setFormData({
                              ...formData,
                              invoiceLineItemDetails: temp,
                            });
                            calculateAmount(e, index);
                          }}
                          value={
                            formData?.invoiceLineItemDetails[index]?.workingDays
                          }
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={(e) =>
                            (e.keyCode === 38 ||
                              e.keyCode === 40 ||
                              e.key === "e" ||
                              e.key === "-") &&
                            e.preventDefault()
                          }
                          onKeyPress={(e) =>
                            ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                              e.which > 57 ||
                              e.key === "e" ||
                              e.key === "-") &&
                            e.prevent
                          }
                        />
                        {validate &&
                          !formData?.invoiceLineItemDetails[index]
                            ?.workingDays && (
                            <p
                              className={
                                validate &&
                                  !formData?.invoiceLineItemDetails[index]
                                    ?.workingDays
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Days
                            </p>
                          )}
                      </div>
                      <div className={style.col6_item}>
                        <input
                          type="number"
                          className={style.smallInput}
                          value={
                            formData?.invoiceLineItemDetails[index]
                              ?.maxWorkingDay
                          }
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={(e) =>
                            (e.keyCode === 38 ||
                              e.keyCode === 40 ||
                              e.key === "e" ||
                              e.key === "-") &&
                            e.preventDefault()
                          }
                          onKeyPress={(e) =>
                            ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                              e.which > 57 ||
                              e.key === "e" ||
                              e.key === "-") &&
                            e.prevent
                          }
                          readOnly
                        />
                        {validate &&
                          !formData?.invoiceLineItemDetails[index]
                            ?.maxWorkingDay && (
                            <p
                              className={
                                validate &&
                                  !formData?.invoiceLineItemDetails[index]
                                    ?.maxWorkingDay
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Days
                            </p>
                          )}
                      </div>
                      <div className={style.col7_item}>
                        <input
                          type="text"
                          className={style.smallInput}
                          value={
                            formData?.invoiceLineItemDetails[index]?.amount
                          }
                          readOnly
                        />
                        {validate &&
                          !formData?.invoiceLineItemDetails[index]?.amount && (
                            <p
                              className={
                                validate &&
                                  !formData?.invoiceLineItemDetails[index]?.amount
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Amount
                            </p>
                          )}
                      </div>

                      <div className={style.col7_item}>
                        <input
                          type="text"
                          className={style.smallInput}
                          value={
                            formData?.invoiceLineItemDetails[index]
                              ?.calculativeBaseAmount
                          }
                          readOnly
                        />
                      </div>

                      <div className={style.col8_item}>
                        {formData?.invoiceLineItemDetails?.length > 1 && (
                          <>
                            <FaTrash
                              className={style.remove_icon}
                              size={20}
                              onClick={(e) => {
                                const currentAmount =
                                  formData?.invoiceLineItemDetails[index]
                                    ?.calculativeBaseAmount;

                                const totalInvoiceAmount = (
                                  Number(formData?.totalInvoiceAmount) -
                                  Number(currentAmount)
                                )?.toFixed(2);

                                formData.totalInvoiceAmount =
                                  totalInvoiceAmount;

                                if (formData?.gstApplicable === false) {
                                  formData.amountToBeReceived =
                                    totalInvoiceAmount +
                                    (
                                      (formData?.otherCharges
                                        ? Number(formData?.otherCharges)
                                        : 0) -
                                      (formData?.discount
                                        ? Number(formData?.discount)
                                        : 0)
                                    )?.toFixed(2);
                                }

                                setLineItemArray(
                                  lineItemArray.filter((_, i) => {
                                    return i !== index;
                                  })
                                );

                                setFormData({
                                  ...formData,
                                  invoiceLineItemDetails:
                                    formData?.invoiceLineItemDetails?.filter(
                                      (_, i) => i !== index
                                    ),
                                });
                                formData.invoiceLineItemDetails =
                                  formData?.invoiceLineItemDetails?.filter(
                                    (_, i) => i !== index
                                  );

                                calculateGst([
                                  ...formData?.invoiceLineItemDetails,
                                ]);
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

              <div className={style.lastBtn_item}>
                <p style={{ paddingTop: "1%", fontWeight: "bold" }}>
                  Total Invoice Amount
                </p>
                <div>
                  <input
                    readOnly
                    className={style.smallInputDes}
                    value={formData?.totalInvoiceAmount}
                  />
                </div>
                <InvoiceButton
                  style={{ width: "9%", height: "30px", paddingTop: "2px" }}
                  onClick={() => {
                    // push new value 2 in array
                    setLineItemArray([
                      ...lineItemArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setFormData({
                      ...formData,
                      invoiceLineItemDetails: [
                        ...formData?.invoiceLineItemDetails,
                        {
                          candidateName: "",
                          invoiceMonth: "",
                          invoiceYear: "",
                          billingRate: "",
                          workingDays: null,
                          maxWorkingDay:
                            formData?.invoiceLineItemDetails[0]?.maxWorkingDay,
                          amount: null,
                          milestoneName: "",
                          milestoneTimeFrame: "",
                          milestoneProjectPercentage: "",
                          sno: "",
                          description: "",
                        },
                      ],
                    });
                  }}
                >
                  Add
                </InvoiceButton>
              </div>
            </InvoiceContainer>
          )}
          <InvoiceContainer>
            <div className={style.Container}>
              <div className={style.col1}>
                <div className={style.heading}>GST Applicable</div>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="Yes1"
                    name="radio-group2"
                    onChange={() => {
                      setFormData({ ...formData, gstApplicable: true });
                      formData.gstApplicable = true;
                      calculateGst([...formData?.invoiceLineItemDetails]);
                    }}
                    checked={formData.gstApplicable}
                  />
                  <label for="Yes1">Yes</label>
                </div>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="no1"
                    name="radio-group2"
                    checked={!formData?.gstApplicable}
                    onChange={() => {
                      setFormData({
                        ...formData,
                        gstApplicable: false,
                        amountToBeReceived: Number(
                          formData?.totalInvoiceAmount +
                          (formData?.otherCharges
                            ? Number(formData?.otherCharges)
                            : 0) -
                          (formData?.discount
                            ? Number(formData?.discount)
                            : 0)
                        )?.toFixed(2),
                      });
                    }}
                  />
                  <label for="no1">No</label>
                </div>
              </div>
            </div>
            <div className={style.Container}>
              <div className={style.col4}></div>
            </div>
            {formData.gstApplicable === true ? (
              <>
                <div className={style.GstList}>
                  <div className={style.col12}>
                    <p className={style.label}>Base Amount</p>
                    <input
                      type="number"
                      readOnly
                      className={style.GstInput}
                      value={formData?.baseAmountInvoice}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          baseAmountInvoice: e.target.value,
                        });
                      }}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>IGST</p>
                    <input
                      type="number"
                      readOnly
                      className={style.GstInput}
                      value={formData?.igst}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          igst: e?.target?.value,
                          gstTotal: e?.target?.value,
                          totalBill: (
                            Number(formData?.baseAmountInvoice) +
                            Number(e?.target?.value)
                          )?.toFixed(2),
                        });
                      }}
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>CGST</p>
                    <input
                      type="number"
                      readOnly
                      value={formData?.cgst}
                      className={style.GstInput}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>

                  <div className={style.col12}>
                    <p className={style.label}>SGST</p>
                    <input
                      readOnly
                      type="number"
                      value={formData?.sgst}
                      className={style.GstInput}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                </div>
                <div className={style.GstList2}>
                  <div className={style.col12}>
                    <p className={style.label}>GST(TOTAL)</p>
                    <input
                      readOnly
                      type="number"
                      className={style.GstInput}
                      value={formData?.gstTotal}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>Total Billed</p>
                    <input
                      readOnly
                      value={formData?.totalBill}
                      type="number"
                      className={style.GstInput}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>TDS</p>
                    <input
                      type="number"
                      className={style.GstInput}
                      value={formData?.tds}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          tds: e?.target?.value,
                          amountToBeReceived: (
                            Number(formData?.totalBill) -
                            Number(e?.target?.value) +
                            (formData?.otherCharges
                              ? Number(formData?.otherCharges)
                              : 0) -
                            (formData?.discount
                              ? Number(formData?.discount)
                              : 0)
                          )?.toFixed(2),
                        });
                      }}
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>Other Charges</p>
                    <input
                      type="number"
                      value={formData?.otherCharges}
                      className={style.GstInput}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          otherCharges: e?.target?.value,
                          amountToBeReceived: (
                            Number(formData?.totalBill) -
                            Number(formData?.tds) +
                            Number(e?.target?.value) -
                            (formData?.discount
                              ? Number(formData?.discount)
                              : 0)
                          )?.toFixed(2),
                        })
                      }
                    />
                  </div>
                </div>
                <div className={style.GstList2}>
                  <div className={style.col12}>
                    <p className={style.label}>Discount</p>
                    <input
                      type="number"
                      value={formData?.discount}
                      className={style.GstInput}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          discount: e?.target?.value,
                          amountToBeReceived: (
                            Number(formData?.totalBill) -
                            Number(formData?.tds) -
                            Number(e?.target?.value) +
                            (formData?.otherCharges
                              ? Number(formData?.otherCharges)
                              : 0)
                          )?.toFixed(2),
                        })
                      }
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>Amount to be received</p>
                    <input
                      type="number"
                      value={formData?.amountToBeReceived}
                      className={style.GstInput}
                      readOnly
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col12}></div>
                  <div className={style.col12}></div>
                </div>
              </>
            ) : (
              <div className={style.GstList2}>
                <div className={style.col12}>
                  <p className={style.label}>Other Charges</p>
                  <input
                    type="number"
                    value={formData?.otherCharges}
                    className={style.GstInput}
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) =>
                      (e.keyCode === 38 ||
                        e.keyCode === 40 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.preventDefault()
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.prevent
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otherCharges: e?.target?.value,
                        amountToBeReceived: (
                          Number(formData?.totalInvoiceAmount) +
                          Number(e?.target?.value) -
                          (formData?.discount ? Number(formData?.discount) : 0)
                        )?.toFixed(2),
                      })
                    }
                  />
                </div>
                <div className={style.col12}>
                  <p className={style.label}>Discount</p>
                  <input
                    type="number"
                    value={formData?.discount}
                    className={style.GstInput}
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) =>
                      (e.keyCode === 38 ||
                        e.keyCode === 40 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.preventDefault()
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.prevent
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        discount: e?.target?.value,
                        amountToBeReceived: (
                          Number(formData?.totalInvoiceAmount) -
                          Number(e?.target?.value) +
                          (formData?.otherCharges
                            ? Number(formData?.otherCharges)
                            : 0)
                        )?.toFixed(2),
                      })
                    }
                  />
                </div>
                <div className={style.col12}>
                  <p className={style.label}>Amount to be received</p>
                  <input
                    type="number"
                    className={style.GstInput}
                    value={formData?.amountToBeReceived}
                    readOnly
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) =>
                      (e.keyCode === 38 ||
                        e.keyCode === 40 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.preventDefault()
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.prevent
                    }
                  />
                </div>
                <div className={style.col12}></div>
              </div>
            )}
          </InvoiceContainer>
          <InvoiceContainer>
            <div className={style.Container}>
              <div className={style.upload_container}>
                <div className={style.heading}>Upload Files</div>
                <UploadMultiFile
                  handleFileChange={handleFileChange}
                  handleFileDelete={handleFileDelete}
                  documentResponses={formData.documentResponses}
                />
              </div>
            </div>
          </InvoiceContainer>
          <div className={style.lastBtn}>
            <InvoiceButton
              type={"cancel"}
              onClick={() => {
                setNextScreen("invoiceList");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: null,
                  page3: null,
                  page4: null,
                  active: "invoiceList",
                }));
              }}
            >
              Cancel
            </InvoiceButton>
            <InvoiceButton onClick={CreateInvoiceHandler}>Create</InvoiceButton>
          </div>
          <Modal
            show={show}
            centered
            classNames={{
              modal: "customModal",
            }}
          >
            <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
              Do you want to add client ?
            </h6>
            <p className={style.label_item1}>
              Client Name <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              className={style.smallInput1}
              onChange={(e) => setAddClient(e.target.value)}
            />
            <button className={style.buttonAdd1} onClick={handleDelete}>
              Yes
            </button>
            <button className={style.buttonCancle} onClick={handleDeleteCancle}>
              No
            </button>

            <style type="text/css">
              {`
      .modal-content {
        width: 73%;
      }
    `}
            </style>
          </Modal>
        </>
      }
    </>
  );
};

export default CreateInvoice;
