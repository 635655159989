import Dashboard from "../Pages/projectCostingAndManagement/pageLayout/dashboard/dashboard.js";
import { path } from "./PathNames.js";
import EditProject from "../Pages/projectCostingAndManagement/pageLayout/allProjectsLists/editProject.js";
import InvoiceList from "../Pages/projectCostingAndManagement/pageLayout/invoiceList/invoiceList.js";
import EditInvoice from "../Pages/projectCostingAndManagement/pageLayout/editInvoice/editInvoice.js";
import ProjectCosting from "../Pages/projectCostingAndManagement/pageLayout/projectCosting/projectCosting.js";
import ProjectCostBreakUp from "../Pages/projectCostingAndManagement/pageLayout/projectCostBreakup/projectCostBreakup.js";
import OverheadsDashboard from "../Pages/projectCostingAndManagement/pageLayout/overheadsDashboard/overheadsDashboard.js";
import ResourceMargin from "../Pages/projectCostingAndManagement/pageLayout/resourceMargin/resourceMargin.js";

const PcmRoute = [
  {
    path: path.pcm_dashboard,
    component: Dashboard,
  },
  {
    path: path.pcm_editProject,
    component: EditProject,
  },
  {
    path: path.pcm_invoices,
    component: InvoiceList,
  },
  { path: path.pcm_editInvoice, component: EditInvoice },
  { path: path.pcm_addInvoice, component: EditInvoice },
  { path: path.pcm_projectCostingInformation, component: ProjectCosting },
  { path: path.pcm_projectCostBreakUp, component: ProjectCostBreakUp },
  {
    path: path.pcm_overheads,
    component: OverheadsDashboard ,
  },
  {
    path: path.pcm_resourceMargin,
    component: ResourceMargin ,
  },
];

// Now 'routes' is an array of JSON objects with path and component properties.

export default PcmRoute;
