import React from "react";
import { MyIssueCom } from "./component/MyIssueCom";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import style from "./MyIssue.module.css"
export const MyIssue =()=>{
    return(<>
       <Header />
      <div className={style.mainSection}>
        <SideBar />
        <MyIssueCom />
      </div>
    </>)
}