import React, { useState } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import style from "./EmployeeDashboard.module.scss";
import { employeeList, employeeListCount } from "../../../../Utils/axios/InductionApi";
import { employeeId } from "../../../../Utils/constants";
import { useQuery } from "react-query";
import * as Img from "../../../../assets/index";

function EmployeeDashboard({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) {
  const { data: INP } = useQuery(["getInProgressEmployeeCount"], () =>
    employeeListCount()
  );
  const { data: DONE } = useQuery(["employeeListDONE", employeeId], () =>
    employeeList(10, 1, null, "true")
  );

  const onInProgress = () => {
    setNextScreen("employeeInduction");
    setPrevData((prevData) => ({
      ...prevData,
      page1: "employeeInduction",
      page2: null,
      page3: null,
      page4: null,
      active: "employeeInduction",
      sidebar: "employeeInduction",
      status: "INP",
    }));
  };

  const onDone = () => {
    setNextScreen("employeeInduction");
    setPrevData((prevData) => ({
      ...prevData,
      page1: "employeeInduction",
      page2: null,
      page3: null,
      page4: null,
      active: "employeeInduction",
      sidebar: "employeeInduction",
      status: "DONE",
    }));
  };
  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      ></BreadCrum>
      <div className={style.flex}>
        <div className={style.card} onClick={onInProgress}>
          <img src={Img.inProgressActive} />
          <p>{INP?.totalEmployee}</p>
          <p style={{ color: "gray" }}>In-Progress</p>
        </div>
        <div className={style.card} onClick={onDone}>
          <img src={Img.doneActive} />

          <p>{DONE?.totalEmployee}</p>
          <p style={{ color: "gray" }}>Done</p>
        </div>
      </div>
    </>
  );
}

export default EmployeeDashboard;
