import React from "react";
import Sidebar from "../../Clients/component/SideBar";
import Header from "../../../taskTracker/commonComponent/Header";
import style from "./CreateTask.module.css";
import CreateTaskRMS from "./component/CreateTaskComponent";
const CreateTaskRMSComponent = (props) => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <Sidebar />
        <CreateTaskRMS data={props} />
      </div>
    </div>
  );
};

export default CreateTaskRMSComponent;
