import React, { useState } from "react";
import { Col } from "react-bootstrap";
import style from "./createClient.module.css";
import Sidebar from "../SideBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { post } from "../../../CommonApi/axiosCall";
import { api } from "../../../CommonApi/api";
import { Toastify } from "../../../../../App";

const CreateClient = () => {
  const [required, setRequired] = useState(false);
  const [disable, setDisable] = useState(false);

  const history = useHistory();
  const [state, setState] = useState({
    domain: "",
    name: "",
  });

  const [clientData] = useState([]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const id = query.get("id");

  const onInputChange = (e) => {
    e.target.setCustomValidity("");
    if (e.target.name === "clientname") {
      setRequired(false);
      setState({
        ...state,
        name: e.target.value,
      });
    } else if (e.target.name === "domain") {
      setState({
        ...state,
        domain: e.target.value,
      });
    }
  };
  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/clients");
    }, 2000);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (state.name.trim().length <= 0) {
      setRequired(true);
      return false;
    }
    const data = {
      description: state?.description?.trim(),
      domain: state?.domain?.trim(),
      name: state?.name?.trim(),
    };
    setDisable(true);

    let baseUrl = api.createClient;

    post(baseUrl, data)
      .then((res) => {
        Toastify("Client Created Successfully");
        handleRedirect();
      })
      .catch(() => {});
  };

  const updateClient = (e) => {
    e.preventDefault();
    if (state.name.length <= 0) {
      setRequired(true);
      return false;
    }
    const data = {
      clientid: id,
      domain: state.domain.trim(),
      name: state.name.trim(),
    };

    let baseUrl = api.editClient;

    post(baseUrl, data)
      .then((res) => {
        Toastify("Client Updated Successfully");
        handleRedirect();
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className={style.main_client_body}>
        <Sidebar />
        <form
          className={style.table_div}
          onSubmit={id ? updateClient : onSubmit}
        >
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>
            <div style={{ padding: "10px 0px", fontWeight: "700" }}>
              Create Client
            </div>
            <div className={style.form_div}>
              <div id="create_task_id" className={style.gridContainer}>
                <Col>
                  <label className={style.assignLabel}>
                    <div className={style.font15}>
                      Client Name <span style={{ color: "red" }}>*</span>
                      <input
                        className={style.input}
                        autoComplete="off"
                        defaultValue={clientData?.name}
                        type="input"
                        name="clientname"
                        onChange={onInputChange}
                      />
                    </div>
                    {required ? (
                      <p className={style.error}>Please Enter Client Name</p>
                    ) : null}
                  </label>
                </Col>

                <Col>
                  <label className={style.descLabel}>
                    <div className={style.font15}>Client Domain </div>
                    <input
                      autoComplete="off"
                      className={style.input}
                      type="text"
                      defaultValue={clientData?.domain}
                      name="domain"
                      onChange={onInputChange}
                    />
                  </label>
                </Col>
              </div>
            </div>
          </div>
          <div className={style.btn_div}>
            <button
              type="submit"
              className={style.submitbtn}
              disabled={disable}
            >
              {id ? "Update" : "Submit"}
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default CreateClient;
