import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "../EditEmployeeTimeSheet/EditViewTaskSheet.module.css";
import { useQuery } from "react-query";
import { getTimesheetId } from "../../../../Utils/axios/NewTimeSheetApi";
const EditViewTaskSheet = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const { data: getTimesheetDataId } = useQuery(
    ["getTimesheetId"],
    () => getTimesheetId(prevData?.IdData),
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      <Container>
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <span className={style.ViewTask}>View Task Details</span>
          </Col>
        </Row>
      </Container>
      <div className={style.DetailView}>
        <p className={style.ViewEmployeeDetails}>Task Details</p>
        <Container fluid style={{ paddingBottom: "20px" }}>
          <Row>
            <Col md={3} className={style.ProjectEmployee}>
              <h6>Project Name</h6>
              <p>{getTimesheetDataId?.projectName}</p>
            </Col>
            <Col md={3} className={style.ProjectEmployee}>
              <h6>Task Type</h6>
              <p>{getTimesheetDataId?.taskType}</p>
            </Col>
            {getTimesheetDataId?.taskType === "API Integration" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Screen Name</h6>
                  <p>{getTimesheetDataId?.screenName}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>API Name</h6>
                  <p>{getTimesheetDataId?.apiname}</p>
                </Col>
                <Col
                  md={3}
                  className={style.ProjectEmployee}
                  style={{ marginTop: "13px" }}
                >
                  <h6>Time Spent (In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}

            {getTimesheetDataId?.taskType === "API Modification" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>API Name</h6>
                  <p>{getTimesheetDataId?.apiname}</p>
                </Col>
                <Col
                  md={3}
                  className={style.ProjectEmployee}
                  style={{ marginTop: "13px" }}
                >
                  <h6>Time Spent (In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}

            {getTimesheetDataId?.taskType === "API Development" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Task Summary</h6>
                  <p>{getTimesheetDataId?.taskSummary}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>API Name</h6>
                  <p>{getTimesheetDataId?.apiname}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}
            {getTimesheetDataId?.taskType === "Defect Fixing" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Defect Number</h6>
                  <p>{getTimesheetDataId?.defectNumber}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}
            {getTimesheetDataId?.taskType === "API Design" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}

            {getTimesheetDataId?.taskType === "Tech Design(Low Level)" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Feature Number</h6>
                  <p>{getTimesheetDataId?.featureName}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}
            {getTimesheetDataId?.taskType === "Test Case Preparation" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Feature Number</h6>
                  <p>{getTimesheetDataId?.featureName}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}

            {getTimesheetDataId?.taskType === "Test Case Preparation" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Screen Name</h6>
                  <p>{getTimesheetDataId?.screenName}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}

            {getTimesheetDataId?.taskType === "UI Testing" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Screen Name</h6>
                  <p>{getTimesheetDataId?.screenName}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}

            {getTimesheetDataId?.taskType === "API Testing" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>API Name</h6>
                  <p>{getTimesheetDataId?.apiname}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}

            {getTimesheetDataId?.taskType === "Functional Testing" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Feature Name</h6>
                  <p>{getTimesheetDataId?.featureName}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}

            {getTimesheetDataId?.taskType === "Scrum Meeting" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}

            {getTimesheetDataId?.taskType === "Code Review" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}

            {getTimesheetDataId?.taskType === "UI Development" ? (
              <>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Screen Name</h6>
                  <p>{getTimesheetDataId?.screenName}</p>
                </Col>
                <Col md={3} className={style.ProjectEmployee}>
                  <h6>Time Spent(In Hrs/Min)</h6>
                  <p>{getTimesheetDataId?.timeSpent}</p>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
      <button
        className={style.buttonAdd}
        onClick={() => {
          setNextScreen("empTimeSheet");
          setPrevData((prevData) => ({
            ...prevData,
            page2: "empTimeSheet",
            page3: null,
            page4: null,
            active: "empTimeSheet",
          }));
        }}
      >
        Update
      </button>
      <button
        className={style.ViewCancel}
        onClick={() => {
          setNextScreen("AddTaskDetails");
          setPrevData((prevData) => ({
            ...prevData,
            page2: "AddTaskDetails",
            page3: null,
            page4: null,
            active: "AddTaskDetails",
          }));
        }}
      >
        Cancel
      </button>
    </>
  );
};
export default EditViewTaskSheet;
