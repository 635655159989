import ViewUser from "../Pages/userManegment/pageLayout/viewUser/ViewUser.js";
import CreateUser from "../Pages/userManegment/pageLayout/createPosition/CreateUser.js";
import UsersList from "../Pages/userManegment/pageLayout/UsersList/UsersList.js";
import Edituser from "../Pages/userManegment/pageLayout/editUser/Edituser.js";
import Canfirmation from "../Pages/userManegment/pageLayout/createPosition/canfimation/Canfirmation.js";
import NewEmployee from "../Pages/userManegment/pageLayout/newEmployee/NewEmployee.js";
import Dashboard from "../Pages/userManegment/pageLayout/dashboard/Dashboard.js";
import ExitEmpolyee from "../Pages/userManegment/pageLayout/ExitEmployee/ExitEmpolyee.js";
import ConfirmationEmployee from "../Pages/userManegment/pageLayout/confirmationEmployee/ConfirmationEmployee.js";
import Report from "../Pages/userManegment/pageLayout/UMSReport/Report.js";
import NewJoinee from "../Pages/userManegment/pageLayout/newJoinee/NewJoinee.js";
import ExitedEmployeeList from "../Pages/userManegment/pageLayout/exitedEmployee/ExitedEmployeeList.js";
import AllExitedEmployee from "../Pages/userManegment/pageLayout/allExitedEmployee/AllExitedEmployee.js";
import { path } from "./PathNames.js";
import BGVReport from "../Pages/userManegment/pageLayout/bgvReport/BGVReport.js";
import CompanyHierarchy from "../Pages/userManegment/pageLayout/compnayHierarchy/companyHierarchy.js";
import SalaryMasterList from "../Pages/userManegment/pageLayout/salaryMasterList/SalaryMaster.js";
import IncentiveReport from "../Pages/userManegment/pageLayout/IncentiveReport/IncentiveReport.js";

// function UmsRoute() {
//   return (
//     <Route>
//       <Route exact path={path.ums_userList} component={UsersList} />
//       <Route exact path={path.ums_createUser} component={CreateUser} />
//       <Route exact path={path.ums_createconfirm} component={Canfirmation} />
//       <Route exact path={path.ums_userDescription} component={ViewUser} />
//       <Route exact path={path.ums_editUser} component={Edituser} />
//       <Route exact path={path.ums_newEmployee} component={NewEmployee} />
//       <Route exact path={path.ums_exitEmployee} component={ExitEmpolyee} />
//       <Route
//         exact
//         path={path.ums_exitedEmployee}
//         component={ExitedEmployeeList}
//       />
//       <Route
//         exact
//         path={path.ums_confirmationEmployee}
//         component={ConfirmationEmployee}
//       />
//       <Route exact path={path.ums_report} component={Report} />
//       <Route exact path={path.ums_newJoinee} component={NewJoinee} />
//       <Route
//         exact
//         path={path.ums_allExitedEmployee}
//         component={AllExitedEmployee}
//       />
//     </Route>
//   );
// }
const UmsRoute = [
  {
    path: path.ums_dashboard,
    component: Dashboard
  },
  {
    path: path.ums_userList,
    component: UsersList
  },
  {
    path: path.ums_createUser,
    component: CreateUser
  },
  {
    path: path.ums_createconfirm,
    component: Canfirmation
  },
  {
    path: path.ums_userDescription,
    component: ViewUser
  },
  {
    path: path.ums_userDescription2,
    component: ViewUser
  },
  {
    path: path.ums_editUser,
    component: Edituser
  },
  {
    path: path.ums_newEmployee,
    component: NewEmployee
  },
  {
    path: path.ums_exitEmployee,
    component: ExitEmpolyee
  },
  {
    path: path.ums_exitedEmployee,
    component: ExitedEmployeeList
  },
  {
    path: path.ums_confirmationEmployee,
    component: ConfirmationEmployee
  },
  {
    path: path.ums_report,
    component: Report
  },
  {
    path: path.ums_newJoinee,
    component: NewJoinee
  },
  {
    path: path.ums_allExitedEmployee,
    component: AllExitedEmployee
  },
  {
    path: path.ums_salaryMaster,
    component: SalaryMasterList
  },
  {
    path: path.ums_bgvReport,
    component: BGVReport
  },
  {
    path: path.ums_companyHierarchy,
    component: CompanyHierarchy
  },
  {
    path: path.ums_incentive_report,
    component: IncentiveReport
  }
];

// Now 'routes' is an array of JSON objects with path and component properties.


export default UmsRoute;
