import React, { useState, useEffect } from "react";
import style from "./AddBlockResources.module.css";
import Sidebar from "../../Clients/component/SideBar";
import cookie from "react-cookies";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import constants from "../../../../Utils/constants";
import DatePicker from "react-datepicker";
import dropdownIcon from "../../../../assets/downIcon.png";
import calendar from "../../../../assets/Grievance portal/dateldpi.png";
import Moment from "moment";
import { Toastify, ToastifyError } from "../../../../App";
import Select from "react-select";
import { customStylesForRMS } from "../../Clients/component/style";
import { api } from "../../CommonApi/api";
import { get } from "../../CommonApi/axiosCall";
import { employeeId } from "../../../../Utils/constants";

const AddBlockResources = () => {
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{ paddingTop: "8px", fontSize: "15px", fontWeight: 500 }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "35%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const CustomInput1 = () => {
    return (
      <span>
        <img
          alt="dropdownIcon"
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  const [projectList, setProjectList] = useState([]);
  const [clientListing, setClientListing] = useState([]);
  const history = useHistory();
  const [disable, setDisable] = useState(false);
  const [state, setState] = useState({
    allocationDate: "",
    clientId: 0,
    deallocationDate: "",
    empId: 0,
    projectType: "",
    projectId: 0,
  });

  const [allocationId, setAllocationId] = useState("");
  const [skillList, setSkillList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeName, setEmployeeName] = useState("");

  const [projectApplication, setProjectApplication] = useState([]);
  const [validate, setValidate] = useState(false);

  employeeList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  skillList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  projectList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const baseUrl = constants.apiBaseUrlResource;

  const url = constants.apiBaseUrl;

  const getResourceAloocation = (id, end) => {
    axios
      .get(
        baseUrl +
          `/getBandwidthAllocationDetails?id=${id}&startDate=${Moment(
            state?.startDate
          ).format("YYYY-MM-DDTHH:mm:ss")}&endDate=${Moment(
            state?.endDate
          ).format("YYYY-MM-DDTHH:mm:ss")}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        }
      )
      .then((res) => {
        setAllocationId(res.data.responseData);
        if (res.data.responseData === "Full") {
          setDisable(true);
        } else {
          setDisable(false);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const getEmployeeList = () => {
    axios
      .get(url + "/getEmployeeList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setEmployeeList(res.data.responseData.employeeList);
      })
      .catch((err) => {
        return err;
      });
  };

  const ProjectApplication = (id) => {
    let baseURL = api.getApplicationNameByProjectId;
    if (id) {
      baseURL += `?projectId=${id}`;
    }

    get(baseURL)
      .then((res) => {
        setProjectApplication(res.data.responseData);
      })
      .catch(() => {});
  };

  const getClientListing = () => {
    let url = api.getOnsiteClientList;

    get(url)
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch(() => {});
  };

  const getProjectSkill = () => {
    axios
      .get(baseUrl + "/getProjectforFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setProjectList(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getSkills = () => {
    axios
      .get(baseUrl + "/getAllSkills", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setSkillList(res.data.responseData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getProjectSkill();
    getEmployeeList();
    getSkills();
    getClientListing();
  }, []);

  useEffect(() => {
    const resourceAllocation = state?.endDate
      ? getResourceAloocation(state?.empId)
      : null;
  }, [state?.endDate]);

  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/BlockResourcesList");
    }, 2000);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      !state?.deallocationDate ||
      !state.allocationDate ||
      (!state?.clientId && state.projectType === false) ||
      (!state?.projectId && state.projectType === true) ||
      !state.empId ||
      state.projectType === ""
    ) {
      setValidate(true);
      return;
    }
    var data = {
      ...state,
      allocationDate: Moment(state?.allocationDate).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
      deallocationDate: Moment(state?.deallocationDate).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
    };

    axios
      .post(baseUrl + "/block-resource", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus.statusCode === 1) {
          ToastifyError(res.data.responseData.message);
        } else {
          Toastify("Data Submitted Successfully");
          setDisable(true);
          handleRedirect();
        }
      })
      .catch(() => {});
  };

  // handle react Select
  const handleChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const allocation = [
    { name: "25%", id: "OneFourth" },
    { name: "50%", id: "Half" },
    { name: "75%", id: "ThreeFourth" },
    { name: "100%", id: "Full" },
  ];
  const projectType = [
    { label: "OffShore", value: true },
    { label: "OnSite", value: false },
  ];

  const getskillList = skillList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getClientList = clientListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getemployeeList = employeeList?.map((i) => ({
    value: i.id,
    label: `${i.name}   (${i.employeeCode})`,
  }));

  const getprojectApplication = projectApplication?.map((i) => ({
    value: i.applicationId,
    label: i.applicationName,
  }));

  const getprojectList = projectList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getallocation = allocation?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const [allocationType] = useState([
    {
      value: 1,
      label: "Allocated",
    },
    { value: 2, label: "Allocated (Soft)" },
  ]);
  
  return (
    <>
      <div className={style.main_client_body}>
        <Sidebar />

        <form className={style.table_div} onSubmit={onSubmit}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb}></div>
            <div style={{ marginTop: "22px", fontWeight: "700" }}>
              Add Blocked Resources
            </div>
            <div className={style.form_div}>
              {allocationId && allocationId !== "Full" && employeeName ? (
                <p
                  style={{
                    color: "red",
                    paddingBottom: "10px",
                    paddingLeft: "13px",
                  }}
                >
                  You can allocate {employeeName}{" "}
                  {allocationId === "OneFourth"
                    ? `${100 - 25}% only`
                    : allocationId === "Half"
                    ? `${100 - 50}% only`
                    : allocationId === "ThreeFourth"
                    ? `${100 - 75}% only`
                    : allocationId === "Zero"
                    ? `${100}%`
                    : null}
                </p>
              ) : allocationId && allocationId === "Full" && employeeName ? (
                <p
                  style={{
                    color: "red",
                    paddingLeft: "13px",
                    paddingBottom: "10px",
                  }}
                >
                  {" "}
                  You can't allocate {employeeName} because bandwidth is not
                  available{" "}
                </p>
              ) : null}

              <div className={style.Cu_row1}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>
                    Employee Name <span style={{ color: "red" }}>*</span>
                  </p>

                  <Select
                    styles={customStylesForRMS}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => CustomInput1(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      handleChange(`empId`, e?.value);
                      setEmployeeName(e.label);
                    }}
                    options={getemployeeList}
                  />
                  <p
                    className={
                      validate && !state?.empId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Employee Name
                  </p>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    Project Type<span style={{ color: "red" }}>*</span>
                  </p>
                  <Select
                    styles={customStylesForRMS}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => CustomInput1(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        projectType: e?.value,
                        clientId: null,
                        projectId: null
                      }));
                    }}
                    options={projectType}
                  />
                  <p
                    className={
                      validate && state?.projectType === ""
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Project Type
                  </p>
                </div>
                <div className={style.cu_col3}>
                  <p className={style.cu_label} style={{ paddingLeft: "5px" }}>
                    Project Name<span style={{ color: "red" }}>*</span>
                  </p>

                  <Select
                  value={getprojectList?.find(
                    (item) => item?.value === state?.projectId
                  ) !== undefined
                    ? getprojectList?.find(
                        (item) => item?.value === state?.projectId
                      )
                    : { value: null, label: null }}
                    styles={customStylesForRMS}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => CustomInput1(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => handleChange(`projectId`, e?.value)}
                    options={getprojectList}
                    isDisabled={
                      state?.projectType === false || state?.projectType == ""
                    }
                  />

                  <p
                    className={
                      validate && !state?.projectId && state.projectType == true
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Project Name
                  </p>
                </div>
              </div>
              <div className={style.Cu_row1}>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>
                    Client Name<span style={{ color: "red" }}>*</span>
                  </p>

                  <Select
                    value={
                      getprojectList?.find(
                        (item) => item?.value === state?.clientId
                      ) !== undefined
                        ? getprojectList?.find(
                            (item) => item?.value === state?.clientId
                          )
                        : { value: null, label: null }
                    }
                    styles={customStylesForRMS}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => CustomInput1(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => handleChange(`clientId`, e?.value)}
                    options={getClientList}
                    isDisabled={
                      state?.projectType === true || state?.projectType === ""
                    }
                  />
                  <p
                    className={
                      validate &&
                      !state?.clientId &&
                      state.projectType === false
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Client Name
                  </p>
                </div>
                <div className={style.cu_col1}>
                  <p className={style.cu_label}>
                    Start Date<span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style.cu_field2}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      selected={state?.allocationDate}
                      startDate={null}
                      onChange={(date) => handleChange(`allocationDate`, date)}
                      disabledKeyboardNavigation
                      defaultValue={null}
                      customInput={<CustomInput />}
                    />
                  </div>
                  <p
                    className={
                      validate && !state?.allocationDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Start Date
                  </p>
                </div>
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    End Date<span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style.cu_field2}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      selected={state?.deallocationDate}
                      startDate={null}
                      minDate={state?.startDate}
                      onChange={(date) =>
                        handleChange(`deallocationDate`, date)
                      }
                      disabledKeyboardNavigation
                      defaultValue={null}
                      customInput={<CustomInput />}
                    />
                  </div>
                  <p
                    className={
                      validate && !state?.deallocationDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select End Date
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={style.btn_div}>
            <button
              onClick={onSubmit}
              className={style.submitbtn}
              disabled={disable}
            >
              Submit
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default AddBlockResources;
