import React from "react";
import { ToastContainer } from "react-toastify";
import BreadCrum from "../../../breadcrums/BreadCrum";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import style from "./ViewExpence.module.css";
import pdf from '../../../../../assets/pdf.png';
import word from '../../../../../assets/word.png';
import file from '../../../../../assets/file.png';
import { getExpenseId } from "../../../../../Utils/axios/expenceApi.js";
import { useQuery } from "react-query";
import moment from "moment";
const ViewExpence = ({ setNextScreen, prevData, setPrevData }) => {
  const { data: ExpenseDataById } = useQuery(
    ["getExpenseId"],
    () => getExpenseId(prevData.idData),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
    }
  );
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <img src={pdf} alt="pdf" />;
      case "docx":
        return <img src={word} alt="word" />;
      default:
        return <img src={file} alt="other" />;
    }
  };
  const handleDivClick = (url) => {
    window.open(url, '_blank');
  };
  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>

      <InvoiceContainer>
        <div className={style.copy_expence}>Copy Expense </div>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>Copy Expense?</div>
            <div className={style.radioitem}>
              <p className={style.values2}>
                {ExpenseDataById?.copyOfExpense === true ? "Yes" : "NO"}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-start" }}
          className={style.Container}
        >
          <div className={style.col12}>
            <p className={style.label}>Vendor Name</p>
            <p className={style.valuesVendor}>
              {ExpenseDataById &&
                (ExpenseDataById?.vendorNameById
                  ? ExpenseDataById?.vendorNameById
                  : "-")}
            </p>
          </div>

          {ExpenseDataById?.venderType === "Partner" ? (
            <>
              <div className={style.col12}>
                <p className={style.label}>Service Month</p>
                <p className={style.valuesVendor}>
                  {ExpenseDataById &&
                    (ExpenseDataById?.serviceMonth
                      ? ExpenseDataById?.serviceMonth
                      : "-")}
                </p>
              </div>

              <div className={style.col12}>
                <p className={style.label}>Service Year</p>
                <p className={style.valuesVendor}>
                  {ExpenseDataById &&
                    (ExpenseDataById?.serviceYear
                      ? ExpenseDataById?.serviceYear
                      : "-")}
                </p>
              </div>
            </>
          ) : null}
        </div>
      </InvoiceContainer>
      {/*Expence Details*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Expense Details</div>
        {/*row 1*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Category</p>
            <p className={style.values}>
              {ExpenseDataById &&
                (ExpenseDataById?.categoryName
                  ? ExpenseDataById?.categoryName
                  : "-")}
            </p>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        {/*row 2*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Invoice No</p>
            <p className={style.values}>
              {ExpenseDataById &&
                (ExpenseDataById?.invoice ? ExpenseDataById?.invoice : "-")}
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>Date of Invoice</p>
            <p className={style.values}>
              {" "}
              {ExpenseDataById &&
                (ExpenseDataById?.dateOfExpense
                  ? moment(ExpenseDataById?.dateOfExpense).format("DD MMM YY")
                  : "-")}
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>Expense Month</p>
            <p className={style.values}>
              {ExpenseDataById &&
                (ExpenseDataById?.expenseMonth
                  ? ExpenseDataById?.expenseMonth
                  : "-")}
            </p>
          </div>
          <div className={style.col4}>
            <p className={style.label}>Expense Year</p>
            <p className={style.values}>
              {ExpenseDataById &&
                (ExpenseDataById?.expenseYear
                  ? ExpenseDataById?.expenseYear
                  : "-")}
            </p>
          </div>
        </div>
        <div className={style.Container}>
          <div className={style.col22}>
            <p className={style.label}>Approved By</p>
            <p className={style.values}>
              {ExpenseDataById &&
                (ExpenseDataById?.approveBy ? ExpenseDataById?.approveBy : "-")}
            </p>
          </div>
          <div className={style.col22}>
            <p className={style.label}>GST No.</p>
            <p className={style.values}>
              {ExpenseDataById &&
                (ExpenseDataById?.gstNumber ? ExpenseDataById?.gstNumber : "-")}
            </p>
          </div>
          <div className={style.col22}>
            <p className={style.label}>PAN Card</p>
            <p className={style.values}>
              {ExpenseDataById &&
                (ExpenseDataById?.pan ? ExpenseDataById?.pan : "-")}
            </p>
          </div>
          <div className={style.col22}></div>
        </div>
        <div className={style.border}></div>
      </InvoiceContainer>
      {/* Line Item Details Container */}
      {ExpenseDataById?.venderType === "Partner" ? (
        <InvoiceContainer>
          <div className={style.copy_expence}>Line Item Details</div>
          {ExpenseDataById?.lineItemDetails?.map((item, index) => {
            return (
              <>
                {index > 0 && <hr />}
                <div className={style.Container} key={index}>
                  <div className={style.col12_item}>
                    <p className={style.label_item}>Candidate Name</p>
                    <p className={style.values}>
                      {item?.candidateName ? item?.candidateName : "-"}
                    </p>
                  </div>
                  <div className={style.col4_item}>
                    <p className={style.label_item}>Billing Rate</p>
                    <p className={style.values}>
                      {item?.billingRate ? item?.billingRate : "-"}
                    </p>
                  </div>
                  <div className={style.col5_item}>
                    <p className={style.label_item}>Working Days</p>
                    <p className={style.values}>
                      {item?.workingDays ? item?.workingDays : "-"}
                    </p>
                  </div>
                  <div className={style.col6_item}>
                    <p className={style.label_item}>Max Working Days</p>
                    <p className={style.values}>
                      {item?.maxWorkingDays ? item?.maxWorkingDays : "-"}
                    </p>
                  </div>
                  <div className={style.col7_item}>
                    <p className={style.label_item}>Base Amount</p>
                    <p className={style.values}>
                      {item?.baseAmount ? item?.baseAmount : "-"}
                    </p>
                  </div>
                </div>
                <div className={style.col10_item}>
                  <p className={style.label_item}>Description</p>
                  <p className={style.valuesVendor}>
                    {item?.description ? item?.description : "-"}
                  </p>
                </div>
              </>
            );
          })}

          <div className={style.total_amount_div}>
            <p style={{ fontWeight: "bold" }}>Total Base Amount: </p>
            <p className={style.base_amount_value}>
              {ExpenseDataById?.lineItemDetails?.length > 0 &&
                ExpenseDataById.lineItemDetails
                  .map((item) => parseFloat(item.baseAmount))
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                  )
                  .toFixed(2)}
            </p>
          </div>
        </InvoiceContainer>
      ) : (
        <InvoiceContainer>
          <div className={style.copy_expence}>Line Item Details</div>
          {ExpenseDataById?.lineItemDetails?.map((item, index) => {
            return (
              <>
                <div className={style.ContainerVendorNoCase} key={index}>
                  <div className={style.col12_item}>
                    {index === 0 && (
                      <p className={style.label_item}>Unit Amount</p>
                    )}
                    <p className={style.values}>
                      {item?.unitAmount ? item?.unitAmount : "-"}
                    </p>
                  </div>
                  <div className={style.col2_item}>
                    {index === 0 && (
                      <p className={style.label_item}>No. of Unit</p>
                    )}
                    <p className={style.values}>
                      {item?.numberOfUnit ? item?.numberOfUnit : "-"}
                    </p>
                  </div>
                  <div className={style.col3_item}>
                    {index === 0 && (
                      <p className={style.label_item}>Base Amount</p>
                    )}
                    <p className={style.values}>
                      {item?.vendorBaseAmount ? item?.vendorBaseAmount : "-"}
                    </p>
                  </div>
                  <div className={style.col4_item}>
                    {index === 0 && (
                      <p className={style.label_item}>Description</p>
                    )}
                    <p className={style.valuesVendor2}>
                      {item?.description ? item?.description : "-"}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </InvoiceContainer>
      )}

      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>GST Applicable</div>
            <div className={style.radioitem}>
              <p className={style.values2}>
                {ExpenseDataById?.gstApplicable === true ? "Yes" : "NO"}
              </p>
            </div>
          </div>
        </div>
        {ExpenseDataById?.gstApplicable === true ? (
          <>
            <div>
              <div className={style.Container}></div>
              <div className={style.Container}>
                <div className={style.col2}>
                  <p className={style.label}>GST Percentage</p>
                  <p className={style.values}>
                    {ExpenseDataById && ExpenseDataById?.gstPercentage + "%"}
                  </p>
                </div>
                <div className={style.col2}>
                  <p className={style.label}>Base Amount</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.baseAmount
                        ? ExpenseDataById?.baseAmount
                        : "-")}
                  </p>
                </div>
                <div className={style.col2}>
                  <p className={style.label}>IGST</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.igst ? ExpenseDataById?.igst : "-")}
                  </p>
                </div>
                <div className={style.col3}>
                  <p className={style.label}>CGST</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.cgst ? ExpenseDataById?.cgst : "-")}
                  </p>
                </div>
                <div className={style.col4}>
                  <p className={style.label}>SGST</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.sgst ? ExpenseDataById?.sgst : "-")}
                  </p>
                </div>
              </div>
              <div className={style.Container}>
                <div className={style.col2}>
                  <p className={style.label}>GST (Total)</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.gst ? ExpenseDataById?.gst : "-")}
                  </p>
                </div>
                <div className={style.col2}>
                  <p className={style.label}>Total Billed</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.totalBill
                        ? ExpenseDataById?.totalBill
                        : "-")}
                  </p>
                </div>
                <div className={style.col2}>
                  <p className={style.label}>TDS</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.tds ? ExpenseDataById?.tds : "-")}
                  </p>
                </div>
                <div className={style.col3}>
                  <p className={style.label}>Other Charges</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.otherCharges
                        ? ExpenseDataById?.otherCharges
                        : "-")}
                  </p>
                </div>
                <div className={style.col4}>
                  <p className={style.label}>Discount</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.discount
                        ? ExpenseDataById?.discount
                        : "-")}
                  </p>
                </div>
              </div>
              <div className={style.Container}>
                <div className={style.col3}>
                  <p className={style.label}>Amount to Be Paid</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.amountToBePaid
                        ? ExpenseDataById?.amountToBePaid
                        : "-")}
                  </p>
                </div>
                <div className={style.col2}>
                  <p className={style.label}>Payable 1</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.gst
                        ? (Number(ExpenseDataById?.amountToBePaid) - Number(ExpenseDataById?.gst))
                        : "-")}
                  </p>
                </div>
                <div className={style.col2}>
                  <p className={style.label}>Payable 2</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.gst
                        ? Number(ExpenseDataById?.gst)
                        : "-")}
                  </p>
                </div>

                <div className={style.col4}>
                  <p className={style.label}>Payment Due On</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById.paymentDueOn
                        ? moment(ExpenseDataById.paymentDueOn).format(
                          "DD MMM YY"
                        )
                        : "-")}
                  </p>
                </div>

                <div style={{ width: "19%" }} className={style.col4}>
                  <p className={style.label}>Payment Mode</p>
                  <p className={style.values}>
                    {ExpenseDataById?.paymentMode
                      ? ExpenseDataById?.paymentMode
                      : "-"}
                  </p>
                </div>
              </div>
              <div className={style.Container2}>
                <div className={style.col4}>
                  <p className={style.label}>Payment Note</p>
                  <p className={style.values}>
                    {ExpenseDataById?.paymentNote
                      ? ExpenseDataById?.paymentNote
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className={style.Container}></div>
              <div className={style.Container}>
                <div className={style.col2}>
                  <p className={style.label}>Base Amount</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.baseAmount
                        ? ExpenseDataById?.baseAmount
                        : "-")}
                  </p>
                </div>
                <div className={style.col2}>
                  <p className={style.label}>TDS</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.tds ? ExpenseDataById?.tds : "-")}
                  </p>
                </div>
                <div className={style.col3}>
                  <p className={style.label}>Other Charges</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.otherCharges
                        ? ExpenseDataById?.otherCharges
                        : "-")}
                  </p>
                </div>
                <div className={style.col4}>
                  <p className={style.label}>Discount</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById.discount
                        ? ExpenseDataById.discount
                        : "-")}
                  </p>
                </div>
              </div>
              <div className={style.Container2}>
                <div className={style.col3}>
                  <p className={style.label}>Amount to Be Paid</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById?.amountToBePaid
                        ? ExpenseDataById?.amountToBePaid
                        : "-")}
                  </p>
                </div>

                <div className={style.col4}>
                  <p className={style.label}>Payment Due On</p>
                  <p className={style.values}>
                    {ExpenseDataById &&
                      (ExpenseDataById.paymentDueOn
                        ? moment(ExpenseDataById.paymentDueOn).format(
                          "DD MMM YY"
                        )
                        : "-")}
                  </p>
                </div>
                <div className={style.col4}>
                  <p className={style.label}>Payment Mode</p>
                  <p className={style.values}>
                    {ExpenseDataById?.paymentMode
                      ? ExpenseDataById?.paymentMode
                      : "-"}
                  </p>
                </div>

                <div className={style.col4}>
                  <p className={style.label}>Payment Note</p>
                  <p className={style.values}>
                    {ExpenseDataById?.paymentNote
                      ? ExpenseDataById?.paymentNote
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </InvoiceContainer>
      {ExpenseDataById?.documentResponses?.length !== 0 &&
        <InvoiceContainer>
          <div className={style.file_container}>
            <div className={style.heading}>Files Uploaded</div>
            {ExpenseDataById?.documentResponses?.map((file, index) => (
              <div key={index} title={file.documentURL.split('@')[1]} className={`${style.file_name}`}
                onClick={() => handleDivClick(file.documentURL)}
              >
                <span>{checkfileType(file.documentURL.substr(-3))}&nbsp;</span>
                <p className={style.text}>{file.documentURL.split('@')[1]}</p>
              </div>
            ))}
          </div>
        </InvoiceContainer>
      }
      <div className={style.lastBtn}>
        <InvoiceButton
          type={"cancel"}
          onClick={() => {
            setNextScreen("expense");
            setPrevData((prevData) => ({
              ...prevData,
              page2: null,
              page3: null,
              page4: null,
              active: "expense",
            }));
          }}
        >
          Back
        </InvoiceButton>

        <InvoiceButton
          onClick={() => {
            setNextScreen("editExpense");
            setPrevData((prevData) => ({
              ...prevData,
              page2: "editExpense",
              page3: null,
              page4: null,
              active: "editExpense",
              idData: prevData?.idData,
            }));
          }}
        >
          Edit
        </InvoiceButton>
      </div>
    </>
  );
};

export default ViewExpence;
