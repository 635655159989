import React from "react";
import { QuestionByPositionCom } from "./component/QuestionByPositionCom";
import style from "./QuestioByPosition.module.css"
import QuestionnaireSidebar from "../sidebar/SideBar";
export const QuestionByPosition =()=>{
    return(<>
       <div className={style.mainSection}>
         <QuestionnaireSidebar />
         <QuestionByPositionCom />
       </div>
     </>)
 }
