export const customSearchForATS = {
    control: () => ({
    //   margin: "0 10px",
      borderRadius: "9px",
    //   padding: "0 20px 0 0px",
      border: "1px solid rgba(0, 0, 0, 0.192)",
      height: '40px', 
      width: '200px',
    }),
    placeholder: () => ({
      // display: "none",
      
      marginTop: "-30px",
      color: "#6666",
    }),
 

    //  option color on hover green
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#48729ed5",
      },
      backgroundColor: state.isSelected ? "#48729ed5" : "white",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        display: 'none',
      }),
    // dropdown width
    menu: (provided) => ({
      ...provided,
      width: "92%",
      marginLeft: "0.6rem",
    }),
  };