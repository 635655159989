export function generateRandomString() {
  const length = 16;
  const characters = "AabcdfdfcBCDEFGHIJKLMN";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }

  return randomString;
}
