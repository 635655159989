import React, { useState } from 'react';
export const employeesContext = React.createContext();

const ASContext = (props) => {
    const[showInput,setShowInput] = useState(false);
  const [active, setActive] = useState("appraisals");
    return (
        <employeesContext.Provider value={[showInput,setShowInput,active,setActive]}>
            {props.children}
        </employeesContext.Provider>
    );
}

export default ASContext;
