import React from "react";
import { Route, Redirect } from "react-router-dom";
import cookie from "react-cookies";
import secureLocalStorage from "react-secure-storage";
const accessData = secureLocalStorage?.getItem("cacheKey");

export function ProtectedRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => (cookie.load("token") ? children : <Redirect to="/" />)}
    />
  );
}
export function ApplicationProtectedRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() =>
        accessData?.includes("ATS") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function ClientQuestionProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("QUES") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function TaskTrackerProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("TT") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function EmployeeInductionProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("IND") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function RMSProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("RMS") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function HRHelpProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("HRHD") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function UMSProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("UMS") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function FEProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("FE") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function TSProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("TS") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function PMProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("PM") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function EPRProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("EPR") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
export function ContractProtectedRoute({ children }) {
  return (
    <Route
      render={() =>
        accessData?.includes("CPR") ? children : <Redirect to="/dashboard" />
      }
    />
  );
}
