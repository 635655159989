import React, { useEffect, useState } from 'react';
import style from "./AppraisalInputForm.module.css"
import { createAppraisal, getEmployeeProposedDetail } from '../../../../../Utils/axios/AppraisalApi';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';
import { notifyError, notifySuccess } from '../../../../atsDashboard/utils/notify';

const AppraisalInputForm = ({ rewardList, promotionList, currentDetailsObj, modalData, setShowForm, refetchEmployeeDetails }) => {
  const empId = modalData.eid;
  const [award, setAward] = useState(null);
  const [promotion, setPromotion] = useState(null);
  const [increasedAmount, setIncreasedAmount] = useState(0);
  const [increasedPercent, setIncreasedPercent] = useState("");
  const [coreSalaryM, setCoreSalaryM] = useState("");
  const [fullSalaryM, setFullSalaryM] = useState("");
  const [coreSalaryA, setCoreSalaryA] = useState("");
  const [fullSalaryA, setFullSalaryA] = useState("");
  const [error, setError] = useState(false);
  const [applicableDate, setApplicableDate] = useState('')

  const createAppraisalMutation = useMutation(createAppraisal, {
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData)
      }
      else {
        notifyError(res.responseData.message)
      }
      refetchEmployeeDetails();
      refetchEmployeeProposedDetails();


    },
    onError: (err) => {
    },
  });

  const { data: employeeProposedDetails, refetch: refetchEmployeeProposedDetails } = useQuery(["getEmployeeProposedDetail"], () =>
    getEmployeeProposedDetail(empId)
  );
  useEffect(() => {
    setAward(employeeProposedDetails?.rewardId)
    setPromotion(employeeProposedDetails?.promotionId)
    setIncreasedPercent(employeeProposedDetails?.incrementPercentage)
    setApplicableDate(employeeProposedDetails?.applicableDate ? moment(employeeProposedDetails?.applicableDate).format("YYYY-MM-DD"):null)
    let newAmount = parseInt((employeeProposedDetails?.incrementAmount), 10)
    setIncreasedAmount(newAmount);
    setCoreSalaryM(employeeProposedDetails?.newMonthlyCoreCTC)
    setCoreSalaryA(employeeProposedDetails?.newYearlyCoreCTC)
    setFullSalaryA(employeeProposedDetails?.newYearlyFullCTC)
    setFullSalaryM(employeeProposedDetails?.newMonthlyFullCTC)
  }, [employeeProposedDetails]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      "appraisalCycleId": Number(modalData.cycleId),
      "employeeId": Number(modalData.eid),
      "incrementAmount": Number(increasedAmount),
      "incrementPercentage": Number(increasedPercent),
      "newMonthlyCoreCTC": Number(coreSalaryM),
      "newMonthlyFullCTC": Number(fullSalaryM),
      "newYearlyCoreCTC": Number(coreSalaryA),
      "newYearlyFullCTC": Number(fullSalaryA),
      "promotionId": promotion === "" || promotion === "Choose Promotion" ? null : Number(promotion),
      "rewardId": award === "" || award === "Choose Reward" ? null : Number(award),
      "applicableDate": applicableDate
    }
    // if (!data.incrementAmount) {
    //   setError(true);
    //   return;
    // }
    createAppraisalMutation.mutate(data);
    setShowForm(false);
    setShowForm(false);
    setAward(null)
    setPromotion(null)
    setIncreasedPercent("")
    setIncreasedAmount("")
    setCoreSalaryM("")
    setCoreSalaryA("")
    setFullSalaryA("")
    setFullSalaryM("")
    setApplicableDate('')
  };

  const handleCancel = () => {
    setShowForm(false);
    setAward(null)
    setPromotion(null)
    setIncreasedPercent("")
    setIncreasedAmount("")
    setCoreSalaryM("")
    setCoreSalaryA("")
    setFullSalaryA("")
    setFullSalaryM("")

  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
    const arrowKeys = ["ArrowUp", "ArrowDown"];
    if (arrowKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  // Event handler to prevent value change on mouse wheel scroll
  const handleClick = (e) => {
    e.target.addEventListener("mousewheel", (event) => {
      e.target.blur();
      // Your event handling code here
    });
  };


  //   Input Handler Functions
  const handleAmount = (e) => {
    let amt = e.target.value.replace(/[^0-9.-]/g, "");
    let newAmount = parseInt(amt, 10)
    setIncreasedAmount(newAmount);
    setIncreasedPercent(((Number(amt) * 100) / Number(currentDetailsObj.currentMonthlyCoreCTC)).toFixed(1));
    setCoreSalaryM((Number(amt) + Number(currentDetailsObj.currentMonthlyCoreCTC)).toFixed(0));
    setCoreSalaryA(((Number(amt) + Number(currentDetailsObj.currentMonthlyCoreCTC)) * 12).toFixed(0));
    setFullSalaryM((Number(currentDetailsObj.currentMonthlyFullCTC) + Number(amt)).toFixed(0));
    setFullSalaryA((Number(currentDetailsObj.currentYearlyFullCTC) + (Number(amt) * 12)).toFixed(0))
  }

  const handlePercent = (e) => {
    setIncreasedPercent((e.target.value.replace("e", "")));
    let amt =
      Number((Number(currentDetailsObj.currentMonthlyCoreCTC) * Number(e.target.value.replace("e", ""))) /
        100);
    setIncreasedAmount(amt.toFixed(0));
    setCoreSalaryM((amt + Number(currentDetailsObj.currentMonthlyCoreCTC)).toFixed(0));
    setCoreSalaryA(((amt + Number(currentDetailsObj.currentMonthlyCoreCTC)) * 12).toFixed(0));
    setFullSalaryM((Number(currentDetailsObj.currentMonthlyFullCTC) + Number(amt)).toFixed(0));
    setFullSalaryA((Number(currentDetailsObj.currentYearlyFullCTC) + (Number(amt) * 12)).toFixed(0))
  };

  const handleCoreSalaryM = (e) => {
    setCoreSalaryM((e.target.value.replace("e", "")));
    let amt = (Number(e.target.value.replace("e", "")) - Number(currentDetailsObj.currentMonthlyCoreCTC));
    setIncreasedAmount((amt).toFixed(0));
    setIncreasedPercent((amt * 100 / Number(currentDetailsObj.currentMonthlyCoreCTC)).toFixed(1))
    setCoreSalaryA(((e.target.value.replace("e", "")) * 12).toFixed(0))
    setFullSalaryM((Number(currentDetailsObj.currentMonthlyFullCTC) + Number(amt)).toFixed(0));
    setFullSalaryA((Number(currentDetailsObj.currentYearlyFullCTC) + (Number(amt) * 12)).toFixed(0))

  }

  const handleInputChange = (e) => {
    setApplicableDate(e.target.value)
  }
  const handleCoreSalaryA = (e) => {
    setCoreSalaryA((e.target.value.replace("e", "")));
    let amt = ((Number(e.target.value.replace("e", "")) / 12) - Number(currentDetailsObj.currentMonthlyCoreCTC));
    setIncreasedAmount(amt.toFixed(0));
    setIncreasedPercent((amt * 100 / Number(currentDetailsObj.currentMonthlyCoreCTC)).toFixed(1))
    setCoreSalaryM(((e.target.value.replace("e", "")) / 12).toFixed(0))
    setFullSalaryM((Number(currentDetailsObj.currentMonthlyFullCTC) + Number(amt)).toFixed(0));
    setFullSalaryA((Number(currentDetailsObj.currentYearlyFullCTC) + (Number(amt) * 12)).toFixed(0))

  }
  const handleFullSalaryM = (e) => {
    setFullSalaryM(e.target.value.replace("e", ""));
  }

  return (
    <div className={style.container}>
      {/* <h5 className={style.title}>Appraisal Details</h5> */}
      <div className={style.form}>
        <form >
          <div className={style.form_row}>
            <div>
              <label className={style.label} htmlFor="Reward"> Choose Reward</label>
              <div>
                <div className={style.select}>
                  <select className={style.selectStyling}
                    name="Choose Reward"
                    value={award}
                    onChange={(e) => setAward(e.target.value)}
                  >
                    <option value={null}>Choose Reward</option>
                    {rewardList?.map((item) => (
                      <option value={item?.id} key={item?.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div>
              <label className={style.label} htmlFor="field2">Promotion Name</label>
              <div>
                <div className={style.select}>
                  <select
                    className={style.selectStyling}
                    name="Choose Promotion"
                    value={promotion}
                    onChange={(e) => setPromotion(e.target.value)}
                  >
                    <option value={null}>Choose Promotion</option>
                    {promotionList?.map((item) => (
                      <option value={item?.id} key={item?.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div>
              <label className={style.label} htmlFor="field2">Increment Amount</label>
              <input
                placeholder="Enter Increment Amount"
                className={style.input_field}
                type="number"
                id="incrementAmount"
                name="incrementAmount"
                value={increasedAmount}
                onChange={(e) => handleAmount(e)}
                onKeyDown={handleKeyDown}
                onClick={handleClick}
              />
              <span
                className={`${style.errorText} ${error ? style.showError : style.hideError
                  }`}
              >
                *Please Enter Increment Amount
              </span>
            </div>
            <div>
              <label className={style.label} htmlFor="field2">Increment Percentage</label>
              <input
                placeholder="Enter Increment Percentage"
                className={style.input_field}
                type="number"
                id="increasedPercent"
                name="increasedPercent"
                value={increasedPercent}
                onChange={(e) => handlePercent(e)}
                onKeyDown={handleKeyDown}
                onClick={handleClick}

              />
              <span
                className={`${style.errorText} ${error ? style.showError : style.hideError
                  }`}
              >
                *Please Enter Increment Percentage
              </span>

            </div>

          </div>
          {/* Input fields */}
          <div className={style.form_row}>
            <div>
              <label className={style.label} htmlFor="field1">New Core CTC (M)</label>
              <input
                placeholder="New Monthly Core CTC"
                className={style.input_field}
                type="number"
                id="coreSalaryM"
                name="coreSalaryM"
                value={coreSalaryM}
                onChange={(e) => handleCoreSalaryM(e)}
                onKeyDown={handleKeyDown}
                onClick={handleClick}

              />
              <span
                className={`${style.errorText} ${error ? style.showError : style.hideError
                  }`}
              >
                *Please Enter New Monthly Core CTC
              </span>

            </div>
            <div>
              <label className={style.label} htmlFor="field2">New Core CTC (A)</label>
              <input
                placeholder="New Annual Core CTC"
                className={style.input_field}
                type="number"
                id="coreSalaryA"
                name="coreSalaryA"
                value={coreSalaryA}
                onChange={(e) => handleCoreSalaryA(e)}
                onKeyDown={handleKeyDown}
                onClick={handleClick}

              />
              <span
                className={`${style.errorText} ${error ? style.showError : style.hideError
                  }`}
              >
                *Please Enter New Annual Core CTC
              </span>

            </div>
            <div>
              <label className={style.label} htmlFor="field2">New Full CTC (M)</label>
              <input
                placeholder="New Monthly Full CTC"
                className={style.input_field}
                type="number"
                id="fullSalaryM"
                name="fullSalaryM"
                value={fullSalaryM}
                onChange={(e) => handleFullSalaryM(e)}
                // onChange={(e)=>setFullSalaryM(e.target.value)}
                onKeyDown={handleKeyDown}
                onClick={handleClick}

              />
              <span
                className={`${style.errorText} ${error ? style.showError : style.hideError
                  }`}
              >
                *Please Enter New Monthly Full CTC
              </span>

            </div>
            <div>
              <label className={style.label} htmlFor="field2">New Full CTC (A)</label>
              <input
                placeholder="New Annual Full CTC"
                className={style.input_field}
                type="number"
                id="fullSalaryA"
                name="fullSalaryA"
                value={fullSalaryA}
                onChange={(e) => setFullSalaryA(e.target.value)}
                onKeyDown={handleKeyDown}
                onClick={handleClick}

              />
              <span
                className={`${style.errorText} ${error ? style.showError : style.hideError
                  }`}
              >
                *Please Enter New Monthly Full CTC
              </span>

            </div>
          </div>
          <div className={style.form_row}>
            <div>
              <label className={style.label} htmlFor="field1">Applicable Date</label>
              <br></br>
              <input
                type="date" 
                name="startDate"
                className={style.input_field}
                value={applicableDate}
                onChange={handleInputChange}
                id="startDate"
              />
              <span
                className={`${style.errorText} ${error ? style.showError : style.hideError
                  }`}
              >
                *Please Select date
              </span>

            </div>
          </div>
          {/* Submit and Cancel buttons */}
          <div className={style.buttons}>
            <button type="submit" style={{ backgroundColor: "#855ea1", border: "1px solid #855ea1" }} onClick={handleSubmit}>Submit</button>
            <button type="button" style={{ backgroundColor: "#855ea1", border: "1px solid #855ea1" }} onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AppraisalInputForm;
