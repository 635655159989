import React from "react";
import { ViewQuestionnaireCom } from "./component/ViewQuestionnaireCom";
import style from "./ViewQuestionnaire.module.css";
import QuestionnaireSidebar from "../sidebar/SideBar";
export const ViewQuestionnaire = () => {
  return (
    <>
      <div className={style.mainSection}>
        <QuestionnaireSidebar />
        <ViewQuestionnaireCom />
      </div>
    </>
  );
};
