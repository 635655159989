
import AtsNavigation from "../Pages/atsDashboard/navigation/AtsNavigation.js";
import Feedback from "../Pages/atsDashboard/pageLayout/emailComp/Feedback.jsx";
import ViewCandidate from "../Pages/atsDashboard/pageLayout/viewCandidate/ViewCandidate.js";
import ViewOffer from "../Pages/atsDashboard/pageLayout/viewOffer/ViewOffer.js";
import ViewPosition from "../Pages/atsDashboard/pageLayout/viewPosition/ViewPosition.js";
import GeneRateLink from "../Pages/ClientQuestionnaire/CommonComponent/GenerateLink/GenerateLink.js";

// function AtsRoute() {
//   return (
//     <Route>
//       <Route exact path="/ats-navigation" component={AtsNavigation} />
//       <Route exact path="/FeedbackByEmail/:id" component={Feedback} />
//       <Route
//         exact
//         path="/viewQuestionDetailsById/:token"
//         component={GeneRateLink}
//       />
//     </Route>
//   );
// }
const AtsRoute = [
  {
    path: "/ats-navigation",
    component: AtsNavigation
  },
  {
    path: "/ats-navigation/view-candidate/:candidateId",
    component: ViewCandidate
  },
  {
    path: "/ats-navigation/view-position/:positionId",
    component: ViewPosition
  },
  {
    path: "/FeedbackByEmail/:id",
    component: Feedback
  },
  {
    path: "/viewQuestionDetailsById/:token",
    component: GeneRateLink
  },
  {
    path: "/viewOffer/:offerId/:candidateId",
    component: ViewOffer
  },
  {
    path: "/viewCandidate/:candidateName/:candidateId",
    component: ViewCandidate
  }
];

export default AtsRoute;
