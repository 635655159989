import React from "react";
import * as Img from "../../../../assets";
import moment from "moment";

export const CustomInputCross = React.forwardRef(({ setEndDate, setFilter, endDate, placeholder, onClick, icon }, ref) => {

  const formatDate = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    const date = new Date(`${year}-${month}-${day}`);
    const options = { year: '2-digit', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-GB', options).replace(' ', ' ').replace(',', '');
};

  
  return (  
    <>
      <span onClick={onClick}
        ref={ref}
        style={{
          cursor: "pointer",
          fontWeight: "400",
        }}
      >
        {formatDate(endDate)}
      </span>
      <img
        src={Img.cross}
        width="8px"
        height="8px"
        alt="Cross"
        style={{
          position: "absolute",
          right: "7%",
          top: "43%",
          cursor: "pointer",
        }}
        onClick={() => {
          setEndDate("");
          setFilter((prev) => {
            return { ...prev, endDate: "" }
          });
        }}
        onKeyDown={() => {
          setEndDate("");
          setFilter((prev) => {
            return { ...prev, endDate: "" }
          });
        }}
        tabIndex={0}
      />
    </>
  );
});

export const CustomInputClg = React.forwardRef((props, ref) => {
  return (
    <div onClick={props.onClick}
      onKeyDown={props.onClick}>
      <span
        ref={ref}
        style={{
          cursor: "pointer",
          fontWeight: "400",
        }}
      >
        {props.value || props.placeholder}
      </span>
      <img
        src={
          props.icon
        }
        alt="calender"
        style={
          props.icon === "cc" || "pm"
            ? {
              position: "absolute",
              right: "5%",
              top: "10%",
              cursor: "pointer",
              width: "20px",
            }
            : {
              position: "absolute",
              right: "5%",
              top: "10%",
              cursor: "pointer",
            }
        }
      />
    </div>
  );
});
