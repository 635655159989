import React from "react";
import style from "./invoiceCantainer.module.css";

const InvoiceContainer = ({ children, styles }) => {
  return (
    <div className={style.invoicecontainer} style={styles}>
      {children}
    </div>
  );
};

export default InvoiceContainer;
