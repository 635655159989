import React from "react";
import style from "./filter.module.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import { fi } from "date-fns/locale";
const FilterButton = ({ setShowFilter, filterData }) => {
  return (
    <>
      <button
        className={
          filterData?.status !== "" ||
          filterData?.category !== "" ||
          filterData?.MonthId !== "" ||
          filterData?.VendorId !== "" ||
          filterData?.PaymentDateId !== ""
            ? style.filterActive
            : style.filter
        }
        onClick={() => setShowFilter(true)}
      >
        <FilterListIcon />
        Filter
      </button>
    </>
  );
};

export default FilterButton;
