import React from 'react';
import { Route } from "react-router-dom";
import Header from "../commonComponent/Header";
import SideBar from "../commonComponent/SideBar";
// import TaskAssignedByMeDetails from './component/TaskAssignedByMeDetails'; 
import TaskClosedByMeComponent from './component/TaskClosedByMeComponent';
import style from "./ClosedTaskByMe.module.css";

const ClosedTaskByMe = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <TaskClosedByMeComponent />
      </div>
    </div>
  );
}

export default ClosedTaskByMe