import React, { useEffect, useState } from "react";
import BreadCrum from "../../../breadcrums/BreadCrum";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import { FaTrash } from "react-icons/fa";
import style from "./CreateExp.module.css";
import Select from "react-select";
import axios from "axios";
import cookie from "react-cookies";
import calender from "../../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import { InvoiceStyles, InvoiceStylesVendorName } from "../../../utils/style";
import dropdownIcon from "../../../../../assets/downIcon.png";
import cancel from "../../../../../assets/cancell.png";
import {
  getVendorName,
  getMonth,
  getExpenseCategory,
  getcopyExpense,
  createvendor,
  createcategory,
  getYearData,
  gettdsdetailsData,
  GetgstnumberData,
  getresourcedetails,
  createExpense,
  gettdsdetails,
  getExpenseId,
  getGstPercentage,
  getPaymentMode,
  GetpannumberData,
  uploadExpInvFile,
  deleteExpInvFile,
} from "../../../../../Utils/axios/expenceApi.js";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { Col, Modal, Row } from "react-bootstrap";
import { ToastifyError } from "../../../../../App";
import UploadMultiFile from "../../../uploadMultipleFile/UploadMultiFile.js";
import { notifyError } from "../../../../atsDashboard/utils/notify.js";
const apiKey = process.env.REACT_APP_Expence_URL;


const CreateExpense = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [lineItemArray, setLineItemArray] = useState([1]);
  const [lineItemArray2, setLineItemArray2] = useState([1]);
  const [validate, setValidate] = React.useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isInvoiceExist, setIsInvoiceExist] = useState(false);

  const [VendorAdd, setVendorAdd] = useState();
  const [VendorAdd1, setVendorAdd1] = useState();
  const [venderType, setVerderType] = useState(null);
  // copy No expense

  const { data: gstPercentageList } = useQuery(
    ["getGstPercentageList"],
    getGstPercentage
  );
  const gstPercentageOption = gstPercentageList
    ?.filter((item) => item?.gstPercentage !== 0)
    ?.map((e) => ({
      value: e.id,
      label: e.gstPercentage,
    }));

  const { data: paymentModeList } = useQuery(
    ["getPaymentModeList"],
    getPaymentMode
  );

  const handleInvoice = async (partnerId, invoiceNumber,yearId) => {
    axios
      .get(
        `${apiKey}/existsInvoiceNumberByPartnerIdAndServiceYearId?invoiceNumber=${invoiceNumber}&partnerId=${partnerId}&serviceYearId=${yearId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          setIsInvoiceExist(true);
        } else {
          setIsInvoiceExist(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  const paymentModeOption = paymentModeList
    ?.filter((item) => item?.paymentMode !== "Credit Note")
    ?.map((item) => ({
      value: item?.id,
      label: item?.paymentMode,
    }));

  const [formData, setFormData] = useState({
    formData: "",
    copyOfExpense: false,
    vendorId: "",
    vendorName: "",
    discount: "",
    otherCharges: "",
    igst: "",
    sgst: "",
    cgst: "",
    gst: "",
    tds: "",
    gstNo: "",
    baseAmount: "",
    documentResponses: [],
    baseAmountVendor: "",
    totalBill: "",
    amountToBePaid: "",
    gstApplicable: true,
    category: "",
    categoryName: "",
    paymentDueOn: "",
    dateOfExpense: "",
    invoice: "",
    approveBy: "",
    serviceMonth: "",
    serviceMonthId: "",
    serviceYear: "",
    serviceYearId: "",
    expenseMonth: "",
    expenseYear: "",
    gstPercentage: 5,
    paymentMode: "",
    paymentNote: "",
    paymentModeId: "",
    payable1: "",
    payable2: "",
    lineItemDetails: [
      {
        resourseId: "",
        candidateName: "",
        expenseMonth: "",
        expenseYear: "",
        billingRate: "",
        workingDays: "",
        maxWorkingDays: "",
        baseAmount: "",
        description: "",
      },
    ],
    lineItemDetailsVendor: [
      {
        description: "",
        numberOfUnit: "",
        unitAmount: "",
        vendorBaseAmount: "",
      },
    ],
  });
  const { data: ExpenseDataById } = useQuery(
    ["getcopyExpensechf", formData?.vendorId],
    () =>
      formData?.vendorId &&
      prevData?.page2 === "createExpense" &&
      getcopyExpense(formData?.vendorId)
  );

  const { data: ExpenseDataByEditId } = useQuery(["getExpenseId"], () => {
    if (prevData?.page2 === "editExpense") {
      return getExpenseId(prevData?.idData);
    }
  });

  // copy Yes expense
  const datatable = formData?.copyOfExpense
    ? moment(ExpenseDataById?.dateOfExpense)?.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    : null;

  const datatable2 = formData?.copyOfExpense
    ? moment(ExpenseDataById?.paymentDueOn)?.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    : null;

  const [formData1, setFormData1] = useState({
    formData1: "",
    copyOfExpense: formData?.copyOfExpense,
    vendorId: formData?.vendorId,
    igst: ExpenseDataById?.igst,
    sgst: ExpenseDataById?.sgst,
    cgst: ExpenseDataById?.cgst,
    discount: ExpenseDataById?.discount,
    otherCharges: ExpenseDataById?.otherCharges,
    gst: ExpenseDataById?.gst,
    documentResponses: ExpenseDataByEditId?.documentResponses?.map(item => item.documentURL),
    baseAmount: ExpenseDataById?.baseAmount,
    totalBill: ExpenseDataById?.totalBill,
    amountToBePaid: ExpenseDataById?.amountToBePaid,
    gstApplicable: ExpenseDataById?.gstApplicable,
    invoice: ExpenseDataById?.invoice,
    title: ExpenseDataById?.title,
    dateOfExpense: datatable,
    paymentDueOn: datatable2,
    expenseMonth: ExpenseDataById?.expenseMonth,
    expenseYear: ExpenseDataById?.expenseYear,
    paymentNote: ExpenseDataById?.paymentNote,
    serviceMonth: ExpenseDataById?.serviceMonth,
    paymentModeId: ExpenseDataById?.paymentModeId,
    paymentMode: ExpenseDataById?.paymentMode,
    serviceMonthId: ExpenseDataById?.serviceMonthId,
    serviceYear: ExpenseDataById?.serviceYear,
    serviceYearId: ExpenseDataById?.serviceYearId,
    category: ExpenseDataById?.categoryName,
    categoryName: ExpenseDataById?.categoryName,
    gstPercentage: ExpenseDataById?.gstPercentage,
    payable1: (ExpenseDataById?.amountToBePaid ? Number(ExpenseDataById?.amountToBePaid) : 0) - (ExpenseDataById?.gst ? Number(ExpenseDataById?.gst) : 0),
    payable2: (ExpenseDataById?.gst ? Number(ExpenseDataById?.gst) : 0),
    lineItemDetails: ExpenseDataById
      ? ExpenseDataById?.lineItemDetails?.map((ele) => {
        return {
          baseAmount: ele?.baseAmount,
          billingRate: ele?.billingRate,
          expenseMonth: ele?.expenseMonth,
          expenseYear: ele?.expenseYear,
          maxWorkingDays: ele?.maxWorkingDays,
          workingDays: ele?.workingDays,
          resourseId: ele?.resourseId,
          description: ele?.description,
          candidateName: ele?.candidateName,
        };
      })
      : [
        {
          baseAmount: "",
          billingRate: "",
          expenseMonth: "",
          expenseYear: "",
          maxWorkingDays: "",
          workingDays: "",
          resourseId: "",
          description: "",
        },
      ],
    lineItemDetailsVendor: ExpenseDataById
      ? ExpenseDataById?.lineItemDetails?.map((ele) => {
        return {
          description: ele?.description,
          numberOfUnit: ele?.numberOfUnit,
          unitAmount: ele?.unitAmount,
          vendorBaseAmount: ele?.vendorBaseAmount,
        };
      })
      : [
        {
          description: "",
          numberOfUnit: "",
          unitAmount: "",
          vendorBaseAmount: "",
        },
      ],
  });
  const { data: GetTDSDataItem } = useQuery(
    ["gettdsdetailsData", formData?.vendorId, formData1?.vendorId],
    () => {
      if (formData?.vendorId) {
        return gettdsdetailsData(formData?.vendorId);
      }

      if (formData1?.vendorId) {
        return gettdsdetailsData(formData1?.vendorId);
      }
    }
  );

  const { data: getClientName } = useQuery(
    [
      "getresourcedetails",
      formData?.vendorId,
      formData?.serviceMonthId,
      formData?.serviceYearId,
      formData1?.vendorId,
      formData1?.serviceMonthId,
      formData1?.serviceYearId,
    ],
    () => {
      if (
        formData?.vendorId &&
        (formData?.serviceMonthId === 0 || formData?.serviceMonthId) &&
        formData?.serviceYearId
      ) {
        return getresourcedetails(
          formData?.vendorId,
          formData?.serviceMonthId,
          formData?.serviceYearId
        );
      }

      if (
        formData1?.vendorId &&
        (formData1?.serviceMonthId === 0 || formData1?.serviceMonthId) &&
        formData1?.serviceYearId
      ) {
        return getresourcedetails(
          formData1?.vendorId,
          formData1?.serviceMonthId,
          formData1?.serviceYearId
        );
      }
    }
  );

  const clientNameOption =
    getClientName &&
    getClientName?.map((i) => ({
      value: i?.resourseId,
      label: i?.resourseName,
      rate: i?.rate,
      type: i?.type,
    }));

  const { data: GetgstData } = useQuery(
    ["GetgstnumberData", formData?.vendorId, formData1?.vendorId],
    () =>
      (formData?.vendorId || formData1?.vendorId) &&
      GetgstnumberData(formData?.vendorId || formData1?.vendorId)
  );

  const { data: GetpanData } = useQuery(
    ["GetpannumberData", formData?.vendorId, formData1?.vendorId],
    () =>
      (formData?.vendorId || formData1?.vendorId) &&
      GetpannumberData(formData?.vendorId || formData1?.vendorId)
  );

  useEffect(() => {
    if (GetpanData?.pan) {
      setFormData((prev) => ({
        ...prev,
        pan: GetpanData?.pan,
      }));

      setFormData1((prev) => ({
        ...prev,
        pan: GetpanData?.pan,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        pan: null,
      }));

      setFormData1((prev) => ({
        ...prev,
        pan: null,
      }));
    }
  }, [GetpanData]);

  useEffect(() => {
    if (GetgstData?.gstNumber) {
      setFormData((prev) => ({
        ...prev,
        gstNo: GetgstData?.gstNumber,
      }));

      setFormData1((prev) => ({
        ...prev,
        gstNo: GetgstData?.gstNumber,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        gstNo: null,
      }));

      setFormData1((prev) => ({
        ...prev,
        gstNo: null,
      }));
    }
  }, [GetgstData]);

  const { data: getTdsDetails } = useQuery(
    ["gettdsdetails", formData?.vendorId, formData1?.vendorId],
    () => {
      if (formData?.vendorId) {
        return gettdsdetails(formData?.vendorId);
      }

      if (formData1?.vendorId) {
        return gettdsdetails(formData1?.vendorId);
      }
    }
  );

  useEffect(() => {
    if (prevData?.page2 === "editExpense") {
      if (ExpenseDataByEditId?.copyOfExpense === true) {
        setFormData((prevValue) => ({
          ...prevValue,
          copyOfExpense: ExpenseDataByEditId?.copyOfExpense,
        }));
        setFormData1((prevValue) => ({
          ...prevValue,
          copyOfExpense: ExpenseDataByEditId?.copyOfExpense,
          vendorId: ExpenseDataByEditId?.vendorId,
          vendorName: ExpenseDataByEditId?.vendorName,
          discount: ExpenseDataByEditId?.discount,
          otherCharges: ExpenseDataByEditId?.otherCharges,
          igst: ExpenseDataByEditId?.igst,
          sgst: ExpenseDataByEditId?.sgst,
          cgst: ExpenseDataByEditId?.cgst,
          gst: ExpenseDataByEditId?.gst,
          tds: ExpenseDataByEditId?.tds,
          paymentModeId: ExpenseDataByEditId?.paymentModeId,
          paymentMode: ExpenseDataByEditId?.paymentMode,
          paymentNote: ExpenseDataByEditId?.paymentNote,
          documentResponses: ExpenseDataByEditId?.documentResponses?.map(item => item?.documentURL),
          baseAmount: ExpenseDataByEditId?.baseAmount,
          totalBill: ExpenseDataByEditId?.totalBill,
          amountToBePaid: ExpenseDataByEditId?.amountToBePaid,
          gstApplicable: ExpenseDataByEditId?.gstApplicable,
          invoice: ExpenseDataByEditId?.invoice,
          title: ExpenseDataByEditId?.title,
          approveBy: ExpenseDataByEditId?.approveBy,
          dateOfExpense: moment(ExpenseDataByEditId?.dateOfExpense).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ),
          expenseMonth: ExpenseDataByEditId?.expenseMonth,
          expenseYear: ExpenseDataByEditId?.expenseYear,
          serviceMonth: ExpenseDataByEditId?.serviceMonth,
          serviceMonthId: ExpenseDataByEditId?.serviceMonthId,
          serviceYearId: ExpenseDataByEditId?.serviceYearId,
          serviceYear: ExpenseDataByEditId?.serviceYear,
          category: ExpenseDataByEditId?.category,
          categoryName: ExpenseDataByEditId?.categoryName,
          gstPercentage: ExpenseDataByEditId?.gstPercentage,
          paymentDueOn: moment(ExpenseDataByEditId?.paymentDueOn).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ),
          payable1: (ExpenseDataByEditId?.amountToBePaid ? Number(ExpenseDataByEditId?.amountToBePaid) : 0) - (ExpenseDataByEditId?.gst ? Number(ExpenseDataByEditId?.gst) : 0),
          payable2: (ExpenseDataByEditId?.gst ? Number(ExpenseDataByEditId?.gst) : 0),
          lineItemDetails: ExpenseDataByEditId
            ? ExpenseDataByEditId?.lineItemDetails?.map((ele) => {
              return {
                baseAmount: ele?.baseAmount,
                billingRate: ele?.billingRate,
                expenseMonth: ele?.expenseMonth,
                expenseYear: ele?.expenseYear,
                maxWorkingDays: ele?.maxWorkingDays,
                workingDays: ele?.workingDays,
                resourseId: ele?.resourseId,
                description: ele?.description,
                candidateName: ele?.candidateName,
              };
            })
            : [
              {
                baseAmount: "",
                billingRate: "",
                expenseMonth: "",
                expenseYear: "",
                maxWorkingDays: "",
                workingDays: "",
                resourseId: "",
                description: "",
                candidateName: "",
              },
            ],
          lineItemDetailsVendor: ExpenseDataByEditId
            ? ExpenseDataByEditId?.lineItemDetails?.map((ele) => {
              return {
                description: ele?.description,
                numberOfUnit: ele?.numberOfUnit,
                unitAmount: ele?.unitAmount,
                vendorBaseAmount: ele?.vendorBaseAmount,
              };
            })
            : [
              {
                description: "",
                numberOfUnit: "",
                unitAmount: "",
                vendorBaseAmount: "",
              },
            ],
        }));
      } else {
        setFormData((prevValue) => ({
          ...prevValue,
          copyOfExpense: ExpenseDataByEditId?.copyOfExpense,
          vendorId: ExpenseDataByEditId?.vendorId,
          vendorName: ExpenseDataByEditId?.vendorName,
          categoryName: ExpenseDataByEditId?.categoryName,
          discount: ExpenseDataByEditId?.discount,
          otherCharges: ExpenseDataByEditId?.otherCharges,
          igst: ExpenseDataByEditId?.igst,
          sgst: ExpenseDataByEditId?.sgst,
          cgst: ExpenseDataByEditId?.cgst,
          gst: ExpenseDataByEditId?.gst,
          tds: ExpenseDataByEditId?.tds,
          paymentModeId: ExpenseDataByEditId?.paymentModeId,
          paymentMode: ExpenseDataByEditId?.paymentMode,
          paymentNote: ExpenseDataByEditId?.paymentNote,
          baseAmount: ExpenseDataByEditId?.baseAmount,
          documentResponses: ExpenseDataByEditId?.documentResponses?.map(item => item?.documentURL),
          baseAmountVendor: ExpenseDataByEditId?.baseAmount,
          totalBill: ExpenseDataByEditId?.totalBill,
          amountToBePaid: ExpenseDataByEditId?.amountToBePaid,
          gstApplicable: ExpenseDataByEditId?.gstApplicable,
          invoice: ExpenseDataByEditId?.invoice,
          title: ExpenseDataByEditId?.title,
          approveBy: ExpenseDataByEditId?.approveBy,
          dateOfExpense: moment(ExpenseDataByEditId?.dateOfExpense).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ),
          expenseMonth: ExpenseDataByEditId?.expenseMonth,
          expenseYear: ExpenseDataByEditId?.expenseYear,
          serviceMonth: ExpenseDataByEditId?.serviceMonth,
          serviceYear: ExpenseDataByEditId?.serviceYear,
          serviceMonthId: ExpenseDataByEditId?.serviceMonthId,
          serviceYearId: ExpenseDataByEditId?.serviceYearId,
          category: ExpenseDataByEditId?.category,
          gstPercentage: ExpenseDataByEditId?.gstPercentage,
          paymentDueOn: moment(ExpenseDataByEditId?.paymentDueOn).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ),
          payable1: (ExpenseDataByEditId?.amountToBePaid ? Number(ExpenseDataByEditId?.amountToBePaid) : 0) - (ExpenseDataByEditId?.gst ? Number(ExpenseDataByEditId?.gst) : 0),
          payable2: (ExpenseDataByEditId?.gst ? Number(ExpenseDataByEditId?.gst) : 0),
          lineItemDetails: ExpenseDataByEditId?.lineItemDetails
            ? ExpenseDataByEditId?.lineItemDetails?.map((ele) => {
              return {
                baseAmount: ele?.baseAmount,
                billingRate: ele?.billingRate,
                expenseMonth: ele?.expenseMonth,
                expenseYear: ele?.expenseYear,
                maxWorkingDays: ele?.maxWorkingDays,
                workingDays: ele?.workingDays,
                resourseId: ele?.resourseId,
                description: ele?.description,
                candidateName: ele?.candidateName,
              };
            })
            : [
              {
                baseAmount: "",
                billingRate: "",
                expenseMonth: "",
                expenseYear: "",
                maxWorkingDays: "",
                workingDays: "",
                resourseId: "",
                description: "",
                candidateName: "",
              },
            ],
          lineItemDetailsVendor: ExpenseDataByEditId?.lineItemDetails
            ? ExpenseDataByEditId?.lineItemDetails?.map((ele) => {
              return {
                description: ele?.description,
                numberOfUnit: ele?.numberOfUnit,
                unitAmount: ele?.unitAmount,
                vendorBaseAmount: ele?.vendorBaseAmount,
              };
            })
            : [
              {
                description: "",
                numberOfUnit: "",
                unitAmount: "",
                vendorBaseAmount: "",
              },
            ],
        }));
      }
    }
  }, [ExpenseDataByEditId, prevData?.page2]);

  useEffect(() => {
    if (ExpenseDataByEditId?.venderType && prevData?.page2 === "editExpense") {
      setVerderType({ type: ExpenseDataByEditId?.venderType });
    }
  }, [ExpenseDataByEditId?.venderType, prevData?.page2]);

  useEffect(() => {
    if (
      formData?.copyOfExpense === true &&
      prevData?.page2 === "createExpense"
    ) {
      setFormData1((prevValue) => ({
        ...prevValue,
        copyOfExpense: prevValue?.copyOfExpense,
        vendorId: prevValue?.vendorId,
        categoryName: ExpenseDataById?.categoryName,
        igst: ExpenseDataById?.igst,
        sgst: ExpenseDataById?.sgst,
        cgst: ExpenseDataById?.cgst,
        gst: ExpenseDataById?.gst,
        discount: ExpenseDataById?.discount,
        otherCharges: ExpenseDataById?.otherCharges,
        paymentModeId: ExpenseDataById?.paymentModeId,
        paymentMode: ExpenseDataById?.paymentMode,
        tds: GetTDSDataItem?.tdsPercentage,
        paymentNote: ExpenseDataById?.paymentNote,
        baseAmount: ExpenseDataById?.baseAmount,
        documentResponses: ExpenseDataById?.documentResponses?.map(item => item?.documentURL),
        totalBill: ExpenseDataById?.totalBill,
        amountToBePaid: ExpenseDataById?.amountToBePaid,
        gstApplicable: ExpenseDataById?.gstApplicable,
        invoice: ExpenseDataById?.invoice,
        title: ExpenseDataById?.title,
        approveBy: ExpenseDataById?.approveBy,
        dateOfExpense: moment(ExpenseDataById?.dateOfExpense).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        ),
        expenseMonth: ExpenseDataById?.expenseMonth,
        expenseYear: ExpenseDataById?.expenseYear,
        serviceMonth: ExpenseDataById?.serviceMonth,
        serviceYear: ExpenseDataById?.serviceYear,
        serviceMonthId: ExpenseDataById?.serviceMonthId,
        serviceYearId: ExpenseDataById?.serviceYearId,
        category: ExpenseDataById?.category,
        gstPercentage: ExpenseDataById?.gstPercentage,
        paymentDueOn: moment(ExpenseDataById?.paymentDueOn).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        ),
        payable1: (ExpenseDataById?.amountToBePaid ? Number(ExpenseDataById?.amountToBePaid) : 0) - (ExpenseDataById?.gst ? Number(ExpenseDataById?.gst) : 0),
        payable2: (ExpenseDataById?.gst ? Number(ExpenseDataById?.gst) : 0),
        lineItemDetails: ExpenseDataById?.lineItemDetails
          ? ExpenseDataById?.lineItemDetails?.map((ele) => {
            return {
              baseAmount: ele?.baseAmount,
              billingRate: ele?.billingRate,
              expenseMonth: ele?.expenseMonth,
              expenseYear: ele?.expenseYear,
              maxWorkingDays: ele?.maxWorkingDays,
              workingDays: ele?.workingDays,
              resourseId: ele?.resourseId,
              description: ele?.description,
              candidateName: ele?.candidateName,
            };
          })
          : [
            {
              baseAmount: "",
              billingRate: "",
              expenseMonth: "",
              expenseYear: "",
              maxWorkingDays: "",
              workingDays: "",
              resourseId: "",
              description: "",
              candidateName: "",
            },
          ],
        lineItemDetailsVendor: ExpenseDataById?.lineItemDetails
          ? ExpenseDataById?.lineItemDetails?.map((ele) => {
            return {
              description: ele?.description,
              numberOfUnit: ele?.numberOfUnit,
              unitAmount: ele?.unitAmount,
              vendorBaseAmount: ele?.vendorBaseAmount,
            };
          })
          : [
            {
              description: "",
              numberOfUnit: "",
              unitAmount: "",
              vendorBaseAmount: "",
            },
          ],
      }));
    }
  }, [ExpenseDataById, formData?.copyOfExpense]);

  const { data: vendorData } = useQuery(["getVendorName"], getVendorName);
  const venderOption =
    vendorData &&
    vendorData?.map((e) => ({
      value: e.id,
      label: e.vendorName,
      paymentDueOn: e.paymentDueOn,
      paymentDueDays: e.paymentDueDays,
      type: e?.type,
    }));

  //No Case
  const [convertedDate, setConvertedDate] = useState(null);

  const paymentDueDateFun = (value) => {
    let date = venderOption?.find((e) => {
      return e.value === formData?.vendorId;
    });

    let dateCount = date?.paymentDueDays;

    let dOE = moment(value)?.format();

    if (dOE) {
      // Convert dOE to a Date object
      const dateOfExpense = new Date(dOE);

      // Add dateCount days to dateOfExpense
      dateOfExpense.setDate(dateOfExpense.getDate() + dateCount);

      // Format the resulting date to a string if necessary
      // For example: yyyy-mm-dd
      const updateDateString = `${dateOfExpense?.getDate()}-${dateOfExpense?.toLocaleString(
        "default",
        { month: "short" }
      )}-${dateOfExpense?.getFullYear()}`;

      const inputFormat = "DD-MMM-YYYY";
      const outputFormat = "YYYY-MM-DDTHH:mm:ssZ";

      setConvertedDate(
        moment(updateDateString, inputFormat)?.format(outputFormat)
      );
      setFormData({
        ...formData,
        paymentDueOn: convertedDate,
      });
    } else {
    }
  };

  useEffect(() => {
    if (convertedDate) {
      setFormData({
        ...formData,
        paymentDueOn: convertedDate,
      });
    }
  }, [convertedDate]);

  //Yes Case
  const [convertedDate1, setConvertedDate1] = useState(null);

  const paymentDueDateFun2 = (value) => {
    let date = venderOption?.find((e) => {
      return e.value === formData1?.vendorId;
    });

    let dateCount = date?.paymentDueDays;

    let dOE = moment(value)?.format();

    if (dateCount && dOE) {
      // Convert dOE to a Date object
      const dateOfExpense = new Date(dOE);

      // Add dateCount days to dateOfExpense
      dateOfExpense.setDate(dateOfExpense.getDate() + dateCount);

      // Format the resulting date to a string if necessary
      // For example: yyyy-mm-dd
      const updateDateString = `${dateOfExpense?.getDate()}-${dateOfExpense?.toLocaleString(
        "default",
        { month: "short" }
      )}-${dateOfExpense?.getFullYear()}`;

      const inputFormat = "DD-MMM-YYYY";
      const outputFormat = "YYYY-MM-DDTHH:mm:ssZ";

      setConvertedDate1(
        moment(updateDateString, inputFormat).format(outputFormat)
      );
      setFormData1({
        ...formData1,
        paymentDueOn: convertedDate1,
      });
    }
  };

  useEffect(() => {
    if (convertedDate1) {
      setFormData1({
        ...formData1,
        paymentDueOn: convertedDate1,
      });
    }
  }, [convertedDate1]);

  const { data: monthData } = useQuery(["getMonth"], getMonth);
  const monthOption = monthData?.map((e) => ({
    value: e.id - 1,
    label: e.month,
  }));

  const { data: expenseCategoryData } = useQuery(
    ["getExpenseCategory"],
    getExpenseCategory
  );
  const expenseCategoryOption =
    expenseCategoryData &&
    expenseCategoryData?.map((e) => ({
      value: e.id,
      label: e.expenseCategory,
    }));

  const { data: GetCategoryYear } = useQuery(["getYearData"], getYearData);
  const GetCategoryYearOption = GetCategoryYear?.map((e) => ({
    value: e.id,
    label: e.year,
    year1: e?.year1,
    year2: e?.year2,
  }));

  const mutation = useMutation(createvendor, {});
  const mutation1 = useMutation(createcategory, {});

  const uploadFileMutation = useMutation(uploadExpInvFile, {
    onSuccess: (data) => {
      setFormData({
        ...formData,
        documentResponses: [...formData.documentResponses, ...data],
      })
    },
    onError: () => {
      notifyError('File Upload failed')
    },
  });
  const handleFileChange = (e) => {
    uploadFileMutation.mutate(e.target.files);
  };
  const handleFileDelete = (fileURL) => {
    deleteExpInvFile(fileURL);
    setFormData({
      ...formData,
      documentResponses: formData.documentResponses.filter(selectedURL => selectedURL !== fileURL),
    })
  }
  const handleFileDelete1 = (fileURL) => {
    deleteExpInvFile(fileURL);
    setFormData1({
      ...formData1,
      documentResponses: formData1.documentResponses.filter(selectedURL => selectedURL !== fileURL),
    })
  }
  const vendarValue = {
    companyName: VendorAdd,
    partnerName: "",
  };

  const vendarCategory = {
    categoryName: VendorAdd1,
  };
  const handleDelete = () => {
    setShow(false);
    mutation.mutate(vendarValue);
  };

  const handleDeleteCancle = () => {
    setShow(false);
  };

  const handleCategory = () => {
    setShow1(false);
    mutation1.mutate(vendarCategory);
  };

  const handleCategoryCancle = () => {
    setShow1(false);
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
            fontWeight: "400",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() =>
            setFormData({
              ...formData,
              [props?.properties]: "",
              expenseMonth: "",
              expenseYear: "",
              paymentDueOn: null,
            })
          }
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross2 = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
            fontWeight: "400",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() =>
            setFormData1({
              ...formData1,
              [props?.properties]: "",
              expenseMonth: "",
              expenseYear: "",
              paymentDueOn: "",
            })
          }
          alt=""
        />
      </div>
    );
  });

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const getDaysInMonth = (year, month) => {
    month = HandlingData(month);
    if ([0, 1, 2].includes(month)) {
      year = year && year?.split("-")[1];
    } else {
      year = year && year?.split("-")[0];
    }
    const date = new Date(year, month, 0);
    return date.getDate();
  };

  const HandlingData = (data) => {
    switch (data) {
      case "January":
        return 1;
      case "February":
        return 2;
      case "March":
        return 3;
      case "April":
        return 4;
      case "May":
        return 5;
      case "June":
        return 6;
      case "July":
        return 7;
      case "August":
        return 8;
      case "September":
        return 9;
      case "October":
        return 10;
      case "November":
        return 11;
      case "December":
        return 12;
      default:
        return data;
    }
  };

  const handleServiceMonthChange = (e, index) => {
    let day = null;
    let temp = [...formData?.lineItemDetails];
    if (e?.label && formData?.serviceYear) {
      day = getDaysInMonth(formData?.serviceYear, e?.label);
      for (let i = 0; i < index; i++) {
        temp[i].maxWorkingDays = day;
        temp[i].workingDays = "";
      }
    }
    formData.serviceMonth = e?.label;
    formData.serviceMonthId = e?.value;
    formData.serviceYear = "";
    formData.serviceYearId = "";
    setFormData({
      ...formData,
      lineItemDetails: temp,
    });
  };

  const handleServiceYearChange = (e, index) => {
    let temp = [...formData?.lineItemDetails];
    let day = null;
    if (formData?.serviceMonth && e?.label) {
      day = getDaysInMonth(e?.label, formData?.serviceMonth);
      for (let i = 0; i < index; i++) {
        temp[i].maxWorkingDays = day;
        temp[i].workingDays = "";
      }
    }
    formData.serviceYear = e?.label;
    if ([0, 1, 2].includes(formData.serviceMonthId)) {
      formData.serviceYearId = e?.year2;
    } else {
      formData.serviceYearId = e?.year1;
    }
    setFormData({ ...formData, lineItemDetails: temp });
  };

  const handleServiceMonthChange1 = (e, index) => {
    let day = null;
    let temp = [...formData1?.lineItemDetails];
    if (e?.label && formData1?.serviceYear) {
      day = getDaysInMonth(formData1?.serviceYear, e?.label);
      for (let i = 0; i < index; i++) {
        temp[i].maxWorkingDays = day;
        temp[i].workingDays = "";
      }
    }
    formData1.serviceMonth = e?.label;
    formData1.serviceMonthId = e?.value;
    formData1.serviceYear = "";
    formData1.serviceYearId = "";
    setFormData1({
      ...formData1,
      lineItemDetails: temp,
    });
  };

  const handleServiceYearChange1 = (e, index) => {
    let temp = [...formData1?.lineItemDetails];
    let day = null;
    if (formData1?.serviceMonth && e?.label) {
      day = getDaysInMonth(e?.label, formData1?.serviceMonth);
      for (let i = 0; i < index; i++) {
        temp[i].maxWorkingDays = day;
        temp[i].workingDays = "";
      }
    }
    formData1.serviceYear = e?.label;
    if ([0, 1, 2].includes(formData1.serviceMonthId)) {
      formData1.serviceYearId = e?.year2;
    } else {
      formData1.serviceYearId = e?.year1;
    }
    setFormData1({ ...formData1, lineItemDetails: temp });
  };

  // No Case
  const handleWorkingDays = (e, index) => {
    let temp = [...formData?.lineItemDetails];
    if (Number(e?.target?.value) > Number(temp[index]?.maxWorkingDays)) {
      return;
    }
    temp[index].workingDays = e?.target?.value;
    if (
      temp[index]?.maxWorkingDays &&
      temp[index]?.workingDays &&
      temp[index]?.billingRate
    ) {
      handleBaseAmount(index);
    }
    setFormData({ ...formData, lineItemDetails: temp });
  };

  // No Case
  const handleMaxWorkingDays = (e, index) => {
    let temp = [...formData?.lineItemDetails];
    temp[index].maxWorkingDays = e?.target?.value;
    temp[index].workingDays = "";
    if (
      temp[index]?.maxWorkingDays &&
      temp[index]?.workingDays &&
      temp[index]?.billingRate
    ) {
      handleBaseAmount(index);
    }
    setFormData({ ...formData, lineItemDetails: temp });
  };

  // No Case lineitem base amount and gst calculation
  const handleBaseAmount = (index) => {
    let temp = [...formData?.lineItemDetails];
    let gstNo = GetgstData?.gstNumber;

    const workingDays = Number(temp[index]?.workingDays)
      ? Number(temp[index]?.workingDays)
      : 0;
    const maxWorkingDays = Number(temp[index]?.maxWorkingDays);
    const billingRate = Number(temp[index]?.billingRate);

    temp[index].baseAmount = Number(
      (workingDays / maxWorkingDays) * billingRate
    ).toFixed(2);

    const getBaseAmount = temp?.map((item) => Number(item?.baseAmount));

    const totalBaseAmount = getBaseAmount?.reduce(
      (acc, currValue) => acc + currValue
    );

    formData.baseAmount = Number(totalBaseAmount)?.toFixed(2);

    formData.tds = Number(
      (formData.baseAmount * getTdsDetails?.tdsPercentage) / 100
    )?.toFixed(2);

    if (!gstNo?.startsWith("09")) {
      if (formData?.gstPercentage) {
        formData.igst = Number(
          (formData?.baseAmount * formData?.gstPercentage) / 100
        )?.toFixed(2);
        formData.cgst = 0;
        formData.sgst = 0;
        formData.gst = Number(
          (formData?.baseAmount * formData?.gstPercentage) / 100
        )?.toFixed(2);
      }
    } else {
      if (formData?.gstPercentage) {
        formData.igst = 0;
        formData.cgst = Number(
          (formData?.baseAmount * (formData?.gstPercentage / 2)) / 100
        )?.toFixed(2);
        formData.sgst = Number(
          (formData?.baseAmount * (formData?.gstPercentage / 2)) / 100
        )?.toFixed(2);
        formData.gst = Number(
          ((formData?.baseAmount * (formData?.gstPercentage / 2)) / 100) * 2
        )?.toFixed(2);
      }
    }
    const otherCharges = formData?.otherCharges ? Number(formData?.otherCharges) : 0;
    const discount = formData?.discount ? Number(formData?.discount) : 0;
    formData.totalBill = (
      Number(formData?.baseAmount) + Number(formData?.gst) + otherCharges - discount
    )?.toFixed(2);

    if (formData?.gstApplicable === true) {
      formData.amountToBePaid = (
        Number(formData?.totalBill) - Number(formData?.tds)
      )?.toFixed(2);
      formData.payable1 = Number(formData?.amountToBePaid) - Number(formData?.gst);
      formData.payable2 = Number(formData?.gst);
    } else {
      formData.amountToBePaid = (
        Number(formData?.baseAmount) - Number(formData?.tds) + otherCharges - discount
      )?.toFixed(2);
    }

    // if (formData?.otherCharges) {
    //   formData.amountToBePaid = Number(
    //     Number(formData?.amountToBePaid) + Number(formData?.otherCharges)
    //   )?.toFixed(2);
    // }

    // if (formData?.discount) {
    //   formData.amountToBePaid = (
    //     Number(formData.amountToBePaid) - Number(formData?.discount)
    //   )?.toFixed(2);
    // }
  };

  const tdsCalculateFun = (value) => {
    if (venderType?.type === "Partner") {
      let totalBill = formData?.totalBill;
      let baseAmount = formData?.baseAmount;
      let amountToBePaid = totalBill - value;
      let tdsBaseAmount = baseAmount - value + Number(formData?.otherCharges) - Number(formData?.discount);
      setFormData((prev) => ({
        ...prev,
        tds: value,
        amountToBePaid:
          formData.gstApplicable === true
            ? parseFloat(amountToBePaid).toFixed(2)
            : parseFloat(tdsBaseAmount).toFixed(2),
        payable1: (Number(amountToBePaid) - Number(formData?.gst)).toFixed(2),
        payable2: Number(formData?.gst).toFixed(2),
      }));
      // if (formData?.otherCharges) {
      //   formData.amountToBePaid = Number(
      //     Number(formData?.amountToBePaid) + Number(formData?.otherCharges)
      //   )?.toFixed(2);
      // }

      // if (formData?.discount) {
      //   formData.amountToBePaid = (
      //     Number(formData.amountToBePaid) - Number(formData?.discount)
      //   )?.toFixed(2);
      // }
    } else if (venderType?.type === "Vendor") {
      let totalBill = formData?.totalBill;
      let baseAmount = formData?.baseAmountVendor;
      let amountToBePaid = totalBill - value;
      let tdsBaseAmount = baseAmount - value + Number(formData?.otherCharges) - Number(formData?.discount);
      setFormData((prev) => ({
        ...prev,
        tds: value,
        amountToBePaid:
          formData.gstApplicable === true
            ? parseFloat(amountToBePaid).toFixed(2)
            : parseFloat(tdsBaseAmount).toFixed(2),
        payable1: (Number(amountToBePaid) - Number(formData?.gst)).toFixed(2),
        payable2: Number(formData?.gst).toFixed(2),

      }));

      // if (formData?.otherCharges) {
      //   formData.amountToBePaid = Number(
      //     Number(formData?.amountToBePaid) + Number(formData?.otherCharges)
      //   )?.toFixed(2);
      // }

      // if (formData?.discount) {
      //   formData.amountToBePaid = (
      //     Number(formData.amountToBePaid) - Number(formData?.discount)
      //   )?.toFixed(2);
      // }
    }
  };

  // Yes Case
  const handleWorkingDays2 = (e, index) => {
    let temp = [...formData1?.lineItemDetails];
    const workingDays = temp[index].workingDays;
    const maxWorkingDays = temp[index].maxWorkingDays; // Replace with your maximum value

    // Validate input using regex
    const isValidInput = /^\d+$/.test(e.target.value); // Only allow positive integers
    if (isValidInput && e.target.value <= maxWorkingDays) {
      temp[index].workingDays = Number(e.target.value);
    } else if (e.target.value === "") {
      temp[index].workingDays = ""; // Revert to previous value
    } else {
      temp[index].workingDays = workingDays;
    }
    if (
      temp[index]?.maxWorkingDays &&
      temp[index]?.workingDays &&
      temp[index]?.billingRate
    ) {
      handleBaseAmount2(index);
    } else if (!temp[index]?.workingDays) {
      handleBaseAmount2(index);
    }
    setFormData1({ ...formData1, lineItemDetails: temp });
  };

  // Yes Case
  const handleMaxWorkingDays2 = (e, index) => {
    let temp = [...formData1?.lineItemDetails];
    temp[index].maxWorkingDays = e?.target?.value;
    temp[index].workingDays = "";
    if (
      temp[index]?.maxWorkingDays &&
      temp[index]?.workingDays &&
      temp[index]?.billingRate
    ) {
      handleBaseAmount2(index);
    }
    setFormData1({ ...formData1, lineItemDetails: temp });
  };

  // Yes Case
  // lineItemDetails base Amount
  const handleBaseAmount2 = (index) => {
    let temp = [...formData1?.lineItemDetails];
    let gstNo = GetgstData?.gstNumber;

    const workingDays = Number(temp[index]?.workingDays)
      ? Number(temp[index]?.workingDays)
      : 0;

    const maxWorkingDays = Number(temp[index]?.maxWorkingDays);
    const billingRate = Number(temp[index]?.billingRate);

    temp[index].baseAmount = Number(
      (workingDays / maxWorkingDays) * billingRate
    ).toFixed(2);
    setFormData1({ ...formData1, lineItemDetails: temp });

    const getBaseAmount = temp?.map((item) => Number(item?.baseAmount));

    const totalBaseAmount = getBaseAmount?.reduce(
      (acc, currValue) => acc + currValue
    );

    formData1.baseAmount = Number(totalBaseAmount).toFixed(2);
    formData1.tds = Number(
      (formData1?.baseAmount * getTdsDetails?.tdsPercentage) / 100
    )?.toFixed(2);

    if (!gstNo?.startsWith("09")) {
      if (formData1?.gstPercentage) {
        formData1.igst = Number(
          (formData1?.baseAmount * formData1?.gstPercentage) / 100
        )?.toFixed(2);
        formData1.cgst = 0;
        formData1.sgst = 0;
        formData1.gst = Number(
          (formData1?.baseAmount * formData1?.gstPercentage) / 100
        )?.toFixed(2);
      }
    } else {
      if (formData1?.gstPercentage) {
        formData1.igst = 0;
        formData1.cgst = Number(
          (formData1?.baseAmount * (formData1?.gstPercentage / 2)) / 100
        )?.toFixed(2);
        formData1.sgst = Number(
          (formData1?.baseAmount * (formData1?.gstPercentage / 2)) / 100
        )?.toFixed(2);
        formData1.gst = Number(
          ((formData1?.baseAmount * (formData1?.gstPercentage / 2)) / 100) * 2
        )?.toFixed(2);
      }
    }
    const otherCharges = formData1?.otherCharges ? Number(formData1?.otherCharges) : 0;
    const discount = formData1?.discount ? Number(formData1?.discount) : 0;
    formData1.totalBill = (
      Number(formData1?.baseAmount) + Number(formData1?.gst) + otherCharges - discount
    )?.toFixed(2);

    if (formData1?.gstApplicable === true) {
      formData1.amountToBePaid = (
        Number(formData1?.totalBill) - Number(formData1?.tds)
      )?.toFixed(2);
      formData1.payable1 = (Number(formData1?.amountToBePaid) - Number(formData1?.gst)).toFixed(2);
      formData1.payable2 = Number(formData1?.gst).toFixed(2);
    } else {
      formData1.amountToBePaid = (
        Number(formData1?.baseAmount) - Number(formData1?.tds) + otherCharges - discount
      )?.toFixed(2);
    }

    // if (formData1?.otherCharges) {
    //   formData1.amountToBePaid = Number(
    //     Number(formData1?.amountToBePaid) + Number(formData1?.otherCharges)
    //   )?.toFixed(2);
    // }

    // if (formData1?.discount) {
    //   formData1.amountToBePaid = (
    //     Number(formData1.amountToBePaid) - Number(formData1?.discount)
    //   )?.toFixed(2);
    // }
  };

  const createExpenseDataMutation = useMutation(createExpense, {
    onSuccess: (res) => {
      if (
        res?.message ===
        "This invoice number is already present for this partner"
      ) {
        ToastifyError("Duplicate Invoice Number");
        return;
      }

      if (res?.message) {
        ToastifyError(res?.message);
        return;
      }

      setNextScreen("expense");
      setPrevData((prevData) => ({
        ...prevData,
        sidebar: "expense",
        page: "expense",
        page1: "expense",
        page2: null,
        page3: null,
        active: "expense",
      }));
      setNextScreen("expense");
    },
   
  });

  const lineItemDetailsFn = (item) => {
    let status = true;
    for (let val of item) {
      if (
        val?.resourseId === "" ||
        val?.billingRate === "" ||
        val?.workingDays === "" ||
        val?.maxWorkingDays === "" ||
        val?.baseAmount === "" ||
        val?.description === ""
      ) {
        status = true;
        break;
      } else {
        status = false;
      }
    }
    if (status) {
      setValidate(true);
      return false;
    } else {
      return true;
    }
  };

  const lineItemDetailsFnVender = (item) => {
    let status = true;
    for (let val of item) {
      if (
        val?.unitAmount === "" ||
        val?.numberOfUnit === "" ||
        val?.vendorBaseAmount === "" ||
        val?.description === ""
      ) {
        status = true;
        break;
      } else {
        status = false;
      }
    }
    if (status) {
      setValidate(true);
      return false;
    } else {
      return true;
    }
  };

  const vendorBaseAmountFun = (index) => {
    let gstNo = GetgstData?.gstNumber;
    let temp = [...formData.lineItemDetailsVendor];
    const unitAmount = parseFloat(temp[index]?.unitAmount)
      ? parseFloat(temp[index]?.unitAmount)
      : 0;
    const numberOfUnit = parseFloat(temp[index]?.numberOfUnit)
      ? parseFloat(temp[index]?.numberOfUnit)
      : 0;
    if (
      (unitAmount === 0 || unitAmount) &&
      (numberOfUnit === 0 || numberOfUnit)
    ) {
      // Perform multiplication
      const totalAmount = Number(unitAmount * numberOfUnit)?.toFixed(2);

      // Update the vendorBaseAmount within the respective lineItemDetails object
      temp[index].vendorBaseAmount = totalAmount;

      const getBaseAmount = temp?.map((item) => Number(item?.vendorBaseAmount));

      const totalBaseAmount = getBaseAmount?.reduce(
        (acc, currValue) => acc + currValue
      );

      formData.baseAmountVendor = Number(totalBaseAmount)?.toFixed(2);

      formData.tds = Number(
        (formData.baseAmountVendor * getTdsDetails?.tdsPercentage) / 100
      )?.toFixed(2);

      if (!gstNo?.startsWith("09")) {
        if (formData?.gstPercentage) {
          formData.igst = Number(
            (formData?.baseAmountVendor * formData?.gstPercentage) / 100
          )?.toFixed(2);
          formData.cgst = 0;
          formData.sgst = 0;
          formData.gst = Number(
            (formData?.baseAmountVendor * formData?.gstPercentage) / 100
          )?.toFixed(2);
        }
      } else {
        if (formData?.gstPercentage) {
          formData.igst = 0;
          formData.cgst = Number(
            (formData?.baseAmountVendor * (formData?.gstPercentage / 2)) / 100
          )?.toFixed(2);
          formData.sgst = Number(
            (formData?.baseAmountVendor * (formData?.gstPercentage / 2)) / 100
          )?.toFixed(2);
          formData.gst = Number(
            ((formData?.baseAmountVendor * (formData?.gstPercentage / 2)) /
              100) *
            2
          )?.toFixed(2);
        }
      }
      const otherCharges = formData?.otherCharges ? Number(formData?.otherCharges) : 0;
      const discount = formData?.discount ? Number(formData?.discount) : 0;
      formData.totalBill = (
        Number(formData?.baseAmountVendor) + Number(formData?.gst) + otherCharges - discount
      )?.toFixed(2);

      if (formData?.gstApplicable === true) {
        formData.amountToBePaid = (
          Number(formData?.totalBill) - Number(formData?.tds)
        )?.toFixed(2);
        formData.payable1 = (Number(formData?.amountToBePaid) - Number(formData?.gst)).toFixed(2);
        formData.payable2 = Number(formData?.gst).toFixed(2);
      } else {
        formData.amountToBePaid = (
          Number(formData?.baseAmountVendor) - Number(formData?.tds) + otherCharges - discount
        )?.toFixed(2);
      }

      // if (formData?.otherCharges) {
      //   formData.amountToBePaid = Number(
      //     Number(formData?.amountToBePaid) + Number(formData?.otherCharges)
      //   )?.toFixed(2);
      // }

      // if (formData?.discount) {
      //   formData.amountToBePaid = (
      //     Number(formData.amountToBePaid) - Number(formData?.discount)
      //   )?.toFixed(2);
      // }

      // Update the lineItemDetails array in the formData state
      setFormData({ ...formData, lineItemDetailsVendor: temp });
    }
  };

  // yess case
  const vendorBaseAmountFun2 = (index) => {
    let gstNo = GetgstData?.gstNumber;
    let temp = [...formData1.lineItemDetailsVendor];
    const unitAmount = parseFloat(temp[index]?.unitAmount)
      ? parseFloat(temp[index]?.unitAmount)
      : 0;
    const numberOfUnit = parseFloat(temp[index]?.numberOfUnit)
      ? parseFloat(temp[index]?.numberOfUnit)
      : 0;
    if (
      (unitAmount === 0 || unitAmount) &&
      (numberOfUnit === 0 || numberOfUnit)
    ) {
      // Perform multiplication
      const totalAmount = Number(unitAmount * numberOfUnit)?.toFixed(2);

      // Update the vendorBaseAmount within the respective lineItemDetails object
      temp[index].vendorBaseAmount = totalAmount;

      const getBaseAmount = temp?.map((item) => Number(item?.vendorBaseAmount));
      const totalBaseAmount = getBaseAmount?.reduce(
        (acc, currValue) => acc + currValue
      );
      formData1.baseAmount = Number(totalBaseAmount)?.toFixed(2);

      formData1.tds = Number(
        (formData1.baseAmount * getTdsDetails?.tdsPercentage) / 100
      )?.toFixed(2);

      if (!gstNo?.startsWith("09")) {
        if (formData1?.gstPercentage) {
          formData1.igst = Number(
            (formData1?.baseAmount * formData1?.gstPercentage) / 100
          )?.toFixed(2);
          formData1.cgst = 0;
          formData1.sgst = 0;
          formData1.gst = Number(
            (formData1?.baseAmount * formData1?.gstPercentage) / 100
          )?.toFixed(2);
        }
      } else {
        if (formData1?.gstPercentage) {
          formData1.igst = 0;
          formData1.cgst = Number(
            (formData1?.baseAmount * (formData1?.gstPercentage / 2)) / 100
          )?.toFixed(2);
          formData1.sgst = Number(
            (formData1?.baseAmount * (formData1?.gstPercentage / 2)) / 100
          )?.toFixed(2);
          formData1.gst = Number(
            ((formData1?.baseAmount * (formData1?.gstPercentage / 2)) / 100) * 2
          )?.toFixed(2);
        }
      }
      const otherCharges = formData1?.otherCharges ? Number(formData1?.otherCharges) : 0;
      const discount = formData1?.discount ? Number(formData1?.discount) : 0;
      formData1.totalBill = (
        Number(formData1?.baseAmount) + Number(formData1?.gst) + otherCharges - discount
      )?.toFixed(2);

      if (formData1?.gstApplicable === true) {
        formData1.amountToBePaid = (
          Number(formData1?.totalBill) - Number(formData1?.tds)
        )?.toFixed(2);
        formData1.payable1 = (Number(formData1?.amountToBePaid) - Number(formData1?.gst)).toFixed(2);
        formData1.payable2 = Number(formData1?.gst).toFixed(2);
      } else {
        formData1.amountToBePaid = (
          Number(formData1?.baseAmount) - Number(formData1?.tds) + otherCharges - discount
        )?.toFixed(2);
      }

      // if (formData1?.otherCharges) {
      //   formData1.amountToBePaid = Number(
      //     Number(formData1?.amountToBePaid) + Number(formData1?.otherCharges)
      //   )?.toFixed(2);
      // }

      // if (formData1?.discount) {
      //   formData1.amountToBePaid = (
      //     Number(formData1.amountToBePaid) - Number(formData1?.discount)
      //   )?.toFixed(2);
      // }
      // Update the lineItemDetails array in the formData state
      setFormData1({ ...formData1, lineItemDetailsVendor: temp });
    }
  };

  const submitHandler = () => {
    if (venderType?.type === "Partner") {
      if (
        formData?.vendorId &&
        formData?.category &&
        formData?.invoice &&
        formData?.dateOfExpense &&
        formData?.serviceMonth &&
        formData?.serviceYearId &&
        formData?.paymentMode &&
        lineItemDetailsFn(formData?.lineItemDetails)
      ) {
        let percentageId = gstPercentageOption?.filter(
          (item) => item?.label === formData?.gstPercentage
        )[0]?.value;

        let partnerPayload = {
          copyOfExpense: formData?.copyOfExpense,
          vendorName: formData?.vendorName,
          vendorId: formData?.vendorId,
          venderType: "Partner",
          serviceMonth: formData?.serviceMonth,
          serviceMonthId: formData?.serviceMonthId,
          serviceYear: formData?.serviceYear,
          otherCharges: formData?.otherCharges,
          discount: formData?.discount,
          documentURL: formData?.documentResponses,
          serviceYearId: formData?.serviceYearId,
          category: formData?.category,
          invoice: formData?.invoice,
          dateOfExpense: formData?.dateOfExpense,
          expenseMonth: formData?.expenseMonth,
          expenseYear: formData?.expenseYear,
          paymentNote: formData?.paymentNote,
          approveBy: formData?.approveBy,
          gstApplicable: formData?.gstApplicable,
          baseAmount: formData?.baseAmount,
          igst: Number(formData?.igst),
          cgst: Number(formData?.cgst),
          sgst: Number(formData?.sgst),
          gst: Number(formData?.gst),
          totalBill: Number(formData?.totalBill),
          gstPercentageId: percentageId,
          tds: Number(formData?.tds),
          paymentModeId: Number(formData?.paymentModeId),
          amountToBePaid: Number(formData?.amountToBePaid),
          paymentDueOn: formData?.paymentDueOn,
          lineItemDetails: formData?.lineItemDetails?.map((ele) => {
            return {
              resourseId: ele?.resourseId,
              billingRate: ele?.billingRate,
              workingDays: Number(ele?.workingDays),
              maxWorkingDays: ele?.maxWorkingDays,
              baseAmount: ele?.baseAmount,
              description: ele?.description,
              candidateName: ele?.candidateName,
            };
          }),
        };
        if (prevData?.page2 === "editExpense") {
          partnerPayload.id = prevData?.idData;
        }

        if (formData?.gstApplicable === false) {
          partnerPayload.igst = null;
          partnerPayload.gst = null;
          partnerPayload.cgst = null;
          partnerPayload.sgst = null;
          partnerPayload.totalBill = partnerPayload.amountToBePaid;
          partnerPayload.gstPercentageId = 1;
        }
        createExpenseDataMutation.mutate(partnerPayload);
        setValidate(false);
      } else {
        setValidate(true);
      }
    } else if (venderType?.type === "Vendor") {
      let percentageId = gstPercentageOption?.filter(
        (item) => item?.label === formData?.gstPercentage
      )[0]?.value;

      if (
        formData?.vendorId &&
        formData?.category &&
        formData?.invoice &&
        formData?.dateOfExpense &&
        formData?.serviceMonth &&
        formData?.serviceYearId &&
        formData?.paymentMode &&
        lineItemDetailsFnVender(formData?.lineItemDetailsVendor)
      ) {
        let vendorPayload = {
          copyOfExpense: formData?.copyOfExpense,
          vendorId: formData?.vendorId,
          vendorName: formData?.vendorName,
          venderType: "Vendor",
          expenseMonth: formData?.expenseMonth,
          otherCharges: formData?.otherCharges,
          discount: formData?.discount,
          documentURL: formData?.documentResponses,
          expenseYear: formData?.expenseYear,
          serviceMonth: formData?.serviceMonth,
          serviceMonthId: formData?.serviceMonthId,
          pan: formData?.pan,
          serviceYear: formData?.serviceYear,
          paymentNote: formData?.paymentNote,
          serviceYearId: formData?.serviceYearId,
          paymentModeId: formData?.paymentModeId,
          igst: Number(formData?.igst),
          sgst: Number(formData?.sgst),
          cgst: Number(formData?.cgst),
          gst: Number(formData?.gst),
          tds: Number(formData?.tds),
          gstPercentageId: percentageId,
          baseAmount: formData?.baseAmountVendor,
          totalBill: Number(formData?.totalBill),
          amountToBePaid: Number(formData?.amountToBePaid),
          gstApplicable: formData?.gstApplicable,
          category: formData?.category,
          paymentDueOn: formData?.paymentDueOn,
          dateOfExpense: formData?.dateOfExpense,
          invoice: formData?.invoice,
          approveBy: formData?.approveBy,
          lineItemDetails: formData?.lineItemDetailsVendor?.map((ele) => {
            return {
              unitAmount: Number(ele?.unitAmount),
              numberOfUnit: Number(ele?.numberOfUnit),
              description: ele?.description,
              vendorBaseAmount: ele?.vendorBaseAmount,
            };
          }),
        };
        if (prevData?.page2 === "editExpense") {
          vendorPayload.id = prevData?.idData;
        }
        if (formData?.gstApplicable === false) {
          vendorPayload.igst = null;
          vendorPayload.gst = null;
          vendorPayload.cgst = null;
          vendorPayload.sgst = null;
          vendorPayload.totalBill = vendorPayload.amountToBePaid;
          vendorPayload.gstPercentageId = 1;
        }
        createExpenseDataMutation.mutate(vendorPayload);
        setValidate(false);
      } else {
        setValidate(true);
      }
    } else {
      setValidate(true);
    }
  };
  const submitHandler1 = () => {
    if (venderType?.type === "Partner") {
      if (
        formData1?.vendorId &&
        formData1?.category &&
        formData1?.invoice &&
        formData1?.dateOfExpense &&
        formData1?.serviceMonth &&
        formData1?.serviceYearId &&
        formData1?.paymentMode &&
        lineItemDetailsFn(formData1?.lineItemDetails)
      ) {
        let percentageId = gstPercentageOption?.filter(
          (item) => item?.label === formData1?.gstPercentage
        )[0]?.value;

        let partnerPayload = {
          copyOfExpense: formData1?.copyOfExpense,
          venderType: "Partner",
          vendorName: formData1?.vendorName,
          vendorId: formData1?.vendorId,
          otherCharges: formData1?.otherCharges,
          discount: formData1?.discount,
          documentURL: formData1?.documentResponses,
          serviceMonth: formData1?.serviceMonth,
          serviceMonthId: formData1?.serviceMonthId,
          serviceYear: formData1?.serviceYear,
          serviceYearId: formData1?.serviceYearId,
          paymentModeId: formData1?.paymentModeId,
          category: formData1?.category,
          pan: formData?.pan,
          invoice: formData1?.invoice,
          dateOfExpense: formData1?.dateOfExpense,
          paymentNote: formData1?.paymentNote,
          gstPercentage: formData?.gstPercentage,
          expenseMonth: formData1?.expenseMonth,
          expenseYear: formData1?.expenseYear,
          approveBy: formData1?.approveBy,
          gstApplicable: formData1?.gstApplicable,
          gstPercentageId: percentageId,
          baseAmount: formData1?.baseAmount,
          igst: Number(formData1?.igst),
          cgst: Number(formData1?.cgst),
          sgst: Number(formData1?.sgst),
          gst: Number(formData1?.gst),
          totalBill: Number(formData1?.totalBill),
          tds: Number(formData1?.tds),
          amountToBePaid: Number(formData1?.amountToBePaid),
          paymentDueOn: formData1?.paymentDueOn,
          lineItemDetails: formData1?.lineItemDetails?.map((ele) => {
            return {
              resourseId: ele?.resourseId,
              billingRate: ele?.billingRate,
              workingDays: Number(ele?.workingDays),
              maxWorkingDays: ele?.maxWorkingDays,
              baseAmount: ele?.baseAmount,
              description: ele?.description,
            };
          }),
        };

        if (prevData?.page2 === "editExpense") {
          partnerPayload.id = prevData?.idData;
        }

        if (formData?.gstApplicable === false) {
          partnerPayload.igst = null;
          partnerPayload.gst = null;
          partnerPayload.cgst = null;
          partnerPayload.sgst = null;
          partnerPayload.totalBill = partnerPayload.amountToBePaid;
          partnerPayload.gstPercentageId = 1;
        }

        createExpenseDataMutation.mutate(partnerPayload);
        setValidate(false);
      } else {
        setValidate(true);
      }
    } else if (venderType?.type === "Vendor") {
      if (
        formData1?.category &&
        formData1?.invoice &&
        formData1?.dateOfExpense &&
        formData1?.serviceMonth &&
        formData1?.serviceYearId &&
        formData?.paymentMode &&
        lineItemDetailsFnVender(formData1?.lineItemDetailsVendor)
      ) {
        let percentageId = gstPercentageOption?.filter(
          (item) => item?.label === formData1?.gstPercentage
        )[0]?.value;

        let vendorPayload = {
          copyOfExpense: formData1?.copyOfExpense,
          vendorId: formData1?.vendorId,
          vendorName: formData1?.vendorName,
          venderType: "Vendor",
          igst: Number(formData1?.igst),
          sgst: Number(formData1?.sgst),
          cgst: Number(formData1?.cgst),
          gst: Number(formData1?.gst),
          tds: Number(formData1?.tds),
          otherCharges: formData1?.otherCharges,
          discount: formData1?.discount,
          documentURL: formData1?.documentResponses,
          serviceMonth: formData1?.serviceMonth,
          paymentNote: formData1?.paymentNote,
          serviceMonthId: formData1?.serviceMonthId,
          paymentModeId: formData1?.paymentModeId,
          gstPercentageId: percentageId,
          serviceYear: formData1?.serviceYear,
          gstPercentage: formData1?.gstPercentage,
          serviceYearId: formData1?.serviceYearId,
          expenseMonth: formData1?.expenseMonth,
          expenseYear: formData1?.expenseYear,
          baseAmount: formData1?.baseAmount,
          totalBill: Number(formData1?.totalBill),
          amountToBePaid: Number(formData1?.amountToBePaid),
          gstApplicable: formData1?.gstApplicable,
          category: formData1?.category,
          categoryName: formData1?.categoryName,
          paymentDueOn: formData1?.paymentDueOn,
          dateOfExpense: formData1?.dateOfExpense,
          invoice: formData1?.invoice,
          approveBy: formData1?.approveBy,
          lineItemDetails: formData1?.lineItemDetailsVendor?.map((ele) => {
            return {
              unitAmount: Number(ele?.unitAmount),
              numberOfUnit: Number(ele?.numberOfUnit),
              description: ele?.description,
              vendorBaseAmount: ele?.vendorBaseAmount,
            };
          }),
        };
        if (prevData?.page2 === "editExpense") {
          vendorPayload.id = prevData?.idData;
        }

        if (formData?.gstApplicable === false) {
          vendorPayload.igst = null;
          vendorPayload.gst = null;
          vendorPayload.cgst = null;
          vendorPayload.sgst = null;
          vendorPayload.totalBill = vendorPayload.amountToBePaid;
          vendorPayload.gstPercentageId = 1;
        }
        createExpenseDataMutation.mutate(vendorPayload);
        setValidate(false);
      } else {
        setValidate(true);
      }
    }
  };

  useEffect(() => {
    if (
      monthOption &&
      prevData?.page2 === "createExpense" &&
      formData?.serviceMonth === ""
    ) {
      const currentMonth = new Date().getMonth();
      const defaultServiceMonth = monthOption.find(
        (month) => month.value === currentMonth
      );

      setFormData({
        ...formData,
        serviceMonth: defaultServiceMonth?.label || "",
        serviceMonthId: defaultServiceMonth?.value || "",
      });
    }
  }, [monthOption]);

  return (
    <>
      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      {/*Copy Expence*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Copy Expense </div>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>Copy Expense?</div>
            {prevData?.page2 === "editExpense" ? (
              <p className={style.values}>
                {ExpenseDataByEditId?.copyOfExpense === true ? "Yes" : "NO"}
              </p>
            ) : (
              <>
                {" "}
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="Yes"
                    name="copyOfExpense"
                    checked={formData?.copyOfExpense}
                    onChange={() => {
                      setFormData({ ...formData, copyOfExpense: true });
                      setFormData1({ ...formData1, copyOfExpense: true });
                    }}
                    value={formData.copyOfExpense}
                  />
                  <label for="Yes">Yes</label>
                </div>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="no"
                    name="copyOfExpense"
                    checked={!formData?.copyOfExpense}
                    onChange={() => {
                      setFormData({
                        ...formData,
                        copyOfExpense: false,
                        paymentDueOn: "",
                      });

                      setFormData1({
                        ...formData1,
                        copyOfExpense: false,
                        paymentDueOn: "",
                      });
                    }}
                    value={formData.copyOfExpense}
                  />
                  <label for="no">No</label>
                </div>
              </>
            )}
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>

        <Row>
          <Col md={3} style={{ marginLeft: "26px" }}>
            <p className={style.label}>
              Vendor Name<span style={{ color: "red" }}>*</span>
            </p>
            {prevData?.page2 === "editExpense" ? (
              <input
                value={
                  ExpenseDataByEditId?.copyOfExpense === true
                    ? formData1?.vendorName
                    : formData?.vendorName
                }
                className={style.bigInput}
                readOnly
              />
            ) : (
              <Select
                styles={InvoiceStylesVendorName}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setVerderType(e);
                  setFormData1({
                    ...formData1,
                    vendorId: e?.value,
                    vendorName: e?.label,
                    paymentDueOn: e?.paymentDueOn,
                  });

                  setFormData({
                    ...formData,
                    vendorId: e?.value,
                    vendorName: e?.label,
                    paymentDueOn: e.paymentDueOn,
                    lineItemDetails: [
                      {
                        resourseId: "",
                        expenseMonth: "",
                        expenseYear: "",
                        billingRate: "",
                        workingDays: "",
                        maxWorkingDays: "",
                        baseAmount: "",
                        description: "",
                        numberOfUnit: "",
                        unitAmount: "",
                        vendorBaseAmount: "",
                      },
                    ],
                  });
                }}
                options={venderOption}
              />
            )}
            <p
              className={
                validate && !formData?.vendorId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Vendor Name
            </p>
          </Col>

          {formData1.copyOfExpense === true ? (
            <>
              <Col md={3} style={{ marginLeft: "26px" }}>
                <p className={style.label}>
                  Service Month<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    handleServiceMonthChange1(
                      e,
                      formData1?.lineItemDetails?.length
                    )
                  }
                  options={monthOption}
                  value={monthOption?.find((e) => {
                    return e?.label === formData1?.serviceMonth;
                  })}
                />
                <p
                  className={
                    validate && !formData1?.serviceMonth
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Service Month
                </p>
              </Col>

              <Col md={3} style={{ marginLeft: "26px" }}>
                <p className={style.label}>
                  Service Year<span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    handleServiceYearChange1(
                      e,
                      formData1?.lineItemDetails?.length
                    );
                  }}
                  options={GetCategoryYearOption}
                  value={
                    formData1?.serviceYear
                      ? GetCategoryYearOption?.find((e) => {
                        return e?.label === formData1?.serviceYear;
                      })
                      : null
                  }
                />

                <p
                  className={
                    validate && !formData1?.serviceYear
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please write Service Year
                </p>
              </Col>
            </>
          ) : (
            <>
              <Col md={3} style={{ marginLeft: "26px" }}>
                <p className={style.label}>
                  Service Month<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    handleServiceMonthChange(
                      e,
                      formData?.lineItemDetails?.length
                    )
                  }
                  options={monthOption}
                  value={monthOption?.find((e) => {
                    return e?.label === formData?.serviceMonth;
                  })}
                />
                <p
                  className={
                    validate && !formData?.serviceMonth
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Service Month
                </p>
              </Col>

              <Col md={3} style={{ marginLeft: "26px" }}>
                <p className={style.label}>
                  Service Year<span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    handleServiceYearChange(
                      e,
                      formData?.lineItemDetails?.length
                    );
                  }}
                  options={GetCategoryYearOption}
                  value={
                    formData?.serviceYear
                      ? GetCategoryYearOption?.find((e) => {
                        return e?.label === formData?.serviceYear;
                      })
                      : null
                  }
                />

                <p
                  className={
                    validate && !formData?.serviceYear
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please write Service Year
                </p>
              </Col>
            </>
          )}
        </Row>
      </InvoiceContainer>

      {formData.copyOfExpense === true ? (
        <>
          <InvoiceContainer>
            <div className={style.copy_expence}>Expense Details</div>
            {/*row 1*/}
            <div className={style.rowExp}>
              <Row>
                <Col md={3} style={{ marginLeft: "26px" }}>
                  <p className={style.label}>
                    Category<span style={{ color: "red" }}>*</span>
                  </p>
                  <Select
                    styles={InvoiceStylesVendorName}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    value={expenseCategoryOption?.find((e) => {
                      return e?.label === formData1?.categoryName;
                    })}
                    onChange={(e) => {
                      setFormData1({
                        ...formData1,
                        category: e.value,
                        categoryName: e.label,
                      });
                    }}
                    options={expenseCategoryOption}
                  />
                  <p
                    className={
                      validate && !formData1?.category
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Enter Category
                  </p>
                </Col>
              </Row>
            </div>
            {/*row 2*/}
            <div className={style.ContainerExpDetails}>
              <div className={style.col2}>
                <p className={style.label}>
                  Invoice No<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  value={formData1?.invoice}
                  className={style.bigInput}
                  onChange={(e) => {
                    setFormData1({ ...formData1, invoice: e?.target?.value })
                    setIsInvoiceExist(false)
                  }
                  }
                  onBlur={(e) => handleInvoice(ExpenseDataByEditId?.copyOfExpense === true
                    ? formData1?.vendorId
                    : formData?.vendorId, e.target.value,formData?.serviceYearId)}
                />
                {isInvoiceExist ?
                  <p
                    className={style.errorText}>
                    Invoice number already present for this partner
                  </p>
                  : <p
                    className={
                      validate && !formData1?.invoice
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Enter Invoice No
                  </p>
                }
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Date of Invoice<span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData1?.dateOfExpense
                        ? moment(formData1?.dateOfExpense).toDate()
                        : null
                    }
                    onChange={(date) => {
                      const selectedMonth = moment(date).format("MMMM");
                      const selectedYear = moment(date).format("YYYY");
                      paymentDueDateFun2(date);
                      setFormData1({
                        ...formData1,
                        dateOfExpense: moment(date).format(),
                        expenseMonth: selectedMonth,
                        expenseYear: selectedYear,
                        paymentDueOn: "",
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      formData1?.dateOfExpense ? (
                        <CustomInputCross2 properties="dateOfExpense" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
                <p
                  className={
                    validate && !formData1?.dateOfExpense
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Date of Invoice
                </p>
              </div>

              <div className={style.col3}>
                <p className={style.label}>
                  Expense Month<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStylesVendorName}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      expenseMonth: e?.label,
                    });
                  }}
                  options={monthOption}
                  value={
                    formData1?.expenseMonth
                      ? monthOption?.find((e) => {
                        return e?.label === formData1?.expenseMonth;
                      })
                      : null
                  }
                />
              </div>
              <div className={style.col4}>
                <p className={style.label}>
                  Expense Year<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  value={formData1?.expenseYear}
                  className={style.bigInput}
                  readOnly
                />
                <p
                  className={
                    validate && !formData1?.expenseYear
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Expense Year
                </p>
              </div>
            </div>

            <div className={style.ContainerExpDetails}>
              <div className={style.col212}>
                <p className={style.label}>Approved By</p>
                <input
                  value={formData1?.approveBy}
                  className={style.bigInput}
                  onChange={(e) =>
                    setFormData1({ ...formData1, approveBy: e?.target?.value })
                  }
                />
              </div>

              <div className={style.col212}>
                <p className={style.label}>GST No.</p>
                <input
                  value={formData1?.gstNo ? formData1?.gstNo : ""}
                  className={style.bigInput}
                  readOnly
                />
              </div>

              <div className={style.col212}>
                <p className={style.label}>PAN Card</p>
                <input
                  value={formData1?.pan ? formData1?.pan : ""}
                  className={style.bigInput}
                  readOnly
                />
              </div>

              <div className={style.col212}></div>
            </div>
          </InvoiceContainer>

          {/* Line Item Details Container formdata1 Partner */}
          {venderType?.type === "Partner" ? (
            <InvoiceContainer>
              <div className={style.copy_expence}>Line Item Details</div>
              {/*row 1*/}
              {formData1?.lineItemDetails?.map((item, index) => {
                return (
                  <>
                    <div>
                      <div key={index}>
                        <div>
                          <div className={style.ContainerLineItemV}>
                            <div className={style.col12_item}>
                              {index === 0 && (
                                <p className={style.label_item}>
                                  Candidate Name
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              )}
                              <Select
                                styles={InvoiceStyles}
                                classNamePrefix={"create_position"}
                                components={{
                                  DropdownIndicator: () => CustomInput(),
                                  IndicatorSeparator: () => null,
                                }}
                                options={clientNameOption}
                                onChange={(e) => {
                                  let temp = [...formData1?.lineItemDetails];
                                  temp[index].resourseId = e?.value;
                                  temp[index].candidateName = e?.label;
                                  temp[index].billingRate = Number(
                                    clientNameOption?.find(
                                      (item) => item?.value == e?.value
                                    )?.rate
                                  );
                                  temp[index].workingDays = "";
                                  temp[index].baseAmount = "";
                                  temp[index].expenseMonth = "";
                                  temp[index].expenseYear = "";
                                  setFormData1({
                                    ...formData1,
                                    lineItemDetails: temp,
                                  });
                                }}
                                value={
                                  formData1?.lineItemDetails[index]
                                    ?.candidateName
                                    ? clientNameOption?.find(
                                      (e) =>
                                        e.label ===
                                        formData1?.lineItemDetails[index]
                                          ?.candidateName
                                    )
                                    : null
                                }
                              />
                              {validate && !item?.resourseId && (
                                <p
                                  className={
                                    validate && !item?.resourseId
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please write Candidate Name
                                </p>
                              )}
                            </div>

                            <div className={style.col4_item}>
                              {index === 0 && (
                                <p className={style.label_item}>
                                  Billing Rate
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              )}
                              <input
                                type="number"
                                className={style.smallInput}
                                readOnly
                                onChange={(e) => {
                                  let temp = [...formData1?.lineItemDetails];
                                  temp[index].billingRate = e?.target?.value;
                                  setFormData1({
                                    ...formData1,
                                    lineItemDetails: temp,
                                  });
                                }}
                                value={
                                  formData1?.lineItemDetails[index]?.billingRate
                                }
                                min="0"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) =>
                                  (e.keyCode === 38 ||
                                    e.keyCode === 40 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.preventDefault()
                                }
                                onKeyPress={(e) =>
                                  ((e.which !== 8 &&
                                    e.which !== 0 &&
                                    e.which < 48) ||
                                    e.which > 57 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.prevent
                                }
                              />
                              {
                                <p
                                  className={
                                    validate && !item?.billingRate
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please write Billing Rate
                                </p>
                              }
                            </div>
                            <div className={style.col5_item}>
                              {index === 0 && (
                                <p className={style.label_item}>
                                  Working Days
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              )}
                              <input
                                type="number"
                                className={style.smallInput}
                                value={
                                  formData1?.lineItemDetails[index]?.workingDays
                                }
                                onChange={(e) => {
                                  handleWorkingDays2(e, index);
                                }}
                                min="0"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) =>
                                  (e.keyCode === 38 ||
                                    e.keyCode === 40 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.preventDefault()
                                }
                                onKeyPress={(e) =>
                                  ((e.which !== 8 &&
                                    e.which !== 0 &&
                                    e.which < 48) ||
                                    e.which > 57 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.prevent
                                }
                              />
                              <p
                                className={
                                  validate && !item?.workingDays
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please write Working Days
                              </p>
                            </div>
                            <div className={style.col6_item}>
                              {index === 0 && (
                                <p className={style.label_item}>
                                  Max Working Days
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              )}
                              <input
                                type="number"
                                className={style.smallInput}
                                onChange={(e) =>
                                  handleMaxWorkingDays2(e, index)
                                }
                                value={
                                  formData1?.lineItemDetails[index]
                                    ?.maxWorkingDays
                                }
                                min="0"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) =>
                                  (e.keyCode === 38 ||
                                    e.keyCode === 40 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.preventDefault()
                                }
                                onKeyPress={(e) =>
                                  ((e.which !== 8 &&
                                    e.which !== 0 &&
                                    e.which < 48) ||
                                    e.which > 57 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.prevent
                                }
                              />
                              <p
                                className={
                                  validate && !item?.maxWorkingDays
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please write Max Working Days
                              </p>
                            </div>
                            <div className={style.col12_item}>
                              {index === 0 && (
                                <p className={style.label_item}>
                                  Base Amount
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              )}
                              <input
                                type="number"
                                className={style.smallInput}
                                onChange={(e) => handleBaseAmount2(index)}
                                readOnly
                                value={
                                  formData1?.lineItemDetails[index]?.baseAmount
                                }
                                min="0"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) =>
                                  (e.keyCode === 38 ||
                                    e.keyCode === 40 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.preventDefault()
                                }
                                onKeyPress={(e) =>
                                  ((e.which !== 8 &&
                                    e.which !== 0 &&
                                    e.which < 48) ||
                                    e.which > 57 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.prevent
                                }
                              />
                              <p
                                className={
                                  validate && !item?.baseAmount
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please write Base Amount
                              </p>
                            </div>
                          </div>
                          <div className={style.ContainerLineItemV}>
                            <div className={style.col7_item}>
                              {index === 0 && (
                                <p className={style.label_item}>
                                  Description
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                              )}

                              <input
                                type="text"
                                className={style.smallInputDes}
                                value={
                                  formData1?.lineItemDetails[index]?.description
                                }
                                onChange={(e) => {
                                  let temp = [...formData1?.lineItemDetails];
                                  temp[index].description = e?.target?.value;
                                  setFormData1({
                                    ...formData1,
                                    lineItemDetails: temp,
                                  });
                                }}
                              />
                              <p
                                className={
                                  validate &&
                                    !formData1?.lineItemDetails[index].description
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please Enter Description
                              </p>
                            </div>

                            <div className={style.col8_item}>
                              {formData1?.lineItemDetails?.length > 1 && (
                                <>
                                  <FaTrash
                                    className={style.remove_icon}
                                    size={20}
                                    onClick={(e) => {
                                      let gstNo = GetgstData?.gstNumber;

                                      const totalBaseAmount = Number(
                                        formData1?.lineItemDetails[index]
                                          ?.baseAmount
                                      )?.toFixed(2);

                                      const finalBaseAmount = (
                                        Number(formData1?.baseAmount) -
                                        Number(totalBaseAmount)
                                      ).toFixed(2);

                                      formData1.baseAmount = finalBaseAmount;

                                      formData1.tds = Number(
                                        (formData1.baseAmount *
                                          getTdsDetails?.tdsPercentage) /
                                        100
                                      )?.toFixed(2);

                                      if (!gstNo?.startsWith("09")) {
                                        if (formData1?.gstPercentage) {
                                          formData1.igst = Number(
                                            (formData1?.baseAmount *
                                              formData1?.gstPercentage) /
                                            100
                                          )?.toFixed(2);
                                          formData1.cgst = 0;
                                          formData1.sgst = 0;
                                          formData1.gst = Number(
                                            (formData1?.baseAmount *
                                              formData1?.gstPercentage) /
                                            100
                                          )?.toFixed(2);
                                        }
                                      } else {
                                        if (formData1?.gstPercentage) {
                                          formData1.igst = 0;
                                          formData1.cgst = Number(
                                            (formData1?.baseAmount *
                                              (formData1?.gstPercentage / 2)) /
                                            100
                                          )?.toFixed(2);
                                          formData1.sgst = Number(
                                            (formData?.baseAmount *
                                              (formData1?.gstPercentage / 2)) /
                                            100
                                          )?.toFixed(2);
                                          formData1.gst = Number(
                                            ((formData1?.baseAmount *
                                              (formData1?.gstPercentage / 2)) /
                                              100) *
                                            2
                                          )?.toFixed(2);
                                        }
                                      }
                                      const otherCharges = formData1?.otherCharges ? Number(formData1?.otherCharges) : 0;
                                      const discount = formData1?.discount ? Number(formData1?.discount) : 0;
                                      formData1.totalBill = (
                                        Number(formData1?.baseAmount) +
                                        Number(formData1?.gst) +
                                        otherCharges -
                                        discount
                                      )?.toFixed(2);

                                      formData1.amountToBePaid = (
                                        Number(formData1?.totalBill) -
                                        Number(formData1?.tds)
                                      )?.toFixed(2);

                                      formData1.payable1 = (Number(formData1?.amountToBePaid) - Number(formData1?.gst)).toFixed(2);
                                      formData1.payable2 = Number(formData1?.gst).toFixed(2);

                                      // if (formData1?.otherCharges) {
                                      //   formData1.amountToBePaid = Number(
                                      //     Number(formData1?.amountToBePaid) +
                                      //     Number(formData1?.otherCharges)
                                      //   )?.toFixed(2);
                                      // }

                                      // if (formData1?.discount) {
                                      //   formData1.amountToBePaid = (
                                      //     Number(formData1.amountToBePaid) -
                                      //     Number(formData1?.discount)
                                      //   )?.toFixed(2);
                                      // }

                                      setLineItemArray(
                                        lineItemArray.filter((_, i) => {
                                          return i !== index;
                                        })
                                      );
                                      setFormData1({
                                        ...formData1,
                                        lineItemDetails:
                                          formData1?.lineItemDetails?.filter(
                                            (_, i) => i !== index
                                          ),
                                      });
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

              <div className={style.lastBtn_item}>
                <p style={{ paddingTop: "1%", fontWeight: "bold" }}>
                  Total Base Amount
                </p>
                <div>
                  <input
                    readOnly
                    className={style.smallInputDes}
                    value={formData1?.baseAmount}
                  />
                </div>
                <InvoiceButton
                  onClick={() => {
                    setLineItemArray([
                      ...lineItemArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setFormData1({
                      ...formData1,
                      lineItemDetails: [
                        ...formData1?.lineItemDetails,
                        {
                          resourseId: "",
                          expenseMonth: "",
                          expenseYear: "",
                          billingRate: "",
                          workingDays: "",
                          maxWorkingDays:
                            formData1?.lineItemDetails[0]?.maxWorkingDays,
                          baseAmount: "",
                        },
                      ],
                    });
                  }}
                >
                  Add
                </InvoiceButton>
              </div>
            </InvoiceContainer>
          ) : null}
          {/* Line Item Details Container formdata1 vendor */}
          {venderType?.type === "Vendor" ? (
            <InvoiceContainer>
              <div className={style.copy_expence}>Line Item Details</div>
              {/*row 1*/}
              {formData1?.lineItemDetailsVendor?.map((item, index) => {
                return (
                  <>
                    <div>
                      <div key={index}>
                        <div>
                          <div className={style.ContainerLineItem}>
                            <div className={style.col2_item}>
                              <p className={style.label_item}>
                                Unit Amount
                                <span style={{ color: "red" }}>*</span>
                              </p>
                              <input
                                type="number"
                                className={style.smallInput}
                                value={
                                  formData1?.lineItemDetailsVendor[index]
                                    ?.unitAmount
                                }
                                onChange={(e) => {
                                  let temp = [
                                    ...formData1?.lineItemDetailsVendor,
                                  ];
                                  temp[index].unitAmount = e?.target?.value;
                                  setFormData1({
                                    ...formData1,
                                    lineItemDetailsVendor: temp,
                                  });
                                  vendorBaseAmountFun2(index);
                                }}
                                min="0"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) =>
                                  (e.keyCode === 38 ||
                                    e.keyCode === 40 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.preventDefault()
                                }
                                onKeyPress={(e) =>
                                  ((e.which !== 8 &&
                                    e.which !== 0 &&
                                    e.which < 48) ||
                                    e.which > 57 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.prevent
                                }
                              />
                              <p
                                className={
                                  validate && !item?.unitAmount
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please Enter Unit Amount
                              </p>
                            </div>
                            <div className={style.col4_item}>
                              <p className={style.label_item}>
                                No. of Unit
                                <span style={{ color: "red" }}>*</span>
                              </p>
                              <input
                                type="number"
                                className={style.smallInput}
                                value={
                                  formData1?.lineItemDetailsVendor[index]
                                    ?.numberOfUnit
                                }
                                onChange={(e) => {
                                  let temp = [
                                    ...formData1?.lineItemDetailsVendor,
                                  ];
                                  temp[index].numberOfUnit = e?.target?.value;
                                  setFormData1({
                                    ...formData1,
                                    lineItemDetailsVendor: temp,
                                  });
                                  vendorBaseAmountFun2(index);
                                }}
                                min="0"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) =>
                                  (e.keyCode === 38 ||
                                    e.keyCode === 40 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.preventDefault()
                                }
                                onKeyPress={(e) =>
                                  ((e.which !== 8 &&
                                    e.which !== 0 &&
                                    e.which < 48) ||
                                    e.which > 57 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.preventDefault()
                                }
                              />
                              <p
                                className={
                                  validate && !item?.numberOfUnit
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please Enter No of Unit
                              </p>
                            </div>
                            <div className={style.col3_item}>
                              <p className={style.label_item}>
                                Base Amount
                                <span style={{ color: "red" }}>*</span>
                              </p>
                              <input
                                type="number"
                                className={style.smallInput}
                                onChange={(e) => handleBaseAmount2(index)}
                                readOnly
                                value={
                                  formData1?.lineItemDetailsVendor[index]
                                    ?.vendorBaseAmount
                                }
                                min="0"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) =>
                                  (e.keyCode === 38 ||
                                    e.keyCode === 40 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.preventDefault()
                                }
                                onKeyPress={(e) =>
                                  ((e.which !== 8 &&
                                    e.which !== 0 &&
                                    e.which < 48) ||
                                    e.which > 57 ||
                                    e.key === "e" ||
                                    e.key === "-") &&
                                  e.prevent
                                }
                              />
                              <p
                                className={
                                  validate && !item?.baseAmount
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please write Base Amount
                              </p>
                            </div>
                            <div className={style.col12_itemv}>
                              <p className={style.label_item}>
                                Description
                                <span style={{ color: "red" }}>*</span>
                              </p>

                              <input
                                type="text"
                                className={style.smallInputDes}
                                value={
                                  formData1?.lineItemDetailsVendor[index]
                                    ?.description
                                }
                                onChange={(e) => {
                                  let temp = [
                                    ...formData1?.lineItemDetailsVendor,
                                  ];
                                  temp[index].description = e?.target?.value;
                                  setFormData1({
                                    ...formData1,
                                    lineItemDetailsVendor: temp,
                                  });
                                }}
                              />
                              <p
                                className={
                                  validate &&
                                    !formData1?.lineItemDetailsVendor[index]
                                      .description
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please Enter Description
                              </p>
                            </div>
                            <div className={style.col8_item8}>
                              {formData1?.lineItemDetailsVendor?.length > 1 && (
                                <>
                                  <FaTrash
                                    className={style.remove_icon}
                                    size={20}
                                    onClick={(e) => {
                                      let gstNo = GetgstData?.gstNumber;

                                      const totalBaseAmount = Number(
                                        formData1?.lineItemDetailsVendor[index]
                                          ?.vendorBaseAmount
                                      )?.toFixed(2);

                                      const finalBaseAmount = (
                                        Number(formData1?.baseAmount) -
                                        Number(totalBaseAmount)
                                      ).toFixed(2);

                                      formData1.baseAmount = finalBaseAmount;

                                      formData1.tds = Number(
                                        (formData1.baseAmount *
                                          getTdsDetails?.tdsPercentage) /
                                        100
                                      )?.toFixed(2);

                                      if (!gstNo?.startsWith("09")) {
                                        if (formData1?.gstPercentage) {
                                          formData1.igst = Number(
                                            (formData1?.baseAmount *
                                              formData1?.gstPercentage) /
                                            100
                                          )?.toFixed(2);
                                          formData1.cgst = 0;
                                          formData1.sgst = 0;
                                          formData1.gst = Number(
                                            (formData1?.baseAmount *
                                              formData1?.gstPercentage) /
                                            100
                                          )?.toFixed(2);
                                        }
                                      } else {
                                        if (formData1?.gstPercentage) {
                                          formData1.igst = 0;
                                          formData1.cgst = Number(
                                            (formData1?.baseAmount *
                                              (formData1?.gstPercentage / 2)) /
                                            100
                                          )?.toFixed(2);
                                          formData1.sgst = Number(
                                            (formData1?.baseAmount *
                                              (formData1?.gstPercentage / 2)) /
                                            100
                                          )?.toFixed(2);
                                          formData1.gst = Number(
                                            ((formData1?.baseAmount *
                                              (formData1?.gstPercentage / 2)) /
                                              100) *
                                            2
                                          )?.toFixed(2);
                                        }
                                      }
                                      const otherCharges = formData1?.otherCharges ? Number(formData1?.otherCharges) : 0;
                                      const discount = formData1?.discount ? Number(formData1?.discount) : 0;

                                      formData1.totalBill = (
                                        Number(formData1?.baseAmount) +
                                        Number(formData1?.gst) +
                                        otherCharges -
                                        discount
                                      )?.toFixed(2);

                                      formData1.amountToBePaid = (
                                        Number(formData1?.totalBill) -
                                        Number(formData1?.tds)
                                      )?.toFixed(2);

                                      formData1.payable1 = (Number(formData1?.amountToBePaid) - Number(formData1?.gst)).toFixed(2);
                                      formData1.payable2 = Number(formData1?.gst).toFixed(2);

                                      // if (formData1?.otherCharges) {
                                      //   formData1.amountToBePaid = Number(
                                      //     Number(formData1?.amountToBePaid) +
                                      //     Number(formData1?.otherCharges)
                                      //   )?.toFixed(2);
                                      // }

                                      // if (formData1?.discount) {
                                      //   formData1.amountToBePaid = (
                                      //     Number(formData1.amountToBePaid) -
                                      //     Number(formData1?.discount)
                                      //   )?.toFixed(2);
                                      // }

                                      setLineItemArray2(
                                        lineItemArray2.filter((_, i) => {
                                          return i !== index;
                                        })
                                      );
                                      setFormData1({
                                        ...formData1,
                                        lineItemDetailsVendor:
                                          formData1?.lineItemDetailsVendor?.filter(
                                            (_, i) => i !== index
                                          ),
                                      });
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

              <div className={style.lastBtn_item}>
                <p style={{ paddingTop: "1%", fontWeight: "bold" }}>
                  Total Base Amount
                </p>
                <div>
                  <input
                    readOnly
                    className={style.smallInputDes}
                    value={formData1?.baseAmount}
                  />
                </div>
                <InvoiceButton
                  onClick={() => {
                    setLineItemArray2([
                      ...lineItemArray2,
                      Math.floor(Math.random() * 100),
                    ]);
                    setFormData1({
                      ...formData1,
                      lineItemDetailsVendor: [
                        ...formData1?.lineItemDetailsVendor,
                        {
                          unitAmount: "",
                          numberOfUnit: "",
                          description: "",
                          vendorBaseAmount: "",
                        },
                      ],
                    });
                  }}
                >
                  Add
                </InvoiceButton>
              </div>
            </InvoiceContainer>
          ) : null}

          {/* formData1 GST Applicable */}
          <InvoiceContainer>
            <div className={style.Container}>
              <div className={style.col1}>
                <div className={style.heading}>GST Applicable</div>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="Yes1"
                    name="radio-group"
                    onChange={() => {
                      const amountToBePaid =
                        Number(formData1?.totalBill) -
                        Number(formData1?.tds);

                      setFormData1({
                        ...formData1,
                        gstApplicable: true,
                        amountToBePaid: amountToBePaid.toFixed(2),
                        payable1: (amountToBePaid - Number(formData1?.gst)).toFixed(2),
                        payable2: Number(formData1?.gst).toFixed(2),
                      });
                    }}
                    checked={formData1.gstApplicable === true ? true : false}
                  />
                  <label for="Yes1">Yes</label>
                </div>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="no1"
                    name="radio-group"
                    onChange={() => {
                      const amountToBePaid =
                        Number(formData1?.baseAmount) -
                        Number(formData1?.tds);

                      setFormData1({
                        ...formData1,
                        gstApplicable: false,
                        amountToBePaid: amountToBePaid.toFixed(2),
                      });
                    }}
                    checked={formData1.gstApplicable === false ? true : false}
                  />
                  <label for="no1">No</label>
                </div>
              </div>
            </div>
            {formData1.gstApplicable === true ? (
              <>
                <div className={style.GstList}>
                  <div className={style.col17}>
                    <p className={style.label}>GST Percentage</p>
                    <Select
                      styles={InvoiceStylesVendorName}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        let gstNo = GetgstData?.gstNumber;
                        if (!gstNo?.startsWith("09")) {
                          if (e?.label !== null) {
                            formData1.igst = Number(
                              (formData1?.baseAmount * e?.label) / 100
                            )?.toFixed(2);
                            formData1.cgst = 0;
                            formData1.sgst = 0;
                            formData1.gst = Number(
                              (formData1?.baseAmount * e?.label) / 100
                            )?.toFixed(2);
                          }
                        } else {
                          if (e?.label !== null) {
                            formData1.igst = 0;
                            formData1.cgst = Number(
                              (formData1?.baseAmount * (e?.label / 2)) / 100
                            )?.toFixed(2);
                            formData1.sgst = Number(
                              (formData1?.baseAmount * (e?.label / 2)) / 100
                            )?.toFixed(2);
                            formData1.gst = Number(
                              ((formData1?.baseAmount * (e?.label / 2)) / 100) *
                              2
                            )?.toFixed(2);
                          }
                        }
                        const otherCharges = formData1?.otherCharges ? Number(formData1?.otherCharges) : 0;
                        const discount = formData1?.discount ? Number(formData1?.discount) : 0;
                        formData1.totalBill = (
                          Number(formData1?.baseAmount) + Number(formData1?.gst) + otherCharges - discount
                        )?.toFixed(2);

                        formData1.amountToBePaid = (
                          Number(formData1?.totalBill) - Number(formData1?.tds)
                        )?.toFixed(2);

                        formData1.payable1 = (Number(formData1?.amountToBePaid) - Number(formData1?.gst)).toFixed(2);
                        formData1.payable2 = Number(formData1?.gst).toFixed(2);

                        // if (formData1?.otherCharges) {
                        //   formData1.amountToBePaid = Number(
                        //     Number(formData1?.amountToBePaid) +
                        //     Number(formData1?.otherCharges)
                        //   )?.toFixed(2);
                        // }

                        // if (formData1?.discount) {
                        //   formData1.amountToBePaid = (
                        //     Number(formData1.amountToBePaid) -
                        //     Number(formData1?.discount)
                        //   )?.toFixed(2);
                        // }

                        formData1.gstPercentage = e?.label;
                        setFormData1({
                          ...formData1,
                          gstPercentage: e?.label,
                        });
                      }}
                      options={gstPercentageOption}
                      value={gstPercentageOption?.find((e) => {
                        return e?.label === formData1?.gstPercentage;
                      })}
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>Base Amount</p>
                    <input
                      value={formData1?.baseAmount}
                      type="number"
                      readOnly
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          baseAmount: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>IGST</p>
                    <input
                      value={formData1?.igst}
                      readOnly
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({ ...formData1, igst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>CGST</p>
                    <input
                      value={formData1?.cgst}
                      type="number"
                      readOnly
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({ ...formData1, cgst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>

                  <div className={style.col12}>
                    <p className={style.label}>SGST</p>
                    <input
                      value={formData1?.sgst}
                      type="number"
                      readOnly
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({ ...formData1, sgst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                </div>
                <div className={style.GstList2}>
                  <div className={style.col12}>
                    <p className={style.label}>GST(TOTAL)</p>
                    <input
                      value={formData1?.gst}
                      readOnly
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({ ...formData1, gst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>Amount (Billed)</p>
                    <input
                      value={formData1?.totalBill}
                      readOnly
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          totalBill: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>TDS</p>
                    <input
                      value={formData1?.tds}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) => {
                        const tdsValue = Number(e?.target?.value);
                        setFormData1({
                          ...formData1,
                          tds: e?.target?.value,
                          amountToBePaid: (
                            Number(formData1?.totalBill) - tdsValue
                          )?.toFixed(2),
                          payable1: (Number(formData?.totalBill) - tdsValue - Number(formData1?.gst)).toFixed(2),
                          payable2: Number(formData1?.gst).toFixed(2),
                        });
                      }}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>

                  <div className={style.col12}>
                    <p className={style.label}>Other Charges1</p>
                    <input
                      value={formData1?.otherCharges}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          otherCharges: e?.target?.value,
                          totalBill: (
                            Number(formData1?.baseAmount) + Number(formData1?.gst) +
                            Number(e?.target?.value) -
                            (formData?.discount
                              ? Number(formData?.discount)
                              : 0)
                          )?.toFixed(2),
                          amountToBePaid: (
                            Number(formData1?.baseAmount) + Number(formData1?.gst) -
                            Number(formData1?.tds) +
                            Number(e?.target?.value) -
                            (formData?.discount
                              ? Number(formData?.discount)
                              : 0)
                          )?.toFixed(2),
                          payable1: (
                            Number(formData1?.baseAmount) -
                            Number(formData1?.tds) +
                            Number(e?.target?.value) -
                            (formData?.discount
                              ? Number(formData?.discount)
                              : 0)
                          )?.toFixed(2),
                          payable2: Number(formData1?.gst).toFixed(2),
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>

                  <div className={style.col17}>
                    <p className={style.label}>Discount</p>
                    <input
                      type="number"
                      value={formData1?.discount}
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          discount: e?.target?.value,
                          totalBill: (
                            Number(formData1?.baseAmount) + Number(formData1?.gst) -
                            Number(e?.target?.value) +
                            (formData1?.otherCharges
                              ? Number(formData1?.otherCharges)
                              : 0)
                          )?.toFixed(2),
                          amountToBePaid: (
                            Number(formData1?.baseAmount) + Number(formData1?.gst) -
                            Number(formData1?.tds) -
                            Number(e?.target?.value) +
                            (formData1?.otherCharges
                              ? Number(formData1?.otherCharges)
                              : 0)
                          )?.toFixed(2),
                          payable1: (
                            Number(formData1?.baseAmount) -
                            Number(formData1?.tds) -
                            Number(e?.target?.value) +
                            (formData1?.otherCharges
                              ? Number(formData1?.otherCharges)
                              : 0)
                          )?.toFixed(2),
                          payable2: Number(formData1?.gst).toFixed(2),
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                </div>
                <div className={style.GstList4}>
                  <div className={style.col12}>
                    <p className={style.label}>Amount (Payable)</p>
                    <input
                      value={formData1?.amountToBePaid}
                      type="number"
                      className={style.GstInput}
                      readOnly
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>Payable 1</p>
                    <input
                      value={formData1?.payable1}
                      type="number"
                      className={style.GstInput}
                      readOnly
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>Payable 2</p>
                    <input
                      value={formData1?.payable2}
                      type="number"
                      className={style.GstInput}
                      readOnly
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>Payment Due On</p>

                    <input
                      value={
                        formData1?.paymentDueOn
                          ? moment(formData1?.paymentDueOn).format(
                            "DD MMM YYYY"
                          )
                          : null
                      }
                      className={style.GstInput}
                      readOnly
                    />
                  </div>
                  <div className={style.payment_mode_col}>
                    <p className={style.label}>Payment Mode<span style={{ color: "red" }}>*</span></p>
                    <Select
                      styles={InvoiceStylesVendorName}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          paymentModeId: e?.value,
                          paymentMode: e?.label,
                        })
                      }
                      options={paymentModeOption}
                      value={paymentModeOption?.find((e) => {
                        return e?.label === formData1?.paymentMode;
                      })}
                    />
                    <p
                      className={
                        validate && !formData1?.paymentMode
                          ? style.errorText2
                          : style.errorTextHidden2
                      }
                    >
                      Please Select Payment Mode
                    </p>
                  </div>
                </div>
                <div className={style.GstList3}>
                  <div className={style.payment_note_col2}>
                    <p className={style.label}>Payment Note</p>
                    <input
                      value={formData1?.paymentNote}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          paymentNote: e?.target?.value,
                        })
                      }
                      className={style.GstInput}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={style.GstList2}>
                  <div className={style.col12}>
                    <p className={style.label}>Base Amount</p>
                    <input
                      value={formData1?.baseAmount}
                      type="number"
                      className={style.GstInput}
                      readOnly
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          baseAmount: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>TDS</p>
                    <input
                      value={formData1?.tds}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) => {
                        const amountToBePaid =
                          Number(formData1?.baseAmount) -
                          Number(e?.target?.value) +
                          (formData1?.otherCharges
                            ? Number(formData1.otherCharges)
                            : 0) -
                          (formData1?.discount
                            ? Number(formData1.discount)
                            : 0);

                        setFormData1({
                          ...formData1,
                          tds: e?.target?.value,
                          amountToBePaid: amountToBePaid.toFixed(2),
                        });
                      }}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>

                  <div className={style.col12}>
                    <p className={style.label}>Other Charges2</p>
                    <input
                      value={formData1?.otherCharges}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          otherCharges: e?.target?.value,
                          amountToBePaid: (
                            Number(formData1?.baseAmount) -
                            Number(formData1?.tds) +
                            Number(e?.target?.value) -
                            (formData?.discount
                              ? Number(formData?.discount)
                              : 0)
                          )?.toFixed(2),
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>

                  <div className={style.col17}>
                    <p className={style.label}>Discount</p>
                    <input
                      type="number"
                      value={formData1?.discount}
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          discount: e?.target?.value,
                          amountToBePaid: (
                            Number(formData1?.baseAmount) -
                            Number(formData1?.tds) -
                            Number(e?.target?.value) +
                            (formData1?.otherCharges
                              ? Number(formData1?.otherCharges)
                              : 0)
                          )?.toFixed(2),
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                </div>

                <div className={style.GstList3}>
                  <div className={style.col12}>
                    <p className={style.label}>Amount (Payable)</p>
                    <input
                      value={formData1?.amountToBePaid}
                      type="number"
                      className={style.GstInput}
                      readOnly
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                  <div className={style.col17}>
                    <p className={style.label}>Payment Due On</p>
                    <input
                      value={
                        formData1?.paymentDueOn
                          ? moment(formData1?.paymentDueOn).format(
                            "DD MMM YYYY"
                          )
                          : null
                      }
                      className={style.GstInput}
                      readOnly
                    />
                  </div>
                  <div className={style.payment_mode_col}>
                    <p className={style.label}>Payment Mode<span style={{ color: "red" }}>*</span></p>
                    <Select
                      styles={InvoiceStylesVendorName}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          paymentModeId: e?.value,
                          paymentMode: e?.label,
                        })
                      }
                      options={paymentModeOption}
                      value={paymentModeOption?.find((e) => {
                        return e?.label === formData1?.paymentMode;
                      })}
                    />
                    <p
                      className={
                        validate && !formData1?.paymentMode
                          ? style.errorText2
                          : style.errorTextHidden2
                      }
                    >
                      Please Select Payment Mode
                    </p>
                  </div>

                  <div className={style.payment_note_col}>
                    <p className={style.label}>Payment Note</p>
                    <input
                      value={formData1?.paymentNote}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          paymentNote: e?.target?.value,
                        })
                      }
                      className={style.GstInput}
                    />
                  </div>
                </div>
              </>
            )}
          </InvoiceContainer>
          <InvoiceContainer>
            <div className={style.Container}>
              <div className={style.upload_container}>
                <div className={style.heading}>Upload Files</div>
                <UploadMultiFile
                  handleFileChange={handleFileChange}
                  handleFileDelete={handleFileDelete1}
                  documentResponses={formData1.documentResponses}
                />
              </div>
            </div>
          </InvoiceContainer>
          <div className={style.lastBtn}>
            <InvoiceButton
              type={"cancel"}
              onClick={() => {
                setNextScreen("expense");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: null,
                  page3: null,
                  page4: null,
                  active: "expense",
                }));
              }}
            >
              Cancel
            </InvoiceButton>

            <InvoiceButton
              onClick={() => {
                submitHandler1();
              }}
            >
              {prevData?.page2 === "editExpense" ? "Update" : "Create"}
            </InvoiceButton>
          </div>
        </>
      ) : (
        <>
          <InvoiceContainer>
            <div className={style.copy_expence}>Expense Details</div>
            <div className={style.rowExp}>
              <Row>
                <Col md={3} style={{ marginLeft: "26px" }}>
                  <p className={style.label}>
                    Category<span style={{ color: "red" }}>*</span>
                  </p>
                  <Select
                    styles={InvoiceStylesVendorName}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        category: e.value,
                        categoryName: e?.label,
                      });
                    }}
                    options={expenseCategoryOption}
                    value={expenseCategoryOption?.find((e) => {
                      return e?.label === formData?.categoryName;
                    })}
                  />
                  <p
                    className={
                      validate && !formData?.category
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Enter Category
                  </p>
                </Col>
              </Row>
            </div>

            {/*row 2*/}
            <div className={style.ContainerExpDetails}>
              <div className={style.col2}>
                <p className={style.label}>
                  Invoice No<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  className={style.bigInput}
                  value={formData?.invoice}
                  onChange={(e) => {
                    setFormData({ ...formData, invoice: e?.target?.value })
                    setIsInvoiceExist(false)
                  }
                  }
                  onBlur={(e) => handleInvoice(ExpenseDataByEditId?.copyOfExpense === true
                    ? formData1?.vendorId
                    : formData?.vendorId, e.target.value,formData?.serviceYearId)}

                />
                {isInvoiceExist ?
                  <p
                    className={style.errorText}>
                    Invoice number already present for this partner
                  </p>
                  : <p
                    className={
                      validate && !formData1?.invoice
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Enter Invoice No
                  </p>
                }
              </div>

              <div className={style.col2}>
                <p className={style.label}>
                  Date of Invoice<span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData?.dateOfExpense
                        ? moment(formData?.dateOfExpense).toDate()
                        : null
                    }
                    onChange={(date) => {
                      const selectedMonth = moment(date).format("MMMM");
                      const selectedYear = moment(date).format("YYYY");
                      paymentDueDateFun(date);
                      setFormData({
                        ...formData,
                        dateOfExpense: moment(date).format(),
                        expenseMonth: selectedMonth,
                        expenseYear: selectedYear,
                        paymentDueOn: "",
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      formData?.dateOfExpense ? (
                        <CustomInputCross properties="dateOfExpense" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
                <p
                  className={
                    validate && !formData?.dateOfExpense
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Date of Invoice
                </p>
              </div>

              <div className={style.col3}>
                <p className={style.label}>Expense Month</p>
                {/* <input
                  className={style.bigInput}
                  value={formData?.expenseMonth}
                  readOnly
                /> */}

                <Select
                  styles={InvoiceStylesVendorName}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      expenseMonth: e?.label,
                    });
                  }}
                  options={monthOption}
                  value={
                    formData?.expenseMonth
                      ? monthOption?.find((e) => {
                        return e?.label === formData?.expenseMonth;
                      })
                      : null
                  }
                />
              </div>
              <div className={style.col4}>
                <p className={style.label}>Expense year</p>
                <input
                  className={style.bigInput}
                  value={formData?.expenseYear}
                  readOnly
                />
              </div>
            </div>

            <div className={style.ContainerExpDetails}>
              <div className={style.col212}>
                <p className={style.label}>Approved By</p>
                <input
                  value={formData?.approveBy}
                  className={style.bigInput}
                  onChange={(e) =>
                    setFormData({ ...formData, approveBy: e?.target?.value })
                  }
                />
              </div>
              <div className={style.col212}>
                <p className={style.label}>GST No.</p>
                <input
                  value={formData?.gstNo ? formData?.gstNo : ""}
                  className={style.bigInput}
                  readOnly
                />
              </div>

              <div className={style.col212}>
                <p className={style.label}>PAN Card</p>
                <input
                  value={formData1?.pan ? formData1?.pan : ""}
                  className={style.bigInput}
                  readOnly
                />
              </div>

              <div className={style.col212}></div>
            </div>
          </InvoiceContainer>

          {/* Line Item Details Container Partner */}
          {venderType?.type === "Partner" && (
            <InvoiceContainer>
              <div className={style.copy_expence}>Line Item Details</div>
              {/*row 1*/}

              {formData?.lineItemDetails?.map((item, index) => {
                return (
                  <>
                    <div
                      className={
                        index === 0 ? style.first_div : style.another_div
                      }
                      key={`lineItem ${index}`}
                    >
                      {/* Resorce Type */}
                      <div>
                        <div
                          style={{ padding: "0px 17px" }}
                          className={style.ContainerLineItemV}
                        >
                          <div className={style.col12_item}>
                            {index === 0 && (
                              <p className={style.label_item}>
                                Candidate Name
                                <span style={{ color: "red" }}>*</span>
                              </p>
                            )}

                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.lineItemDetails];
                                temp[index].resourseId = e?.value;
                                temp[index].candidateName = e?.label;
                                temp[index].billingRate = Number(
                                  clientNameOption?.find(
                                    (item) => item?.value == e?.value
                                  )?.rate
                                );
                                temp[index].workingDays = "";
                                temp[index].baseAmount = "";
                                temp[index].expenseMonth = "";
                                temp[index].expenseYear = "";
                                setFormData({
                                  ...formData,
                                  lineItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.lineItemDetails[index].candidateName
                                  ? clientNameOption?.find(
                                    (e) =>
                                      e.label ===
                                      formData?.lineItemDetails[index]
                                        .candidateName
                                  )
                                  : null
                              }
                              options={clientNameOption}
                            />
                            {validate &&
                              !formData?.lineItemDetails[index].resourseId && (
                                <p
                                  className={
                                    validate &&
                                      !formData?.lineItemDetails[index].resourseId
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please write Candidate Name
                                </p>
                              )}
                          </div>

                          <div className={style.col4_item}>
                            {index === 0 && (
                              <p className={style.label_item}>
                                Billing Rate
                                <span style={{ color: "red" }}>*</span>
                              </p>
                            )}
                            <input
                              type="number"
                              className={style.smallInput}
                              readOnly
                              value={
                                formData?.lineItemDetails[index]?.billingRate
                              }
                              min="0"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) =>
                                (e.keyCode === 38 ||
                                  e.keyCode === 40 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.preventDefault()
                              }
                              onKeyPress={(e) =>
                                ((e.which !== 8 &&
                                  e.which !== 0 &&
                                  e.which < 48) ||
                                  e.which > 57 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.prevent
                              }
                            />
                            {validate && !item?.billingRate && (
                              <p
                                className={
                                  validate && !item?.billingRate
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please write Billing Rate
                              </p>
                            )}
                          </div>

                          <div className={style.col5_item}>
                            {index === 0 && (
                              <p className={style.label_item}>
                                Working Days
                                <span style={{ color: "red" }}>*</span>
                              </p>
                            )}
                            <input
                              type="number"
                              className={style.smallInput}
                              value={
                                formData?.lineItemDetails[index]?.workingDays
                              }
                              onChange={(e) => {
                                handleWorkingDays(e, index);
                              }}
                              min="0"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) =>
                                (e.keyCode === 38 ||
                                  e.keyCode === 40 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.preventDefault()
                              }
                              onKeyPress={(e) =>
                                ((e.which !== 8 &&
                                  e.which !== 0 &&
                                  e.which < 48) ||
                                  e.which > 57 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.prevent
                              }
                            />
                            {validate &&
                              !formData?.lineItemDetails[index]
                                ?.workingDays && (
                                <p
                                  className={
                                    validate &&
                                      !formData?.lineItemDetails[index]
                                        ?.workingDays
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please write Working Days
                                </p>
                              )}
                          </div>

                          <div className={style.col6_item}>
                            {index === 0 && (
                              <p className={style.label_item}>
                                Max Working Days
                                <span style={{ color: "red" }}>*</span>
                              </p>
                            )}
                            <input
                              type="number"
                              className={style.smallInput}
                              onChange={(e) => {
                                handleMaxWorkingDays(e, index);
                              }}
                              value={
                                formData?.lineItemDetails[index]?.maxWorkingDays
                              }
                              min="0"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) =>
                                (e.keyCode === 38 ||
                                  e.keyCode === 40 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.preventDefault()
                              }
                              onKeyPress={(e) =>
                                ((e.which !== 8 &&
                                  e.which !== 0 &&
                                  e.which < 48) ||
                                  e.which > 57 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.preventDefault()
                              }
                            />
                            {validate && !item?.maxWorkingDays && (
                              <p
                                className={
                                  validate && !item?.maxWorkingDays
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please write Max Working Days
                              </p>
                            )}
                          </div>

                          <div className={style.col12_item}>
                            {index === 0 && (
                              <p className={style.label_item}>
                                Base Amount
                                <span style={{ color: "red" }}>*</span>
                              </p>
                            )}
                            <input
                              type="number"
                              className={style.smallInput}
                              value={
                                formData?.lineItemDetails[index]?.baseAmount
                              }
                              readOnly
                              min="0"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) =>
                                (e.keyCode === 38 ||
                                  e.keyCode === 40 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.preventDefault()
                              }
                              onKeyPress={(e) =>
                                ((e.which !== 8 &&
                                  e.which !== 0 &&
                                  e.which < 48) ||
                                  e.which > 57 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.prevent
                              }
                            />
                            {validate && !item?.baseAmount && (
                              <p
                                className={
                                  validate && !item?.baseAmount
                                    ? style.errorText
                                    : style.errorTextHidden
                                }
                              >
                                Please write Base Amount
                              </p>
                            )}
                          </div>
                        </div>
                        <div
                          style={{ paddingLeft: "17px" }}
                          className={
                            index === 0
                              ? style.ContainerLineItemV
                              : style.ContainerLineItemV2
                          }
                        >
                          <div className={style.col7_item}>
                            {index === 0 && (
                              <p className={style.label_item}>
                                Description
                                <span style={{ color: "red" }}>*</span>
                              </p>
                            )}

                            <input
                              type="text"
                              className={style.smallInputDes}
                              value={
                                formData?.lineItemDetails[index]?.description
                              }
                              onChange={(e) => {
                                let temp = [...formData?.lineItemDetails];
                                temp[index].description = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  lineItemDetails: temp,
                                });
                              }}
                            />
                            {validate &&
                              !formData?.lineItemDetails[index].description && (
                                <p
                                  className={
                                    validate &&
                                      !formData?.lineItemDetails[index]
                                        .description
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Enter Description
                                </p>
                              )}
                          </div>

                          <div className={style.col8_item}>
                            {formData?.lineItemDetails?.length > 1 && (
                              <>
                                <FaTrash
                                  className={
                                    index === 0
                                      ? style.remove_icon
                                      : style.remove_icon2
                                  }
                                  style={{ marginTop: "10%" }}
                                  size={20}
                                  onClick={(e) => {
                                    let gstNo = GetgstData?.gstNumber;

                                    const totalBaseAmount = Number(
                                      formData?.lineItemDetails[index]
                                        ?.baseAmount
                                    )?.toFixed(2);

                                    const finalBaseAmount = (
                                      Number(formData?.baseAmount) -
                                      Number(totalBaseAmount)
                                    ).toFixed(2);

                                    formData.baseAmount = finalBaseAmount;

                                    formData.tds = Number(
                                      (formData.baseAmount *
                                        getTdsDetails?.tdsPercentage) /
                                      100
                                    )?.toFixed(2);

                                    if (!gstNo?.startsWith("09")) {
                                      if (formData?.gstPercentage) {
                                        formData.igst = Number(
                                          (formData?.baseAmount *
                                            formData?.gstPercentage) /
                                          100
                                        )?.toFixed(2);
                                        formData.cgst = 0;
                                        formData.sgst = 0;
                                        formData.gst = Number(
                                          (formData?.baseAmount *
                                            formData?.gstPercentage) /
                                          100
                                        )?.toFixed(2);
                                      }
                                    } else {
                                      if (formData?.gstPercentage) {
                                        formData.igst = 0;
                                        formData.cgst = Number(
                                          (formData?.baseAmount *
                                            (formData?.gstPercentage / 2)) /
                                          100
                                        )?.toFixed(2);
                                        formData.sgst = Number(
                                          (formData?.baseAmount *
                                            (formData?.gstPercentage / 2)) /
                                          100
                                        )?.toFixed(2);
                                        formData.gst = Number(
                                          ((formData?.baseAmount *
                                            (formData?.gstPercentage / 2)) /
                                            100) *
                                          2
                                        )?.toFixed(2);
                                      }
                                    }
                                    const otherCharges = formData?.otherCharges ? Number(formData?.otherCharges) : 0;
                                    const discount = formData?.discount ? Number(formData1?.discount) : 0;
                                    formData.totalBill = (
                                      Number(formData?.baseAmount) +
                                      Number(formData?.gst) +
                                      otherCharges -
                                      discount
                                    )?.toFixed(2);

                                    formData.amountToBePaid = (
                                      Number(formData?.totalBill) -
                                      Number(formData?.tds)
                                    )?.toFixed(2);
                                    formData.payable1 = (Number(formData?.amountToBePaid) - Number(formData?.gst)).toFixed(2);
                                    formData.payable2 = Number(formData?.gst).toFixed(2);

                                    // if (formData?.otherCharges) {
                                    //   formData.amountToBePaid = Number(
                                    //     Number(formData?.amountToBePaid) +
                                    //     Number(formData?.otherCharges)
                                    //   )?.toFixed(2);
                                    // }

                                    // if (formData?.discount) {
                                    //   formData.amountToBePaid = (
                                    //     Number(formData.amountToBePaid) -
                                    //     Number(formData?.discount)
                                    //   )?.toFixed(2);
                                    // }

                                    setLineItemArray(
                                      lineItemArray.filter((_, i) => {
                                        return i !== index;
                                      })
                                    );

                                    setFormData({
                                      ...formData,
                                      lineItemDetails:
                                        formData?.lineItemDetails?.filter(
                                          (_, i) => i !== index
                                        ),
                                    });
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

              <div className={style.lastBtn_item}>
                <p style={{ paddingTop: "1%", fontWeight: "bold" }}>
                  Total Base Amount
                </p>
                <div>
                  <input
                    readOnly
                    className={style.smallInputDes}
                    value={formData?.baseAmount}
                  />
                </div>
                <InvoiceButton
                  onClick={() => {
                    setLineItemArray([
                      ...lineItemArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setFormData({
                      ...formData,
                      lineItemDetails: [
                        ...formData?.lineItemDetails,
                        {
                          resourseId: "",
                          expenseMonth: "",
                          expenseYear: "",
                          billingRate: "",
                          workingDays: "",
                          maxWorkingDays:
                            formData?.lineItemDetails[0]?.maxWorkingDays,
                          baseAmount: "",
                        },
                      ],
                    });
                  }}
                >
                  Add
                </InvoiceButton>
              </div>
            </InvoiceContainer>
          )}

          {/* Line Item Details Container Vendor */}
          {venderType?.type === "Vendor" && (
            <InvoiceContainer>
              <div className={style.copy_expence}>Line Item Details</div>
              {/*row 1*/}
              {formData?.lineItemDetailsVendor?.map((item, index) => {
                return (
                  <>
                    <div key={`lineItem ${index}`}>
                      <div>
                        <div className={style.ContainerLineItem}>
                          <div className={style.col2_item}>
                            <p className={style.label_item}>
                              Unit Amount
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <input
                              className={style.smallInput}
                              value={
                                formData?.lineItemDetailsVendor[index]
                                  ?.unitAmount
                              }
                              type="number"
                              min={0}
                              onChange={(e) => {
                                let temp = [...formData?.lineItemDetailsVendor];
                                temp[index].unitAmount = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  lineItemDetailsVendor: temp,
                                });
                                vendorBaseAmountFun(index);
                              }}
                            />
                            <p
                              className={
                                validate &&
                                  !formData?.lineItemDetailsVendor[index]
                                    .unitAmount
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Unit Amount
                            </p>
                          </div>
                          <div className={style.col4_item}>
                            <p className={style.label_item}>
                              No. of Unit
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <input
                              className={style.smallInput}
                              value={
                                formData?.lineItemDetailsVendor[index]
                                  ?.numberOfUnit
                              }
                              type="number"
                              min={0}
                              onChange={(e) => {
                                let temp = [...formData?.lineItemDetailsVendor];
                                temp[index].numberOfUnit = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  lineItemDetailsVendor: temp,
                                });
                                vendorBaseAmountFun(index);
                              }}
                              onKeyDown={(e) =>
                                (e.keyCode === 38 ||
                                  e.keyCode === 40 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.preventDefault()
                              }
                              onKeyPress={(e) =>
                                ((e.which !== 8 &&
                                  e.which !== 0 &&
                                  e.which < 48) ||
                                  e.which > 57 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.prevent
                              }
                            />
                            <p
                              className={
                                validate &&
                                  !formData?.lineItemDetailsVendor[index]
                                    .numberOfUnit
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter No of Unit
                            </p>
                          </div>
                          <div className={style.col3_item}>
                            <p className={style.label_item}>
                              Base Amount<span style={{ color: "red" }}>*</span>
                            </p>

                            <input
                              className={style.smallInput}
                              readOnly
                              value={
                                formData?.lineItemDetailsVendor[index]
                                  ?.vendorBaseAmount
                              }
                            />
                          </div>
                          <div className={style.col12_itemv}>
                            <p className={style.label_item}>
                              Description
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <input
                              className={style.smallInput}
                              value={
                                formData?.lineItemDetailsVendor[index]
                                  ?.description
                              }
                              onChange={(e) => {
                                let temp = [...formData?.lineItemDetailsVendor];
                                temp[index].description = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  lineItemDetailsVendor: temp,
                                });
                              }}
                            />
                            <p
                              className={
                                validate &&
                                  !formData?.lineItemDetailsVendor[index]
                                    .description
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please write Description
                            </p>
                          </div>
                          <div className={style.col8_item8}>
                            {formData?.lineItemDetailsVendor?.length > 1 && (
                              <>
                                <FaTrash
                                  className={style.remove_icon}
                                  size={20}
                                  onClick={(e) => {
                                    let gstNo = GetgstData?.gstNumber;

                                    const totalBaseAmount = Number(
                                      formData?.lineItemDetailsVendor[index]
                                        ?.vendorBaseAmount
                                    )?.toFixed(2);

                                    const finalBaseAmount = (
                                      Number(formData?.baseAmountVendor) -
                                      Number(totalBaseAmount)
                                    ).toFixed(2);

                                    formData.baseAmountVendor = finalBaseAmount;

                                    formData.tds = Number(
                                      (formData.baseAmountVendor *
                                        getTdsDetails?.tdsPercentage) /
                                      100
                                    )?.toFixed(2);

                                    if (!gstNo?.startsWith("09")) {
                                      if (formData?.gstPercentage) {
                                        formData.igst = Number(
                                          (formData?.baseAmountVendor *
                                            formData?.gstPercentage) /
                                          100
                                        )?.toFixed(2);
                                        formData.cgst = 0;
                                        formData.sgst = 0;
                                        formData.gst = Number(
                                          (formData?.baseAmountVendor *
                                            formData?.gstPercentage) /
                                          100
                                        )?.toFixed(2);
                                      }
                                    } else {
                                      if (formData?.gstPercentage) {
                                        formData.igst = 0;
                                        formData.cgst = Number(
                                          (formData?.baseAmountVendor *
                                            (formData?.gstPercentage / 2)) /
                                          100
                                        )?.toFixed(2);
                                        formData.sgst = Number(
                                          (formData?.baseAmountVendor *
                                            (formData?.gstPercentage / 2)) /
                                          100
                                        )?.toFixed(2);
                                        formData.gst = Number(
                                          ((formData?.baseAmountVendor *
                                            (formData?.gstPercentage / 2)) /
                                            100) *
                                          2
                                        )?.toFixed(2);
                                      }
                                    }
                                    const otherCharges = formData?.otherCharges ? Number(formData?.otherCharges) : 0;
                                    const discount = formData?.discount ? Number(formData?.discount) : 0;
                                    formData.totalBill = (
                                      Number(formData?.baseAmountVendor) +
                                      Number(formData?.gst) +
                                      otherCharges -
                                      discount
                                    )?.toFixed(2);

                                    formData.amountToBePaid = (
                                      Number(formData?.totalBill) -
                                      Number(formData?.tds)
                                    )?.toFixed(2);

                                    formData.payable1 = (Number(formData?.amountToBePaid) - Number(formData?.gst)).toFixed(2);
                                    formData.payable2 = Number(formData?.gst).toFixed(2);

                                    // if (formData?.otherCharges) {
                                    //   formData.amountToBePaid = Number(
                                    //     Number(formData?.amountToBePaid) +
                                    //     Number(formData?.otherCharges)
                                    //   )?.toFixed(2);
                                    // }

                                    // if (formData?.discount) {
                                    //   formData.amountToBePaid = (
                                    //     Number(formData.amountToBePaid) -
                                    //     Number(formData?.discount)
                                    //   )?.toFixed(2);
                                    // }

                                    setLineItemArray2(
                                      lineItemArray2.filter(
                                        (_, i) => i !== index
                                      )
                                    );

                                    setFormData({
                                      ...formData,
                                      lineItemDetailsVendor:
                                        formData?.lineItemDetailsVendor?.filter(
                                          (_, i) => i !== index
                                        ),
                                    });
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

              <div className={style.lastBtn_item}>
                <p style={{ paddingTop: "1%", fontWeight: "bold" }}>
                  Total Base Amount
                </p>
                <div>
                  <input
                    readOnly
                    className={style.smallInputDes}
                    value={formData?.baseAmountVendor}
                  />
                </div>
                <InvoiceButton
                  onClick={() => {
                    setLineItemArray2([
                      ...lineItemArray2,
                      Math.floor(Math.random() * 100),
                    ]);
                    setFormData({
                      ...formData,
                      lineItemDetailsVendor: [
                        ...formData?.lineItemDetailsVendor,
                        {
                          description: "",
                          numberOfUnit: "",
                          unitAmount: "",
                          vendorBaseAmount: "",
                        },
                      ],
                    });
                  }}
                >
                  Add
                </InvoiceButton>
              </div>
            </InvoiceContainer>
          )}

          {/* partnerType GST Applicable */}
          {venderType?.type === "Partner" && (
            <InvoiceContainer>
              <div className={style.Container}>
                <div className={style.col1}>
                  <div className={style.heading}>GST Applicable</div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="Yes11"
                      name="radio-group"
                      checked={formData?.gstApplicable === true ? true : false}
                      onChange={() => {
                        const amountToBePaid =
                          Number(formData?.totalBill) -
                          Number(formData?.tds);

                        setFormData({
                          ...formData,
                          gstApplicable: true,
                          amountToBePaid: amountToBePaid?.toFixed(2),
                          payable1: (amountToBePaid - Number(formData?.gst)).toFixed(2),
                          payable2: Number(formData?.gst).toFixed(2),
                        });
                      }}
                    />
                    <label for="Yes11">Yes</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="no11"
                      name="radio-group"
                      checked={formData?.gstApplicable === false ? true : false}
                      onChange={() => {
                        const amountToBePaid =
                          Number(formData?.baseAmount) -
                          Number(formData?.tds);

                        setFormData({
                          ...formData,
                          gstApplicable: false,
                          amountToBePaid: amountToBePaid?.toFixed(2),
                        });
                      }}
                    />
                    <label for="no11">No</label>
                  </div>
                </div>
              </div>
              {formData.gstApplicable === true ? (
                <>
                  <div className={style.GstList}>
                    <div className={style.col17}>
                      <p className={style.label}>GST Percentage</p>
                      <Select
                        styles={InvoiceStylesVendorName}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          let gstNo = GetgstData?.gstNumber;
                          if (!gstNo?.startsWith("09")) {
                            if (e?.label) {
                              formData.igst = Number(
                                (formData?.baseAmount * e?.label) / 100
                              )?.toFixed(2);
                              formData.cgst = 0;
                              formData.sgst = 0;
                              formData.gst = Number(
                                (formData?.baseAmount * e?.label) / 100
                              )?.toFixed(2);
                            }
                          } else {
                            if (e?.label) {
                              formData.igst = 0;
                              formData.cgst = Number(
                                (formData?.baseAmount * (e?.label / 2)) / 100
                              )?.toFixed(2);
                              formData.sgst = Number(
                                (formData?.baseAmount * (e?.label / 2)) / 100
                              )?.toFixed(2);
                              formData.gst = Number(
                                ((formData?.baseAmount * (e?.label / 2)) /
                                  100) *
                                2
                              )?.toFixed(2);
                            }
                          }
                          const otherCharges = formData?.otherCharges ? Number(formData?.otherCharges) : 0;
                          const discount = formData?.discount ? Number(formData?.discount) : 0;
                          formData.totalBill = (
                            Number(formData?.baseAmount) + Number(formData?.gst) + otherCharges - discount
                          )?.toFixed(2);

                          formData.amountToBePaid = (
                            Number(formData?.totalBill) - Number(formData?.tds)
                          )?.toFixed(2);

                          formData.payable1 = (Number(formData?.amountToBePaid) - Number(formData?.gst)).toFixed(2);
                          formData.payable2 = Number(formData?.gst).toFixed(2);
                          // if (formData?.otherCharges) {
                          //   formData.amountToBePaid = Number(
                          //     Number(formData?.amountToBePaid) +
                          //     Number(formData?.otherCharges)
                          //   )?.toFixed(2);
                          // }

                          // if (formData?.discount) {
                          //   formData.amountToBePaid = (
                          //     Number(formData.amountToBePaid) -
                          //     Number(formData?.discount)
                          //   )?.toFixed(2);
                          // }

                          formData.gstPercentage = e?.label;

                          setFormData({
                            ...formData,
                            gstPercentage: e?.label,
                          });
                        }}
                        options={gstPercentageOption}
                        value={gstPercentageOption?.find((e) => {
                          return e?.label === formData?.gstPercentage;
                        })}
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>Base Amount</p>
                      <input
                        value={formData?.baseAmount}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>IGST</p>
                      <input
                        value={formData?.igst}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        onChange={(e) =>
                          setFormData({ ...formData, igst: e?.target?.value })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>CGST</p>
                      <input
                        value={formData?.cgst}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        onChange={(e) =>
                          setFormData({ ...formData, cgst: e?.target?.value })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>

                    <div className={style.col12}>
                      <p className={style.label}>SGST</p>
                      <input
                        value={formData?.sgst}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        onChange={(e) =>
                          setFormData({ ...formData, sgst: e?.target?.value })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                  </div>
                  <div className={style.GstList2}>
                    <div className={style.col12}>
                      <p className={style.label}>GST(TOTAL)</p>
                      <input
                        value={formData?.gst}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>

                    <div className={style.col12}>
                      <p className={style.label}>Amount (Billed)</p>
                      <input
                        value={formData?.totalBill}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            totalBill: e?.target?.value,
                          })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>TDS</p>
                      <input
                        value={formData?.tds}
                        type="number"
                        className={style.GstInput}
                        onChange={(e) => {
                          const tdsValue = Number(e?.target?.value);
                          setFormData({
                            ...formData,
                            tds: tdsValue,
                            amountToBePaid: (
                              Number(formData?.totalBill) -
                              tdsValue
                            )?.toFixed(2),
                            payable1: (Number(formData?.totalBill) - tdsValue - Number(formData?.gst)).toFixed(2),
                            payable2: Number(formData?.gst).toFixed(2),
                          });
                        }}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>Other Charges3</p>
                      <input
                        value={formData?.otherCharges}
                        type="number"
                        className={style.GstInput}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            otherCharges: e?.target?.value,
                            totalBill: (
                              Number(formData?.baseAmount) + Number(formData?.gst) +
                              Number(e?.target?.value) -
                              (formData?.discount
                                ? Number(formData?.discount)
                                : 0)
                            )?.toFixed(2),
                            amountToBePaid: (
                              Number(formData?.baseAmount) + Number(formData?.gst) -
                              Number(formData?.tds) +
                              Number(e?.target?.value) -
                              (formData?.discount
                                ? Number(formData?.discount)
                                : 0)
                            )?.toFixed(2),
                            payable1: (
                              Number(formData?.baseAmount) -
                              Number(formData?.tds) +
                              Number(e?.target?.value) -
                              (formData?.discount
                                ? Number(formData?.discount)
                                : 0)
                            )?.toFixed(2),
                            payable2: Number(formData?.gst).toFixed(2),
                          })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col17}>
                      <p className={style.label}>Discount</p>
                      <input
                        type="number"
                        value={formData?.discount}
                        className={style.GstInput}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            discount: e?.target?.value,
                            totalBill: (
                              Number(formData?.baseAmount) + Number(formData?.gst) -
                              Number(e?.target?.value) +
                              (formData?.otherCharges
                                ? Number(formData?.otherCharges)
                                : 0)
                            )?.toFixed(2),
                            amountToBePaid: (
                              Number(formData?.baseAmount) + Number(formData?.gst) -
                              Number(formData?.tds) -
                              Number(e?.target?.value) +
                              (formData?.otherCharges
                                ? Number(formData?.otherCharges)
                                : 0)
                            )?.toFixed(2),
                            payable1: (
                              Number(formData?.baseAmount) -
                              Number(formData?.tds) -
                              Number(e?.target?.value) +
                              (formData?.otherCharges
                                ? Number(formData?.otherCharges)
                                : 0)
                            )?.toFixed(2),
                            payable2: Number(formData?.gst).toFixed(2),
                          })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                  </div>

                  <div className={style.GstList2}>
                    <div className={style.col12}>
                      <p className={style.label}>Amount (Payable)</p>
                      <input
                        value={formData?.amountToBePaid}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>Payable 1</p>
                      <input
                        value={formData?.payable1}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>Payable 2</p>
                      <input
                        value={formData?.payable2}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>Payment Due On</p>
                      <input
                        type="text"
                        value={
                          formData?.dateOfExpense
                            ? moment(formData?.paymentDueOn).format(
                              "DD MMM YYYY"
                            )
                            : ""
                        }
                        className={style.GstInput}
                        readOnly
                      />
                    </div>
                    <div className={style.payment_mode_col}>
                      <p className={style.label}>Payment Mode<span style={{ color: "red" }}>*</span></p>
                      <Select
                        styles={InvoiceStylesVendorName}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            paymentModeId: e?.value,
                            paymentMode: e?.label,
                          })
                        }
                        options={paymentModeOption}
                        value={paymentModeOption?.find((e) => {
                          return e?.label === formData?.paymentMode;
                        })}
                      />
                      <p
                        className={
                          validate && !formData?.paymentMode
                            ? style.errorText2
                            : style.errorTextHidden2
                        }
                      >
                        Please Select Payment Mode
                      </p>
                    </div>
                  </div>
                  <div className={style.GstList3}>

                    <div className={style.payment_note_col2}>
                      <p className={style.label}>Payment Note</p>
                      <input
                        value={formData?.paymentNote}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            paymentNote: e?.target?.value,
                          })
                        }
                        className={style.GstInput}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={style.GstList2}>
                    <div className={style.col12}>
                      <p className={style.label}>Base Amount</p>
                      <input
                        value={formData?.baseAmount}
                        type="number"
                        className={style.GstInput}
                        readOnly
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>TDS</p>
                      <input
                        value={formData?.tds}
                        type="number"
                        className={style.GstInput}
                        onChange={(e) => tdsCalculateFun(e.target.value)}
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>Other Charges4</p>
                      <input
                        value={formData?.otherCharges}
                        type="number"
                        className={style.GstInput}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            otherCharges: e?.target?.value,
                            totalBill: (
                              Number(formData?.baseAmount) + Number(formData?.gst) +
                              Number(e?.target?.value) -
                              (formData?.discount
                                ? Number(formData?.discount)
                                : 0)
                            )?.toFixed(2),
                            amountToBePaid: (
                              Number(formData?.baseAmount) + Number(formData?.gst) -
                              Number(formData?.tds) +
                              Number(e?.target?.value) -
                              (formData?.discount
                                ? Number(formData?.discount)
                                : 0)
                            )?.toFixed(2),
                          })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>

                    <div className={style.col2}>
                      <p className={style.label}>Discount</p>
                      <input
                        type="number"
                        value={formData?.discount}
                        className={style.GstInput}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            discount: e?.target?.value,
                            totalBill: (
                              Number(formData?.baseAmount) + Number(formData?.gst) -
                              Number(e?.target?.value) +
                              (formData?.otherCharges
                                ? Number(formData?.otherCharges)
                                : 0)
                            )?.toFixed(2),
                            amountToBePaid: (
                              Number(formData?.baseAmount) + Number(formData?.gst) -
                              Number(formData?.tds) -
                              Number(e?.target?.value) +
                              (formData?.otherCharges
                                ? Number(formData?.otherCharges)
                                : 0)
                            )?.toFixed(2),
                          })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                  </div>

                  <div className={style.GstList2}>
                    <div className={style.col12}>
                      <p className={style.label}>Amount (Payable)</p>
                      <input
                        value={formData?.amountToBePaid}
                        type="number"
                        className={style.GstInput}
                        readOnly
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>Payment Due On</p>
                      <input
                        value={
                          formData?.dateOfExpense
                            ? moment(formData?.paymentDueOn).format(
                              "DD MMM YYYY"
                            )
                            : ""
                        }
                        className={style.GstInput}
                        readOnly
                      />
                    </div>
                    <div className={style.payment_mode_col}>
                      <p className={style.label}>Payment Mode<span style={{ color: "red" }}>*</span></p>
                      <Select
                        styles={InvoiceStylesVendorName}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            paymentModeId: e?.value,
                            paymentMode: e?.label,
                          })
                        }
                        options={paymentModeOption}
                        value={paymentModeOption?.find((e) => {
                          return e?.label === formData?.paymentMode;
                        })}
                      />
                      <p
                        className={
                          validate && !formData?.paymentMode
                            ? style.errorText2
                            : style.errorTextHidden2
                        }
                      >
                        Please Select Payment Mode
                      </p>
                    </div>

                    <div className={style.payment_note_col}>
                      <p className={style.label}>Payment Note</p>
                      <input
                        value={formData?.paymentNote}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            paymentNote: e?.target?.value,
                          })
                        }
                        className={style.GstInput}
                      />
                    </div>
                  </div>

                  <div className={style.GstList3}></div>
                </>
              )}
            </InvoiceContainer>
          )}

          {/* vendorType GST Applicable*/}
          {venderType?.type === "Vendor" && (
            <InvoiceContainer>
              <div className={style.Container}>
                <div className={style.col1}>
                  <div className={style.heading}>GST Applicable</div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="Yes1"
                      name="radio-group"
                      checked={formData?.gstApplicable === true ? true : false}
                      onChange={() => {
                        const amountToBePaid =
                          Number(formData?.baseAmountVendor) + Number(formData?.gst) -
                          Number(formData?.tds) +
                          (formData?.otherCharges
                            ? Number(formData.otherCharges)
                            : 0) -
                          (formData?.discount ? Number(formData.discount) : 0);

                        setFormData({
                          ...formData,
                          gstApplicable: true,
                          amountToBePaid: amountToBePaid?.toFixed(2),
                          payable1: (amountToBePaid - Number(formData?.gst)).toFixed(2),
                          payable2: Number(formData?.gst),
                        });
                      }}
                    />
                    <label for="Yes1">Yes</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="no1"
                      name="radio-group"
                      checked={formData?.gstApplicable === false ? true : false}
                      onChange={() => {
                        const amountToBePaid =
                          Number(formData?.baseAmountVendor) -
                          Number(formData?.tds) +
                          (formData?.otherCharges
                            ? Number(formData.otherCharges)
                            : 0) -
                          (formData?.discount ? Number(formData.discount) : 0);

                        setFormData({
                          ...formData,
                          gstApplicable: false,
                          amountToBePaid: amountToBePaid?.toFixed(2),
                        });
                      }}
                    />
                    <label for="no1">No</label>
                  </div>
                </div>
              </div>
              {formData.gstApplicable === true ? (
                <>
                  <div className={style.GstList}>
                    <div className={style.col17}>
                      <p className={style.label}>GST Percentage</p>
                      <Select
                        styles={InvoiceStylesVendorName}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          let gstNo = GetgstData?.gstNumber;

                          formData.tds = Number(
                            (formData.baseAmountVendor *
                              getTdsDetails?.tdsPercentage) /
                            100
                          )?.toFixed(2);

                          if (!gstNo?.startsWith("09")) {
                            if (e?.label) {
                              formData.igst = Number(
                                (formData?.baseAmountVendor * e?.label) / 100
                              )?.toFixed(2);
                              formData.cgst = 0;
                              formData.sgst = 0;
                              formData.gst = Number(
                                (formData?.baseAmountVendor * e?.label) / 100
                              )?.toFixed(2);
                            }
                          } else {
                            if (e?.label) {
                              formData.igst = 0;
                              formData.cgst = Number(
                                (formData?.baseAmountVendor * (e?.label / 2)) /
                                100
                              )?.toFixed(2);
                              formData.sgst = Number(
                                (formData?.baseAmountVendor * (e?.label / 2)) /
                                100
                              )?.toFixed(2);
                              formData.gst = Number(
                                ((formData?.baseAmountVendor * (e?.label / 2)) /
                                  100) *
                                2
                              )?.toFixed(2);
                            }
                          }
                          const otherCharges = formData?.otherCharges ? Number(formData?.otherCharges) : 0;
                          const discount = formData?.discount ? Number(formData?.discount) : 0;
                          formData.totalBill = (
                            Number(formData?.baseAmountVendor) +
                            Number(formData?.gst) +
                            otherCharges -
                            discount
                          )?.toFixed(2);

                          formData.amountToBePaid = (
                            Number(formData?.totalBill) - Number(formData?.tds)
                          )?.toFixed(2);

                          formData.payable1 = (Number(formData?.amountToBePaid) - Number(formData?.gst)).toFixed(2);
                          formData.payable2 = Number(formData?.gst).toFixed(2);
                          // if (formData?.otherCharges) {
                          //   formData.amountToBePaid = Number(
                          //     Number(formData?.amountToBePaid) +
                          //     Number(formData?.otherCharges)
                          //   )?.toFixed(2);
                          // }

                          // if (formData?.discount) {
                          //   formData.amountToBePaid = (
                          //     Number(formData.amountToBePaid) -
                          //     Number(formData?.discount)
                          //   )?.toFixed(2);
                          // }

                          formData.gstPercentage = e?.label;

                          setFormData({
                            ...formData,
                            gstPercentage: e?.label,
                          });
                        }}
                        options={gstPercentageOption}
                        value={gstPercentageOption?.find((e) => {
                          return e?.label === formData?.gstPercentage;
                        })}
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>Base Amount</p>
                      <input
                        value={formData?.baseAmountVendor}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>IGST</p>
                      <input
                        value={formData?.igst}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        onChange={(e) =>
                          setFormData({ ...formData, igst: e?.target?.value })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>CGST</p>
                      <input
                        value={formData?.cgst}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        onChange={(e) =>
                          setFormData({ ...formData, cgst: e?.target?.value })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>

                    <div className={style.col12}>
                      <p className={style.label}>SGST</p>
                      <input
                        value={formData?.sgst}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        onChange={(e) =>
                          setFormData({ ...formData, sgst: e?.target?.value })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                  </div>
                  <div className={style.GstList2}>
                    <div className={style.col12}>
                      <p className={style.label}>GST(TOTAL)</p>
                      <input
                        value={formData?.gst}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>

                    <div className={style.col12}>
                      <p className={style.label}>Amount (Billed)</p>
                      <input
                        value={formData?.totalBill}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            totalBill: e?.target?.value,
                          })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>TDS</p>
                      <input
                        value={formData?.tds}
                        type="number"
                        className={style.GstInput}
                        onChange={(e) => tdsCalculateFun(e.target.value)}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>

                    <div className={style.col12}>
                      <p className={style.label}>Other Charges5</p>
                      <input
                        value={formData?.otherCharges}
                        type="number"
                        className={style.GstInput}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            otherCharges: e?.target?.value,
                            totalBill: (
                              Number(formData?.baseAmountVendor) + Number(formData?.gst) +
                              Number(e?.target?.value) -
                              (formData?.discount
                                ? Number(formData?.discount)
                                : 0)
                            )?.toFixed(2),
                            amountToBePaid: (
                              Number(formData?.baseAmountVendor) + Number(formData?.gst) -
                              Number(formData?.tds) +
                              Number(e?.target?.value) -
                              (formData?.discount
                                ? Number(formData?.discount)
                                : 0)
                            )?.toFixed(2),
                            payable1: (
                              Number(formData?.baseAmountVendor) -
                              Number(formData?.tds) +
                              Number(e?.target?.value) -
                              (formData?.discount
                                ? Number(formData?.discount)
                                : 0)
                            )?.toFixed(2),
                            payable2: Number(formData?.gst)?.toFixed(2),
                          })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col17}>
                      <p className={style.label}>Discount</p>
                      <input
                        type="number"
                        value={formData?.discount}
                        className={style.GstInput}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            discount: e?.target?.value,
                            totalBill: (
                              Number(formData?.baseAmountVendor) + Number(formData?.gst) -
                              Number(e?.target?.value) +
                              (formData?.otherCharges
                                ? Number(formData?.otherCharges)
                                : 0)
                            )?.toFixed(2),
                            amountToBePaid: (
                              Number(formData?.baseAmountVendor) + Number(formData?.gst) -
                              Number(formData?.tds) -
                              Number(e?.target?.value) +
                              (formData?.otherCharges
                                ? Number(formData?.otherCharges)
                                : 0)
                            )?.toFixed(2),
                            payable1: (
                              Number(formData?.baseAmountVendor) -
                              Number(formData?.tds) -
                              Number(e?.target?.value) +
                              (formData?.otherCharges
                                ? Number(formData?.otherCharges)
                                : 0)
                            )?.toFixed(2),
                            payable2: Number(formData?.gst).toFixed(2),
                          })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                  </div>

                  <div className={style.GstList4}>
                    <div className={style.col12}>
                      <p className={style.label}>Amount (Payable)</p>
                      <input
                        value={formData?.amountToBePaid}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>Payable 1</p>
                      <input
                        value={formData?.payable1}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>Payable 2</p>
                      <input
                        value={formData?.payable2}
                        type="number"
                        className={style.GstInput}
                        readOnly
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>

                    <div className={style.col12}>
                      <p className={style.label}>Payment Due On</p>
                      <input
                        type="text"
                        value={
                          formData?.dateOfExpense
                            ? moment(formData?.paymentDueOn).format(
                              "DD MMM YYYY"
                            )
                            : ""
                        }
                        className={style.GstInput}
                        readOnly
                      />
                    </div>

                    <div className={style.payment_mode_col}>
                      <p className={style.label}>Payment Mode<span style={{ color: "red" }}>*</span></p>
                      <Select
                        styles={InvoiceStylesVendorName}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            paymentModeId: e?.value,
                            paymentMode: e?.label,
                          })
                        }
                        options={paymentModeOption}
                        value={paymentModeOption?.find((e) => {
                          return e?.label === formData?.paymentMode;
                        })}
                      />
                      <p
                        className={
                          validate && !formData?.paymentMode
                            ? style.errorText2
                            : style.errorTextHidden2
                        }
                      >
                        Please Select Payment Mode
                      </p>
                    </div>
                  </div>
                  <div className={style.GstList3}>
                    <div className={style.payment_note_col2}>
                      <p className={style.label}>Payment Note</p>
                      <input
                        value={formData?.paymentNote}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            paymentNote: e?.target?.value,
                          })
                        }
                        className={style.GstInput}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={style.GstList2}>
                    <div className={style.col12}>
                      <p className={style.label}>Base Amount</p>
                      <input
                        value={formData?.baseAmountVendor}
                        type="number"
                        className={style.GstInput}
                        readOnly
                      />
                    </div>
                    <div className={style.col12}>
                      <p className={style.label}>TDS</p>
                      <input
                        value={formData?.tds}
                        type="number"
                        className={style.GstInput}
                        // readOnly
                        onChange={(e) => tdsCalculateFun(e.target.value)}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>

                    <div className={style.col12}>
                      <p className={style.label}>Other Charges6</p>
                      <input
                        value={formData?.otherCharges}
                        type="number"
                        className={style.GstInput}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            otherCharges: e?.target?.value,
                            totalBill: (
                              Number(formData?.baseAmountVendor) + Number(formData?.gst) +
                              Number(e?.target?.value) -
                              (formData?.discount
                                ? Number(formData?.discount)
                                : 0)
                            )?.toFixed(2),
                            amountToBePaid: (
                              Number(formData?.baseAmountVendor) + Number(formData?.gst) -
                              Number(formData?.tds) +
                              Number(e?.target?.value) -
                              (formData?.discount
                                ? Number(formData?.discount)
                                : 0)
                            )?.toFixed(2),
                          })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>

                    <div className={style.col2}>
                      <p className={style.label}>Discount</p>
                      <input
                        type="number"
                        value={formData?.discount}
                        className={style.GstInput}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            discount: e?.target?.value,
                            totalBill: (
                              Number(formData?.baseAmountVendor) + Number(formData?.gst) -
                              Number(e?.target?.value) +
                              (formData?.otherCharges
                                ? Number(formData?.otherCharges)
                                : 0)
                            )?.toFixed(2),
                            amountToBePaid: (
                              Number(formData?.baseAmountVendor) + Number(formData?.gst) -
                              Number(formData?.tds) -
                              Number(e?.target?.value) +
                              (formData?.otherCharges
                                ? Number(formData?.otherCharges)
                                : 0)
                            )?.toFixed(2),
                          })
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                    </div>
                  </div>
                  <div className={style.GstList3}>
                    <div className={style.col12}>
                      <p className={style.label}>Amount (Payable)</p>
                      <input
                        value={formData?.amountToBePaid}
                        type="number"
                        className={style.GstInput}
                        readOnly
                      />
                    </div>

                    <div className={style.col2}>
                      <p className={style.label}>Payment Due On</p>
                      <input
                        value={
                          formData?.paymentDueOn
                            ? moment(formData?.paymentDueOn).format(
                              "DD MMM YYYY"
                            )
                            : ""
                        }
                        className={style.GstInput}
                        readOnly
                      />
                    </div>
                    <div className={style.payment_mode_col}>
                      <p className={style.label}>Payment Mode<span style={{ color: "red" }}>*</span></p>
                      <Select
                        styles={InvoiceStylesVendorName}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            paymentModeId: e?.value,
                            paymentMode: e?.label,
                          })
                        }
                        options={paymentModeOption}
                        value={paymentModeOption?.find((e) => {
                          return e?.label === formData?.paymentMode;
                        })}
                      />
                      <p
                        className={
                          validate && !formData?.paymentMode
                            ? style.errorText2
                            : style.errorTextHidden2
                        }
                      >
                        Please Select Payment Mode
                      </p>
                    </div>

                    <div className={style.payment_note_col}>
                      <p className={style.label}>Payment Note</p>
                      <input
                        value={formData?.paymentNote}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            paymentNote: e?.target?.value,
                          })
                        }
                        className={style.GstInput}
                      />
                    </div>
                  </div>
                </>
              )}
            </InvoiceContainer>
          )}
          <InvoiceContainer>
            <div className={style.Container}>
              <div className={style.upload_container}>
                <div className={style.heading}>Upload Files</div>
                <UploadMultiFile
                  handleFileChange={handleFileChange}
                  handleFileDelete={handleFileDelete}
                  documentResponses={formData.documentResponses}
                />
              </div>
            </div>
          </InvoiceContainer>
          <div className={style.lastBtn}>
            <InvoiceButton
              type={"cancel"}
              onClick={() => {
                setNextScreen("expense");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: null,
                  page3: null,
                  page4: null,
                  active: "expense",
                }));
              }}
            >
              Cancel
            </InvoiceButton>

            <InvoiceButton
              onClick={() => {
                submitHandler();
              }}
            >
              {prevData?.page2 === "editExpense" ? "Update" : "Create"}
            </InvoiceButton>
          </div>
        </>
      )}
      <Modal
        show={show}
        centered
        classNames={{
          modal: "customModal",
        }}
      >
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to add Vendor Name ?
        </h6>
        <p className={style.label_item1}>
          Vendor Name <span style={{ color: "red" }}>*</span>
        </p>
        <input
          type="text"
          className={style.smallInput1}
          onChange={(e) => setVendorAdd(e.target.value)}
        />
        <button className={style.buttonAdd1} onClick={handleDelete}>
          Yes
        </button>
        <button className={style.buttonCancle} onClick={handleDeleteCancle}>
          No
        </button>

        <style type="text/css">
          {`
      .modal-content {
        width: 73%;
      }
    `}
        </style>
      </Modal>
      <Modal
        show={show1}
        centered
        classNames={{
          modal: "customModal",
        }}
      >
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to add Category ?
        </h6>
        <p className={style.label_item1}>
          Category <span style={{ color: "red" }}>*</span>
        </p>
        <input
          type="text"
          className={style.smallInput1}
          onChange={(e) => setVendorAdd1(e.target.value)}
        />
        <button className={style.buttonAdd1} onClick={handleCategory}>
          Yes
        </button>
        <button className={style.buttonCancle} onClick={handleCategoryCancle}>
          No
        </button>

        <style type="text/css">
          {`
      .modal-content {
        width: 73%;
      }
    `}
        </style>
      </Modal>
    </>
  );
};

export default CreateExpense;
