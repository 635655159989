import * as Img from "../../../../assets/index";
import { path } from "../../../../routes/PathNames";
export const menuItems = [
    {
      path: path.ASDashboard,
      imgSrc: Img.ap_dashboard,
      imgSrcSelected: Img.ap_dashboard_active,
      heading: "Dashboard",
      validPaths: [
        path.ASDashboard,
        path.cycleEmployeeList,
        path.EmployeeDetails
      ],
    },
    {
      path: path.ASEmployeeList,
      imgSrc: Img.ap_empList,
      imgSrcSelected: Img.ap_empListActive,
      heading: "Employee List",
      validPaths: [
        path.ASEmployeeList,
        path.EmployeeInformation
      ],
    },
]