import React from 'react';
import AppraisalSystemSidebar from '../commonComponent/Sidebar/AppraisalSystemSidebar';
import AppraisalCycles from './AppraisalCycles/AppraisalCycles';
import style from "./Dashboard.module.css";
const Dashboard = () => {

    return (
        <div className={style.mainSection}>
            <AppraisalSystemSidebar />
            <div className={style.content}>
            <AppraisalCycles />
            </div>
        </div>
    );
}

export default Dashboard;
