import React from 'react'
import style from "./EmployeeDetails.module.css"
import InvoiceContainer from '../../../Employee_Induction/CommonComponent/InvoiceContainer/InvoiceContainer'
import moment from 'moment'

function EmployeeDetails({emp}) {
  return (
    <div>
    <InvoiceContainer>
        <div className={style.emp_details}>Employee Details </div>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Emp Code</p>
            <p className={style.value}>{emp?.empCode}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Emp Name</p>
            <p className={style.value}>{emp?.empName}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Designation</p>
            <p className={style.value}>{emp?.designation}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Resignation</p>
            <p className={style.value}>
                {/* XX XXX XXXX */}
              {emp?.resignationDate?moment(emp?.resignationDate).format("DD MMM YY"):""}
            </p>
          </div>
          
        </div>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Last Working Day</p>
            <p className={style.value}>
            {emp?.lastWorkingDay?moment(emp?.lastWorkingDay).format("DD MMM YY") : ""}
            </p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>F&F Due Dt.</p>
            <p className={style.value}>
            {emp?.fnfDueDate?moment(emp?.fnfDueDate).format("DD MMM YY") : ""}
            </p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Exit Status</p>
            <p className={style.value}>{emp?.exitStatus}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Delay By</p>
            <p className={style.value}>{emp?.delayBy}</p>
          </div>
        </div>
      </InvoiceContainer>
    </div>
  )
}

export default EmployeeDetails
