import axios from "axios";
import cookie from "react-cookies";

const baseUrl = process.env.REACT_APP_CLIENT_CONTRACT_URL;
const apikey2 = process.env.REACT_APP_EPR_APP_URL;


axios.defaults.headers.common["Authorization"] = cookie.load("token");
axios.defaults.headers.post["Content-Type"] = "application/json";

const transformedData = (data) => {
  const res = data.map((item) => ({
    id: item.id,
    label: item.name,
  }));

  return res;
};

const transformedData1 = (data) => {
  const res = data.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  return res;
};
export const createClientContract = async (data) => {
  const response = await axios.post(`${baseUrl}/create-client-contracts`, data);
  return response.data.responseData;
};

export const getContractTypeList = async () => {
  const response = await axios.get(
    `${baseUrl}/get-contract-type-list
      `
  );
  return transformedData(response.data.responseData);
};

export const getEngagementList = async () => {
  const response = await axios.get(
    `${baseUrl}/get-engagement-list
        `
  );
  return transformedData(response.data.responseData);
};

export const getExecutedByList = async () => {
  const response = await axios.get(
    `${baseUrl}/get-executed-by
        `
  );
  return transformedData(response.data.responseData);
};

export const getContractGroupingList = async () => {
  const response = await axios.get(
    `${baseUrl}/get-contract-grouping
        `
  );
  return transformedData(response.data.responseData);
};

export const getApplicableContractStatusList = async () => {
  const response = await axios.get(
    `${baseUrl}/get-applicable-contract-status-list
        `
  );
  return transformedData(response.data.responseData);
};

export const getBusinessTypeList = async () => {
  const response = await axios.get(
    `${baseUrl}/get-business-type-list
          `
  );
  return transformedData(response.data.responseData);
};

export const getAllClientContractList = async (
  page,
  salesRepresentativeEmpId,
  statusId,
  clientId
) => {
  let apiRes = `${baseUrl}/get-all-client-contract-List?limit=10`;
  if (statusId) {
    apiRes += `&statusId=${statusId}`;
  }
  if (salesRepresentativeEmpId) {
    apiRes += `&salesRepresentativeId=${salesRepresentativeEmpId}`;
  }
  if (page) {
    apiRes += `&page=${page}`;
  }
  if (clientId) {
    apiRes += `&clientId=${clientId}`;
  }

  const response = await axios.get(apiRes);

  return response.data.responseData;
};

export const getClientContractIms = async (
  clientId,
  statusId,
  salesRepresentativeId,
  executedById,
  limit,
  page
) => {
  let apiRes = `${baseUrl}/get-Client-contract-Ims-list?`;
  if (clientId) {
    apiRes += `&clientId=${clientId}`;
  }
  if (statusId) {
    apiRes += `&statusId=${statusId}`;
  }
  if (salesRepresentativeId) {
    apiRes += `&salesRepresentativeId=${salesRepresentativeId}`;
  }
  if (executedById) {
    apiRes += `&executedById=${executedById}`;
  }
  if (limit) {
    apiRes += `&limit=${limit}`;
  }
  if (page) {
    apiRes += `&page=${page}`;
  }

  const response = await axios.get(apiRes);

  return response.data.responseData;
};

export const getDownloadClientContractList = async () => {
  let apiRes = `${baseUrl}/get-contracts-download`;
  const response = await axios.get(apiRes);
  return response.data.responseData;
};
export const getClientContractMissingInprogress = async (
  clientId,
  statusId,
  salesRepresentativeId,
  executedById,
  limit,
  page
) => {
  let apiRes = `${baseUrl}/get-contract-Missing-inprogress-list?limit=10&statusId=4&statusId=3`;
  if (clientId) {
    apiRes += `&clientId=${clientId}`;
  }
  if (statusId) {
    apiRes += `&statusId=${statusId}`;
  }
  if (salesRepresentativeId) {
    apiRes += `&salesRepresentativeId=${salesRepresentativeId}`;
  }
  if (executedById) {
    apiRes += `&executedById=${executedById}`;
  }
  if (limit) {
    apiRes += `&limit=${limit}`;
  }
  if (page) {
    apiRes += `&page=${page}`;
  }

  const response = await axios.get(apiRes);

  return response.data.responseData;
};


export const getAllUpcomingEndingContract = async (
  clientId,
  statusId,
  limit,
  page,
  salesRepresentativeId,
  reverse
) => {
  let apiRes = `${baseUrl}/get-all-upcomming-ending-contract?`;
  if (clientId) {
    apiRes += `&clientId=${clientId}`;
  }
  if (statusId) {
    apiRes += `&statusId=${statusId}`;
  }
  if (limit) {
    apiRes += `&limit=${limit}`;
  }
  if (page) {
    apiRes += `&page=${page}`;
  }
  if (reverse) {
    apiRes += `&reverse=${reverse}`;
  }
  if (salesRepresentativeId) {
    apiRes += `&salesRepresentativeId=${salesRepresentativeId}`;
  }
  const response = await axios.get(apiRes);

  return response.data.responseData;
};

export const getAllClientContractById = async (clientContractId) => {
  const response = await axios.get(
    `${baseUrl}/get-client-contract-ById?clientContrantId=${clientContractId}
          `
  );

  return response.data.responseData;
};

export const getClientList = async () => {
  const response = await axios.get(`${baseUrl}/get-client-list`);

  const td = transformedData(response.data.responseData);
  return td.sort((a, b) => a.label.localeCompare(b.label));
};

export const getClientListFilter = async () => {
  const response = await axios.get(`${baseUrl}/get-client-name-for-filter`);

  const td = transformedData(response.data.responseData);
  return td.sort((a, b) => a.label.localeCompare(b.label));
};

export const getEmployeeList = async () => {
  const response = await axios.get(`${baseUrl}/get-sales-reprsentative-list`, {
    headers: {
      Authorization: null,
    },
  });

  return transformedData(response.data.responseData);
};

export const getEmployeeList1 = async () => {
  const response = await axios.get(`${baseUrl}/get-sales-reprsentative-list`, {
    headers: {
      Authorization: null,
    },
  });

  return transformedData1(response.data.responseData);
};
// queries

export const getAllEmployeeList = async () => {
  const response = await axios.get(`${baseUrl}/get-resource-emp-list`);
  return response.data.responseData;
};

export const getCoseContractList = async () => {
  const response = await axios.get(`${baseUrl}/get-close-contract-list`);
  return response.data.responseData;
};

export const getCommetsList = async (contractId) => {
  const response = await axios.get(`${baseUrl}/get-commets-list?contractId=${contractId}`);
  return response.data.responseData;
}


export const addCommets = async (data) => {
  const response = await axios.post(`${baseUrl}/add-commets`,data);
  return response.data;
};