export const dropdownStyle = (color) => ({
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: "#f5f6fa",
    backgroundColor: "#f5f6fa",
    opacity: "1",
    border: "none",
    minHeight: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),

  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    zIndex: "99999",
    "&:hover": {
      backgroundColor: color,
      color: "white",
    },
    backgroundColor: "white",
    color: "black",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999999",
  }),
});
