import React, { useState, useEffect } from "react";
import Style from "./ApplicationInformation.module.css";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { Row } from "react-bootstrap";
import { api } from "../../../CommonApi/api";
import { get, post, put } from "../../../CommonApi/axiosCall";
import { Toastify } from "../../../../../App";

export default function ApplicationInformation(props) {
  const [skillList, setSkillList] = useState([]);
  const [technologyListEnum, setTechnologyListEnum] = useState("");
  const [application, setApplication] = useState("");
  const [typeStatus, setTypeStatus] = useState("");
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setTechnologyListEnum(props?.applicationDetail?.skillId);
    setApplication(props?.applicationDetail?.application);
    setTypeStatus(props?.applicationDetail?.typeStatus);
  }, [props?.applicationDetail]);

  const history = useHistory();

  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/ProjectDetails");
    }, 2000);
  };

  const getSkills = () => {
    let baseURL = api.getAllSkills;

    get(baseURL)
      .then((res) => {
        setSkillList(res.data.responseData);
      })
      .catch((err) => {});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!technologyListEnum || !application?.trim().length || !typeStatus) {
      setError(true);
      return false;
    }
    if (props.applicationDetail?.typeStatus) {
      const data = {
        skillId: technologyListEnum,
        application: application.trim(),
        typeStatus: typeStatus,
        projectId: props.id,
        applicationid: props.applicationDetail.applicationId,
      };

      let baseURL = api.editAllApplication;

      put(baseURL, data)
        .then((res) => {
          Toastify("Data Submitted Successfully");
          handleRedirect();
          setDisable(true);
        })
        .catch((err) => {});
    } else {
      const data = {
        skillId: technologyListEnum,
        application: application.trim(),
        typeStatus: typeStatus,
        projectId: props.id,
      };

      let baseURL = api.addApplication;

      post(baseURL, data)
        .then((res) => {
          Toastify("Data Submitted Successfully");
          handleRedirect();
          setDisable(true);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    getSkills();
  }, []);

  const type = [
    { name: "Web", id: "Web" },
    { name: "Mobile", id: "Mobile" },
    { name: "Frontend", id: "Frontend" },
    { name: "Backend", id: "Backend" },
    { name: "CMS", id: "CMS" },
  ];

  return (
    <>
      <div className={Style.projectresource_mainsection}>
        <div className={Style.maindiv}>
          <p className={Style.span2}>Application Information</p>
          <div className={Style.maindivContent}></div>
          <form>
            <Row>
              <div
                className={Style.proSecton}
                style={{ padding: "6px", width: "33%", maxWidth: "100%" }}
              >
                <label>
                  Application<span style={{ color: "red" }}>*</span>{" "}
                </label>
                <br />
                <input
                  autoComplete="off"
                  className={Style.input}
                  type="text"
                  id="fname"
                  name="application"
                  defaultValue={props.applicationDetail.application}
                  onChange={(e) => setApplication(e.target.value)}
                  style={{
                    padding: "10px",
                    width: "90%",
                    height: "35px",
                    marginTop: "10px",
                    border: "none",
                    outline: "none",
                    backgroundColor: "#F5F6FA",
                  }}
                />
                {error && !application?.trim().length ? (
                  <p className={Style.error}>Please Enter Application</p>
                ) : null}
              </div>
              <div
                className={Style.proSecton}
                style={{ padding: "6px", width: "33%", maxWidth: "100%" }}
              >
                <label>
                  Technology <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                {/* setApplication */}
                <select
                  value={technologyListEnum}
                  onChange={(e) => setTechnologyListEnum(e.target.value)}
                  name="technology"
                  className={Style.input}
                >
                  <option hidden>Select Technology</option>
                  {skillList.map((val) => {
                    return <option value={val.id}>{val?.name}</option>;
                  })}
                </select>
                {error && !technologyListEnum ? (
                  <p className={Style.error}>Please Select Technology</p>
                ) : null}
              </div>
              <div
                className={Style.proSecton}
                style={{ padding: "6px", width: "33%", maxWidth: "100%" }}
              >
                <label>
                  Type <span style={{ color: "red" }}>*</span>{" "}
                </label>
                <br />
                <select
                  value={typeStatus}
                  onChange={(e) => setTypeStatus(e.target.value)}
                  name="type"
                  className={Style.input}
                >
                  <option hidden>Select Type</option>
                  {type.map((val) => {
                    return <option value={val.id}>{val?.name}</option>;
                  })}
                </select>
                {error && !typeStatus ? (
                  <p className={Style.error}>Please Select Type</p>
                ) : null}
              </div>
            </Row>
          </form>
          <br />
        </div>
      </div>
      <div className={Style.inputbutton}>
        <button
          type="submit"
          onClick={onSubmit}
          style={{
            marginTop: "-8px",
            height: "40px",
            width: "100px",
            borderRadius: "10px",
          }}
          disabled={disable}
        >
          Submit
        </button>
      </div>
    </>
  );
}
