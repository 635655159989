import React, { useEffect, useState } from "react";
import style from "./SideBar.module.css";
import performancereview from "../../../assets/performancereview.png";
import performancereview1 from "../../../assets/performancereview1.png"
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ats from "./../../../assets/ats.png";
import { employeeId } from "../../../Utils/constants";
import axios from "axios";

const url = process.env.REACT_APP_PARTNER_MANAGEMENT;

const SideBar = ({
  sidebarHandler,
  nextScreen,
  setNextScreen,
  setPrevData,
  prevData,
  setCurrentPage,
  roles,
}) => {
  const [isValid, setIsValid] = useState(false)

  useEffect(()=>{
    axios
      .get(url + `/validate-employee-access?empId=${employeeId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          // Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        let accessArray = res?.data?.responseData;
        if(accessArray.find(obj=>obj.elementCode==="235I4301W78727EB")) setIsValid(true);
        else {setPrevData((prev)=>({
          ...prev,
          page: "EmployeePerformanceReview",
          sidebar: "EmployeePerformanceReview",
          page1: "EmployeePerformanceReview",
          active: "EmployeePerformanceReview",
        }))
        setNextScreen("EmployeePerformanceReview")
      }
      })
      .catch(() => {});
  },[])

    

  return (
    <div className={style.leftSection}>
      {/* 0 */}
      <button className={style.performanceReview}>
        <img
          src={performancereview}
          style={{ backgroundColor: "white", borderRadius: "20px" }}
          alt="img"
          height="40px"
        />
        <span>PMS</span>
      </button>
      {/* 1 */}

      {isValid && <button
        onClick={() => {
          sidebarHandler("Dashboard");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "Dashboard",
            page: "Dashboard",
            page1: "Dashboard",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "Dashboard",
          });
        }}
        className={
          prevData?.sidebar === "Dashboard"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          <GridViewRoundedIcon />
        </div>
        <div className={style.sb}>
          <span>Dashboard</span>
        </div>
      </button>}

      <button
        onClick={() => {
          sidebarHandler("EmployeePerformanceReview");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "EmployeePerformanceReview",
            page: "EmployeePerformanceReview",
            page1: "EmployeePerformanceReview",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "EmployeePerformanceReview",
          });
        }}
        className={
          prevData?.sidebar === "EmployeePerformanceReview"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "EmployeePerformanceReview" ? (
            <img src={performancereview} alt="img" />
          ) : (
            <img src={performancereview1} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Summary</span>
        </div>
      </button>
      {/* <button
        onClick={() => {
          sidebarHandler("ReviewCases");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "ReviewCases",
            page: "ReviewCases",
            page1: "ReviewCases",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "ReviewCases",
          });
        }}
        className={
          prevData?.sidebar === "ReviewCases"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "ReviewCases" ? (
            <img src={performancereview} alt="img" />
          ) : (
            <img src={performancereview1} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Review Cases</span>
        </div>
      </button> */}

      {/* 11 */}
      {/* <button
        onClick={() => {
          sidebarHandler("candidateAllocation(Client)");
          setPrevData((prevData) => ({
            ...prevData,
            page: "positionALL",
            sidebar: "candidateAllocation(Client)",
            ListOfProfileInClient: "null",
            page1: "candidateAllocation(Client)",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "candidateAllocation(Client)",
          }));
        }}
        className={
          prevData.sidebar === "candidateAllocation(Client)"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData.sidebar === "candidateAllocation(Client)" ? (
            <img src={cag} alt="img" width="30px" />
          ) : (
            <img src={caw} alt="img" width="30px" />
          )}
        </div>
        <div className={style.sb}>
          <span>Candidate Allocation (Client)</span>
        </div>
      </button> */}
    </div>
  );
};

export default SideBar;
