import React from "react";
import { ViewIssueComponent } from "./ViewIssueCom/ViewIssueCom";
import style from "./ViewIssue.module.css"
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
export const ViewIssue =()=>{
    return(<>
        <Header />
       <div className={style.mainSection}>
         <SideBar />
         <ViewIssueComponent />
       </div>
     </>)
 }
