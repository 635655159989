import { FormControlLabel } from "@mui/material";
import React from "react";
import { FormGroup } from "react-bootstrap";
import {DropSwitchPCM} from "../../atsDashboard/utils/style";

const DropSwitchCommPCM = ({ isChecked, handleChange, label }) => {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<DropSwitchPCM checked={isChecked} onChange={handleChange} />}
          label={label}
        />
      </FormGroup>
    </>
  );
};

export default DropSwitchCommPCM;
