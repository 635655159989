import React, { useState, useEffect, useRef } from "react";
import style from "./TaskAssignedByMeDetails.module.css";
import SideBar from "../../Clients/component/SideBar";
import Header from "../../../taskTracker/commonComponent/Header";
import pencilEditButtonImg from "../../../../assets/editIcon.png";
import rubbishBinImg from "../../../../assets/deleteIcon.png";
import { useHistory, useLocation } from "react-router-dom";
import rubbishBinImg2 from "../../../../assets/rubbish-bin (2).png";
import pencil from "../../../../assets/pencil-edit-button (2).png";
import Moment from "moment";
import axios from "axios";
import cookie from "react-cookies";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import constants, { employeeId } from "../../../../Utils/constants";
import ClosedTaskDetailsById from "../component/ClosedTask/ClosedTaskDetails";
import ConfirmPopup from "../../../taskTracker/taskAssignedByMe/component/ConfirmPopup";

const arr = [
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit amet, consectetur adipisci",
  },
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit amet, co consectetur adipisci",
  },
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit ametamet, consectetur adipisci",
  },
];

const RmsTaskDetalis = (props) => {
  const commentText = useRef(null);
  const [BackId] = useState(props.history?.location?.state?.id1);
  const [data, setData] = useState("");
  const [commentHistory, setcommentHistory] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [newData, setnewData] = useState();
  const [isdeleted, setIsdeleted] = useState(false);
  const [commentToEdit, setcommentToEdit] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmCommentModal, setConfirmCommentModal] = useState(false);
  const [taskCommentId, setTaskCommentId] = useState();
  const [myTaskDetail, setMyTaskDetailByID] = useState();
  const [commentValidation, setCommentValidation] = useState(false);
  const [returnPage, setreturnPage] = useState(
    props?.history.location?.state?.activePage
  );
  const profile = cookie.load("profile");
  const limit = 3;

  const handleCommentEdit1 = (comment) => {
    setcommentToEdit(comment);
  };

  const EditComment = () => {
    history.push("./taskTracker/Models/EditComment");
  };

  const url2 = constants.apiBaseUrl2;

  const deleteTaskComment = () => {
    axios
      .delete(url2 + `/deleteTaskComment?taskCommentId=${taskCommentId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setTaskCommentId(undefined);
        handleConfirmCommentPopupClose();
        pageCount > 0 && currentPage > 1 && commentHistory.length === 1
          ? setCurrentPage(currentPage - 1)
          : setCurrentPage(currentPage);
        handleapidata(newData?.taskId);
      })
      .catch((err) => {
        return err;
      });
  };

  const getSingleDetailsById = (id) => {
    axios
      .get(url2 + `/getTaskDetailsByTaskId?empId=${employeeId}&taskId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        handleapidata(res.data.responseData.taskId);
        setMyTaskDetailByID(res.data.responseData);
        setnewData(res.data.responseData);
      })
      .catch((err) => {});
  };

  const handleReDirect = () => {
    props?.history?.location?.pathname === "/OffShore/TaskDetails"
      ? history.push(`/resourceManagement?id=${BackId}`)
      : props?.history?.location?.pathname === "/OnSite/TaskDetails"
      ? history.push(`/ResourcesAllocationOnsite?id=${BackId}`)
      : history.push(`/PoolResources?id=${BackId}`);
  };
  const handleTaskEdit = (comment) => {
    history.push({
      pathname: "/EditRMSTask",
      state: {
        comment: comment,
        newData: newData,
        activePage: 2,
      },
    });
  };

  const directEdit2 = (data2, id) => {
    history.push({
      pathname:
        props.history.location?.pathname === "/OffShore/TaskDetails"
          ? "/Offshore/TaskEdit"
          : props?.history?.location?.pathname === "/OnSite/TaskDetails"
          ? "/Onsite/TaskEdit"
          : "/Pool/TaskEdit",
      state: {
        task: id,
        activePage: 3,
      },
    });
  };

  const DeletePopUp = () => {
    setConfirmCommentModal(true);
  };

  const postData = (e) => {
    setData(e.target.value);
  };

  var dateTime1 = Moment(Moment().toDate()).format("YYYY-MM-DD hh:mm");

  const Data = {
    localDateTime: dateTime1,
    taskId: newData?.taskId,
    comment: data.trim(),
  };

  const AddComment = () => {
    if (!Data.comment) {
      setCommentValidation(true);
      return;
    }
    axios
      .post(url2 + "/addTaskComment", Data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
        } else {
          commentText.current.value = "";
          setData("");
        }
        handleapidata(newData?.taskId);
      })
      .catch((err) => {
        return err;
      });
  };

  const redirectHistory = () => {
    history.push({
      pathname: "/RMSTaskHistory",
      state: {
        task: newData,
        activePage: returnPage,
      },
    });
  };

  const GoBack = () => {
    history.goBack(-1);
  };
  const ClosedTask = (closed) => {
    const data = {
      taskId: newData.taskId,
      title: newData.title,
      description: newData.description,
      planedEndDate: Moment(newData.planedEndDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: newData.assignedTo,
      priority: newData.priority,
      status: closed,
      employeeRelatedToTask: myTaskDetail?.taskRelatedToEmployeeId,
      functionalFieldId: myTaskDetail?.functionalFieldAreaId,
      projectName: myTaskDetail?.projectName,
      localDateTime: Moment(newData.planedEndDate).format("YYYY-MM-DD hh:mm"),
    };
    axios
      .post(url2 + "/editTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          handleReDirect();
        } else {
          handleReDirect();
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const MarkDone = (done) => {
    const data = {
      taskId: newData.taskId,
      title: newData.title,
      description: newData.description,
      planedEndDate: Moment(newData.planedEndDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: newData.assignedTo,
      priority: newData.priority,
      status: "Done",
      employeeRelatedToTask: myTaskDetail?.taskRelatedToEmployeeId,
      functionalFieldId: myTaskDetail?.functionalFieldAreaId,
      projectName: myTaskDetail?.projectName,
      localDateTime: Moment(newData.planedEndDate).format("YYYY-MM-DD hh:mm"),
    };
    axios
      .post(url2 + "/editTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          handleReDirect();
        } else {
          handleReDirect();
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const handleapidata = (TaskId) => {
    const commentList = axios
      .get(
        url2 +
          "/getTaskComment?limit=" +
          limit +
          "&page=" +
          currentPage +
          "&taskId=" +
          TaskId,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        }
      )
      .then((res) => {
        const responseData = res.data.responseData;
        setcommentHistory(responseData.comments);
        setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
      })
      .catch((err) => {
        return err;
      });
  };

  const history = useHistory();
  useEffect(() => {
    // getSingleDetailsById(props?.history?.location?.state?.task?.taskId)
    getSingleDetailsById(props.history?.location?.state?.id);
    // handleapidata(newData?.taskId)
  }, []);

  function handleConfirmCommentPopupClose() {
    setConfirmCommentModal(false);
  }
  function handleConfirmCommentPopupOpen(commentId) {
    setTaskCommentId(commentId);
    setConfirmCommentModal(true);
  }
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <div className={style.rightSection}>
          <div className={style.taskAssigned}>
            <div style={{ padding: "16px" }}>
              <div className={style.container}>
                <div className={style.subcontainer}>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Task Title</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.title}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Creation Date</h5>
                    <p>{isdeleted ? "" : myTaskDetail?.creationDate}</p>
                    {/* <p>{Moment(newData.planedEndDate).format("DD MMM (ddd)")}</p> */}
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "50%" }}
                  >
                    <h5>Task Description</h5>
                    <p>{isdeleted ? "" : myTaskDetail?.description}</p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Planned End Date</h5>
                    <p>
                      {isdeleted
                        ? ""
                        : Moment(myTaskDetail?.plannedEndDate).format(
                            "DD MMM (ddd)"
                          )}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>{returnPage == 1 ? "Assigned By" : "Assigned To"}</h5>
                    <p>
                      {isdeleted
                        ? ""
                        : myTaskDetail?.name
                        ? myTaskDetail?.name
                        : myTaskDetail?.assignedToName}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Priority</h5>
                    <p>{isdeleted ? "" : myTaskDetail?.priority}</p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Status</h5>
                    <p
                      className={
                        myTaskDetail?.status == "Assigned"
                          ? style.asigned_color
                          : myTaskDetail?.status == "Done"
                          ? style.done_color
                          : myTaskDetail?.status == "Onhold"
                          ? style.onhold_color
                          : style.Closed_color
                      }
                    >
                      {myTaskDetail?.status === "Closed"
                        ? "Closed"
                        : myTaskDetail?.status === "Onhold"
                        ? "On Hold"
                        : myTaskDetail?.status}
                      {/* {newData.status==="Inprogress"?"In Progress":newData.status} */}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Functional Area</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.functionalFieldArea}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Project</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.projectName}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Task Related To</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.taskRelatedToEmployee}
                    </p>
                  </div>
                </div>

                <div className={style.rr} style={{ textAlign: "right" }}>
                  <button className={style.GoBack} onClick={() => GoBack()}>
                    <ArrowBackIcon />
                    Back
                  </button>
                  {/* <span className={style.back_btn} style={{ cursor: "pointer" }} onClick={() => GoBack()}>
            <ArrowBackIcon />Back</span> */}
                  {/* {employeeId == newData?.assignedBy || newData?.status=="Done"?null: */}
                  {newData?.status == "Done" ? null : (
                    <button
                      onClick={() => MarkDone("Done")}
                      className={style.TaskDone}
                    >
                      Done
                    </button>
                  )}
                  {/*                   
                    {employeeId == newData.assignedBy ? */}
                  <button
                    onClick={() => ClosedTask("Closed")}
                    className={style.closed}
                  >
                    Close Task
                  </button>

                  <button
                    onClick={() => redirectHistory()}
                    className={style.taskHistory}
                  >
                    View Task History
                  </button>

                  <button onClick={DeletePopUp} className={style.delete}>
                    Delete
                  </button>

                  {isdeleted && (
                    <button
                      className={style.edit}
                      onClick={() => {
                        setIsdeleted(false);
                        setreturnPage(props.history.location.state.activePage);
                      }}
                    >
                      Undo
                    </button>
                  )}
                  {props?.history.location.pathname ===
                  "/MyTaskDetailsDetails" ? null : (
                    <button
                      onClick={() =>
                        directEdit2(newData, props.history?.location?.state?.id)
                      }
                      className={style.edit}
                    >
                      Edit
                    </button>
                  )}
                </div>

                <br />
              </div>
              <div
                style={{ marginTop: "10px", marginBottom: "10px" }}
                id="add_commentid"
              >
                <h5 className={style.comment}>Add Comment</h5> <br />
                <div className={style["text-area-container"]}>
                  <textarea
                    onChange={postData}
                    name="textArea"
                    rows={3}
                    ref={commentText}
                    // className={style.scrollAdd}
                  />
                </div>
                {/* {commentValidation && !data(
                  <div className={style.req_msg}>
                    <p>* Task comment can not be empty.</p>
                  </div>
                )} */}
                <p
                  className={
                    commentValidation && !data.trim()
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Comment
                </p>
                <button onClick={AddComment} className={style.addComment}>
                  Add Comment
                </button>
              </div>

              <div className={style.tabl}>
                <span
                  style={{ fontWeight: "bold", fontSize: "18px" }}
                  className="comment2"
                >
                  Comment History
                </span>
                {commentHistory?.length > 0 ? (
                  <div>
                    {/* <TableScrollbar rows={2}> */}
                    <table className={style.table}>
                      <thead>
                        <tr>
                          <th className={style.taskid} style={{ width: "19%" }}>
                            Reported Date
                          </th>
                          <th
                            className={style.taskTitle}
                            style={{ width: "25%" }}
                          >
                            Username
                          </th>
                          <th
                            className={style.taskdesc}
                            style={{ width: "51%" }}
                          >
                            Comment
                          </th>
                          <th
                            className={style.taskAssigne}
                            style={{ width: "10%" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      {/* <form className={style.formtable}> */}
                      <tbody>
                        {commentHistory &&
                          commentHistory.map((comment1, index1) => {
                            return (
                              <tr className={style.tabledata} key={index1}>
                                {false && (
                                  <EditComment
                                    taskComment={commentToEdit}
                                    newData={newData}
                                  />
                                )}
                                <td className={style.tabletaskId}>
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      width: "15%",
                                      padding: "10px 10px 10px 8px",
                                    }}
                                  >
                                    {Moment(comment1.taskModifiedDate).format(
                                      "DD MMM (ddd)"
                                    )}
                                  </span>
                                </td>
                                <td
                                  className={style.tabletaskTitle}
                                  style={{ width: "25%" }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(252, 187, 8)",
                                      fontStyle: "italic",
                                      textDecorationLine: "underline",
                                      fontSize: "16px",
                                      width: "25%",
                                      padding: "10px 10px 10px 8px",
                                    }}
                                  >
                                    {comment1.name}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "16px",
                                    whiteSpace: "pre-line",
                                    padding: "10px 10px",
                                  }}
                                >
                                  {comment1.taskComment}
                                </td>
                                {/* <td style={{ }} >

                        <img style={{ cursor: "pointer" }}
                          onClick={() => handleTaskEdit(comment1)}

                          src={pencilEditButtonImg}
                        />
                        <img onClick={ () => deleteTaskComment(comment1.taskCommentId) } style={{ paddingLeft: "10px", cursor:"pointer" }} src={rubbishBinImg}/>
                      </td> */}

                                <td
                                  style={{
                                    width: "10%",
                                    padding: "10px 10px 10px 13px",
                                  }}
                                >
                                  {comment1.name === profile.name ? (
                                    <div>
                                      <img
                                        alt="img"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleTaskEdit(comment1)}
                                        src={pencilEditButtonImg}
                                      />
                                      <img
                                        alt="img"
                                        onClick={() =>
                                          handleConfirmCommentPopupOpen(
                                            comment1.taskCommentId
                                          )
                                        }
                                        style={{
                                          paddingLeft: "11px",
                                          cursor: "pointer",
                                        }}
                                        src={rubbishBinImg}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <img alt="img" src={pencil} />
                                      <img
                                        alt="img"
                                        style={{
                                          paddingLeft: "11px",
                                        }}
                                        src={rubbishBinImg2}
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      {/* </form> */}
                    </table>
                    {/* </TableScrollbar> */}
                  </div>
                ) : (
                  <div className={style.table}>No Data Available</div>
                )}
              </div>
              {myTaskDetail?.taskRelatedToEmployeeId ? (
                <ClosedTaskDetailsById
                  id={myTaskDetail?.taskRelatedToEmployeeId}
                />
              ) : null}

              <div className={style.mobileContainer}>
                {arr.map((ar, indexar) => {
                  return (
                    <div
                      key={indexar}
                      style={{
                        display: "block",
                        position: "relative",
                        borderBottom: "1px solid #707070",
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Reported Date :
                        </span>
                        {ar.a}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Username :
                        </span>
                        {ar.b}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Comment :
                        </span>
                        {ar.c}
                      </p>
                      <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <img
                          alt="img"
                          style={{ cursor: "pointer" }}
                          onClick={handleCommentEdit1}
                          src={pencilEditButtonImg}
                        />
                        <img
                          alt="img"
                          style={{ paddingLeft: "10px" }}
                          src={rubbishBinImg}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmPopup open={confirmCommentModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to delete?
          </h5>
          <div className={style.btnCont}>
            <button className={style.yesBtn} onClick={deleteTaskComment}>
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={handleConfirmCommentPopupClose}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </div>
  );
};

export default RmsTaskDetalis;
