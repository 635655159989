import React from "react";
import style from "./InvoiceButton1.module.css";

const InvoiceButton1 = ({ styles, children, onClick, type }) => {
  switch (type) {
    case "plus":
      return (
        <button style={styles} className={style.createBtn} onClick={onClick}>
          <span> + </span>
          {children}
        </button>
      );
    case "plus1":
      return (
        <button style={styles} className={style.createBtn1} onClick={onClick}>
          {children}
        </button>
      );
    case "cancel":
      return (
        <button style={styles} className={style.cancelBtn} onClick={onClick}>
          {children}
        </button>
      );
    default:
      return (
        <button style={styles} className={style.createBtn} onClick={onClick}>
          {children}
        </button>
      );
  }
};

export default InvoiceButton1;
