import React, { useState } from "react";
import Moment from "moment";
import { Modal } from "react-responsive-modal";
import style from "./OffshoreReport.module.css";
import { useHistory } from "react-router-dom";
import BIcon from "./../../../../assets/BIcon.svg";


const OffshorReportTable = (props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const GoToDetails = () => {
    history.push({
      pathname: "/PartialPoolDescription",
      state: {
        id: props.val,
        currentPage: props?.currentPage,
        allData: props.allData,
        applied: props.applied,
        filterName: props.filterName,
      },
    });
  };

  const bandwidthPercentage =
    {
      Half: "50%",
      ThreeFourth: "75%",
      OneFourth: "25%",
    }[props.val.bandwidth] || "100%";

  return (
    <>
      <tr className={style.tr1}>
        <td>{props?.val?.empCode}</td>
        {props?.val?.isBlocked?<td>
            <img
              src={BIcon}
              style={{ height: "1.5rem", maxWidth: "2rem" }}
              alt="img"
            />
          </td>: (
          <td style={{ padding: "10px" }}></td>
        )}
        <td onClick={GoToDetails} className={style.link}>
          {props.val?.empName}
        </td>
        <td>
          {~~(props.val.experience / 12)} Y - {props.val.experience % 12} M
        </td>
        <td>{props.val.salaryRange}</td>
        <td>{props?.val?.skillName}</td>
        <td style={{ width: "5%" }}>{bandwidthPercentage}</td>
        <td style={{ paddingLeft: "30px !important" }}>
          {props.val.poolStartdate &&
            Moment(props.val.poolStartdate).format("DD MMM YY")}
        </td>
        {props?.val?.projectName.length > 0 && (
          <td style={{ width: "2%" }}>
            <span
              className={style.count1}
              style={{
                fontSize: "11.5px",
                color: "green",
                cursor: "pointer",
                fontWeight: "900",
              }}
              onClick={() => setShow(true)}
            >
              A
            </span>
          </td>
        )}
        <td>{props?.val?.partnerName}</td>
      </tr>
      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "700",
          }}
        >
          Project/Client Name
        </p>
        <div className={style.modal_container}>
          <p>
            {props?.val?.projectName &&
              props.val.projectName.map((val, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <br />}
                  {val}
                </React.Fragment>
              ))}
          </p>

          <div className={style.M_button}>
            <div className={style.button}></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OffshorReportTable;
