import React, { useEffect, useContext, useState } from "react";
import SideBar from "../../Clients/component/SideBar";
import style from "./BlockResourcesList.module.css";
// import TableRow from "../../component/TableRow";
import moment from "moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Table } from "react-bootstrap";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loaderImg from "../../../../assets/loader.gif";
import Moment from "moment";
import Select from "react-select";
import { DataContext } from "../../CommonComponent/useContext/DataContext";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { customSearch } from "../../Clients/component/style";
import Pagination from "../../CommonComponent/Pagination";
import milestone from "../../../../assets/milestone.png";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { api } from "../../CommonApi/api";
import { get, post } from "../../CommonApi/axiosCall";
import { ToastContainer } from "react-toastify";
import { rmsAccessCode } from "../../../../Utils/constants";
import useValidationAccess from "../../../custumHooks/useValidationAccess";

const BlockResourcesList = (props) => {
  const { checkValidateAccess } = useValidationAccess();
  const history = useHistory();
  const [show1, setShow1] = useState(false);
  const [selectedValues, setSelectedValues] = useState([1]);
  const { data, setData } = useContext(DataContext);
  const [partnerName, setParnername] = useState([]);
  const [applied, setApplied] = useState(
    props.history?.location.state?.allData?.applied
      ? props.history.location.state?.allData?.applied
      : false
  );
  const [showFilter, setShowFilter] = useState(false);
  const [blockData, setBlockData] = useState();
  const [count, setCount] = useState(0);
  const [applyStatusFilter, setApplyFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    props.history?.location?.state?.allData?.currentPage
      ? props.history.location?.state?.allData?.currentPage
      : 1
  );
  const [totalResourceOffShore, setTotalResourceOffShore] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [offshoreEmp, setOffshoreEmp] = useState([]);
  const [blockProject, setBlockProject] = useState([]);
  const [blockClientList, setBlockClientList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ExitDate, setExitDate] = useState();
  const [show, setShow] = useState(false);
  const [action, setAction] = useState([]);
  const [bandwidths, setBandwidths] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [filterName, setFilterName] = useState(
    props.history.location.state?.allData?.filterName
      ? props.history.location.state?.allData?.filterName
      : {}
  );
  const [projectId, setProjectId] = useState([]);
  const [skills, setSkills] = useState([]);
  const [allData, setAllData] = useState({
    clientId: 0,
    projectId: 0,
    empId: 0,
    partnerId: [],
  });
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  offshoreEmp?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const handleCheckboxChange = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((v) => v !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code1":
          setAllData({ ...allData, empId: e?.value });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "Code2":
          setAllData({ ...allData, projectId: e?.value });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "Code3":
          setAllData({ ...allData, clientId: e?.value });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "Code4":
          setAllData({ ...allData, partnerId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        default:
      }
    }
  };
  const getOffshorePartner = () => {
    let baseURL = api.getBlockResourcesPartner;
    get(baseURL)
      .then((res) => {
        setParnername(res.data.responseData);
      })
      .catch(() => {});
  };
  const getResourceOffShoreData = (currentPage) => {
    setIsLoaded(true);

    let baseURL = api.getBlockRefestList;
    if (limit) {
      baseURL += `?limit=${limit}`;
    }
    if (currentPage) {
      let statusQueryParam = "&";
      for (let i = 1; i < selectedValues.length; i++) {
        statusQueryParam += `status=${selectedValues[i]}`;
        if (i < selectedValues.length - 1) {
          statusQueryParam += "&"; // Add '&' to separate multiple status values
        }
      }
      baseURL += `&page=${currentPage}&${statusQueryParam}`;
    }
    if (allData?.partnerId[0]) {
      let statusQueryParam1 = "&";
      for (let i = 0; i < allData?.partnerId.length; i++) {
        statusQueryParam1 += `partnerId=${allData?.partnerId[i]}`;
        if (i < allData?.partnerId.length - 1) {
          statusQueryParam1 += ""; // Add '&' to separate multiple status values
        }
      }

      baseURL += `${statusQueryParam1}`;
    }

    if (allData?.empId) {
      baseURL += `&empId=${allData.empId}`;
    }
    if (allData?.clientId) {
      baseURL += `&clientId=${allData.clientId}`;
    }
    if (allData?.projectId) {
      baseURL += `&projectId=${allData.projectId}`;
    }
    // if(allData?.partnerId[0]){
    //   baseURL += `&partnerId=${allData.partnerId[0]}`
    // }
    get(baseURL)
      .then((res) => {
        setIsLoaded(false);
        setBlockData(res.data.responseData);
        setCount(res.data.responseData.blockedResourcesCount);
        setpageCount(res.data.responseData.blockedResourcesCount / 10);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };
  // const goToDetails=()=>{
  //   history.push("/BlockResourcesView")
  // }
  const handleAddResources = () => {
    history.push("/AddBlockResources");
  };
  const goToDetails = (val) => {
    history.push({
      pathname: "/BlockResourcesView",
      state: {
        id: val.id,
        currentPage: currentPage,
        allData: allData,
        filterName: filterName,
        applied: applied,
      },
    });
  };

  useEffect(() => {
    if (data?.resourceManagement?.handleFilter) {
      setApplied(true);
    }
    getOffshoreProject();
    getOffshoreEmp();
    getBlockResourcesClient();
    getOffshorePartner();
  }, []);
  const getPartnerList = partnerName?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  useEffect(() => {
    getResourceOffShoreData(currentPage);
  }, [currentPage, allData, applyStatusFilter, show1]);

  const getOffshoreEmp = () => {
    let baseURL = api.getBlockResourcesEmployee;
    get(baseURL)
      .then((res) => {
        setOffshoreEmp(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getOffshoreProject = () => {
    let baseURL = api.getBlockResourcesProjectList;

    get(baseURL)
      .then((res) => {
        setBlockProject(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getBlockResourcesClient = () => {
    let baseURL = api.getBlockResourcesClientList;
    get(baseURL)
      .then((res) => {
        setBlockClientList(res.data.responseData);
      })
      .catch(() => {});
  };

  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  useEffect(() => {
    setData({ ...data, blockResources: allData });
  }, [allData]);

  const getPositionCodeForFilterOption = offshoreEmp?.map((i) => ({
    value: i.id,
    label: i.name + ` (${i.code})`,
  }));
  const getClientListForFilter = blockClientList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getProjectListForFilter = blockProject?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const value1 = allData?.empId
    ? getPositionCodeForFilterOption?.find((e) => e.value === allData.empId)
    : null;
  const value2 = allData?.projectId
    ? getPositionCodeForFilterOption?.find((e) => e.value === allData.projectId)
    : null;
  const value3 = allData?.clientId
    ? getPositionCodeForFilterOption?.find((e) => e.value === allData.clientId)
    : null;
  const value4 = allData?.partnerId[0]
    ? getPartnerList?.find((e) => e.value === allData.partnerId[0])
    : null;
  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "600", width: "17%" }}
              >
                Resources (Blocked)
              </div>
              <BootstrapTooltip title="Allocate Resource">
                <span
                  className={style.plus}
                  onClick={() => handleAddResources()}
                >
                  <span style={{ color: "rgb(252,187,28)", cursor: "pointer" }}>
                    <ControlPointIcon
                      style={{ width: "3rem", height: "2rem" }}
                    />
                  </span>
                </span>
              </BootstrapTooltip>
              <div style={{ width: "7%" }}>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {count}
                  </span>
                </p>
              </div>
              {/* <div style={{ width: "27%", paddingLeft: "10%" }}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img
                      className={style.appliedcheck}
                      src={rightTickPRM}
                      alt="img"
                    />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div> */}
              <div
                className={style.s1}
                style={{ width: "26%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.empId == 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        empId: 0,
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Employee Name/Code"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value1}
                  onChange={(e) => {
                    onSearchHandler(e, "Code1");
                    setCurrentPage(1);
                  }}
                  options={getPositionCodeForFilterOption}
                />
              </div>{" "}
              <div
                className={style.s1}
                style={{ width: "17%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.projectId === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        projectId: 0,
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Project"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value2}
                  onChange={(e) => {
                    onSearchHandler(e, "Code2");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getProjectListForFilter}
                />
              </div>
              <div
                className={style.s1}
                style={{ width: "17%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.clientId == 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        clientId: 0,
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Client"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value3}
                  onChange={(e) => {
                    onSearchHandler(e, "Code3");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getClientListForFilter}
                />
              </div>
              <div
                className={style.s1}
                style={{ width: "17%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.partnerId?.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        partnerId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Partner"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value4}
                  onChange={(e) => {
                    onSearchHandler(e, "Code4");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getPartnerList}
                />
              </div>
              {/* {checkValidateAccess(
                rmsAccessCode?.allocate_resource_offshore
              ) && (
                <div className={style.btn_add}>
                  <Link
                    to="/AddBlockResources"
                    style={{ textDecoration: "none" }}
                  >
                    <button className={style.create_btn}>
                      <span className={style.plus}>+</span>Blocked Resources
                    </button>
                  </Link>
                </div>
              )} */}
            </div>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.offshorelist}
            style={{ backgroundColor: "white", minHeight: "28rem" }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : blockData?.blockedResources?.length > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr>
                    <th style={{ padding: "10px" }}>Code</th>
                    <th style={{ padding: "10px" }}>Name</th>
                    <th style={{ padding: "10px" }}>Project Type</th>
                    <th style={{ padding: "10px" }}>Project Name</th>
                    <th style={{ padding: "10px" }}>Client Name</th>
                    <th style={{ padding: "10px" }}>Start Date</th>
                    <th style={{ padding: "10px" }}>End Date</th>
                    <th style={{ padding: "10px" }}>
                      Status{" "}
                      <FilterAltIcon
                        style={{
                          color: "rgb(252,187,8)",
                          cursor: "pointer",
                          marginTop: "px",
                        }}
                        onClick={() => setShow1(true)}
                      />
                    </th>
                    <th style={{ padding: "10px" }}>Partner Name</th>
                  </tr>
                </thead>
                <tbody>
                  {blockData?.blockedResources.map((val) => (
                    <tr
                      style={{
                        borderBottom: "2px solid #F0F0F0",
                        textAlign: "start",
                        fontSize: "13px",
                        position: "relative",
                      }}
                    >
                      <td style={{ padding: "10px" }}>{val.empCode}</td>
                      <td
                        onClick={() => goToDetails(val)}
                        style={{
                          cursor: "pointer",
                          padding: "10px",
                          textTransform: "capitalize",
                          fontWeight: "bolder",

                          textDecorationLine: "underline",
                        }}
                      >
                        {val.empName}
                      </td>
                      <td style={{ padding: "10px" }}>
                        {val.projectType === true
                          ? "OffShore"
                          : val.projectType === false
                          ? "Onsite"
                          : null}
                      </td>
                      <td style={{ padding: "10px" }}>{val.projectName}</td>

                      <td style={{ padding: "10px" }}>{val.clientName}</td>
                      <td style={{ padding: "10px" }}>
                        {val.allocationdate &&
                          moment(val.allocationdate).format("DD MMM YY")}
                      </td>
                      <td style={{ padding: "10px" }}>
                        {val.deallocationdate &&
                          moment(val.deallocationdate).format("DD MMM YY")}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          color:
                            val.status === "Active"
                              ? "green"
                              : val.status === "Not Started"
                              ? "Blue"
                              : val.status === "In-Active"
                              ? "red"
                              : null,
                        }}
                      >
                        {val.status}
                      </td>
                      <td style={{ padding: "10px" }}>{val.partnerName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>
                <table style={{ fontSize: "12px", border: "none" }}>
                  <thead
                    style={{
                      backgroundColor: "#F0F0F0",
                      position: "sticky",
                      top: "0px",
                    }}
                  >
                    <tr>
                      <th style={{ padding: "10px" }}>Employee Code</th>
                      <th style={{ padding: "10px" }}>Employee Name</th>
                      <th style={{ padding: "10px" }}>Project Type</th>
                      <th style={{ padding: "10px" }}>Project Name</th>
                      <th style={{ padding: "10px" }}>Client Name</th>
                      <th style={{ padding: "10px" }}>Start Date</th>
                      <th style={{ padding: "10px" }}>End Date</th>
                      <th style={{ padding: "10px" }}>
                        Status{" "}
                        <FilterAltIcon
                          style={{
                            color: "rgb(252,187,8)",
                            cursor: "pointer",
                            marginTop: "px",
                          }}
                          onClick={() => setShow1(true)}
                        />
                      </th>
                      <th style={{ padding: "10px" }}>Partner Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blockData?.blockedResources.map((val) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #F0F0F0",
                          textAlign: "start",
                          fontSize: "13px",
                          position: "relative",
                        }}
                      >
                        <td style={{ padding: "10px" }}>{val.empCode}</td>
                        <td
                          onClick={() => goToDetails(val)}
                          style={{
                            cursor: "pointer",
                            padding: "10px",
                            textTransform: "capitalize",
                            fontWeight: "bolder",

                            textDecorationLine: "underline",
                          }}
                        >
                          {val.empName}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.projectType === true
                            ? "OffShore"
                            : val.projectType === false
                            ? "Onsite"
                            : null}
                        </td>
                        <td style={{ padding: "10px" }}>{val.projectName}</td>
                        <td style={{ padding: "10px" }}>{val.clientName}</td>
                        <td style={{ padding: "10px" }}>
                          {val.allocationdate &&
                            moment(val.allocationdate).format("DD MMM YY")}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.deallocationdate &&
                            moment(val.deallocationdate).format("DD MMM YY")}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            color:
                              val.status === "Active"
                                ? "green"
                                : val.status === "Not Started"
                                ? "Blue"
                                : val.status === "In-Active"
                                ? "red"
                                : null,
                          }}
                        >
                          {val.status}
                        </td>
                        <td style={{ padding: "10px" }}>{val.partnerName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className={style.noData}>
                  <img src={milestone} alt="img" />
                  <h3 className={style.noData_header}>No data available</h3>
                </div>
              </>
            )}
          </div>
          {count > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>

      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
          {ExitDate
            ? Moment(ExitDate).format("DD MMM YY")
            : "No Date Available"}
        </div>
      </Modal>
      <Modal
        open={show1}
        center
        onClose={() => setShow1(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
          <label style={{ height: "2rem" }}>
            <input
              type="checkbox"
              style={{
                visibility: "visible",
                accentColor: "rgb(252,187,8)",
                outline: "none",
              }}
              value={2}
              checked={selectedValues.includes(2)}
              onChange={() => handleCheckboxChange(2)}
            />
            <span className={style.span1}>In Active</span>
          </label>
          {/* <label style={{ height: "2rem" }}>
            <input
              type="checkbox"
              value={3}
              style={{
                visibility: "visible",
                accentColor: "rgb(252,187,8)",
                outline: "none",
              }}
              checked={selectedValues.includes(3)}
              onChange={() => handleCheckboxChange(3)}
            />
            <span className={style.span1}>Not Started</span>
          </label> */}
          <label style={{ height: "2rem" }}>
            <input
              type="checkbox"
              value={4}
              style={{
                visibility: "visible",
                accentColor: "rgb(252,187,8)",
                outline: "none",
              }}
              checked={selectedValues.includes(4)}
              onChange={() => handleCheckboxChange(4)}
            />
            <span className={style.span1}>All</span>
          </label>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              onClick={() => {
                setSelectedValues([1]);
                setShow1(false);
              }}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </button>

            <button
              onClick={() => {
                setApplyFilter(!applyStatusFilter);
                setShow1(false);
              }}
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default BlockResourcesList;
