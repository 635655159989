import Modal from "react-responsive-modal";
import style from "./Filter.module.css"
import { useState } from "react";
const FilterModalSalary = ({
    setShowFilter,
    showFilter,
    setFilter,
}) => {
    const [selectedData, setSelectedData] = useState([]);
    const variableComponentsList = [
        { value: "EmployerPF", label: "Employer PF",},
        { value: "EmployeePF", label: "Employee PF",},
        { value: "Gratuity", label: "Gratuity",},
        { value: "SA1", label: "SA1",},
        { value: "SA2", label: "SA2",},
        { value: "Incentives", label: "Incentives",},
        { value: "VariableBonus", label: "Variable Bonus",},
        { value: "JoiningBonus", label: "Joining Bonus",},
        { value: "RetentionBonus", label: "Retention Bonus",},
        { value: "Others", label: "Others",}
      ];

  return (
    <Modal
        open={showFilter}
        center
        onClose={() => setShowFilter(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
    <div className={style.filterModal} style={{minWidth: "10rem"}}>
        <div className={style.filterCards}>
            <div className={style.firstCard} style={{height: "300px"}}>
                <div className={style.filte_heading}>Variable Component</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {variableComponentsList?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={item.value}
                          value={item.value}
                          checked={selectedData.includes(item.value)}
                          onChange={(e) => {
                            console.log(e.target.checked)
                            if(e.target.checked){
                                setSelectedData((prev)=>([...prev, item.value]));
                            }
                            else{
                                let temp=[...selectedData];
                                temp=temp.filter(val=>val!=item.value);
                                setSelectedData(temp);
                            }
                          }}
                        />
                        <label
                          htmlFor={item.value}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
            </div>
            
        </div>
        <div className={style.filterBtn}>
                <button
                    className={style.cancelBtn}
                    onClick={()=>{
                        setSelectedData([]);
                        setFilter((prev)=>({...prev, variableComponentFilter: []}));
                        setShowFilter(false)}
                    }
                >Clear</button>
                <button className={style.createBtn} 
                    onClick={() => {
                        setFilter((prev)=>({
                            ...prev,
                            variableComponentFilter: selectedData
                        }));
                        setShowFilter(false)
                    }}
                >
                    Apply
                </button>
            </div>
    </div></Modal>
  )
}

export default FilterModalSalary