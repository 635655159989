import React, { useEffect, useState } from "react";
import style from "./MyWatchList.module.css";
import SideBar from "../Clients/component/SideBar";
import milestone from "../../../assets/milestone.png";
import loaderImg from "../../../assets/loader.gif";
import { api } from "../CommonApi/api";
import Pagination from "../CommonComponent/Pagination";
import { get, del } from "../CommonApi/axiosCall";
import { ToastifyError, Toastify } from "../../../App";
import Tooltip from "@mui/material/Tooltip";
import Moment from "moment";
import { employeeId } from "../../../Utils/constants";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import axios from "axios";

export default function WatchList() {
  const [isLoaded, setIsLoaded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [watchlistCount, setWatchlistCount] = useState(0);
  const [watchlistdata, setWatchListData] = useState([]);
  const [removeSucees, setRemoveSuccess] = useState(false);
  const [pageCount, setpageCount] = useState(0);

  let limit = 10;
  const getProjectData = (currentPage) => {
    setIsLoaded(true);
    let baseURL = api.getWatchListData;
    if (limit) {
      baseURL += `?limit=${limit}`;
    }
    if (currentPage) {
      baseURL += `&page=${currentPage}`;
    }
    if (employeeId) {
      baseURL += `&requesterId=${employeeId}`;
    }

    // axios
    //   .get(baseURL, { headers: { "Content-Type": "application/json" } })
    //   .then((res) => {
    //     setWatchListData(res.data.responseData.watchlist);  
    //     setIsLoaded(false);
    //     setWatchlistCount(res.data.responseData.count);
    //     setpageCount(res.data.responseData.count / 10);
    //   })
    //   .catch((err) => {
    //     setIsLoaded(false);
    //   });
    get(baseURL).then((res) => {
      setWatchListData(res.data.responseData.watchlist);  
      setIsLoaded(false);
      setWatchlistCount(res.data.responseData.count);
      setpageCount(res.data.responseData.count / 10);
    }).catch((err) => {
        setIsLoaded(false);
      });
  };
  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  useEffect(() => {
    getProjectData(currentPage);
  }, [currentPage, removeSucees]);
  const removeWatchListt = (requesterId, resourceId) => {
    let url = api.removeWatchList;
    if (requesterId !== undefined && requesterId !== null) {
      url += `?requesterId=${requesterId}`;
    }
    if (resourceId !== undefined && resourceId !== null) {
      url += `&resourceId=${resourceId}`;
    }
    del(url)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 1) {
          ToastifyError(res.data.responseData.message);
        } else if (res.data.responseStatus.statusCode === 0) {
          Toastify(res.data.responseData);
          setRemoveSuccess(!removeSucees);
        }
      })
      .catch();
  };
  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />
        {/* Table Div Content */}
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "700", width: "10%" }}
              >
                My Watchlist
              </div>
              <div style={{ width: "15%" }}>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {watchlistCount}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            className={style.original_table}
            style={{ backgroundColor: "white", minHeight: "27.5rem" }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : //) : projectData.projects?.length > 0 ? (
            watchlistdata?.length > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "150px" }}>
                    <th style={{ padding: "10px" }}>Code</th>
                    <th style={{ padding: "10px" }}>Name</th>
                    <th style={{ padding: "10px" }}>Technology</th>
                    <th style={{ padding: "10px" }}>Exp</th>
                    <th style={{ padding: "10px" }}>Salary Range</th>
                    <th style={{ padding: "10px" }}>Partner Name</th>
                    <th style={{ padding: "10px" }}>Resigned Date</th>
                    <th style={{ padding: "10px" }}>Exit Date</th>
                    <th style={{ padding: "10px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {watchlistdata.map((val, index) => (
                    <tr
                      key={index}
                      style={{
                        borderBottom: "2px solid #F0F0F0",
                        textAlign: "start",
                        fontSize: "13px",
                      }}
                    >
                      <td style={{ padding: "10px" }}>{val.empCode}</td>
                      <td style={{ padding: "10px" }}>{val.empName}</td>
                      <td style={{ padding: "10px" }}>{val.skillName}</td>
                      <td style={{ padding: "10px" }}>
                        {~~(val.experience / 12)} Y - {val.experience % 12} M
                      </td>
                      <td style={{ padding: "10px" }}>{val.salaryRange}</td>
                      <td style={{ padding: "10px" }}>{val.partnerName}</td>
                      <td style={{ padding: "10px" }}>
                        {val.resignDate &&
                          Moment(val.resignDate).format("DD MMM YY")}
                      </td>
                      <td style={{ padding: "10px" }}>
                        {val.exitDate &&
                          Moment(val.exitDate).format("DD MMM YY")}
                      </td>
                      <td style={{ padding: "10px" }}>
                        <Tooltip title="Remove from Watchlist">
                          <GroupRemoveIcon
                            onClick={() =>
                              removeWatchListt(employeeId, val?.empid)
                            }
                            style={{
                              cursor: "pointer",
                              color: "rgb(252, 187, 8)",
                            }}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} alt="milestone" />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}
          </div>
          {/* <Link to="/clientdescription">Click</Link> */}
          {watchlistCount > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
