import React, { useEffect, useState } from "react";
import style from "./AppraisalPromotionsListComponent.module.css";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import searchIcon from "../../../../../../assets/Icon-search.png";
import deleteIcon from "../../../../assets/delete-icon.png";
import moment from 'moment'
import { customSearchACP } from "./style";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegComments, FaEye } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import { ImLink } from "react-icons/im";
import Modal from "react-responsive-modal";
import AppraisalFilter from "../AppraisalCycleEmployeListFilter/AppraisalFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import { FiDownload } from "react-icons/fi";
import axios from "axios";
import { addCommas } from "../../../../commonComponent/utils/AddCommas";
import {
  deleteAppraisal,
  getCombinationFilterList,
  holdAppraisal,
  getDepartmentList,
  getEmployeeList,
  appraisalSendBack,
  appraisalApproved,
  getEmployeesForPromotion,
  addPromotion,
} from "../../../../../../Utils/axios/AppraisalApi";
import { useMutation, useQuery } from "react-query";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../../../commonComponent/Pagination/Pagination";
import HrHeadCom from "../../../../commonComponent/EmployeeDetails_apprHistory/CurrentProposedHR/HrHeadComments/HrHeadCom";
import {
  notifyError,
  notifySuccess,
} from "../../../../../atsDashboard/utils/notify";
import ProjectComments from "../../../../commonComponent/ProjectComments/ProjectComments";
import AppraisalLoader from "../../../../commonComponent/Loader/AppraisalLoader";
import CustomExcelFileDownload from "../../../../commonComponent/ExcelDownload/CommonExcelDownload";
import { IoChatbubblesSharp } from "react-icons/io5";

const AppraisalCyclePromotionsEmployeeListComponent = () => {
  const url = process.env.REACT_APP_APPRAISAL_SYSTEM_URL;
  // getCombinationFilter
  const { data: combinationFilterList } =
    useQuery(["getCombinationFilterList"], () => getCombinationFilterList());

  const [rewardList, setRewardList] = useState([]);
  const [promotionList, setPromotionList] = useState([]);

  useEffect(() => {
    setRewardList(combinationFilterList?.reward);
    setPromotionList(combinationFilterList?.promotion);
  }, [combinationFilterList]);
  // Filters
  const [filterApplied, setFilterApplied] = useState(false);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    location?.state?.currentPage ? location?.state?.currentPage : 1
  );
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [filterData, setFilterData] = useState(
    location?.state?.filterData
      ? location?.state?.filterData
      : {
        appraisalStatus: [],
        bond: null,
        client: [],
        empType: [],
        location: [],
        // promotion: [],
        reportingManager: [],
        reward: [],
        applicableDate: '',
        technology: [],
      }
  );

  const filterUpdateFuc = () => {
    setCurrentPage(1);
    setFilter((prev) => ({
      ...prev,
      technology: [...(filterData?.technology || [])],
      empType: [...(filterData?.empType || [])],
      reportingManager: [...(filterData?.reportingManager || [])],
      client: [...(filterData?.client || [])],
      reward: [...(filterData?.reward || [])],
      applicableDate: [...(filterData?.applicableDate || "")],
      location: filterData?.location,
      bond: filterData?.bond,
      appraisalStatus: [...(filterData?.appraisalStatus || [])],
    }));
    setShowFilterModal(false);
    setFilterApplied(true);
  };

  const filterClearAllFuc = () => {
    setFilter((prev) => ({
      ...prev,
      appraisalStatus: null,
      bond: null,
      client: null,
      deptId: null,
      empType: null,
      location: null,
      // promotion: null,
      reportingManager: null,
      reward: null,
      applicableDate: '',
      technology: null,
    }));

    setFilterData((prev) => ({
      ...prev,
      appraisalStatus: [],
      bond: null,
      client: [],
      deptId: [],
      empType: [],
      location: [],
      // promotion: [],
      reportingManager: [],
      reward: [],
      applicableDate: '',
      technology: [],
    }));
    setShowFilterModal(false);
    setFilterApplied(false);
  };
  const locationList = [
    {
      value: "Offshore",
      label: "Off-Shore",
    },
    {
      value: "Onsite",
      label: "On-Site",
    },
  ];
  const technologyList = combinationFilterList?.technology?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const empTypeList = combinationFilterList?.empType?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const clientList = combinationFilterList?.client?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const rmList = combinationFilterList?.reportingManager?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const rewardListNew = combinationFilterList?.reward?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const statusListNew = combinationFilterList?.status?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const [pageCount, setPageCount] = useState(1);
  const [render, setRender] = useState(false);
  const [activeDept, setActiveDept] = useState(false);
  const [activeEmp, setActiveEmp] = useState(false);
  const [selectedDept, setSelectedDept] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [promotionError, setPromotionError] = useState(false);
  const [editAward, setEditAward] = useState("");
  const [editPromotion, setEditPromotion] = useState("");
  const [editApplicableDate, setEditApplicableDate] = useState();
  const [applicableDate, setApplicableDate] = useState("");
  const [departmentList, setDepartmentList] = useState(null);
  // Create Appraisal Data
  const [award, setAward] = useState("");
  const [promotion, setPromotion] = useState("");
  // Getting Department List
  const { data: departments } = useQuery(["departments"], () =>
    getDepartmentList()
  );
  useEffect(() => {
    setDepartmentList(
      departments?.map((department) => {
        return { value: department.id, label: department.name };
      })
    );
  }, [departments]);

  // Employee List Filter
  const [employeeListFilterObj, setEmployeeListFilterObj] = useState([]);
  const { data: employeeListFilter } = useQuery(["employeeListFilter"], () =>
    getEmployeeList()
  );
  useEffect(() => {
    setEmployeeListFilterObj(
      employeeListFilter?.map((employee) => {
        return {
          value: employee.id,
          label: `${employee.name} (${employee.employeeCode})`,
        };
      })
    );
  }, [employeeListFilter]);
  const { cycleName, cycleId } = useParams();

  const handleInputChange = (e) => {
    setApplicableDate(e.target.value)
  }

  const [filter, setFilter] = useState({
    appraisalCycleId: cycleId,
    appraisalStatus: null,
    bond: null,
    client: null,
    deptId: null,
    empType: null,
    employeeId: null,
    location: null,
    // promotion: null,
    reportingManager: null,
    reward: null,
    applicableDate: null,
    technology: null,
  });
  // Appraisal System All API Integration

  // getEmployeesList of that particular cycle
  const [employeeListObj, setEmployeeListObj] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const {
    data: employeeList,
    isLoading: employeeListLoading,
    refetch: refetchEmployeeList,
  } = useQuery(["getEmployeesForPromotion", filter, currentPage], () =>
    getEmployeesForPromotion(25, currentPage, filter)
  );
  useEffect(() => {
    setTotalCount(employeeList?.totalCount && employeeList.totalCount);
    setEmployeeListObj(
      employeeList?.employees?.map((emp) => {
        return {
          ...emp,
          created: false,
          edit: false,
          reward: emp.empInfo.status === "Pending" ? "false" : emp.reward,
          applicableDateIP: "false",
          promotion: emp.empInfo.status === "Pending" ? "false" : emp.promotion,
        };
      })
    );
    setPageCount(employeeList?.totalCount && employeeList.totalCount / 25);
  }, [employeeList, render]);

  // ADD EMPLOYEES TO APPRAISAL CYCLE

  // addPromotion

  const addPromotionMutation = useMutation(addPromotion, {
    onMutate: () => { },
    onSuccess: (res) => {
      refetchEmployeeList();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => { },
  });

  const handleDelete = (e) => {
    const eid = Number(e.target.id);
    try {
      deleteAppraisalMutation.mutate({ cycleId, eid });
    } catch (mutationError) { }
  };
  const deleteAppraisalMutation = useMutation(deleteAppraisal, {
    onMutate: () => { },
    onSuccess: (res) => {
      // Refetch the cycles data after a successful mutation
      refetchEmployeeList();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => { },
  });
  const handleAppraisal = (e) => {
    resetState();
    let eid = Number(e.target.id);
    setEmployeeListObj((prev) =>
      prev.map((emp) => {
        if (emp.empInfo.id === eid) {
          setApplicableDate(emp.applicableDate ? moment(emp?.applicableDate).format("YYYY-MM-DD") : null)
          console.log("emp...", emp);
          emp.created = true;
          emp.reward = "true";
          emp.applicableDateIP = "true";
          emp.promotion = "true";
          return emp;
        }
        closeAll(emp);
        return emp;
      })
    );
  };
  const handleSubmit = (e) => {
    let eid = Number(e.target.id);
    let data = {
      appraisalCycleId: Number(cycleId),
      employeeId: Number(eid),
      promotionId:
        promotion === "" || promotion === "Choose Promotion"
          ? null
          : Number(promotion),
      applicableDate: applicableDate,
      rewardId:
        award === "" || award === "Choose Reward" ? null : Number(award),
    };
    if (!data?.promotionId) {
      notifyError("Please Enter Promotion");
      setPromotionError(true);
    } else {
      setPromotionError(false);
      addPromotionMutation.mutate(data);
      setRender(!render);
    }
  };
  const closeAll = (emp) => {
    if (emp.reward === "true") emp.reward = "false";
    if (emp.applicableDate === "true") emp.applicableDate = "false";
    if (emp.promotion === "true") emp.promotion = "false";
    emp.created = false;
  };
  const handleCancel = (e) => {
    let eid = Number(e.target.id);
    setEmployeeListObj((prev) =>
      prev.map((emp) => {
        if (emp.empInfo.id === eid) {
          emp.reward = "false";
          emp.applicableDateIP = "false";
          emp.promotion = "false";
          emp.created = false;
          return emp;
        }
        return emp;
      })
    );
    setAward("");
    setPromotion("");
    setApplicableDate("");
  };
  const resetState = () => {
    setAward("");
    setApplicableDate("")
    setPromotion("");
  };

  // Edit Functioning

  const handleEdit = (e, emp) => {
    let eid = Number(e.target.id);
    setEditAward(emp.rewardId);
    setAward(emp.rewardId);
    setPromotion(emp.promotionId);
    setApplicableDate(emp.applicableDate ? moment(emp?.applicableDate).format("YYYY-MM-DD") : null)
    // setApplicableDate(emp.applicableDate)
    setEditApplicableDate(emp.applicableDate)
    setEditPromotion(emp.promotionId);
    setEmployeeListObj((prev) =>
      prev.map((emp) => {
        if (Number(emp.empInfo.id) === eid) {
          emp.edit = true;
          emp.created = true;
          emp.reward = "true";
          emp.applicableDateIP = "true";
          emp.promotion = "true";
          return emp;
        }
        return emp;
      })
    );
  };
  const handleEditCancel = (e) => {
    let eid = Number(e.target.id);
    setEmployeeListObj((prev) =>
      prev.map((emp) => {
        if (emp.empInfo.id === eid) {
          emp.reward = editAward > 0 ? rewardList[editAward - 1].name : null;
          emp.applicableDate = applicableDate
          emp.applicableDateIP = "false"
          emp.promotion =
            editPromotion > 0 ? promotionList[editPromotion - 1].name : null;
          emp.created = false;
          emp.edit = false;
          return emp;
        }
        return emp;
      })
    );
  };
  // Filters
  const handleChangeEmp = (selectedOption, type) => {
    setSelectedEmp(selectedOption);
    switch (type) {
      case "search":
        setActiveEmp(true);
        setFilter({ ...filter, employeeId: selectedOption.value });
        break;
      case "close":
        setActiveEmp(false);
        setFilter({ ...filter, employeeId: null });
        break;
      default:
        break;
    }
  };
  const handleChangeDepartment = (selectedOption, type) => {
    setSelectedDept(selectedOption);
    switch (type) {
      case "search":
        setActiveDept(true);
        setFilter({ ...filter, deptId: selectedOption.value });
        break;
      case "close":
        setActiveDept(false);
        setFilter({ ...filter, deptId: null });
        break;
      default:
        break;
    }
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  // appraisalSendBack
  const handleSendBack = (e) => {
    let eid = Number(e.target.id);
    appraisalSendBackMutation.mutate({ eid, cycleId });
  };
  const appraisalSendBackMutation = useMutation(appraisalSendBack, {
    onMutate: () => { },
    onSuccess: (res) => {
      refetchEmployeeList();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => { },
  });

  // Approve Appraisal
  const handleApprove = (e) => {
    let eid = Number(e.target.id);
    appraisalApprovedMutation.mutate({ eid, cycleId });
  };
  const appraisalApprovedMutation = useMutation(appraisalApproved, {
    onMutate: () => { },
    onSuccess: (res) => {
      refetchEmployeeList();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => { },
  });

  // hold Appraisal
  const handleHoldAppraisal = (e) => {
    let eid = Number(e.target.id);
    holdAppraisalMutation.mutate({ eid, cycleId });
  };
  const holdAppraisalMutation = useMutation(holdAppraisal, {
    onMutate: () => { },
    onSuccess: (res) => {
      refetchEmployeeList();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => { },
  });

  // Comment Modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({
    cycleId,
    eid: "",
    cycleStatus: "",
  });
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const closePCModal = () => {
    setModalIsOpen(false);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  const openPCModal = () => {
    setPCModalIsOpen(true);
  };
  const handleProjectComments = () => {
    openPCModal();
  };

  // Project Details
  const [projectModalIsOpen, setProjectModalIsOpen] = useState(false);

  const openProjectModal = () => {
    setProjectModalIsOpen(true);
  };

  const closeProjectModal = () => {
    setProjectModalIsOpen(false);
  };
  const [projectDetailsObj, setProjectDetailsObj] = useState(null);
  const [projectDetailsComments, setProjectDetailsComments] = useState(null);
  const [pcModalIsOpen, setPCModalIsOpen] = useState(false);

  const handleView = (projectDetailObj, projectCommentsObj) => {
    setProjectDetailsObj(projectDetailObj);
    setProjectDetailsComments(projectCommentsObj);
    openProjectModal();
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  // Format Date for Bond
  const formatDateBond = (dateStr) => {
    // Split the date string into day, month, and year
    const [day, monthStr, year] = dateStr.split(" ");

    // Create a map to convert month abbreviation to its numeric value
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    // Get the numeric value of the month from the map
    const month = monthMap[monthStr];
    const date = new Date();

    // Construct the formatted date string in YYYY-MM-DD format
    const formattedDate = `20${year}-${month}-${day.padStart(2, "0")}`;
    let difference = Math.ceil(
      (new Date(formattedDate) - date) / (1000 * 60 * 60 * 24 * 30)
    );
    return difference > 0 && difference < 3;
  };

  // Excel Download

  const sheetHeader = [
    "Code",
    "Name",
    "Designation",
    "DOJ",
    "Exp with IM",
    "Total Exp",
    "Reporting Manager",
    "Bond",
    "Emp Type",
    "Department",
    "Location",
    "Client Name",
    "Technology",
    "Rating",
    "Last Appraisal Cycle",
    "Last Increment %",
    "Last Increment Amount",
    "Last Promotion Cycle",
    "Last Reward",
    "SA 1",
    "SA 2",
    "Incentives",
    "Joining Bonus",
    "Variable Bonus",
    "Retention Bonus",
    "Others",
    "Current Monthly Core CTC",
    "Current Yearly Core CTC",
    "Current Monthly Full CTC",
    "Current Yearly Full CTC",
    "Reward",
    "Promotion",
    "Status",
  ];
  const handleExcelData = (data) => {
    return data?.map((e) => {
      return [
        e.empInfo.employeeCode,
        e.empInfo.name,
        e.empInfo.designation,
        e.empInfo.dateOfJoining,
        e.empInfo.totalExpWithIM,
        e.empInfo.totalExperience,
        e.empInfo.reportingManager,
        e.empInfo.bondEndDate,
        e.empInfo.empType,
        e.empInfo.department,
        e.empInfo.location,
        e.empInfo.clientName,
        e.empInfo.technology,
        e.empInfo.rating,
        e.empInfo.lastAppraisalCycle,
        e.empInfo.lastIncrementPercenatage,
        e.empInfo.lastIncrementAmount,
        e.empInfo.lastPromotionCycle,
        e.empInfo.lastReward,
        e.empInfo.sa1,
        e.empInfo.sa2,
        e.empInfo.incentives,
        e.empInfo.joiningBonus,
        e.empInfo.variableBonus,
        e.empInfo.retentionBonus,
        e.empInfo.others,
        e.empInfo.currentMonthlyCoreCTC,
        e.empInfo.currentYearlyCoreCTC,
        e.empInfo.currentMonthlyFullCTC,
        e.empInfo.currentYearlyFullCTC,
        e.reward,
        e.applicableDate,
        e.promotion,
        e.empInfo.status,
      ];
    });
  };
  const downloadExcelFile = async () => {
    // Fetch API
    const getEmployeesForPromotion = async (limit, currentPage) => {
      const response = await axios.post(
        url + `/getEmployeesForPromotion?limit=1&page=1`,
        {
          appraisalCycleId: cycleId,
          appraisalStatus: [],
          bond: null,
          client: [],
          deptId: null,
          empType: [],
          employeeId: null,
          location: [],
          // promotion: [],
          reportingManager: [],
          reward: [],
          applicableDate: '',
          technology: [],
        }
      );
      return response.data.responseData;
    };
    // // Collect Response
    const employeeList = await getEmployeesForPromotion();
    CustomExcelFileDownload(
      sheetHeader,
      handleExcelData(employeeList?.employees),
      `${cycleName} Promotion Employee List`
    );
  };

  return (
    <>
      <div className={style.upper}>
        <div className={style.totalCount}>{totalCount}</div>
        <div className={style.filterBox}>
          <div style={{ width: "25%", marginRight: "10px" }}>
            <FiDownload
              style={{
                verticalAlign: "baseline",
                fontSize: "1.8rem",
                cursor: "pointer",
                marginLeft: "1rem",
                color: "#562679",
              }}
              onClick={downloadExcelFile}
            />
          </div>

          <div style={{ width: "25%" }}>
            <button
              className={style.filter_btn}
              onClick={() => setShowFilterModal(true)}
            >
              <FilterListIcon style={{ marginRight: "10px" }} />
              Filters
            </button>
          </div>
          <div className={style.searchContainer} style={{ zIndex: '20' }}>
            {!activeEmp ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="searchIcon" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  handleChangeEmp(null, "close");
                }}
                tabIndex={0} // Makes the span focusable
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    handleChangeEmp(null, "close");
                  }
                }} // Allows the span to be activated by pressing Enter or Space
                aria-label="close"
              >
                <AiOutlineClose />
              </span>
            )}
            <Select

              styles={customSearchACP}
              classNamePrefix={"create_position"}
              placeholder="Search EmpName/Code"
              value={selectedEmp}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              options={employeeListFilterObj}
              onChange={(selectedOption) =>
                handleChangeEmp(selectedOption, "search")
              }
            />
          </div>

          <div className={style.searchContainer}>
            {!activeDept ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="searchIcon" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  handleChangeDepartment(null, "close");
                }}
                tabIndex={0} // Makes the span focusable
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    handleChangeDepartment(null, "close");
                  }
                }} // Allows the span to be activated by pressing Enter or Space
                aria-label="close"
              >
                <AiOutlineClose />
              </span>
            )}
            <Select
              styles={customSearchACP}
              className="custom-select"
              classNamePrefix={"create_position"}
              placeholder="Search Department"
              value={selectedDept}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              options={departmentList}
              onChange={(selectedOption) =>
                handleChangeDepartment(selectedOption, "search")
              }
            />
          </div>
        </div>
      </div>
      {!employeeListLoading ? (
        <div className={style.table_container}>
          <table className={style.scrollable_table}>
            <thead>
              <tr style={{
                position: "sticky",
                zIndex: 10,
                top: "0px"
              }}  >
                <div className={style.stickyTH} style={{ marginTop: "-1px" }}>
                  <th className={`${style.sticky1h} ${style.th}`}>Code</th>
                  <th className={`${style.sticky2h} ${style.th}`}>Name</th>
                  <th className={`${style.sticky3h} ${style.th}`}>
                    Desigination
                  </th>
                  <th className={`${style.sticky4h} ${style.th}`}>DOJ</th>
                  <th className={`${style.sticky5h} ${style.th}`}>
                    Exp With IM
                  </th>
                </div>
                <th className={`${style.th}`}>Total Exp</th>
                <th className={`${style.th}`}>Reporting Manager</th>
                <th className={`${style.th}`}>Bond</th>
                <th className={`${style.th}`}>Employee Type</th>
                <th className={`${style.th}`}>Department</th>
                <th className={`${style.th}`}>Location</th>
                <th className={`${style.th}`}>Client Name</th>
                <th className={`${style.th}`}>Technology</th>
                <th className={`${style.th}`}>Rating</th>
                <th className={`${style.th}`}>Project Details</th>
                <th className={`${style.th}`}>Performance Details</th>
                <th className={`${style.th} ${style.past}`}>
                  Last Effective From
                </th>
                <th className={`${style.th} ${style.past}`}>
                  Last Appraisal Cycle
                </th>
                <th className={`${style.th} ${style.past}`}>
                  Last Increment %
                </th>
                <th className={`${style.th} ${style.past}`}>
                  Last Increment Amount
                </th>
                <th className={`${style.th} ${style.past}`}>
                  Last Promotion Cycle
                </th>
                <th className={`${style.th} ${style.past}`}>Last Reward</th>
                <th className={`${style.th} ${style.salary}`}>SA1</th>
                <th className={`${style.th} ${style.salary}`}>SA2</th>
                <th className={`${style.th} ${style.salary}`}>Incentives</th>
                <th className={`${style.th} ${style.salary}`}>Joining Bonus</th>
                <th className={`${style.th} ${style.salary}`}>
                  Variable Bonus
                </th>
                <th className={`${style.th} ${style.salary}`}>
                  Retention Bonus
                </th>
                <th className={`${style.th} ${style.salary}`}>Others</th>
                <th className={`${style.th} ${style.salary}`}>
                  Current Monthly Core CTC
                </th>
                <th className={`${style.th} ${style.salary}`}>
                  Current Yearly Core CTC
                </th>
                <th className={`${style.th} ${style.salary}`}>
                  Current Monthly Full CTC
                </th>
                <th className={`${style.th} ${style.salary}`}>
                  Current Yearly Full CTC
                </th>
                <th className={` ${style.th}`}>Reward</th>
                <th className={` ${style.th}`}>Effective From</th>
                <th className={` ${style.th}`}>Promotion</th>
                <th className={` ${style.th}`}>
                  <div className={`${style.holdComment}`}>Comments</div>
                </th>
                <th className={` ${style.th}`}>Status</th>
                <th className={` ${style.th}`}>Action</th>
              </tr>
            </thead>
            {employeeListObj?.length > 0 ? (
              <tbody>
                {employeeListObj?.map((emp) => {
                  return (
                    <tr key={emp.empInfo.id} className={style.inputRow}>
                      <div className={style.stickyTD}>
                        <td
                          className={`${style.sticky1d} ${style.thValue} ${style.td} ${style.padding_top}`}
                        >
                          <NavLink
                            to={`/ASDashboard/${cycleName}/${cycleId}/CycleEmployeeList/EmployeeDetails/${emp.empInfo.id}`}
                          >
                            <p className={`${style.code} ${style.td}`}>
                              {emp.empInfo.employeeCode}
                            </p>
                          </NavLink>
                        </td>
                        <td
                          className={`${style.sticky2d}  ${style.thValue}  ${style.td} ${style.padding_top}`}
                        >
                          <div className={style.name}>
                            {emp.empInfo.name.length > 17 ? (
                              <p title={emp.empInfo.name}>
                                {emp.empInfo.name.slice(0, 16)}...
                              </p>
                            ) : (
                              <p>{emp.empInfo.name}</p>
                            )}
                          </div>
                        </td>
                        <td
                          className={`${style.sticky3d} ${style.thValue}  ${style.td} ${style.padding_top}`}
                        >
                          <div className={style.designation}>
                            {emp.empInfo.designation?.length > 23 ? (
                              <p title={emp.empInfo.designation}>
                                {emp.empInfo.designation.slice(0, 19)}...
                              </p>
                            ) : (
                              <p>{emp.empInfo.designation}</p>
                            )}
                          </div>
                        </td>
                        <td
                          className={`${style.sticky4d}  ${style.thValue}  ${style.td} ${style.padding_top}`}
                        >
                          {moment(emp.empInfo.dateOfJoining).format("DD MMM YY")}
                        </td>
                        <td
                          className={`${style.sticky5d}  ${style.thValue}  ${style.td} ${style.padding_top}`}
                        >
                          {emp.empInfo.totalExpWithIM}
                        </td>
                      </div>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.totalExperience}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.reportingManager}
                      </td>
                      <td
                        className={` ${style.td} ${style.makeCenter} ${emp.empInfo.bondEndDate &&
                          formatDateBond(emp.empInfo.bondEndDate) &&
                          style.bondIn2Months
                          }`}
                      >
                        {emp.empInfo.bond === "Y" ? (
                          <p title={emp.empInfo.bondEndDate && moment(emp.empInfo.bondEndDate).format("DD MMM YY")}>Y</p>
                        ) : (
                          "N"
                        )}
                      </td>
                      <td className={` ${style.td}`}>{emp.empInfo.empType}</td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.department}
                      </td>
                      <td
                        className={` ${style.td} ${emp.empInfo.upcomingDeallocationDate &&
                          style.bondIn2Months
                          }`}
                      >
                        {emp.empInfo.location}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.clientName}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.technology}
                      </td>
                      <td
                        className={`${style.td} ${style.makeCenter}`}
                        style={{ color: emp.empInfo.ratingOutOf === "1 to 5" ? 'red' : 'black' }}
                        title={emp.empInfo.lastPRMonth}
                      >
                        {emp.empInfo.rating}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        <span
                          className={`${style.link}`}
                          onClick={() =>
                            handleView(
                              emp.empInfo.projectDetailObj,
                              emp.empInfo.projectCommentsObj
                            )
                          }
                          tabIndex={0} // Makes the span focusable
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "Space") {
                              handleView(
                                emp.empInfo.projectDetailObj,
                                emp.empInfo.projectCommentsObj
                              );
                            }
                          }} // Handles key presses
                          aria-label="View Details" // Provides an accessible name
                        >
                          <FaEye />
                        </span>
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        <a
                          href={emp.empInfo.performanceDetailsUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className={`${style.link}`}>
                            <ImLink />
                          </span>
                        </a>
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                      {emp.empInfo.lastApplicableFrom && moment(emp.empInfo.lastApplicableFrom).format("DD MMM YY")}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.lastAppraisalCycle}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.lastIncrementPercenatage && (
                          <p>{emp.empInfo.lastIncrementPercenatage} %</p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.lastIncrementAmount && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.lastIncrementAmount)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.lastPromotionCycle}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.lastReward &&
                          `${emp.empInfo.lastReward} (${emp.empInfo.lastRewardCycle})`}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.sa1 && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.sa1)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.sa2 && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.sa2)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.incentives && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.incentives)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.joiningBonus && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.joiningBonus)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.variableBonus && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.variableBonus)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.retentionBonus && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.retentionBonus)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.others && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.others)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.currentMonthlyCoreCTC && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.currentMonthlyCoreCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.currentYearlyCoreCTC && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.currentYearlyCoreCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.currentMonthlyFullCTC && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.currentMonthlyFullCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.makeCenter}`}>
                        {emp.empInfo.currentYearlyFullCTC && (
                          <p>
                            {/* <span className={style.money}>&#8377; </span > */}
                            {addCommas(emp.empInfo.currentYearlyFullCTC)}
                          </p>
                        )}
                      </td>
                      <td className={` ${style.td} `}>
                        {emp.reward === "true" ? (
                          <div>
                            <div>
                              <select
                                className={style.selectStyling}
                                name="Choose Reward"
                                value={award}
                                onChange={(e) => setAward(e.target.value)}
                              >
                                <option value={null}>Choose Reward</option>
                                {rewardList?.map((item) => (
                                  <option value={item?.id} key={`${item?.name}${item?.id}`}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : emp.reward === "false" ? (
                          ""
                        ) : (
                          emp.reward
                        )}
                      </td>
                      <td className={` ${style.td} `}>
                        {console.log(applicableDate, "applicableDate")}
                        {emp.applicableDateIP === "true" ? (
                          <div>
                            <div>
                              <input
                                type="date" // Use "date" input type for date selection
                                name="startDate"
                                className={style.titleIp}
                                value={applicableDate}
                                onChange={handleInputChange}
                                id="startDate"
                              />
                            </div>
                          </div>
                        ) : emp.applicableDateIP === "false" ? (
                          emp.applicableDate &&
                          moment(emp.applicableDate).format("DD MMM YY")
                        ) : (
                          emp.applicableDate &&
                          moment(emp.applicableDate).format("DD MMM YY")
                        )}
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.promotion === "true" ? (
                          <div>
                            <div>
                              <span
                                className={`${style.errorText} ${promotionError
                                  ? style.showError
                                  : style.hideError
                                  }`}
                              >
                                *
                              </span>
                              <select
                                className={style.selectStyling}
                                name="Choose Promotion"
                                value={promotion}
                                onChange={(e) => setPromotion(e.target.value)}
                              >
                                <option value={null}>Choose Promotion</option>
                                {promotionList?.map((item) => (
                                  <option value={item?.id} key={`${item?.name}${item?.id}`}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : emp.promotion === "false" ? (
                          ""
                        ) : (
                          emp.promotion
                        )}
                      </td>
                      <td
                        className={` ${style.td} ${style.commentsE} ${style.makeCenter}`}
                      >
                        {emp.empInfo.hrHeadCommentobj?.length > 0 ? <FaComments
                          className={style.commentsF}
                          onClick={() => {
                            setModalData({
                              cycleId,
                              eid: emp.empInfo.id,
                              cycleStatus: emp.empInfo.appraisalCycleStatus,
                            });
                            openModal();
                          }}
                        /> : <FaRegComments
                          onClick={() => {
                            setModalData({
                              cycleId,
                              eid: emp.empInfo.id,
                              cycleStatus: emp.empInfo.appraisalCycleStatus,
                            });
                            openModal();
                          }}
                        />
                        }
                      </td>
                      <td className={` ${style.td}`}>
                        {emp.empInfo.status === "Approved" ? (
                          <span style={{ color: "green", fontSize: "12px" }}>
                            Approved
                          </span>
                        ) : (
                          <span style={{ color: "inherit", fontSize: "12px" }}>
                            {emp.empInfo.status}
                          </span>
                        )}
                      </td>
                      <td className={` ${style.td} ${style.actionTD}`}>
                        {emp.created === false &&
                          emp.empInfo.status === "Pending" ? (
                          <div className={style.action}>
                            <button
                              className={style.appr_btn}
                              title="Create Promotion"
                              id={emp.empInfo.id}
                              onClick={(e) => {
                                handleAppraisal(e)
                              }}
                            >
                              P
                            </button>
                            <button
                              className={style.appr_btn}
                              title="Hold"
                              id={emp.empInfo.id}
                              onClick={(e) => handleHoldAppraisal(e)}
                            >
                              ||
                            </button>
                            <img
                              id={emp.empInfo.id}
                              onClick={handleDelete}
                              src={deleteIcon}
                              className={style.delete}
                              title="Delete Employee"
                              tabIndex={0} // Makes the image focusable
                              aria-label="Delete Employee" // Provides an accessible name
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Space") {
                                  handleDelete(); // Triggers the delete function on key presses
                                }
                              }}
                            />
                          </div>
                        ) : emp.created === true ? (
                          emp.edit === false ? (
                            <div className={style.action}>
                              <button
                                className={style.appr_btn}
                                onClick={handleSubmit}
                                id={emp.empInfo.id}
                              >
                                Submit
                              </button>
                              <button
                                className={style.appr_btn}
                                onClick={handleCancel}
                                id={emp.empInfo.id}
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div className={style.action}>
                              <button
                                className={style.appr_btn}
                                onClick={handleSubmit}
                                id={emp.empInfo.id}
                              >
                                Submit
                              </button>
                              <button
                                className={style.appr_btn}
                                onClick={handleEditCancel}
                                id={emp.empInfo.id}
                              >
                                Cancel
                              </button>
                            </div>
                          )
                        ) : emp.empInfo.status === "Submitted" ? (
                          <div className={style.action}>
                            <button
                              className={style.appr_btn}
                              onClick={handleApprove}
                              id={emp.empInfo.id}
                            >
                              Approve
                            </button>
                            <button
                              className={style.appr_btn}
                              onClick={handleSendBack}
                              id={emp.empInfo.id}
                            >
                              SendBack
                            </button>
                            <button
                              className={style.appr_btn}
                              onClick={(e) => handleEdit(e, emp)}
                              id={emp.empInfo.id}
                            >
                              Edit
                            </button>
                            <img
                              id={emp.empInfo.id}
                              onClick={handleDelete}
                              src={deleteIcon}
                              className={style.delete}
                              title="Delete Employee"
                              tabIndex={0} // Makes the image focusable
                              aria-label="Delete Employee" // Provides an accessible name
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Space") {
                                  handleDelete(); // Triggers the delete function on key presses
                                }
                              }}
                            />
                          </div>
                        ) : emp.empInfo.appraisalCycleStatus.trim() !==
                          "Closed" ? (
                          <div className={style.action}>
                            <button
                              className={style.appr_btn}
                              onClick={(e) => handleEdit(e, emp)}
                              id={emp.empInfo.id}
                            >
                              Edit
                            </button>
                            <img
                              id={emp.empInfo.id}
                              onClick={handleDelete}
                              src={deleteIcon}
                              className={style.delete}
                              title="Delete Employee"
                              tabIndex={0} // Makes the image focusable
                              aria-label="Delete Employee" // Provides an accessible name
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Space") {
                                  handleDelete(); // Triggers the delete function on key presses
                                }
                              }}
                            />
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <div className={style.noUser}>No Employees Available</div>
            )}
          </table>
        </div>
      ) : (
        <AppraisalLoader />
      )}
      {!employeeListLoading && (
        <div className={style.pagination}>
          <Pagination
            pagesCount={pageCount}
            pageSize="25"
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />
        </div>
      )}
      <Modal
        open={modalIsOpen}
        onRequestClose={closeModal}
        center
        onClose={() => setModalIsOpen(false)}
        showCloseIcon={false}
        className={style.modal}
      >
        <div className={style.commentModal}>
          <HrHeadCom
            modalData={modalData}
            setModalIsOpen={setModalIsOpen}
            refetchEmployeeList={refetchEmployeeList}
          />
        </div>
      </Modal>
      <Modal
        open={pcModalIsOpen}
        onRequestClose={closePCModal}
        center
        onClose={() => setPCModalIsOpen(false)}
        showCloseIcon={false}
        className={style.modal}
      >
        <div className={style.commentModal2}>
          {projectDetailsComments?.length > 0 ? (
            <ProjectComments hrHeadComment={projectDetailsComments} />
          ) : (
            <div
              style={{
                position: "fixed",
                left: "45%",
                top: "50%",
                fontWeight: "700",
              }}
            >
              No Comments
            </div>
          )}
        </div>
      </Modal>
      <Modal
        open={projectModalIsOpen}
        onRequestClose={closeProjectModal}
        center
        onClose={() => setProjectModalIsOpen(false)}
        showCloseIcon={false}
      >
        <div className={style.projectModal}>
          <div className={style.projectUpperPortion}>
            <div className={style.projectHeading}>Project Details</div>
            <div
              className={style.projectComments}
              onClick={() => handleProjectComments()}
              tabIndex={0} // Makes the div focusable
              aria-label="View Project Comments" // Provides an accessible name
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space") {
                  handleProjectComments(); // Triggers the click function on key presses
                }
              }}
            >
              <FaRegComments />
            </div>
          </div>
          <table className={style.projectTable}>
            <thead className={style.projectThead}>
              <tr className={style.projecttrHead}>
                <th className={style.projectth}>Project Name</th>
                <th className={style.projectth}>Bandwidth</th>
                <th className={style.projectth}>Allocation</th>
                <th className={style.projectth}>DeAllocation</th>
              </tr>
            </thead>
            {projectDetailsObj?.length > 0 ? (
              <tbody className={style.projecttbody}>
                {projectDetailsObj.map((project) => {
                  return (
                    <tr className={style.projecttdRow} key={project.projectId}>
                      <td className={style.projecttd}>{project.projectName}</td>
                      <td className={style.projecttd}>
                        {project.bandwidth === "OneFourth" ? (
                          <p>25%</p>
                        ) : project.bandwidth === "Half" ? (
                          <p>50%</p>
                        ) : project.bandwidth === "Full" ? (
                          <p>100%</p>
                        ) : (
                          <p>75%</p>
                        )}
                      </td>
                      <td className={style.projecttd}>
                        {formatDate(project.allocationDate)}
                      </td>
                      <td className={style.projecttd}>
                        {formatDate(project.deallocationDate)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <div className={style.noProjects}>
                Project Details are not Available
              </div>
            )}
          </table>
        </div>
      </Modal>
      <AppraisalFilter
        showFilter={showFilterModal}
        setShowFilter={setShowFilterModal}
        filterUpdateFuc={filterUpdateFuc}
        filterClearAllFuc={filterClearAllFuc}
        setFilterData={setFilterData}
        filterData={filterData}
        secondCard="Technology"
        secondCardlist={technologyList}
        thirdCard="Employee Type"
        thirdCardlist={empTypeList}
        forthCard="Client"
        forthCardlist={clientList}
        fiveCard="Reporting Manager"
        fiveCardlist={rmList}
        SixCard="Reward"
        SixCardlist={rewardListNew}
        Eightcard="Status"
        Eightcardlist={statusListNew}
        NineCard="Bond"
        Ninecardlist={["yes", "No"]}
        TenCard="Location"
        Tencardlist={locationList}
        filterApplied={filterApplied}
      />
    </>
  );
};

export default AppraisalCyclePromotionsEmployeeListComponent;
