import pdf from '../../../assets/pdf.png'
import word from "../../../assets/word.png";
import file from "../../../assets/file.png";
export const checkFileType = (value) => {
    switch (value) {
      case "pdf":
        return <img src={pdf} alt="pdf" />;
      case "docx":
        return <img src={word} alt="word" />;
      default:
        return <img src={file} alt="file" />;
    }
  };