import styled from "@emotion/styled";
import { Switch } from "@mui/material";
import { borderColor, height, minHeight } from "@mui/system";

export const customStyles = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "#f5f6fa", // Corrected this line
    // opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  // isDisabled: (base) => ({
  //     ...base,
  //     backgroundColor: "#999",
  //     color: "#333",
  // }),

  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};
export const customStyles7 = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "#f5f6fa", // Corrected this line
    // opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    cursor: "pointer"
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  // isDisabled: (base) => ({
  //     ...base,
  //     backgroundColor: "#999",
  //     color: "#333",
  // }),

  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};
export const customStyles6 = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "#f5f6fa", // Corrected this line
    // opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  // isDisabled: (base) => ({
  //     ...base,
  //     backgroundColor: "#999",
  //     color: "#333",
  // }),

  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '200px', // Set the maximum height for the menu
    overflowY: 'auto', // Enable vertical scrolling
  }),
};

export const customStyles5 = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    // minWidth: "inherit",
    // margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};
export const customStyles2 = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    border: "none",
    opacity: state.isDisabled ? "0.2" : "1",

    minWidth: "99%",
    margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    overflowX: "scroll",
    flexWrap: "unset",
    "&::-webkit-scrollbar": {
      height: "4px",
    },
  }),
  multiValue: (base) => ({
    ...base,
    flex: "0 0 auto",
    justifyContent: "center",
  }),
  // clearIndicator
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};
export const customSearch = {
  control: (base, { isDisabled }) => ({
    // margin: "0 7px",
    ...base,
    borderRadius: "5px",
    padding: "0 20px 0 10px",
    border: "1px solid #00000061",
    cursor: "pointer",
    backgroundColor: isDisabled ? "#e0dcdc" : "#f5f6fa",
  }),
  placeholder: () => ({
    // display: "none",
    marginTop: "-30px",
    color: "hsl(0, 0%, 5%);",
    fontSize: "15px"
  }),

  //  option color on hover green
  option: (provided, state) => console.log("isSelected", state.isSelected)||({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  // dropdown width
  menu: (provided) => ({
    ...provided,
    width: "92%",
    marginLeft: "0.6rem",
    zIndex: "999",
    maxHeight: "300px",
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
};
export const weekSearch = {
  control: () => ({
    // margin: "0 7px",
    borderRadius: "5px",
    padding: "0 20px 0 10px",
    border: "1px solid #00000061",
    cursor: "pointer",
    backgroundColor: "#f5f6fa",
  }),
  placeholder: () => ({
    // display: "none",
    marginTop: "-30px",
    color: "hsl(0, 0%, 5%);",
  }),
  //  option color on hover green
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
    fontSize: "15px",
  }),
  // dropdown width
  menu: (provided) => ({
    ...provided,
    width: "92%",
    marginLeft: "0.6rem",
    zIndex: "999",
  }),
};

export const customSearchPosition = {
  control: () => ({
    margin: "0 10px",
    borderRadius: "5px",
    padding: "0 20px 0 10px",
    border: "1px solid rgba(0, 0, 0, 0.192)",
    width: "96%",
    cursor: "pointer",
  }),
  placeholder: () => ({
    // display: "none",
    marginTop: "-30px",
    color: "hsl(0deg 2% 18%)",
  }),
  //  option color on hover green
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  // dropdown width
  menu: (provided) => ({
    ...provided,
    width: "96%",
    marginLeft: "0.6rem",
    zIndex: "999",
  }),
};
export const customStyles3 = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    backgroundColor: state.isDisabled ? "#999" : "#f5f6fa",
    border: "none",
    // minWidth: "inherit",
    margin: "0px 1.5% 0px 1.7%",
    height: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

export const DropSwitch = styled(Switch)(({ checked }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: checked === "light" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: checked === "light" ? "#E9E9EA" : "#39393D",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: checked === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: checked === "light" ? "#E9E9EA" : "#d2cbcb",
    opacity: 1,
  },
}));

export default DropSwitch;

export const DropSwitchPCM = styled(Switch)(({ checked }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: checked === "light" ? "#2ECA45" : "#964b00",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: checked === "light" ? "#E9E9EA" : "#39393D",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: checked === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: checked === "light" ? "#E9E9EA" : "#d2cbcb",
    opacity: 1,
  },
}));

export const customStylesByTushar = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#e7dddd" : "#f5f6fa", // Corrected this line
    // opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  // isDisabled: (base) => ({
  //     ...base,
  //     backgroundColor: "#999",
  //     color: "#333",
  // }),

  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};
export const customStylesForSpaces = {
  control: (style, state) => ({
    ...style,
    "&:hover": {
      borderColor: "#65a15e"
    },
    borderColor: state?.isFocused ? "#65a15e" : "",
    boxShadow: state.isFocused ? "#65a15e 0px 0px 0px 1px" : "",
    cursor: "pointer",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
      borderColor: state?.isFocused ? "#65a15e" : "",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  // isDisabled: (base) => ({
  //     ...base,
  //     backgroundColor: "#999",
  //     color: "#333",
  // }),

  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

export const customStylesForSpaceRoles = {
  control: (style, state) => ({
    ...style,
    minHeight: "20px",
    height: "20px",
    cursor: "pointer",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(50, 205, 50, 0.5)",
      borderColor: state?.isFocused ? "#65a15e" : "",
    },
    backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
  }),
  placeholder: () => ({
    display: "none",
  }),
  singleValue: (base) => ({
    ...base,
    display: "inline",
    fontSize: '14px', // Adjust the font size
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    height: "25px",
    minHeight: "25px"
  }),
};