import axios from "axios";
import constants from "../constants";

const baseUrl = process.env.REACT_APP_RESOURCEMASTER_APP_URL;
const baseUrl2 = process.env.REACT_APP_ATS_APP_URL;

const baseURL =constants.apiBaseUrl

export const viewResourceHistory = async (empId) => {
  const response = await axios.get(
    `${baseUrl}/view-resource-hisory?empId=${empId}`
  );
  return response.data.responseData;
};

export const getAllocatedOffShoreEmployee = async () => {
  const response = await axios.get(`${baseUrl}/delievery-employee-listing `);
  const finalRes = response.data.responseData?.employees?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  return finalRes;
};

export const addToPool = async ({id, resourceType, status}) => {
  const response = await axios.put(`${baseUrl}/update-forcibly-added-status?id=${id}&resourceType=${resourceType}&status=${status}`);
  return response;
}

export const addToPoolResource = async ({empId, status}) => {
  const response = await axios.put(`${baseUrl}/update-removal-status?empId=${empId}&status=${status}`);
  return response;
}

export const getTechnologyDashboardForOffshoreResources = async (data) => {
  const response = await axios.post(baseUrl + `/get-technology-dashboard-for-offshore-resources`, data);
  return response?.data?.responseData;
};
export const getTechnologyDashboardForOnSiteResources = async (data) => {
  const response = await axios.post(baseUrl + `/get-technology-dashboard-for-onsite-resources`, data);
  return response?.data?.responseData;
};
export const getTechnologyDashboardForPoolResources = async (data) => {
  const response = await axios.post(baseUrl + `/get-technology-dashboard-for-pool-resources`, data);
  return response?.data?.responseData;
};
export const getTechnologyDashboardOfferAcceptedCandidate = async (data) => {
  const response = await axios.post(baseUrl2 + `/get-technology-dashboard-for-offer-accepted-candidates`, data);
  return response?.data?.responseData;
};

export const getResourceBySkill = async (skillId) => {
  const response = await axios.get(baseUrl + `/rms-technology-dashboard?skillId=${skillId ? skillId : ""}`);
  return response?.data?.responseData;
};