import React from "react";
import style from "./showstatus.module.css";
const ShowStatus = ({ index, component, children, circleColor }) => {
  const showtooltip = (id, type) => {
    if (type === "SHOW") {
      document.getElementById(id).style.visibility = "visible";
    } else {
      document.getElementById(id).style.visibility = "hidden";
    }
  };
  return (
    <>
      <div
        onMouseOver={() => showtooltip(index, "SHOW")}
        onMouseOut={() => showtooltip(index, "HIDE")}
        style={{
          ...circleColor,
          marginLeft: "0.5rem",
          cursor: "pointer",
          fontSize: "1rem",
          width: "2rem",
          height: "2rem",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
        <span
          id={index}
          className={style.tooltipctainer}
          style={{
            visibility: "hidden",
            margin: "0 0 0 0",
          }}
        >
          {component}
        </span>
      </div>
    </>
  );
};

export default ShowStatus;
