import React, { useContext, useEffect} from "react";
import AppraisalSystemSidebar from "../../../commonComponent/Sidebar/AppraisalSystemSidebar";
import style from "./AppraisalCycleEmployeeList.module.css";
import AppraisalCycleEmployeeListComponent from "./AppraisalCycleEmployeeListComponent/AppraisalCycleEmployeeListComponent";
import AppraisalCyclePromotionsEmployeeListComponent from "./AppraisalCyclePromotionsEmployeeListComponent/AppraisalCyclePromotionsEmployeeListComponent";
import AppraisalCycleRewardsEmployeeListComponent from "./AppraisalCycleRewardsEmployeeListComponent/AppraisalCycleRewardsEmployeeListComponent";
import ASContext from "../../../commonComponent/Context/ASContext";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { DataContext } from "../../../../Projects_and_Resource_Master/CommonComponent/useContext/DataContext";


const Child = () => {
  const { data, setData } = useContext(DataContext);
  useEffect(() => {
    !data?.activeAP&&setData((prev)=>{
      return {...prev,activeAP:"appraisals"}
    })
  }, []);
  
  const showAppraisals = () => {
    setData((prev)=>({
      ...prev,activeAP:"appraisals"
    }))
  };
  const showRewards = () => {
    setData((prev)=>({
      ...prev,activeAP:"rewards"
    }))
  };
  const showPromotions = () => {
    setData((prev)=>({
      ...prev,activeAP:"promotions"
    }))
  };

  return (
      <div className={style.mainSection}>
          <AppraisalSystemSidebar />
          <div className={style.rightSide}>
            <div className={style.upper}>
              <div className={style.breadscrumDiv}>
              <p className={style.pp}>
                <NavLink to="/ASDashboard">
                  <span className={style.breadscrum}>Dashboard</span>
                </NavLink>
                <span> &gt; Employees List</span>
              </p>
              </div>
              <div className={style.category}>
              <div
                className={`${style.appraisals} ${
                  data?.activeAP === "appraisals" && style.active
                } `}
                onClick={showAppraisals}
                tabIndex={0} 
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    showAppraisals();
                  }
                }}
                aria-pressed={data?.activeAP === "appraisals"}  // ARIA state for toggle buttons
              >
                Appraisals
              </div>
              <div
                className={`${style.promotions} ${
                  data?.activeAP === "promotions" && style.active
                }`}
                onClick={showPromotions}
                tabIndex={1} 
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    showPromotions();
                  }
                }}
                aria-pressed={data?.activeAP === "promotions"}  // ARIA state for toggle buttons
              >
                Promotions
              </div>
              <div
                className={`${style.rewards} ${
                  data?.activeAP === "rewards" && style.active
                }`}
                onClick={showRewards}
                tabIndex={2} 
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    showRewards();
                  }
                }}
                aria-pressed={data?.activeAP === "rewards"}  // ARIA state for toggle buttons
              >
                Rewards
              </div>
            </div>
              
            </div>
            {data?.activeAP==="appraisals"?
            <div className={style.table}><AppraisalCycleEmployeeListComponent /></div> :
            data?.activeAP==="rewards"? <div className={style.table}><AppraisalCycleRewardsEmployeeListComponent /></div>
            : data?.activeAP==="promotions"?<div className={style.table}><AppraisalCyclePromotionsEmployeeListComponent /> </div>
            :null
        }
          </div>
      </div>
  );
};
const AppraisalCycleEmployeeList = () => {
  return (
    <ASContext>
      <Child />
    </ASContext>
  )
}
export default AppraisalCycleEmployeeList;
