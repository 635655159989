import React, { useState } from "react";
import style from "./AddComment.module.css";
import { useMutation } from "react-query";
import { addCommentsForCandidate, addCommentsForPartner, addCommentsForPosition, createJDShareComment } from "../../../../../Utils/axios/api";
import { notifyError, notifySuccess } from "../../../utils/notify";
import { employeeId } from "../../../../../Utils/constants";

function AddComment({
  candidateId,
  setShowAddComment,
  obj,
  setObj,
  setShowCommentModal,
  partnerId,
  positionId
}) {
  console.log("setObj",obj);
  const [commentData, setCommentData] = useState({
    comments: obj ? obj.comments || obj.comment : "",
    commentId: obj ? obj.commentId : null,
    commentedById: employeeId,
    partnerId: partnerId
  });
  const addCommentsForCandidateMutation = useMutation(addCommentsForPartner, {
    onMutate: () => { },
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 1) {
        notifySuccess(res.responseData);
      }
      else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {
      notifyError("Something went wrong");
    },
  });

  const createJDShareCommentMutation = useMutation(createJDShareComment, {
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 1) {
        notifySuccess(res.responseData);
      }
      else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {
      notifyError("Something went wrong");
    },
  })
  const handleSubmit = (e) => {
    e.preventDefault();
    if (commentData.comments.trim()) {
      if(positionId==0){
        addCommentsForCandidateMutation.mutate({
          comments: commentData?.comments,
          commentedById: employeeId,
          id: commentData?.commentId ? commentData.commentId : null,
          partnerId: partnerId
        });
      } else {
          createJDShareCommentMutation.mutate({
            comment: commentData?.comments,
            commentId: commentData?.commentId ? commentData.commentId : null,
            employeeId: employeeId,
            partnerId: partnerId,
            positionId: positionId
          })
      }
      setShowAddComment(false);
      setShowCommentModal(false);
    }
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setObj(null);
    setShowAddComment(false);
  };
  const handleChange = (e) => {
    setCommentData((prev) => {
      return { ...prev, comments: e.target.value };
    });
  };

  return (
    <div className={style.main_container}>
      <p className={style.heading}>Add Comment</p>

      <form>
        <textarea
          name="description"
          id="com"
          cols="50"
          rows="10"
          className={style.textArea}
          value={commentData?.comments}
          onChange={(e) => handleChange(e)}
        ></textarea>
        <br />
        <div className={style.btn_container}>
          <button type="cancel" className={style.btn} onClick={handleCancel}>
            Cancel
          </button>
          <button type="submit" className={style.btn} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddComment;

AddComment.defaultProps = {
  positionId: 0
}