import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../OnBoardingStatus/OnBoardingStatus.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import moment from "moment";
const OnBoardingStatus = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPag,
}) => {
  const dateObject = moment(prevData?.effectiveFrom).format("DD MMM YY");
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "15px", marginLeft: "25px" }}>
              On Boarding
            </h6>
          </Col>
        </Row>

        <InvoiceContainer>
          <Row>
            <Col md={9} style={{ paddingLeft: "45px" }}>
              <h6>Current Status</h6>
              <p>{prevData?.status}</p>
            </Col>
            <Col md={3}>
              <h6>Effective From</h6>
              <p>{dateObject}</p>
            </Col>
          </Row>
        </InvoiceContainer>
        <button
          className={classes.buttonAdd}
          onClick={() => {
            setNextScreen("PartnerList");
            setPrevData((prevData) => ({
              ...prevData,
              page2: "PartnerList",
              page3: null,
              page4: null,
              active: "PartnerList",
            }));
          }}
        >
          Done
        </button>
      </Container>
    </>
  );
};
export default OnBoardingStatus;
