import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import style from "./ProjectDetails.module.css";

const TableRow = (props) => {
  return (
    <tr
      style={{
        borderBottom: "2px solid #F0F0F0",
        textAlign: "start",
        fontSize: "13px",
      }}
    >
      <Link
        to={`/Projectdescription?id=${props.val.projectid}`}
        className={style.backColor}
      >
        <td
          style={{
            padding: "10px",
            textTransform: "capitalize",
            fontWeight: "bolder",
            textDecorationLine: "underline",
          }}
        >
          {props.val.clientName}
        </td>
      </Link>
      <td style={{padding:"10px" }}>{props.val.projectName}</td>
      <td style={{padding:"10px" }}>{props.val.empName}</td>
      <td style={{padding:"10px"}}>
        {Moment(props.val.startdate).format("DD MMM YY")}
      </td>
      <td style={{padding:"10px"  }}>{props.val.catogaryprojectName}</td>
      {/* <td style={{ width: "15%" }}>{props?.statusTypeName}</td> */}
      {props?.val.statusTypeName === "HOLD" ? (
        <td style={{padding:"10px"  }}>
          {props?.val.statusTypeName}
          {Moment(props.val.holdDate).format("DD MMM YY")}
        </td>
      ) : props?.val.statusTypeName === "CLOSED" ? (
        <td style={{padding:"10px"  }}>
          {props?.val.statusTypeName}
          {Moment(props.val.enddate).format("DD MMM YY")}
        </td>
      ) : (
        <td style={{padding:"10px"  }}>{props?.val.statusTypeName}</td>
      )}
    </tr>
  );
};

export default TableRow;
