import * as XLSX from "xlsx";
import React from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import { getAllSalaryMasterWithFilter, getAllSalaryMasterWithFilterDownload } from "../../../../Utils/axios/umsApi";
import { useQuery } from "react-query";
import Loader from "../../../../GenericComponent/Loader/Loader";
import { addCommas } from "../../../Appraisal_System/commonComponent/utils/AddCommas";
import moment from "moment";
const SalaryMaster = ({ filter, limit, currentPage, startDate, endDate, allCount }) => {
  const { data, isLoading, refetch } = useQuery(
    ["getAllSalaryMasterWithFilterDownload", { filter, currentPage }],
    () =>
      getAllSalaryMasterWithFilterDownload(
        allCount,
        currentPage,
        filter?.empId,
        filter?.empTypeId,
        startDate,
        endDate,
        filter?.sortBy,
        true
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        let data = res && res?.salaryMasterResponses && [...res?.salaryMasterResponses];
        handleDownloadButtonClick(data);
      },
    }
  );

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Type",
        "Code",
        "Name",
        "DOJ",
        "Salary Range",
        "Current Monthly Core CTC",
        "Current Monthly Full CTC",
        "Current Yearly Core CTC",
        "Current Yearly Full CTC",
        "Salary ( As per Project & Costing )",
        "Basic",
        "HRA",
        "OA",
        "Conveyance",
        "Bonus",
        "Gross Salary",
        "Employer PF",
        "Employee PF",
        "Gratuity",
        "SA1",
        "SA2",
        "Incentives",
        "Variable Bonus",
        "Joining Bonus",
        "Retention Bonus",
        "Others",
        "Salary Note",
      ], // Header row
      ...data.map((val) => [
        val?.empType,
        val?.empCode,
        val?.empName,
        moment(val?.dateOfJoining).format("DD MMM YY"),
        val?.salaryRange,
        val?.salary && addCommas(val?.salary),
        val?.currentMonthlyFullCTC && addCommas(val?.currentMonthlyFullCTC),
        val?.currentYearlyCoreCTC && addCommas(val?.currentYearlyCoreCTC),
        val?.currentYearlyFullCTC && addCommas(val?.currentYearlyFullCTC),
        val?.salaryAsProjectAndCosting &&
        addCommas(val?.salaryAsProjectAndCosting),
        val?.basic,
        val?.hra,
        val?.oa,
        val?.conveyance,
        val?.bonus,
        val?.grossSalary,
        val?.employerPF,
        val?.employeePF,
        val?.gratuity,
        val?.sa1 && addCommas(val?.sa1),
        val?.sa2 && addCommas(val?.sa2),
        val?.incentives && addCommas(val?.incentives),
        val?.variableBonus && addCommas(val?.variableBonus),
        val?.joiningBonus && addCommas(val?.joiningBonus),
        val?.retentionBonus && addCommas(val?.retentionBonus),
        val?.others && addCommas(val?.others),
        val?.salaryNotes,
      ]), // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `SalaryMaster-${moment(new Date()).format("DD MMM YY, HH:MM")}+${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);
    downloadExcelFile(workbook);
  }
  return (
    <div>
      {isLoading && <Loader />}
      <FiDownload
        size={"35px"}
        color="#289E98"
        onClick={() => refetch()}
      />
    </div>
  );
};

export default SalaryMaster;
