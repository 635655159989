import React, { useEffect, useState } from "react";
import Header from "../../taskTracker/commonComponent/Header";
import moment from "moment";
import SideBar from "../../Projects_and_Resource_Master/Clients/component/SideBar";
import style from "./ResourceHistory.module.scss";
import { useQuery } from "react-query";
import {
  getAllocatedOffShoreEmployee,
  viewResourceHistory,
} from "../../../Utils/axios/rmsApi";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import searchIcon from "../../../assets/Icon-search.png";
import { customSearch } from "../Clients/component/style";
import { renderTitle } from "../../../Utils/constants";

function ResourceHistory() {
  const [filterName, setFilterName] = useState({
    empId: null,
    empLabel: null,
  });

  const {
    data: profileData,
    isError,
    isLoading,
  } = useQuery(
    ["viewResourceHistory", filterName],
    () => viewResourceHistory(filterName?.empId),
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );

  const { data: employeeNameOptions } = useQuery(
    ["employeeNameOptions"],
    () => getAllocatedOffShoreEmployee(),
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );
  console.log(employeeNameOptions)
  const bandwidthPercentage = (bandwidth) => {
    return (
      {
        Half: "50%",
        ThreeFourth: "75%",
        OneFourth: "25%",
      }[bandwidth] || "100%"
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isDateGreater = (deallocationDate) => {
    const deallocationDateObj = deallocationDate
      ? new Date(deallocationDate)
      : null;
    const currentDate = new Date();
    return deallocationDateObj && deallocationDateObj > currentDate;
  };
  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <SideBar />
        <div className={style.rightSide} style={{ width: "80%" }}>
          <div className={style.s1} style={{ width: "30%" }}>
            {!filterName?.empId ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="img" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilterName({ ...filterName, empId: null, empLabel: null });
                }}
              >
                <AiOutlineClose />
              </span>
            )}
            <Select
              styles={customSearch}
              classNamePrefix={"create_position"}
              placeholder="Employee Name/Code"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={
                filterName.empId
                  ? { value: filterName.empId, label: filterName.empLabel }
                  : null
              }
              onChange={(e) => {
                setFilterName({
                  ...filterName,
                  empId: e.value,
                  empLabel: e.label,
                });
              }}
              options={employeeNameOptions}
            />
          </div>
          {filterName?.empId && (
            <>
              <div className={style.card}>
                <p className={style.title}>Employee Details</p>

                {/* 1 */}
                <div className={style.Cu_row1}>
                  {/* name, empcode, exp , salaey name */}

                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Employee Name</p>
                    <p className={style.value}>
                      <p
                        className={style.value}
                      >{`${profileData?.empName} (${profileData?.empCode})`}</p>
                    </p>
                  </div>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Employee Experience</p>
                    <p className={style.value}>
                      <p className={style.value}>
                        {(function () {
                          let year = ~~(profileData?.experience / 12);
                          let month = profileData?.experience % 12;
                          year = year > 0 ? year + " Y  " : "0 Y";
                          month = month > 0 ? ` - ${month}  M ` : "";
                          return `   ${year}  ${month}`;
                        })()}
                      </p>
                    </p>
                  </div>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Salary</p>
                    <p className={style.value}>
                      <p className={style.value}>{profileData?.salaryRange}</p>
                    </p>
                  </div>
                </div>
              </div>

              <div className={style.card}>
                <p className={style.title}>Employee History</p>
                <div className={style.PositionList}>
                  <div className={style.table}>
                    <div className={style.th}>
                      <p className={style.tableContent}>Project Name</p>
                      <p className={style.tableContent}>Client Name</p>
                      <p className={style.tableContent}>Skill</p>
                      <p className={style.tableContent}>Bandwidth</p>
                      <p className={style.tableContent}>Start Date</p>
                      <p className={style.tableContent}>End Date</p>
                    </div>
                    <div>
                      {isLoading && (
                        <p className={style.no_data}>Loading .....</p>
                      )}
                      {!isLoading && !isError ? (
                        profileData?.resourceHistoryDetails?.length > 0 ? (
                          typeof profileData?.resourceHistoryDetails !==
                            "string" &&
                          profileData?.resourceHistoryDetails?.map((item) => {
                            return (
                              <div className={style.td} key={item?.positionId}>
                                <p
                                  className={`${style.tableContent} `}
                                  title={renderTitle(item?.project, 15)}
                                  // onClick={() => {
                                  //   setNextScreen("viewFeedback");
                                  //   setPrevData((prevData) => ({
                                  //     ...prevData,
                                  //     empId: item?.empId,
                                  //     pReId: item?.performanceReviewId,
                                  //     sidebar: "EmployeePerformanceReview",
                                  //     page: "viewFeedback",
                                  //     page1: "EmployeePerformanceReview",
                                  //     page2: "EmployeeFeedbackList",
                                  //     page3: "viewFeedback",
                                  //     page4: null,
                                  //     active: "viewFeedback",
                                  //     showAllFeedback: false,
                                  //   }));
                                  // }}
                                >
                                  {item?.project}
                                </p>
                                <p
                                  className={style.tableContent}
                                  title={renderTitle(item?.client, 15)}
                                >
                                  {item?.client}
                                </p>
                                <p
                                  className={style.tableContent}
                                  title={renderTitle(item?.skill, 15)}
                                >
                                  {item?.skill}
                                </p>
                                <p className={style.tableContent}>
                                  {bandwidthPercentage(item?.bandwidthUsed)}
                                </p>
                                <p className={style.tableContent}  style={{
                                   fontWeight:"500",
                                    color: isDateGreater(item.deallocationDate) ? 'green' : 'red',
                                    // Add any other inline styles you need
                                  }}>
                                  {item.allocationDate
                                    ? moment(item.allocationDate).format(
                                        "DD MMM YYYY"
                                      )
                                    : ""}
                                </p>

                                <p
                                  className={style.tableContent}
                                  style={{
                                    fontWeight:"500",
                                    color: isDateGreater(item.deallocationDate) ? 'green' : 'red',
                                    // Add any other inline styles you need
                                  }}
                                >
                                  {item.deallocationDate
                                    ? moment(item.deallocationDate).format(
                                        "DD MMM YYYY"
                                      )
                                    : ""}
                                </p>
                              </div>
                            );
                          })
                        ) : (
                          <p className={style.no_data}>No Data Found</p>
                        )
                      ) : null}
                    </div>
                  </div>
                  <></>
                </div>
              </div>
              {/* <Modal
        open={show2}
        center
        onClose={() => setShow2(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
          <div>
            <p className={style.textM}>Data not found!</p>
          </div>
          <div className={style.M_button}>
            <div className={style.button}>
              <button
                className={style.b_inactive}
                onClick={() => {
                  setNextScreen("EmployeePerformanceReview");
                  setPrevData((prevData) => ({
                    ...prevData,
                    sidebar: "EmployeePerformanceReview",
                    page: "EmployeePerformanceReview",
                    page1: "EmployeePerformanceReview",
                    page2: null,
                    page3: null,
                    active: "EmployeePerformanceReview",
                  }));
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ResourceHistory;
