import React from 'react'
import Header from "../../../../taskTracker/commonComponent/Header";
import style from "./availableResources.module.css";
import Sidebar from "../../../Clients/component/SideBar";
import FilterListIcon from '@mui/icons-material/FilterList';
import {Table} from "react-bootstrap";
// import TableRow from '../Clients/component/TableRow';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TableRow from './TableRow';
import { Link } from 'react-router-dom';

const availableResources = () => {
  return (
    <>
        <Header/>
        <div className={style.main_client_body}>
            <Sidebar />

            
            <div className={style.table_div}>
                <div className={style.table_div_head}>
                    <div className={style.breadscrumb} style={{padding:"6px 0px"}}>
                        <span>Project <span className={style.arrow}> {'>'} </span> Create Project <span className={style.arrow2}> {'>'} </span> <span className={style.recent_tab}>Available Resources</span></span>
                    </div>

                    <div className={style.second_child}>
                        <div style={{padding:"10px 0px", fontWeight:"700"}}>Available Resources<span className={style.number}>23</span> <Link to="/AllocateResources"><button className={style.button}>Allocate</button></Link></div>
                        <button className={style.filter_btn}>{<FilterListIcon/>}Filter</button>
                        <input className={style.search_emp} type="text" placeholder='Search Employee'/>
                    </div>
                </div>

                <div className={style.original_table}>
                    <Table borderless size="sm" style={{fontSize:"12px"}}>
                        <thead style={{backgroundColor:"#f5f5f5"}}>
                            <tr style={{padding:"100px"}}>
                                <th style={{padding:"15px", display:"flex", alignItems:"center"}}><input type="checkbox" style={{marginRight:"10px", visibility:"visible"}}/>Employee Name</th>
                                <th style={{padding:"15px"}}>Master Skills</th>
                                <th style={{padding:"15px"}}>Availability Status<ArrowDownwardIcon style={{fontSize:"17px"}}/></th>
                                <th style={{padding:"15px"}}>Allocate Resource</th>
                            </tr>
                        </thead>

                        <tbody>
                            <TableRow/>
                            <TableRow/>
                            <TableRow/>
                            <TableRow/>
                            <TableRow/>
                            <TableRow/>
                            <TableRow/>
                            <TableRow/>
                            <TableRow/>
                        </tbody>
                    </Table>
                    </div>
                    {/* <Link to="/AllocateResources">Allocate Resources</Link> */}
                </div>
            </div>
    </>
  )
}

export default availableResources
