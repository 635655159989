import React, { useState } from 'react';
import Style from "./Note.module.css";
function Note({ isEditNote, setIsNote, noteData, setNoteData, handleAddNote }) {
    const [error, setError] = useState(false);
    const [disable, setDisable] = useState(false);

    const handleInputChange = (e) => {
        if (e.target.value !== '') {
            setError(false);
        }
        setNoteData({
            ...noteData,
            note: e.target.value,
        })
    }
    const handleSubmit = () => {
        if (noteData.note.trim() === '') {
            setError(true);
        }
        else {
            handleAddNote();
            setDisable(true);
        }
    }
    const handleBack = () => {
        setIsNote(false);
        setNoteData({
            ...noteData,
            note: "",
        })
    }
    return (
        <>
            <div className={Style.note_container}>
                <p className={Style.note_heading}>
                    {" "}
                    {isEditNote ? "Edit Note" : "Add Note"}
                </p>
                <div className={Style.note_content}>
                    <textarea
                        autoComplete="off"
                        onChange={handleInputChange}
                        className={Style.text}
                        name="note"
                        value={noteData.note}
                        style={{ backgroundColor: "#F5F6FA" }}
                    ></textarea>
                    {error ? (
                        <p className={Style.error}>Please Enter Note</p>
                    ) : null}
                </div>
            </div>
            {/* Buttons */}
            <div className={Style.btn_div}>
                {true && (
                    <button
                        className={Style.btn_edit}
                        onClick={handleBack}
                    >
                        {isEditNote ? 'Back' : 'Cancel'}
                    </button>
                )}
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className={Style.btn_submit}
                    disabled={disable}
                >
                    {isEditNote ? 'Save' : 'Submit'}
                </button>
            </div>
        </>
    );
}

export default Note;