import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import style from "./EditClient.module.css";
import Sidebar from "../component/SideBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { api } from "../../CommonApi/api";
import { get, post } from "../../CommonApi/axiosCall";
import { Toastify } from "../../../../App";

const EditClient = () => {
  const [required, setRequired] = useState(false);
  const [clientData, setClientData] = useState([]);
  const history = useHistory();
  const [disable, setDisable] = useState(false);
  const [state, setState] = useState({
    description: clientData.description,
    domain: clientData.domain,
    name: clientData.name,
  });

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const id = query.get("id");

  const getClientDetails = (id) => {
    let baseURL = api.getClientDetails;
    if (id) {
      baseURL += `?id=${id}`;
    }

    get(baseURL)
      .then((res) => {
        setClientData(res.data.responseData);
        setState({
          description: res.data.responseData.description,
          domain: res.data.responseData.domain,
          name: res.data.responseData.name,
        });
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getClientDetails(id);
  }, [id]);

  const onInputChange = (e) => {
    if (e.target.name === "clientname") {
      e.target.setCustomValidity("");
      setRequired(false);
      setState({
        ...state,
        name: e.target.value,
      });
    } else if (e.target.name === "domain") {
      setState({
        ...state,
        domain: e.target.value,
      });
    }
  };
  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/clients");
    }, 2000);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (state.name.trim().length <= 0) {
      setRequired(true);
      return false;
    }
    const data = {
      clientid: id,
      domain: state.domain,
      name: state.name.trim(),
    };
    setDisable(true);

    let baseURL = api.editClient;

    post(baseURL, data)
      .then((res) => {
        Toastify("Data Submitted Successfully");
        handleRedirect();
      })
      .catch((err) => { });
  };

  return (
    <>
      <div className={style.main_client_body}>
        <Sidebar />
        <form className={style.table_div} onSubmit={onSubmit}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>
            <div style={{ padding: "10px 0px", fontWeight: "700" }}>
              Edit Client
            </div>
            <div className={style.form_div}>
              <div id="create_task_id" className={style.gridContainer}>
                <Col>
                  <label className={style.assignLabel}>
                    <div className={style.font15}>
                      Client Name <span style={{ color: "red" }}>*</span>
                    </div>
                    <input
                      className={style.input}
                      autoComplete="off"
                      defaultValue={clientData?.name}
                      type="input"
                      name="clientname"
                      onChange={onInputChange}
                    />
                    {required ? (
                      <p className={style.error}>Please Enter Client Name</p>
                    ) : null}
                  </label>
                </Col>
                <Col>
                  <label className={style.descLabel}>
                    <div className={style.font15}>Client Domain </div>
                    <input
                      autoComplete="off"
                      className={style.input}
                      type="text"
                      defaultValue={clientData?.domain}
                      name="domain"
                      onChange={onInputChange}
                    />
                  </label>
                </Col>
              </div>
            </div>
          </div>
          <div className={style.btn_div}>
            <button
              type="submit"
              className={style.submitbtn}
              disabled={disable}
            >
              Update
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default EditClient;
