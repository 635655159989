import dropdownIcon from "../../../assets/downIcon.png";

export const customStyles = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    width: "20vw",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#a15e5e",
    },
    backgroundColor: state.isSelected ? "#a15e5e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};
export const customStyles2 = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    border: "none",
    opacity: state.isDisabled ? "0.2" : "1",
    width: "65%",
    minWidth: "40vw",
    margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    overflowX: "auto",
    flexWrap: "set",
    // "&::-webkit-scrollbar": {
    //   height: "10px",
    //   width: "300px",
    // },
    // overflowY: "scroll",
    // "::-webkit-scrollbar": {
    //   width: "4px",
    //   height: "0px",
    // },
    // "::-webkit-scrollbar-track": {
    //   background: "#f1f1f1",
    // },
    // "::-webkit-scrollbar-thumb": {
    //   background: "#888",
    // },
    // "::-webkit-scrollbar-thumb:hover": {
    //   background: "#555",
    // },
  }),
  multiValue: (base) => ({
    ...base,
    flex: "0 0 auto",
    justifyContent: "center",
  }),
  // clearIndicator
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#a15e5e",
    },
    backgroundColor: state.isSelected ? "#a15e5e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
export const customSearch = {
  control: () => ({
    // margin: "0 7px",
    borderRadius: "5px",
    padding: "0 20px 0 10px",
    border: "1px solid #00000061",
    cursor: "pointer",
    backgroundColor: "#f5f6fa",
  }),
  placeholder: () => ({
    // display: "none",
    marginTop: "-30px",
    color: "hsl(0deg 2% 18%)",
  }),
  //  option color on hover green
  option: (provided, state) => console.log(state.isSelected)||({
    ...provided,
    "&:hover": {
      backgroundColor: "#a15e5e",
    },
    backgroundColor: state.isSelected ? "#a15e5e" : "white",
  }),
  // dropdown width
  menu: (provided) => ({
    ...provided,
    width: "92%",
    marginLeft: "0.6rem",
    zIndex: "999",
  }),
};

export const customSearchPosition = {
  control: () => ({
    margin: "0 10px",
    borderRadius: "5px",
    padding: "0 20px 0 10px",
    border: "1px solid rgba(0, 0, 0, 0.192)",
    width: "96%",
    cursor: "pointer",
  }),
  placeholder: () => ({
    // display: "none",
    marginTop: "-30px",
    color: "hsl(0deg 2% 18%)",
  }),
  //  option color on hover green
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#a15e5e",
    },
    backgroundColor: state.isSelected ? "#a15e5e" : "white",
  }),
  // dropdown width
  menu: (provided) => ({
    ...provided,
    width: "96%",
    marginLeft: "0.6rem",
    zIndex: "999",
  }),
};
export const customStyles3 = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    backgroundColor: state.isDisabled ? "#999" : "#f5f6fa",
    border: "none",
    // minWidth: "inherit",
    margin: "0px 1.5% 0px 1.7%",
    height: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#a15e5e",
    },
    backgroundColor: state.isSelected ? "#a15e5e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

export const CustomInput = () => {
  return (
    <span>
      <img
        src={dropdownIcon}
        style={{ position: "absolute", top: "32%", right: "3%" }}
        height="10"
      />
    </span>
  );
};

export const CustomInput2 = () => {
  return (
    <span>
      <img
        src={dropdownIcon}
        style={{ position: "absolute", top: "35%", right: "1%" }}
        height="10"
      />
    </span>
  );
};

export const customStylesERP = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: "f5f6fa",
    backgroundColor:
      // change color when disabled
      "#f5f6fa",
    opacity: "1",
    border: "none",
    // minWidth: "inherit",
    margin: "0px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    // width: "20vw",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#a15e5e",
    },
    backgroundColor: state.isSelected ? "#a15e5e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

export const customStylesERP2 = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: "f5f6fa",
    backgroundColor:
      // change color when disabled
      "#f5f6fa",
    opacity: "1",
    border: "none",
    // minWidth: "inherit",
    margin: "0px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    // width: "20vw",
    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#a15e5e",
    },
    backgroundColor: state.isSelected ? "#a15e5e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "Black !important",
    fontSize: "30px !important",
    // padding: "0 28px 0 0",
  }),
};
