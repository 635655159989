import axios from "axios";
const baseURL = process.env.REACT_APP_NEW_TIMESHEET_URL;
const rmsUrl = process.env.REACT_APP_RESOURCEMASTER_APP_URL;
const apikey2 = process.env.REACT_APP_EPR_APP_URL;
export const getProjectName = async () => {
  const response = await axios.get(`${rmsUrl}/getAllActiveProject`);
  return response.data.responseData;
};

export const getProjectLead = async () => {
  const response = await axios.get(`${baseURL}/get-all-project-leads`);
  return response.data.responseData;
};

export const plapproved = async (id) => {
  const response = await axios.put(
    `${baseURL}/pl-approval?plApprovalId=2&leadId=${id?.timeid}&timesheetId=${id?.RadioButton}`
  );
  return response.data;
};
export const plapproved1 = async (id) => {
  const response = await axios.put(
    `${baseURL}/pl-approval?plApprovalId=3&leadId=${id?.timeid}&timesheetId=${id?.RadioButton}&remarks=${id?.Textid}`
  );
  return response.data;
};
const baseURL1 = process.env.REACT_APP_RESOURCEMASTER_APP_URL;

export const getTaskType = async () => {
  const response = await axios.get(`${baseURL}/get-task-type`);
  return response.data.responseData;
};

export const getTimeSpent = async () => {
  const response = await axios.get(`${baseURL}/get-time-spent`);
  return response.data.responseData;
};

export const getAllActiveProject = async () => {
  const response = await axios.get(`${baseURL1}/getAllActiveProject`, {
    headers: {
      Authorization: null,
    },
  });
  return response.data.responseData;
};

export const getCheckEmpRole = async (id) => {
  const response = await axios.get(`${baseURL}/check-emp-role?empId=${id}`);
  return response?.data?.responseData;
};

export const createTimeSheet = async (data) => {
  const response = await axios.post(`${baseURL}/add-task`, data);
  return response.data.responseData;
};

export const EditTimeSheet = async (data) => {
  const response = await axios.put(`${baseURL}/edit-task`, data);
  return response.data.responseData;
};

export const getTimeSheetEmpId = async (empid, date) => {
  const response = await axios.get(
    `${baseURL}/view-my-timesheet?date=${date}&empId=${empid}`
  );
  return response.data.responseData;
};

export const getTimesheetId = async (timeId, empId) => {
  const response = await axios.get(
    `${baseURL}/view-task-details?date=${timeId}&empId=${empId}`
  );
  return response?.data?.responseData;
};

export const DeleteTimeSheet = async ({ timesheetId, requester }) => {
  const response = await axios.delete(
    `${baseURL}/deleteTimeSheet?requester=${requester}&timesheetId=${timesheetId}`
  );
  return response?.data?.responseData;
};

export const TeamTimeSheetData = async (date, leadId, limit, page, name) => {
  if (name) {
    const response = await axios.get(
      `${baseURL}/view-team-timesheet?date=${date}&leadId=${leadId}&limit=${limit}&page=${page}&name=${name}`
    );
    return response?.data?.responseData;
  } else {
    const response = await axios.get(
      `${baseURL}/view-team-timesheet?date=${date}&leadId=${leadId}&limit=${limit}&page=${page}`
    );
    return response?.data?.responseData;
  }
};

export const ViewTimeSheetByID = async (id) => {
  const response = await axios.get(
    `${baseURL}/view-timesheet-by-id?timesheetId=${id}`
  );
  return response?.data?.responseData;
};

export const ViewTeamTimeSheetBYID = async (date, empId, leadId) => {
  let url = `${baseURL}/view-task-details?date=${date}&empId=${empId}`;

  if (leadId) {
    url += `&leadId=${leadId}`;
  }

  const response = await axios.get(url);
  return response?.data?.responseData;
};

export const getEmployeeList = async () => {
  const response = await axios.get(`${apikey2}/get-active-employee-details`);
  return response.data.responseData;
};
