import React from "react";
import { CreateQuestionnaireCom } from "./component/CreateQuestionnaireCom";
import style from "./CreateQuestionnaire.module.css"
import QuestionnaireSidebar from "../sidebar/SideBar";
export const QuestionAdd =()=>{
    return(<>
       <div className={style.mainSection}>
         <QuestionnaireSidebar />
         <CreateQuestionnaireCom />
       </div>
     </>)
 }
