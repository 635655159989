import React from "react";
import style from "./SideBar.module.css";
import SRC_ASSET_MENU_MAIN from "../../../assets/assetsmangement/devices.png";
import { useHistory } from "react-router-dom";
import { NavLink, } from "react-router-dom";
import { useLocation } from "react-router";
import AssignmentIcon from '@mui/icons-material/Assignment';
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";



const QuestionnaireSidebar = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className={style.leftSection}>
    <div className={style.header}>
        <img
          alt="DashBoardImage"
          src={InnovationMLogo}
          className={style.dashboardImage}
          onClick={() => history.push("/dashboard")}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              history.push("/dashboard");
            }
          }}
          tabIndex={1}
        />
      </div>
      <button className={style.taskTracker}>
        <div>
          <img src={SRC_ASSET_MENU_MAIN} alt="img" />
        </div>

        <span>Client <br></br> Questionnaire</span>
      </button>
      
      
        <NavLink to="/QuestionList" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/QuestionList"||
              location.pathname === "/AddQuestionnaire"||
              location.pathname === "/EditQuestionnaire"||
              location.pathname === "/ViewQuestionnaire"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<GridViewRoundedIcon className={style.icon} />}
            <div className={style.heading}>Dashboard</div>
          </div>
        </NavLink>
        <NavLink to="/QuestionByPostion" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/QuestionByPostion" ||
              location.pathname === "/ViewByPosition"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<AssignmentIcon className={style.icon} />}
            <div className={style.heading}>View By Position</div>
          </div>
        </NavLink>
        <NavLink to="/QuestionBySkill" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/QuestionBySkill" ||
              location.pathname === "/ViewBySkills"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<AssignmentIcon className={style.icon} />}
            <div className={style.heading}>View By Skills</div>
          </div>
        </NavLink>
    
    </div>
  );
};

export default QuestionnaireSidebar;
