import React from 'react'
import style from './UploadFile.module.css';
import { FaFileUpload } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";

function UploadFile({ type, name, fileURL, placeholder, handleChange, removeFile }) {
  return (
    <>
      {fileURL ? (
        <div className={style.Cu_file}>
          <div className={style.rmpdf}>
            <a href={fileURL} target='_blank'><FaFileAlt
              style={{ cursor: 'pointer' }}
            />
            </a>
          </div>
          <p
            onClick={() => {
              removeFile();
            }}
            className={style.rmpdfcross}
          >
            x
          </p>
        </div>
      ) : (
        <div>
          <input
            className={style.upload_file}
            placeholder={`${placeholder
              ? placeholder
              : "no file selected"
              }`}
            id="fileUpload"
            type={type}
            name={name}
            style={{ display: "none" }}
            onChange={(e) => {
              handleChange(e.target.files[0]);
            }}
          />
          <label htmlFor="fileUpload" title='Upload Document' style={iconStyle}>
            <FaFileUpload />
          </label>
        </div>
      )}
    </>
  )
}
const iconStyle = {
  color: 'rgb(75, 89, 195)',
  fontSize: '30px',
  verticalAlign: 'baseline',
  cursor: 'pointer',
  marginBottom: '5px',
  marginRight: '10px',
};
export default UploadFile