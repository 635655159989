import * as XLSX from "xlsx";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import { getDashboardList } from "../../utils/api";
import moment from "moment";
import { useQuery } from "react-query";

const ReviewFileDownload = ({ filter }) => {
  const { refetch } = useQuery(
    ["getDashboardList"],
    () => getDashboardList({
      location: filter?.location?.map((item) =>
        item === 0 ? "All" : item === 1 ? "Onsite" : "Offshore"
      ),
      empId: filter?.empId,
      employeeType: [...filter?.employeeType],
      functionalArea: [...filter?.functionalArea],
      leadId: filter?.leadId,
      year: filter?.year,
    }, filter?.limit, filter?.page),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        handleDownloadButtonClick(res?.response);
      },
    }
  );

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Code",
        "Name",
        "DOJ",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    ];

    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];

    data.forEach((employee) => {
      const dojDate = employee.doj ? moment(employee.doj) : null;
      const dojMonth = dojDate ? dojDate.month() + 1 : null; 
      const dojYear = dojDate ? dojDate.year() : null;

      const row = [
        employee.empCode,            
        employee.empName,            
        dojDate ? dojDate.format("DD MMM YY") : "N/A"  
      ];

      
      monthNames.forEach((month, index) => {
        const currentMonth = index + 1; 
        const ratingObj = employee.monthWishResponse.find(obj => obj.month === currentMonth);
        const rating = ratingObj?.rating;

        const status = ratingObj?.status;
        const statusToShow = ["PIP", "Reward", "Appreciation", "Feedback"].includes(status) ? status : null;

        
        const isBeforeDOJ = dojDate && (
          filter.year < dojYear || 
          (filter.year === dojYear && currentMonth < dojMonth)
        );
        const cellValue = isBeforeDOJ ? "N/A" : (rating || "");
        row.push(statusToShow ? `${cellValue} (${statusToShow})` : cellValue);
      });
      sheetData.push(row);
    });

    
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    return workbook;
  }

  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `PMS Dashboard${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }

  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = generateExcelData(data);
    downloadExcelFile(workbook);
  }

  return (
    <div>
      <FiDownload
        style={{ verticalAlign: "baseline", color: "#A15E5E" }}
        onClick={() => refetch()}
      />
    </div>
  );
};

export default ReviewFileDownload;
