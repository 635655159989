import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadExpInvFile } from '../../../../Utils/axios/expenceApi';

const ImageDropzone = ({ images, setImages }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    const validFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

    if (validFiles.length === 0) {
      console.error('No valid image files dropped');
      return;
    }

    try {
      // Upload images
      const responseData = await uploadExpInvFile(validFiles);
  
      // Add uploaded images to state
      setImages((prevImages) => [
        ...prevImages,
        ...responseData.map((imageUrl) => ({
          name: imageUrl.substring(imageUrl.lastIndexOf('/') + 1),
          preview: imageUrl
        }))
      ]);
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  }, [setImages]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxSize: 2 * 1024 * 1024 // 2 MB
  });

  const handleRemoveImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };

  return (
    <div {...getRootProps()} style={dropzoneStyles}>
      <input {...getInputProps()} />
      <p>Drag & drop images here, or click to select images</p>
      {images.length > 0 && (
        <div style={imagePreviewContainer}>
          {images.map((file, index) => (
            <div key={index} style={imagePreviewItem}>
              <img
                src={file.preview}
                alt={file.name}
                style={imagePreview}
              />
              <button onClick={() => handleRemoveImage(index)} style={removeButtonStyles}>
                &times;
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer'
};

const imagePreviewContainer = {
  display: 'flex',
  marginTop: '20px',
  flexWrap: 'wrap'
};

const imagePreviewItem = {
  position: 'relative',
  marginRight: '10px',
  marginBottom: '10px'
};

const imagePreview = {
  maxWidth: '100px',
  maxHeight: '100px'
};

const removeButtonStyles = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  background: 'red',
  color: 'white',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  fontSize: '16px',
  width: '24px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export default ImageDropzone;
