import React from "react";
import style from "./customTooltip.module.css";
const ATSContainer = ({ children, styles }) => {
  return (
    <div className={style.containers} style={styles}>
      {children}
    </div>
  );
};

export default ATSContainer;
