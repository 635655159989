import React from "react";
import style from "./header.module.scss";
import cookie from "react-cookies";
import logo from "./../../../assets/innovationm-navbar-logo.png";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";

export default function Header() {
  const profile = cookie.load("profile");
  const history = useHistory();
  function onLogout() {
    cookie.remove("token");
    history.push("/");
  }
  return (
    <div className={style.header}>
      <img alt="logo" src={logo} className={style.logo} />
      <div className={style.heading}>Welcome to IM Portal Apps <div style={{textAlign:"center", fontSize:"0.78rem"}}>{moment().format("ddd, D MMM YYYY")}</div></div>
      <div className={style.profileSection}>
        <img
          className={style.profileImage}
          alt={profile.givenName}
          src={profile.imageUrl}
        />
        <label className={style.logout} onClick={onLogout}>
          Logout
        </label>
      </div>
    </div>
  );
}
