import React, { forwardRef } from 'react';
import icon from '../../../../assets/calblue.png';

const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
  <div style={{ position: 'relative', display: 'inline-block' }}>
    <input
      type="text"
      value={value}
      onClick={onClick}
      ref={ref}
      readOnly
      style={{ paddingRight: '30px', width: '100%' }}
      className="customDatePickerInput"
    />
    <img
      src={icon}
      alt="calendar icon"
      onClick={onClick}  // Add onClick event to the image
      style={{
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        height: '20px',
        width: '20px',
        cursor: 'pointer'  // Change cursor to pointer to indicate it's clickable
      }}
    />
  </div>
));

export default CustomDateInput;
