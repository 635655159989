import React from "react";
import Select from "react-select";
import * as Img from "../../assets/index";
import { dropdownStyle } from "./dropdownStyle";
import PropTypes from "prop-types";

function CommonDropdown({
  placeholder,
  value,
  customInputOnClick,
  onChange,
  options,
  color,
  disabled,
}) {
  return (
    <Select
      styles={dropdownStyle(color)}
      placeholder={placeholder}
      components={{
        DropdownIndicator: () => (
          <span>
            <img
              src={value ? Img.cross : Img.arrow}
              alt="dropdownIcon"
              style={{
                position: "absolute",
                top: "40%",
                right: "5%",
              }}
              height="8"
              onClick={customInputOnClick}
            />
          </span>
        ),
        IndicatorSeparator: () => null,
      }}
      isDisabled={disabled}
      value={value}
      onChange={onChange}
      options={options}
      menuPosition="fixed"
    />
  );
}

CommonDropdown.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.object,
  customInputOnClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
};

CommonDropdown.defaultProps = {
  value: null,
  customInputOnClick: () => {},
};

export default CommonDropdown;
export function CommonDropdown1({
  placeholder,
  values, // Change the prop name from "value" to "values"
  customInputOnClick,
  onChange,
  options,
  color,
  disabled,
}) {
  return (
    <Select
      styles={dropdownStyle(color)}
      placeholder={placeholder}
      components={{
        DropdownIndicator: () => (
          <span>
            <img
              src={values.length > 0 ? Img.cross : Img.arrow} // Check if values array has items
              alt="dropdownIcon"
              style={{
                position: "absolute",
                top: "40%",
                right: "5%",
              }}
              height="8"
              onClick={customInputOnClick}
            />
          </span>
        ),
        IndicatorSeparator: () => null,
      }}
      isDisabled={disabled}
      value={values} // Change from "value" to "values"
      onChange={onChange}
      options={options}
      menuPosition="fixed"
      isMulti // Add the isMulti prop to enable multi-select
    />
  );
}

CommonDropdown1.propTypes = {
  placeholder: PropTypes.string.isRequired,
  values: PropTypes.array, // Change from object to array for multi-select
  customInputOnClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
};

CommonDropdown1.defaultProps = {
  values: [], // Set default value as an empty array for multi-select
  customInputOnClick: () => {},
};



