import React,{ useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import buttonimg from "../../../../assets/AppraisalPortal/performanceAppraisal.png";
import InnovationMLogo from "../../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import { menuItems } from "./data";
import style from "./AppraisalSystemSidebar.module.css";

const AppraisalSystemSidebar = () => {
  const location = useLocation();
  let history = useHistory();
  const [buttonHover, setButtonHover] = useState(false);

  const isActiveMenuItem = (validPaths) => {
    return validPaths.some((path) => location.pathname.includes(path));
  };
  return (
    <div className={style.leftSection}>
      <img
        alt="DashBoardImage"
        src={InnovationMLogo}
        className={style.dashboardImage}
        onClick={() => history.push("/dashboard")}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Space') {
            history.push("/dashboard")
          }
        }}
        aria-label="Open Project Comments" // Provide an accessible name
      />
      <button className={style.btn}>
        <div>
          <img src={buttonimg} alt="img" />
        </div>

        <span>Performance <br/>Appraisal</span>
      </button>

      <div className={style.side_buttons_div}>
        {/* coming from data.js */}
        {menuItems.map((i, index) => {
            return (
              <NavLink to={i.path} style={{ textDecoration: "none" }} key={`${index}${i.heading}`}>
                <div
                  className={
                    // check if path matches or index match to hover one
                    isActiveMenuItem(i.validPaths) || buttonHover === index
                      ? style.active_btn
                      : style.deactivate_btn
                  }
                  // set index on hover
                  onMouseEnter={() => setButtonHover(index)}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  <img
                    src={
                      isActiveMenuItem(i.validPaths) || buttonHover === index
                        ? i.imgSrcSelected
                        : i.imgSrc
                    }
                    alt={i.heading}
                    className={style.icon}
                  />
                  <div
                    className={style.heading}
                    dangerouslySetInnerHTML={{ __html: i.heading }}
                  />
                </div>
              </NavLink>
            );
        })}
      </div>
    </div>
  );
};

export default AppraisalSystemSidebar;
