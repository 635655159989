import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../ViewPartner/ViewPartner.module.css";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton1";
import {
  validateEmployeeAccess,
  getViewVendor,
  deleteVendorById,
} from "../../../../Utils/axios/PortalManagement";
import { useMutation, useQuery } from "react-query";
import { useEffect } from "react";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import { employeeId } from "../../../../Utils/constants";
import { Toastify, ToastifyError } from "../../../../App";
import CopyEmail from "../../../../GenericComponent/CopyEmailFeature/CopyEmail";

const ViewVendor = ({ setNextScreen, prevData, setPrevData }) => {
  const { data: PartnerDataById, refetch } = useQuery(
    ["getViewPartner1"],
    () => getViewVendor(prevData?.venderId || prevData?.CreateId),
    {
      onSuccess: (res) => {
        setPrevData((prevData) => ({
          ...prevData,
          status: res?.statusId,
          specializationExistId: res?.specializationExist,
        }));
      },
    }
  );
  const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(employeeId)),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));

  useEffect(() => {
    refetch();
  }, [PartnerDataById]);

  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
      default:
        return;
    }
  };

  const mutation = useMutation(deleteVendorById, {
    onSuccess:()=>{
      Toastify("Partner Deleted Successfully");
      setNextScreen("PartnerList");
      setPrevData((prevData) => ({
        ...prevData,
        page2: "EditVendor",
        page1: "ViewPartner",
        page3: null,
        page4: null,
        active: "EditVendor",
      }));
    },
    onError: (res) => {
      ToastifyError("Something Went Wrong");
    },
  });

  return (
    <>
      <ToastContainer />
      <Container>
        <div>
          <BreadCrum
            prevData={prevData}
            setPrevData={setPrevData}
            setNextScreen={setNextScreen}
          ></BreadCrum>
        </div>
        <InvoiceContainer>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Vendor Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.venderName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Address</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.addressLine1}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>City</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.city}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>State</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.state}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Pin</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.pinCode}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Country</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.country}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>GSTIN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.gstin}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>PAN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.pan}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Payment Due Days</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.pamentDueDays}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>SLA</p>
              <p className={classes.partnerlabal}>
                {checkfileType(PartnerDataById?.sla?.split(".")[5])}
                <a
                  className={classes.msaopen}
                  href={PartnerDataById?.sla}
                  target="_blank"
                  rel="noreferrer"
                >
                  {PartnerDataById?.sla?.split("@")[1]}
                </a>
              </p>
            </Col>

            <Col md={3}>
              <p className={classes.label1}>GST Applicable</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.gstApplicable}
              </p>
            </Col>
            <Col md={3}></Col>
            <Col md={3}>
              <p className={classes.label1}>TDS Applicable</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.tdsApplicable}
              </p>
            </Col>
            {PartnerDataById?.tdsApplicable === "Yes" ? (
              <Col md={3}>
                <p className={classes.label1}>TDS Section</p>
                <p className={classes.partnerlabal}>
                  {PartnerDataById?.tdsSection}
                </p>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row></Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Primary Contact</h6>
            </Col>

            <Col md={3}>
              <p className={classes.label}>Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryContactName}
              </p>
            </Col>
            <Col style={{ width: "30%" }} md={3}>
              <p className={classes.label}>Email ID</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryContectEmail} <CopyEmail email={PartnerDataById?.primaryContectEmail} />
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Mobile Number</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryMobileNumber}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Secondary Contact</h6>
            </Col>

            <Col md={3}>
              <p className={classes.label}>Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyContactName}
              </p>
            </Col>
            <Col style={{ width: "30%" }} md={3}>
              <p className={classes.label}>Email ID</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyContectEmail} <CopyEmail email={PartnerDataById?.seconderyContectEmail} />
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Mobile Number</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyMobileNumber}
              </p>
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>

      <div className={classes.lastBtn_item}>
        <InvoiceButton
          onClick={() => {
            mutation.mutate(prevData?.venderId);
          }}
        >
          Delete
        </InvoiceButton>
        {getVData?.some((e) => {
          return e?.label === "131D1775O71917QR";
        }) && (
          <InvoiceButton
            onClick={() => {
              setNextScreen("EditVendor");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "EditVendor",
                page1: "ViewVendor",
                page3: null,
                page4: null,
                active: "EditVendor",
              }));
            }}
          >
            Edit
          </InvoiceButton>
        )}
        {getVData?.some((e) => {
          return e?.label === "131D1775O71917QR";
        }) && (
          <InvoiceButton
            onClick={() => {
              setNextScreen("PartnerList");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "EditVendor",
                page1: "ViewPartner",
                page3: null,
                page4: null,
                active: "EditVendor",
              }));
            }}
          >
            Done
          </InvoiceButton>
        )}
      </div>
    </>
  );
};
export default ViewVendor;
