import { path } from "../../../../routes/PathNames";
import * as img from "../../../../assets/index";
import resource from "../../../../assets/hr-icon.png"
import { projectCostingAndMarginAccessCode } from "../../../../Utils/constants";

export const menuItems = [
  {
    path: path.pcm_dashboard,
    imgSrc: img.projects,
    imgSrcSelected: img.projectsSelected,
    heading: "Projects (Offshore)",
    validPaths: [
      path.pcm_dashboard,
      path.pcm_editProject,
      path.pcm_invoices,
      path.pcm_editInvoice,
      path.pcm_addInvoice,
      path.pcm_projectCostingInformation,
      path.pcm_projectCostBreakUp,
    ],
    accessCode: projectCostingAndMarginAccessCode?.allProjects_dashboard,
  },
  {
    path: path.pcm_overheads,
    imgSrc: img.overhead,
    imgSrcSelected: img.overheadSelected,
    heading: "Overheads",
    validPaths: [path.pcm_overheads],
    accessCode: projectCostingAndMarginAccessCode?.overheads_dashboard,
  },
  {
    path: path.pcm_resourceMargin,
    imgSrc: resource,
    imgSrcSelected: resource,
    heading: "Resource Margins",
    validPaths: [path.pcm_resourceMargin],
    accessCode: projectCostingAndMarginAccessCode?.resourceMargins,
  },
];
