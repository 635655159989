import React, { useState, useEffect } from "react";
import style from "./EmployeeFeedback.module.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import dropdownIcon from "../../../../assets/arrowdownIcon.png";
import calender from "../../../../assets/calblue.png";
import DatePicker from "react-datepicker";

import moment from "moment";
import cancel from "../../../../assets/cancell.png";
import {
  customStyles2,
  customStylesERP,
  customStylesERP2,
} from "../../utils/style";

import StarRating from "../../rating/StartRating";
import OverallRating from "./commonRating/OverallRating";
import OverallRating2 from "./commonRating/OverallRating2";
import OverallRating3 from "./commonRating/OverallRating3";
import BreadCrum from "../../breadcrums/BreadCrum";
import { useMutation, useQuery } from "react-query";
import {
  getSkills,
  addSkill,
  uploadResume,
  deleteResume,
} from "../../../../Utils/axios/api";
import {
  getReviewSourcType,
  getReviewType,
  getEmployeeList,
  getClientForFilter,
  getFoxMatrixInterviewers,
  editPerformanceReviewFileType,
  getAllClientInterviewer,
  updatePerformanceReviewRating,
  editPerformanceReviewSubjectiveType,
  createPerformanceReviewTest,
  viewFeedback,
  getEmployeeInformation,
  getAllActiveProject,
} from "../../utils/api";
import { employeeId } from "../../../../Utils/constants";
function EditEmployeeFeedback({ setNextScreen, prevData, setPrevData }) {
  const { data: profileData1 } = useQuery(
    ["viewFeedback"],
    () => viewFeedback(prevData?.pReId),
    { refetchOnWindowFocus: false, removeAfterUnmount: true, cacheTime: 1000 }
  );

  const [validate, setValidate] = React.useState(false);
  const empId = employeeId;

  const [formData, setFormData] = React.useState({
    employeId: prevData?.empId !== "" ? prevData?.empId : "",
    employeeName: "",
    reviewDate: profileData1?.reviewDate,
    reviewSourceTypeId: profileData1?.reviewSourceTypeId,
    reviewTypeId: profileData1?.reviewTypeId,
    projectId: profileData1?.projectId,
    reviewedById: profileData1?.reviewedById,
    reviewedByName: profileData1?.reviewedByName,
    clientId: profileData1?.clientId ? profileData1?.clientId : "",
    onlinePlatformId: profileData1?.onlinePlatformId
      ? profileData1?.onlinePlatformId
      : "",
  });

  const [formDataRating, setFormDataRating] = useState({
    overallFeedback: profileData1?.overallFeedback,
    feedbackSkills: profileData1?.feedbackSkill?.map((e) => ({
      value: e.value,
      label: e.label,
      rating:
        e.rating === "NE" || e.rating === "NS" ? e.rating : Number(e.rating),
    })),
    performanceReviewId: prevData?.pReId,
    statusIdRating: profileData1?.statusIdRating,
    statusId: profileData1?.statusId,
    overallRatingValue: profileData1?.overallRatingValue,
  });
  const [formDataTest, setFormDataTest] = useState({
    testSkills: profileData1?.feedbackSkill,
    statusIdRating: profileData1?.statusIdRating,
    performanceReviewId: prevData?.pReId,
    overallRatingValue: profileData1?.statusId,
  });
  const [formDataSubjective, setFormDataSubjective] = useState({
    subjective: profileData1?.feedbackDescription,
    statusId: profileData1?.statusId,
    statusIdRating: profileData1?.statusIdRating,
    performanceReviewId: prevData?.pReId,
    overallRatingValue: profileData1?.overallRatingValue,
  });
  const [formDataFile, setFormDataFile] = useState({
    file: profileData1?.filePath,
    statusId: profileData1?.statusId,
    statusIdRating: profileData1?.statusIdRating,
    performanceReviewId: prevData?.pReId,
    overallRatingValue: profileData1?.overallRatingValue,
  });
  useEffect(() => {
    setFormData(() => ({
      employeId: prevData?.empId != "" ? prevData?.empId : "",
      employeeName: "",
      reviewDate: profileData1?.reviewDate,
      reviewSourceTypeId: profileData1?.reviewSourceTypeId,
      reviewTypeId: profileData1?.reviewTypeId,
      projectId: profileData1?.projectId,
      reviewedById: profileData1?.reviewedById,
      reviewedByName: profileData1?.reviewedByName,
      clientId: profileData1?.clientId ? profileData1?.clientId : "",
      onlinePlatformId: profileData1?.onlinePlatformId
        ? profileData1?.onlinePlatformId
        : "",
    }));
    setFormDataRating(() => ({
      overallFeedback: profileData1?.overallFeedback,
      feedbackSkills: profileData1?.feedbackSkill?.map((e) => ({
        value: e.value,
        label: e.label,
        rating:
          e.rating === "NE" || e.rating === "NS" ? e.rating : Number(e.rating),
      })),
      performanceReviewId: prevData?.pReId,
      statusIdRating: profileData1?.statusIdRating,
      overallRatingValue: profileData1?.overallRatingValue,
      statusId: profileData1?.statusId,
    }));
    setFormDataFile(() => ({
      file: profileData1?.filePath,
      statusId: profileData1?.statusId,
      overallRatingValue: profileData1?.overallRatingValue,
      statusIdRating: profileData1?.statusIdRating,
      performanceReviewId: prevData?.pReId,
    }));
    setFormDataSubjective(() => ({
      subjective: profileData1?.feedbackDescription,
      statusId: profileData1?.statusId,
      overallRatingValue: profileData1?.overallRatingValue,
      statusIdRating: profileData1?.statusIdRating,
      performanceReviewId: prevData?.pReId,
    }));
    setFormDataTest(() => ({
      testSkills: profileData1?.feedbackSkill,
      overallRatingValue: profileData1?.overallRatingValue,
      statusIdRating: profileData1?.statusIdRating,
      performanceReviewId: prevData?.pReId,
    }));
    profileData1?.reviewTypeId && refetch();
  }, profileData1);
  const [doc, setDoc] = useState("");

  const { data: getReviewData, refetch } = useQuery(
    ["getReviewType", formData?.reviewSourceTypeId],
    () =>
      getReviewType(Number(formData?.reviewSourceTypeId), {
        refetchOnWindowFocus: false,
        removeAfterUnmount: true,
        cacheTime: 1000,
        enabled: false,
      })
  );

  const { data: getEmployeeList2 } = useQuery(
    ["getEmployeeInformation"],
    getEmployeeInformation
  );

  const getEmployeeListOption2 =
    getEmployeeList2?.length > 1 &&
    getEmployeeList2?.map((i) => ({
      value: i?.id,
      label: i?.name + " (" + i?.employeeCode + ") ",
    }));

  const { data: getClientForFilterList } = useQuery(
    ["getClientForFilter"],
    getClientForFilter,
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const { data: getFoxMatrixInterviewersList } = useQuery(
    ["getFoxMatrixInterviewers"],
    getFoxMatrixInterviewers,
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const { data: getAllClientInterviewers } = useQuery(
    ["getAllClientInterviewer"],
    getAllClientInterviewer
  );

  const getAllClientInterviewersOption = getAllClientInterviewers?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const { data: getEmployeeLists } = useQuery(
    ["getEmployeeList"],
    getEmployeeList,
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const { data: getResourceTypes } = useQuery(
    ["getReviewSourcType"],
    getReviewSourcType,
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const { data: getSkillList } = useQuery(["getSkills"], getSkills);

  const getReviewSourcTypeOption = getResourceTypes?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getReviewTypesOption =
    getReviewData?.length > 0
      ? typeof getReviewData !== "string" &&
        getReviewData?.map((i) => ({
          value: i?.id,
          label: i?.reviewType,
        }))
      : null;

  const getClientListOption = getClientForFilterList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getFoxMatrixInterviewersListOption = getFoxMatrixInterviewersList?.map(
    (i) => ({
      value: i.id,
      label: i.name,
    })
  );

  const { data: getAllActiveProjectList } = useQuery(
    ["getAllActiveProject"],
    getAllActiveProject
  );

  const getAllActiveProjectListOption = getAllActiveProjectList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getEmployeeListOption1 =
    getEmployeeLists?.employeeList?.length > 1 &&
    getEmployeeLists?.employeeList?.map((i) => ({
      value: i?.id,
      label: i?.name,
    }));

  const getSkillListOption = Array.isArray(getSkillList)
    ? getSkillList?.map((i) => ({
        value: i.skillId,
        label: i.coreSkillName,
      }))
    : [];

  const onlineOption = [
    {
      label: "Eval Ground",
      value: 1,
    },
  ];

  const uploadDocMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setFormDataFile({ ...formDataFile, file: data.toString() });
    },
  });

  useEffect(() => {
    setDoc(formDataFile?.file);
  }, [formDataFile?.file]);

  const mutationSubjective = useMutation(editPerformanceReviewSubjectiveType, {
    onMutate: () => {
      // setDisabled(true);
    },
    onSuccess: (res) => {
      setNextScreen("viewFeedback");
      setPrevData((prevData) => ({
        ...prevData,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeePerformanceReview",
        page1: "EmployeePerformanceReview",
        page2: "EmployeeFeedbackList",
        page3: "viewFeedback",
        page4: null,
        active: "viewFeedback",
      }));
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });
  const mutationFileType = useMutation(editPerformanceReviewFileType, {
    onMutate: () => {
      // setDisabled(true);
    },
    onSuccess: (res) => {
      setNextScreen("viewFeedback");
      setPrevData((prevData) => ({
        ...prevData,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeePerformanceReview",
        page1: "EmployeePerformanceReview",
        page2: "EmployeeFeedbackList",
        page3: "viewFeedback",
        page4: null,
        active: "viewFeedback",
      }));
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });
  const mutationReviewRating = useMutation(updatePerformanceReviewRating, {
    onMutate: () => {
      // setDisabled(true);
    },
    onSuccess: (res) => {
      setNextScreen("viewFeedback");
      setPrevData((prevData) => ({
        ...prevData,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeePerformanceReview",
        page1: "EmployeePerformanceReview",
        page2: "EmployeeFeedbackList",
        page3: "viewFeedback",
        page4: null,
        active: "viewFeedback",
      }));
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });
  const mutationReviewTest = useMutation(createPerformanceReviewTest, {
    onMutate: () => {
      // setDisabled(true);
    },
    onSuccess: (res) => {
      setNextScreen("viewFeedback");
      setPrevData((prevData) => ({
        ...prevData,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeePerformanceReview",
        page1: "EmployeePerformanceReview",
        page2: "EmployeeFeedbackList",
        page3: "viewFeedback",
        page4: null,
        active: "viewFeedback",
      }));
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });

  const onfeedbackTypeIdOne = () => {
    if (formDataRating?.overallFeedback && formDataRating?.feedbackSkills?.length) {
      let data = {
        employeId: formData?.employeId,
        employeeName:
          getEmployeeListOption2 &&
          getEmployeeListOption2?.find(
            (obj) => obj.value === formData?.employeId
          )?.label,
        feedbackTypeId: profileData1?.feedbackTypeId,
        reviewDate: formData?.reviewDate,
        reviewSourceTypeId: formData?.reviewSourceTypeId,
        projectId: formData?.projectId,
        reviewTypeId: formData?.reviewTypeId,
        reviewedById: formData?.reviewedById,
        ...(formData?.reviewSourceTypeId == 4 ||
        formData?.reviewSourceTypeId == 2
          ? {}
          : {
              reviewedById: formData?.reviewedById,
            }),
        ...(formData?.reviewSourceTypeId == 2 && {
          clientId: formData?.clientId,
        }),
        ...(formData?.reviewSourceTypeId == 2
          ? {
              reviewedByName: formData?.reviewedByName,
            }
          : null),
        ...(formData?.reviewSourceTypeId == 4 && {
          onlinePlatformId: formData?.onlinePlatformId,
        }),
        ...formDataRating,
      };
      setValidate(false);

      mutationReviewRating.mutate({ ...data, updatedById: empId });
    } else {
      setValidate(true);
    }
  };

  const onfeedbackTypeIdTwo = () => {
    let data = {
      employeId: formData?.employeId,
      employeeName:
        getEmployeeListOption2 &&
        getEmployeeListOption2?.find((obj) => obj.value === formData?.employeId)
          ?.label,
      feedbackTypeId: profileData1?.feedbackTypeId,
      reviewDate: formData?.reviewDate,
      reviewSourceTypeId: formData?.reviewSourceTypeId,
      reviewTypeId: formData?.reviewTypeId,
      projectId: formData?.projectId,
      reviewedById: formData?.reviewedById,
      ...(formData?.reviewSourceTypeId == 4 || formData?.reviewSourceTypeId == 2
        ? {}
        : {
            reviewedById: formData?.reviewedById,
          }),
      ...(formData?.reviewSourceTypeId == 2 && {
        clientId: formData?.clientId,
      }),
      ...(formData?.reviewSourceTypeId == 2
        ? {
            reviewedByName: formData?.reviewedByName,
          }
        : null),
      ...(formData?.reviewSourceTypeId == 4 && {
        onlinePlatformId: formData?.onlinePlatformId,
      }),
      ...formDataTest,
    };

    setValidate(false);
    mutationReviewTest.mutate({ ...data, updatedById: empId });
  };

  const onfeedbackTypeThree = () => {
    let data = {
      employeId: formData?.employeId,
      employeeName:
        getEmployeeListOption2 &&
        getEmployeeListOption2?.find((obj) => obj.value === formData?.employeId)
          ?.label,
      feedbackTypeId: profileData1?.feedbackTypeId,
      reviewDate: formData?.reviewDate,
      projectId: formData?.projectId,
      reviewSourceTypeId: formData?.reviewSourceTypeId,
      reviewTypeId: formData?.reviewTypeId,
      ...(formData?.reviewSourceTypeId == 4 || formData?.reviewSourceTypeId == 2
        ? {}
        : {
            reviewedById: formData?.reviewedById,
          }),
      ...(formData?.reviewSourceTypeId == 2 && {
        clientId: formData?.clientId,
      }),
      ...(formData?.reviewSourceTypeId == 2
        ? {
            reviewedByName: formData?.reviewedByName,
          }
        : null),
      ...(formData?.reviewSourceTypeId == 4 && {
        onlinePlatformId: formData?.onlinePlatformId,
      }),
      ...formDataFile,
    };
    setValidate(false);
    mutationFileType.mutate({ ...data, updatedById: empId });
  };

  const onfeedbackTypeIdFour = () => {
    if (formDataSubjective?.subjective.trim()) {
      let data = {
        employeId: Number(formData?.employeId),
        employeeName:
          getEmployeeListOption2 &&
          getEmployeeListOption2?.find(
            (obj) => obj.value === formData?.employeId
          )?.label,
        feedbackTypeId: profileData1?.feedbackTypeId,
        reviewDate: formData?.reviewDate,
        projectId: formData?.projectId,
        reviewSourceTypeId: formData?.reviewSourceTypeId,
        reviewTypeId: formData?.reviewTypeId,
        // reviewedById: formData?.reviewedById,
        ...(formData?.reviewSourceTypeId == 4 ||
        formData?.reviewSourceTypeId == 2
          ? {}
          : {
              reviewedById: formData?.reviewedById,
            }),
        ...(formData?.reviewSourceTypeId == 2 && {
          clientId: formData?.clientId,
        }),
        ...(formData?.reviewSourceTypeId == 2
          ? {
              reviewedByName: formData?.reviewedByName,
            }
          : null),
        ...(formData?.reviewSourceTypeId == 4 && {
          onlinePlatformId: formData?.onlinePlatformId,
        }),
        // ...(prevData?.empId != undefined && {
        //   id: prevData?.empId,
        // }),
        ...formDataSubjective,
      };
      setValidate(false);

      mutationSubjective.mutate({ ...data, updatedById: empId });
    } else {
      setValidate(true);
    }
  };

  const onCreateHandler = () => {
    if (
      formData?.employeId &&
      formData?.reviewDate &&
      formData?.reviewTypeId &&
      formData?.reviewSourceTypeId &&
      (
        (
          (formData?.reviewSourceTypeId === 1  ||
          formData?.reviewSourceTypeId === 3 ||
          formData?.reviewSourceTypeId === 5 ||
          formData?.reviewSourceTypeId === 6 ||
          formData?.reviewSourceTypeId === 7 ||
          formData?.reviewSourceTypeId === 8) && 
          formData?.reviewedById) ||
        (formData?.reviewSourceTypeId === 2 && formData?.clientId) ||
        (formData?.reviewSourceTypeId === 4 && formData?.onlinePlatformId)
      )
    ) {
      //FileType
      if (profileData1?.feedbackTypeId == 3) {
        onfeedbackTypeThree();
      }
      //Rating type
      else if (profileData1?.feedbackTypeId == 1) {
        onfeedbackTypeIdOne();
      }
      //test Type
      else if (profileData1?.feedbackTypeId == 2) {
        onfeedbackTypeIdTwo();
      }
      //Subjective Type
      else if (profileData1?.feedbackTypeId == 4) {
        onfeedbackTypeIdFour();
      }
    } else {
      setValidate(true);
    }
  };

  const mutationAdd = useMutation(addSkill, {
    onSuccess: (res) => {
      let selected = formData?.testSkills;
      selected.pop();
      selected = [...selected, { value: res?.id, label: res?.skillName }];
      let items = {};
      selected?.forEach((element) => {
        if (!items[element?.value]) {
          items[element?.value] = element;
        }
      });
      let newData = Object.values(items);

      setFormData({
        ...formData,
        testSkills: newData,
      });
    },
  });

  const mutationRating = useMutation(addSkill, {
    onSuccess: (res) => {
      let selected = formDataRating?.feedbackSkills;

      selected.push({ value: res?.id, label: res?.skillName });
      setFormDataRating({
        ...formDataRating,
        feedbackSkills: selected,
      });
    },
  });

  const isSameUser = (a, b) => a?.value === b?.value && a?.label === b?.label;
  const handleCross = (left, right, compareFunction) => {
    if (!left) {
      return;
    }
    return left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );
  };

  const handleSelect = (e) => {
    setFormDataRating({
      ...formDataRating,
      feedbackSkills: e,
    });
    let prevSelect = handleCross(formDataRating?.feedbackSkills, e, isSameUser);
    if (prevSelect?.length > 0) {
      return;
    }
    if (
      getSkillListOption?.some((item) => item?.label == e[e.length - 1]?.label)
    ) {
    } else {
      if (e?.length > 0) {
        setTimeout(() => {
          mutationRating.mutate({
            skill: e[e.length - 1]?.label,
          });
        }, 1000);
      }
    }
  };

  const handleSelect1 = (e) => {
    setFormDataTest({
      ...formDataTest,
      testSkills: e,
    });

    let prevSelect = handleCross(formDataTest?.testSkills, e, isSameUser);
    if (prevSelect?.length > 0) {
      return;
    }
    if (
      getSkillListOption?.some((item) => item?.label == e[e.length - 1]?.label)
    ) {
    } else {
      if (e?.length > 0) {
        setTimeout(() => {
          mutationAdd.mutate({
            skill: e[e.length - 1]?.label,
          });
        }, 1000);
      }
    }
  };

  const mainOption = () => {
    if (formData?.reviewSourceTypeId == 1) {
      return getFoxMatrixInterviewersListOption;
    } else if (formData?.reviewSourceTypeId == 2) {
      return getAllClientInterviewersOption;
    } else {
      return getEmployeeListOption1;
    }
  };

  const optionsSub = mainOption();
  // get OverAllScore
  const getOverAllScore = formDataTest?.testSkills?.map((item) =>
    Number(item?.scoreObtained)
  );
  const totalOverAllScore = getOverAllScore?.reduce((acc, currentValue) => {
    if (currentValue) {
      return acc + currentValue;
    } else {
      return acc;
    }
  }, 0);

  //get maximum score
  const getMaxAllScore = formDataTest?.testSkills?.map((item) =>
    Number(item?.maxScore)
  );
  const totalMaxAllScore = getMaxAllScore?.reduce((acc, currentValue) => {
    if (currentValue) {
      return acc + currentValue;
    } else {
      return acc;
    }
  }, 0);

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
        />
      </div>
    );
  });

  //To create error message
  const errMessage = (data, message) => {
    return (
      <p
        className={validate && !data ? style.errorText : style.errorTextHidden}
      >
        {message}
      </p>
    );
  };

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="10px"
          height="10px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
        />
      </div>
    );
  });

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };
  const CustomInput2 = () => {
    return formData?.projectId == "" || formData?.projectId == null || formData?.projectId == 0 ? (
        <span>
          <img
            src={dropdownIcon}
            alt="dropdownIcon"
            style={{ position: "absolute", top: "32%", right: "3%" }}
            height="10"
          />
        </span>
      
    ) : null;
  };
  
  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      ></BreadCrum>
      {/* first */}
      <div className={style.card}>
        <p className={style.title}>Employee Details</p>
        <div className={style.Cu_row1}>
          <div className={style.cu_col1}>
            <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
              Employee Name <span className={style.mand}>*</span>
            </p>
            <input
              className={style.input}
              readOnly
              value={
                getEmployeeListOption2 &&
                getEmployeeListOption2?.find(
                  (obj) => obj.value == prevData?.empId
                )?.label
                // getEmployeeListOption2 &&
                // getEmployeeListOption2?.find(
                //   (obj) => obj.value == formData?.employeId
                // )?.label
                //   ? getEmployeeListOption2 &&
                //     getEmployeeListOption2?.find(
                //       (obj) => obj.value === formData?.employeId
                //     )?.label
                //   : ""
              }
            />
            {/* <Select
              styles={customStylesERP}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              options={getEmployeeListOption}
              onChange={(e) =>
                setFormData({ ...formData, employeId: e?.label })
              }
              isDisabled={prevData?.empId === undefined ? false : true}
              value={
                getEmployeeListOption &&
                getEmployeeListOption?.find(
                  (obj) => obj.label === prevData?.empId
                )
              }
            /> */}
            <p
              className={
                validate && !formData?.employeId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Employee Name
            </p>
          </div>
          {/* <div className={style.cu_col2}>
            <p className={style.cu_label}>Employee Name</p>
            <input
              className={style.input}
              readOnly
              value={
                getEmployeeListOption &&
                getEmployeeListOption?.find(
                  (obj) => obj.label === formData?.employeId
                )?.value
                  ? getEmployeeListOption &&
                    getEmployeeListOption?.find(
                      (obj) => obj.label === formData?.employeId
                    )?.value
                  : ""
              }
            />
          </div> */}
        </div>
      </div>
      {/* second */}
      <div className={style.card}>
        <p className={style.title}>Reviewer</p>
        <div className={style.Cu_row2}>
          <div className={style.cu_col1}>
            <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
              Review Date <span className={style.mand}>*</span>
            </p>
            <div className={style.cu_field23Last}>
              <DatePicker
                placeholder=" DD MM YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={
                  formData?.reviewDate
                    ? moment(formData?.reviewDate).toDate()
                    : null
                }
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    reviewDate: date,
                  });
                }}
                dateFormat="dd MMM yy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                customInput={
                  formData?.reviewDate ? (
                    <CustomInputCross properties="reviewDate" />
                  ) : (
                    <CustomInputClg />
                  )
                }
              />
            </div>
            <p
              className={
                validate && !formData?.reviewDate
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Reviewed Date
            </p>
          </div>
          <div className={style.cu_col1}>
            <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
              Review Source <span className={style.mand}>*</span>
            </p>
            <Select
              styles={customStylesERP}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              options={getReviewSourcTypeOption}
              onChange={(e) => {
                setValidate(false)
                setFormData({
                  ...formData,
                  reviewSourceTypeId: e?.value,
                  reviewTypeId: "",
                  reviewedById: "",
                  clientId: e.value != 2 && "",
                  onlinePlatformId: e.value != 4 && "",
                });
              }}
              value={getReviewSourcTypeOption?.find(
                (i) => i.value === formData.reviewSourceTypeId
              )}
            />
            <p
              className={
                validate && !formData?.reviewSourceTypeId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Review Source
            </p>
          </div>
          <div className={style.cu_col1}>
            <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
              Review Type <span className={style.mand}>*</span>
            </p>
            <Select
              styles={customStylesERP}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              options={getReviewTypesOption}
              onChange={(e) =>
                setFormData({ ...formData, reviewTypeId: e?.value })
              }
              value={
                (getReviewTypesOption &&
                  getReviewTypesOption?.find(
                    (i) => i.value === formData.reviewTypeId
                  )) || { value: "" }
              }
            />

            <p
              className={
                validate && !formData?.reviewTypeId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Review Type
            </p>
          </div>
          <div className={style.cu_col1}>
            {formData?.reviewSourceTypeId == 2 ? (
              <>
                <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
                  Client Name <span className={style.mand}>*</span>
                </p>
                <Select
                  styles={customStylesERP}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  options={getClientListOption}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      clientId: e?.value,
                    })
                  }
                  value={
                    (getClientListOption &&
                      getClientListOption?.find(
                        (i) => i.value === formData.clientId
                      )) || { value: "" }
                  }
                />
                <p
                  className={
                    validate && !formData?.clientId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Client Name
                </p>
              </>
            ) : null}
            {formData?.reviewSourceTypeId == 4 ? (
              <>
                <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
                  Online Platform <span className={style.mand}>*</span>
                </p>
                <Select
                  styles={customStylesERP}
                  classNamePrefix={"create_feedback"}
                  menuPlacement="bottom"
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    (onlineOption &&
                      onlineOption?.find(
                        (i) => i.value === formData.onlinePlatformId
                      )) || { value: "" }
                  }
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      onlinePlatformId: e?.value,
                    })
                  }
                  options={onlineOption}
                />
                <p
                  className={
                    validate && !formData?.onlinePlatformId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Online Platform
                </p>
              </>
            ) : null}
            {formData?.reviewSourceTypeId == 2 ||
            formData?.reviewSourceTypeId == 4 ? null : (
              <>
                <>
                  <p
                    className={style.cu_label}
                    style={{ paddingBottom: "5px" }}
                  >
                    Reviewed By <span className={style.mand}>*</span>
                  </p>
                  <Select
                    styles={customStylesERP}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    options={mainOption()}
                    onChange={(e) =>
                      setFormData({ ...formData, reviewedById: e?.value })
                    }
                    value={
                      formData?.reviewedById && formData?.reviewedById
                        ? mainOption()?.find(
                            (e) => e.value == formData?.reviewedById
                          )
                        : null
                    }
                  />
                  {errMessage(
                    formData?.reviewedById,
                    "Please Select Reviewed By"
                  )}
                  
                </>
              </>
            )}
          </div>
        </div>
        <div className={style.Cu_row2}>
          <div className={style.cu_col1} style={{ marginTop: "-1%" }}>
            <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
              Project Name
            </p>
            <Select
              styles={customStylesERP2}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput2(),
                IndicatorSeparator: () => null,
              }}
              isClearable={formData?.projectId == undefined || formData?.projectId===0 ? false : true}
              options={getAllActiveProjectListOption}
              onChange={(e) =>
                setFormData({ ...formData, projectId: e?.value })
              }
              value={
                (getAllActiveProjectListOption &&
                  getAllActiveProjectListOption?.find(
                    (i) => i.value === formData.projectId
                  )) || { value: "" }
              }
            />
            {/* <p
              className={
                validate && !formData?.projectId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Project Name
            </p> */}
          </div>
          <div className={style.cu_col1} style={{ marginTop: "-1%" }}>
            {formData?.reviewSourceTypeId == 2 ? (
              <>
                <p className={style.cu_label}>
                  Reviewed By
                </p>
                <input
                  type="text"
                  className={style.input}
                  style={{marginTop: "5px"}}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      reviewedByName: e?.target?.value,
                    })
                  }
                  value={formData?.reviewedByName}
                />
                
              </>
            ) : null}
          </div>

          <div className={style.cu_col1}></div>
          <div className={style.cu_col1}></div>
        </div>
      </div>
      {/* third */}
      <div className={style.card}>
        <p className={style.title}>Feedback</p>

        {/* dynamic */}
        {/* file upload */}
        {profileData1?.feedbackTypeId === 3 && (
          <div className={style.Cu_feedbackType}>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Upload Feedback</p>
              <div className={style.Cu_row1}>
                {doc ? (
                  <div className={style.Cu_resume}>
                    <p className={style.rmpdf}>{doc.split("@")[1]}</p>
                    <p
                      onClick={() => {
                        setFormDataFile({ ...formDataFile, file: "" });
                        setDoc(null);
                        deleteResume(formDataFile?.file);
                      }}
                      className={style.rmpdfcross}
                    >
                      x
                    </p>
                  </div>
                ) : (
                  <input
                    placeholder={`${
                      formDataFile?.file
                        ? formDataFile?.file
                        : "no file selected"
                    }`}
                    type="file"
                    className={style.upload_resume}
                    onChange={(e) => {
                      uploadDocMutation.mutate(e.target.files[0]);
                    }}
                  />
                )}
              </div>
            </div>

            <div className={style.radio} style={{ marginTop: "-14px" }}>
              <div>Overall Rating :</div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating1"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataFile({
                      ...formDataFile,
                      statusIdRating: 1,
                      overallRatingValue: "",
                    })
                  }
                  checked={formDataFile?.statusIdRating == "1"}
                />
                <label for="rating1">1 to 3</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating2"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataFile({
                      ...formDataFile,
                      statusIdRating: 2,
                      overallRatingValue: "",
                    })
                  }
                  checked={formDataFile?.statusIdRating == "2"}
                />
                <label for="rating2">1 to 5</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating3"
                  name="radio-group1"
                  onChange={() => {
                    setFormDataFile({
                      ...formDataFile,
                      statusIdRating: 3,
                      overallRatingValue: "",
                    });
                  }}
                  checked={formDataFile?.statusIdRating == "3"}
                />
                <label for="rating3">1 to 10</label>
              </div>
            </div>
            <div>
              {formDataFile?.statusIdRating == 1 ? (
                <>
                  <div>
                    <OverallRating
                      formDataSubjective={formDataFile?.overallRatingValue}
                      setFormDataSubjective={setFormDataFile}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}

              {formDataFile?.statusIdRating == 2 ? (
                <>
                  <div>
                    <OverallRating2
                      formDataSubjective={formDataFile?.overallRatingValue}
                      setFormDataSubjective={setFormDataFile}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataFile?.statusIdRating == 3 ? (
                <>
                  <div>
                    <OverallRating3
                      formDataSubjective={formDataFile?.overallRatingValue}
                      setFormDataSubjective={setFormDataFile}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div className={style.radio}>
              <div>Status: <span className={style.mand}>*</span></div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test2"
                  name="radio-group"
                  value="1"
                  onChange={() => {
                    setValidate(false);
                    setFormDataFile({ ...formDataFile, statusId: 1 })
                    }
                  }
                  checked={formDataFile?.statusId == "1"}
                />
                <label for="test2">Shortlisted</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test3"
                  value="2"
                  name="radio-group"
                  onChange={() => {
                    setValidate(false);
                    setFormDataFile({ ...formDataFile, statusId: 2 })}
                  }
                  checked={formDataFile?.statusId == "2"}
                />
                <label for="test3">Rejected</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test4"
                  value="3"
                  name="radio-group"
                  onChange={() => {
                    setValidate(false);
                    setFormDataFile({ ...formDataFile, statusId: 3 })
                    }
                  }
                  checked={formDataFile?.statusId == "3"}
                />
                <label for="test4">Hold</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test5"
                  value="4"
                  name="radio-group"
                  onChange={() => {
                    setValidate(false);
                    setFormDataFile({ ...formDataFile, statusId: 4 })
                    }
                  }
                  checked={formDataFile?.statusId == "4"}
                />
                <label for="test5">Not Applicable</label>
              </div>
            </div>
            {errMessage(
                    formData?.statusId,
                    "Please Select Status"
                  )}
          </div>
        )}

        {/* Rating */}
        {profileData1?.feedbackTypeId === 1 && (
          <div className={style.Cu_feedbackType}>
            <div className={style.Cu_col1}>
              <p className={style.cu_label}>Skill <span className={style.mand}>*</span></p>
              <div style={{ marginLeft: "-0.7rem" }}>
                <CreatableSelect
                  styles={customStyles2}
                  classNamePrefix={"create_feedback"}
                  components={{
                    DropdownIndicator: () => CustomInput2(),
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  value={formDataRating?.feedbackSkills}
                  options={getSkillListOption}
                  formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
                  onChange={(e) => handleSelect(e)}
                  menuPlacement="top"
                />
              </div>
              {errMessage(
                    formDataRating?.feedbackSkills?.length,
                    "Please Select At Least One Skill"
                  )}
            </div>

            {formDataRating?.feedbackSkills?.map((item, index) => {
              return (
                <div className={style.feedback}>
                  <p key={index} className={style.cu_label}>
                    {item?.label}
                  </p>
                  {/* value={formDataTest?.feedbackSkills[index]?.rating} */}
                  <StarRating
                    feedbackRating={formDataRating?.feedbackSkills}
                    setFeedbackRating={setFormDataRating}
                    item={item}
                  />
                </div>
              );
            })}

            <div className={style.Cu_col2}>
              <p className={style.cu_label}>
                Overall Feedback <span className={style.mand}>*</span>
              </p>
              <textarea
                className={style.textarea}
                onChange={(e) =>
                  setFormDataRating({
                    ...formDataRating,
                    overallFeedback: e?.target?.value,
                  })
                }
                value={formDataRating?.overallFeedback}
              />
              <p
                className={
                  validate && !formDataRating?.overallFeedback
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Overall Feedback
              </p>
            </div>

            <div className={style.radio} style={{ marginTop: "-14px" }}>
              <div>Overall Rating :</div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating1"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataRating({
                      ...formDataRating,
                      statusIdRating: 1,
                      overallRatingValue: "",
                    })
                  }
                  checked={formDataRating?.statusIdRating == "1"}
                />
                <label for="rating1">1 to 3</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating2"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataRating({
                      ...formDataRating,
                      statusIdRating: 2,
                      overallRatingValue: "",
                    })
                  }
                  checked={formDataRating?.statusIdRating == "2"}
                />
                <label for="rating2">1 to 5</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating3"
                  name="radio-group1"
                  onChange={() => {
                    setFormDataRating({
                      ...formDataRating,
                      statusIdRating: 3,
                      overallRatingValue: "",
                    });
                  }}
                  checked={formDataRating?.statusIdRating == "3"}
                />
                <label for="rating3">1 to 10</label>
              </div>
            </div>
            <div>
              {formDataRating?.statusIdRating == 1 ? (
                <>
                  <div>
                    <OverallRating
                      formDataSubjective={formDataRating?.overallRatingValue}
                      setFormDataSubjective={setFormDataRating}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}

              {formDataRating?.statusIdRating == 2 ? (
                <>
                  <div>
                    <OverallRating2
                      formDataSubjective={formDataRating?.overallRatingValue}
                      setFormDataSubjective={setFormDataRating}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataRating?.statusIdRating == 3 ? (
                <>
                  <div>
                    <OverallRating3
                      formDataSubjective={formDataRating?.overallRatingValue}
                      setFormDataSubjective={setFormDataRating}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div className={style.radio}>
              <div>Status: <span className={style.mand}>*</span></div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test2"
                  name="radio-group"
                  value="1"
                  onChange={() =>
                    {setValidate(false);
                      setFormDataRating({
                      ...formDataRating,
                      statusId: 1,
                    })}
                  }
                  checked={formDataRating?.statusId == "1"}
                />
                <label for="test2">Shortlisted</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test3"
                  name="radio-group"
                  value="2"
                  onChange={() =>
                    {setValidate(false);
                      setFormDataRating({
                      ...formDataRating,
                      statusId: 2,
                    })}
                  }
                  checked={formDataRating?.statusId == "2"}
                />
                <label for="test3">Rejected</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test4"
                  name="radio-group"
                  value="3"
                  onChange={() =>
                    {setValidate(false);
                      setFormDataRating({
                      ...formDataRating,
                      statusId: 3,
                    })}
                  }
                  checked={formDataRating?.statusId == "3"}
                />
                <label for="test4">Hold</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test5"
                  value="4"
                  name="radio-group"
                  onChange={() =>
                    {setValidate(false);
                      setFormDataRating({
                      ...formDataRating,
                      statusId: 4,
                    })}
                  }
                  checked={formDataRating?.statusId == "4"}
                />
                <label for="test5">Not Applicable</label>
              </div>
            </div>
            {errMessage(
                    formDataRating?.statusId,
                    "Please Select Status"
                  )}
          </div>
        )}

        {/* Test */}
        {profileData1?.feedbackTypeId === 2 && (
          <div className={style.Cu_feedbackType}>
            <p className={style.cu_label}>Add Skill</p>
            <div className={style.Cu_testCol1}>
              <CreatableSelect
                styles={customStyles2}
                classNamePrefix={"create_feedback"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                isMulti
                value={formDataTest?.testSkills}
                options={getSkillListOption}
                onChange={(e) => handleSelect1(e)}
                formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
                menuPlacement="top"
              />
            </div>
            <div className={style.h}>
              <label className={style.h_name}>Skills</label>
              <div className={style.h1}>
                <label className={style.h_name}>Score Obtained</label>
                <label className={style.h_name}>Maximum Score</label>
              </div>
            </div>

            <div className={style.Cu_testCol2}>
              {formDataTest?.testSkills?.map((item, index) => {
                return (
                  <>
                    <div key={index} className={style.h}>
                      <label>{item.label}</label>
                      <div className={style.h1}>
                        <input
                          name="scoreObtained"
                          className={style.cp_field}
                          min={0}
                          max={100}
                          type="number"
                          onKeyDown={handleKeyDown}
                          onClick={handleInputClick}
                          onChange={(e) => {
                            let temp = [...formDataTest?.testSkills];
                            temp[index].scoreObtained = e?.target?.value;
                            setFormDataTest({
                              ...formDataTest,
                              testSkills: temp,
                            });
                          }}
                          value={formDataTest?.testSkills[index]?.scoreObtained}
                        />
                        <input
                          name="maxScore"
                          className={style.cp_field}
                          min={0}
                          max={100}
                          type="number"
                          onKeyDown={handleKeyDown}
                          onClick={handleInputClick}
                          onChange={(e) => {
                            let temp = [...formDataTest?.testSkills];
                            temp[index].maxScore = e?.target?.value;
                            setFormDataTest({
                              ...formDataTest,
                              testSkills: temp,
                            });
                          }}
                          value={formDataTest?.testSkills[index]?.maxScore}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div className={style.h}>
              <label>Overall Score</label>
              <div className={style.h1}>
                <input
                  className={style.cp_field}
                  readOnly
                  min={0}
                  max={100}
                  type="number"
                  value={totalOverAllScore}
                />
                <input
                  className={style.cp_field}
                  readOnly
                  min={0}
                  max={100}
                  type="number"
                  value={totalMaxAllScore}
                />
              </div>
            </div>
            <div className={style.radio} style={{ marginTop: "-14px" }}>
              <div>Overall Rating :</div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating1"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataTest({
                      ...formDataTest,
                      statusIdRating: 1,
                      overallRatingValue: "",
                    })
                  }
                  checked={formDataTest?.statusIdRating == "1"}
                />
                <label for="rating1">1 to 3</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating2"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataTest({
                      ...formDataTest,
                      statusIdRating: 2,
                      overallRatingValue: "",
                    })
                  }
                  checked={formDataTest?.statusIdRating == "2"}
                />
                <label for="rating2">1 to 5</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating3"
                  name="radio-group1"
                  onChange={() => {
                    setFormDataTest({
                      ...formDataTest,
                      statusIdRating: 3,
                      overallRatingValue: "",
                    });
                  }}
                  checked={formDataTest?.statusIdRating == "3"}
                />
                <label for="rating3">1 to 10</label>
              </div>
            </div>

            <div>
              {formDataTest?.statusIdRating == 1 ? (
                <>
                  <div>
                    <OverallRating
                      formDataSubjective={formDataTest?.overallRatingValue}
                      setFormDataSubjective={setFormDataTest}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}

              {formDataTest?.statusIdRating == 2 ? (
                <>
                  <div>
                    <OverallRating2
                      formDataSubjective={formDataTest?.overallRatingValue}
                      setFormDataSubjective={setFormDataTest}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataTest?.statusIdRating == 3 ? (
                <>
                  <div>
                    <OverallRating3
                      formDataSubjective={formDataTest?.overallRatingValue}
                      setFormDataSubjective={setFormDataTest}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        )}

        {/* Subjective */}
        {profileData1?.feedbackTypeId === 4 && (
          <div className={style.Cu_feedbackType}>
            <div className={style.Cu_col1}>
              <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
                Subjective Feedback <span className={style.mand}>*</span>
              </p>
              <textarea
                className={style.textarea}
                onChange={(e) =>
                  setFormDataSubjective({
                    ...formDataSubjective,
                    subjective: e?.target?.value,
                  })
                }
                value={formDataSubjective?.subjective}
              />
              <p
                className={
                  validate && !formDataSubjective?.subjective?.trim()
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Subjective Feedback
              </p>
            </div>

            <div className={style.radio} style={{ marginTop: "-14px" }}>
              <div>Overall Rating :</div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating1"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataSubjective({
                      ...formDataSubjective,
                      statusIdRating: 1,
                      overallRatingValue: "",
                    })
                  }
                  checked={formDataSubjective?.statusIdRating == "1"}
                />
                <label for="rating1">1 to 3</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating2"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataSubjective({
                      ...formDataSubjective,
                      statusIdRating: 2,
                      overallRatingValue: "",
                    })
                  }
                  checked={formDataSubjective?.statusIdRating == "2"}
                />
                <label for="rating2">1 to 5</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating3"
                  name="radio-group1"
                  onChange={() => {
                    setFormDataSubjective({
                      ...formDataSubjective,
                      statusIdRating: 3,
                      overallRatingValue: "",
                    });
                  }}
                  checked={formDataSubjective?.statusIdRating == "3"}
                />
                <label for="rating3">1 to 10</label>
              </div>
            </div>

            <div>
              {formDataSubjective?.statusIdRating == 1 ? (
                <>
                  <div>
                    <OverallRating
                      formDataSubjective={
                        formDataSubjective?.overallRatingValue
                      }
                      setFormDataSubjective={setFormDataSubjective}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}

              {formDataSubjective?.statusIdRating == 2 ? (
                <>
                  <div>
                    <OverallRating2
                      formDataSubjective={
                        formDataSubjective?.overallRatingValue
                      }
                      setFormDataSubjective={setFormDataSubjective}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataSubjective?.statusIdRating == 3 ? (
                <>
                  <div>
                    <OverallRating3
                      formDataSubjective={
                        formDataSubjective?.overallRatingValue
                      }
                      setFormDataSubjective={setFormDataSubjective}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div className={style.radio}>
              <div>Status: <span className={style.mand}>*</span></div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test2"
                  name="radio-group"
                  value="1"
                  onChange={() =>
                    {setValidate(false);
                      setFormDataSubjective({
                      ...formDataSubjective,
                      statusId: 1,
                    })}
                  }
                  checked={formDataSubjective?.statusId == "1"}
                />
                <label for="test2">Shortlisted</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test3"
                  name="radio-group"
                  value="2"
                  onChange={() =>
                    {setValidate(false)
                      setFormDataSubjective({
                      ...formDataSubjective,
                      statusId: 2,
                    })}
                  }
                  checked={formDataSubjective?.statusId == "2"}
                />
                <label for="test3">Rejected</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test4"
                  name="radio-group"
                  value="3"
                  onChange={() =>
                    {setValidate(false);
                      setFormDataSubjective({
                      ...formDataSubjective,
                      statusId: 3,
                    })}
                  }
                  checked={formDataSubjective?.statusId == "3"}
                />
                <label for="test4">Hold</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test5"
                  name="radio-group"
                  value="1"
                  onChange={() =>
                    {setValidate(false);
                      setFormDataSubjective({
                      ...formDataSubjective,
                      statusId: 5,
                    })}
                  }
                  checked={formDataSubjective?.statusId == "5"}
                />
                <label for="test5">PIP</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test6"
                  name="radio-group"
                  value="6"
                  onChange={() =>
                    {setValidate(false);
                      setFormDataSubjective({
                      ...formDataSubjective,
                      statusId: 6,
                    })}
                  }
                  checked={formDataSubjective?.statusId == "6"}
                />
                <label for="test6">Reward</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test7"
                  name="radio-group"
                  value="7"
                  onChange={() =>
                    setFormDataSubjective({
                      ...formDataSubjective,
                      statusId: 7,
                    })
                  }
                  checked={formDataSubjective?.statusId == "7"}
                />
                <label for="test7">Appreciation</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test8"
                  name="radio-group"
                  value="8"
                  onChange={() =>
                    setFormDataSubjective({
                      ...formDataSubjective,
                      statusId: 8,
                    })
                  }
                  checked={formDataSubjective?.statusId == "8"}
                />
                <label for="test8">Feedback</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="test55"
                  name="radio-group"
                  value="4"
                  onChange={() =>
                    setFormDataSubjective({
                      ...formDataSubjective,
                      statusId: 4,
                    })
                  }
                  checked={formDataSubjective?.statusId == "4"}
                />
                <label for="test55">Not-Applicable</label>
              </div>
            </div>
            {errMessage(
                    formData?.statusId,
                    "Please Select Status"
                  )}
          </div>
        )}
      </div>

      {/* button */}
      <div className={style.button}>
        <button
          className={style.btn1}
          onClick={() => {
            setNextScreen("viewFeedback");
            setPrevData((prevData) => ({
              ...prevData,
              sidebar: "EmployeePerformanceReview",
              page: "EmployeePerformanceReview",
              page1: "EmployeePerformanceReview",
              page2: "EmployeeFeedbackList",
              page3: "viewFeedback",
              page4: null,
              active: "viewFeedback",
            }));
          }}
        >
          Cancel
        </button>

        <button
          className={style.btn2}
          onClick={() => {
            onCreateHandler();
          }}
        >
          Submit Feedback
        </button>
      </div>
    </>
  );
}

export default EditEmployeeFeedback;
