import React, { useState, useEffect } from "react";
import style from "./ViewQuestionnaireCom.module.css";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import cookie from "react-cookies";
import Moment from "moment";
import Select from "react-select";
import { customSearch } from "../../CommonComponent/style";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useHistory, useLocation } from "react-router-dom";
import { Toastify } from "../../../../App";
import dropdownIcon from "../../../../assets/downIcon.png";
import { ToastContainer } from "react-toastify";
import Modal from "react-responsive-modal";
import { customStyles } from "../../CommonComponent/style";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { auto } from "@popperjs/core";
import ConfirmPopup from "../../CommonComponent/ConfirmPopup";
import { generateRandomString } from "../../CommonComponent/GenerateRandomSreing";
import { employeeId } from "../../../../Utils/constants";
import { useParams } from "react-router-dom";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";

export const ViewQuestionnaireCom = () => {
  const [selectedItemId, setselectedItemId] = useState();
  const [viewQuestion, setViewQuestion] = useState();
  const [editDetails, setEditDetails] = useState();
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [clientNameSelect, setClientName] = useState(false);
  const [OptionModal, setOptionModal] = useState(false);
  const [OptionModal1, setOptionModal1] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [validate, setValidate] = useState(false);
  const [viewInstruction, setViewInstruction] = useState();
  const [editMode, setEditMode] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [editIdInstruction, setEditInstructionId] = useState();
  const [deleteQuestionnaireId, setDeleteQuestionnaireId] = useState();
  const [addInstructionRender, setAddInstructionRender] = useState(false);
  const [instructionHide] = useState(true);
  const [round, setRound] = useState([]);
  const [roundEdit, setEditRound] = useState(null);
  const [AllData, setAllData] = useState({
    RoundId: "",
    RoundLabel: "",
  });

  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const iconStyle = {
    color: "#964431",
    cursor: "pointer",
  };
  const url = process.env.REACT_APP_QUESTIONNAIRE_URL;

  const handleTextareaChange = (event) => {
    const { value } = event.target;
    setEditDetails((prevEditDetails) => {
      return prevEditDetails.map((item) => {
        if (item.id === selectedItemId) {
          return { ...item, questions: value };
        }
        return item;
      });
    });
  };
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="amg"
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  //cancel handler
  const cancelHandler = () => {
    setselectedItemId();
    setEditDetails();
    setValidate(false)
  };

  const editHandler = (id) => {
    setselectedItemId(id);
    setEditDetails(viewQuestion?.questionsCard.filter((item) => item.id == id));
  };
  const { clientId, minExperience, maxExperience, skillId, seniorityId } =
    useParams();

  const cardStyle = {
    paddingRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const titleStyle = {
    fontWeight: "bold",
  };

  const dateStyle = {
    fontWeight: 500,
    /* align-self: flex-start; */
    paddingLeft: "11px",
    color: "#964431",
  };

  const addedByStyle = {
    alignSelf: "center",
    fontWeight: 500,
    /* align-self: flex-start; */
    paddingLeft: "11px",
    color: "#964431",
  };

  const editDeleteStyle = {
    alignSelf: "flex-end",
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [selectedItemId]);

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({
            ...AllData,
            RoundId: e?.value,
            RoundLabel: e?.label,
          });
          break;

        default:
          return null;
      }
    }
  };
  const adjustTextareaHeight = () => {
    const textareaElement = document.getElementById(selectedItemId);
    if (textareaElement) {
      textareaElement.style.height = "auto";
      textareaElement.style.height = `${textareaElement.scrollHeight}px`;
    }
  };

  const getViewQuestion = () => {
    axios
      .get(
        url +
        `/get-questions-by-position?clientId=${clientId}&maxExperience=${maxExperience}&minExperience=${minExperience}&seniorityId=${seniorityId}&skillId=${skillId}&roundId=${AllData.RoundId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setViewQuestion(res.data.responseData);
        getInstruction(
          res.data.responseData?.clientId,
          res.data.responseData?.skillId
        );
      })
      .catch((err) => { });
  };
  const getInstruction = (clientId, skillsId) => {
    axios
      .get(
        url +
        `/view-instruction-by-client-id-and-skill-id?clientId=${clientId}&skillId=${skillsId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setViewInstruction(res.data.responseData);
        setEditInstructionId(res?.data?.responseData?.id);
      })
      .catch((err) => { });
  };
  useEffect(() => {
    clientId && getViewQuestion();
  }, [addInstructionRender, AllData]);

  const EditformHAndler = (e) => {
    if (!roundEdit) {
      setValidate(true);
      return;
    }
    const data = {
      clientId: viewQuestion?.clientId,
      empId: editDetails[0]?.empId,
      id: editDetails[0]?.id,
      maxExperience: editDetails[0]?.maxExperience,
      minExperience: editDetails[0]?.minExperience,
      questions: editDetails[0]?.questions,
      seniorityId: viewQuestion?.seniorityId,
      submissionDate: new Date(),
      technologyId: viewQuestion?.skillId,
      roundId: roundEdit,
    };

    e.preventDefault();
    axios
      .post(url + "/createQuestions", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        Toastify("Edit Question Successfully");
        getViewQuestion();
        setselectedItemId();
      })
      .catch((err) => {
        return err;
      });
  };
  // Delete Handler
  const deleteHandler = (id) => {
    setDeleteQuestionnaireId(id);
    setConfirmModal(true);
  };

  const deleteIssueHandler = (id) => {
    axios
      .delete(url + `/delete-questionnaire?questionnaireId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        Toastify("Question Deleted Successfully");
        getViewQuestion();
        setConfirmModal(false);
        // handleRedirect(res.data.responseData);
        // setButtonDisable(true);
      })
      .catch((err) => {
        return err;
      });
  };
  const handleCheckboxChange1 = (checkboxId) => {
    const isChecked = checkedBoxes.includes(checkboxId);

    if (isChecked) {
      setCheckedBoxes(checkedBoxes.filter((id) => id !== checkboxId));
    } else {
      setCheckedBoxes([...checkedBoxes, checkboxId]);
    }
  };

  const handleClientNameSelectHandler = () => {
    setClientName(!clientNameSelect);
  };
  const handleSelectAllChange = () => {
    // Toggle the selectAllChecked state
    setSelectAllChecked(!selectAllChecked);

    // Update the checkedBoxes state based on the selectAllChecked status
    if (!selectAllChecked) {
      // If selectAllChecked is false, select all checkboxes
      const allCheckboxIds = viewQuestion?.questionsCard?.map(
        (item) => item?.id
      );
      setCheckedBoxes(allCheckboxIds);
    } else {
      // If selectAllChecked is true, deselect all checkboxes
      setCheckedBoxes([]);
    }
  };

  const getRoundList = () => {
    axios
      .get(url + "/get-rounds", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setRound(res.data.responseData);
      })
      .catch((err) => { });
  };
  const generateLinkHandle = () => {
    history.push({
      pathname: `/viewQuestionDetailsById/${generateRandomString()}`,
      state: { clientNameSelect, checkedBoxes, instructionHide },
    });
  };
  const AddInstructionHandler = () => {
    setOptionModal(true);
  };
  const vieInstructionHandler = () => {
    setOptionModal1(true);
  };
  const handleChange = (e) => {
    setInstructions(e.target.value);
  };
  const handleChange1 = (e) => {
    setViewInstruction({ viewInstruction, instruction: e.target.value });
  };

  const AddInstruction = () => {
    if (!instructions?.trim()) {
      setValidate(true);
      return;
    }
    const dataForInstruction = {
      clientId: viewQuestion?.clientId,
      addedBy: employeeId,
      seniorityId: viewQuestion?.seniorityId,
      instruction: instructions,
      skillId: viewQuestion?.skillId,
    };
    axios
      .post(url + "/add-instructions", dataForInstruction, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        Toastify("Instructions Added Successfully");
        setOptionModal(!OptionModal);
        setAddInstructionRender(true);
      })
      .catch((err) => {
        return err;
      });
  };
  const EditInstruction = () => {
    if (!viewInstruction?.instruction?.trim()) {
      setValidate(true);
      return;
    }
    const dataForInstruction = {
      clientId: viewQuestion?.clientId,
      addedBy: employeeId,
      seniorityId: viewQuestion?.seniorityId,
      instruction: viewInstruction?.instruction,
      skillId: viewQuestion?.skillId,
      id: editIdInstruction,
    };
    axios
      .post(url + "/add-instructions", dataForInstruction, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        Toastify("Instructions Edit Successfully");
        setOptionModal1(false);
        setEditMode(false);
      })
      .catch((err) => {
        return err;
      });
  };
  const getRoundListt = round?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  useEffect(() => {
    getRoundList();
  }, []);
  return (
    <>
      <ToastContainer />
      <div className={style.main}>
        <div className={style.Cu_row1}>
          <div className={style.cu_col1}>
            <span className={style.cu_label}>Client Name</span> :{" "}
            <span>{viewQuestion?.clientName}</span>
          </div>

          <div className={style.cu_col2}>
            <span className={style.cu_label}>Skill</span> :{" "}
            <span>{viewQuestion?.skillName}</span>
          </div>
          <div className={style.cu_col3}>
            <span className={style.cu_label}>Experience</span> :{" "}
            <span>
              {viewQuestion?.minExperience} - {viewQuestion?.maxExperience}{" "}
              Years
            </span>
          </div>
          <div className={style.cu_col3}>
            <span className={style.cu_label}>Seniority</span> :{" "}
            <span>{viewQuestion?.seniority}</span>
          </div>
          <div className={style.cu_col4}>
            <button
              onClick={() => generateLinkHandle()}
              className={
                checkedBoxes?.length !== 0
                  ? style.generateLink
                  : style.generateLinkDisable
              }
              disabled={checkedBoxes?.length === 0}
            >
              <AttachmentIcon />
              Generate Link
            </button>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {" "}
          <label className={style.container}>
            Select All
            <input type="checkbox" onChange={handleSelectAllChange} />
            <span className={style.checkmark}></span>
          </label>
          <label className={style.container}>
            Share Client Name
            <input type="checkbox" onChange={handleClientNameSelectHandler} />
            <span className={style.checkmark}></span>
          </label>
          <div className={style.s1}>
            {AllData?.RoundId == "" ? (
              <span className={style.cp_serchIcon2}>
                <img src={searchIcon} alt="img" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon2}
                onClick={() => {
                  setAllData({
                    ...AllData,
                    RoundId: "",
                    RoundLabel: "",
                  });
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    setAllData({
                      ...AllData,
                      RoundId: "",
                      RoundLabel: "",
                    });
                  }
                }}
                tabIndex={1}
              >
                {AllData.RoundId !== "" ? <AiOutlineClose /> : null}
              </span>
            )}

            <Select
              styles={customSearch}
              classNamePrefix={"create_position"}
              placeholder="Search By Round"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={
                AllData?.RoundId
                  ? {
                    value: AllData?.RoundId,
                    label: AllData?.RoundLabel,
                  }
                  : null
              }
              onChange={(e) => {
                onSearchHandler(e, "Code");
              }}
              options={getRoundListt}
            />
          </div>
          {viewInstruction?.message !== "Client and skill not exists" ? (
            <button
              onClick={() => vieInstructionHandler()}
              className={style.genericComments}
            >
              View Instructions
            </button>
          ) : (
            <button
              onClick={() => AddInstructionHandler()}
              className={style.genericComments}
            >
              Add Instructions
            </button>
          )}
        </div>

        {viewQuestion?.questionsCard?.map((item) => (
          <div key={item.id}>
            <div className={style.card}>
              <span>
                {" "}
                <label className={style.container1}>
                  <input
                    type="checkbox"
                    checked={checkedBoxes.includes(item.id)}
                    onChange={() => handleCheckboxChange1(item.id)}
                  />
                  <span className={style.checkmark1}></span>
                </label>
              </span>
              <div style={cardStyle}>
                {item.id === selectedItemId ? (
                  <div
                    className={style.cu_col3}
                    style={{
                      paddingLeft: "20px",
                      width: "25%",
                      // visibility: formData?.minExperience ? "visible" : "hidden",
                    }}
                  >
                    <p className={style.cu_label}>
                      Round<span className={style.mandatory}>* </span>
                    </p>
                    <Select
                      // menuIsOpen={seniorityDropDownOpen}
                      // onMenuOpen={() => setSeniorityDropdownOpen(true)}
                      // onMenuClose={() => setSeniorityDropdownOpen(false)}
                      styles={customStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      options={getRoundListt}
                      onChange={(e) => {
                        setEditRound(e.value);
                      }}
                    // value={item?.roundName ?? null}

                    ></Select>
                    <p
                      className={
                        validate && !roundEdit
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Round
                    </p>
                  </div>
                ) : (
                  <div style={{ ...dateStyle }}>
                    Round : {item?.roundName ? item?.roundName : ""}
                  </div>
                )}

                <div style={{ ...dateStyle }}>
                  Date : {Moment(item?.addedDate).format("D MMM YY")}
                </div>
                <div style={{ ...addedByStyle }}>
                  Added By : {item?.empName}
                </div>
                <div style={cardStyle}>
                  <div
                    style={{ ...editDeleteStyle }}
                    onClick={() => editHandler(item?.id)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        editHandler(item?.id);
                      }
                    }}
                    tabIndex={2}
                  >
                    <CreateIcon style={iconStyle} />
                  </div>
                  <div
                    style={{ ...titleStyle, ...editDeleteStyle }}
                    onClick={() => deleteHandler(item?.id)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        deleteHandler(item?.id);
                      }
                    }}
                    tabIndex={3}
                  >
                    <DeleteIcon style={iconStyle} />
                  </div>
                </div>
              </div>
              <hr />
              <div className={style.cu_col1} style={{ marginLeft: "21px" }}>
                {item.id === selectedItemId ? (
                  <textarea
                    id={item?.id}
                    style={{
                      whiteSpace: "pre-line",
                      paddingBottom: "10px",
                      height: auto,
                      resize: "none",
                      width: "98%",
                      outline: "none",
                      paddingLeft: "5px",
                    }}
                    value={editDetails[0]?.questions}
                    onChange={(e) => handleTextareaChange(e)}
                  ></textarea>
                ) : (
                  <p style={{ whiteSpace: "pre-line" }}>
                    {item?.questions?.split("").map((char, index) =>
                      char === "*" ? (
                        <span key={index} style={{ color: "red" }}>
                          {char}
                        </span>
                      ) : (
                        char
                      )
                    )}
                  </p>
                )}
                {item.id === selectedItemId ? (
                  <div className={style?.buttonAlign}>
                    {" "}
                    <button
                      className={style.cancel}
                      onClick={() => cancelHandler()}
                    >
                      Cancel
                    </button>
                    <button className={style.edit} onClick={EditformHAndler}>
                      Done
                    </button>{" "}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        open={OptionModal}
        center
        onClose={() => {
          setOptionModal((prevOptionModal) => !prevOptionModal);
        }}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.confirmPopup}>
          <p className={style.cp_label2}>
            Add Instructions <span className={style.mandatory}>*</span>
          </p>

          <textarea
            name="postContent"
            rows={2}
            onChange={(e) => handleChange(e)}
            className={style.textArea}
          />
          <p
            className={
              validate && !instructions?.trim()
                ? style.errorText1
                : style.errorTextHidden
            }
          >
            Please Enter Instructions
          </p>

          <div className={style.submitBtn}>
            <button className={style.yesBtn} onClick={() => AddInstruction()}>
              Submit
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={OptionModal1}
        center
        onClose={() => {
          setOptionModal1((prevOptionModal) => !prevOptionModal);
        }}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={editMode ? style.confirmPopup : style.confirmPopup2}>
          {editMode ? (
            <>
              <p className={style.cp_label2}>
                Edit Instructions <span className={style.mandatory}>*</span>
              </p>
              <textarea
                name="postContent"
                rows={2}
                onChange={handleChange1}
                className={style.textArea}
                value={viewInstruction?.instruction}
              />
              <p
                className={
                  validate && !viewInstruction?.instruction?.trim()
                    ? style.errorText1
                    : style.errorTextHidden
                }
              >
                Please Enter Instructions
              </p>
              <div className={style.submitBtn}>
                <button
                  className={style.yesBtn}
                  onClick={() => EditInstruction()}
                >
                  Update
                </button>
              </div>
            </>
          ) : (
            <>
              <div style={{ position: "fixed", paddingLeft: "42.7rem" }}>
                <CreateIcon
                  style={iconStyle}
                  onClick={() => setEditMode(true)}
                />
              </div>
              <p style={{ whiteSpace: "pre-line" }}>
                {viewInstruction?.instruction}
              </p>
            </>
          )}
        </div>
      </Modal>
      <ConfirmPopup open={confirmModal}>
        <div className={style.confirmPopup1}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to Delete?
          </h5>
          <div className={style.btnCont}>
            <button
              className={style.yesBtn}
              onClick={() => deleteIssueHandler(deleteQuestionnaireId)}
            >
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={() => {
                setConfirmModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </>
  );
};
