import React, { useEffect, useState } from "react";
import style from "./CreateProject.module.css";
import CommonDropdown from "../../../../../../GenericComponent/ui/CommonDropdown";
import CommonCalender from "../../../../../../GenericComponent/ui/CommonCalender";
import { useMutation, useQuery } from "react-query";
import {
  getActiveClientData,
  getEmployeeList,
  getProjectTypes,
  createProject,
  getProjectDetails,
  editProjectDetails,
  getBillingModel,
} from "../../../../../../Utils/axios/PortalManagement";
import {
  checkIfEmpty,
  errorToast,
  onChangeHandler,
  successToast,
} from "../../../../../../Utils/constants";
import moment from "moment";

const CreateProject = ({ prevData, setPrevData, setNextScreen }) => {
  const [required, setRequired] = useState(false);
  const [formData, setFormData] = useState({
    projectDescription: "",
    name: "",
    catogaryproject: "",
    catogaryprojectName: "",
    startDate: "",
    clientId: "",
    clientName: "",
    empId: "",
    empName: "",
    billingModelName: "",
    billingModelId: null,
  });

  const { data: getActiveClientDataList } = useQuery(
    ["getActiveClientData"],
    () => getActiveClientData()
  );

  const activeClientOptions = getActiveClientDataList
    ? getActiveClientDataList?.map((item) => {
        return {
          id: item?.clientid,
          label: item?.name,
        };
      })
    : [];

  const { data: getEmployeeDataList } = useQuery(["getEmployeeList"], () =>
    getEmployeeList()
  );

  const employeeOptions = getEmployeeDataList
    ? getEmployeeDataList?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
        };
      })
    : [];

  const { data: getProjectDetailsData } = useQuery(
    ["getProjectDetails"],
    () =>
      prevData?.active === "EditProject" &&
      prevData?.projectId &&
      getProjectDetails(prevData?.projectId)
  );

  const { data: getProjectTypeDataList } = useQuery(["getProjectTypes"], () =>
    getProjectTypes()
  );

  const projectTypeOption = getProjectTypeDataList
    ? getProjectTypeDataList?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
        };
      })
    : [];

  const { data: getBillingModelOptions } = useQuery(["getBillingModels"], () =>
    getBillingModel()
  );

  const billingModelOption = getBillingModelOptions
    ? getBillingModelOptions?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
        };
      })
    : [];

  const createMutation = useMutation(createProject, {
    onSuccess: (res) => {
      if (res?.data?.responseStatus?.statusCode === 1) {
        errorToast(`${res?.data?.responseData?.message}`);
        return;
      }

      successToast("Project Created Successfully");
      setNextScreen("projectManagement");
      setPrevData({
        ...prevData,
        sidebar: "projectManagement",
        page: "projectManagement",
        page1: "projectManagement",
        page2: null,
        page3: null,
        page4: null,
        page5: null,
        page6: null,
        active: "projectManagement",
      });
    },
    onError: (err) => {
      errorToast("Something Went Wrong");
    },
  });

  const editMutation = useMutation(editProjectDetails, {
    onSuccess: (res) => {
      if (res?.data?.responseStatus?.statusCode === 1) {
        errorToast(`${res?.data?.responseData?.message}`);
        return;
      }

      successToast("Project Updated Successfully");
      setNextScreen("projectManagement");
      setPrevData({
        ...prevData,
        sidebar: "projectManagement",
        page: "projectManagement",
        page1: "projectManagement",
        page2: null,
        page3: null,
        page4: null,
        page5: null,
        page6: null,
        active: "projectManagement",
      });
    },

    onError: (err) => {
      errorToast("Something Went Wrong");
    },
  });

  const onSubmit = () => {
    if (!formData?.clientName) {
      setRequired(true);
      return;
    }

    if (!formData?.name) {
      setRequired(true);
      return;
    }

    if (!formData?.empName) {
      setRequired(true);
      return;
    }

    if (!formData?.startDate) {
      setRequired(true);
      return;
    }

    if (!formData?.catogaryprojectName) {
      setRequired(true);
      return;
    }

    if (prevData?.active === "EditProject") {
      setRequired(false);
      editMutation.mutate({
        catogaryproject: formData?.catogaryproject,
        clientId: formData?.clientId,
        empId: formData?.empId,
        name: formData?.name,
        projectDescription: formData?.projectDescription,
        startDate: moment(formData?.startDate)?.format("YYYY-MM-DD HH:MM"),
        projectId: prevData?.projectId,
        billingModelId: formData?.billingModelId,
      });
      return;
    }

    setRequired(false);
    createMutation.mutate({
      catogaryproject: formData?.catogaryproject,
      clientId: formData?.clientId,
      empId: formData?.empId,
      name: formData?.name,
      projectDescription: formData?.projectDescription,
      startDate: moment(formData?.startDate)?.format("YYYY-MM-DD HH:MM"),
      billingModelId: formData?.billingModelId,
    });
  };

  useEffect(() => {
    if (prevData?.active === "EditProject") {
      setFormData((prev) => ({
        ...prev,
        projectDescription: getProjectDetailsData?.projectdescription,
        name: getProjectDetailsData?.name,
        catogaryproject: getProjectDetailsData?.catogaryprojectId,
        catogaryprojectName: getProjectDetailsData?.typeofproject,
        startDate: getProjectDetailsData?.startdate,
        clientId: getProjectDetailsData?.clientid,
        clientName: getProjectDetailsData?.clientName,
        empId: getProjectDetailsData?.pmId,
        empName: getProjectDetailsData?.pmpc,
        billingModelId: getProjectDetailsData?.billingModelId,
        billingModelName: getProjectDetailsData?.billingModelName,
      }));
    }
  }, [getProjectDetailsData, prevData]);

  return (
    <div className={style.main_div}>
      <div className={style.breadcrum}>
        <span
          onClick={() => {
            setPrevData({
              ...prevData,
              sidebar: "projectManagement",
              page: "projectManagement",
              page1: "projectManagement",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "projectManagement",
            });
            setNextScreen("projectManagement");
          }}
          className={style.cursor}
        >
          ProjectManagement
        </span>{" "}
        {">"}{" "}
        {prevData?.active === "EditProject" ? "Edit Project" : "Create Project"}
      </div>

      <div className={style.inner_main_div}>
        <div className={style.div_heading}>Project Information</div>
        <div className={style.new_data_div}>
          <div className={style.first_div}>
            <div className={style.small_div}>
              <p className={style.para}>
                Client <span className={style.important_star}>*</span>
              </p>
              <div>
                <CommonDropdown
                  placeholder="Select Client"
                  options={activeClientOptions}
                  color="#c3b14b"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      clientId: e?.id,
                      clientName: e?.label,
                    })
                  }
                  value={checkIfEmpty(formData?.clientName, {
                    id: formData?.clientId,
                    label: formData?.clientName,
                  })}
                  customInputOnClick={() =>
                    setFormData({ ...formData, clientId: "", clientName: "" })
                  }
                />
              </div>
              {required && !formData?.clientName && (
                <p className={style.errorMsg}>Please Select Client</p>
              )}
            </div>

            <div className={style.small_div}>
              <p className={style.para}>
                Project Name <span className={style.important_star}>*</span>
              </p>
              <input
                value={formData?.name}
                className={style.input_box}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    name: e?.target?.value,
                  })
                }
              />
              {required && !formData?.name && (
                <p className={style.errorMsg}>Please Enter Project Name</p>
              )}
            </div>

            <div className={style.small_div}>
              <p className={style.para}>
                PM/PC <span className={style.important_star}>*</span>
              </p>
              <div>
                <CommonDropdown
                  placeholder="Select PM/PC"
                  options={employeeOptions}
                  color="#c3b14b"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      empId: e?.id,
                      empName: e?.label,
                    })
                  }
                  value={checkIfEmpty(formData?.empName, {
                    id: formData?.empId,
                    label: formData?.empName,
                  })}
                  customInputOnClick={() =>
                    setFormData({ ...formData, empId: "", empName: "" })
                  }
                />
              </div>
              {required && !formData?.empName && (
                <p className={style.errorMsg}>Please Select PM/PC</p>
              )}
            </div>
          </div>
        </div>

        <div className={`${style.first_div} ${style.margin_top}`}>
          <div className={style.small_div}>
            <p className={style.para}>
              Project Start Date <span className={style.important_star}>*</span>
            </p>
            <div className={style.date}>
              <CommonCalender
                icon="pm"
                value={formData?.startDate}
                formData={formData}
                setFormData={setFormData}
                onCross="startDate"
                onChange={(date) => {
                  onChangeHandler(
                    formData,
                    setFormData,
                    date,
                    "startDate",
                    "date"
                  );
                }}
              />
            </div>
            {required && !formData?.startDate && (
              <p className={style.errorMsg}>Please Select Start Date</p>
            )}
          </div>

          <div className={style.small_div}>
            <p className={style.para}>
              Type of Project <span className={style.important_star}>*</span>
            </p>
            <div>
              <CommonDropdown
                placeholder="Select Project Type"
                options={projectTypeOption}
                color="#c3b14b"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    catogaryproject: e?.id,
                    catogaryprojectName: e?.label,
                  })
                }
                value={checkIfEmpty(formData?.catogaryprojectName, {
                  id: formData?.catogaryproject,
                  label: formData?.catogaryprojectName,
                })}
                customInputOnClick={() =>
                  setFormData({
                    ...formData,
                    catogaryproject: "",
                    catogaryprojectName: "",
                  })
                }
              />
            </div>
            {required && !formData?.catogaryprojectName && (
              <p className={style.errorMsg}>Please Select Project Type</p>
            )}
          </div>

          <div className={style.small_div}>
            <p className={style.para}>
              Billing Model
              {/* <span className={style.important_star}>*</span> */}
            </p>
            <div>
              <CommonDropdown
                placeholder="Select Billing Model"
                options={billingModelOption}
                color="#c3b14b"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    billingModelId: e?.id,
                    billingModelName: e?.label,
                  })
                }
                value={checkIfEmpty(formData?.billingModelName, {
                  id: formData?.billingModelId,
                  label: formData?.billingModelName,
                })}
                customInputOnClick={() =>
                  setFormData({
                    ...formData,
                    billingModelName: "",
                    billingModelId: null,
                  })
                }
              />
            </div>
            {/* {required && !formData?.billingModelName && (
              <p className={style.errorMsg}>Please Select Billing Model</p>
            )} */}
          </div>
        </div>

        <div className={style.second_div}>
          <p className={style.para2}>Project Description</p>
          <textarea
            rows={5}
            className={style.textarea}
            onChange={(e) =>
              setFormData({
                ...formData,
                projectDescription: e?.target?.value,
              })
            }
            value={formData?.projectDescription}
          />
        </div>
      </div>

      <div className={style.btn_div}>
        <button onClick={() => onSubmit()} className={style.btn}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default CreateProject;
