import React from "react";
import classes from "../OnBoardingStatus/ViewOnBoardingStatus.module.css";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  getOnBoardingByOnBoardingId,
  getPartnerOnBoardingHistoryList,
} from "../../../../Utils/axios/PortalManagement";
import moment from "moment";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";

const ViewOnBoardingStatus = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [onboardHistroy, setonboardHistroy] = useState(false);
  const handleonboardHistroy = () => {
    setonboardHistroy(true);
  };

  const { data: getOnBoardingData } = useQuery(
    ["getOnBoardingByOnBoardingId"],
    () => getOnBoardingByOnBoardingId(prevData?.onBoardIds),
    {}
  );

  const {
    data: getOnBoardingHistory,
    isLoading,
    isError,
  } = useQuery(
    ["getPartnerOnBoardingHistoryList"],
    () => getPartnerOnBoardingHistoryList(prevData?.partnerId),
    {}
  );

  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginLeft: "25px" }}>On Boarding</h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col>
              <h6 className={classes.primaryContact}>Status Management</h6>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <p className={classes.label}>Status</p>
              <p className={classes.partnerlabal}>
                {getOnBoardingData?.status}
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>Effective From</p>
              <p className={classes.partnerlabal}>
                {moment(getOnBoardingData?.effectiveFrom).format("DD MMM YY")}
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>Effective Date</p>
              <p className={classes.partnerlabal}>
                {moment(getOnBoardingData?.effectiveDate).format("DD MMM YY")}
              </p>
            </Col>
          </Row>
        </InvoiceContainer>
        <Row>
          <Col md={12}>
            <button
              className={classes.buttonAdd}
              onClick={handleonboardHistroy}
            >
              Done
            </button>
          </Col>
        </Row>

        <Row>
          {onboardHistroy ? (
            <>
              <Col md={12} style={{ marginTop: "w0px" }}>
                <Row>
                  <Col>
                    <h6 style={{ marginLeft: "25px" }}>Status History</h6>
                  </Col>
                </Row>

                <InvoiceContainer>
                  <div className={classes.table}>
                    <div className={classes.th}>
                      <p className={classes.tableContent}>Status</p>
                      <p className={classes.tableContent}>Effective From</p>
                      <p className={classes.tableContent}>Effective Date</p>
                    </div>
                    <div>
                      {isLoading && (
                        <p className={classes.no_data}>Loading .....</p>
                      )}
                      {!isLoading && !isError ? (
                        getOnBoardingHistory?.length > 0 ? (
                          typeof getOnBoardingHistory !== "string" &&
                          getOnBoardingHistory?.map((item, index) => {
                            return (
                              <div
                                className={classes.td}
                                key={item?.positionId}
                              >
                                <p className={classes.tableContent}>
                                  {item?.status}
                                </p>

                                <p className={classes.tableContent}>
                                  {item?.effectiveFrom === null
                                    ? ""
                                    : moment(item?.effectiveFrom).format(
                                        "DD MMM YY"
                                      )}
                                </p>

                                <p className={classes.tableContent}>
                                  {item?.effectiveDate === null
                                    ? ""
                                    : moment(item?.effectiveDate).format(
                                        "DD MMM YY"
                                      )}
                                </p>
                              </div>
                            );
                          })
                        ) : (
                          <p className={classes.no_data}>No Data Found</p>
                        )
                      ) : null}
                    </div>
                  </div>
                </InvoiceContainer>
              </Col>
              <Row>
                <Col md={12}>
                  <button
                    className={classes.buttonAdd3}
                    onClick={() => {
                      setNextScreen("PartnerList");
                      setPrevData((prevData) => ({
                        ...prevData,
                        page2: "PartnerList",
                        page3: null,
                        page4: null,
                        active: "PartnerList",
                      }));
                    }}
                  >
                    Done
                  </button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Row>
      </Container>
    </>
  );
};
export default ViewOnBoardingStatus;
