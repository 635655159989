import * as XLSX from "xlsx";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import { getEmployeeListDownload } from "../../../../Utils/axios/InductionApi";
import moment from "moment";
import { useQuery } from "react-query";

const ReviewFileDownload = () => {
  const { refetch } = useQuery(
    ["getEmployeeListDownload"],
    () => getEmployeeListDownload(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        handleDownloadButtonClick(res?.empDetails);
      },
    }
  );

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Emp Code",
        "Employee Name",
        "Date of joining",
        "Designation",
        "Department",
        "Induction Status",
        "Folder Link",
        ...data[0].employeeInductionDetailsList?.map((e)=>e?.checkListName)
      ], // Header row
      ...data.map((val) => [
        val.empCode,
        val?.empName,
        moment(val.dateOfJoining).format("DD MM YYYY"),
        val.department,
        val.designation,
        val.overallStatus?"Done" : "In-Progress",
        val.folderLink,
        ...val.employeeInductionDetailsList?.map((e)=>e?.status||"")
      ]), // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data &&data?.length>0&& generateExcelData(data);

    downloadExcelFile(workbook);
  }

  return (
    <div>
      <FiDownload
        style={{ color: "#4B59C3", verticalAlign: 'baseline' }}
        onClick={() => refetch()}
      />
    </div>
  );
};

export default ReviewFileDownload;
