import * as XLSX from "xlsx";
import React from "react";
import { saveAs } from "file-saver";
import { BsDownload } from "react-icons/bs";
import { useQuery } from "react-query";
import { downloadData } from "../../../../Utils/axios/PortalManagement";
const ExcelFileDownloadVendor = ({ id }) => {
  const { refetch } = useQuery(["downloadData"], () => downloadData(id), {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (res) => {
      handleDownloadButtonClick(res?.allPartners);
    },
  });

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Vendor Code",
        "Vendor Name",
        "Contact Name",
        "Email Id",
        "Mobile Number",
        "Secondary Name",
        "Secondary Email Id",
        "Secondary Mobile Number",
        "PAN",
        "GSTIN",
        "GST Applicable",
        "TDS Applicable",
        "Address",
        "City",
        "State",
        "Pin",
        "Country",
        "PAN",
        "Payment Due Days",
        "SLA",
      ], // Header row
      ...data.map((val) => [
        val?.venderCode,
        val?.venderName,
        val?.primaryContactName,
        val?.primaryContectEmail,
        val?.primaryMobileNumber,
        val?.seconderyContactName,
        val?.seconderyContectEmail,
        val?.seconderyMobileNumber,
        val?.pan,
        val?.gstin,
        val?.gstApplicable,
        val?.tdsApplicable,
        val?.addressLine1,
        val?.city,
        val?.state,
        val?.pinCode,
        val?.country,
        val?.pan,
        val?.pamentDueDays,
        val?.sla,
      ]), // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);
    downloadExcelFile(workbook);
  }
  return (
    <div>
      <BsDownload
        style={{
          fontSize: "1.5rem",
          color: "#C3B14B",
          fontWeight: "bold",
          marginLeft: "2rem",
          cursor: "pointer",
        }}
        onClick={() => refetch()}
      />
    </div>
  );
};

export default ExcelFileDownloadVendor;
