import React from 'react';

const ModalView = (props) => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {props.children}
        </div>
    )
}

export default ModalView;