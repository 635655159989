import React, { useState } from "react";
import Header from "../../../GenericComponent/header/CommonHeader";
import SideBar from "../sidebar/SideBar";
import style from "./ClientContractView.module.scss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  getAllClientContractById,
  getEngagementList,
  addCommets,
  getCommetsList,
} from "../../../Utils/axios/clientContractApi";
import { useMutation, useQuery } from "react-query";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import {
  checkIfEmpty,
  clientContractCode,
  employeeId,
} from "../../../Utils/constants";
import { path } from "../../../routes/PathNames";
import useValidationAccess from "../../custumHooks/useValidationAccess";
import CommonDropdown from "../../../GenericComponent/ui/CommonDropdown";
import axios from "axios";
import { ToastifyError, Toastify } from "../../../App";
import CustomTextArea from "../../../GenericComponent/TextAria/CustomTextArea";

function ClientContractView() {
  const history = useHistory();
  const param = useParams();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState({
    engagementStatus: null,
    engagementStatusId: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [validate, setValidate] = useState(false);
  const { checkValidateAccess } = useValidationAccess();
  const [showComment, setShowComment] = useState(false);
  const [showCommentLine, setShowCommentLine] = useState(false);
  const [commentLine, setCommentLine] = useState("");
  const [comment, setComment] = useState("");
  const handleShow = () => {
    setShowModal(true);
    setValidate(false);
    setSelectedOption({ engagementStatus: null, engagementStatusId: null });
  };

  const handleClose = () => {
    setShowModal(false);
    setValidate(false);
    setSelectedOption({ engagementStatus: null, engagementStatusId: null });
  };

  const { data: getAllClientContract, refetch } = useQuery(
    ["getAllClientContract"],
    () => getAllClientContractById(param.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const baseUrl = process.env.REACT_APP_CLIENT_CONTRACT_URL;

  const { data: getEngagementListOptions } = useQuery(
    ["getEngagementList"],
    () => getEngagementList(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: getCommetsListData,refetch:refetchComment } = useQuery(
    ["getCommetsList"],
    () => getCommetsList(param.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleCancel = () => {
    handleClose();
  };

  const handleConfirm = () => {
    if (!selectedOption?.engagementStatusId) {
      setValidate(true);
      return;
    }
    // Make the API call using Axios
    const URL = `change-engagement-status?clientId=${getAllClientContract?.clientId}&engagementStatusId=${selectedOption?.engagementStatusId}`;

    axios
      .put(`${baseUrl}/${URL}`)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 1) {
          Toastify("Change Engagement Status Success");
          refetch();
        } else {
          ToastifyError("SomeThing Went Wrong");
        }
      })
      .catch((err) => {
        // Handle error if needed
        console.error("API call error:", err);
      });

    // Pass the selected option to the parent component
    handleClose();
  };
  const mutaion = useMutation(addCommets, {
    onSuccess: (data) => {
      if (data.responseStatus.statusCode === 1) {
        refetchComment();
        Toastify("Add Comment Success");
      } else {
        ToastifyError("SomeThing Went Wrong");
      }
    },
  });

  const handleComment = () => {
    if (!comment) {
      setValidate(true);
    } else {
      setValidate(false);
      mutaion.mutate({
        comment: comment,
        contractId: param.id,
        empId: employeeId,
      });
      setShowComment(false);
      setComment("");
    }
  };

  const handleClientContracts1 = () => {
    location?.state?.page1 === "Client Contracts"
      ? history.push({
          pathname: path.cc_contract_ims.replace(
            ":name",
            "client-contracts"
          ),
          state: {
            ...location.state,
          },
        })
      : history.push({
          pathname: path.cc_list.replace(
            ":name",
            "expiring-contracts"
          ),
          state: {
            ...location.state,
          },
        });
  }

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <div style={{ width: "20vw" }}>
          <SideBar />
        </div>
        <div style={{ width: "80vw", padding: "1rem" }}>
          <div className={style.titleBtn}>
            <p className={style.title}>
              <span
                style={{ color: "#562679", cursor: "pointer" }}
                onClick={() => handleClientContracts1()}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleClientContracts1();
                  }
                }}
                tabIndex={0}
              >
                {location?.state?.page1}
                {" > "}
              </span>
              <span
                style={{ color: "#562679", cursor: "pointer" }}
                onClick={() => history.go(-1)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    history.go(-1);
                  }
                }}
                tabIndex={0}
              >
                {location?.state?.page2}
                {" > "}
              </span>
              Contract View
            </p>
          </div>
          <div className={style.card}>
            <p className={style.heading}>Details</p>
            <div className={style.form}>
              <div className={style.row1}>
                <div className={style.flex}>
                  <label>Contract Type</label>
                  <p>{getAllClientContract?.contractType}</p>
                </div>
                <div className={style.flex}>
                  <label>Sales Representative</label>
                  <p>{getAllClientContract?.salesRepresentative}</p>
                </div>
                <div className={style.flex}>
                  <label>Client Name</label>
                  <p>{getAllClientContract?.clientName}</p>
                </div>
                <div className={style.flex}>
                  <label>Business Type</label>
                  <p>{getAllClientContract?.businessType}</p>
                </div>
              </div>
              <div className={style.row2}>
                <div className={style.flex}>
                  <label>Executed By</label>
                  <p>{getAllClientContract?.executedBy}</p>
                </div>
                <div className={style.flex}>
                  <label>Engagement Status</label>
                  <p>{getAllClientContract?.engagementStatus}</p>
                </div>
                <div className={style.flex}>
                  <label>Contract Terms (Month)</label>
                  <p>{getAllClientContract?.contractTerm}</p>
                </div>
                <div className={style.flex}>
                  <label>Closed Contract Reason</label>
                  <p>{getAllClientContract?.closeCotractName}</p>
                </div>
              </div>
              <div className={style.row3}>
                {getAllClientContract?.clientContractStatus ? (
                  <div className={style.flex}>
                    <label>Contract Status</label>
                    <p>{getAllClientContract?.clientContractStatus}</p>
                  </div>
                ) : (
                  <div className={style.flex}>
                    <label>Existing Status</label>
                    <p>{getAllClientContract?.existingStatus}</p>
                  </div>
                )}
                <div className={style.flex}>
                  <label>Contract Start Date</label>

                  <p>
                    {checkIfEmpty(
                      getAllClientContract?.startDate,
                      moment(getAllClientContract?.startDate).format(
                        "DD MMM YY"
                      )
                    )}
                  </p>
                </div>

                <div className={style.flex}>
                  {" "}
                  <label>Contract End Date</label>
                  <p>
                    {checkIfEmpty(
                      getAllClientContract?.endDate,
                      moment(getAllClientContract?.endDate).format("DD MMM YY")
                    )}
                  </p>
                </div>
                <div className={style.flex}>
                  <label>Contract Reminder Date</label>

                  <p>
                    {checkIfEmpty(
                      getAllClientContract?.reminderDate,
                      moment(getAllClientContract?.reminderDate).format(
                        "DD MMM YY"
                      )
                    )}
                  </p>
                </div>
              </div>
              <div className={style.row4}>
                <div className={style.flex}>
                  <label>Resource Name</label>
                  <p>
                    {getAllClientContract?.resources
                      ?.map((val) => val.name)
                      .join(" , ")}
                  </p>
                </div>
                <div className={style.flex}>
                  <label>File URL</label>

                  <p
                    className={style.link}
                    onClick={() =>
                      window.open(getAllClientContract?.fileUrl, "_blank")
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        window.open(getAllClientContract?.fileUrl, "_blank")
                      }
                    }}
                    tabIndex={0}
                  >
                    Link
                  </p>
                </div>
                <div className={style.flex}>
                  <label>File Folder</label>
                  <p
                    className={style.link}
                    onClick={() =>
                      window.open(getAllClientContract?.fileUrl, "_blank")
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        window.open(getAllClientContract?.fileUrl, "_blank")
                      }
                    }}
                    tabIndex={0}
                  >
                    Link
                  </p>
                </div>
                <div className={style.flex}></div>
              </div>
              <div className={style.row5}>
                <div className={style.flex}>
                  <label>Ongoing Contract</label>
                  <p>{getAllClientContract?.ongoing ? "Yes" : "No"}</p>
                </div>
                <div className={style.flex}>
                  <label>Tracking</label>
                  <p>{getAllClientContract?.traking ? "Yes" : "No"}</p>
                </div>
                <div className={style.flex}></div>
                <div className={style.flex}></div>
              </div>

              <div className={style.row6}>
                <div className={style.flex} style={{ width: "100%" }}>
                  <label>Notes</label>
                  <p>
                    {getAllClientContract?.notes &&
                      getAllClientContract.notes
                        .split("\n")
                        .map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={style.btn}>
            <button
              // className={style.changeStatusBtn}
              onClick={() => {
                setShowComment(true);
              }}
            >
              Add Comment
            </button>

            {checkValidateAccess(clientContractCode.Edit) && (
              <button
                className={style.changeStatusBtn}
                onClick={() => {
                  handleShow();
                }}
              >
                Change Engagement Status
              </button>
            )}
            {checkValidateAccess(clientContractCode.Edit) && (
              <button
                onClick={() => {
                  history.push({
                    pathname:
                      location?.state?.page1 === "Client Contracts"
                        ? path.cc_edit.replace(":name", "client-contracts")
                        : location?.state?.page1 ===
                          "Missing/Inprogress Contract"
                        ? path.cc_edit.replace(
                            ":name",
                            "missing-inprogress-contracts"
                          )
                        : path.cc_edit.replace(":name", "expiring-contracts"),
                    state: {
                      ...location?.state,
                      clientId: getAllClientContract?.id,
                      page2: "",
                    },
                  });
                }}
              >
                Edit
              </button>
            )}
            {checkValidateAccess(clientContractCode.Done) && (
              <button
                onClick={() => {
                  history.push({
                    pathname:
                      location?.state?.page1 === "Client Contracts"
                        ? path.cc_listById
                            .replace(":id", getAllClientContract.clientId)
                            .replace(":name", "client-contracts")
                        : location?.state?.page1 ===
                          "Missing/Inprogress Contract"
                        ? path.cc_listById
                            .replace(":id", getAllClientContract.clientId)
                            .replace(":name", "missing-inprogress-contracts")
                        : path.cc_listById
                            .replace(":id", getAllClientContract.clientId)
                            .replace(":name", "expiring-contracts"),
                    state: { ...location?.state },
                  });
                }}
              >
                Done
              </button>
            )}
          </div>
          <div className={style.table} style={{marginTop:"1rem"}}>
            <div className={style.th}>
              <p> Comment Date</p>
              <p> Comment By</p>
              <p> Comment</p>
            </div>
            <div>
              {getCommetsListData?.length>0?getCommetsListData?.map((val) => (
                <div className={style.td} key={val?.commentDate} >
                  <p>{moment(val?.commentDate).format("DD MMM YY")}</p>
                  <p>{val?.commentedBy}</p>
                  {val?.comment?.length > 130 ? (
                    <p onClick={()=>{setCommentLine(val?.comment) 
                    setShowCommentLine(true)
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        setCommentLine(val?.comment) 
                    setShowCommentLine(true)
                      }
                    }}
                    tabIndex={0}
                    style={{color:"#562679",cursor:"pointer"}}
                    > {`${val?.comment.slice(0, 130)} ...`} </p>
                  ) : (
                    <p>{val.comment}</p>
                  )}
                </div>
              )):<p style={{textAlign:"center"}}>No Comments</p>}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Engagement Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommonDropdown
            value={checkIfEmpty(selectedOption?.engagementStatusId, {
              id: selectedOption?.engagementStatusId,
              label: selectedOption?.engagementStatus,
            })}
            onChange={(e) => {
              setSelectedOption({
                ...selectedOption,
                engagementStatusId: e.id,
                engagementStatus: e.label,
              });
            }}
            options={getEngagementListOptions}
            placeholder="Engagement Status"
            customInputOnClick={() =>
              setSelectedOption({ ...selectedOption, engagementStatusId: "" })
            }
            color="#562679"
          />
          {validate && !selectedOption.engagementStatusId ? (
            <p style={{ color: "red" }}>Please Select Engagement Status</p>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            style={{ background: "#562679", border: "none" }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showComment} onHide={() => setShowComment(false)} centered>
        <Modal.Body>
          <CustomTextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label=" Add Comment"
            styles={{ width: "100%", height: "100px" }}
            mandate={true}
          />
          {validate && !comment ? (
            <p style={{ color: "red" }}>Please Enter Comments </p>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowComment(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleComment}
            style={{ background: "#562679", border: "none" }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCommentLine}
        onHide={() => setShowCommentLine(false)}
        centered
      >
        <Modal.Body>
          {
            <div className={style.commentLine}>
              <p>{commentLine}</p>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setShowCommentLine(false)}
            style={{ background: "#562679", border: "none" }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default ClientContractView;
