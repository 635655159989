import React from "react";
import SideBar from "../../sidebar/SideBar";
import style from "./newEmployee.module.scss";
import { useQuery } from "react-query";
import { getAllJoinedCandidates } from "../../../../Utils/axios/api";
import { getNextMonthAnniversaries } from "../../../../Utils/axios/umsApi";
import moment from "moment";
import { useHistory } from "react-router";
import { path } from "../../../../routes/PathNames";
const NewEmployee = () => {
  const { data, isLoading, isError } = useQuery(
    ["getAllJoinedCandidates"],
    () => getAllJoinedCandidates()
  );
  const {
    data: celebration,
    isLoading: isLoadingClb,
    isError: isErrorClb,
  } = useQuery(["getNextMonthAnniversaries"], () =>
    getNextMonthAnniversaries()
  );
  const history = useHistory();
  return (
    <div className={style.main_client_body}>
      <SideBar />
      <div className={style.main_create}>
      <div className={style.upcoming_celebration}>
        <div>
          <p className={style.vieUser}>Upcoming Joinees</p>
          <div className={style.createposition}>
            <div className={style.th}>
              <p>Candidate Name</p>
              <p>Joining Date</p>
              <p>Core Skill</p>
              <p>Experience</p>
            </div>
            {isLoading && <div className={style.noData}>Loading...</div>}
            {isError && (
              <div className={style.noData}>Something Went Wrong</div>
            )}
            {!isLoading && !isError && (
              <div>
                {data?.length > 0 ? (
                  <>
                    {data?.map((e) => {
                      return (
                        <div className={style.td}>
                          <p
                            onClick={() =>
                              history.push(path.ums_createUser, {
                                candidateId: e?.candidateId,
                                dropJoinDate: e?.dropJoinDate,
                              })
                            }
                          >
                            {e?.candidateName}
                          </p>
                          <p>{moment(e?.dropJoinDate).format("DD MMM YY")}</p>
                          <p>{e?.coreSkill}</p>
                          <p> {~~(e.totalExperience / 12)
                            ? ~~(e.totalExperience / 12)
                            : 0}{" "}
                            Y -{" "}
                            {e.totalExperience % 12
                              ? e.totalExperience % 12
                              : 0}{" "}
                            M</p>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className={style.noData}> No Data</div>
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <p className={style.vieUser}>Upcoming Celebrations</p>
          <div className={style.celebrations}>
            {isLoadingClb && (
              <div style={{ textAlign: "center" }}>Loading...</div>
            )}
            {isErrorClb && (
              <div style={{ textAlign: "center" }}>Something Went Wrong</div>
            )}
            {!isLoadingClb && !isErrorClb ? (
              celebration?.length > 0 ? (
                celebration?.map((e) => {
                  return (
                    <div className={style.celebrations_th}>
                      <div>
                        {e?.type === "DOB" && <p>🎂</p>}
                        {e?.type === "ANNIVERSARY" && <p>❣️</p>}
                        {e?.type === "workAnniversary" && <p>💻</p>}
                      </div>
                      <div>{moment(e?.date).format("DD MMM YY")}</div>
                      <div>{e?.empName}</div>
                    </div>
                  );
                })
              ) : (
                <div style={{ textAlign: "center" }}>No Data</div>
              )
            ) : null}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};
export default NewEmployee;
