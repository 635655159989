import React from "react";
import Header from "../../../GenericComponent/header/CommonHeader";

import SideBar from "../sidebar/SideBar";
import style from "./MissingInprogressContractView.module.scss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getAllClientContractById } from "../../../Utils/axios/clientContractApi";
import { useQuery } from "react-query";

import moment from "moment";
import { ToastContainer } from "react-toastify";
import { checkIfEmpty } from "../../../Utils/constants";

function MissingInprogressContractView() {
  const history = useHistory();
  const param = useParams();
  const location = useLocation();

  const { data: getAllClientContract } = useQuery(
    ["getAllClientContract"],
    () => getAllClientContractById(param.id),
    {}
  );
  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <div style={{ width: "20vw" }}>
          <SideBar />
        </div>
        <div style={{ width: "80vw", padding: "1rem" }}>
          <div className={style.titleBtn}>
            <p className={style.title}>
              <span
                style={{ color: "#562679", cursor: "pointer" }}
                onClick={() => history.go(-1)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    history.go(-1);
                  }
                }}
                tabIndex={0}
              >
                {location?.state?.page1}
                {" > "}
              </span>
             
              Missing/Inprogress Contract View
            </p>
          </div>
          <div className={style.card}>
            <p className={style.heading}>Details</p>
            <div className={style.form}>
              <div className={style.row1}>
                <div className={style.flex}>
                  <label>Contract Type</label>
                  <p>{getAllClientContract?.contractType}</p>
                </div>
                <div className={style.flex}>
                  <label>Sales Representative</label>
                  <p>{getAllClientContract?.salesRepresentative}</p>
                </div>
                <div className={style.flex}>
                  <label>Client Name</label>
                  <p>{getAllClientContract?.clientName}</p>
                </div>
                <div className={style.flex}>
                  <label>Business Type</label>
                  <p>{getAllClientContract?.businessType}</p>
                </div>
              </div>
              <div className={style.row2}>
                <div className={style.flex}>
                  <label>Executed By</label>
                  <p>{getAllClientContract?.executedBy}</p>
                </div>
                <div className={style.flex}>
                  <label>Contact Grouping</label>
                  <p>{getAllClientContract?.contractGrouping}</p>
                </div>
                <div className={style.flex}>
                  <label>Engagement Status</label>
                  <p>{getAllClientContract?.engagementStatus}</p>
                </div>
                <div className={style.flex}>
                  <label>Contract Terms (Month)</label>
                  <p>{getAllClientContract?.contractTerm}</p>
                </div>
              </div>
              <div className={style.row3}>
                <div className={style.flex}>
                  <label>Applicable Status</label>
                  <p>{getAllClientContract?.applicableStatus}</p>
                </div>
                {getAllClientContract?.clientContractStatus ? (
                  <div className={style.flex}>
                    <label>Contract Status</label>
                    <p>{getAllClientContract?.clientContractStatus}</p>
                  </div>
                ) : (
                  <div className={style.flex}>
                    <label>Existing Status</label>
                    <p>{getAllClientContract?.existingStatus}</p>
                  </div>
                )}

                <div className={style.flex}>
                  <label>Contract Start Date</label>

                  <p>
                    {checkIfEmpty(
                      getAllClientContract?.startDate,
                      moment(getAllClientContract?.startDate).format(
                        "DD MMM YY"
                      )
                    )}
                  </p>
                </div>
                <div className={style.flex}>
                  <label>Contract End Date</label>

                  <p>
                    {checkIfEmpty(
                      getAllClientContract?.endDate,
                      moment(getAllClientContract?.endDate).format("DD MMM YY")
                    )}
                  </p>
                </div>
              </div>
              <div className={style.row4}>
                <div className={style.flex}>
                  <label>Contract Reminder Date</label>

                  <p>
                    {checkIfEmpty(
                      getAllClientContract?.reminderDate,
                      moment(getAllClientContract?.reminderDate).format(
                        "DD MMM YY"
                      )
                    )}
                  </p>
                </div>
                <div className={style.flex}>
                  <label>Resource Name</label>
                  <p>{getAllClientContract?.resources?.map((val) => val.name).join(' , ')}</p>
                </div>
                <div className={style.flex}>
                  <label>File URL</label>

                  <p
                    className={style.link}
                    onClick={() =>
                      window.open(getAllClientContract?.fileUrl, "_blank")
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        window.open(getAllClientContract?.fileUrl, "_blank");
                      }
                    }}
                    tabIndex={0}
                  >
                    Link
                  </p>
                </div>
                <div className={style.flex}>
                  <label>File Folder</label>
                  <p
                    className={style.link}
                    onClick={() =>
                      window.open(getAllClientContract?.fileUrl, "_blank")
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        window.open(getAllClientContract?.fileUrl, "_blank");
                      }
                    }}
                    tabIndex={0}
                  >
                    Link
                  </p>
                </div>
              </div>
              <div className={style.row5}>
                <div className={style.flex} style={{ width: "100%" }}>
                  <label>Ongoing Contract</label>
                  <p>{getAllClientContract?.ongoing ? "Yes" : "No"}</p>
                </div>
              </div>
              <div className={style.row6}>
                <div className={style.flex} style={{ width: "100%" }}>
                  <label>Notes</label>
                  <p>
                    {getAllClientContract?.notes &&
                      getAllClientContract.notes
                        .split("\n")
                        .map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={style.btn}>
            {checkValidateAccess(clientContractCode.Edit) && (
              <button
                onClick={() => {
                  history.push({
                    pathname:
                      location?.state?.page1 === "Missing/Inprogress Contract"
                        ? path.cc_edit.replace(":name", "missing-inprogress-contracts")
                        : path.cc_edit.replace(":name", "missing-inprogress-contracts"),
                    state: {
                      ...location?.state,
                      clientId: getAllClientContract?.id,
                      page2: "",
                    },
                  });
                }}
              >
                Edit
              </button>
            )}
            {checkValidateAccess(clientContractCode.Done) && (
              <button
                onClick={() => {
                  history.push({
                    pathname:
                      location?.state?.page1 === "Missing/Inprogress Contract"
                        ? path.cc_listById
                            .replace(":id", getAllClientContract.clientId)
                            .replace(":name", "missing-inprogress-contracts")
                        : path.cc_listById
                            .replace(":id", getAllClientContract.clientId)
                            .replace(":name", "missing-inprogress-contracts"),
                    state: { ...location?.state },
                  });
                }}
              >
                Done
              </button>
            )}
          </div> */}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default MissingInprogressContractView;
