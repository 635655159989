import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./Milestones.module.css";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import Select from "react-select";
import { InvoiceStyles } from "../../../utils/style";
import { FaTrash } from "react-icons/fa";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import dropdownIcon from "../../../../../assets/downIcon.png";
import DatePicker from "react-datepicker";
import PurpleIcon from "../../../../../assets/Calander.png";
import cancel from "../../../../../assets/cancell.png";
import EditIcon from "../../../../../assets/Icon material-edit_.png";

import { useState } from "react";
import moment from "moment";
import CustomTooltip from "../../../../PartnerManagement/customTooltip/CustomTooltip";

import { useMutation, useQuery } from "react-query";

import {
  getViewPartner,
  addPartnerAgreement,
  ResourceType,
  AgreementIDByData,
  AgreementIDByHistory,
  ViewAgreementID,
  EditPartnerAgreement,
  createMilestone,
  getIdClientById,
  addProjectMilestone,
  getMilestone,
  getMilestoneById,
} from "../../../../../Utils/axios/PortalManagement";
import BreadCrum from "../../../../PartnerManagement/breadcrums/BreadCrum";

const Milestones = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {

  const [validate, setValidate] = React.useState(false);
  const [formData, setFormData] = useState({
    milestoneDetails: [],
  });

  const [lineItemArray, setLineItemArray] = useState([1]);
  const [AggrementData, setAggrementData] = useState(false);
  const [EditAgreement, setEditAgreement] = useState(true);
  const [EditId, setEditId] = useState();
  const [EditHistory, setEditHistory] = useState();
  const [EditOpenHistory, setEditOpenHistory] = useState(false);
  const [HideData, setHideData] = useState(false);

  const { data: PartnerDataById } = useQuery(
    ["getIdClientById2"],
    () => getIdClientById(prevData?.clientId),
    {}
  );

  const { data: getMileById } = useQuery(
    ["getMilestoneById", EditId],
    () => getMilestoneById && getMilestoneById(EditId),
    {
      enabled: EditId === undefined ? false : true
    }
  );

  const [formData1, setFormData1] = useState({
    amount: getMileById?.amount,
    name: getMileById?.name,
    projectPercentage: getMileById?.projectPercentage,
    timeFrame: getMileById?.timeFrame,
    clientId: prevData?.clientId,
    milestoneId: EditId,
  });

  useEffect(() => {
    setFormData1((prevValue) => ({
      ...prevValue,
      amount: getMileById?.amount,
      name: getMileById?.name,
      projectPercentage: getMileById?.projectPercentage,
      timeFrame: getMileById?.timeFrame,
      clientId: prevData?.clientId,
      milestoneId: EditId,
    }));
  }, [getMileById]);

  const {
    data: milstonesList,
    isLoading2,
    isError2,
    refetch,
  } = useQuery(
    ["getMilestone"],
    () => getMilestone && getMilestone(prevData?.clientId)
  );

  const mutation = useMutation(addProjectMilestone, {
    onSuccess: (res) => {
      refetch();
      setFormData((prevValue) => ({
        milestoneDetails: [
          {
            amount: "",
            name: "",
            projectPercentage: "",
            timeFrame: "",
            clientId: prevData?.clientId,
          },
        ],
      }));
    },
  });
  const mutation1 = useMutation(addProjectMilestone, {
    onSuccess: (res) => {
      refetch();
      setFormData1((prevValue) => ({
        ...prevValue,
        name: "",
        timeFrame: "",
        projectPercentage: "",
        amount: "",
        clientId: prevData?.clientId,
      }));
    },
  });

  // const { data: getAggrementData } = useQuery(["ResourceType"], ResourceType);

  // const getAggrementDataoption = getAggrementData?.map((e) => ({
  //   value: e.id,
  //   label: e.name,
  // }));

  const lineItemDetailsFn = (item) => {
    let status = true;
    for (let val of item) {
      if (
        val?.name === "" ||
        val?.timeFrame === "" ||
        val?.projectPercentage === "" ||
        val?.amount === ""
      ) {
        status = true;
        break;
      } else {
        status = false;
      }
    }
    if (status) {
      setValidate(true);
      return false;
    } else {
      return true;
    }
  };

  const HandlerAgreement = () => {
    if (lineItemDetailsFn(formData?.milestoneDetails)) {
      mutation.mutate(formData);
      setAggrementData(true);
      setValidate(false);
    } else {
      setValidate(true);
    }
  };

  const EditAgreementHandler = (id) => {
    setEditId(id);
    setEditAgreement(false);
  };

  const HandlerAgreementHistory = (id) => {
    setEditHistory(id);
    setEditOpenHistory(true);
  };

  const EditHandlerAgreement = () => {
    if (formData1?.name) {
      let data = {
        milestoneDetails: [
          {
            amount: formData1?.amount,
            name: formData1?.name,
            projectPercentage: formData1?.projectPercentage,
            timeFrame: formData1?.timeFrame,
            clientId: prevData?.clientId,
            milestoneId: formData1?.milestoneId,
          },
        ],
      };
      mutation1.mutate(data);
      setValidate(false);
    } else {
      setValidate(true);
    }
  };

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    const clearData = () => {
      setFormData((prevFormData) => {
        const updatedAgreementDetails = prevFormData.milstones.map(
          (detail, index) => {
            return {
              ...detail,
              [props?.properties]: "", // Clear the specific property value
            };
          }
        );

        return {
          ...prevFormData,
          milstones: updatedAgreementDetails,
        };
      });
    };

    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={clearData}
          alt=""
        />
      </div>
    );
  });

  const CustomInputClg1 = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross1 = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() =>
            setFormData1({ ...formData1, [props?.properties]: "" })
          }
          alt=""
        />
      </div>
    );
  });


  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        {/* <Row>
          <Col>
            <h6 style={{ marginLeft: "25px" }}>Agreement</h6>
          </Col>
        </Row> */}
        <InvoiceContainer>
          <Row>
            <Col>
              <h6 className={classes.primaryContact}>Project Details</h6>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Client Name</p>
              <p className={classes.partnerlabal}>
                {prevData?.getProjectData?.clientName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Project Name</p>
              <p className={classes.partnerlabal}>
                {prevData?.getProjectData?.name}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Type of Project</p>
              <p className={classes.partnerlabal}>{prevData?.getProjectData?.typeofproject}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Status</p>
              <p className={classes.partnerlabal}>
                {prevData?.getProjectData?.status}
              </p>
            </Col>
          </Row>
        </InvoiceContainer>
        {EditAgreement ? (
          <>
            <InvoiceContainer>
              <div className={classes.copy_expence}>
                Add Project
                <span style={{ marginLeft: "10px", float: "right" }}>
                  <InvoiceButton
                    type={"plus2"}
                    style={{ width: "9%", height: "30px", paddingTop: "2px" }}
                    onClick={() => {
                      setHideData(true);
                      setLineItemArray([
                        ...lineItemArray,
                        Math.floor(Math.random() * 100),
                      ]);
                      setFormData({
                        ...formData,
                        milestoneDetails: [
                          ...formData?.milestoneDetails,
                          {
                            name: "",
                            timeFrame: "",
                            projectPercentage: "",
                            amount: "",
                            clientId: prevData?.clientId,
                          },
                        ],
                      });
                    }}
                  >
                    Add
                  </InvoiceButton>
                </span>
              </div>
              {/*row 1*/}

              <Row>
                <div className={classes.Container1}>
                  <div className={classes.col12_item}>
                    <p className={classes.label_item_resource}>
                      Milestone Name <span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <div className={classes.col2_item}>
                    <p
                      className={classes.label_item_resource_type}
                      style={{ paddingLeft: "37px" }}
                    >
                      Time Frame <span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <div className={classes.col3_item}>
                    <p
                      className={classes.label_item5}
                      style={{ paddingLeft: "75px" }}
                    >
                      Percentage <span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <div className={classes.col4_item}>
                    <p
                      className={classes.label_item6}
                      style={{ paddingLeft: "111px" }}
                    >
                      Amount <span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                  <div className={classes.col5_item}>
                    <p className={classes.label_item}></p>
                  </div>
                </div>
              </Row>
              {HideData
                ? formData?.milestoneDetails?.map((item, index) => {
                  return (
                    <>
                      <div className={classes.Container} key={index}>
                        <div className={classes.col12_item}>
                          <input
                            style={{ marginLeft: "-12px" }}
                            type="text"
                            className={classes.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.milestoneDetails];
                              temp[index].name = e?.target?.value;
                              setFormData({
                                ...formData,
                                milestoneDetails: temp,
                              });
                            }}
                            value={formData?.milestoneDetails[index]?.name}
                          />
                          <p
                            className={
                              validate &&
                                !formData?.milestoneDetails[index]?.name
                                ? classes.errorText
                                : classes.errorTextHidden
                            }
                          >
                            Please Enter Name Milestone
                          </p>
                        </div>
                        <div
                          className={classes.col2_item}
                          style={{ marginLeft: "-25px" }}
                        >
                          <input
                            style={{ marginLeft: "-12px" }}
                            type="text"
                            className={classes.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.milestoneDetails];
                              temp[index].timeFrame = e?.target?.value;
                              setFormData({
                                ...formData,
                                milestoneDetails: temp,
                              });
                            }}
                            value={
                              formData?.milestoneDetails[index]?.timeFrame
                            }
                          />
                          <p
                            className={
                              validate &&
                                !formData?.milestoneDetails[index]?.timeFrame
                                ? classes.errorText
                                : classes.errorTextHidden
                            }
                          >
                            Please Enter Time Frame
                          </p>
                        </div>
                        <div className={classes.col3_item}>
                          <input
                            style={{ marginLeft: "-12px" }}
                            type="number"
                            className={classes.smallInput}
                            onChange={(e) => {
                              const input = e.target.value;
                              const isValidInput = /^(100|[0-9]{0,2})$/.test(
                                input
                              );
                              if (isValidInput) {
                                let temp = [...formData?.milestoneDetails];
                                temp[index].projectPercentage =
                                  input === "" ? "" : parseInt(input);
                                setFormData({
                                  ...formData,
                                  milestoneDetails: temp,
                                });
                              }
                            }}
                            value={
                              formData?.milestoneDetails[index]
                                ?.projectPercentage
                            }
                          />
                          <p
                            className={
                              validate &&
                                !formData?.milestoneDetails[index]
                                  ?.projectPercentage
                                ? classes.errorText
                                : classes.errorTextHidden
                            }
                          >
                            Please Enter Percentage
                          </p>
                        </div>
                        <div className={classes.col4_item}>
                          <input
                            style={{ marginLeft: "-12px" }}
                            type="number"
                            min={0}
                            className={classes.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.milestoneDetails];
                              temp[index].amount = e?.target?.value;
                              setFormData({
                                ...formData,
                                milestoneDetails: temp,
                              });
                            }}
                            value={formData?.milestoneDetails[index]?.amount}
                            onKeyDown={handleKeyDown}
                            onClick={handleInputClick}
                          />
                          <p
                            className={
                              validate &&
                                !formData?.milestoneDetails[index]?.amount
                                ? classes.errorText
                                : classes.errorTextHidden
                            }
                          >
                            Please Enter Amount
                          </p>
                        </div>

                        <div className={classes.col8_item}>
                          {formData?.milestoneDetails?.length > 1 && (
                            <>
                              <FaTrash
                                className={classes.remove_icon}
                                size={20}
                                onClick={(e) => {
                                  setLineItemArray(
                                    lineItemArray.filter((_, i) => {
                                      return i !== index;
                                    })
                                  );
                                  setFormData({
                                    ...formData,
                                    milestoneDetails:
                                      formData?.milestoneDetails?.filter(
                                        (_, i) => i !== index
                                      ),
                                  });
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })
                : ""}
              <button className={classes.buttonAdd} onClick={HandlerAgreement}>
                Submit
              </button>
            </InvoiceContainer>
          </>
        ) : (
          <>
            <InvoiceContainer>
              <div className={classes.copy_expence}>Edit Project</div>
              {/*row 1*/}
              <>
                <div className={classes.Container}>
                  <div className={classes.col12_item}>
                    <p className={classes.label_item_resource1}>
                      Name Milestone <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="text"
                      className={classes.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          name: e.target.value,
                        });
                      }}
                      value={formData1?.name}
                    />
                    <p
                      className={
                        validate && !formData1?.name
                          ? classes.errorText
                          : classes.errorTextHidden
                      }
                    >
                      Please Enter Name Milestone
                    </p>
                  </div>
                  <div className={classes.col2_item}>
                    <p className={classes.label_item_resource2}>
                      Time Frame <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="text"
                      className={classes.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeFrame: e.target.value,
                        });
                      }}
                      value={formData1?.timeFrame}
                    />
                    <p
                      className={
                        validate && !formData1?.timeFrame
                          ? classes.errorText
                          : classes.errorTextHidden
                      }
                    >
                      Please Enter Time Frame
                    </p>
                    {/* <Select
                      styles={InvoiceStyles}
                      options={getAggrementDataoption}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={getAggrementDataoption?.find(
                        (e) => e.value === formData1?.rateTypeId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          rateTypeId: e?.value,
                        });
                      }}
                    /> */}
                  </div>
                  <div className={classes.col3_item}>
                    <p className={classes.label_item_resource3}>
                      Percentage <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="text"
                      className={classes.smallInput}
                      // onChange={(e) => {
                      //   setFormData1({
                      //     ...formData1,
                      //     projectPercentage: e.target.value,
                      //   });
                      // }}
                      onChange={(e) => {
                        const input = e.target.value;
                        const isValidInput = /^(100|[0-9]{0,2})$/.test(input);
                        if (isValidInput) {
                          setFormData1({
                            ...formData1,
                            projectPercentage: e.target.value,
                          });
                        }
                      }}
                      value={formData1?.projectPercentage}
                    />
                    <p
                      className={
                        validate && !formData1?.projectPercentage
                          ? classes.errorText
                          : classes.errorTextHidden
                      }
                    >
                      Please Enter Percentage
                    </p>
                  </div>
                  <div className={classes.col4_item}>
                    <p className={classes.label_item_resource4}>
                      Amount <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="text"
                      className={classes.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          amount: e.target.value,
                        });
                      }}
                      value={formData1?.amount}
                      onKeyDown={handleKeyDown}
                      onClick={handleInputClick}
                    />
                    <p
                      className={
                        validate && !formData1?.amount
                          ? classes.errorText
                          : classes.errorTextHidden
                      }
                    >
                      Please Enter Amount
                    </p>
                  </div>
                  {/* <div className={classes.col5_item}>
                    <p className={classes.label_item9}></p>
                    <input
                      type="number"
                      className={classes.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          rate: e.target.value,
                        });
                      }}
                      value={formData1?.rate}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div> */}
                </div>
              </>
            </InvoiceContainer>
            <button
              className={classes.buttonAdd4}
              onClick={() => {
                EditHandlerAgreement();
                setFormData((prevData) => ({
                  ...prevData,
                  name: "",
                  timeFrame: "",
                  projectPercentage: "",
                  amount: "",
                  clientId: prevData?.clientId,
                }));
              }}
            >
              Submit
            </button>
          </>
        )}

        <Row>
          <Col>
            <h6 style={{ marginLeft: "14px", marginTop: "50px" }}>
              Milestones
            </h6>
          </Col>
        </Row>
        <Col md={12}>
          <InvoiceContainer>
            <div className={classes.table}>
              <div className={classes.th1}>
                <p className={classes.tableContent}>Name</p>
                <p className={classes.tableContent}>Time Frame</p>
                <p className={classes.tableContent}>Percentage</p>
                <p className={classes.tableContent}>Amount</p>
                {/* <p className={classes.tableContent}>Rate</p> */}
                <p className={classes.tableContent}>Action</p>
              </div>
              <div>
                {isLoading2 && <p className={classes.no_data}>Loading .....</p>}
                {!isLoading2 && !isError2 ? (
                  milstonesList?.length > 0 ? (
                    typeof milstonesList !== "string" &&
                    milstonesList?.map((item, index) => {
                      return (
                        <div
                          className={classes.td1}
                          key={item?.positionId}
                          style={{ position: "relative" }}
                        >
                          {/* <p
                            className={classes.tableContent}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() =>
                              HandlerAgreementHistory(item?.agreementId)
                            }
                          >
                            {item?.resource}
                          </p> */}

                          <p
                            onClick={() =>
                              HandlerAgreementHistory(item?.milestoneId)
                            }
                          >
                            <CustomTooltip
                              className={classes.tableContent}
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              value={item?.name}
                              limit={15}
                              index={index}

                            // tooltipstyle={{ left: "55%" }}
                            />
                          </p>

                          <p className={classes.tableContent}>
                            {item?.timeFrame}
                          </p>

                          <p className={classes.tableContent}>
                            {/* {item?.startDate
                              ? moment(item?.startDate).format("DD MMM YY")
                              : ""} */}
                            {item?.projectPercentage}
                          </p>

                          <p className={classes.tableContent}>
                            {/* {item?.endDate
                              ? moment(item?.endDate).format("DD MMM YY")
                              : ""} */}
                            {item?.amount}
                          </p>

                          {/* <p className={classes.tableContent}>{item?.rate}</p> */}
                          <p
                            className={classes.tableContent}
                            onClick={() => {
                              EditAgreementHandler(item?.id);
                            }}
                          >
                            <img
                              src={EditIcon}
                              alt=""
                              style={{ cursor: "pointer" }}
                            />
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={classes.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            </div>
          </InvoiceContainer>
        </Col>

        {/* <Col md={12} style={{ marginTop: "10px" }}>
          <Row>
            <Col>
              <h6 style={{ marginLeft: "25px" }}>Resource History</h6>
            </Col>
          </Row>

          <InvoiceContainer>
            <div className={classes.table}>
              <div className={classes.th}>
                <p className={classes.tableContent}>Resource</p>
                <p className={classes.tableContent}>Resource Type</p>
                <p className={classes.tableContent}>Start Date</p>
                <p className={classes.tableContent}>End Date</p>
                <p className={classes.tableContent}>Rate</p>
                <p></p>
              </div>
              <div>
                {isLoading && <p className={classes.no_data}>Loading .....</p>}
                {!isLoading1 && !isError1 ? (
                  AgreementDataTech?.length > 0 ? (
                    typeof AgreementDataTech !== "string" &&
                    AgreementDataTech?.map((item, index) => {
                      return (
                        <div
                          className={classes.td}
                          key={item?.positionId}
                          style={{ position: "relative" }}
                        >

                          <CustomTooltip
                            value={item?.resource}
                            limit={15}
                            className={`${classes.tableContent} ${classes.link}`}
                            index={`resource${index}`}
                          />
                          <p className={classes.tableContent}>
                            {item?.resourceType}
                          </p>

                          <p className={classes.tableContent}>
                            {item?.startDate
                              ? moment(item?.startDate).format("DD MMM YY")
                              : ""}
                          </p>

                          <p className={classes.tableContent}>
                            {item?.endDate
                              ? moment(item?.endDate).format("DD MMM YY")
                              : ""}
                          </p>

                          <p className={classes.tableContent}>{item?.rate}</p>
                          <p></p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={classes.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            </div>
          </InvoiceContainer>
        </Col> */}
        <button
          className={classes.buttonAdd1}
          onClick={() => {
            setNextScreen("PartnerList");
            setPrevData((prevData) => ({
              ...prevData,
              page2: "PartnerList",
              page3: null,
              page4: null,
              active: "PartnerList",
            }));
          }}
        >
          Done
        </button>
      </Container>
    </>
  );
};
export default Milestones;
