import { useQuery } from "react-query";
import { getEmployeeProposedDetail } from "../../../../../Utils/axios/AppraisalApi";
import style from "./ProposedDetailsComponent.module.css";
import moment from 'moment'
import { addCommas } from "../../utils/AddCommas";
function ProposedDetailsComponent({empId}) {
  const { data:employeeProposedDetails, isLoading:employeeProposedDetailsLoading} = useQuery(["getEmployeeProposedDetail"], () =>
  getEmployeeProposedDetail(empId)
  );
let currentData=[];
let titles=[];
if(!employeeProposedDetailsLoading && employeeProposedDetails)
{
  currentData = [
    employeeProposedDetails?.reward,
    employeeProposedDetails?.promotion,
    addCommas(employeeProposedDetails?.incrementAmount),
    employeeProposedDetails?.incrementPercentage,
    employeeProposedDetails?.applicableDate && moment(employeeProposedDetails?.applicableDate).format("DD MMM YY"),
    addCommas(employeeProposedDetails?.newMonthlyCoreCTC),
    addCommas(employeeProposedDetails?.newYearlyCoreCTC),
    addCommas(employeeProposedDetails?.newMonthlyFullCTC),
    addCommas(employeeProposedDetails?.newYearlyFullCTC),
  ];
  titles = [
    "Reward",
    "Promotion",
    "Increment Amount",
    "Increment %",
    "Effective From",
    "New Core CTC(M)",
    "New Core CTC(A)",
    "New Full CTC(M)",
    "New Full CTC(A)",
    
  ];
}
  return (
    <>
     { employeeProposedDetails &&   <div>
      <div className={style.container}>
        <div className={style.title}>Proposed Details</div>
        <hr className={style.hr}></hr>
        <div className={style.EmpBox_row}>
          {titles.map((title, index) => {
            return (
              <div className={style.EmpBox_col} key={`PD${index}`}>
                <p className={style.EmpBox_label}>{title}</p>
                <p
                  className={
                    title === "Client Name"
                      ? style.EmpBox_Client
                      : style.EmpBox_field
                  }
                >
                  {currentData[index]?currentData[index]:currentData[index]===0?0:"-"}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>}
    </>
  );
}
export default ProposedDetailsComponent;
