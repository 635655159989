import style from "./overheadList.module.css";
import { useHistory } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CreateIcon from "@mui/icons-material/Create";
import React, { useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-responsive-modal";
import { useState, createContext, useContext } from "react";
import DatePicker from "react-datepicker";
import Moment from "moment/moment";
import calendarIcon from "../../../../assets/calendarIcon.png";
import { getOverheadLists } from "../../../../Utils/axios/pcm_Api";
import { notifyError } from "../../../atsDashboard/utils/notify";
import { useQuery } from "react-query";
import Loading from "../loading/loading";
import { addCommasWithDecimal } from "../../../Appraisal_System/commonComponent/utils/AddCommas";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { projectCostingAndMarginAccessCode } from "../../../../Utils/constants";

export default function OverheadsLists() {
  const [count, setCount] = useState(null);
  const { checkValidateAccess } = useValidationAccess();
  return (
    <div className={style.main}>
      {checkValidateAccess(
        projectCostingAndMarginAccessCode?.overheads_dashboard
      ) && (
        <>
          <Header count={count} />
          <div>
            <Body setCount={setCount} />
          </div>
        </>
      )}
    </div>
  );
}

function Header({ count }) {
  return (
    <div className={style.header}>
      <div className={`${style.heading} ${style.cmnt_icon} ${style.gap1rem}`}>
        <div>Overheads</div>
        <div>
          <p className={style.countPCM}>
            <span style={{ fontSize: "1.235rem", fontWeight: "500" }}>
              {count}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

function Body({ setCount }) {
  const { data, isLoading } = useQuery(
    ["overheadList"],
    () => getOverheadLists(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err) => notifyError("Something went wrong!"),
    }
  );

  const tableRow = data?.map((row, index) => (
    <Row key={row?.month + index} {...row} />
  ));

  useEffect(() => {
    setCount(data?.length);
  }, [data]);

  return (
    <div className={style.body}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={style.tableContainer}>
          <table>
            <tbody>
              <tr className={style.tHeading}>
                <th style={{ paddingLeft: "1.5rem" }}>Month</th>
                <th className={style.head1}>Year</th>
                <th className={style.head1}>
                  Overhead Amount <br /> (in lakhs)
                </th>
              </tr>
              {isLoading ? (
                <tr className={style.tRow}>
                  <td
                    colSpan={3}
                    style={{ textAlign: "center", padding: "10px" }}
                  >
                    No Overheads Available
                  </td>
                </tr>
              ) : (
                tableRow
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

function Row({ month, year, overheadCostWithoutExpensesByMonth }) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const convertToLakhs = (number) => {
    if (number === null || number === undefined) return null;
    const decimalsValue = roundToTwoDecimalPlaces(number / 100000);
    return decimalsValue === "0" ? "-" : decimalsValue;
  };
  function roundToTwoDecimalPlaces(number) {
    const tempVal = addCommasWithDecimal(number.toFixed(2));
    return tempVal === "0" ? "-" : tempVal;
  }

  function findMonth(index) {
    return months[index - 1];
  }
  return (
    <tr className={style.tRow}>
      <td className={style.month}>{findMonth(month)}</td>
      <td className={style.cell}>{year}</td>
      <td className={style.cell}>
        {/* <CurrencyRupeeIcon style={{ width: "14px", marginBottom: "2px" }} /> */}
        {overheadCostWithoutExpensesByMonth &&
          convertToLakhs(overheadCostWithoutExpensesByMonth)}
      </td>
    </tr>
  );
}
