
import EPRNavigation from "../Pages/EmployeePerformanceReview/navigation/EPRNavigation.js";

// function EmployeePerformanceReviewRoute() {
//   return (
//     <Route>
//       <Route
//         exact
//         path="/EmployeePerformanceReview"
//         component={EPRNavigation}
//       />
//     </Route>
//   );
// }
const EmployeePerformanceReviewRoute = [
  {
    path: "/EmployeePerformanceReview",
    component: EPRNavigation
  },
  // Add more routes as needed
];

export default EmployeePerformanceReviewRoute;
