import * as XLSX from "xlsx";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import { getEmployeeListDownload } from "../../../../../Utils/axios/InductionApi";
import moment from "moment";
import { useQuery } from "react-query";

const PoolResourcesDownload = ({data }) => {
  const [reportStatus, setReportStatus] = useState(true);
  
     
  function generateExcelData(data) {

    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Emp Code",
        "Employee Name",
        "Experience",
        "Experience Range",
        "Salary Range",
        "Technology",
        "Pool Start Date",
        "Bench Age",
        "last Comment Date",
      ], // Header row
      ...data?.map((val) => [
        val.empCode,
        val?.empName,
        `${~~(val.experience / 12)} Y - ${val.experience % 12} M`,
        val.experienceRange,
        val.salaryRange,
        val.skillName,
        val.poolStartdate&& moment(val.poolStartdate).format("DD MM YYYY"),
        val.benchAge,
        val.currentCommentDate&&moment(val.currentCommentDate).format("DD MM YYYY"),
      ])
       // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);

    downloadExcelFile(workbook);
  }

  return (
    <div>
      <FiDownload
        style={{ verticalAlign: "baseline" }}
        onClick={() => handleDownloadButtonClick(data)}
      />
    </div>
  );
};

export default PoolResourcesDownload;
