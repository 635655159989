import React, { useEffect, useState } from "react";
import style from "./ViewIssueCom.module.css";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory, useLocation } from "react-router";
import Moment from "moment";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import { Toastify, ToastifyError } from "../../../../../App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmPopup from "../../../commonComponent/ConfirmPopup";
import SourceIcon from "@mui/icons-material/Source";
import { withStyles } from "@material-ui/core/styles";
import { employeeId } from "../../../../../Utils/constants";
export const ViewIssueComponent = () => {
  const BlackTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "black",
      color: "#fff",
      fontSize: "13px",
      whiteSpace: "pre-line",
      padding: "0.5rem",
    },
  }))(Tooltip);
  const [issueById, setIssueByID] = useState();
  const [confirmModal2, setConfirmModal2] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [commentsDetailsById, setCommentsDetailsById] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [allcommentsDetailsById, setAllCommentsDetailsById] = useState();
  const [deletedId, setDeletedId] = useState();
  const [editCommentsId, setEditCommentsId] = useState();
  const [validate, setValidate] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [editCommentsShow, setEditCommentsShow] = useState(false);
  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const id = query.get("id");
  const history = useHistory();

  const getIssueById = (id) => {
    axios
      .get(url + `/getIssueById?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setIssueByID(res.data.responseData);
      })
      .catch(() => {});
  };

  // Redirect after Delete Issue

  const handleReDirect = () => {
    setTimeout(() => {
      history.push("/MyIssue");
    }, 3000);
  };

  // cancel Button Handler

  // Edit button Handler
  const EditHandler = () => {
    history.push(`/EditIssue?id=${id}`);
  };

  // Cancel Button Handler
  const cancelHandler = () => {
    history.goBack(-1);
  };
  //file icon  setting
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
      default:
        return;
    }
  };
  // Delete Handler
  const deleteIssue = () => {
    setConfirmModal(true);
  };

  // Delete Issue
  const deleteIssueHandler = () => {
    setDeleteConfirm(true);
  };

  useEffect(() => {
    if (deleteConfirm) {
      axios
        .delete(url + `/deleteIssue?id=${id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        })
        .then((res) => {
          Toastify("Delete Issue Successfully");
          setConfirmModal(false);
          handleReDirect();
        })
        .catch(() => {});
    }
  }, [deleteConfirm]);
  const getCommentsById = (id1) => {
    axios
      .get(url + `/get-comment-by-id?id=${id1}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCommentsDetailsById(res.data.responseData);
      })
      .catch(() => {});
  };
  const getAllCommentsById = (id) => {
    axios
      .get(url + `/get-all-comments-by-issue-id?issueId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setAllCommentsDetailsById(res.data.responseData);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getIssueById(id);
    getAllCommentsById(id);
  }, []);

  const cancelAddComments1 = () => {
    setEditCommentsShow(false);
  };
  const DeleteIssueHandler = (id1) => {
    axios
      .delete(url + `/delete-comment-by-id?empId=${employeeId}&id=${id1}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        Toastify("Deleted Comments Successfully");
        getAllCommentsById(id);
        setConfirmModal2(false);
      })
      .catch((err) => {
        return err;
      });
  };
  const editCommentsHandler = () => {
    const AddCommentsDataa = {
      comment: commentsDetailsById?.comment,
      empId: employeeId,
      issueId: id,
      id: editCommentsId,
    };
    axios
      .post(url + "/add-comment-in-issue", AddCommentsDataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          ToastifyError(res.message);
        } else {
          Toastify("Edit Comments Successfully");
          setEditCommentsShow(false);
          getAllCommentsById(id);
        }
      })
      .catch((err) => {
        return err;
      });
  };
  return (
    <>
      <ToastContainer />
      <div className={style.main}>
        <p className={style.viewIssuP}>View Issue</p>

        <div className={style.card}>
          <p className={style.title}>Issue Details</p>
          <div className={style.Cu_row1}>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Issue ID</p>
              <p>{`HRHD${issueById?.id}`}</p>
            </div>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Category</p>
              <p>{issueById?.categoryName}</p>
            </div>
            <div className={style.cu_col2}>
              <p className={style.cu_label}>Sub Category</p>
              <p>{issueById?.subCategoryName}</p>
            </div>
            <div className={style.cu_col3}>
              <p className={style.cu_label}>logged Date</p>
              <p>{Moment(issueById?.loggedDate).format("DD MMM YY")}</p>
            </div>
          </div>
          <div className={style.Cu_row1}>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Raised By</p>
              <p>{issueById?.raisedByName}</p>
            </div>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Status</p>
              <p>{issueById?.status}</p>
            </div>
            <div className={style.cu_col2}></div>
            <div className={style.cu_col3}></div>
          </div>
          <div className={style.cu_col1} style={{ marginLeft: "21px" }}>
            <p className={style.cu_label}>Description</p>
            <p className={style.description} style={{ whiteSpace: "pre-line" }}>
              {issueById?.description}
            </p>
          </div>
          <p className={style.cu_label1}>Attachment</p>
          <div style={{ paddingLeft: "22px", paddingBottom: "10px" }}>
            {checkfileType(issueById?.attachment?.split(".")[5])}
            <a
              style={{ textDecoration: "underline" }}
              href={issueById?.attachment}
              target="_blank"
              rel="noreferrer"
            >
              {issueById?.attachment.split("@")[1]}
            </a>
          </div>
        </div>
        <div className={style.buttonDiv}>
          <button className={style.cancelbtn} onClick={() => cancelHandler()}>
            Cancel
          </button>
          {issueById?.status === "Closed" ? null : (
            <>
              <button className={style.deletebtn} onClick={() => deleteIssue()}>
                Delete
              </button>
              <button className={style.submitbtn} onClick={EditHandler}>
                Edit
              </button>
            </>
          )}
        </div>
        {editCommentsShow ? (
          <div className={style.card1}>
            <p className={style.title}>Edit Comments</p>
            <textarea
              name="postContent"
              placeholder="Edit Comments"
              rows={2}
              onChange={(e) =>
                setCommentsDetailsById({
                  ...commentsDetailsById,
                  comment: e.target.value,
                })
              }
              style={{
                outline: "none",
                width: "98%",
                height: "5rem",
                paddingLeft: "5px",
                marginTop: "5px",
                resize: "none",
                marginLeft: "10px",
              }}
              value={commentsDetailsById?.comment}
            />
            <p
              className={
                validate && !commentsDetailsById?.comment?.trim()
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Comments
            </p>
            <div className={style.AddCommentsButtonAlign}>
              <button
                className={style?.cancelbtn}
                onClick={() => cancelAddComments1()}
              >
                Cancel
              </button>{" "}
              <button
                className={style?.editbtn}
                onClick={() => editCommentsHandler()}
              >
                Update
              </button>
            </div>
          </div>
        ) : null}
        <div className={style.IssueList}>
          {isLoading ? (
            <p className={style.noDataAlign}>Loading...</p>
          ) : (
            <table style={{ width: "100%" }}>
              <thead>
                <tr className={style.th}>
                  <th className={style.tableContent}>Date</th>
                  <th className={style.tableContent}>Added By</th>
                  <th className={style.tableContent} style={{ width: "23rem" }}>
                    Comments
                  </th>
                  <th className={style.tableContent}>Status</th>
                  <th className={style.tableContent}>Attachment</th>
                  {/* <th
                    className={style.tableContent}
                    style={{ textAlign: "end" }}
                  >
                    Action
                  </th> */}
                </tr>
              </thead>
              {/* {myIssueList?.issues?.length===0?<p className={style?.noDataAlign}>No Comments Available</p>:( */}
              {allcommentsDetailsById?.length === 0 ? (
                <p className={style?.noDataAlign}>No Comments Available</p>
              ) : (
                <tbody style={{ height: "20px" }}>
                  {/* {myIssueList?.issues.map((issue) => ( */}
                  {allcommentsDetailsById?.map((val) => (
                    <tr
                      key={val?.id}
                      className={style.td}
                      style={{ position: "relative" }}
                    >
                      <td className={style.tableContent}>
                        {Moment(val?.date).format("DD MMM YY")}
                      </td>
                      <td className={style.tableContent}>{val?.empName}</td>
                      {val?.comment.length > 60 ? (
                        <td
                          className={style.tableContent}
                          style={{ width: "23rem" }}
                        >
                          <BlackTooltip title={val?.comment}>
                            <p>{val?.comment}</p>
                          </BlackTooltip>
                        </td>
                      ) : (
                        <td
                          className={style.tableContent}
                          style={{ width: "23rem" }}
                        >
                          {val.comment}
                        </td>
                      )}
                      <td className={style.tableContent}>{val?.status}</td>
                      <td className={style.tableContent}>
                        <a
                          style={{ textDecoration: "underline" }}
                          href={val?.attachment}
                          target="_blank"
                        >
                          {val?.attachment && (
                            <SourceIcon className={style?.EditAndDeleteIcon} />
                          )}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          )}
        </div>
      </div>

      <ConfirmPopup open={confirmModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to Delete?
          </h5>
          <div className={style.btnCont}>
            <button
              className={style.yesBtn}
              onClick={() => deleteIssueHandler()}
            >
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={() => {
                setConfirmModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
      <ConfirmPopup open={confirmModal2}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to Delete?
          </h5>
          <div className={style.btnCont}>
            <button
              className={style.yesBtn}
              onClick={() => DeleteIssueHandler(deletedId)}
            >
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={() => {
                setConfirmModal2(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </>
  );
};
