export const api = {
  // api for client
  getClientData: "/getClient",
  getClientFilterData: "/getClientForFilter",
  deleteClient: "/deleteClient",
  getClientDetails: "/getClientDetails",
  changeClientStatus: "/changeClientStatus",
  createClient: "/createClient",
  editClient: "/editClient",
  getPoolResourceCount: "/getPoolResource",
  resourceOffShoreCount: "/getAddResourceOffshore",
  resourseOnSiteCount: "/getAddResourceOnsite",
  getAllCount: "/getDashboard",

  // api for project
  searchClientandProject: "/getClientDataForFilterOfProject",
  getProjectTypes: "/getProjectTypes",
  getFilteredProject: "/getProjectforFilter",
  getProjectList: "/getProjects",
  getClientFilteredData: "/getClientFilterData",
  getSingleApplicationById: "/getSingleApplicationById",
  changeProjectStatus: "/changeProjectStatus",
  getProjectDetailsById: "/getProjectDetails",
  getAllApplicationByProjectId: "/getAllApplications",
  getAllSkills: "/getAllSkills",
  addApplication: "/addApplications",
  editAllApplication: "/editAllApplication",
  editProject: "/editProject",

  // api for offshore resources
  offShoreResourceList: "/getAddResourceOffshore",
  allocatedOffShoreEmployeeList: "/getAllocatedOffShoreEmployee",
  projectOffShoreList: "/getProjectOffShore",
  updateUserDetails: "/updateUsersDetails",
  getOffshoreEmployeeSkillForFilterData:
    "/getOffshoreEmployeeSkillForFilterData",
  getBandwidthAllocationDetails: "/getBandwidthAllocationDetails",
  getApplicationNameByProjectId: "/getApplicationNameByProjectId",
  getAllocationType: "/getAllocationType",
  addResourceOffshore: "/addResourceOffshore",
  getClosedTaskByEmpId: "/getClosedTasksByEmpIdForRms",
  getClientDataForFilterOfProject: "/getClientDataForFilterOfProject",
  getOffshoreResourceHistoryDetails: "/getOffshoreResourceHistoryDetails",
  getOffshorePartner: "/get-partners-name-used-in-offshore",

  // api for onsite resources
  getOnsiteResourceList: "/getAddResourceOnsite",
  getOnsiteClientList: "/getClientOnSite",
  getOnsiteEmployeeList: "/getAllOnSiteEmployee",
  getOnsiteFilterSkillData: "/getOnsiteFilterSkillData",
  addResourceOnsite: "/addResourceOnsite",
  getOnsiteResourceDetailsById: "/getOnsiteResourceDetailsById",
  getPoolResourceComments: "/getAllPoolResourceComments",
  getPoolResourceCommentById: "/getPoolResourceCommentsById",
  editOnsiteResource: "/editOnsiteResource",
  getOnSiteResourceHistoryDetails: "/getOnsiteResourceHistoryDetails",
  getActiveProjectList: "/getAllActiveProject",
  createTask: "/createTask",
  monthlyDeployeReport: "/get-weekly-monthly-deployment-report",
  monthlyWeeklyRampDown: "/get-weekly-monthly-ramp-down-report",
  monthlyWeeklyNotification:
    "/get-weekly-monthly-notification-resignation-report",
  estimatedClientInformed:
    "/get-weekly-monthly-estimated-client-to-be-informed-report",
  upcomingRampDownChanges: "/get-upcoming-ramp-down-report",
  getBlockRefestList: "/get-blocked-resources-list",
  getonsitePartnerList: "/get-partners-name-used-in-onsite",
  getPoolResourcesPartner: "/get-pool-resources-employee-for-filter",
  getNotificationReasons: "/get-notification-reasons",

  // block resources
  getBlockResourcesEmployee: "/get-blocked-resources-for-filter",
  getBlockResourcesProjectList: "/get-projects-for-filter",
  getBlockResourcesProjectList1: "/getProjectforFilter",
  getBlockResourcesClientList: "/get-clients-for-filter",

  //partial pool resources
  getPartialPoolResources: "/get-partners-name-used-in-partial-pool-resources",
  //Block resources
  getBlockResourcesPartner: "/get-partners-name-used-in-blocked-resources",
  getPoolResourcespartner: "/get-partners-name-used-in-pool-resources",

  // watchlist api
  addResouresWatchList: "/add-resource-to-Watchlist",
  getWatchListData: "./get-my-Watchlist",
  removeWatchList: "./remove-resource-from-Watchlist",
};
