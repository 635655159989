import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../ChangeOnBoardingStatus/ChangeOnBoardingStatus.module.css";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton1";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import DatePicker from "react-datepicker";
import PurpleIcon from "../../../../assets/Calander.png";
import cancel from "../../../../assets/cancell.png";
import { useState } from "react";
import moment from "moment";
import Select from "react-select";
import { InvoiceStyles } from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import {
  getOnBoardingStatusList,
  PartnerOnBoarding,
  getOnBoardingList,
} from "../../../../Utils/axios/PortalManagement";
import { useMutation, useQuery } from "react-query";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";

const ChangeOnBoardingStatus = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPag,
}) => {
  const {
    data: OnBoardList,
    isLoading,
    isError,
  } = useQuery(
    ["getOnBoardingList"],
    () => getOnBoardingList(prevData?.OnBoardId),
    {}
  );

  const [onboardStatus, setonboardStatus] = useState(false);
  const [StatusDataId, SetStatusDataId] = useState();
  const [effectiveFromData, SeteffectiveFromData] = useState();
  const [effectiveDateData, SeteffectiveDateData] = useState();

  useEffect(() => {
    OnBoardList?.map((elem) => {
      SetStatusDataId(elem?.statusId);
      SeteffectiveFromData(elem?.effectiveFrom);
      SeteffectiveDateData(elem?.effectiveDate);
    });
  }, []);

  const [formData, setFormData] = useState({
    effectiveFrom: effectiveFromData,
    effectiveDate: effectiveDateData,
    statusId: StatusDataId,
    partnerId: prevData?.partnerId,
  });

  const { data: OnBoardStatus } = useQuery(
    ["getOnBoardingStatusList"],
    getOnBoardingStatusList
  );

  useEffect(() => {
    setFormData((prevValue) => ({
      ...prevValue,
      effectiveFrom: effectiveFromData,
      effectiveDate: effectiveDateData,
      statusId: StatusDataId,
      partnerId: prevData?.partnerId,
    }));
  }, [StatusDataId]);

  const OnBoardStatusOption = OnBoardStatus?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const mutation = useMutation(PartnerOnBoarding, {
    onSuccess: (res) => {
      setPrevData((prevData) => ({
        ...prevData,
        onBoardIds: res?.id,
        page: "ChangeOnBoardingStatus",
        page1: "PartnerList",
        page2: "ViewPartner",
        page3: "OnBoarding",
        page4: "ChangeOnBoardingStatus",
        page5: "ViewOnBoardingStatus",
        active: "ViewOnBoardingStatus",
      }));
      setNextScreen("ViewOnBoardingStatus");
    },
  });

  const handlerOnbording = () => {
    mutation.mutate(formData);
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
          alt=""
        />
      </div>
    );
  });

  const handleOnboardStatus = () => {
    setonboardStatus(true);
  };

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <div style={{ marginLeft: "10px" }}></div>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "-5px", marginLeft: "25px" }}>
              On Boarding
            </h6>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className={classes.onboardStatus}>
              <p>
                <span className={classes.OnBoardingFont}>
                  Current Status <span style={{ paddingLeft: "1px" }}>:</span>
                </span>
                <span style={{ fontSize: "14px", paddingLeft: "5px" }}>
                  {prevData?.StatusData}
                </span>
                <span className={classes.OnBoardingFont}>
                  Effective From <span style={{ paddingLeft: "1px" }}>:</span>
                </span>
                <span style={{ fontSize: "14px", paddingLeft: "5px" }}>
                  {moment(prevData?.effectiveFrom).format("DD MMM YY")}
                </span>
              </p>
            </div>
          </Col>
          <Col md={3} style={{ marginLeft: "24px" }}>
            <InvoiceButton type={"plus1"} onClick={handleOnboardStatus}>
              Change Status
            </InvoiceButton>
          </Col>
        </Row>
        {onboardStatus ? (
          <>
            <InvoiceContainer>
              <Row>
                <Col>
                  <h6 className={classes.primaryContact}>Status Management</h6>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className={classes.label3}>
                    Status <span style={{ color: "red" }}>*</span>
                  </p>
                  <Select
                    styles={InvoiceStyles}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    value={OnBoardStatusOption?.find(
                      (e) => e.value === formData?.statusId
                    )}
                    options={OnBoardStatusOption}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        statusId: e?.value,
                      });
                    }}
                  />
                  {/* <p
      className={
        validate && !formData1?.currencyId
          ? classes.errorText
          : classes.errorTextHidden
      }
    >
      Please Select Currency
    </p> */}
                </Col>
                <Col md={4}>
                  <p className={classes.label3}>
                    Effective From <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={classes.cu_field23Last1}>
                    <DatePicker
                      placeholder=" DD MM YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={
                        formData?.effectiveFrom
                          ? moment(formData?.effectiveFrom).toDate()
                          : null
                      }
                      onChange={(date) => {
                        const dateString = date;

                        const dateObject = new Date(dateString);

                        const isoString = dateObject.toISOString(); // "2023-05-16T00:00:00.000Z"
                        const formattedDate = isoString.replace(
                          "T00:00:00.000Z",
                          "T05:52:34.459Z"
                        );

                        setFormData({
                          ...formData,
                          effectiveFrom: formattedDate,
                        });
                      }}
                      dateFormat="dd MMM yyyy"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      customInput={
                        formData?.effectiveFrom ? (
                          <CustomInputCross properties="effectiveFrom" />
                        ) : (
                          <CustomInputClg />
                        )
                      }
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <p className={classes.label4}>
                    Effective Date <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={classes.cu_field23Last}>
                    <DatePicker
                      placeholder=" DD MM YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={
                        formData?.effectiveDate
                          ? moment(formData?.effectiveDate).toDate()
                          : null
                      }
                      onChange={(date) => {
                        const dateString = date;

                        const dateObject = new Date(dateString);

                        const isoString = dateObject.toISOString(); // "2023-05-16T00:00:00.000Z"
                        const formattedDate = isoString.replace(
                          "T00:00:00.000Z",
                          "T05:52:34.459Z"
                        );
                        setFormData({
                          ...formData,
                          effectiveDate: formattedDate,
                        });
                      }}
                      dateFormat="dd MMM yyyy"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      customInput={
                        formData?.effectiveDate ? (
                          <CustomInputCross properties="effectiveDate" />
                        ) : (
                          <CustomInputClg />
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
            </InvoiceContainer>
            <button className={classes.buttonAdd} onClick={handlerOnbording}>
              Submit
            </button>
          </>
        ) : (
          ""
        )}
      </Container>
    </>
  );
};
export default ChangeOnBoardingStatus;
