import React from "react";
import style from "./customTooltip.module.css";

const CustomTooltip = ({ index, value, limit, styles, tooltipstyle }) => {
  const showtooltip = (id, name, type, size) => {
    if (type === "SHOW") {
      if (name?.length > size) {
        document.getElementById(id).style.visibility = "visible";
      }
    } else {
      if (name?.length > size) {
        document.getElementById(id).style.visibility = "hidden";
      }
    }
  };
  return (
    <>
      <p
        onMouseOver={() => showtooltip(index, value, "SHOW", limit)}
        onMouseOut={() => showtooltip(index, value, "HIDE", limit)}
        style={styles}
      >
        {value?.length > limit ? value?.substr(0, limit) + "..." : value}
        <span
          id={index}
          className={style.tooltiptext}
          style={{
            visibility: "hidden",
            margin: "0 0 0 0",
            ...tooltipstyle,
          }}
        >
          {value}
        </span>
      </p>
    </>
  );
};

export default CustomTooltip;
