import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import Modal from "react-responsive-modal";
import { addRMSNote, viewRMSNote } from "../../../Utils/axios/api";
import { notifyError, notifySuccess } from "../../atsDashboard/utils/notify";
import { employeeId } from "../../../Utils/constants";
import moment from "moment";
import style from "./viewNoteRMS.module.css";

export default function AddNoteRMS({
  viewAddNote,
  setViewAddNote,
  empId,
  existPoolNotes,
  refetchPoolResources,
  note,
  setNote,
  id
}) {
  const addNoteMutation = useMutation(addRMSNote, {
    onSuccess: (res) => {
      setNote("");
      setViewAddNote(false);
      notifySuccess(
        existPoolNotes ? "Note Edited Successfully" : "Note Added Successfully"
      );
      refetchPoolResources();
    },
    onError: (err) => {
      notifyError("Something went wrong!");
    },
  });
  return (
    <Modal
      open={viewAddNote}
      center
      onClose={() => setViewAddNote(false)}
      showCloseIcon={false}
    >
      <h5 className={style.noteHead}>
        {existPoolNotes ? "Edit Note" : "Add Note"}
      </h5>
      <div>
        <textarea
          className={style.addNoteInput}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
      <div className={style.btnRow}>
        <button
          className={style.addbtn}
          onClick={() =>
            addNoteMutation.mutate({
              comment: note,
              empId,
              employeeId,
              id,
              localDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm"),
              poolNotes: true,
            })
          }
        >
          {existPoolNotes ? "Edit" : "Add"}
        </button>
        <button
          onClick={() => {
            setNote("");
            setViewAddNote(false);
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
