import React from "react";
import style from "./Nodata.module.css";

const Nodata = () => {
  return (
    <div className={style.main_div}>
      <p className={style.heading}>Additional Information</p>
      <div className={style.no_data_div}>No data available</div>
    </div>
  );
};

export default Nodata;
