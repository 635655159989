export const customStylesInduction = {
    control: (style, state) => ({
      ...style,
      // width:"92%",
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "f5f6fa",
      backgroundColor:
        // change color when disabled
        "#f5f6fa",
      opacity: "1",
      border: "none",
      // minWidth: "inherit",
      margin: "9px 1.5% 0px 1.7%",
      // margin: "9px 8.5% 0px -3.3%",
      minHeight: "34px",
      outline: "none",
      // width: "20vw",
      cursor: "pointer",
    }),
    placeholder: () => ({
      display: "none",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "blue",
      },
      backgroundColor: state.isSelected ? "blue" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: "999",
    }),
  };
  export const customStylesInduction3 = {
    control: (style, state) => ({
      ...style,
      // width:"92%",
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "f5f6fa",
      backgroundColor:
        // change color when disabled
        "#f5f6fa",
      opacity: "1",
      border: "none",
      // minWidth: "inherit",
      // margin: "9px 1.5% 0px 1.7%",
      // margin: "9px 8.5% 0px -3.3%",
      minHeight: "39px",
      outline: "none",
      // width: "20vw",
      cursor: "pointer",
    }),
    placeholder: () => ({
      display: "none",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "blue",
      },
      backgroundColor: state.isSelected ? "blue" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: "999",
    }),
  };
  export const customStylesInduction5 = {
    control: (style, state) => ({
      ...style,
      // width:"92%",
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "f5f6fa",
      backgroundColor:
        // change color when disabled
        "#f5f6fa",
      opacity: "1",
      border: "none",
      // minWidth: "inherit",
      // margin: "9px 1.5% 0px 1.7%",
      // margin: "9px 8.5% 0px -3.3%",
      minHeight: "39px",
      outline: "none",
      width: "15vw",
      marginLeft: "-6px",
      cursor: "pointer",
    }),
    placeholder: () => ({
      display: "none",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "blue",
      },
      backgroundColor: state.isSelected ? "blue" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: "999",
    }),
  };
  export const customSearch = {
    control: () => ({
      // margin: "0 7px",
      borderRadius: "5px",
      padding: "0 20px 0 10px",
      border: "1px solid #00000061",
      cursor: "pointer",
      backgroundColor: "#f5f6fa",
    }),
    placeholder: () => ({
      // display: "none",
      marginTop: "-30px",
      color: "hsl(0deg 2% 18%)",
    }),
    //  option color on hover green
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#855ea1",
      },
      backgroundColor: state.isSelected ? "#855ea1" : "white",
    }),
    // dropdown width
    menu: (provided) => ({
      ...provided,
      width: "98%",
      // marginLeft: "0.6rem",
      zIndex: "999",
      marginLeft: "2px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", // Set the desired height here
      overflow: "auto",
    }),
  };
  
  export const filterBox = {
    control: (style, state) => ({
      ...style,
      borderRadius: "5px",
      // background: state.isDisabled ? "#999" : "f5f6fa",
      backgroundColor:
        // change color when disabled
        state.isDisabled ? "#999" : "#f5f6fa",
      opacity: state.isDisabled ? "0.2" : "1",
      border: "1px solid #00000061",
      minHeight: "38px",
      outline: "none",
      width: "100%",
      cursor: "pointer",
      paddingRight: "50px",
      boxShadow: "none",
    }),
  
    placeholder: () => ({
      marginTop: "-35px",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#855ea1",
      },
      backgroundColor: state.isSelected ? "#855ea1" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: "999",
    }),
  };
  
  export const customStylesInduction2 = {
    control: (style, state) => ({
      ...style,
      width: "32%",
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "f5f6fa",
      backgroundColor:
        // change color when disabled
        "#f5f6fa",
      opacity: "1",
      border: "none",
      // minWidth: "inherit",
      margin: "2px 1.5% 0px -0.7%",
      // margin: "2px 8.5% 0px -3.3%",
      minHeight: "34px",
      outline: "none",
      // width: "20vw",
      cursor: "pointer",
    }),
    placeholder: () => ({
      display: "none",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#a15e5e",
      },
      backgroundColor: state.isSelected ? "#a15e5e" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: "999",
    }),
  };
  export const customSearchEF = {
    control: () => ({
      // margin: "0 7px",
      borderRadius: "5px",
      padding: "0 20px 0 10px",
      border: "1px solid #00000061",
      cursor: "pointer",
      backgroundColor: "#f5f6fa",
    }),
    placeholder: () => ({
      // display: "none",
      marginTop: "-30px",
      color: "hsl(0deg 2% 18%)",
    }),
    //  option color on hover green
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#4b59c3",
        color: "white",
      },
      backgroundColor: state.isSelected ? "#4b59c3" : "white",
    }),
    // dropdown width
    menu: (provided) => ({
      ...provided,
      width: "98%",
      // marginLeft: "0.6rem",
      zIndex: "999",
      marginLeft: "2px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", // Set the desired height here
      overflow: "auto",
    }),
  };
  