import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import style from "./EPR.module.css";
import Pagination from "../../pagination/Pagination";
import Tooltip from "react-simple-tooltip";
import searchIcon from "../../../../assets/Icon-search.png";
import BreadCrum from "../../breadcrums/BreadCrum";
import { useQuery } from "react-query";
import moment from "moment";
import { ToastContainer, } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import Select from "react-select";
import { customSearch } from "../../utils/style";
import { AiOutlineClose } from "react-icons/ai";
import {
  PerformanceReviewList,
  getAllEmployeeList,
  getEmployeeInformation,
  getProjectLeads,
  getDepartment,
  validateEmployeeAccess,
  PerformanceReviewCasesList,
} from "../../utils/api";
import ReviewFileDownload from "../ReviewFileDownload/ReviewFileDownload";
import { employeeId } from "../../../../Utils/constants";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { EprAccessCode } from "../../../../Utils/constants";
import FilterButton from "../../Filterbutton/FilterButton";
import Filter from "../../filter/Filter";

function EPR({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
  isReviewActive,
}) {
  const { checkValidateAccess } = useValidationAccess();

  const [reportStatus, setReportStatus] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [Data, setData] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const location = useLocation();
  let foreignState = location.state;

  // Get the value of the 'id' parameter
  const id = urlParams.get("id");
  const [filter, setFilter] = useState(
    prevData?.EPRFilter
      ? prevData?.EPRFilter
      : {
        empLeadId: 0,
        empName: "",
        empId: 0,
        functionalFieldId: 0,
      }
  );

  const { data: getEmployeeList2 } = useQuery(
    ["getEmployeeInformation"],
    getEmployeeInformation
  );
  const { data: getDepartmentList } = useQuery(
    ["getDepartment"],
    getDepartment
  );
  useEffect(() => {
    setPrevData((prevData) => ({
      ...prevData,
      EPRFilter: filter,
    }));
  }, [filter]);

  const { data: getProjectLead } = useQuery(
    ["getProjectLeads"],
    getProjectLeads
  );

  const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(employeeId)),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );
  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));

  const empName = () => {
    if (
      getEmployeeListOption2?.find((e) => e?.label === filter?.empName)?.label1
    ) {
      return getEmployeeListOption2?.find((e) => e?.label === filter?.empName)
        ?.label1;
    } else {
      return "";
    }
  };

  const { data, isLoading, isError, refetch } = useQuery(
    isReviewActive ?
      ["PerformanceReviewCasesList", { currentPage, filter, reportStatus, Data }] :
      ["PerformanceReviewList", { currentPage, filter, reportStatus, Data }],
    isReviewActive ?
      () =>
        PerformanceReviewCasesList(
          10,
          currentPage,
          filter?.empId,
          filter?.empName,
          filter?.empLeadId,
          filter?.functionalFieldId,
          reportStatus,
          Data?.reviewMonth,
          Data?.reviewSourceType,
          Data?.reviewType,
          employeeId
        ) :
      () =>
        PerformanceReviewList(
          10,
          currentPage,
          filter?.empId,
          filter?.empName,
          filter?.empLeadId,
          filter?.functionalFieldId,
          reportStatus,
          Data?.reviewMonth,
          Data?.reviewSourceType,
          Data?.reviewType,
          employeeId
        ),
    {
      // refetchOnWindowFocus: false,
      // enabled: false,
      // removeAfterUnmount: true,
      // cacheTime: 1000,
    }
  );
  useEffect(()=>{
    const isEmpExist = data?.getPerformanceReviewList?.some((item)=>item.employeeId === foreignState?.empId);
    if (foreignState && isEmpExist) {
      setNextScreen("EmployeeFeedbackList");
      setPrevData((prevData) => ({
        ...prevData,
        empId: foreignState?.empId,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeeFeedbackList",
        page1: "EmployeePerformanceReview",
        page2: "EmployeeFeedbackList",
        page3: null,
        active: "EmployeeFeedbackList",
      }));
    }
  },[data])

  const getEmployeeListOption2 =
    getEmployeeList2?.length > 1
      ? getEmployeeList2?.map((i) => ({
        value: i?.id,
        label1: i?.name,
        label: `${i?.name} (${i?.employeeCode})`,
      }))
      : [];

  const { data: getEmployeeLists } = useQuery(
    ["getEmployeeList"],
    getAllEmployeeList
  );

  const getEmployeeNameOptions = getEmployeeLists?.employeeList?.map((item) => {
    return {
      id: item?.id,
      label: `${item?.name} (${item?.employeeCode})`,
      label1: item?.name,
      employeeCode: item?.employeeCode,
    };
  });

  const getEmployeeListOption =
    getEmployeeLists?.employeeList?.length > 1 &&
    getEmployeeLists?.employeeList?.map((i) => ({
      label: i?.id,
      value: i?.name,
    }));

  const getLeadListOption =
    getProjectLead?.length > 1 &&
    getProjectLead?.map((i) => ({
      value: i?.id,
      label: i?.name,
    }));

  const getDepartmentOption =
    getDepartmentList?.length > 1 &&
    getDepartmentList?.map((i) => ({
      value: i?.id,
      label: i?.name,
    }));

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  const onSearchHandler = (e, option) => {

    setCurrentPage(1);
    if (e || e === "") {
      switch (option) {
        case "functionalFieldId":
          setFilter({ ...filter, functionalFieldId: e?.value });
          break;
        case "empLeadId":
          setFilter({ ...filter, empLeadId: e?.value });
          break;
        case "empName":
          setFilter({ ...filter, empName: e?.label1 });
          break;
        case "empId":
          setFilter({ ...filter, empId: e?.label });
          break;

        default:
      }
    } else {
      setFilter({
        empName: "",
        empId: 0,
        empLeadId: 0,
        functionalFieldId: 0,
      });
    }
  };

  useEffect(() => {
    if (id) {
      setNextScreen("EmployeeFeedbackList");
      setPrevData((prevData) => ({
        ...prevData,
        empId: id,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeeFeedbackList",
        page1: "EmployeePerformanceReview",
        page2: "EmployeeFeedbackList",
        page3: null,
        active: "EmployeeFeedbackList",
      }));
    }
  }, [id]);

  const filterUpdateFuc = () => {
    setCurrentPage(1);
    setData(filterData);
    setShowFilter(false);
  };
  const filterClearAllFuc = () => {
    setData("");

    setShowFilter(false);
    setFilterData((preData) => ({
      ...preData,
      reviewMonth: [],
      reviewType: [],
      reviewSourceType: [],
    }));

    return;
  };

  return (
    <>
      <ToastContainer />

      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
        filterComponent={
          <FilterButton setShowFilter={setShowFilter} filterData={filterData} />
        }
        countComponent={
          <div
            className={style.count}
          >
            <p>{data?.count}</p>
          </div>
        }
        PlusComponent={
          checkValidateAccess(EprAccessCode.add_new_review) ? (
            <Tooltip
              customCss={`white-space: nowrap;`}
              radius={5}
              padding={5}
              background="#001426"
              content="Add New Review"
              className={style.tooltip}
            >
              <div
                className={style.add}
                onClick={() => {
                  setNextScreen("EmployeeFeedback");
                  setPrevData(() => ({
                    sidebar: "EmployeePerformanceReview",
                    page: "EmployeeFeedback",
                    page1: "EmployeePerformanceReview",
                    page2: "EmployeeFeedback",
                    page3: null,
                    active: "EmployeeFeedback",
                  }));
                }}
              >
                <p className={style.plus}>+</p>
              </div>
            </Tooltip>
          ) : null
        }
      >
        <div style={{ width: "8%" }} aria-id="PM0001">
          <p className={style.download}>
            <span
              className={style.backColor1}
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                cursor: "pointer",
                color: "#a15e5e",
              }}
            >
              {checkValidateAccess(EprAccessCode.download_button) ? (
                <ReviewFileDownload
                  limit="10"
                  page={currentPage}
                  empId={filter?.empId}
                  empName={empName()}
                  empLeadId={filter?.empLeadId}
                  functionalFieldId={filter?.functionalFieldId}
                  filter={filter}
                />
              ) : null}
            </span>
          </p>
        </div>
        <div className={style.topBar}>
          <div className={style.s1}>
            {filter?.functionalFieldId === 0 ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilter({
                    ...filter,
                    functionalFieldId: 0,
                  });
                }}
              >
                <AiOutlineClose />
              </span>
            )}
            <Select
              styles={customSearch}
              classNamePrefix={"create_position"}
              placeholder="Department"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={
                filter?.functionalFieldId
                  ? getDepartmentOption?.find(
                    (i) => i.value === filter?.functionalFieldId
                  )
                  : null
              }
              onChange={(e) => onSearchHandler(e, "functionalFieldId")}
              options={getDepartmentOption}
            />
          </div>
          <div className={style.s1}>
            {filter?.empLeadId === 0 ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilter({
                    ...filter,
                    empLeadId: 0,
                  });
                }}
              >
                <AiOutlineClose />
              </span>
            )}
            <Select
              styles={customSearch}
              classNamePrefix={"create_position"}
              placeholder="Lead Name"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={
                filter?.empLeadId
                  ? getLeadListOption?.find(
                    (i) => i.value === filter?.empLeadId
                  )
                  : null
              }
              onChange={(e) => onSearchHandler(e, "empLeadId")}
              options={getLeadListOption}
            />
          </div>

          <div className={style.s1}>
            {filter?.empName === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilter({
                    ...filter,
                    empName: "",
                  });
                  // setFilterName({ ...filterName, empName: "" });
                }}
              >
                <AiOutlineClose />
              </span>
            )}
            <Select
              styles={customSearch}
              classNamePrefix={"create_position"}
              placeholder="Employee Name"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={
                filter?.empName
                  ? {
                    value: filter?.empName,
                    label: filter?.empName,
                  }
                  : null
              }
              onChange={(e) => onSearchHandler(e, "empName")}
              options={getEmployeeNameOptions}
            />
          </div>
        </div>
      </BreadCrum>

      <div className={style.PositionList}>
        <div className={style.table}>
          <div className={style.th}>
            <p className={style.tableContent}>Employee Name</p>
            <p className={style.tableContent}>
              Review
              <br /> Date
            </p>
            <p className={style.tableContent}>
              Review
              <br /> Month
            </p>
            <p className={style.tableContent}>
              Review
              <br /> Type
            </p>
            <p className={style.tableContent}>
              Review
              <br /> Source Type
            </p>
            <p className={style.tableContent}>
              Online
              <br /> Platform
            </p>
            <p className={style.tableContent}>Client Name</p>

            <p className={style.tableContentStatus}>Reviewed By</p>
            <p className={style.tableContent}>Updated By</p>
          </div>
          <div>
            {isLoading && <p className={style.no_data}>Loading .....</p>}
            {!isLoading && !isError ? (
              data?.getPerformanceReviewList?.length > 0 ? (
                typeof data !== "string" &&
                data?.getPerformanceReviewList?.map((item) => {
                  return (
                    <div className={style.td} key={item?.positionId}>
                      <p
                        className={`${style.tableContent1} ${style.link}`}
                        onClick={isReviewActive ? () => {
                          setNextScreen("ReviewCasesEmployeeList");
                          setPrevData((prevData) => ({
                            ...prevData,
                            empId: item?.employeeId,
                            sidebar: "ReviewCases",
                            page: "ReviewCasesEmployeeList",
                            page1: "ReviewCases",
                            page2: "ReviewCasesEmployeeList",
                            page3: null,
                            active: "ReviewCasesEmployeeList",
                          }));
                        } : () => {
                          setNextScreen("EmployeeFeedbackList");
                          setPrevData((prevData) => ({
                            ...prevData,
                            empId: item?.employeeId,
                            sidebar: "EmployeePerformanceReview",
                            page: "EmployeeFeedbackList",
                            page1: "EmployeePerformanceReview",
                            page2: "EmployeeFeedbackList",
                            page3: null,
                            active: "EmployeeFeedbackList",
                          }));
                        }}
                      >
                        {item.employeeName}
                      </p>
                      <p className={style.tableContent}>
                        {moment(item.ReviewDate).format("DD MMM YY")}
                      </p>
                      <p className={style.tableContent}>
                        {moment(item.ReviewDate).format("MMM")}
                      </p>
                      <p className={style.tableContent}>{item?.reviewType}</p>
                      <p className={style.tableContent}>
                        {item?.reviewSourceType}
                      </p>
                      <p className={style.tableContent}>
                        {item?.OnlinePlatform}
                      </p>
                      <p className={style.tableContent}>{item?.clientName}</p>
                      <p className={style.tableContent}>{item?.reviewedBy}</p>
                      <p className={style.tableContent}>{item?.updatedBy}</p>
                    </div>
                  );
                })
              ) : (
                <p className={style.no_data}>No Data Found</p>
              )
            ) : null}
          </div>
        </div>
        <></>
      </div>
      <Filter
        filterData={filterData}
        setFilterData={setFilterData}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        firstCard="Review Month"
        secondCard="Review Type"
        thirdCard="Review Source Type"
        filterUpdateFuc={filterUpdateFuc}
        filterClearAllFuc={filterClearAllFuc}
      />
      <div className={style.pagination}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagesCount={data?.count / 10}
          color="#65A15E"
          color2="white"
        />
      </div>
    </>
  );
}

export default EPR;
