import Modal from "react-responsive-modal";
import style from "./viewNoteRMS.module.css"

export default function ViewNoteRMS({ viewNote, setViewNote, empId , note}) {
  return (
    <Modal
      open={viewNote}
      center
      onClose={() => setViewNote(false)}
      showCloseIcon={false}
    >
      <div>{note}</div>
      <div className={style.viewNotebtn}>
        <button onClick={() => setViewNote(false)}>Done</button>
      </div>
    </Modal>
  );
}
