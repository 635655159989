import React, { useState } from "react";
import SideBar from "../../sidebar/SideBar";
import style from "./bgvReport.module.scss";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";


import {
  bgvStatusReport,
  getAllType,
  getAllUsersWithFilter,
} from "../../../../Utils/axios/umsApi";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import { ToastContainer } from "react-toastify";
import TotalCountUMS from "../commonComponent/TotalCountUMS";
import { path } from "../../../../routes/PathNames";
import { customSearch } from "../../../atsDashboard/utils/style";
import CreatableSelect from "react-select/creatable";
import { DataContext } from "../../../Projects_and_Resource_Master/CommonComponent/useContext/DataContext";
import { useContext } from "react";
import FilterSearchSelect from "../../../atsDashboard/commonComponent/filterSearch/FilterSearchSelect";
import BGVReportExcelFileDownload from "../ExcelFileDownload/BGVReportExcelFileDownload";

const BGVReport = () => {
  const { data: bgvStatusId, setData } = useContext(DataContext);
  const [selectedValue, setSelectedValue] = React.useState(false);
  const [selectedValue1, setSelectedValue1] = React.useState(false);
  const [filter, setFilter] = useState({
    currentPage: bgvStatusId?.currentPage ? bgvStatusId?.currentPage : 1,
    bgvStatus: bgvStatusId?.bgvStatus ? bgvStatusId?.bgvStatus : "",
    empId: bgvStatusId?.empId ? bgvStatusId?.empId : "",
    empTypeId: bgvStatusId?.empTypeId ? bgvStatusId?.empTypeId : "",
  });
  const [userFilter, setUserFilter] = useState({
    status: [],
    allocated: [],
    employeeType: [],
    salaryRange: [],
    functionalArea: [],
    leadId: [],
    clientId: [],
    experienceRange: [],
  });
  const { data: EmpList } = useQuery(["getAllUsersWithFilter"], () =>
    getAllUsersWithFilter(1, 1, userFilter)
  );
  const EmplistOption = EmpList?.employeeResponseModels?.map((e) => ({
    label: e?.name,
    value: e?.id,
  }));
  const { data, isLoading, isError } = useQuery(
    ["bgvStatusReport", filter],
    () => bgvStatusReport(filter?.currentPage, filter?.bgvStatus, filter?.empId, filter?.empTypeId)
  );
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setFilter({ ...filter, currentPage: filter?.currentPage + 1 });
      setData({ ...filter, currentPage: filter?.currentPage + 1 });
    } else if (status === "prev") {
      setFilter({ ...filter, currentPage: filter?.currentPage - 1 });
      setData({ ...filter, currentPage: filter?.currentPage - 1 });
    }
  };
  const history = useHistory();

  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push(path.ums_userDescription, { id, type: "bgv" });
    }, 0);
  };
  const getStatusOption = [
    { label: "In-Progress", value: "In-Progress" },
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
    { label: "N.A", value: "N.A" },
    { label: "N.R", value: "N.R" },
  ];
  const { data: getAllTypeData } = useQuery(["getAllType"], getAllType, {
    refetchOnWindowFocus: false
  });
  const getAllTypeOption = getAllTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  })) ?? [];
  return (
    <>
      <ToastContainer />
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.topFlex}>
            <div className={style.title}>
              <div> BGV Report</div>
              <TotalCountUMS totalCount={data?.count} />
              <BGVReportExcelFileDownload />
            </div>
            <div className={style.filter}>
              {/* <FilterSearchSelect
                options={getAllTypeOption}
                value={filter?.empTypeId}
                onChange={(e) => {
                  setFilter((prev) => ({ ...prev, empTypeId: e.value,currentPage:1 }));
                  setData((prev) => ({ ...prev, empTypeId: e.value,currentPage:1 }));
                }}
                placeholder="Select Type"
                onClose={() => {
                  setFilter((prev) => ({ ...prev, empTypeId: "",currentPage:1 }));
                  setData((prev) => ({ ...prev, empTypeId: "",currentPage:1 }));
                }}
              /> */}
              <div className={style.dropdownContainer1}>
                <CreatableSelect
                  styles={customSearch}
                  options={getAllTypeOption}
                  value={filter?.empTypeId}
                  placeholder="Select Type"
                  isMulti
                  components={{
                    DropdownIndicator: () => selectedValue1 ? null : <IoSearchOutline />,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setSelectedValue1(!selectedValue1)
                    setFilter((prev) => ({ ...prev, empTypeId: e, currentPage: 1 }));
                    setData((prev) => ({ ...prev, empTypeId: e, currentPage: 1 }));
                  }}
                />
              </div>
              <FilterSearchSelect
                options={EmplistOption}
                value={filter?.empId}
                onChange={(e) => {
                  setFilter((prev) => ({ ...prev, empId: e.value, currentPage: 1 }));
                  setData((prev) => ({ ...prev, empId: e.value, currentPage: 1 }));
                }}
                placeholder="Employee Name"
                onClose={() => {
                  setFilter((prev) => ({ ...prev, empId: "", currentPage: 1 }));
                  setData((prev) => ({ ...prev, empId: "", currentPage: 1 }));
                }}
              />

              <div className={style.dropdownContainer1}>
                <CreatableSelect
                  styles={customSearch}
                  placeholder="BGV Status"
                  isMulti
                  components={{
                    DropdownIndicator: () => {
                      return selectedValue ? null : <IoSearchOutline />;
                    },
                    IndicatorSeparator: () => null,
                  }}
                  value={filter?.bgvStatus}
                  onChange={(e) => {
                    setSelectedValue(!selectedValue);
                    setFilter((prev) => ({ ...prev, bgvStatus: e, currentPage: 1 }));
                    setData((prev) => ({ ...prev, bgvStatus: e, currentPage: 1 }));
                  }}
                  options={getStatusOption}
                />
              </div>
            </div>
          </div>
          <div className={style.UsersList}>
            <div className={style.th}>
              <p>Emp Code</p>
              <p>Employee Name</p>
              <p>
                Number of Company
                <br /> Worked in
              </p>
              <p>Positive</p>
              <p>Negative</p>
              <p>NA</p>
              <p>NR</p>
              <p>BGV Status</p>
            </div>
            {isLoading && <div style={{ textAlign: "center" }}>Loading...</div>}
            {isError && (
              <div style={{ textAlign: "center" }}>Something Went Wrong</div>
            )}
            {!isLoading && !isError ? (
              data?.response?.length > 0 ? (
                data?.response?.map((e) => {
                  return (
                    <div
                      className={style.td}
                      key={e.id}

                    >

                      <p onClick={() => handleRedirect(e?.empId)}>
                        {e?.empCode}
                      </p>
                      <p>{e?.empName}</p>
                      <p>{(e.noOfComWorkedIn === 0 || e.noOfComWorkedIn) ? e.noOfComWorkedIn : null}</p>
                      <p>{e.positiveCount ? e.positiveCount : null}</p>
                      <p>{e.negativeCount ? e.negativeCount : null}</p>
                      <p>{e.naCount ? e.naCount : null}</p>
                      <p>{e.nrCount ? e.nrCount : null}</p>
                      <p style={{
                        color: e.negativeCount >= 1 ? "#ea303099" : null,
                      }}>{e?.bgvStatus}</p>
                    </div>
                  );
                })
              ) : (
                <div style={{ textAlign: "center" }}>No Data</div>
              )
            ) : null}
          </div>

          <div style={{ marginRight: "29px" }}>
            <Pagination
              pagesCount={data?.count / 10}
              onPageChange={handlePageChange}
              currentPage={filter?.currentPage}
              color="#289e98"
              color2="white"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BGVReport;
