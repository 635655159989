// export const customStyles = {
//   control: (style, state) => ({
//     ...style,
//     boxShadow: "0px 3px 9px #00000029",
//     borderRadius: "9px",
//     background: state.isDisabled ? "#999" : "f5f6fa",
//     backgroundColor:
//       // change color when disabled
//       state.isDisabled ? "#999" : "#f5f6fa",
//     opacity: state.isDisabled ? "0.2" : "1",
//     border: "none",
//     // minWidth: "inherit",
//     margin: "8px 1.5% 0px 1.7%",
//     minHeight: "38px",
//     outline: "none",
//     width: "100%",
//     cursor: "pointer",
//   }),
//   placeholder: () => ({
//     display: "none",
//   }),
//   clearIndicator: (base) => ({
//     ...base,
//     display: "none",
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     "&:hover": {
//       backgroundColor: "rgba(50, 205, 50, 0.5)",
//     },
//     backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
//   }),
//   menu: (provided) => ({
//     ...provided,
//     zIndex: "999",
//   }),

import { padding } from "@mui/system";

// };

export const InvoiceStyles = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    // minWidth: "inherit",
    // margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    width: "80%",
    cursor: "pointer",
  }),
  singleValue: () => ({
    maxWidth: "80%",
    color: "hsl(0, 0%, 20%)",
    gridArea: "1/1/2/3",
    marginLeft: "2px",
    marginRight: "2px",
    // maxWidth: "100%"",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#855ea1",
    },
    backgroundColor: state.isSelected ? "#855ea1" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

export const InvoiceStyles2 = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    // minWidth: "inherit",
    // margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    width: "80%",
    cursor: "pointer",
  }),
  singleValue: () => ({
    maxWidth: "80%",
    color: "hsl(0, 0%, 20%)",
    gridArea: "1/1/2/3",
    marginLeft: "2px",
    marginRight: "2px",
    // maxWidth: "100%"",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#855ea1",
    },
    backgroundColor: state.isSelected ? "#855ea1" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};
export const InvoiceStylesVendorName = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    // minWidth: "inherit",
    // margin: "8px 1.5% 0px 1.7%",
    minHeight: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
    paddingRight: "50px",
  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#855ea1",
    },
    backgroundColor: state.isSelected ? "#855ea1" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};
export const filterBox = {
  control: (style, state) => ({
    ...style,
    borderRadius: "5px",
    // background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "1px solid #00000061",
    minHeight: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
    paddingRight: "50px",
    boxShadow: "none",
  }),

  placeholder: () => ({
    marginTop: "-35px",
  }),

  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),

  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#855ea1",
    },
    backgroundColor: state.isSelected ? "#855ea1" : "white",
  }),

  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),

  multiValue: (styles, { data }) => ({
    ...styles,
    // minWidth: "auto",
  }),
};

export const filterBox1 = {
  control: (style, state) => ({
    ...style,
    borderRadius: "5px",
    backgroundColor: state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "1px solid #00000061",
    minHeight: "38px",
    outline: "none",
    width: "100%",
    cursor: "pointer",
    paddingRight: "50px",
    boxShadow: "none",
  }),

  placeholder: () => ({
    marginTop: "-35px",
  }),

  clearIndicator: (base) => ({
    ...base,
    cursor: "pointer",
    padding: "0px 8px",
    color: "#888",
    '&:hover': {
      color: "#000",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#855ea1",
    },
    backgroundColor: state.isSelected ? "#855ea1" : "white",
  }),

  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),

  multiValue: (styles, { data }) => ({
    ...styles,
  }),

  multiValueRemove: (styles) => ({
    ...styles,
    color: '#888',
    cursor: 'pointer',
    '&:hover': {
      color: '#000',
      backgroundColor: '#f5f6fa',
    },
  }),
};

// export const customStyles2 = {
//   control: (style, state) => ({
//     ...style,
//     boxShadow: "0px 3px 9px #00000029",
//     borderRadius: "9px",
//     background: state.isDisabled ? "#999" : "f5f6fa",
//     backgroundColor:
//       // change color when disabled
//       state.isDisabled ? "#999" : "#f5f6fa",
//     border: "none",
//     opacity: state.isDisabled ? "0.2" : "1",

//     minWidth: "99%",
//     margin: "8px 1.5% 0px 1.7%",
//     minHeight: "38px",
//     outline: "none",
//     cursor: "pointer",
//   }),
//   placeholder: () => ({
//     display: "none",
//   }),
//   valueContainer: (base) => ({
//     ...base,
//     overflowX: "scroll",
//     flexWrap: "unset",
//     "&::-webkit-scrollbar": {
//       height: "4px",
//     },
//   }),
//   multiValue: (base) => ({
//     ...base,
//     flex: "0 0 auto",
//     justifyContent: "center",
//   }),
//   // clearIndicator
//   clearIndicator: (base) => ({
//     ...base,
//     display: "none",
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     "&:hover": {
//       backgroundColor: "rgba(50, 205, 50, 0.5)",
//     },
//     backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
//   }),
//   menu: (provided) => ({
//     ...provided,
//     zIndex: "999",
//   }),
// };
export const customSearch = {
  control: () => ({
    borderRadius: "5px",
    padding: "0 20px 0 10px",
    border: "1px solid #00000061",
    cursor: "pointer",
    backgroundColor: "#f5f6fa",
  }),
  placeholder: () => ({
    marginTop: "-30px",
    color: "hsl(0deg 2% 18%)",
  }),
  //  option color on hover green
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#855ea1",
    },
    backgroundColor: state.isSelected ? "#855ea1" : "white",
  }),
  // dropdown width
  menu: (provided) => ({
    ...provided,
    width: "98%",
    // marginLeft: "0.6rem",
    zIndex: "999",
    marginLeft: "2px",
  }),
};

// export const customSearchPosition = {
//   control: () => ({
//     margin: "0 10px",
//     borderRadius: "5px",
//     padding: "0 20px 0 10px",
//     border: "1px solid rgba(0, 0, 0, 0.192)",
//     width: "96%",
//     cursor: "pointer",
//   }),
//   placeholder: () => ({
//     // display: "none",
//     marginTop: "-30px",
//     color: "hsl(0deg 2% 18%)",
//   }),
//   //  option color on hover green
//   option: (provided, state) => ({
//     ...provided,
//     "&:hover": {
//       backgroundColor: "rgba(50, 205, 50, 0.5)",
//     },
//     backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
//   }),
//   // dropdown width
//   menu: (provided) => ({
//     ...provided,
//     width: "96%",
//     marginLeft: "0.6rem",
//     zIndex: "999",
//   }),
// };
// export const customStyles3 = {
//   control: (style, state) => ({
//     ...style,
//     boxShadow: "0px 3px 9px #00000029",
//     borderRadius: "9px",
//     background: state.isDisabled ? "#999" : "#f5f6fa",
//     opacity: state.isDisabled ? "0.2" : "1",
//     backgroundColor: state.isDisabled ? "#999" : "#f5f6fa",
//     border: "none",
//     // minWidth: "inherit",
//     margin: "0px 1.5% 0px 1.7%",
//     height: "38px",
//     outline: "none",
//     width: "100%",
//     cursor: "pointer",
//   }),
//   placeholder: () => ({
//     display: "none",
//   }),
//   clearIndicator: (base) => ({
//     ...base,
//     display: "none",
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     "&:hover": {
//       backgroundColor: "rgba(50, 205, 50, 0.5)",
//     },
//     backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
//   }),
//   menu: (provided) => ({
//     ...provided,
//     zIndex: "999",
//   }),
// };
