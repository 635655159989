import React, { useEffect, useContext, useState } from "react";
import style from "./Client.module.css";
import { Link } from "react-router-dom";
import milestone from "../../../../src/assets/milestone.png";
import { DataContext } from "../CommonComponent/useContext/DataContext";
import TableRow from "../Clients/component/TableRow";
import SideBar from "./component/SideBar";
import loaderImg from "../../../assets/loader.gif";
import searchIcon from "../../../assets/Icon-search.png";
import { customSearch } from "./component/style";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import Pagination from "../CommonComponent/Pagination";
import { api } from "../CommonApi/api";
import { post, get } from "../CommonApi/axiosCall";
import { ToastContainer } from "react-toastify";
import { useQuery } from "react-query";
import { getClientStatusList } from "../../../Utils/axios/PortalManagement";
import { checkIfEmpty } from "../../../Utils/constants";

export default function Clients() {
  const { data, setData } = useContext(DataContext);
  const [clientData, setClientData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalClient, settotalClient] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [status, setStatus] = useState([]);
  const [client, setClient] = useState([]);
  const [filterName, setFilterName] = useState({});

  const { data: clientStatusList } = useQuery(["getClientStatusList"], () =>
    getClientStatusList()
  );

  const clientStatusOptions = clientStatusList
    ?.filter((item) => item?.name !== "Active")
    ?.map((item) => {
      return {
        id: item?.id,
        label: item?.name === "Resigned" ? "All" : item?.name,
      };
    });

  const [allData, setAllData] = useState(
    data?.clients
      ? data?.clients
      : { statusId: [], clientId: [], handleFilter: false }
  );
  const limit = 10;

  const [clearData] = useState({
    status: [],
    clientId: [],
  });

  const [applied, setApplied] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [clientFilterList, setClientFilterList] = useState([]);

  const getClientData = (clearData, currentPage) => {
    const filterData = clearData?.clientId?.length == 0 ? clearData : allData;
    setIsLoaded(true);

    let baseURL = `${api.getClientData}`;
    if (limit) {
      baseURL += `?limit=${limit}`;
    }
    if (currentPage) {
      baseURL += `&page=${currentPage}`;
    }
    if (allData?.clientId?.length > 0) {
      baseURL += `&clientId=${filterData?.clientId[0]?filterData?.clientId[0]:0}`;
    }
    if (allData?.statusId?.length > 0) {
      baseURL += `&status=${filterData?.statusId[0]}`;
    }

    post(baseURL)
      .then((res) => {
        setIsLoaded(false);
        setClientData(res.data.responseData);
        setpageCount(res.data.responseData.totalClient / 10);
        settotalClient(res.data.responseData.totalClient);
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  };

  const getClientForFilter = () => {
    let baseURL = `${api.getClientFilterData}`;
    get(baseURL)
      .then((res) => {
        setClientFilterList(res.data.responseData);
      })
      .catch((err) => {
        setClientFilterList([]);
      });
  };

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, clientId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;

        case "Status":
          setAllData({ ...allData, statusId: [e?.id] });
          setFilterName({ ...filterName, statusName: e?.label });
          break;

        default:
      }
    }
  };

  const closeFilter = () => {
    setAllData({ ...allData, handleFilter: false });
    setCurrentPage(1);
    setAllData((preValue) => {
      return preValue
        ? {
            ...preValue,
            status: [],
          }
        : null;
    });
    getClientData(clearData, 1);
    setStatus([]);
    setApplied(false);
  };
  useEffect(() => {
    setData({ ...data, clients: allData });
  }, [allData]);

  useEffect(() => {
    if (data?.clients?.handleFilter) {
      setApplied(true);
    }
  }, []);

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const getPositionCodeForFilterOption = clientFilterList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  useEffect(() => {
    getClientData(allData, currentPage);
    getClientForFilter();
  }, [currentPage, allData]);

  const value = allData?.clientId[0]
    ? getPositionCodeForFilterOption?.find(
        (e) => e.value === allData.clientId[0]
      )
    : null;

  const value2 = allData?.statusId[0]
    ? clientStatusOptions?.find((e) => e.value === allData.statusId[0])
    : null;

  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>
            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", width: "8%", fontWeight: "700" }}
              >
                Clients
              </div>
              <div style={{ width: "17%" }}>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {totalClient}
                  </span>
                </p>
              </div>
              <div className={style.s1} style={{ width: "24%" }}>
                {allData?.statusId.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="searchIcon" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        statusId: [],
                      });
                      setFilterName({ ...filterName, statusName: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}

                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Status"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  // value={checkIfEmpty(filterData?.statusId, {
                  //   id: filterData?.statusId,
                  //   label: filterData?.statusName,
                  // })}

                  value={value2}
                  onChange={(e) => {
                    onSearchHandler(e, "Status");
                    setCurrentPage(1);
                  }}
                  options={clientStatusOptions}
                />
              </div>
              <div className={style.s1} style={{ width: "24%" }}>
                {allData?.clientId.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="searchIcon" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        clientId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}

                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Client Name"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value}
                  onChange={(e) => {
                    onSearchHandler(e, "Code");
                    setCurrentPage(1);
                  }}
                  options={getPositionCodeForFilterOption}
                />
              </div>
              {/* {
                <div className={style.btn_add}>
                  <Link to="/createClient" style={{ textDecoration: "none" }}>
                    <button className={style.create_btn}>
                      <span className={style.plus}>+</span>
                      <p>Create Client</p>
                    </button>
                  </Link>
                </div>
              } */}
            </div>
          </div>
          <div
            onClick={() => {
              // setShowFilter(false);
              closeFilter();
            }}
            className={style.original_table}
            style={{ backgroundColor: "white", height: "27rem" }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : clientData?.clients?.length > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "10px" }}>Client Name</th>
                    <th style={{ padding: "10px" }}>Client Domain</th>
                    <th style={{ padding: "10px" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {clientData?.clients.map((val) => {
                    return <TableRow val={val} />;
                  })}
                </tbody>
              </table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} alt="mileStone" />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}
          </div>
          {totalClient > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {/* {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          setstatus={setStatus}
          type={"client"}
          clientList={clientFilterList}
          setClient={setClient}
        />
      )} */}
      <ToastContainer />
    </>
  );
}
