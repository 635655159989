import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter } from "react-router-dom";

import { createBrowserHistory } from "history";
import ErrorBoundary from "./ErrorBoundary";

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter history={history}>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
