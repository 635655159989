import React from "react";
import style from "./customTooltip.module.css";

const ATSButton = ({ children, onClick, styles, disabled, type }) => {
  const handleButton = () => {
    switch (type) {
      case "cancel":
        return (
          <button
            onClick={onClick}
            style={styles}
            className={style.atsButtonCancel}
            disabled={disabled}
          >
            {children}
          </button>
        );
      default:
        return (
          <button
            onClick={onClick}
            style={styles}
            className={style.atsButton}
            disabled={disabled}
          >
            {children}
          </button>
        );
    }
  };
  return handleButton();
};

export default ATSButton;
