import React from "react";
import Header from "../../../../taskTracker/commonComponent/Header";
// import Sidebar from "../../../Sidebar/Sidebar";
import Sidebar from "../../../Clients/component/SideBar";
import Style from "./Resource.module.css";
import { Link } from 'react-router-dom';

//import TableComponent from "./TableComponent";


export default function Resource() {
  return (
    <>
      {/* tHIS IS HEADER */}
      <Header />
      <div className={Style.projectresource_mainSection}>
          <Sidebar />
        {/* tHIS IS HEADER */}
        <div className={Style.projectresource_mainsection}>
          <h6>Project {">"} <span className={Style.head}> Create Project</span></h6>
          <h6>Create Project</h6>
          {/* main content */}
          <div className={Style.maindiv}>
          <div className={Style.maindivContent} style={{borderBottom:"1px solid grey", display:"flex", justifyContent:"space-between"}}>
            <div style={{display:"flex"}}>
              <div style={{padding:"10px 10px", borderRadius:"10px"}}>
                <Link to ="/projects" style={{textDecoration:"none", color:"black"}}><p>Project Information</p></Link>
              </div>
              <div style={{borderBottom:"5px solid rgb(252, 187, 8)", padding:"10px 10px", borderRadius:"10px"}}>
                <p>Resources</p>
              </div>
              <div style={{padding:"10px 10px"}}>
              <Link to ="/milestone" style={{textDecoration:"none", color:"black"}}><p>Milestone</p></Link>
              </div>
              </div>

              <Link to="availableResources" style={{textDecoration:"none"}}>
              <div>
                  <button className={Style.add_btn}>+</button>
                  <span style={{marginLeft:"10px", marginRight:"10px",color:"#FCB902"}}>Add Resources</span>
              </div>
              </Link>
            </div>
            {/* form start here */}

            {/* this is compnent link */}
<div className={Style.tablediv}>  
<table style={{border:"5px solid white"}}>
  <tr style={{backgroundColor: " #dddddd",height:"50px",textAlign:"left",}}>
    <th style={{paddingLeft: "35px"}}>Employee Name</th>
    <th>Employee Skill</th>
    <th>Allocation Status</th>
    <th>Action</th>
  </tr>
  <tr >
    <td style={{color:"#fcb902",paddingLeft: "35px"}}>Manvi Gupta</td>
    <td>Lorum ipsum dolor sit amet,consectetur,adipising elit</td>
    <td>100%</td>
    <td style={{color:"#fcb902"}} >De-Allocate changes Allocation</td>
  </tr>
  <tr >
    <td style={{color:"#fcb902",paddingLeft: "35px"}}>Manvi Gupta</td>
    <td>Lorum ipsum dolor sit amet,consectetur,adipising elit</td>
    <td>50%</td>
    <td style={{color:"#fcb902"}}>De-Allocate changes Allocation</td>
  </tr>
  <tr >
    <td style={{color:"#fcb902",paddingLeft: "35px"}}>Manvi Gupta</td>
    <td>Lorum ipsum dolor sit amet,consectetur,adipising elit</td>
    <td>50%</td>
    <td style={{color:"#fcb902"}} >De-Allocate changes Allocation</td>
  </tr>
  <tr >
    <td style={{color:"#fcb902",paddingLeft: "35px"}}>Manvi Gupta</td>
    <td>Lorum ipsum dolor sit amet,consectetur,adipising elit</td>
    <td>50%</td>
    <td style={{color:"#fcb902"}} >De-Allocate changes Allocation</td>
  </tr>
  <tr >
    <td style={{color:"#fcb902",paddingLeft: "35px"}}>Manvi Gupta</td>
    <td>Lorum ipsum dolor sit amet,consectetur,adipising elit</td>
    <td>25%</td>
    <td style={{color:"#fcb902"}}>De-Allocate changes Allocation</td>
  </tr>
  <tr >
    <td style={{color:"#fcb902",paddingLeft: "35px"}}>Manvi Gupta</td>
    <td>Lorum ipsum dolor sit amet,consectetur,adipising elit</td>
    <td>25%</td>
    <td style={{color:"#fcb902"}} >De-Allocate changes Allocation</td>
  </tr>
  </table>
</div> 
      
</div>
   <div className={Style.divbutton}>
   <button className={Style.buttondiv} style={{backgroundColor:"white"}}> Hide De-Allocated Resources</button>
  </div> 
           {/* next div of resource */}
         
<div className={Style.tabledivSecond}>  
<table>
  <tr style={{backgroundColor: " #dddddd",height:"40px",textAlign:"left", width:"40px" }}>
    <th style={{paddingLeft: "35px"}}>Employee Name</th>
    <th>Employee Skill</th>
    <th>De-Allocation Date</th>
  </tr>
  <tr >
    <td style={{color:"#fcb902",paddingLeft: "35px"}}>Manvi Gupta</td>
    <td>Lorum ipsum dolor sit amet,consectetur,adipising elit</td>
    <td style={{color:"#fcb902"}} >De-Allocate changes Allocation</td>
  </tr>
  <tr >
    <td style={{color:"#fcb902",paddingLeft: "35px"}}>Manvi Gupta</td>
    <td>Lorum ipsum dolor sit amet,consectetur,adipising elit</td>
    <td style={{color:"#fcb902"}} >De-Allocate changes Allocation</td>
  </tr>
  <tr >
    <td style={{color:"#fcb902",paddingLeft: "35px"}}>Manvi Gupta</td>
    <td>Lorum ipsum dolor sit amet,consectetur,adipising elit</td>
    <td style={{color:"#fcb902"}} >De-Allocate changes Allocation</td>
  </tr>
</table>
</div> 


</div>
</div>
      
    </>
  );
}
