import React from "react";
import style from "./AppraisalButton.module.css";
import addButton from "../../../assets/add-button.svg"
import cancelButton from "../../../assets/cancel-arrow.svg"
import submitButton from "../../../assets/back-submit.svg"

const AppraisalButton = ({ children, onClick, type }) => {
  switch (type) {
    case "plus":
      return (
        <button className={style.createBtn} onClick={onClick}>
          <span> <img src={addButton} alt="addButton" /> </span>
          {children}
        </button>
      );
    case "cancel":
      return (
        <button className={style.cancelBtn} onClick={onClick}>
          <span> <img src={cancelButton} alt="addButton" /> </span>
          {children}
        </button>
      );
      case "back":
        return (
          <button className={style.createBtn} onClick={onClick}>
            <span> <img src={submitButton} style={{transform: 'rotate(180deg)'}} alt="addButton" /> </span>
            {children}
          </button>
        );
        case "submit":
        return (
          <button className={style.createBtn} onClick={onClick}>
            <span> <img src={submitButton} alt="addButton" /> </span>
            {children}
          </button>
        );
    default:
      return (
        <button className={style.createBtn} onClick={onClick}>
          {children}
        </button>
      );
  }
};

export default AppraisalButton;
