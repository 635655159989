import React from "react";
import { Col, Container, Row, Modal, Form } from "react-bootstrap";
import style from "../ViewTimeSheetById/ViewTimeSheetById.module.css";
import { useMutation, useQuery } from "react-query";
import {
  ViewTeamTimeSheetBYID,
  plapproved1,
  plapproved,
} from "../../../../Utils/axios/NewTimeSheetApi";
import { Toastify, ToastifyError } from "../../../../App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import CustomTooltip from "../../../NewEmployeeTimeSheet/customTooltip/CustomTooltip.js";
import { employeeId } from "../../../../Utils/constants";
const ViewTimeSheetById = ({ setNextScreen, prevData, setPrevData }) => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [TextData, setTextData] = useState();
  const [RadioButton, setRadioButton] = useState();
  const [timeSheetId, setTimeSheetId] = useState([]);

  const handleCheckboxChange = (timesheetId) => {
    setTimeSheetId((prevTimeSheetId) => {
      const isChecked = prevTimeSheetId.includes(timesheetId);
      if (isChecked) {
        return prevTimeSheetId.filter((id) => id !== timesheetId);
      } else {
        return [...prevTimeSheetId, timesheetId];
      }
    });
  };

  const { mutate: plapprovedData } = useMutation(plapproved1, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 0) {
        ToastifyError(res?.responseData?.message);
      } else {
        Toastify("Timesheet PL status not approved");
      }

      refetch();
    },
  });

  const { mutate: plapprovedID } = useMutation(plapproved, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 0) {
        ToastifyError(res?.responseData?.message);
      } else {
        Toastify("Timesheet PL status approved Successfully");
      }
      refetch();
    },
  });

  const btnData = RadioButton;
  const dataProject = btnData?.projectName;

  const handleApproved = () => {
    setShow(false);
    plapprovedID({
      timeid: Number(employeeId),
      RadioButton: timeSheetId,
    });
  };

  const handleApprovedCancle = () => {
    setShow(false);
  };

  const handleNotApproved = () => {
    setShow1(false);
    plapprovedData({
      timeid: Number(employeeId),
      Textid: TextData,
      RadioButton: timeSheetId,
    });
  };

  const handleNotApprovedCancle = () => {
    setShow1(false);
  };

  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const { data, isLoading, isError, refetch } = useQuery(
    ["ViewTeamTimeSheetBYID"],
    () =>
      ViewTeamTimeSheetBYID(prevData?.outputDate, prevData?.empiD, employeeId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const originalDate = new Date(prevData?.outputDate);
  const day = originalDate.getDate();
  const month = originalDate.toLocaleString("default", { month: "short" });
  const year = originalDate.getFullYear().toString().slice(-2);
  const convertedDate = `${day} ${month} ${year}`;

  return (
    <>
      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        <Row>
          <Col>
            <span className={style.ViewTeamTask}>
              {prevData?.name} Task's Details
            </span>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ marginLeft: "2px" }}>
          <Col>
            <p className={style.datetime}>{convertedDate}</p>
          </Col>
        </Row>
      </Container>
      <div>
        <div className={style.DetailTeamView}>
          <p className={style.ViewTeamEmployeeDetails}>Task Details</p>
          <div className={style.th}>
            <p style={{ fontWeight: "700" }}>Project Name</p>
            <p style={{ fontWeight: "700" }}>Task Type</p>
            <p style={{ fontWeight: "700" }}>Name/Number</p>
            <p style={{ fontWeight: "700" }}>Time Spent</p>
            <p style={{ fontWeight: "700" }}>Task Summary</p>
            <p style={{ fontWeight: "700" }}>Lead Approval</p>
            <p style={{ fontWeight: "700" }}>Status</p>
          </div>
          {!isLoading && !isError ? (
            data?.length > 0 ? (
              typeof data !== "string" &&
              data?.map((item, index) => {
                const projectData = {
                  projectName: item?.projectName,
                  timesheetId: item?.timesheetId,
                };
                const minutes = item?.timeSpent;
                const hours = Math.floor(minutes / 60);
                const remainingMinutes = minutes % 60;
                return (
                  <>
                    <div className={style.td}>
                      <p>
                        <CustomTooltip
                          value={item?.projectName}
                          limit={16}
                          index={index}
                          tooltipstyle={{ left: "34%" }}
                        />
                      </p>
                      <p>{item?.taskType}</p>
                      <p>
                        {item?.defectNumber
                          ? item?.defectNumber
                          : item?.featureName
                          ? item?.featureName
                          : item?.screenName
                          ? item?.screenName
                          : item?.apiname
                          ? item?.apiname
                          : null}
                      </p>

                      <p>
                        {" "}
                        {hours === 0
                          ? `${remainingMinutes} Min`
                          : `${hours} Hrs ${remainingMinutes} Min`}
                      </p>
                      <CustomTooltip
                        value={item?.taskSummary}
                        limit={15}
                        index={`summary${index}`}
                        tooltipstyle={{ left: "55%" }}
                      />

                      <p>
                        {item?.projectStatus && (
                          <input
                            type="checkbox"
                            className={style.checkbox}
                            name="fav_language"
                            style={{
                              height: "18px",
                              width: "18px",
                              cursor: "pointer",
                            }}
                            onChange={(e) =>
                              handleCheckboxChange(item?.timesheetId)
                            }
                            disabled={
                              item?.plApproved === "Approved" ? true : false
                            }
                          />
                        )}
                      </p>

                      {item?.plApproved === "Pending" ? (
                        <p
                          style={{
                            color: "#9c58a5",
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                          disabled={
                            prevData?.dailyStatus === "Approved" ? true : false
                          }
                        >
                          {item?.plApproved}
                        </p>
                      ) : item?.plApproved === "Approved" ? (
                        <p
                          style={{
                            color: "green",
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.plApproved}
                        </p>
                      ) : item?.plApproved === "Not Approved" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.plApproved}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                );
              })
            ) : (
              <p className={style.no_data}>No Data Found</p>
            )
          ) : null}
        </div>
      </div>
      {prevData?.dailyStatus === "Approved" ? (
        ""
      ) : (
        <>
          <button className={style.buttonAdd} onClick={handleShow}>
            Approved
          </button>
          <button className={style.buttonCancle} onClick={handleShow1}>
            Not Approved
          </button>
        </>
      )}
      <Modal show={show} centered size="sm">
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#9c58a5" }}>Confirm Status</h6>
        </Modal.Header>
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to approve.
        </h6>
        <button className={style.buttonAdd4} onClick={handleApproved}>
          Yes
        </button>
        <button className={style.buttonCancle4} onClick={handleApprovedCancle}>
          No
        </button>
        <style type="text/css">
          {`
      .modal-content {
        width: 80%;
      }
    `}
        </style>
      </Modal>
      <ToastContainer />
      <Modal show={show1} centered size="sm">
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#9c58a5" }}>Confirm Status</h6>
        </Modal.Header>
        <h6 style={{ margin: "5px 24px", fontSize: "12px" }}>
          Reason for not approve
        </h6>
        <h6 style={{ margin: "0px 24px", fontSize: "12px" }}>
          Project Name: {dataProject}
        </h6>
        <Form.Control
          as="textarea"
          rows={2}
          style={{ width: "85%", marginLeft: "24px", marginTop: "10px" }}
          onChange={(e) => {
            setTextData(e.target.value);
          }}
        />

        <button className={style.buttonAdd3} onClick={handleNotApproved}>
          Yes
        </button>
        <button
          className={style.buttonCancle3}
          onClick={handleNotApprovedCancle}
        >
          No
        </button>
      </Modal>
    </>
  );
};
export default ViewTimeSheetById;
