import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import style from "./Filter.module.css";
import { useQuery } from "react-query";
import "./style.css";

import {
  getReviewMonthListing,
  getReviewSourcType,
  getReviewType,
  getReviewTypesInListing,
} from "../utils/api";
import { months } from "../../../Utils/constants";
function Filter({
  setShowFilter,
  showFilter,
  firstCard,
  secondCard,
  thirdCard,
  filterData,
  setFilterData,
  filterUpdateFuc,
  filterClearAllFuc,
}) {
  const [selectedItems, setSelectedItems] = useState({
    reviewMonth: [],
    reviewSourceType: [],
    reviewType: [],
  });

  const { data: getResourceTypes } = useQuery(
    ["getReviewSourcType"],
    getReviewSourcType,
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const { data: getReviewTypesInListingOptions } = useQuery(
    ["getReviewTypesInListing"],
    getReviewTypesInListing,
    {}
  );

  const { data: getReviewMonthListingOptions } = useQuery(
    ["getReviewMonthListing"],
    getReviewMonthListing,
    {}
  );

  useEffect(() => {
    setFilterData(selectedItems);
  }, [selectedItems]);

  const handleCheckboxChange = (event, type) => {
    const value = parseInt(event.target.id);

    if (type === "month") {
      if (selectedItems.reviewMonth.includes(value)) {
        // If the value is already selected, remove it
        setSelectedItems({
          ...selectedItems,
          reviewMonth: selectedItems.reviewMonth.filter(
            (item) => item !== value
          ),
        });
      } else {
        // If the value is not selected, add it
        setSelectedItems({
          ...selectedItems,
          reviewMonth: [...selectedItems.reviewMonth, value],
        });
      }
    } else if (type === "reviewType") {
      if (selectedItems.reviewType.includes(value)) {
        // If the value is already selected, remove it
        setSelectedItems({
          ...selectedItems,
          reviewType: selectedItems.reviewType.filter((item) => item !== value),
        });
      } else {
        // If the value is not selected, add it
        setSelectedItems({
          ...selectedItems,
          reviewType: [...selectedItems.reviewType, value],
        });
      }
    } else if (type === "sourceType") {
      if (selectedItems.reviewSourceType.includes(value)) {
        // If the value is already selected, remove it
        setSelectedItems({
          ...selectedItems,
          reviewSourceType: selectedItems.reviewSourceType.filter(
            (item) => item !== value
          ),
        });
      } else {
        // If the value is not selected, add it
        setSelectedItems({
          ...selectedItems,
          reviewSourceType: [...selectedItems.reviewSourceType, value],
        });
      }
    }
  };

  return (
    <>
      <Modal
        open={showFilter}
        center
        onClose={() => setShowFilter(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.filterModal}>
          <div className={style.filterCards}>
            {firstCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>Review Month</div>
                <div
                  style={{
                    margin: "5px 10px",
                    overflow: "auto",
                    height: "30vh",
                  }}
                >
                  {getReviewMonthListingOptions?.map((item) => (
                    <div
                      key={item.id}
                      style={{ display: "flex", gap: "20px", width: "15vw" }}
                    >
                      <input
                        type="checkbox"
                        id={item.id}
                        name="checkbox"
                        checked={selectedItems.reviewMonth?.includes(item.id)}
                        onChange={(e) => handleCheckboxChange(e, "month")}
                        style={{ visibility: "visible" }}
                      />
                      <label style={{color: "black"}} htmlFor={item.name}>{item.name}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {secondCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>Review Type</div>
                <div
                  style={{
                    margin: "5px 10px",
                    overflow: "auto",
                    height: "30vh",
                  }}
                >
                  {getReviewTypesInListingOptions?.map((item) => (
                    <div key={item.id} style={{ display: "flex", gap: "20px" }}>
                      <input
                        type="checkbox"
                        id={item.id}
                        name="checkbox"
                        checked={selectedItems.reviewType?.includes(item.id)}
                        onChange={(e) => handleCheckboxChange(e, "reviewType")}
                        style={{ visibility: "visible" }}
                      />
                      <label style={{color: "black"}} htmlFor={item.name}>{item.name}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {thirdCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>Review Source Type</div>
                <div
                  style={{
                    margin: "5px 10px",
                    overflow: "auto",
                    height: "30vh",
                  }}
                >
                  {getResourceTypes?.map((item) => (
                    <div key={item.id} style={{ display: "flex", gap: "20px" }}>
                      <input
                        type="checkbox"
                        id={item.id}
                        name="checkbox"
                        checked={selectedItems.reviewSourceType?.includes(
                          item.id
                        )}
                        onChange={(e) => handleCheckboxChange(e, "sourceType")}
                        style={{ visibility: "visible" }}
                      />
                      <label style={{color: "black"}} htmlFor={item.name}>{item.name}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={style.filterBtn}>
          <button
            className={style.cancelBtn}
            onClick={() => {
              filterClearAllFuc();
              setSelectedItems((preData) => ({
                ...preData,
                reviewMonth: [],
                reviewType: [],
                reviewSourceType: [],
              }));
            }}
          >
            Clear All
          </button>
          <button
            className={style.createBtn}
            onClick={() => {
              filterUpdateFuc();
            }}
          >
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Filter;
