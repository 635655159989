import React from "react";
import loaderImg from "../../../../assets/Blocks@2x-1.0s-200px-200px (4).gif";
import style from "./AppraisalLoader.module.css";

const AppraisalLoader = () => {
  return (
    <div>
      <img src={loaderImg} alt="loader" className={style.loaderImg} />
      <p className={style.loadingText}>Loading...</p>
    </div>
  );
};

export default AppraisalLoader;