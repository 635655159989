import React from "react";
import style from "./tasktracker.module.css";

import MyTasks from "./myTasks/myTasks";
import RecurringTask from "./RecurringTask/myTasks"
import { Route, Switch } from "react-router-dom";
import Header from "./commonComponent/Header";
import SideBar from "./commonComponent/SideBar";

export default function TaskTracker(props) {
  return (
    <div>
      <Header />

      <div className={style.mainSection}>
        <SideBar />
        <Switch>
          <Route path={"/taskTracker"} exact component={MyTasks} />
          {/* <Route path={"/taskTracker/TaskAssignedByMeComponent"} exact component={TaskAssignedByMeComponent} /> */}
          {/* <Route path={"/taskTracker/CreateTaskComponent"} exact component={CreateTaskComponent} /> */}
          {/* <Route
            exact
            path="/taskTracker/TaskAssignedByMeComponent"
            render={(props) => <TaskAssignedByMeComponent {...props} />}
          />
          <Route
            exact
            path="/taskTracker/CreateTask"
            component={CreateTaskComponent}
          /> */}
        </Switch>
      </div>

      {/* <div className={style.rightSection}>
          <MyTasks />
        </div> */}
    </div>
  );
}
