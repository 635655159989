import React, { useState } from "react";
import classes from "./ClientList.module.css";
import { Col, Container } from "react-bootstrap";
import { useQuery } from "react-query";
import {
  validateEmployeeAccess,
  getClientListFilter,
  getClientList,
  getClientStatusList,
} from "../../../../../Utils/axios/PortalManagement.js";
import { checkIfEmpty, employeeId } from "../../../../../Utils/constants";
import Pagination from "../../../CommonComponent/pagination/pagination.js";
import TotalCount from "./../../../TotalCount/TotalCount";
import CommonDropdown from "../../../../../GenericComponent/ui/CommonDropdown";

const ClientList = ({
  setNextScreen,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [filterData, setFilterData] = useState({
    statusId: "",
    statusName: "",
    clientId: "",
    clientName: "",
  });

  const { data: getValidateEmp } = useQuery(["validateEmployeeAccess"], () =>
    validateEmployeeAccess(parseInt(employeeId))
  );

  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));

  const { data, isLoading, isError } = useQuery(
    ["getAllClients", { currentPage, filterData }],
    () =>
      getClientList(10, currentPage, filterData?.statusId, filterData?.clientId)
  );

  const { data: clientListFilter } = useQuery(["getClientListFilter"], () =>
    getClientListFilter()
  );

  const clientListOptions = clientListFilter?.map((item) => {
    return {
      id: item?.id,
      label: item?.name,
    };
  });

  const { data: clientStatusList } = useQuery(["getClientStatusList"], () =>
    getClientStatusList()
  );

  const clientStatusOptions = clientStatusList
    ?.filter((item) => item?.name !== "Active")
    ?.map((item) => {
      return {
        id: item?.id,
        label: item?.name === "Resigned" ? "All" : item?.name,
      };
    });

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  return (
    <>
      <>
        <Container fluid>
          <Col md={12} style={{ marginTop: "8px" }}>
            <div className={classes.partnerlist}>
              <div className={classes.oppp}>
                <h6 className={classes.manage}>Client List</h6>
                {getVData?.some((e) => {
                  return e?.label === "329X7157D19173YX";
                }) && (
                    <div
                      title="Create Client"
                      className={classes.add}
                      onClick={() => {
                        setNextScreen("CreateClientList");
                        setPrevData((prevData) => ({
                          ...prevData,
                          page1: "clientList",
                          page2: "CreateClientList",
                          page3: null,
                          page4: null,
                          active: "CreateClientList",
                        }));
                      }}
                    >
                      <p className={classes.plus}>+</p>
                    </div>
                  )}

                <TotalCount totalCount={data?.count} />
              </div>

              <div className={classes.dropdown_div}>
                <div className={classes.topBarName2}>
                  <CommonDropdown
                    color="#c3b14b"
                    placeholder="Status"
                    options={clientStatusOptions}
                    value={checkIfEmpty(filterData?.statusId, {
                      id: filterData?.statusId,
                      label: filterData?.statusName,
                    })}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        statusId: e?.id,
                        statusName: e?.label,
                      });
                      setCurrentPage(1);
                    }}
                    customInputOnClick={() =>
                      setFilterData({
                        ...filterData,
                        statusId: "",
                        statusName: "",
                      })
                    }
                  />
                </div>

                <div className={classes.topBarName}>
                  <CommonDropdown
                    color="#c3b14b"
                    placeholder="Client Name"
                    options={clientListOptions}
                    value={checkIfEmpty(filterData?.clientId, {
                      id: filterData?.clientId,
                      label: filterData?.clientName,
                    })}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        clientId: e?.id,
                        clientName: e?.label,
                      });
                      setCurrentPage(1);
                    }}
                    customInputOnClick={() =>
                      setFilterData({
                        ...filterData,
                        clientId: "",
                        clientName: "",
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </Col>
          {/* {isLoading && (
            <p className={classes.no_data}>Loading .....</p>
          )} */}
          {isLoading ? (
            <p className={classes.no_data}>Loading .....</p>
          ) : data?.count > 0 ? (
            <div className={classes.table_div}>
              <table className={classes.table}>
                <thead className={classes.table_heading}>
                  <tr>
                    <th className={`${classes.table_head}`}>Client Code</th>
                    <th className={`${classes.table_head}`}>
                      Client
                      <br /> Trade Name
                    </th>
                    <th className={`${classes.table_head}`}>
                      Client <br /> Legal Name
                    </th>
                    <th className={`${classes.table_head}`}>Email Id</th>
                    <th className={`${classes.table_head}`}>
                      Point of Contact of Client
                    </th>
                    <th className={`${classes.table_head}`}>Mobile Number</th>
                    <th className={`${classes.table_head}`}>Sales Owner Name</th>
                    <th className={`${classes.table_head}`}>Spocs Designation</th>
                    <th className={`${classes.table_head}`}>Status</th>
                    <th className={`${classes.table_head}`}>Is Prospect</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.clientResponse?.map((item, idx) => (
                    <tr
                      className={item?.isProspect === true ? classes.redBackground : null}
                      key={idx}
                    >
                      <td className={`${classes.table_column}`}>
                        <p>{item?.clientId}</p>
                      </td>
                      <td className={`${classes.table_column}`}>
                        <p>
                          <span
                            onClick={() => {
                              setNextScreen('viewClient');
                              setPrevData((prevData) => ({
                                ...prevData,
                                page1: 'clientList',
                                page2: 'viewClient',
                                page3: null,
                                page4: null,
                                active: 'viewClient',
                                clientId: item?.clientId,
                              }));
                            }}
                            className={classes.client_name}
                          >
                            {item?.clientName}
                          </span>
                        </p>
                      </td>
                      <td className={`${classes.table_column}`}>
                        {item?.legelClientName || '-'}
                      </td>
                      <td className={`${classes.table_column}`}>
                        {item?.email || '-'}
                      </td>
                      <td className={`${classes.table_column}`}>
                        {item?.pointOfContactOfClient || '-'}
                      </td>
                      <td className={`${classes.table_column}`}>
                        {item?.mobileNo || '-'}
                      </td>
                      <td className={`${classes.table_column}`}>
                        {item?.salesOwnerName || '-'}
                      </td>
                      <td className={`${classes.table_column}`}>
                        {item?.spocDesignation || '-'}
                      </td>
                      <td className={`${classes.table_column}`}>
                        {item?.status || '-'}
                      </td>
                      <td className={`${classes.table_column}`}>
                        {item?.isProspect !== null
                          ? item?.isProspect === true
                            ? 'Yes'
                            : 'No'
                          : '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className={classes.no_data_para}>No Data Found</p>
          )}
        </Container>
        <div className={classes.pagination}>
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pagesCount={data?.count / 10}
            color="#C3B14B"
            color2="white"
          />
        </div>
      </>
    </>
  );
};

export default ClientList;
