import { useState, useEffect } from "react";
import SideBar from "../Clients/component/SideBar";
import Style from "./OnsoteReportDashboard.module.css";
import { rmsAccessCode } from "../../../Utils/constants";
import useValidationAccess from "../../custumHooks/useValidationAccess";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import { api } from "../CommonApi/api";
import constants from "../../../Utils/constants";
import { get, post } from "../CommonApi/axiosCall";

export default function OnsiteReportDashoBoard() {
  const [totalActiveResourcesCount, setTotalActiveResourcesCount] = useState(0);
  const [countFromInnovationm, setCountFromInnovationm] = useState(0);
  const [countFromPartner, setCountFromPartner] = useState(0);
  const [countFromWeeklyMonthly, setCountWeekluMonthly] = useState(0);
  const [countFromWeeklyMonthly2, setCountWeekluMonthly2] = useState(0);
  const [countFromNotification, setCountFromNotification] = useState(0);
  const [countFromNotification2, setCountFromNotification2] = useState(0);
  const [countRampDown, setCountRampDown] = useState(0);
  const [countRampDown2, setCountRampDown2] = useState(0);
  const [countFromExpectedClient, setCountFromExpectedClient] = useState(0);
  const [countFromExpectedClient2, setCountFromExpectedClient2] = useState(0);
  const [countFromUpcomingRampDown, setCountFromUpComingRampDown] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { checkValidateAccess } = useValidationAccess();

  let history = useHistory();
  const baseUrl = constants.apiBaseUrlResource;
  const limit = 10;
  const [allData] = useState({
    "action": [
        1
    ],
    "clientId": [],
    "employeeId": [],
    "skill": [],
    "salaryRange": [],
    "partners": [],
    "startDateMonth": [],
    "endDateMonth": [],
    "employeeStatus": [
        null,
        null
    ],
    "quaterlyEndOfDeployment": [],
    "quaterlyDeployment": [],
    "handleFilter": false,
    "partnerId": [],
    "salesPersonId": [],
    "isForcedAddedToPool": null
});
  const [allData1] = useState({
    action: [1],
    clientId: [],
    employeeId: [],
    skill: [],
    salesPersonId: [],
    salaryRange: [],
    partners: [99],
    startDateMonth: [],
    endDateMonth: [],
    employeeStatus: [null, null],
    quaterlyEndOfDeployment: [],
    quaterlyDeployment: [],
    handleFilter: false,
  });
  const [allData3] = useState({
    action: [1],
    clientId: [],
    employeeId: [],
    skill: [],
    salesPersonId: [],
    salaryRange: [],
    partners: [2],
    startDateMonth: [],
    endDateMonth: [],
    employeeStatus: [null, null],
    quaterlyEndOfDeployment: [],
    quaterlyDeployment: [],
    handleFilter: false,
  });
  const getAddResourceOnsiteData = (clearData, currentPage) => {
    let url = api.getOnsiteResourceList;
    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}`;
    }
    axios
      .post(
        baseUrl +
        "/getAddResourceOnsite?" +
        "limit=" +
        limit +
        "&page=" +
        currentPage,
        clearData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: null,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setTotalActiveResourcesCount(res.data.responseData.totalResources);
      })
      .catch(() => { });
  };
  const getCountFromInnovationm = (clearData, currentPage) => {
    let url = api.getOnsiteResourceList;
    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}`;
    }

    axios
      .post(
        baseUrl +
        "/getAddResourceOnsite?" +
        "limit=" +
        limit +
        "&page=" +
        currentPage,
        clearData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: null,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setCountFromInnovationm(res.data.responseData.totalResources);
      })
      .catch(() => { });
  };
  const getCountFromPartner = (clearData, currentPage) => {
    let url = api.getOnsiteResourceList;
    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}`;
    }

    axios
      .post(
        baseUrl +
        "/getAddResourceOnsite?" +
        "limit=" +
        limit +
        "&page=" +
        currentPage,
        clearData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: null,
          },
        }
      )
      .then((res) => {
        setCountFromPartner(res.data.responseData.totalResources);
      })
      .catch(() => { });
  };
  const getWeeklyMonthlyDeployment = (currentPage) => {
    let url = api.monthlyDeployeReport;

    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}&reportType=${1}`;
    }
    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCountWeekluMonthly(res.data.responseData.totalCount);
      })
      .catch(() => { });
  };

  const getWeeklyMonthlyDeployment2 = (currentPage) => {
    let url = api.monthlyDeployeReport;

    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}&reportType=${2}`;
    }
    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCountWeekluMonthly2(res.data.responseData.totalCount);
      })
      .catch(() => { });
  };
  const getRampDownCount = (currentPage) => {
    let url = api.monthlyWeeklyRampDown;

    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}&reportType=${1}`;
    }
    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCountRampDown(res.data.responseData.totalCount);
      })
      .catch(() => { });
  };
  const getRampDownCount2 = (currentPage) => {
    let url = api.monthlyWeeklyRampDown;

    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}&reportType=${2}`;
    }
    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCountRampDown2(res.data.responseData.totalCount);
      })
      .catch(() => { });
  };
  const getCountNotification = (currentPage) => {
    let url = api.monthlyWeeklyNotification;

    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}&reportType=${1}`;
    }
    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCountFromNotification(res.data.responseData.totalCount);
      })
      .catch(() => { });
  };
  const getCountNotification2 = (currentPage) => {
    let url = api.monthlyWeeklyNotification;

    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}&reportType=${2}`;
    }
    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCountFromNotification2(res.data.responseData.totalCount);
      })
      .catch(() => { });
  };
  const getExpectedClient = (currentPage) => {
    let url = api.estimatedClientInformed;

    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}&reportType=${1}`;
    }
    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCountFromExpectedClient(res.data.responseData.totalResources);
      })
      .catch(() => { });
  };
  const getExpectedClient2 = (currentPage) => {
    let url = api.estimatedClientInformed;

    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}&reportType=${2}`;
    }
    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCountFromExpectedClient2(res.data.responseData.totalResources);
      })
      .catch(() => { });
  };
  const getUpcomingData = (currentPage) => {
    let url = api.upcomingRampDownChanges;

    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}&reportType=${1}`;
    }

    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCountFromUpComingRampDown(res.data.responseData.totalResources);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getAddResourceOnsiteData(allData, currentPage);
    getCountFromInnovationm(allData1, currentPage);
    getCountFromPartner(allData3, currentPage);
    getWeeklyMonthlyDeployment(currentPage);
    getRampDownCount(currentPage);
    getCountNotification(currentPage);
    getExpectedClient(currentPage);
    getWeeklyMonthlyDeployment2(currentPage);
    getRampDownCount2(currentPage);
    getCountNotification2(currentPage);
    getExpectedClient2(currentPage);
    getUpcomingData(currentPage);
  }, []);
  return (
    <>
      <div className={Style.projectresource_mainSection}>
        <SideBar />
        <div className={Style.projectresource_mainsection}>
          <div style={{ marginLeft: "0px" }}>
            {/* one */}
            <div className={Style.imgSection}>
              {checkValidateAccess(
                rmsAccessCode?.total_active_deployment_report
              ) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/ResourcesAllocationOnsite")}
                  >
                    <h1
                      style={{
                        color: "black",
                        position: "relative",
                        top: "3rem",
                        fontSize: "53px",
                      }}
                    >
                      {totalActiveResourcesCount}
                    </h1>
                    <span>
                      Active Deployment/Onsite<br></br>
                    </span>

                    <div style={{ width: "12%", marginRight: "42px" }}></div>
                  </div>
                )}
              {checkValidateAccess(
                rmsAccessCode?.total_deployment_from_innovationM
              ) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/DeploymentsFromInnovationM")}
                  >
                    <h1
                      style={{
                        color: "black",
                        position: "relative",
                        top: "3rem",
                        fontSize: "53px",
                      }}
                    >
                      {countFromInnovationm}
                    </h1>
                    <span>Deployment from InnovationM</span>
                  </div>
                )}
              {checkValidateAccess(
                rmsAccessCode?.total_deployment_from_partner
              ) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/DeploymentFromPartner")}
                  >
                    <h1
                      style={{
                        color: "black",
                        position: "relative",
                        top: "3rem",
                        fontSize: "53px",
                      }}
                    >
                      {countFromPartner}
                    </h1>

                    <span>Deployment from Partner</span>
                  </div>
                )}
            </div>
            <div className={Style.imgSection}>
              {checkValidateAccess(
                rmsAccessCode?.weekly_monthly_deployed_report
              ) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/WeekLyMonthLyDeploye/1/1")}
                  >
                    <h1
                      style={{
                        color: "black",
                        position: "relative",
                        top: "3rem",
                        fontSize: "53px",
                      }}
                    >
                      {`${countFromWeeklyMonthly} / ${countFromWeeklyMonthly2}`}
                    </h1>

                    <span>Weekly/Monthly Deployed</span>
                  </div>
                )}
              {checkValidateAccess(
                rmsAccessCode?.weekly_monthly_ramp_down_report
              ) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/MonthlyWeeklyRampDown")}
                  >
                    {" "}
                    <h1
                      style={{
                        color: "black",
                        position: "relative",
                        top: "3rem",
                        fontSize: "53px",
                      }}
                    >
                      {`${countRampDown} / ${countRampDown2}`}
                    </h1>
                    <span>Weekly/Monthly Ramp Down</span>
                  </div>
                )}
              {checkValidateAccess(
                rmsAccessCode?.weekly_monthly_notifications_resignation_report
              ) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/WeeklyMonthlyNotification")}
                  >
                    <h1
                      style={{
                        color: "black",
                        position: "relative",
                        top: "3rem",
                        fontSize: "53px",
                      }}
                    >
                      {`${countFromNotification} / ${countFromNotification2}`}
                    </h1>

                    <span>
                      Weekly/Monthly Notifications/ <br></br>Resignation
                    </span>
                  </div>
                )}
            </div>

            <div className={Style.imgSection}>
              {checkValidateAccess(
                rmsAccessCode?.expected_client_to_be_informed_weekly_report
              ) && (
                  <div
                    className={Style.imgcontentSection}
                    onClick={() => history.push("/ExpectedClientInformed")}
                  >
                    <h1
                      style={{
                        color: "black",
                        position: "relative",
                        top: "3rem",
                        fontSize: "53px",
                      }}
                    >
                      {countFromExpectedClient2}
                    </h1>
                    <span>Expected Client to be informed</span>
                  </div>
                )}
              {checkValidateAccess(rmsAccessCode?.upcoming_ram_down_report) && (
                <div
                  onClick={() => history.push("/UpcomingRampDownReport")}
                  className={Style.imgcontentSection}
                >
                  <h1
                    style={{
                      color: "black",
                      position: "relative",
                      top: "3rem",
                      fontSize: "53px",
                    }}
                  >
                    {countFromUpcomingRampDown}
                  </h1>
                  <span>
                    Upcoming Ramp Down<br></br>
                  </span>
                </div>
              )}

              <div
                style={{ visibility: "hidden" }}
                className={Style.imgcontentSection}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
