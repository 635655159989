import PartnerNavigation from "../Pages/PartnerManagement/navigation/PartnerNavigation.js";
import ProjectManagement from "../Pages/PartnerManagement/pageLayout/ProjectManagement/ProjectManagement.js";
import { path } from "./PathNames.js";

const PortalManagementRoute = [
  {
    path: path.portalManagement,
    component: PartnerNavigation,
  },

  {
    path: path.projectManagement,
    component: ProjectManagement,
  },
];

export default PortalManagementRoute;
