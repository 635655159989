import React, { useEffect, useContext, useState } from "react";
import SideBar from "../Clients/component/SideBar";
import style from "./resourceManagement.module.css";
import TableRow from "./component/TableRow";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Table } from "react-bootstrap";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import "bootstrap/dist/css/bootstrap.min.css";
import loaderImg from "../../../assets/loader.gif";
import Moment from "moment";
import Select from "react-select";
import { DataContext } from "../CommonComponent/useContext/DataContext";
import searchIcon from "../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { customSearch } from "../Clients/component/style";
import Pagination from "../CommonComponent/Pagination";
import milestone from "../../../assets/milestone.png";
import rightTickPRM from "../../../assets/rightTickPRM.png";
import Filter from "../components/Filter";
import { api } from "../CommonApi/api";
import { get, post } from "../CommonApi/axiosCall";
import { ToastContainer } from "react-toastify";
import { rmsAccessCode } from "../../../Utils/constants";
import useValidationAccess from "../../custumHooks/useValidationAccess";
import { notifyError, notifySuccess } from "../../atsDashboard/utils/notify";
import { addToPool } from "../../../Utils/axios/rmsApi";
import { useMutation } from "react-query";
import PoolResourcesDownload from "./PoolResourceDownload";
import { sortBy } from "lodash";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const ResourceManagement = (props) => {
  const history = useHistory();
  const { checkValidateAccess } = useValidationAccess();

  const { data, setData } = useContext(DataContext);
  const [applied, setApplied] = useState(
    props.history?.location.state?.applied
      ? props.history.location.state?.applied
      : false
  );
  const [showFilter, setShowFilter] = useState(false);
  const [OffShoreData, setOffshoreData] = useState();
  const [currentPage, setCurrentPage] = useState(
    props.history?.location?.state?.currentPage
      ? props.history.location?.state?.currentPage
      : 1
  );
  const [forProjectOffshore, setForProjectOffshore] = useState([1]);
  const [totalResourceOffShore, setTotalResourceOffShore] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [offshoreEmp, setOffshoreEmp] = useState([]);
  const [offshoreProject, setOffshoreProject] = useState([]);
  const [offshoreSkills, setOffshoreSkills] = useState([]);
  const [partnerName, setParnername] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ExitDate, setExitDate] = useState();
  const [show, setShow] = useState(false);
  const [action, setAction] = useState([]);
  const [bandwidths, setBandwidths] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [filterName, setFilterName] = useState(
    props.history.location.state?.filterName
      ? props.history.location.state?.filterName
      : {}
  );
  const [projectId, setProjectId] = useState([]);
  const [skills, setSkills] = useState([]);
  const [allData, setAllData] = useState(
    data?.resourceManagement
      ? data?.resourceManagement
      : props.history.location.state?.allData
        ? props.history.location.state?.allData
        : {
          action: [1],
          bandwidths: bandwidths,
          empId: employeeId,
          projectId: projectId,
          skillId: skills,
          salaryRange: [],
          partners: [],
          employeeStatus: [null, null],
          handleFilter: false,
          partnerId: [],
          isForcedAddedToPool: null,
          sortBy: "",
          sortOrder:""
        }
  );
  offshoreEmp?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, empId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "Code1":
          setAllData({ ...allData, partnerId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        default:
      }
    }
  };
  const getResourceOffShoreData = (clearData, currentPage) => {
    const filterData = clearData?.skillId?.length === 0 ? allData : allData;
    if (filterData?.action.length > 1 && filterData?.action[0] === 1) {
      filterData.action.shift();
    }

    setIsLoaded(true);

    let baseURL = api.offShoreResourceList;
    if (limit) {
      baseURL += `?limit=${limit}`;
    }
    if (currentPage) {
      baseURL += `&page=${currentPage}`;
    }
    post(baseURL, filterData)
      .then((res) => {
        setIsLoaded(false);
        setOffshoreData(res.data.responseData);
        setTotalResourceOffShore(res.data.responseData.totalResources);
        setpageCount(res.data.responseData.totalResources / 10);
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };

  const addToPoolResourceMutate = useMutation(addToPool, {
    onSuccess: (res) => {
      if (res?.data?.responseStatus?.statusCode === 0) {
        notifySuccess(res?.data?.responseData)
      } else {
        notifyError(res?.data?.responseData?.message)
      }
    },
    onError: (err) => {
      notifyError(err)
    }
  })

  useEffect(() => {
    if (data?.resourceManagement?.handleFilter) {
      setApplied(true);
    }
    getOffshoreProject();
    getOffshoreEmp();
    getOffshoreSkills();
    getOffshorePartner();
  }, []);
  useEffect(() => {
    getResourceOffShoreData(allData, currentPage);
  }, [currentPage, allData, addToPoolResourceMutate?.isSuccess]);

  const getOffshoreEmp = () => {
    let baseURL = api.allocatedOffShoreEmployeeList;
    get(baseURL)
      .then((res) => {
        setOffshoreEmp(res.data.responseData.employees);
      })
      .catch((err) => { });
  };

  const getOffshoreProject = () => {
    let baseURL = api.projectOffShoreList + `?action=${forProjectOffshore}`;

    get(baseURL, forProjectOffshore)
      .then((res) => {
        setOffshoreProject(res.data.responseData);
      })
      .catch((err) => { });
  };

  useEffect(()=>{
    getOffshoreProject();
  },[forProjectOffshore])

  const getOffshoreSkills = () => {
    let baseURL = api.getOffshoreEmployeeSkillForFilterData;
    get(baseURL)
      .then((res) => {
        setOffshoreSkills(res.data.responseData);
      })
      .catch(() => { });
  };

  

  const getOffshorePartner = () => {
    let baseURL = api.getOffshorePartner;
    get(baseURL)
      .then((res) => {
        setParnername(res.data.responseData);
      })
      .catch(() => { });
  };
  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleAddResources = () => {
    history.push("/AddResourcesOffshore");
  };
  const closeFilter = () => {
    setCurrentPage(1);
    setAllData((preValue) => {
      return preValue
        ? {
          ...preValue,
          action: [1],
          bandwidths: [],
          empId: allData?.empId,
          projectId: [],
          skillId: [],
          salaryRange: [],
          partners: [],
          employeeStatus: [null, null],
          handleFilter: false,
          isForcedAddedToPool: null
        }
        : null;
    });
    setAction([]);
    setBandwidths([]);
    setProjectId([]);
    setSkills([]);
    setShowFilter(false);
    setApplied(false);
    setForProjectOffshore([1]);
  };
  useEffect(() => {
    setData({ ...data, resourceManagement: allData });
  }, [allData]);

  const handleApplied = (arg) => {
    setAllData({ ...allData, handleFilter: true });
    if (
      allData?.action?.length !== 0 ||
      allData?.bandwidths?.length !== 0 ||
      allData?.empId?.length !== 0 ||
      allData?.skillId?.length !== 0 ||
      allData?.projectId?.length !== 0 ||
      allData?.salaryRange?.length !== 0 ||
      allData?.partners.length !== 0
    ) {
      setApplied(true);
    }
    setShowFilter(false);
    setCurrentPage(1);
    getResourceOffShoreData(allData, 1);
  };

  const getPositionCodeForFilterOption = offshoreEmp?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getPartnerList = partnerName?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const value = allData?.empId[0]
    ? getPositionCodeForFilterOption?.find((e) => e.value === allData.empId[0])
    : null;
  const value1 = allData?.partnerId[0]
    ? getPartnerList?.find((e) => e.value === allData.partnerId[0])
    : null;

    const handleSort = (field) => {
      setAllData((prev) => ({
        ...prev, sortBy: field,
      }));
      if (allData.sortBy !== field) {
        setAllData((prev) => ({
          ...prev, sortOrder: "ASC",
        }));
        return;
      }
      if (allData.sortOrder === "ASC") {
        setAllData((prev) => ({
          ...prev, sortOrder: "DESC",
        }));
      } else {
        setAllData((prev) => ({ ...prev, sortOrder: "ASC", }));
      }
    };
  
  
    const renderSortArrow = (field) => {
      if (allData.sortBy !== field) return null;
  
      return allData.sortOrder === "ASC" ? (
        <TiArrowSortedUp
          style={{
            fontSize: "1.1rem",
            cursor: "pointer",
            color: "blue",
          }}
        />
      ) : (
        <TiArrowSortedDown
          style={{
            fontSize: "1.1rem",
            cursor: "pointer",
            color: "blue",
          }}
        />
      );
  
    };
  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "600", width: "17%" }}
              >
                Resource (Offshore)
              </div>
              <BootstrapTooltip title="Allocate Resource">
                <span
                  className={style.plus}
                  onClick={() => handleAddResources()}
                >
                  <span style={{ color: "rgb(252,187,28)", cursor: "pointer" }}>
                    <ControlPointIcon
                      style={{ width: "3rem", height: "2rem" }}
                    />
                  </span>
                </span>
              </BootstrapTooltip>
              <div style={{ width: "12%" }}>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {totalResourceOffShore}
                  </span>
                </p>
              </div>
              <div style={{ width: "8%" }} aria-id="PM0001">
                <p className={style.download}>
                  <span
                    className={style.backColor1}
                    style={{
                      fontSize: "2rem",
                      fontWeight: "500",
                      cursor: "pointer",
                      color: "#a15e5e",
                    }}
                  >
                    <PoolResourcesDownload total={totalResourceOffShore} allData={allData} />
                  </span>
                </p>
              </div>
              <div style={{ width: "25%", paddingLeft: "7%" }}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img
                      className={style.appliedcheck}
                      src={rightTickPRM}
                      alt="img"
                    />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>

              <div
                className={style.s1}
                style={{ width: "29%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.empId?.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        empId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Employee Name/Code"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value}
                  onChange={(e) => {
                    onSearchHandler(e, "Code");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getPositionCodeForFilterOption}
                />
              </div>
              <div
                className={style.s1}
                style={{ width: "21%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.partnerId?.length === 0 ? (
                  <span className={style.cp_serchIcon} style={{marginLeft: '79%'}}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    
                    onClick={() => {
                      setAllData({
                        ...allData,
                        partnerId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Partner"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value1}
                  onChange={(e) => {
                    onSearchHandler(e, "Code1");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getPartnerList}
                />
              </div>
              {/* {checkValidateAccess(

rmsAccessCode?.allocate_resource_offshore
) && (  <div className={style.btn_add}>
                <Link
                  to="/AddResourcesOffshore"
                  style={{ textDecoration: "none" }}
                >
                  <button className={style.create_btn}>
                    <span className={style.plus}>+</span>Allocate Resource
                  </button>
                </Link>
              </div>)} */}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
            <input
              type="checkbox"
              style={{ accentColor: "green", visibility: "visible", width: "1.1rem", height: "1.1rem" }}
              defaultChecked={allData?.isForcedAddedToPool}
              id="removedFromPool"
              onClick={() => { allData?.isForcedAddedToPool && setCurrentPage(1); setAllData(prev => ({ ...prev, isForcedAddedToPool: prev.isForcedAddedToPool === null ? true : null })) }}
            />
            <label htmlFor="removedFromPool" className={style.main} style={{ fontWeight: "600", color: "black" }}>

              Added To Pool
            </label>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.offshorelist}
            style={{ backgroundColor: "white", minHeight: "28rem" }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : OffShoreData?.resources.length > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "10px", position: "sticky", left: "0px", backgroundColor: "rgb(240,240,240)" }}>Code</th>
                    <th style={{ padding: "10px", position: "sticky", left: "56px", backgroundColor: "rgb(240,240,240)" }}>Name</th>
                    <th style={{ padding: "10px" }}></th>
                    <th style={{ padding: "10px" }}>Exp</th>
                    <th style={{ padding: "10px" }}>Salary Range</th>
                    <th style={{ padding: "10px" }}>Project</th>
                    <th style={{ padding: "10px", cursor: "pointer", textDecoration: "underline" }} onClick={()=>handleSort("s.skill_name")}>Technology {renderSortArrow("s.skill_name")}</th>
                    <th style={{ textAlign: "center", padding: "10px" }}>
                      % Allocation
                    </th>
                    <th style={{ padding: "10px" }}>Status</th>
                    <th style={{ padding: "10px" }}>Start Date</th>
                    <th style={{ padding: "10px" }}>End Date</th>
                    <th style={{ padding: "10px" }}>Last Comment <br /> Date</th>
                    <th style={{ padding: "10px" }}>Partner Name</th>
                    <th style={{ padding: "10px" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {OffShoreData?.resources.map((val) => {
                    return (
                      <TableRow
                        val={val}
                        setExitDate={setExitDate}
                        setShow={setShow}
                        allData={allData}
                        currentPage={currentPage}
                        filterName={filterName}
                        applied={applied}
                        addToPoolResourceMutate={addToPoolResourceMutate}
                      />
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} alt="img" />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}
          </div>
          {totalResourceOffShore > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          type={"offShore"}
          setAction={setAction}
          setBandwidths={setBandwidths}
          employeeId={offshoreEmp}
          setEmployeeId={setEmployeeId}
          projectList={offshoreProject}
          setProject={setProjectId}
          offshoreSkills={offshoreSkills}
          setSkills={setSkills}
          setForProjectOffshore={setForProjectOffshore}
        />
      )}
      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
          {ExitDate
            ? Moment(ExitDate).format("DD MMM YY")
            : "No Date Available"}
        </div>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default ResourceManagement;
