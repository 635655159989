import axios from "axios";
const url = process.env.REACT_APP_APPRAISAL_SYSTEM_URL;
// Dashboard API Integration for Appraisal Cycle

export const getAllAppraisalCycles = async (limit,page) => {
    const response = await axios.get(url+`/getAllAppraisalCycle?limit=${limit}&page=${page}`);
    return response.data?.responseData;
  };
  
  export const addNewAppraisalCycle = async (data) => {
    const response = await axios.post(url+`/addNewAppraisalCycle`, data, {
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   Authorization: ` ${cookie.load("token")}`,
      //   employeeId: employeeId,
      // },
    });
    return response.data;
  };
  export const editAppraisalCycle = async (data) => {
    const response = await axios.put(
      url+`/editAppraisalCycle`,data);
    return response.data;
  };
  
  export const deleteAppraisalCycle = async (cycleId) => {
    const response = await axios.put(
      url+`/deleteAppraisalCycle?appraisalCycleId=${cycleId}    `
    );
    return response.data;
  };
  // export const getAllEmployees = async (limit,page) => {
  //   const response = await axios.get(url+`/getAllEmployees?limit=${limit}&page=${page}`);
  //   return response.data?.responseData;
  // };
  
  // Employee List Page API
  
  export const getAllEmployees = async (limit, page, filter) => {
    const response = await axios.post(
      url+`/getAllEmployees?limit=${limit}&page=${page}`,
      filter
    );
    return response.data.responseData;
  };
  
  export const addEmployeeToAppraisalCycle = async (data) => {
    const response = await axios.post(url+`/addEmployeeToAppraisalCycle?action=${data.action}&employeeId=${data.id}`
    // {
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   Authorization: ` ${cookie.load("token")}`,
      //   employeeId: employeeId,
      // },
    // }
    );
    return response.data;
  };
  // export const getAllPosition = async (limit, page, filter) => {
  //   const response = await axios.post(
  //     `${apiKey}/getAllPosition?limit=${limit}&page=${page}`,
  //     filter
  //   );
  //   return response.data.responseData;
  // };
  export const getOpenAppraisalCycle = async () => {
    const response = await axios.get(url+`/getOpenAppraisalCycle`);
    return response.data?.responseData;
  };
  export const getDepartmentList = async () => {
    const response = await axios.get(url+`/getDepartmentList`);
    return response.data?.responseData;
  };
  export const getCurrentAndAllPastCycle = async () => {
    const response = await axios.get(url+`/getCurrentAndAllPastCycle`);
    return response.data?.responseData;
  };
  export const addEmployeesToAppraisalCycle = async (data) => {
    const response = await axios.post(url+'/addEmployeesToAppraisalCycle', data, {
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   Authorization: ` ${cookie.load("token")}`,
      //   employeeId: employeeId,
      // },
    });
    return response.data;
  };
  export const getEmployeeDetailById = async (cycleId,empId) => {
    if(cycleId){
      const response = await axios.get(url+`/getEmployeeDetailById?appraisalCycleId=${cycleId}&empId=${empId}`);
      return response.data?.responseData;
    }
    // http://localhost:8098/appraisalsystem/v1/getEmployeeDetailById?appraisalCycleId=5&empId=1
    const response = await axios.get(url+`/getEmployeeDetailById?empId=${empId}`);
    return response.data?.responseData;
  };
  export const getEmployeeList = async () => {
    const response = await axios.get(url+`/getEmployeeList`);
    return response.data?.responseData;
  };
  export const getEmployeesFromAppraisalCycle = async (cycleId,limit,page) => {
    const response = await axios.get(url+`/getEmployeesFromAppraisalCycle?appraisalCycleId=${cycleId}&limit=10&page=1`);
    return response.data?.responseData;
  };
  export const getEmpAppraisalHistory = async (empId) => {
    const response = await axios.get(url+`/getEmpAppraisalHistory?employeeId=${empId}`);
    return response.data?.responseData;
  };
  export const getEmployeeProposedDetail = async (empId) => {
    const response = await axios.get(url+`/getEmployeeProposedDetail?empId=${empId}`);
    return response.data?.responseData;
  };
  export const getEmpAppraisalHistoryByAppraisalCycle = async (cycleId,empId) => {
    const response = await axios.get(url+`/getEmpAppraisalHistoryByAppraisalCycle?appraisalCycleId=${cycleId}&employeeId=${empId}`);
    return response.data?.responseData;
  };
  export const deleteAppraisal = async ({cycleId,eid}) => {
    const response = await axios.put(
      url+`/deleteAppraisal?appraisalCycleId=${cycleId}&employeeId=${eid}    `
    );
    return response.data;
  };
  export const getCombinationFilterList = async () => {
    const response = await axios.get(url+`/getCombinationFilterList`);
    // http://localhost:8098/appraisalsystem/v1/getCombinationFilterList
    return response.data?.responseData;
  };
  // export const positionList = async (limit, page, filter) => {
  //   const response = await axios.post(
  //     `${apiKey}/getAllCreatePositions?limit=${limit}&page=${page}`,
  //     filter
  //   );
  //   return response.data.responseData;
  // };
  
  // DashBoard -> AppraisalCycleEmployeeLists
  
  // For Appraisal
  
  // export const getEmployeesForAppraisal = async (cycleId,limit,page) => {
  //   const response = await axios.get(url+`/getEmployeesForAppraisal?appraisalCycleId=${cycleId}&limit=${limit}&page=${page}`);
  //   return response.data?.responseData;
  // };
  export const getEmployeesForAppraisal = async (limit, page, filter) => {
    const response = await axios.post(
      url+`/getEmployeesForAppraisal?limit=${limit}&page=${page}`,
      filter
    );
    return response.data.responseData;
  };
  //  Submitting Appraisal
  export const createAppraisal = async (data) => {
    const response = await axios.put(
      url+`/createAppraisal`,data);
    return response.data;
  };
  
  // SendBack
  export const appraisalSendBack = async ({cycleId,eid}) => {
    const response = await axios.patch(url+`/appraisalSendBack?appraisalCycleId=${cycleId}&employeeId=${eid}`);
    return response.data;
  };
  
  // holdAppraisal
  export const holdAppraisal = async ({cycleId,eid}) => {
    const response = await axios.patch(url+`/holdAppraisal?appraisalCycleId=${cycleId}&employeeId=${eid}`);
    return response.data;
  };
  
  // Approve Appraisal
  export const appraisalApproved = async ({cycleId,eid}) => {
    const response = await axios.patch(url+`/appraisalApproved?appraisalCycleId=${cycleId}&employeeId=${eid}`);
    return response.data;
  };
  
  // getEmployeesForReward
  export const getEmployeesForReward = async (limit, page, filter) => {
    const response = await axios.post(
      url+`/getEmployeesForReward?limit=${limit}&page=${page}`,
      filter
    );
    return response.data.responseData;
  };
  
  // getEmployeesForPromotion
  export const getEmployeesForPromotion = async (limit, page, filter) => {
    const response = await axios.post(
      url+`/getEmployeesForPromotion?limit=${limit}&page=${page}`,
      filter
    );
    return response.data.responseData;
  };
  export const addPromotion = async (data) => {
    const response = await axios.put(
      url+`/addPromotion`,data);
    return response.data;
  };
  // Hr Head Comment
  export const getHrHeadComment = async (cycleId,eid) => {
    const response = await axios.get(url+`/getHrHeadComment?appraisalCycleId=${cycleId}&employeeId=${eid}`);
    return response.data?.responseData;
  };
  
  export const addHrHeadComment = async (data) => {
    const response = await axios.post(url+'/addHrHeadComment', data, {
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   Authorization: ` ${cookie.load("token")}`,
      //   employeeId: employeeId,
      // },
    });
    return response.data;
  };
  export const deleteHrHeadComment = async ({employeeId,commentId}) => {
    const response = await axios.put(
      url+`/deleteHrHeadComment?commentByEmpId=${employeeId}&commentId=${commentId}`
    );
    return response.data.responseData;
  };
  
  // Close Cycle
  export const closeAppraisalCycle = async (cycleId) => {
    const response = await axios.post(url+`/closeAppraisalCycle?appraisalCycleId=${cycleId}`,  {
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   Authorization: ` ${cookie.load("token")}`,
      //   employeeId: employeeId,
      // },
    });
    return response.data;
  };
  
  export const addReward = async (data) => {
    const response = await axios.put(
      url+`/addReward`,data);
    return response.data;
  };