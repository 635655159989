import React, { useEffect, useState } from 'react';
import style from "./PromotionInputForm.module.css"
import { addPromotion, getEmployeeProposedDetail } from '../../../../../Utils/axios/AppraisalApi';
import { useMutation, useQuery } from 'react-query';
import { notifyError, notifySuccess } from '../../../../atsDashboard/utils/notify';

import moment from 'moment'

const PromotionInputForm = ({ rewardList, promotionList, modalData, setShowForm, refetchEmployeeDetails, proposedDetails }) => {
  const empId = modalData.eid;
  const [award, setAward] = useState(null);
  const [promotion, setPromotion] = useState(null);
  const [error, setError] = useState(false);
  const [applicableDate, setApplicableDate] = useState();
  const addPromotionMutation = useMutation(addPromotion, {
    onMutate: () => {
    },
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData)
      }
      else {
        notifyError(res.responseData.message)
      }
      refetchEmployeeDetails();
      refetchEmployeeProposedDetails();

    },
    onError: (err) => {
    },
  });

  const { data: employeeProposedDetails, refetch: refetchEmployeeProposedDetails } = useQuery(["getEmployeeProposedDetail"], () =>
    getEmployeeProposedDetail(empId)
  );
  useEffect(() => {
    setAward(employeeProposedDetails?.rewardId)
    setPromotion(employeeProposedDetails?.promotionId)
    // setApplicableDate(employeeProposedDetails?.applicableDate)
    setApplicableDate(moment(employeeProposedDetails?.applicableDate).format("YYYY-MM-DD"))
    console.log(employeeProposedDetails?.applicableDate);
  }, [employeeProposedDetails]);

  const handleInputChange = (e) => {
    setApplicableDate(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to handle form submission here
    let data = {
      "appraisalCycleId": Number(modalData.cycleId),
      "employeeId": Number(modalData.eid),
      "promotionId": promotion === "" || promotion === "Choose Promotion" ? null : Number(promotion),
      "rewardId": award === "" || award === "Choose Reward" ? null : Number(award),
      "applicableDate": applicableDate
    }
    if (data.promotionId === null || data.promotionId === 0) {
      setError(true);
      return;
    }
    addPromotionMutation.mutate(data);
    setShowForm(false);
    setError(false);
    setAward(null)
    setPromotion(null)
  };

  const handleCancel = () => {
    // Add your logic to handle form cancellation here
    setShowForm(false);
    setAward(null)
    setError(false);
    setPromotion(null)
  };

  //   Input Handler Functions
  return (
    <div className={style.container}>
      {/* <h5 className={style.title}>Appraisal Details</h5> */}
      <div className={style.form}>
        <form >
          <div className={style.form_row}>
            <div>
              <label htmlFor="Reward" className={style.label}> Choose Reward</label>
              <div>
                <div className={style.select}>
                  <select
                    className={style.selectStyling}
                    name="Choose Reward"
                    value={award}
                    onChange={(e) => setAward(e.target.value)}
                  >
                    <option value={null}>Choose Reward</option>
                    {rewardList?.map((item) => (
                      <option value={item?.id} key={item?.id}>{item.name}</option>
                    ))}
                  </select>
                </div>

              </div>
            </div>
            <div>
              <label htmlFor="field2" className={style.label}>Promotion Name</label>
              <div>
                <div className={style.select}>
                  <select
                    className={style.selectStyling}
                    name="Choose Promotion"
                    value={promotion}
                    onChange={(e) => setPromotion(e.target.value)}
                  >
                    <option value={null}>Choose Promotion</option>
                    {promotionList?.map((item) => (
                      <option value={item?.id} key={item?.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <span
                  className={`${style.errorText} ${error ? style.showError : style.hideError
                    }`}
                >
                  *Please Choose Promotion
                </span>
              </div>
            </div>
            <div>
              <label htmlFor="field2" className={style.label}>Applicable From</label>
              <div>
                <div className={style.select}>
                  <br></br>
                  <input
                    type="date" // Use "date" input type for date selection
                    name="startDate"
                    className={style.selectStyling}
                    value={applicableDate}
                    onChange={handleInputChange}
                    id="startDate"
                  />
                </div>
                <span
                  className={`${style.errorText} ${error ? style.showError : style.hideError
                    }`}
                >
                  *Please Select Date
                </span>
              </div>
            </div>
          </div>
          {/* Submit and Cancel buttons */}
          <div className={style.buttons}>
            <button type="submit" style={{ backgroundColor: "#855ea1", border: "1px solid #855ea1" }} onClick={handleSubmit}>Submit</button>
            <button type="button" style={{ backgroundColor: "#855ea1", border: "1px solid #855ea1" }} onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PromotionInputForm;
