import React from 'react';
import { Route } from "react-router-dom";
import SideBar from "../commonComponent/SideBar";
import Header from "../commonComponent/Header";
import style from './CreateTask.module.css';
import CreateTaskComponent from './component/CreateTaskComponent';
const CreateTask = () => {

  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <CreateTaskComponent />
      </div>
    </div>
  );
}

export default CreateTask;


