import React from "react";
import Modal from "react-responsive-modal";
import style from "./FilterInvoice.module.css";
import moment from "moment";
import { filterBox } from "../utils/style";
import {
  getClientName,
  getServiceType,
  getAdvanceStatus,
  getAmountToBeReceived,
  getPaymentStatus,
  getInvoicePaymentDate,
} from "../../../Utils/axios/expenceApi.js";
import CreatableSelect from "react-select/creatable";
import { useQuery } from "react-query";

function FilterInvoice({
  setShowFilter,
  showFilter,
  firstCard,
  secondCard,
  firstCardlist,
  secondCardlist,
  thirdCard,
  thirdCardlist,
  forthCard,
  forthCardlist,
  fifthCard,
  fifthCardList,
  sixthCard,
  sixthCardList,
  filterData,
  setFilterData,
  filterUpdateFuc,
  filterClearAllFuc,
  sevenCard,
  eightCard,
}) {
  const { data: clientData } = useQuery(["getClientName"], getClientName);
  const clientOption = clientData?.map((e) => ({
    value: e.id,
    label: e.clientName,
  }));
  const { data: ServiceType } = useQuery(["getServiceType"], getServiceType);
  const ServiceTypeData = ServiceType?.map((e) => ({
    value: e.id,
    label: e.serviceType,
  }));
  const { data: AdvanceStatus } = useQuery(
    ["getAdvanceStatus"],
    getAdvanceStatus
  );
  const AdvanceStatusData = AdvanceStatus?.map((e) => ({
    value: e.id,
    label: e.status,
  }));
  const { data: AmountToBeReceived } = useQuery(
    ["getAmountToBeReceived"],
    getAmountToBeReceived
  );
  const AmountToBeReceivedData = AmountToBeReceived?.map((e) => ({
    value: e.amount,
    label: e.amount,
  }));
  const { data: PaymentStatusData } = useQuery(
    ["getPaymentStatus"],
    getPaymentStatus
  );
  const PaymentStatus = PaymentStatusData?.map((e) => ({
    value: e.id,
    label: e.status,
  }));

  const { data: getPaymentDateData } = useQuery(
    ["getPaymentDate"],
    getInvoicePaymentDate
  );

  const PaymentDate = getPaymentDateData?.map((e) => ({
    value: e.id,
    label: moment(e.paymentDate).format("YYYY-MM-DD"),
  }));

  const handleInputValue = (e, status) => {
    setFilterData((preData) => ({
      ...preData,
      projectId: e.value,
      projectValue: e.label,
    }));
  };

  const handleChnageAdvanceStatus = (e) => {
    setFilterData((preData) => ({
      ...preData,
      advanceStausId: e.value,
      advanceStausValue: e.label,
    }));
  };

  const handleClientName = (e) => {
    setFilterData((preData) => ({
      ...preData,
      clientId: e.value,
      ClientValue: e.label,
    }));
  };

  const handleAmountToBeReceived = (e) => {
    setFilterData((preData) => ({
      ...preData,
      amountToBeReceived: e.value,
      // amountToBeReceivedValue:e.value
    }));
  };
  const handlePaymentStatus = (e) => {
    setFilterData((preData) => ({
      ...preData,
      paymentStatus: e.value,
      paymentStatusValue: e.label,
    }));
  };

  const handlePaymentDate = (e) => {
    setFilterData((preData) => ({
      ...preData,
      paymentDate: e.label,
      paymentDateValue: e.label,
    }));
  };

  return (
    <>
      <Modal
        open={showFilter}
        center
        onClose={() => setShowFilter(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal overflow-visible-important",
        }}
      >
        <div className={style.filterModal}>
          <div className={style.filterCards}>
            {firstCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{firstCard}</div>
                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  <CreatableSelect
                    styles={filterBox}
                    isMulti
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    options={ServiceTypeData}
                    value={filterData?.projectType}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        projectType: e,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {forthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{forthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        amountToBeReceived: e,
                      });
                    }}
                    value={filterData?.amountToBeReceived}
                    options={AmountToBeReceivedData}
                  />
                </div>
              </div>
            )}

            {secondCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{secondCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        clientName: e,
                      });
                    }}
                    value={filterData?.clientName}
                    options={clientOption}
                  />
                </div>
              </div>
            )}
            {thirdCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{thirdCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        advanceStatus: e,
                      });
                    }}
                    value={filterData?.advanceStatus}
                    options={AdvanceStatusData}
                  />
                </div>
              </div>
            )}
            {fifthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{fifthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        paymentReceivedDate: e,
                      });
                    }}
                    value={filterData?.paymentReceivedDate}
                    options={PaymentDate}
                  />
                </div>
              </div>
            )}
            {sixthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{sixthCard}</div>
                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        paymentStatus: e,
                      });
                    }}
                    value={filterData?.paymentStatus}
                    options={PaymentStatus}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={style.filterBtn}>
          <button
            className={style.cancelBtn}
            onClick={() => {
              filterClearAllFuc();
            }}
          >
            Clear All
          </button>
          <button className={style.createBtn} onClick={() => filterUpdateFuc()}>
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
}

export default FilterInvoice;
