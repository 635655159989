import React, { useEffect, useState } from "react";
import Header from "../../../taskTracker/commonComponent/Header";
import SideBar from "../../Clients/component/SideBar";
import style from "./WeeklyMonthlyNotification.module.css";
import moment from "moment";
import constants, { months } from "../../../../Utils/constants";
import axios from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import loaderImg from "../../../../assets/loader.gif";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { customSearch } from "../../Clients/component/style";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import milestone from "../../../../assets/milestone.png";
import Pagination from "../../CommonComponent/Pagination";
import { api } from "../../CommonApi/api";
import { get } from "../../CommonApi/axiosCall";
import { useHistory } from "react-router-dom";
import { BsInfoSquareFill } from "react-icons/bs";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { useQuery } from "react-query";
import { getEmployeeList } from "../../../../Utils/axios/clientContractApi";

import cookie from "react-cookies";

export const WeeklyMonthlyNotification = (props) => {
  const baseUrl = constants.apiBaseUrlResource;
  const [, setShowFilter] = useState(false);
  const [filteredData, setfilterData] = useState();
  const [OnsiteData, setOnsiteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [totalResourceOnsite, setTotalResourceOffShore] = useState(0);
  const [, setClientListing] = useState([]);
  const [, setMonths] = useState([]);
  const [empListing, setEmpListing] = useState([]);
  const [onsiteSkills, setOnsiteSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [show, setShow] = useState(false);

  const [ClientInformedReason] = useState("");

  const [selectedValue, setSelectedValue] = useState(1);
  const [selectedProjectId, setSelectedProjectid] = useState(0);
  const [filterName, setFilterName] = useState({});
  const [filterEmp, setFilterEmp] = useState({});
  const [allData, setAllData] = useState({
    action: [1],
    clientId: [],
    employeeId: [],
    skill: [],
    salesPersonId: [],
    salaryRange: [],
    partners: [],
    startDateMonth: [],
    endDateMonth: [],
    employeeStatus: [null, null],
    quaterlyEndOfDeployment: [],
    quaterlyDeployment: [],
    handleFilter: false,
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [targetDuration, setTargetDuration] = useState();
  const handleNext = () => {
    if (currentIndex + 3 < OnsiteData.length) {
      setCurrentIndex(currentIndex + 3);
    }
    setSelectedProjectid(0);
  };

  const handlePrevious = () => {
    if (currentIndex - 3 >= 0) {
      setCurrentIndex(currentIndex - 3);
    }
    setSelectedProjectid(0);
  };
  useEffect(() => {
    // Filter the OnsiteData based on the targetDuration
    const filteredData1 = OnsiteData.filter(
      (item) => item.durationName === targetDuration
    );
    setfilterData(filteredData1);
  }, [targetDuration, OnsiteData]);
  const BlackTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "black",
      color: "#fff",
      fontSize: "1rem",
      whiteSpace: "pre-line",
      padding: "0.5rem",
    },
  }))(Tooltip);
  const history = useHistory();

  const HistoryHandler1 = () => {
    history.push("/onSiteReportDashBoard");
  };
  function handleOptionChange(event) {
    setSelectedValue(event.target.value);
    setSelectedProjectid(0);
  }

  const handleProjectClick = (durationName) => {
    setTargetDuration(durationName);
  };

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, skill: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "Code1":
          setAllData({ ...allData, employeeId: [e?.value] });
          setFilterEmp({ ...filterEmp, positionCode: e?.label });
          break;
        case "Code2":
          setAllData({ ...allData, salesPersonId: [e?.value] });
          setFilterEmp({ ...filterEmp, positionCode: e?.label });
          break;
        default:
      }
    }
  };
  const { data: getEmployeeListOptions } = useQuery(["getEmployeeList"], () =>
    getEmployeeList()
  );

  empListing?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const getOnsiteSkillsList = onsiteSkills?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getemployee = empListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getSalpersonList = getEmployeeListOptions?.map((i) => ({
    value: i.id,
    label: i.label,
  }));

  const getAddResourceOnsiteData = (clearData, currentPage) => {
    let url = api.monthlyWeeklyNotification;
    if (allData?.employeeId) {
      url += `?empId=${allData?.employeeId}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}`;
    }
    if (selectedValue) {
      url += `&reportType=${selectedValue}`;
    }
    if (allData?.skill) {
      url += `&skill=${allData?.skill}`;
    }
    if (allData?.salesPersonId) {
      url += `&salesPersonId=${allData?.salesPersonId}`;
    }

    setIsLoaded(true);
    axios
      .get(baseUrl + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setIsLoaded(false);
        setOnsiteData(res.data.responseData.onsiteReportResources);
        setTotalResourceOffShore(
          res.data.responseData.onsiteReportResources[selectedProjectId]?.count
        );
        if (selectedValue == 2) {
          setTargetDuration(
            res.data.responseData.onsiteReportResources.reverse().slice(0, 1)[0]
              ?.durationName
          );
        } else {
          setTargetDuration(
            res.data.responseData.onsiteReportResources.slice(0, 1)[0]
              ?.durationName
          );
        }
        setpageCount(
          res.data.responseData.onsiteReportResources[selectedProjectId]
            ?.count / 10
        );
      })
      .catch(() => {
        setIsLoaded(false);
      });
  };
  const getClientListing = () => {
    let url = api.getOnsiteClientList;

    get(url)
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch(() => {});
  };

  const getMonths = () => {
    axios
      .get(baseUrl + "/getMonthDetails", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setMonths(res.data.responseData);
      })
      .catch(() => {});
  };

  const getEmpListing = () => {
    let url = api.getOnsiteEmployeeList;

    get(url)
      .then((res) => {
        setEmpListing(res.data.responseData.employees);
      })
      .catch(() => {});
  };

  const getOnsiteSkills = () => {
    let url = api.getOnsiteFilterSkillData;
    get(url)
      .then((res) => {
        setOnsiteSkills(res.data.responseData);
      })
      .catch(() => {});
  };

  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  useEffect(() => {
    getAddResourceOnsiteData(allData, currentPage);
  }, [currentPage, selectedValue, allData]);

  useEffect(() => {
    getMonths();
    getClientListing();
    getEmpListing();
    getOnsiteSkills();
  }, []);

  const value1 = allData?.employeeId[0]
    ? getemployee?.find((e) => e.value === allData.employeeId[0])
    : null;
  const value2 = allData?.skill[0]
    ? getOnsiteSkillsList?.find((e) => e.value === allData.skill[0])
    : null;
  const value3 = allData?.salesPersonId[0]
    ? getSalpersonList?.find((e) => e.value === allData.salesPersonId[0])
    : null;
  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <label style={{ paddingLeft: "2rem" }}>
                <input
                  type="radio"
                  name="location"
                  value={1}
                  checked={selectedValue == 1}
                  onChange={handleOptionChange}
                />
                Weekwise
              </label>
              <label style={{ paddingLeft: "2rem" }}>
                <input
                  type="radio"
                  name="location"
                  value={2}
                  checked={selectedValue == 2}
                  onChange={handleOptionChange}
                />
                Monthwise
              </label>
            </div>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>

            <div className={style.second_child}>
              <div className={style.title}>
                Weekly/Monthly Notifications/ Resignation Report
              </div>
              <div className={style.twoFilter}>
                <div
                  className={style.s2}
                  // style={{ width: "26%" }}
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  {allData?.salesPersonId?.length == 0 ? (
                    <span className={style.cp_serchIcon2}>
                      <img src={searchIcon} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon2}
                      onClick={() => {
                        setAllData({
                          ...allData,
                          salesPersonId: [],
                        });
                        setFilterEmp({ ...filterEmp, positionCode: null });
                      }}
                    >
                      {allData?.salesPersonId?.length != 0 ? (
                        <AiOutlineClose />
                      ) : null}
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Sales Person"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={value3}
                    onChange={(e) => {
                      onSearchHandler(e, "Code2");
                      setCurrentPage(1);
                      setShowFilter(false);
                    }}
                    options={getSalpersonList}
                  />
                </div>
                <div
                  className={style.s1}
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  {allData?.skill?.length == 0 ? (
                    <span className={style.cp_serchIcon}>
                      <img src={searchIcon} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon}
                      onClick={() => {
                        setAllData({
                          ...allData,
                          skill: [],
                        });
                        setFilterName({ ...filterName, positionCode: null });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Technology"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={value2}
                    onChange={(e) => {
                      onSearchHandler(e, "Code");
                      setShowFilter(false);
                      setCurrentPage(1);
                    }}
                    options={getOnsiteSkillsList}
                  />
                </div>
                <div
                  className={style.s22}
                  // style={{ width: "26%" }}
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  {allData?.employeeId?.length == 0 ? (
                    <span className={style.cp_serchIcon2}>
                      <img src={searchIcon} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon2}
                      onClick={() => {
                        setAllData({
                          ...allData,
                          employeeId: [],
                        });
                        setFilterEmp({ ...filterEmp, positionCode: null });
                      }}
                    >
                      {allData?.employeeId?.length != 0 ? (
                        <AiOutlineClose />
                      ) : null}
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Employee Name/Code"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={value1}
                    onChange={(e) => {
                      onSearchHandler(e, "Code1");
                      setCurrentPage(1);
                      setShowFilter(false);
                    }}
                    options={getemployee}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            {selectedValue == 1
              ? OnsiteData.map((project, id) => (
                  <button
                    key={project.id}
                    className={
                      project?.durationName === targetDuration
                        ? style.button
                        : style.activeButton
                    }
                    onClick={() => {
                      handleProjectClick(project?.durationName);
                      setCurrentPage(1);
                    }}
                  >
                    {project.durationName}
                    <span
                      className={
                        project?.durationName === targetDuration
                          ? style.count1
                          : style.count2
                      }
                    >
                      {project?.count}
                    </span>
                  </button>
                ))
              : OnsiteData.slice(currentIndex, currentIndex + 3).map(
                  (project, id) => (
                    <button
                      key={project.id}
                      className={
                        project?.durationName === targetDuration
                          ? style.button
                          : style.activeButton
                      }
                      onClick={() => {
                        handleProjectClick(project.durationName);
                        setCurrentPage(1);
                      }}
                    >
                      {project.durationName}{" "}
                      <span
                        className={
                          project?.durationName === targetDuration
                            ? style.count1
                            : style.count2
                        }
                      >
                        {project?.count}
                      </span>
                    </button>
                  )
                )}

            {selectedValue != 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: "3rem",
                }}
              >
                <ArrowBackIosIcon
                  onClick={handlePrevious}
                  disabled={currentIndex === 0}
                  style={{
                    cursor: "pointer",
                    color: currentIndex === 0 ? "gray" : "black",
                  }}
                />

                <ArrowForwardIosIcon
                  onClick={handleNext}
                  disabled={currentIndex + 3 >= OnsiteData.length}
                  style={{
                    cursor: "pointer",
                    color:
                      currentIndex + 3 >= OnsiteData.length ? "gray" : "black",
                  }}
                />
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.onsiteList}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : filteredData?.length > 0 &&
              filteredData[0]?.onsiteResources?.length > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "10px" }}>Code</th>
                    <th style={{ padding: "10px" }}>Name</th>
                    <th style={{ padding: "10px" }}>Client Name</th>
                    <th style={{ padding: "10px" }}>Employer</th>
                    <th style={{ padding: "10px" }}>Sales Person</th>
                    {/* <th style={{ padding: "10px" }}>Resource Source</th> */}
                    <th style={{ padding: "10px" }}>Technology</th>
                    <th style={{ padding: "10px" }}>Deployment<br/> Date</th>
                    <th style={{ padding: "10px" }}>Resign Date</th>
                    <th style={{ padding: "10px" }}>
                      Client Notification<br/> Date
                    </th>
                    <th style={{ padding: "10px" }}>Notification<br/> Week</th>
                    <th style={{ padding: "10px" }}>
                      Client Notification<br/> Reason
                    </th>
                    <th style={{ padding: "10px" }}>Expected <br/>End Date</th>
                    <th style={{ padding: "10px" }}>End Date</th>
                    <th style={{ padding: "10px" }}>End Week</th>
                    <th style={{ padding: "10px" }}>End Month</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData[0]?.onsiteResources?.map((val) => {
                    return (
                      <tr
                        key={val.id}
                        style={{
                          borderBottom: "2px solid #F0F0F0",
                          textAlign: "start",
                          fontSize: "13px",
                        }}
                      >
                        <td style={{ padding: "10px" }}>{val.empCode}</td>
                        <td style={{ padding: "10px" }}>{val.empName}</td>
                        <td style={{ padding: "10px" }}>{val.clientName}</td>
                        <td style={{ padding: "10px" }}>{val.employer}</td>
                        <td style={{ padding: "10px" }}>
                          {val.salesPersonName}
                        </td>
                        {/* <td style={{ padding: "10px" }}>
          {val.resourceSource === false
            ? "Self"
            : val.resourceSource === true
            ? "Handover"
            : null}
        </td> */}
                        <td style={{ padding: "10px" }}>{val.skillName}</td>
                        <td style={{ padding: "10px" }}>
                          {moment(val.allocationdate).format("DD MMM YY")}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.resignDate
                            ? moment(val.resignDate).format("DD MMM YY")
                            : ""}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.clientNotificationDate
                            ? moment(val.clientNotificationDate).format(
                                "DD MMM YY"
                              )
                            : ""}
                        </td>
                        <td style={{ padding: "10px" }}>{val?.week}</td>
                        <td style={{ padding: "10px" }}>
                          {val?.clientInformedReason ? (
                            <BlackTooltip title={val?.clientInformedReason}>
                              <p>{<BsInfoSquareFill />}</p>
                            </BlackTooltip>
                          ) : null}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.expectedEndDate
                            ? moment(val.expectedEndDate).format("DD MMM YY")
                            : ""}
                        </td>
                        <td style={{ padding: "10px" }}>
                          {val.deallocationdate
                            ? moment(val.deallocationdate).format("DD MMM YY")
                            : ""}
                        </td>
                        <td style={{ padding: "10px" }}>{val.endWeek}</td>
                        <td style={{ padding: "10px" }}>
                          {val.deallocationdate &&
                            moment(val.deallocationdate).format("MMM")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}

            {/* } */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <button className={style?.gotoListBtn} onClick={HistoryHandler1}>
              Back
            </button>
            {totalResourceOnsite > limit && pageCount > 0 && (
              <div
                className={style.paginationmobileContainer}
                style={{ marginTop: "20px" }}
              >
                <Pagination
                  pagesCount={pageCount}
                  pageSize={limit}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>{ClientInformedReason}</div>
      </Modal>
    </>
  );
};
