import { useState, useEffect } from "react";
import style from "./HrHead.module.css";
import AddCommentCom from "../AddCommentForm/AddCommentCom";
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import {
  getHrHeadComment,
  deleteHrHeadComment,
} from "../../../../../../Utils/axios/AppraisalApi";
import { employeeId } from "../../../../../../Utils/constants";
import deleteIcon from "../../../../assets/delete-icon.png";
import editIcon from "../../../../assets/Edit Icon.png";
function HrHeadCom({ modalData, setModalIsOpen, refetchEmployeeList }) {
  const [obj, setObj] = useState(null);

  // Getting Comment
  const [hrHeadComment, setHrHeadComment] = useState([]);
  const {
    data: comments,
    isLoading: commentsLoading,
    isError: commentsError,
    refetch: refetchComments,
  } = useQuery(["getHrHeadComment"], () =>
    getHrHeadComment(modalData.cycleId, modalData.eid)
  );
  useEffect(() => {
    setHrHeadComment(
      comments?.map((comment) => {
        return {
          Reported_date: comment.createdOn,
          username: comment.commentByEmpName,
          Comment: comment.hrHeadComment,
          commentId: comment.commentId,
          commentByEmpId: comment.commentByEmpId,
        };
      })
    );
  }, [comments]);
  const [showComment, setShowComment] = useState(false);
  const handleAddComments = () => {
    setShowComment(!showComment);
    setObj(null);
  };
  // Deleting Comment
  const handleDelete = (e) => {
    const commentId = Number(e.target.id);
    try {
      deleteHrHeadCommentMutation.mutate({ employeeId, commentId });
    } catch (mutationError) {
      console.error("Mutation error:", mutationError);
    }
  };
  const deleteHrHeadCommentMutation = useMutation(deleteHrHeadComment, {
    onMutate: () => {},
    onSuccess: () => {
      refetchComments();
      typeof refetchEmployeeList === "function" && refetchEmployeeList();
    },
    onError: (err) => {},
  });

  // Editing Comment
  const handleEdit = (f, obj) => {
    const commentId = Number(f.target.id);
    setObj(obj);
    setShowComment(true);
  };

  return (
    <div>
      <div className={style.title_Box}>
        <h5 className={style.title}>Comment Details</h5>
      </div>
      <table className={style.container}>
        <thead>
          <tr>
            <td className={style.td_style}>Comment Date</td>
            <td className={style.td_style}>Username</td>
            <td className={style.td_style}>Comment</td>
            {modalData.cycleStatus !== "Closed" && (
              <td className={style.td_style}>Action</td>
            )}
          </tr>
        </thead>
        <tbody>
          {hrHeadComment?.map((obj) => {
            return (
              // <div className={style.content}>
              <tr>
                <td className={style.td_style2}>{obj.Reported_date}</td>
                <td className={style.td_style2}>{obj.username}</td>
                <td className={`${style.text_overflow} ${style.td_style2}`}>
                  {obj.Comment}
                </td>
                <td className={style.td_style2}>
                  <div className={style.action}>
                    {employeeId === obj.commentByEmpId &&
                      modalData.cycleStatus !== "Closed" && (
                        <img
                          src={deleteIcon}
                          alt="image"
                          id={obj.commentId}
                          title="Delete Comment"
                          onClick={(e) => handleDelete(e)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === 'Space') {
                              handleDelete(e);
                            }
                          }}
                          tabIndex={0} // Make the <img> focusable
                          aria-label="Delete Comment" // Provide an accessible name
                        />
                      )}
                    {employeeId === obj.commentByEmpId &&
                      modalData.cycleStatus !== "Closed" && (
                        <img
                          src={editIcon}
                          alt="edit"
                          height="16px"
                          width="18px"
                          title="Edit Comment"
                          id={obj.commentId}
                          onClick={(e) => {
                            handleEdit(e, obj);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === 'Space') {
                              handleEdit(e,obj);
                            }
                          }}
                          tabIndex={0} // Make the <img> focusable
                          aria-label="Edit Comment" // Provide an accessible name
                        />
                      )}
                  </div>
                </td>
              </tr>
              // </div>
            );
          })}
        </tbody>
      </table>
      {modalData.cycleStatus === "Open" && (
        <div className={style.addButton}>
          <button className={style.add_btn} onClick={handleAddComments}>
            {!showComment ? "Add Comment" : "Cancel"}
          </button>
        </div>
      )}
      {showComment ? (
        <AddCommentCom
          obj={obj}
          setObj={setObj}
          modalData={modalData}
          setShowComment={setShowComment}
          refetchComments={refetchComments}
          setModalIsOpen={setModalIsOpen}
          refetchEmployeeList={refetchEmployeeList}
        />
      ) : null}
    </div>
  );
}
export default HrHeadCom;
