import { FormControlLabel } from "@mui/material";
import React from "react";
import { FormGroup } from "react-bootstrap";
import DropSwitch from "../../umsUtills/style";

const DropSwitchComm = ({ isChecked, handleChange, label }) => {
    return (
        <>
            <FormGroup>
                <FormControlLabel
                    control={<DropSwitch checked={isChecked} onChange={handleChange} />}
                    label={label}
                />
            </FormGroup>
        </>
    );
};

export default DropSwitchComm;
