
import { path } from "../../../../routes/PathNames";
import dashboardI from "../../../../assets/ExitFormality/DashboardWhite.png"
import dashboardA from "../../../../assets/ExitFormality/Dashboard.png"
import employeeExitI from "../../../../assets/ExitFormality/ExmployeeExitWhite.png"
import employeeExitA from "../../../../assets/ExitFormality/EmployeeExit.png"
export const menuItems = [
  {
    path: path.ef_dashboard,
    imgSrc: dashboardI,
    imgSrcSelected: dashboardA,
    heading: "Dashboard",
    validPaths: [path.ef_dashboard],
  },
  {
    path: path.ef_employeeExit,
    imgSrc: employeeExitI,
    imgSrcSelected: employeeExitA,
    heading: "Employee Exit",
    validPaths: [path.ef_employeeExit],
  },
];
