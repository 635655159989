import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../ViewCreatePartner/ViewCreatePartner.module.css";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton1";
import { FaLinkedin } from "react-icons/fa";
import axios from "axios";
import moment from "moment";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;

const ViewCreatePartner = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPag,
}) => {
  const [PartnerDataById, setPartnerDataById] = useState();
  const getClientDetails = (id) => {
    axios
      .get(baseUrl + `/partner-lead?partnerLeadId=${prevData?.CreateId}`, {})
      .then((res) => {
        setPartnerDataById(res.data.responseData);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getClientDetails();
  }, []);
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
      default:
        return;
    }
  };
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "15px", marginLeft: "25px" }}>View Partner Lead</h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Partner Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.partnerName}
              </p>
            </Col>

            <Col md={3}>
              <p className={classes.label}>T.A.</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.talentAcquisitionName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Company Size</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.companySize}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Status</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.statusName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Source</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.sourceName}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Location</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.locationName}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>LinkedIn</p>
              <p className={classes.partnerlabal}><FaLinkedin onClick={() => window.open(PartnerDataById?.linkedInUrl, '_blank') } style={{ color: 'blue', cursor: 'pointer', fontSize: '18px' }} /></p>
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>
      <div className={classes.lastBtn_item}>
        <InvoiceButton
          onClick={() => {
            setNextScreen("EditViewProject");
            setPrevData((prevData) => ({
              ...prevData,
              page2: null,
              page3: null,
              page4: null,
              active: "EditViewProject",
              EditId: PartnerDataById?.id,
            }));
          }}
        >
          Edit
        </InvoiceButton>
        <InvoiceButton
          onClick={() => {
            setNextScreen("projectLead");
            setPrevData((prevData) => ({
              ...prevData,
              page2: null,
              page3: null,
              page4: null,
              active: "projectLead",
            }));
          }}
        >
          Done
        </InvoiceButton>
      </div>
    </>
  );
};
export default ViewCreatePartner;
