import BreadCrum from "../../breadcrums/BreadCrum";
import InputBox from "../../commonComponent/ElementCommon/InputBox";
import ATSButton from "../../customTooltip/ATSButton";
import ATSCard from "../../customTooltip/ATSCard";
import ATSContainer from "../../customTooltip/ATSContainer";
import style from "./blackListedCompany.module.scss";
import {
  addBlackListMarketingCompny,
  getAllBlackLIstedCompany,
} from "../../../../Utils/axios/api";
import { useMutation, useQuery } from "react-query";
import { useEffect, useState } from "react";
import { Toastify, ToastifyError } from "../../../../App";
import Pagination from "../../../taskTracker/commonComponent/Pagination";
import moment from "moment";
import { MdModeEditOutline } from "react-icons/md";
import TotalCount from "../../TotalCount/TotalCount";
import { FaBuilding, FaLinkedin, FaSearch } from "react-icons/fa";
import { employeeId } from "../../../../Utils/constants";

const BlackListedCompany = ({ prevData, setPrevData, setNextScreen }) => {
  const [formData, setFormData] = useState({ companyName: "", reason: "", companyLinkedInUrl:"", companyWebUrl: "" });
  const [filterData, setFilterData] = useState({ searchFilter: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [validate, setValidate] = useState(false);
  const [viewAddScreen, setViewAddScreen] = useState(false);
  const mutation = useMutation(addBlackListMarketingCompny, {
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 0) {
        Toastify(res.responseData.message);
      } else {
        Toastify(
          formData.id
            ? "Company Edited Successfully"
            : "Company Added Successfully"
        );
      }
      setViewAddScreen(false);
      setFormData({ companyName: "", reason: "" });
      setValidate(false);
      refetch();
    },
    onError: () => {
      ToastifyError("Something went wrong");
    },
  });
  const handleForm = () => {
    if (formData?.companyName && formData?.reason && (formData?.companyLinkedInUrl.length===0 || isValidUrl(formData?.companyLinkedInUrl)) && (formData?.companyWebUrl.length===0 || isValidUrl(formData?.companyWebUrl))) {
      mutation.mutate({
        ...formData,
        createdById: employeeId,
        id: formData?.id ? formData?.id : null,
      });
    } else {
      setValidate(true);
    }
  };
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const { data, isLoading, isError, refetch } = useQuery(
    ["getAllBlackLIstedCompany", currentPage, filterData],
    () => getAllBlackLIstedCompany(25, currentPage, filterData?.searchFilter)
  );
  
  function isValidUrl(url) {
    const regex = /^https?:\/\/[^\s/$.?#].[^\s]*$/;
    return regex.test(url);
  }
  useEffect(()=>{
    setCurrentPage(1);
  },[filterData])
  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
        PlusComponent={
          <>
            <div
              title="Create Blacklisted Company"
              className={style.add}
              onClick={() => setViewAddScreen(true)}
            >
              <p className={style.plus}>+</p>
            </div>
            <TotalCount totalCount={data?.positionCount} />
          </>
        }
      >
        <div style={{ width: "14rem", position: "relative" }}>
          <input
            className={style.cp_field}
            type="search"
            placeholder="Company Name"
            value={filterData?.searchFilter}
            onChange={(e) =>
              setFilterData((prev) => ({
                ...prev,
                searchFilter: e?.target.value,
              }))
            }
          />
          {filterData?.searchFilter === "" ? (
            <FaSearch
              style={{
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#ccc", // Adjust the color as needed
              }}
            />
          ) : null}
        </div>
      </BreadCrum>
      {viewAddScreen && (
        <>
          <ATSContainer>
            <div className={style.companyBox}>
              <div className={style.col1}>
                <p className={style.cp_label}>
                  Company Name <span className={style.mandatory}>*</span>
                </p>
                <InputBox
                  value={formData.companyName}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      companyName: e.target.value,
                    }))
                  }
                />
                <p
                  className={
                    validate && !formData?.companyName?.trim()
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Company Name
                </p>
              </div>
              <div className={style.col1}>
                <p className={style.cp_label}>
                  Reason <span className={style.mandatory}>*</span>
                </p>
                <InputBox
                  value={formData.reason}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, reason: e.target.value }))
                  }
                />
                <p
                  className={
                    validate && !formData?.reason?.trim()
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Reason
                </p>
              </div>
              <div className={style.col1}>
                <p className={style.cp_label}>
                  Company's LinkedIn
                </p>
                <InputBox
                  value={formData.companyLinkedInUrl}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, companyLinkedInUrl: e.target.value }))
                  }
                />
                {console.log(isValidUrl(formData?.companyLinkedInUrl))}
                <p
                  className={
                    validate && (formData?.companyLinkedInUrl?.length===0 ? false : !isValidUrl(formData?.companyLinkedInUrl))
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Valid Url
                </p>
              </div>
              <div className={style.col1}>
                <p className={style.cp_label}>
                  Company's Website
                </p>
                <InputBox
                  value={formData.companyWebUrl}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, companyWebUrl: e.target.value }))
                  }
                />
                <p
                  className={
                    validate && (formData?.companyWebUrl?.length===0 ? false : !isValidUrl(formData?.companyWebUrl))
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Valid Url
                </p>
              </div>
            </div>
          </ATSContainer>
          <ATSCard>
            <div className={style.submit}>
              <ATSButton
                styles={{ color: "#65a15e", backgroundColor: "white" }}
                onClick={() => {
                  setViewAddScreen(false);
                  setFormData({ companyName: "", reason: "" });
                }}
              >
                Cancel
              </ATSButton>
              <ATSButton onClick={() => handleForm()}>
                {formData.id ? "Edit" : "Submit"}
              </ATSButton>
            </div>
          </ATSCard>
        </>
      )}
      <ATSContainer styles={{ overflow: "auto" }}>
        <div className={style.th}>
          <div>Company Name</div>
          <div>Date</div>
          <div>Reason</div>
          <div>Created By</div>
          <div></div>
          <div style={{width: "2rem"}}></div>
          <div style={{width: "2rem"}}></div>
          <div style={{width: "1rem"}}></div>
        </div>
        {isLoading && <div className={style.no_data}>Loading...</div>}
        {isError && <div className={style.no_data}>Something went wrong</div>}
        {!isLoading && !isError ? (
          data?.getAllData?.length > 0 ? (
            data?.getAllData.map((item, key) => {
              return (
                <div className={style.td} key={key}>
                  <div>{item?.companyName}</div>
                  <div>
                    {moment(item?.blackLListedDate).format("DD MMM YY")}
                  </div>
                  <div title={item?.reason?.length > 16 && item?.reason}>
                    {item?.reason?.length > 16
                      ? item?.reason?.substr(0, 17) + "..."
                      : item?.reason}
                  </div>
                  <div title={item?.createdBy?.length > 16 && item?.createdBy}>
                    {item?.createdBy?.length > 16
                      ? item?.createdBy?.substr(0, 17) + "..."
                      : item?.createdBy}
                  </div>
                  <div></div>
                  <div style={{width: "2rem"}}>
                    {item?.companyLinkedInUrl ? (
                        <>
                          <a
                            href={item?.companyLinkedInUrl}
                            target="_blank"
                            title="Click Here"
                            rel="noopener noreferrer"
                          >
                            <FaLinkedin size={20} style={{ color: "blue", cursor: "pointer"}} />
                          </a>
                        </>
                      ) : (
                        item?.companyWebUrl && <>
                          <a
                            href={item?.companyWebUrl}
                            target="_blank"
                            title="Click Here"
                            rel="noopener noreferrer"
                          >
                            <FaBuilding size={20} style={{ color: "blue", cursor: "pointer"}} />
                          </a>
                        </>
                      )}
                  </div>
                  <div style={{width: "2rem"}}>
                    {(item?.companyWebUrl && item?.companyLinkedInUrl) ? (
                        <>
                          <a
                            href={item?.companyWebUrl}
                            target="_blank"
                            title="Click Here"
                            rel="noopener noreferrer"
                          >
                            <FaBuilding size={20} style={{ color: "blue", cursor: "pointer"}} />
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                  </div>
                  <div
                    style={{width: "1rem"}}
                    onClick={() => {
                      setViewAddScreen(true);
                      setFormData((prev) => ({
                        ...prev,
                        companyName: item?.companyName,
                        id: item?.id,
                        reason: item?.reason,
                        companyLinkedInUrl: item?.companyLinkedInUrl,
                        companyWebUrl: item?.companyWebUrl
                      }));
                    }}
                  >
                    {item?.createdById === employeeId && (
                      <MdModeEditOutline
                        style={{ color: "green", fontSize: "1.3rem" }}
                      />
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className={style.no_data}>No Data</div>
          )
        ) : null}
      </ATSContainer>
      <div className={style.pagination}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagesCount={data?.positionCount / 25}
          color="#65A15E"
          color2="white"
        />
      </div>
    </>
  );
};
export default BlackListedCompany;
