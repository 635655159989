import React, { useEffect, useState } from "react";
import BreadCrum from "../../../breadcrums/BreadCrum";
import ATSContainer from "../../../../atsDashboard/customTooltip/ATSContainer";
import style from "./ProjectLeadReport.module.scss";
import { FaLinkedin } from "react-icons/fa";
import classes from '../../ProjectLead/ProjectLead.module.css'
import {
  getPartnerData,
  getPartnerLeadReport,
} from "../../../../../Utils/axios/PortalManagement";
import { useQuery } from "react-query";
import Pagination from "../../../CommonComponent/pagination/pagination.js";
import { MdOutlineInsertComment } from "react-icons/md";
import CommentHistoryPartnerLead from "../../../CommonComponent/CommentNew/CommentHistory/CommentHistoryPartnerLead.js";
import { Modal } from "react-responsive-modal";

const EmpanelmentReport = ({
  prevData,
  setPrevData,
  setNextScreen,
  // currentPage,
  // setCurrentPage,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(null)
  const [leadId, setLeadId] = useState(null)
  const [candidateData, setCandidateData] = useState([])
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [myPositionId, setMyPositionId] = useState(null);
  const [myPartnerName, setMyPartnerName] = useState(null)
  const [filter, setFilter] = useState({
    reportType: 1,
  });

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


  const dataMap = [
    { key: 'review', partnersKey: 'reviewPartners' },
    { key: 'notRelevant', partnersKey: 'notRelevantPartners' },
    { key: 'mailShared', partnersKey: 'mailSharedPartners' },
    { key: 'notResponding', partnersKey: 'notRespondingPartners' },
    { key: 'meetingAligned', partnersKey: 'meetingAlignedPartners' },
    { key: 'agreementsDiscussion', partnersKey: 'agreementsDiscussionPartners' },
    { key: 'hold', partnersKey: 'holdPartners' },
    { key: 'agreementInProcess', partnersKey: 'agreementInProcessPartners' },
    { key: 'droppedHighRates', partnersKey: 'droppedHighRatesPartners' },
    { key: 'droppedNoBenchAvailable', partnersKey: 'droppedNoBenchAvailablePartners' },
    { key: 'droppedTermsConditionsInnovationM', partnersKey: 'droppedTermsConditionsInnovationMPartners' },
    { key: 'droppedTermsConditionsPartner', partnersKey: 'droppedTermsConditionsPartnerPartners' },
    { key: 'empanelled', partnersKey: 'empanelledPartners' },
    { key: 'total', partnersKey: 'totalPartners' },
  ];

  const getTableHeading = <>
    <th>Review</th>
    <th>Not <br /> Relevant</th>
    <th>Mail <br /> Shared </th>
    <th>Not <br /> Responding</th>
    <th>Meeting <br /> Aligned</th>
    <th>Agreements <br /> Discussion</th>
    <th>Hold</th>
    <th>Agreement in <br /> Process</th>
    <th>Dropped - High <br /> Rates</th>
    <th>Dropped - No <br /> Bench <br /> Available</th>
    <th>Dropped - Terms & <br /> Conditions <br /> (InnovationM)</th>
    <th>Dropped - Terms & <br /> Conditions <br /> (Partner)</th>
    <th>Empanelled</th>
    <th>Grand Total</th></>

  const {
    data: partnerData,
    isLoading,
    isError,
  } = useQuery(
    ["getPartnerLeadReport", filter],
    () => getPartnerLeadReport(filter?.reportType)
  );


  const { data: candidateData1 } = useQuery(
    ["ProjectLeadsData", { currentPage, leadId }],
    () => getPartnerData(10, currentPage, leadId),{
      enabled: leadId ? true : false
    }
  );

  useEffect(() => {
    if (candidateData1) {
      setCandidateData(candidateData1);
    }
  }, [candidateData1]);




  const getLastThreeWeeksData = (data) => {
    // Extract the weeks and sort them by end date
    const weeks = Object.keys(data).filter(key => key !== 'grandTotal').sort((a, b) => {
      const endDateA = new Date(a.split(' - ')[1]);
      const endDateB = new Date(b.split(' - ')[1]);
      return endDateB - endDateA;
    });
    
    // Return the data for the last four weeks
    return weeks.slice(0, 4).map(week => ({ week, ...data[week] }));
  };


  const WeekDataTable = ({ data }) => {
    return (
      <div className={style.tableContainer}>
        <table className={style.weekDataTable} style={{ overflowX: 'auto' }}>
          <thead>
            <tr>
              <th >Weeks</th>
              {getTableHeading}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td style={{ textDecoration: 'none' }}>{item.week}</td>
                {dataMap.map((entry, i) => (
                  <td
                    key={i}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleClick(item?.[entry.partnersKey], item?.[entry.key])}
                  >
                    {item?.[entry.key] || null}
                  </td>
                ))}
              </tr>
            ))}
            <tr style={{ fontWeight: '600' }}>
              <td style={{ textDecoration: 'none' }}>Grand Total</td>
              {dataMap.map((entry, i) => (
                <td
                  key={i}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleClick(partnerData?.grandTotal?.[entry.partnersKey], partnerData?.grandTotal?.[entry.key])}
                >
                  {partnerData?.grandTotal?.[entry.key] || null}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const MonthDataTable = ({ data }) => {
    return (
      <div className={style.tableContainer}>
        <table className={style.weekDataTable} style={{ overflowX: 'auto' }}>
          <thead>
            <tr>
              <th >Months</th>
              {getTableHeading}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td style={{ textDecoration: 'none' }}>{item.name}</td>
                {dataMap.map((entry, i) => (
                  <td
                    key={i}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleClick(item?.[entry.partnersKey], item?.[entry.key])}
                  >
                    {item?.[entry.key] || null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tr style={{ fontWeight: '600' }}>
            <td style={{ textDecoration: 'none' }}>Grand Total</td>
            {dataMap.map((entry, i) => (
              <td
                key={i}
                style={{ cursor: 'pointer' }}
                onClick={() => handleClick(partnerData?.grandTotal?.[entry.partnersKey], partnerData?.grandTotal?.[entry.key])}
              >
                {partnerData?.grandTotal?.[entry.key] || null}
              </td>
            ))}
          </tr>
        </table>
      </div>
    );
  };

  const getPreviousThreeMonths = () => {
    const months = [];
    const today = new Date();

    for (let i = 0; i < 3; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      months.push({ month: date.getMonth() + 1, name: date.toLocaleString('default', { month: 'long' }) });
    }

    return months;
  };

  const getLastThreeMonthsData = (data) => {
    const previousThreeMonths = getPreviousThreeMonths();
    return previousThreeMonths.map(({ month, name }) => ({
      name,
      review: data[month]?.review || 0,
      reviewPartners: data[month]?.reviewPartners || 0,
      notRelevantPartners: data[month]?.notRelevantPartners || 0,
      mailSharedPartners: data[month]?.mailSharedPartners || 0,
      notRespondingPartners: data[month]?.notRespondingPartners || 0,
      meetingAlignedPartners: data[month]?.meetingAlignedPartners || 0,
      agreementsDiscussionPartners: data[month]?.agreementsDiscussionPartners || 0,
      holdPartners: data[month]?.holdPartners || 0,
      agreementInProcessPartners: data[month]?.agreementInProcessPartners || 0,
      droppedHighRatesPartners: data[month]?.droppedHighRatesPartners || 0,
      droppedNoBenchAvailablePartners: data[month]?.droppedNoBenchAvailablePartners || 0,
      droppedTermsConditionsInnovationMPartners: data[month]?.droppedTermsConditionsInnovationMPartners || 0,
      droppedTermsConditionsPartnerPartners: data[month]?.droppedTermsConditionsPartnerPartners || 0,
      empanelledPartners: data[month]?.empanelledPartners || 0,
      totalPartners: data[month]?.totalPartners || 0,
      notRelevant: data[month]?.notRelevant || 0,
      mailShared: data[month]?.mailShared || 0,
      notResponding: data[month]?.notResponding || 0,
      meetingAligned: data[month]?.meetingAligned || 0,
      agreementsDiscussion: data[month]?.agreementsDiscussion || 0,
      hold: data[month]?.hold || 0,
      agreementInProcess: data[month]?.agreementInProcess || 0,
      droppedHighRates: data[month]?.droppedHighRates || 0,
      droppedNoBenchAvailable: data[month]?.droppedNoBenchAvailable || 0,
      droppedTermsConditionsInnovationM: data[month]?.droppedTermsConditionsInnovationM || 0,
      droppedTermsConditionsPartner: data[month]?.droppedTermsConditionsPartner || 0,
      empanelled: data[month]?.empanelled || 0,
      total: data[month]?.total || 0,
    }));
  };

  const getPreviousYears = (data) => {
    const currentYear = new Date().getFullYear();
    const previousYears = [currentYear, currentYear - 1]; // Previous two years

    return previousYears.map(year => ({
      year: year.toString(),
      review: data[year]?.review || 0,
      reviewPartners: data[year]?.reviewPartners || 0,
      notRelevantPartners: data[year]?.notRelevantPartners || 0,
      mailSharedPartners: data[year]?.mailSharedPartners || 0,
      notRespondingPartners: data[year]?.notRespondingPartners || 0,
      meetingAlignedPartners: data[year]?.meetingAlignedPartners || 0,
      agreementsDiscussionPartners: data[year]?.agreementsDiscussionPartners || 0,
      holdPartners: data[year]?.holdPartners || 0,
      agreementInProcessPartners: data[year]?.agreementInProcessPartners || 0,
      droppedHighRatesPartners: data[year]?.droppedHighRatesPartners || 0,
      droppedNoBenchAvailablePartners: data[year]?.droppedNoBenchAvailablePartners || 0,
      droppedTermsConditionsInnovationMPartners: data[year]?.droppedTermsConditionsInnovationMPartners || 0,
      droppedTermsConditionsPartnerPartners: data[year]?.droppedTermsConditionsPartnerPartners || 0,
      empanelledPartners: data[year]?.empanelledPartners || 0,
      totalPartners: data[year]?.totalPartners || 0,
      notRelevant: data[year]?.notRelevant || 0,
      mailShared: data[year]?.mailShared || 0,
      notResponding: data[year]?.notResponding || 0,
      meetingAligned: data[year]?.meetingAligned || 0,
      agreementsDiscussion: data[year]?.agreementsDiscussion || 0,
      hold: data[year]?.hold || 0,
      agreementInProcess: data[year]?.agreementInProcess || 0,
      droppedHighRates: data[year]?.droppedHighRates || 0,
      droppedNoBenchAvailable: data[year]?.droppedNoBenchAvailable || 0,
      droppedTermsConditionsInnovationM: data[year]?.droppedTermsConditionsInnovationM || 0,
      droppedTermsConditionsPartner: data[year]?.droppedTermsConditionsPartner || 0,
      empanelled: data[year]?.empanelled || 0,
      total: data[year]?.total || 0,
    }));
  };


  const lastThreeWeeksData = partnerData ? getLastThreeWeeksData(partnerData) : [];

  const lastThreeMonthsData = partnerData ? getLastThreeMonthsData(partnerData) : [];

  const lastTwoYearsData = partnerData ? getPreviousYears(partnerData) : [];



  const renderTableRows = () => {
    if (!partnerData) {
      return null;
    }

    return daysOfWeek.map(day => {
      const dayData = partnerData[day] || {};
      return (
        <tr key={day}>
          <td style={{ textDecoration: 'none' }}>{day}</td>
          {dataMap.map((item, index) => (
            <td
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => handleClick(dayData?.[item.partnersKey], dayData?.[item.key])}
            >
              {dayData?.[item.key] || null}
            </td>
          ))}
        </tr>
      );
    });
  };


  const renderYearTableRows = () => {
    if (!partnerData) {
      return null;
    }

    return lastTwoYearsData.map((data) => (
      <tr key={data?.year}>
        <td style={{ textDecoration: 'none' }}>{data?.year}</td>
        {dataMap.map((item, index) => (
          <td
            key={index}
            style={{ cursor: 'pointer' }}
            onClick={() => handleClick(data?.[item.partnersKey], data?.[item.key])}
          >
            {data?.[item.key] || null}
          </td>
        ))}
      </tr>
    ));
  };



  const handleLinkedin = (linkedInUrl) => {
    window.open(linkedInUrl, '_blank');
  }



  const handleColor = (status) => {
    switch (status) {
      case "Inactive":
        return { color: "red", fontWeight: "bold" };
      case "Active":
        return { color: "green", fontWeight: "bold" };
      default:
        return
    }
  };


  const handlePage = (item) => {
    setNextScreen("ViewPartner");
    setPrevData((prevData) => ({
      ...prevData,
      page: "positionALL",
      sidebar: "report",
      page1: "report",
      page2: "empanelmentReport",
      page3: "ViewPartner",
      page4: null,
      active: "ViewPartner",
      partnerId: item?.partnerId,
    }));
  }

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };


  const handleClick = (leads, totalCount) => {
    setLeadId(leads);
    setTotalCount(totalCount);
    setCurrentPage(1)
  }


  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      >
        <div className={style.topBar}>
          <div
            className={style.s1}
            style={{
              display: "flex",
              width: "31rem",
              justifyContent: "space-between",
            }}
          >
            <div className={style.radioitem}>
              <input
                type="radio"
                id={`Daywise`}
                name={`present`}
                value={1}
                checked={filter?.reportType === 1}
                onChange={() => {
                  setCurrentPage(1)
                  setTotalCount(0)
                  setCandidateData([]);
                  setLeadId(null);
                  setFilter((prev) => ({
                    ...prev,
                    reportType: 1,
                  }));
                }}
              />
              <label
                htmlFor={`Daywise`}
                style={{ fontSize: "1rem", color: "#000000" }}
              >
                Daywise
              </label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id={`Weekwise`}
                name={`present`}
                checked={filter?.reportType === 2}
                onChange={() => {

                  setCurrentPage(1)
                  setTotalCount(0)
                  setCandidateData([]);
                  setLeadId(null);
                  setFilter((prev) => ({
                    ...prev,
                    reportType: 2,
                  }));
                }}
              />
              <label
                htmlFor={`Weekwise`}
                style={{ fontSize: "1rem", color: "#000000" }}
              >
                Weekwise
              </label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id={`Monthwise`}
                name={`present`}
                checked={filter?.reportType === 3}
                onChange={() => {
                  setCurrentPage(1)
                  setTotalCount(0)
                  setCandidateData([]);
                  setLeadId(null);
                  setFilter((prev) => ({
                    ...prev,
                    reportType: 3,
                  }));
                }}
              />
              <label
                htmlFor={`Monthwise`}
                style={{ fontSize: "1rem", color: "#000000" }}
              >
                Monthwise
              </label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id={`Yearwise`}
                name={`present`}
                checked={filter?.reportType === 4}
                onChange={() => {
                  setCurrentPage(1)
                  setTotalCount(0)
                  setCandidateData([]);
                  setLeadId(null);
                  setFilter((prev) => ({
                    ...prev,
                    reportType: 4,
                  }));
                }}
              />
              <label
                htmlFor={`Yearwise`}
                style={{ fontSize: "1rem", color: "#000000" }}
              >
                Yearwise
              </label>
            </div>
          </div>
        </div>
      </BreadCrum>
      <div>
        {isLoading && <div className={style.no_data}>Loading...</div>}
        {isError && <div className={style.no_data}>Something went wrong</div>}

        {filter?.reportType === 1 && (
          <ATSContainer
            styles={{
              maxHeight: "32rem",
              overflowY: "auto",
            }}
          >
            <div className={style.tableContainer}>
              <table className={style.weekDataTable} style={{ overflowX: 'auto' }}>
                <thead>
                  <tr>
                    <th>Days</th>
                    {getTableHeading}
                  </tr>
                </thead>
                <tbody>
                  {renderTableRows()}
                  <tr style={{ fontWeight: '600' }}>
                    <td style={{ textDecoration: 'none' }}>Grand Total</td>
                    {dataMap.map((entry, i) => (
                      <td
                        key={i}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClick(partnerData?.grandTotal?.[entry.partnersKey], partnerData?.grandTotal?.[entry.key])}
                      >
                        {partnerData?.grandTotal?.[entry.key] || null}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </ATSContainer>
        )}
        {filter?.reportType === 2 && (
          <ATSContainer
            styles={{
              maxHeight: "32rem",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <WeekDataTable data={lastThreeWeeksData} />

          </ATSContainer>
        )}
        {filter?.reportType === 3 && (
          <ATSContainer
            styles={{
              maxHeight: "32rem",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <MonthDataTable data={lastThreeMonthsData} />
          </ATSContainer>
        )}
        {filter?.reportType === 4 && (
          <ATSContainer
            styles={{
              maxHeight: "32rem",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <div className={style.tableContainer}>
              <table className={style.weekDataTable} style={{ overflowX: 'auto' }}>
                <thead>
                  <tr>
                    <th>Years</th>
                    {getTableHeading}
                  </tr>
                </thead>
                <tbody>
                  {renderYearTableRows()}
                  <tr style={{ fontWeight: '600' }}>
                    <td style={{ textDecoration: 'none' }}>Grand Total</td>
                    {dataMap.map((entry, i) => (
                      <td
                        key={i}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClick(partnerData?.grandTotal?.[entry.partnersKey], partnerData?.grandTotal?.[entry.key])}
                      >
                        {partnerData?.grandTotal?.[entry.key] || null}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </ATSContainer>
        )}
        {candidateData?.length > 0 && (
          <ATSContainer
            styles={{
              maxHeight: "26rem",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <div className={style.table_th}>
              <div>Partner Name </div>
              <div>Company Size</div>
              <div>Source</div>
              <div>T.A.</div>
              <div>Location </div>
              <div></div>
            </div>
            {candidateData?.map((item, key) => {
              return (
                <div className={style.table_td} key={`table${key}`}>
                  <div
                  // style={{ color: "#C3B14B", textDecoration: "underline", cursor: "pointer" }}
                  // onClick={() => handlePage(item)}
                  >
                    {item?.partnerName}
                  </div>
                  <div>
                    <MdOutlineInsertComment
                      style={{
                        cursor: "pointer",
                        height: "13.5px",
                        marginLeft: "1px",
                        color: "black",
                      }}
                      onClick={() => {
                        setShowCommentModal(true);
                        setMyPositionId(item?.leadId);
                        setMyPartnerName(item?.partnerName)
                      }}
                      title={" Add Comment For Partner"}
                    />
                  </div>
                  <div>{item?.companySize}</div>
                  <div>{item?.source}</div>
                  <div>{item?.ta}</div>
                  <div>{item?.location}</div>
                  <div><FaLinkedin onClick={() => handleLinkedin(item?.linkedInUrl)} style={{ color: 'blue', cursor: 'pointer', fontSize: '18px' }} /></div>
                </div>
              );
            })}

            <Modal
              open={showCommentModal}
              onClose={() => {
                setShowCommentModal(false);
              }}
              showCloseIcon={true}
              center
            >
              <div className={classes.modal_container_watchlist2}>
                <div className={classes.watchlist_inner_container}>
                  <CommentHistoryPartnerLead
                    setShowCommentModal={setShowCommentModal}
                    partnerId={myPositionId}
                    partnerName={myPartnerName}
                  />
                </div>
              </div>
            </Modal>
          </ATSContainer>
          
        )}
      </div>
      <div className={style.pagination}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagesCount={totalCount / 10}
          color="#C3B14B"
          color2="white"
        />
      </div>
    </>
  );
};

export default EmpanelmentReport;
