import axios from "axios";
import cookie from "react-cookies";
const apiKey = process.env.REACT_APP_EmployeeInduction_URL;
const apiKey1 = process.env.REACT_APP_UMS_APP_URL;
let token = cookie.load("token");
axios.defaults.headers.common["Authorization"] = token;
axios.defaults.headers.post["Content-Type"] = "application/json";
export const EmployeeInduction = async (id) => {
  const response = await axios.get(
    `${apiKey}/getEmployeeInductionById?empId=${id}`
  );
  return response.data.responseData;
};
export const getEmployeeInduction = async (id) => {
  const response = await axios.get(`${apiKey}/getEmployeeInduction/${id}`);
  return response.data.responseData;
};

export const getEmployeeListDownload = async () => {
  const response = await axios.get(`${apiKey}/getEmployeeListDownload`);
  return response.data.responseData;
};

export const getStatus = async (data) => {
  const response = await axios.get(`${apiKey}/getStatus`, data);
  return response.data.responseData;
};
export const getFeedback = async (data) => {
  const response = await axios.get(`${apiKey}/getProgress`, data);
  return response.data.responseData;
};
export const UpdateInduction = async (data) => {
  const response = await axios.post(`${apiKey}/updateInduction`, data);
  return response.data.responseData;
};
export const CreateInduction = async (data) => {
  const response = await axios.post(`${apiKey}/createinduction`, data);
  return response.data.responseData;
};
export const CreateActivity = async (data) => {
  const response = await axios.post(`${apiKey}/updateactivity`, data);
  return response.data.responseData;
};
export const CreateJoiningStatus = async (data) => {
  const response = await axios.post(`${apiKey}/updatejoiningkit`, data);
  return response.data.responseData;
};

export const employeeList = async (
  limit,
  page,
  empIdFilter,
  inductionStatusFilter,
  searchData
) => {
  let apiUrl = `${apiKey}/getListOfEmployee?limit=${limit}`;

  if (searchData) {
    apiUrl += `&name=${searchData}`;
  }
  if (empIdFilter) {
    apiUrl += `&department=${empIdFilter}`;
  }
  if (inductionStatusFilter) {
    apiUrl += `&overallStatus=${inductionStatusFilter}`;
  }

  apiUrl += `&page=${page}`;

  const response = await axios.get(apiUrl);
  return response.data.responseData;
};

export const employeeListCount = async (

) => {
  let apiUrl = `${apiKey}/getInProgressEmployeeCount`;
  const response = await axios.get(apiUrl);
  return response.data.responseData;
};

export const filterEmp = async () => {
  const response = await axios.get(`${apiKey}/getemployeename`);
  return response.data.responseData;
};

export const department = async () => {
  const response = await axios.get(`${apiKey1}/getAllFunctionalArea`);
  return response.data.responseData;
};

export const getActivity = async (checklistId, empId) => {
  try {
    const response = await axios.get(
      `${apiKey}/activitydetails?checklistId=${checklistId}&empId=${empId}`
    );
    return response.data.responseData;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      return []; // Return an empty array in case of 400 or 500 error
    }
    throw error; // Re-throw the error for other error scenarios
  }
};

export const getJoiningKit = async (checklistId, empId) => {
  try {
    const response = await axios.get(
      `${apiKey}/joiningdetails?checklistId=${checklistId}&empId=${empId}`
    );
    return response.data.responseData;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      return []; // Return an empty array in case of 400 or 500 error
    }
    throw error; // Re-throw the error for other error scenarios
  }
};

export const getCheckList = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_EmployeeInduction_URL + "/checklist"
    );
    const { responseData } = response.data;
    return responseData;
  } catch (error) { }
};
export const InductionNotes = async (id) => {
  const response = await axios.get(
    `${apiKey}/getEmployeeNotes?employeeId=${id}&limit=10&page=0`
  );
  return response.data.responseData;
};
export const addNote = async (filter) => {
  const response = await axios.post(`${apiKey}/addNote`, filter);
  return response.data.responseData;
};
export const deleteNote = async ({ noteId, noteByEmpId }) => {
  const response = await axios.delete(`${apiKey}/deleteNote?noteByEmpId=${noteByEmpId}&noteId=${noteId}`);
  return response.data.responseData;
};
export const uploadFile = async (data) => {
  let formData = new FormData();
  formData.append("file", data);
  const response = await axios.post(`${apiKey}/hr-induction-upload-pdf`, formData,
    {
      responseType: "arraybuffer",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        Authorization: `${cookie.load("token")}`,
      },
    });
  return response.config.url;
};
export const deleteFile = async () => {
  const response = await axios.delete(
    `${apiKey}/hr-induction-delete-pdf`
  );
  return response.data.responseData;
};