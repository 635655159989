export const customSearchACP = {
  control: () => ({
      borderRadius: "9px",
      border: "1px solid rgba(0, 0, 0, 0.192)",
      height: '40px', 
      width: '220px',
      display: 'flex', // Align items vertically
      alignItems: 'center', 
    }),
    placeholder: () => ({
      
      marginTop: "-30px",
      color: "hsl(0, 0%, 5%)",
    }),
 

    //  option color on hover green
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#562679",
        color:"white"
      },
      backgroundColor: state.isSelected ? "#855ea1" : "white",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        display: 'none',
      }),
    // dropdown width
    menu: (provided) => ({
      ...provided,
      width: "92%",
      marginLeft: "0.6rem",
    })
};