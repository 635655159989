import moment from "moment/moment";

export const ExperienceDuration = (expDurationYear, expDurationMonth) => {
  let year1 = Math.floor(expDurationYear);
  let month = expDurationMonth;
  let year = year1 > 0 ? year1 + " Y  " : "";
  month =
    year1 > 0 && month > 0
      ? ` - ${month}  M `
      : month > 0
      ? `${month}  M `
      : "";
  return `   ${year}  ${month}`;
};

export const handleExperiece = (val) => {
  if (val) {
    let year = 0;
    let month = 0;
    if (~~(val / 12)) {
      year = `${~~(val / 12)} Years`;
    } else {
      year = "0 Year";
    }
    if (~~(val % 12)) {
      month = `${~~(val % 12)} Months`;
    } else {
      month = "0 Months";
    }
    return `${year} ${month}`;
  }
  return;
};
export const ExitDicisionPlanDate = (decision) => {
  if (decision) {
    return moment(decision).format("DD MMM YY");
  } else return;
};
export const ExitPlanDate = (value) => {
  if (value) {
    return moment(value).format("DD MMM YY");
  } else {
    return;
  }
};
export const BondDate = (status, value) => {
  if (status && value) {
    return `${moment(value).format("DD MMM YY")} `;
  } else {
    return;
  }
};
export const ReginationData = (status, value) => {
  if (status && value) {
    return `${moment(value).format("DD MMM YY")} `;
  } else {
    return;
  }
};
export const ExitDate = (status, value) => {
  if (status && value) {
    return `${moment(value).format("DD MMM YY")} `;
  } else {
    return;
  }
};
export const RetainDate = (status, value) => {
  if (status && value) {
    return `${moment(value).format("DD MMM YY")} `;
  } else {
    return;
  }
};
