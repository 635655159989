import React from "react";
import style from "./ViewQuestionnaireByPosition.module.css"
import { ViewQuestionnaireByPositionsCom } from "./component/ViewQuestionnaireByPositionCom";
import QuestionnaireSidebar from "../sidebar/SideBar";
export const ViewQuestionnaireByPosition =()=>{
    return(<>
       <div className={style.mainSection}>
         <QuestionnaireSidebar />
         <ViewQuestionnaireByPositionsCom />
       </div>
     </>)
 }


