import React, { useEffect, useState } from "react";
import Header from "../../../GenericComponent/header/CommonHeader";

import SideBar from "../sidebar/SideBar";
import style from "./ClientContractListAll.module.scss";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getAllClientContractList,
  getApplicableContractStatusList,
  getEmployeeList,
} from "../../../Utils/axios/clientContractApi";

import { path } from "../../../routes/PathNames";
import { checkIfEmpty, clientContractCode, renderTitle, renderValue } from "../../../Utils/constants";
import Pagination from "../../ClientQuestionnaire/CommonComponent/Pagination";
import CommonDropdown from "../../../GenericComponent/ui/CommonDropdown";
import useValidationAccess from "../../custumHooks/useValidationAccess";

function ClientContractListAll() {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { checkValidateAccess } = useValidationAccess();

  const [filter, setFilter] = useState(
    location?.state?.ccListAllFilter
      ? location?.state?.ccListAllFilter
      : {
          page: 1,
          salesRepresentativeEmpId: "",
          statusId: "",
        }
  );
  const { data: getAllClientContract } = useQuery(
    ["getAllClientContractById", filter],
    () =>
      getAllClientContractList(
        filter?.page,
        filter?.salesRepresentativeEmpId,
        filter?.statusId,
        params.id
      ),
    {}
  );

  useEffect(() => {
    setFilter({ ...filter, page: 1 });
  }, [filter?.salesRepresentativeEmpId, filter?.statusId, params.id]);

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setFilter((prev) => ({ ...prev, page: filter.page + 1 }));
    } else if (status === "prev") {
      setFilter((prev) => ({ ...prev, page: filter.page - 1 }));
    }
  };

  const { data: getApplicableContractStatusOptions } = useQuery(
    ["getApplicableContractStatusList"],
    () => getApplicableContractStatusList()
  );

  const handleClickClientContracts = () => {
    location?.state?.page1 === "Client Contracts"
      ? history.push({
          pathname: path.cc_contract_ims.replace(
            ":name",
            "client-contracts"
          ),
          state: {
            ...location.state,
            ccListAllFilter: filter,
          },
        })
      : history.push({
          pathname: path.cc_list.replace(
            ":name",
            "expiring-contracts"
          ),
          state: {
            ...location.state,
            ccListAllFilter: filter,
          },
        });
  }

  const handleClickClientContracts2 = (item) => {
    history.push({
      pathname:
        location?.state?.page1 === "Client Contracts"
          ? path.cc_viewById
              .replace(":id", item?.id)
              .replace(":name", "client-contracts")
          : path.cc_viewById
              .replace(":id", item?.id)
              .replace(":name", "expiring-contracts"),
      state: {
        ...location?.state,
        page2: `${getAllClientContract?.clientDataResponse?.[0]?.clientName} Contracts`,
        clientId: params?.id,
        ccListAllFilter: filter,
      },
    });
  }

  const handleClickClientContracts3 = () => {
    history.push({
      pathname:
        location?.state?.page1 === "Client Contracts"
          ? path.cc_viewById
              .replace(
                ":id",
                location?.state?.selectedClient?.id
              )
              .replace(":name", "client-contracts")
          : path.cc_viewById
              .replace(
                ":id",
                location?.state?.selectedClient?.id
              )
              .replace(":name", "expiring-contracts"),
      state: {
        ...location?.state,
        page2: `${getAllClientContract?.clientDataResponse?.[0]?.clientName} Contracts`,
        clientId: params?.id,
        ccListAllFilter: filter,
      },
    });
  }

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <div style={{ width: "20vw" }}>
          <SideBar />
        </div>
        <div style={{ width: "80vw", padding: "1rem" }}>
          <div className={style.titleBtn}>
            <div className={style.titleBtnLeft}>
              <p className={style.title}>
                <span
                  style={{ color: "#562679", cursor: "pointer" }}
                  onClick={() => handleClickClientContracts()}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleClickClientContracts();
                    }
                  }}
                  tabIndex={0}
                >
                  {location?.state?.page1} {" > "}
                </span>
                {getAllClientContract?.clientDataResponse?.[0]?.clientName}{" "}
                Contracts
              </p>
              {location.pathname.includes("/client-contract") && (
                <button>{getAllClientContract?.totalDataCount}</button>
              )}
            </div>

            <div className={style.titleBtnRight}>
              {/* filter 1 */}
              {location.pathname.includes("/client-contract") && (
                <div className={style.dropdownContainer12}>
                  <CommonDropdown
                    color="#562679"
                    value={checkIfEmpty(filter?.statusId, {
                      id: filter?.statusId,
                      label: filter?.statusName,
                    })}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        statusId: e.id,
                        statusName: e.label,
                      });
                    }}
                    options={[
                      getApplicableContractStatusOptions?.[0],
                      getApplicableContractStatusOptions?.[1],
                      getApplicableContractStatusOptions?.[2],
                      getApplicableContractStatusOptions?.[3],
                    ]}
                    placeholder="Status"
                    customInputOnClick={() =>
                      setFilter({
                        ...filter,
                        statusId: null,
                        statusName: null,
                      })
                    }
                  />
                </div>
              )}
              {checkValidateAccess(clientContractCode.CreateContract) && (
                <button
                  onClick={() => {
                    history.push({
                      pathname:
                        location?.state?.page1 === "Client Contracts"
                          ? path.cc_create.replace(":name", "client-contracts")
                          : path.cc_create.replace(
                              ":name",
                              "expiring-contracts"
                            ),
                      state: {
                        ...location.state,
                        page2: `${getAllClientContract?.clientDataResponse?.[0]?.clientName} Contracts`,
                        ccListAllFilter: filter,
                        selectedClient: false,
                        clientId: false,
                      },
                    });
                  }}
                  title="Create Contract"
                >
                  +
                </button>
              )}
            </div>
          </div>
          <div className={style.table}>
            <div className={style.th}>
              <p> Start Date</p>
              <p> End Date</p>
              <p>Executed By</p>
              <p>Contract Type</p>
             
              <p> Reminder Date</p>
              <p>Contract Status</p>

            </div>
            <div>
              {!location?.state?.selectedClient ? (
                getAllClientContract?.clientDataResponse?.length > 0 ? (
                  getAllClientContract.clientDataResponse?.map(
                    (item, index) => {
                      return (
                        <div
                          className={style.td}
                          key={item?.id}
                          style={{ position: "relative" }}
                        >
                          <p
                            className={style.link}
                            onClick={() => handleClickClientContracts2(item)}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleClickClientContracts2(item);
                              }
                            }}
                            tabIndex={0}
                          >
                            {item?.startDate
                              ? moment(item?.startDate).format("DD MMM YY")
                              : "-"}
                          </p>
                          <p>
                          {item?.ongoing?"Ongoing":
                            checkIfEmpty(
                              item?.endDate,
                              moment(item?.endDate).format("DD MMM YY")
                            )}
                          </p>
                          <p title={renderTitle(item?.executedBy, 20)}>
                            {renderValue(item?.executedBy, 20)}
                          </p>
                          <p title={renderTitle(item?.contractType, 20)}>
                            {renderValue(item?.contractType, 20)}
                          </p>
                          {/* <p >{item?.ongoing ? "Yes" : "No"}</p> */}

                          <p>
                            {checkIfEmpty(
                              item?.reminderDate,
                              moment(item?.reminderDate).format("DD MMM YY")
                            )}
                          </p>
                      
                          <p style={{paddingLeft:"10px"}}>{item?.clientContractStatus}</p>

                        </div>
                      );
                    }
                  )
                ) : (
                  <p className={style.no_data}>No Data Found</p>
                )
              ) : (
                <div
                  className={style.td}
                  key={location?.state?.selectedClient?.id}
                  style={{ position: "relative" }}
                >
                  <p
                    className={style.link}
                    onClick={() => handleClickClientContracts3()}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleClickClientContracts3();
                      }
                    }}
                    tabIndex={0}
                  >
                    {checkIfEmpty(
                      location?.state?.selectedClient?.startDate,
                      moment(location?.state?.selectedClient?.startDate).format(
                        "DD MMM YY"
                      )
                    )}
                  </p>
                  <p>
                    {checkIfEmpty(
                      location?.state?.selectedClient?.endDate,
                      moment(location?.state?.selectedClient?.endDate).format(
                        "DD MMM YY"
                      )
                    )}
                  </p>
                  <p
                    title={renderTitle(
                      location?.state?.selectedClient?.executedBy,
                      20
                    )}
                  >
                    {renderValue(
                      location?.state?.selectedClient?.executedBy,
                      20
                    )}
                  </p>
                  <p
                    title={renderTitle(
                      location?.state?.selectedClient?.contractType,
                      20
                    )}
                  >
                    {renderValue(
                      location?.state?.selectedClient?.contractType,
                      20
                    )}
                  </p>
                  

                  <p>
                    {checkIfEmpty(
                      location?.state?.selectedClient?.reminderDate,
                      moment(
                        location?.state?.selectedClient?.reminderDate
                      ).format("DD MMM YY")
                    )}
                  </p>

                  
                  <p >{location?.state?.selectedClient?.clientContractStatus}</p>
                </div>
              )}
            </div>
          </div>
          <div className={style.pagination}>
            <Pagination
              onPageChange={handlePageChange}
              currentPage={filter?.page}
              pagesCount={getAllClientContract?.totalDataCount / 10}
              color="#855ea1"
              color2="white"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientContractListAll;
