import React from "react";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import style from "./Header.module.css";
import InnovationMLogo from "../../../../assets/Grievance portal/image-innovationm-logo@2x.png";

const Header = () => {
  let history = useHistory();
  const profile = cookie.load("profile");
  return (
    <div className={style.header}>
      <img
        alt="DashBoardImage"
        src={InnovationMLogo}
        className={style.dashboardImage}
        onClick={() => history.push("/dashboard")} 
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            history.push("/dashboard");
          }
        }}
        tabIndex={0}
      />
      <div className={style.profileSection}>
        <p className={style.username}>Hi, {profile?.name}</p>

        <img
          className={style.profileImage}
          alt={profile?.givenName}
          src={profile?.imageUrl}
        />
      </div>
    </div>
  );
};

export default Header;
