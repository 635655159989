
import style from "../CustomElementStyle.module.css";
const CustomTextArea = ({ label,value,onChange, styles,mandate}) => {
  return (
    <div>
      <p className={style.label}>{label} {mandate &&<span style={{color:"red"}}>*</span>} </p>
      <textarea value={value} onChange={onChange} className={style.textField} style ={styles} />
    </div>
  );
};

export default CustomTextArea;
