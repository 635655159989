import React, { useEffect, useState } from "react";
import SideBar from "../../sidebar/SideBar";
import style from "./report.module.css";
import Select from "react-select";
import { IoSearchOutline } from "react-icons/io5";
import { findCountByGender, getAttritionReport, getReportNew, getSalaryReport } from "../../../../Utils/axios/umsApi";
import { useQuery } from "react-query";
import { customSearch } from "../../../atsDashboard/utils/style";
import { getPrviousYear } from "../../../atsDashboard/utils/validation";
import male from "../../../../assets/male.png";
import female from "../../../../assets/female.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const Report = () => {
  const [year, setYear] = useState([]);
  const currentYear = new Date().getFullYear();
  const [filter, setFilter] = useState({
    label: currentYear,
    value: currentYear,
  });
  const { data, isLoading, isError } = useQuery(["getReportNew", filter],
    () => getReportNew(filter.value)
  );
  const { data: countData, isLoading: countLoading, isError: countIsError } = useQuery(["findCountByGender", filter],
    () => findCountByGender(filter.value)
  );
  const { data: attritionData, isLoading: isAttritionLoading, isError: isAttritionError } = useQuery(["getAttritionReport", filter],
    () => getAttritionReport(filter.value)
  );
  const { data: salaryData, isLoading: isSalaryLoading, isError: isSalaryError } = useQuery(["getSalaryReport"],
    () => getSalaryReport()
  );
  useEffect(() => {
    setYear(getPrviousYear()?.map((item) => ({ label: item, value: item })));
  }, [data, filter])

  function getMonthName(monthNumber) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[monthNumber - 1]; // Adjust month number to zero-based index
  }
  function addCommas(number) {
    if (number === null) return null;
    var x;
    x = number.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  }
  return (
    <>
      {/*<Header />*/}
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.report}>
            <span>Report</span>
            <div className={style.s2}>
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Select Year"
                components={{
                  DropdownIndicator: () =>
                    <IoSearchOutline />
                  ,
                  IndicatorSeparator: () => null,
                }}
                value={
                  filter?.value
                    ? year?.find((e) => e.value === filter?.value)
                    : null
                }
                onChange={(e) => {
                  setFilter({ value: e.value, label: e.value });
                }}
                options={year}
              />
            </div>
          </div>
          <div className={style.UsersList}>
            <div
              style={{
                display: "flex",
              }}
            >
              <div className={style.td}>
                <p
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#EAF7FF",
                  }}
                  className={style.countdata1}
                >
                  Date
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#FFFFEA",
                  }}
                  className={style.countdata2}
                >
                  Total
                  <br />
                  Count
                </p>
                <div
                  className={style.delevery}
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#FFEAEA",
                  }}
                >
                  {data
                    ?.slice(0, 1)[0]
                    ?.employeeType?.map((e) => {
                      return (
                        <p
                          className={style.countdata}
                          style={{
                            fontWeight: "700",
                            backgroundColor: "#FFEAEA",
                          }}
                        >
                          {e?.name}
                        </p>
                      );
                    })}
                </div>
                <div
                  className={style.delevery}
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#EAFFEE",
                  }}
                >
                  {data
                    ?.slice(0, 1)[0]
                    ?.functionalArea?.map((e) => {
                      return (
                        <p
                          style={{
                            fontWeight: "700",
                            backgroundColor: "#EAFFEE",
                          }}
                          className={style.countdata}
                        >
                          {e?.name}
                        </p>
                      );
                    })}
                </div>
                <div
                  className={style.delevery}
                  style={{
                    fontWeight: "700",
                    backgroundColor: "rgb(181 211 236)",
                  }}
                >
                  {data
                    ?.slice(0, 1)[0]
                    ?.technology?.map((e) => {
                      return (
                        <p
                          className={style.countdata}
                          style={{
                            fontWeight: "700",
                            backgroundColor: "rgb(181 211 236)",
                          }}
                        >
                          {e?.name}
                        </p>
                      );
                    })}
                </div>
              </div>
            </div>
            {/* Table Data */}
            <div>
              {isLoading && <div className={style.nodata}>Loading...</div>}
              {isError && (
                <div className={style.nodata}>Something went wrong...</div>
              )}
              {!isLoading && !isError && (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {data?.length > 0 ? (
                    data?.map((e) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <div className={style.td}>
                            <p className={style.countdata1}>{e?.date}</p>
                            <p className={style.countdata2}>{e?.totalCount}</p>
                            <div className={style.delevery}>
                              {e?.employeeType?.map((item) => {
                                return (
                                  <p className={style.countdata}>
                                    {item?.count}
                                  </p>
                                );
                              })}
                            </div>
                            <div className={style.delevery}>
                              {e?.functionalArea?.map((item) => {
                                return (
                                  <p className={style.countdata}>
                                    {item?.count}
                                  </p>
                                );
                              })}
                            </div>
                            <div className={style.delevery}>
                              {e?.technology?.map((item) => {
                                return (
                                  <p className={style.countdata}>
                                    {item?.count}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={style.nodata}>No Data</div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* table */}
          <div className={style.main_heading_container}>
            <span className={style.main_heading}>Attrition Report</span>
          </div>
          <TableContainer component={Paper} className={style.table_container}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={style.tableHead}>
                <TableRow style={{ height: '40px' }}>
                  <TableCell className={style.table_heading} align="left">S. No.</TableCell>
                  <TableCell className={style.table_heading} align="center">Month</TableCell>
                  <TableCell className={style.table_heading} align="center">Year</TableCell>
                  <TableCell className={style.table_heading} align="center">Opening Balance < br /> (Onroll + Contract)</TableCell>
                  <TableCell className={style.table_heading} align="center">Employees < br /> Joined</TableCell>
                  <TableCell className={style.table_heading} align="center">Employees < br /> Left</TableCell>
                  <TableCell className={style.table_heading} align="center">Closing Balance</TableCell>
                  <TableCell className={style.table_heading} align="center">Average No of < br />employees for <br />the month</TableCell>
                  <TableCell className={style.table_heading} align="center">Attrition % < br /> (Monthly)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={style.tableBody}>
                {isAttritionLoading ?
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" colSpan={9} align='center'>
                      Loading...
                    </TableCell>
                  </TableRow> :
                  attritionData?.length == 0 ?
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" colSpan={9} align='center'>
                        No Data
                      </TableCell>
                    </TableRow> :
                    attritionData?.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" component="th" scope="row">{index + 1}</TableCell>
                        <TableCell align="center" component="th" scope="row">{getMonthName(row.month)}</TableCell>
                        <TableCell align="center" component="th" scope="row">{row.year}</TableCell>
                        <TableCell align="center" component="th" scope="row">{row.openingBalance}</TableCell>
                        <TableCell align="center" component="th" scope="row">{row.employeesJoined}</TableCell>
                        <TableCell align="center" component="th" scope="row">{row.employeesLeft}</TableCell>
                        <TableCell align="center" component="th" scope="row">{row.closingBalance}</TableCell>
                        <TableCell align="center" component="th" scope="row">{row.avgNoOfEmployees}</TableCell>
                        <TableCell align="center" component="th" scope="row" style={row.attritionRate.toFixed(2) >= 11 ? { color: 'red' } : {}}>
                          {row.attritionRate.toFixed(2)}%
                        </TableCell>
                      </TableRow>
                    ))
                }
              </TableBody>
            </Table>
          </TableContainer>


          <div className={style.dpSalaryWithCounts}>
            <div className={style.dpSalaryDiv}>
              <div className={style.main_heading_container2}>
                <span className={style.main_heading}>Department Wise Cost</span>
              </div>
              <TableContainer component={Paper} className={`${style.table_container2} ${style.table_scroll}`}>
                <Table aria-label="simple table">
                  <TableHead className={`${style.tableHead} ${style.fixedHeader} ${style.tableHeadDPsalary}`}>
                    <TableRow style={{ height: '40px' }}>
                      {/* <TableCell className={style.table_heading} align="left">S. No.</TableCell> */}
                      <TableCell className={style.table_heading} align="left">Department</TableCell>
                      <TableCell className={style.table_heading} align="center">Costing</TableCell>
                      <TableCell className={style.table_heading} align="center">Average Costing</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={style.tableBody}>
                    {isAttritionLoading ?
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" colSpan={9} align='center'>
                          Loading...
                        </TableCell>
                      </TableRow> :
                      salaryData?.length == 0 ?
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row" colSpan={9} align='center'>
                            No Data
                          </TableCell>
                        </TableRow> :
                        salaryData?.map((salary, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            {/* <TableCell align="left" component="td" scope="row">{index + 1}</TableCell> */}
                            <TableCell align="left" component="td" scope="row"> <div className={style.functionalAreaC}>
                              {salary.functionalArea}
                              {salary.employeeCount ?
                                <div
                                  className={style.makeCircle}
                                >{salary.employeeCount}</div> : null}
                            </div>
                            </TableCell>
                            <TableCell align="center" component="td" scope="row">{addCommas(salary.totalSalary)}</TableCell>
                            <TableCell align="center" component="td" scope="row">{addCommas(salary.averageSalaryOfEmpByFunctionalArea?.toFixed(0) || null)}</TableCell>
                          </TableRow>
                        ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className={style.genderContainer}>
              <div className={style.main_heading_container2}>
                <span className={style.main_heading}>Gender Count</span>
              </div>
              <div className={style.genderCount}>
                <div className={style.innerContainer}>
                  {countData?.map((item) => (
                    <div className={style.genderCount_box}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-example">{item?.label} : {item?.count}</Tooltip>}
                      >
                        <div>
                          <img src={item.label === "Male" ? male : female} alt={`${item?.label} icon`} />
                        </div>
                      </OverlayTrigger>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Report;
