import React, { useEffect, useState } from "react";
import Style from "./RecruiterDashBoard.module.css";
import Select from "react-select";
import { customSearchForATS } from "./style";
import { employeeId } from "../../../../Utils/constants";
import { getAllRecruiterDasboardCount } from "../../../../Utils/axios/api";
import { useQuery } from "react-query";
import { getLowerEmployee } from "../../../../Utils/axios/api";
import { isError } from "lodash";
import { AiOutlineClose } from "react-icons/ai";
import searchIcon from "../../../../assets/Icon-search.png";
const RecruiterDashBoard = ({
  setPrevData,
  prevData,
  setNextScreen,
}) => {
  const[recruiterList,setRecruiterList] = useState([]);
  const[recruiterId,setRecruiterId] = useState(0);
  const[selectedOption,setSelectedOption]=useState(null);
  
  
  // Count
  const {
    data:count,
    isLoading:countIsLoading,
    isError:countIsError,
    error,
    refetch: refetchCount,
  } = useQuery(["get-all-recruiter-dasboard-count"], () =>
    getAllRecruiterDasboardCount(employeeId,recruiterId),
    {
      onError: (err) => {
        console.error('Error fetching data:', err);
        // return <div className={Style.error}>Something Went Wrong</div>
      },
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );

  useEffect(() => {

    refetchCount();
  }, [recruiterId]);


  // Allocations
  const { data: Employee,isLoading:EmployeeIsLoading} = useQuery(
    ["getLowerEmployee", ],
    () => employeeId && getLowerEmployee(employeeId),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setRecruiterList(
      Employee?.map((emp) => {
        return { value: emp.id, label: emp.name };
      })
    );
  }, [Employee]);

  const handleChange =(selected) => {

    let rId=Number(selected.value)
    setRecruiterId(rId);
    setSelectedOption(selected)
  };






  return (
    <div className={Style.projectresource_mainsection}>
      <div className={Style.upper}>
      <div
        style={{
          marginLeft: "25px",
          marginTop: "0px",
          padding: "0px 0px",
          fontWeight: "500",
        }}
      >
        Recruiter Dashboard
      </div>
      <div className={Style.s1}>
            {recruiterId===0 ? (
              <span className={Style.cp_serchIcon}>
                <img src={searchIcon} alt="searchIcon" />
              </span>
            ) : (
              <span
                className={Style.cp_serchIcon}
                onClick={() => {
                  setRecruiterId(0)
                  setSelectedOption(null)
                }}
              >
                <AiOutlineClose />
              </span>
            )}
              <Select
        styles={customSearchForATS}
        options={recruiterList}
        onChange={handleChange}
        value={selectedOption}
        placeholder="Select Recruiter"
        />
          </div>

      </div>
      {/* {isError && <div className={Style.error}>Something Went Wrong {error.message}</div>} */}
      {!countIsLoading &&
      <div className={Style.imgSection}>
      <div
        className={Style.imgcontentSection}
        onClick={()=>{
          setNextScreen("CurrentAllocation")
          setPrevData({
            ...prevData,
            sidebar: "CurrentAllocation",
            page: "CurrentAllocation",
            page1: "CurrentAllocation",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "CurrentAllocation",
            name: "",
            positionId: null,
          });
        }}
      >

        <div className={Style.circle}>
        <h1 className={Style.count}>{count?.activePositionAllocationCount}</h1>
        </div>
        <span>Active Allocations</span>
      </div>
      <div
        className={Style.imgcontentSection}
        onClick={() => {
          setNextScreen("myInterviewList");
          setPrevData((prevData) => ({
            ...prevData,
            page: "positionALL",
            sidebar: "myInterviewList",
            page1: "myInterviewList",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "myInterviewList",
            name: "",
            positionId: null,
          }));
        }}
      >
        {/* <div className={Style.imgdiv}>
          <img src="" alt="this is img section" />
        </div> */}
        {/* <h1>{count.totalClient}</h1> */}
        <div className={Style.circle}>
        <h1 className={Style.count}>{count?.interviewScheduledCount}</h1>
        </div>
        <span>Interviews Scheduled</span>
      </div>
      <div
        className={Style.imgcontentSection}
        onClick={() => {
          setNextScreen("myCandidateFinalStages");
          // setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "myCandidateFinalStages",
            page: "myCandidateFinalStages",
            page1: "myCandidateFinalStages",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "myCandidateFinalStages",
            name: "",
            positionId: null,
          });
        }}
      >
        {/* <div className={Style.imgdiv}>
          <img src="" alt="this is img section" />
        </div> */}
        {/* <h1>{count.totalClient}</h1> */}
        <div className={Style.circle}>
        <h1 className={Style.count}>{count?.finalStageCount}</h1>
        </div>
        <span>Final Stage</span>
      </div>
      <div
        className={Style.imgcontentSection}
        onClick={() => {
          setNextScreen("myTasks");
          setPrevData((prevData) => ({
            ...prevData,
            page: "positionALL",
            sidebar: "myTasks",
            page1: "myTasks",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "myTasks",
            name: "",
            positionId: null,
          }));
        }}
      >
        {/* <div className={Style.imgdiv}>
          <img src="" alt="this is img section" />
        </div> */}
        {/* <h1>{count.totalClient}</h1> */}
        <div className={Style.circle}>
        <h1 className={Style.count}>{count?.joinedCount}</h1>
        </div>
        <span>Joined</span>
      </div>
    </div>}
    </div>
  );
};

export default RecruiterDashBoard;
