import React, { useState } from "react";
import Moment from "moment";
import { Modal } from "react-responsive-modal";
import style from "./OffshoreReport.module.css";
import { useHistory } from "react-router-dom";
import BIcon from "./../../../assets/BIcon.svg";
import moment from "moment";


const OffshorReportTable = (props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const GoToDetails = () => {
    history.push({
      pathname: "/PartialPoolDescription",
      state: {
        id: props.val,
        currentPage: props?.currentPage,
        allData: props.allData,
        applied: props.applied,
        filterName: props.filterName,
      },
    });
  };

  return (
    <>
      <tr className={style.tr1}>
        {/* <td title={props.val?.name} onClick={GoToDetails} className={style.link}> */}
        <td title={props.val?.empName}>
          {props.val?.empName > 16 ? `${props.val?.empName.substring(0, 16)}...` : props.val?.empName}
        </td>
        <td>{props?.val?.positionName}</td>
        <td>{props?.val?.totalExperienceYear} Y - {props?.val?.totalExperienceMonth} M</td>
        <td>{props?.val?.expectedDoj && moment(props?.val?.expectedDoj).format("DD MMM YY")}</td>
        <td>{props?.val?.daysToJoin}</td>
      </tr>
    </>
  );
};

export default OffshorReportTable;
