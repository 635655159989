import style from "./elementCommon.module.scss";
const InputBox =({value,onChange,placeholder,min,max,type,styles})=>{
  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      ev.preventDefault();
    });
  };


    return (
        <input
          type={type}
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          placeholder={placeholder}
          className={style.inputBox}
          style={styles}
          onClick={() => type === "number" && handleInputClick()}
        ></input>
      );
}

export default InputBox;