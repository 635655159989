import React, { useState } from "react";
import EmployDetail from "../../EmployeeDetails_apprHistory/EmployDetails/EmployDetail";
import AppraisalHistoryCom from "../../EmployeeDetails_apprHistory/AppraisalHistory/AppraisalHistoryCom";
import AppraisalSystemSidebar from "../../Sidebar/AppraisalSystemSidebar";
import style from "./ShowEmployeeDetails2.module.css";
import { NavLink } from "react-router-dom";
import CurrentDetailsComponent from "../../EmployeeDetails_apprHistory/CurrentProposedHR/CurrentDetailsComponent"; 
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getEmpAppraisalHistory, getEmployeeDetailById } from "../../../../../Utils/axios/AppraisalApi";
import { useQuery } from "react-query";
const ShowEmployeeDetails2 = () => {

  const [showAppraisalHistory, setShowAppraisalHistory] = useState(false);
  const { empId} = useParams();

  // getEmployeeDetailsById
  const { data:employeeDetails, isLoading:employeeDetailsLoading} = useQuery(["getEmployeeDetailById"], () =>
  getEmployeeDetailById(null,empId)
  );
  let employeeDetailsObj ;
  let currentDetailsObj;
  if(!employeeDetailsLoading){ 
    employeeDetailsObj ={
    "employeeCode": employeeDetails.employeeCode,
    "empId": employeeDetails.id,
        "name": employeeDetails.name,
        "dateOfJoining": employeeDetails.dateOfJoining,
        "totalExperience": employeeDetails.totalExperience,
        "bond": employeeDetails.bond,
        "bondEndDate": employeeDetails.bondEndDate,
        "technology": employeeDetails.technology,
        "department":employeeDetails.department,
        "location": employeeDetails.location,
        "clientName": employeeDetails.clientName,
        "projectDetailObj": employeeDetails.projectDetailObj,
        "performanceDetailsUrl": employeeDetails.performanceDetailsUrl,
        "rating": employeeDetails.rating,
        "ratingOutOf": employeeDetails.ratingOutOf,
        "lastAppraisalCycle":employeeDetails.lastAppraisalCycle,
        "lastIncrementPercentage":employeeDetails.lastIncrementPercenatage,
        "lastIncrementAmount":employeeDetails.lastIncrementAmount,
        "lastReward":employeeDetails.lastReward,
        "lastPRMonth": employeeDetails.lastPRMonth,
        "lastPromotion":employeeDetails.lastPromotion,
        "lastRewardCycle":employeeDetails.lastRewardCycle,
        "lastPromotionCycle":employeeDetails.lastPromotionCycle,
        "empType":employeeDetails.empType,
        "totalExpWithIM":employeeDetails.totalExpWithIM,
        "designation": employeeDetails.designation,
        "reportingManager":employeeDetails.reportingManager,
        "lastApplicableFrom": employeeDetails.lastApplicableFrom


  }
  currentDetailsObj ={
    "incentives": employeeDetails.incentives,
    "joiningBonus": employeeDetails.joiningBonus,
    "variableBonus":employeeDetails.variableBonus,
    "sa1":employeeDetails.sa1,
    "sa2":employeeDetails.sa2,
    "others":employeeDetails.others,
    "salaryNote":employeeDetails.salaryNote,
    "retentionBonus": employeeDetails.retentionBonus,
    "currentMonthlyCoreCTC": employeeDetails.currentMonthlyCoreCTC,
    "currentMonthlyFullCTC": employeeDetails.currentMonthlyFullCTC,
    "currentYearlyCoreCTC": employeeDetails.currentYearlyCoreCTC,
    "currentYearlyFullCTC": employeeDetails.currentYearlyFullCTC,
  }
}

  // getEmployeeAppraisalHistory
  const { data:empAppraisalHistory} = useQuery(["getEmpAppraisalHistory"], () =>
  getEmpAppraisalHistory(empId)
  );
  return (
    <div className={style.mainSection}>
      <AppraisalSystemSidebar />
  { !employeeDetailsLoading &&   <div className={style.container}>
        <div className={style.button}>
          <p className={style.pp}>
            <NavLink to="/ASEmployeeList">
              <span  className={style.breadscrum}>EmployeeList </span>
            </NavLink>
            <span> &gt; Employee Detail </span>
          </p>
          <button className={style.history}
            onClick={() => setShowAppraisalHistory(!showAppraisalHistory)}
          >
            {showAppraisalHistory?"X":"H"}
          </button>
        </div>
        <EmployDetail employeeDetails={employeeDetailsObj}  />
        <CurrentDetailsComponent currentDetails={currentDetailsObj} />
        {showAppraisalHistory && <AppraisalHistoryCom empAppraisalHistory={empAppraisalHistory} />}
      </div> }
    </div>
  );
};

export default ShowEmployeeDetails2;
