import React from "react";
import { EditQuestionnaireCom } from "./component/EditQuestionnaireCom";
import style from "./EditQuestionnaire.module.css"
import QuestionnaireSidebar from "../sidebar/SideBar";
export const QuestionEdit =()=>{
    return(<>
       <div className={style.mainSection}>
         <QuestionnaireSidebar />
         <EditQuestionnaireCom />
       </div>
     </>)
 }
