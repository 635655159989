import React, { lazy, Suspense } from "react";
import MainLoader from "../Loader/Loader";

export const lazyLoad = (ComponentImport) => {
  const LazyComponent = lazy(ComponentImport);

  return (props) => (
    <Suspense fallback={<MainLoader />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
