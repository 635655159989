import axios from "axios";
import constants, { employeeId } from "../../../Utils/constants";
const url = constants.apiBaseUrl2;
const urlEmployees = constants.apiBaseUrl;

export const getSpaceDetailsById = async ({assignedBy, assignedTo, limit, page, priority, sort, sortBy, spaceElementId, spaceId, status, title})=>{
    let res = await axios.get(url+`/getSpaceDetailsById?assignedBy=${assignedBy}&assignedTo=${assignedTo}&empId=${employeeId}&limit=${limit}&page=${page}&priority=${priority}&sort=${sort}&sortBy=${sortBy}&spaceElementId=${spaceElementId}&spaceId=${spaceId}&status=${status}&title=${title}`);
    return res?.data?.responseData;
}
export const getActiveMembersBySpace = async (spaceId)=>{
    let res = await axios.get(url+`/getActiveMembersBySpace?spaceId=${spaceId}`);
    return res?.data?.responseData;
}
export const getActiveEmployeesNotInSpace = async (spaceId)=>{
    let res = await axios.get(url+`/getActiveEmployeesNotInSpace?spaceId=${spaceId}`);
    return res?.data?.responseData;
}
export const getAllSpaceElements = async ()=>{
    let res = await axios.get(url+`/getAllSpaceElements`);
    return res?.data?.responseData;
}
export const addSpace = async ({spaceName, spaceId})=>{
    let data = {empId: employeeId, name: spaceName, spaceId: spaceId};
    let res = await axios.post(url+`/addSpace`, data);
    return res?.data;
}
export const addMembersToSpace = async (data)=>{
    let res = await axios.post(url+`/addMembersToSpace`, data);
    return res?.data;
}
export const updateMemberStatusToSpace = async ({empId, groupRoleId, spaceId})=>{
    let res = await axios.put(url+`/updateSpaceMemberStatus?memberId=${empId}&empId=${employeeId}&spaceId=${spaceId}`);
    return res?.data;
}
export const addSpaceElementToSpace = async (data)=>{
    let res = await axios.post(url + "/addSpaceElementToSpace", data);
    return res?.data?.responseData;
}
export const closeSpace = async (spaceId)=>{
    let res = await axios.put(url + `/closeSpace?empId=${employeeId}&spaceId=${spaceId}`);
    return res?.data?.responseData;
}
export const assignedTo = async (active, spaceId)=>{
    let res = await axios.get(url + `/assignedToByGroupId?active=${active}&groupId=${spaceId}`);
    return res?.data?.responseData;
}
export const assignedBy = async (active, spaceId)=>{
    let res = await axios.get(url + `/assignedByByGroupId?active=${active}&groupId=${spaceId}`);
    return res?.data?.responseData;
}
export const getTaskDetailsByTaskId = async (taskId)=>{
    let res = await axios.get(url + `/getTaskDetailsByTaskId?empId=${employeeId}&taskId=${taskId}`);
    return res?.data?.responseData;
}
export const getEmployeeList = async ()=>{
    let res = await axios.get(urlEmployees + "/getEmployeeList");
    return res?.data?.responseData?.employeeList;
}