export function addCommas(number) {
  if (number === null || number === undefined) return null;

  // Convert number to string
  let numStr = number.toString();

  // Return the original string if it's length is 3 or less
  if (numStr.length <= 3) return numStr;

  // Separate the last three characters (hundreds and below)
  let lastThree = numStr.slice(-3);
  let otherNumbers = numStr.slice(0, numStr.length - 3);

  // Insert commas every two characters from the right in the remaining string
  let result = [];
  while (otherNumbers.length > 0) {
    let chunk = otherNumbers.slice(-2);
    result.unshift(chunk);
    otherNumbers = otherNumbers.slice(0, -2);
  }

  // Join all parts with commas and add the last three digits
  return result.join(",") + "," + lastThree;
}

export function addCommasWithDecimal(number, single) {
  if (number === null || number === undefined) return null;

  // Convert number to string
  let numStr = number.toString();
  //remove decimals for absolute values
  numStr =
    numStr.split(".")[1] === "00" || numStr.split(".")[1] === "0"
      ? numStr.split(".")[0]
      : numStr;
  //calculate split value
  const splitVal = numStr.split(".")[1] > "00" ? (single === true ? 5 : 6) : 3;
  // Return the original string if it's length is 3/6 or less
  if (numStr.length <= splitVal) {
    return numStr;
  }

  // Separate the last six characters (hundreds and below)
  let lastDigits = numStr.slice(-splitVal);
  let otherNumbers = numStr.slice(0, numStr.length - splitVal);

  let negative = false;

  // Insert commas every two characters from the right in the remaining string
  let result = [];
  while (otherNumbers.length > 0) {
    let chunk = otherNumbers.slice(-2);
    if (chunk !== "-") {
      result.unshift(chunk);
    } else {
      negative = true;
    }
    otherNumbers = otherNumbers.slice(0, -2);
  }

  const joinResult = (result) => {
    return result?.length ? result.join(",") + "," + lastDigits : lastDigits;
  };

  // Join all parts with commas and add the last three digits
  return negative ? "-" + joinResult(result) : joinResult(result);
}

export function addCommasPCM(number, single) {
  if (number === null || number === undefined) return null;

  let negative = false;

  // Convert number to string
  let numStr = number.toString();

  // Return the original string if it's length is 3 or less
  if (numStr.length <= 3) {
    return numStr;
  } else if (numStr.length === 4 && numStr[0] === "-") {
    return numStr;
  }

  // Separate the last three characters (hundreds and below)
  let lastThree = numStr.slice(-3);
  let otherNumbers = numStr.slice(0, numStr.length - 3);

  // Insert commas every two characters from the right in the remaining string
  let result = [];
  while (otherNumbers.length > 0) {
    let chunk = otherNumbers.slice(-2);
    if (chunk !== "-") {
      result.unshift(chunk);
    } else {
      negative = true;
    }
    otherNumbers = otherNumbers.slice(0, -2);
  }

  // Join all parts with commas and add the last three digits
  return negative
    ? "-" + result.join(",") + "," + lastThree
    : result.join(",") + "," + lastThree;
}
