import React from "react";
import { DoneIssueComp } from "./DoneIssueComp/DoneIssueComp";
import style from "./CreateIsseuSuccessfull.module.css"
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
export const CreateIsseuSuccessfull =()=>{
    return(<>
        <Header />
       <div className={style.mainSection}>
         <SideBar />
         <DoneIssueComp />
       </div>
     </>)
 }
