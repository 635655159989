import React, { useState } from "react";
import Moment from "moment";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import style from "./PoolResources.module.css";
import Eicon from "./../../../../assets/EIcon.png";
import BIcon from "./../../../../assets/BIcon.svg";
import RIcon from "./../../../../assets/EIcon.png";
import { withStyles } from "@material-ui/core/styles";
import { FaPlusCircle } from "react-icons/fa";
import { CiCircleRemove } from "react-icons/ci";
import { ConfirmRMSPopup } from "../../CommonComponent/confimationDialogBox";
import { MdEdit } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import ViewNoteRMS from "../../CommonComponent/viewNoteRMS";
import AddNoteRMS from "../../CommonComponent/addNoteRMS";
import axios from "axios";

const TableRow = (props) => {
  const [show, setShow] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [viewNote, setViewNote] = useState(false);
  const [viewAddNote, setViewAddNote] = useState(false);
  const [id, setId] = useState(null);
  const history = useHistory();
  const BlackTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "black",
      color: "#fff",
      fontSize: "1rem",
      whiteSpace: "pre-line",
      padding: "0.5rem",
    },
  }))(Tooltip);
  const GoToDetails = () => {
    history.push({
      pathname: "/PoolResourcesDescription",
      state: {
        id: props.val,
        currentPage: props?.currentPage,
        allData: props.allData,
        applied: props.applied,
        filterName: props.filterName,
      },
    });
  };
  const cancelFunction = () => {
    setModalOpen(false);
  };
  const confirmFunction = () => {
    props?.addToPoolResourceMutate.mutate({
      empId: props?.val.empId,
      status: !props?.val.isRemovedFromPool,
    });
    setModalOpen(false);
  };

  const resourceUrl = process.env.REACT_APP_RESOURCEMASTER_APP_URL;

  const handleViewNote = async () => {
    const res = await axios.get(
      `${resourceUrl}/getAllPoolResourceComments?empId=${props?.val.empId}&isPoolNotes=true&limit=10&page=1`
    );
    if (res?.data?.responseData) {
      props?.setNote(res.data.responseData?.getAllData[0]?.comment);
    }
    setViewNote(true);
  };

  const handleAddNote = async () => {
    if (props?.val.existPoolNotes) {
      const res = await axios.get(
        `${resourceUrl}/getAllPoolResourceComments?empId=${props?.val.empId}&isPoolNotes=true&limit=10&page=1`
      );
      if (res?.data?.responseData) {
        props?.setNote(res.data.responseData?.getAllData[0]?.comment);
        setId(res.data.responseData?.getAllData[0]?.poolCommentId);
      }
    } else {
      props?.setNote("");
      setId(null);
    }
    setViewAddNote(true);
  };

  return (
    <>
      <tr
        style={{
          borderBottom: "2px solid #F0F0F0",
          textAlign: "start",
          fontSize: "13px",
          position: "relative",
        }}
      >
        <ConfirmRMSPopup
          text={"Are you sure you want to remove, from pool resources?"}
          name={props.val.empName}
          cancelFunction={cancelFunction}
          confirmFunction={confirmFunction}
          modalIsOpen={isModalOpen}
          setModalIsOpen={setModalOpen}
        />
        <ViewNoteRMS
          viewNote={viewNote}
          setViewNote={setViewNote}
          empId={props?.val.empId}
          existPoolNotes={props?.val.existPoolNotes}
          note={props?.note}
        />
        <AddNoteRMS
          id={id}
          note={props?.note}
          setNote={props?.setNote}
          refetchPoolResources={() => props?.refetchPoolResources()}
          empId={props?.val.empId}
          viewAddNote={viewAddNote}
          setViewAddNote={setViewAddNote}
          existPoolNotes={props?.val.existPoolNotes}
        />

        <td
          style={{
            minWidth: "4.25rem",
            padding: "10px",
            position: "sticky",
            left: "0",
            zIndex: "1",
            backgroundColor: "#FFF",
          }}
        >
          {props.empCode}
        </td>
        <td
          style={{
            padding: "10px",
            cursor: "pointer",
            textTransform: "capitalize",
            fontWeight: "bolder",
            position: "sticky",
            left: "68px",
            zIndex: "1",
            backgroundColor: "#FFF",
          }}
        >
          {props.val?.partialPool && (
            <span
              style={{
                display: "flex",
                height: "20px",
                width: "20px",
                color: "rgb(170, 167, 204)",
                border: "2px solid rgb(170, 167, 204)",
                borderRadius: "50%",
                padding: "4px",
                marginLeft: "5px",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "10px",
              }}
            >
              PP
            </span>
          )}
          {!props.val?.isActive ? (
            <span
              style={{
                display: "flex",
                height: "20px",
                width: "20px",
                color: "rgb(168, 50, 50)",
                border: "2px solid rgb(168, 50, 50)",
                borderRadius: "50%",
                padding: "4px",
                marginLeft: "5px",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "10px",
              }}
            >
              E
            </span>
          ) : props.val?.isBlocked ? (
            <span
              style={{
                display: "flex",
                height: "20px",
                width: "20px",
                color: "rgb(252, 187, 8)",
                border: "2px solid rgb(252, 187, 8)",
                borderRadius: "50%",
                padding: "4px",
                marginLeft: "5px",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "10px",
              }}
            >
              B
            </span>
          ) : (
            <td
              style={{
                position: "sticky",
                left: "68px",
                zIndex: "1",
                backgroundColor: "#FFF",
              }}
            >
              <div style={{ width: "40px" }}></div>
            </td>
          )}
        </td>
        {/* <td
          style={{
            padding: "10px",
            cursor: "pointer",
            textTransform: "capitalize",
            fontWeight: "bolder",
            position: "sticky",
            left: "68px",
            zIndex: "1",
            backgroundColor: "#FFF",
          }}
        >
          {props.val?.partialPool ? (
            <span
              style={{
                display: "flex",
                height: "30px",
                width: "30px",
                color: "rgb(170, 167, 204)",
                border: "2px solid rgb(170, 167, 204)",
                borderRadius: "50%",
                padding: "4px",
                marginLeft: "5px",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              PP
            </span>
          ) : (
            <td
              style={{
                position: "sticky",
                left: "68px",
                zIndex: "1",
                backgroundColor: "#FFF",
              }}
            >
              <div style={{ width: "40px" }}></div>
            </td>
          )}
        </td> */}
        <td
          onClick={GoToDetails}
          style={{
            padding: "10px",
            cursor: "pointer",
            textTransform: "capitalize",
            fontWeight: "bolder",
            position: "sticky",
            left: "128px",
            zIndex: "1",
            backgroundColor: "#FFF",
          }}
        >
          {props.empName}{" "}
          {/* {props.val?.isBlocked && (
            <span
              style={{
                color: "rgb(252, 187, 8)",
                border: "2px solid rgb(252, 187, 8)",
                borderRadius: "999px",
                padding: "2px 8px",
                marginLeft: "5px",
              }}
            >
              B
            </span>
          )} */}
        </td>
        <td style={{ paddingLeft: "10px" }}>
          {" "}
          {~~(props.experience / 12)} Y - {props.experience % 12} M
        </td>
        <td style={{ paddingLeft: "10px" }}>{props?.experienceRange}</td>
        <td style={{ paddingLeft: "10px" }}>{props.salaryRange}</td>
        <td style={{ paddingLeft: "10px" }}>{props.skillName}</td>
        {props?.poolStartdate ? (
          <td style={{ paddingLeft: "10px" }}>
            {Moment(props?.poolStartdate).format("DD MMM YY")}
          </td>
        ) : (
          <td></td>
        )}
        <td style={{ paddingLeft: "10px" }}> {props.val.poolAge}</td>

        <td style={{ paddingLeft: "10px" }}>
          {props?.val.availabilityDate &&
            Moment(props?.val.availabilityDate).format("DD MMM YY")}
        </td>

        <td style={{ paddingLeft: "10px" }}> {props.benchAge}</td>
        {props?.val?.currentCommentDate ? (
          <td style={{ paddingLeft: "10px" }}>
            {Moment(props?.val?.currentCommentDate).format("DD MMM YY")}
          </td>
        ) : (
          <td></td>
        )}
        <td style={{ padding: "10px" }}>
          {props?.val?.projectName &&
            props?.val?.projectName.map((val, index) => (
              <React.Fragment key={index}>
                {index > 0 && <>,</>}
                <BlackTooltip title={val}>
                  <span style={{ fontWeight: "400", fontSize: "13px" }}>
                    {val?.length > 20 ? val.substring(0, 20) + "..." : val}
                  </span>
                </BlackTooltip>
              </React.Fragment>
            ))}
        </td>

        <td style={{ padding: "10px" }}>{props?.val?.partnerName}</td>
        <td style={{ padding: "10px" }}>{props?.val?.currentLocation}</td>

        <td style={{ textAlign: "center" }}>
          {!props?.val.isRemovedFromPool ? (
            <BlackTooltip title={"Remove From Pool"}>
              <p
                onClick={() => {
                  setModalOpen(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setModalOpen(true);
                  }
                }}
                tabIndex={0}
              >
                <CiCircleRemove size={18} style={{ cursor: "pointer" }} />
              </p>
            </BlackTooltip>
          ) : (
            <BlackTooltip title={"Add To Pool"}>
              <p
                onClick={() => {
                  props?.addToPoolResourceMutate.mutate({
                    empId: props?.val.empId,
                    status: !props?.val.isRemovedFromPool,
                  });
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    props?.addToPoolResourceMutate.mutate({
                      empId: props?.val.empId,
                      status: !props?.val.isRemovedFromPool,
                    });
                  }
                }}
                tabIndex={0}
              >
                <FaPlusCircle size={18} style={{ cursor: "pointer" }} />
              </p>
            </BlackTooltip>
          )}
        </td>
        <td style={{ textAlign: "center" }}>
          {" "}
          {props.val?.empSource === "Fresher" ? (
            <span
              style={{
                display: "flex",
                height: "20px",
                width: "20px",
                color: "rgb(255 64 64)",
                border: "2px solid rgb(255 64 64)",
                borderRadius: "50%",
                padding: "4px",
                marginLeft: "5px",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "10px",
              }}
            >
              B
            </span>
          ) : (
            <td
              style={{
                position: "sticky",
                left: "68px",
                zIndex: "1",
                backgroundColor: "#FFF",
              }}
            >
              <div style={{ width: "40px" }}></div>
            </td>
          )}
        </td>
        <td style={{ padding: "5px" }}>
          {props?.val.existPoolNotes && (
            <BlackTooltip title={"View Note"}>
              <p style={{ cursor: "pointer" }} onClick={handleViewNote}>
                <CgNotes
                  style={{ color: "#FCBB08", width: "18px", height: "18px" }}
                />
              </p>
            </BlackTooltip>
          )}
        </td>
        <td style={{ padding: "5px" }}>
          <BlackTooltip
            title={props?.val.existPoolNotes ? "Edit Note" : "Add Note"}
          >
            <p style={{ cursor: "pointer" }} onClick={handleAddNote}>
              <MdEdit
                style={{ color: "#FCBB08", width: "18px", height: "18px" }}
              />
            </p>
          </BlackTooltip>
        </td>
      </tr>
      {/* <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "700",
          }}
        >
          Project/Client Name
        </p>
        <div className={style.modal_container}>
          <p>
            {props?.val?.projectName &&
              props.val.projectName.map((val, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <br />}
                  {val}
                </React.Fragment>
              ))}
          </p>

          <div className={style.M_button}>
            <div className={style.button}></div>
          </div>
        </div>
      </Modal> */}
    </>
  );
};
export default TableRow;
