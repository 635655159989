import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../Specialization/Specialization.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import OptionOrdering from "../OptionModal/OptionOrdering";
import { useQuery } from "react-query";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton1";
import {
  getSkills,
  getViewPartner,
  specializationByID,
} from "../../../../Utils/axios/PortalManagement";
import moment from "moment";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";

const Specialization = ({ setNextScreen, prevData, setPrevData }) => {
  const [formData, setFormData] = useState("");
  const [SpecialDetail, setSpecialDetail] = useState(false);

  const { data: getCoreSkillData } = useQuery(["getCoreSkill"], getSkills);
  const getCoreSkillOption = getCoreSkillData?.map((i) => ({
    value: i.skillId,
    label: i.coreSkillName,
  }));
  const HandleSpecial = () => {
    setSpecialDetail(true);
  };
  console.log(prevData)

  const { data: PartnerDataById } = useQuery(
    ["getViewPartner"],
    () => getViewPartner(prevData?.partnerId),
    {}
  );

  const {
    data: specializationData,
    isLoading,
    isError,
    refetch: refetchViewPartner,
  } = useQuery(
    ["specializationByID"],
    () => specializationByID(prevData?.partnerId),
    {}
  );

  useEffect(() => {
    if (specializationData) {
      const mappedData = specializationData?.specializationList?.map((i) => ({
        value: i.specializationId,
        label: i.specialization,
      }));
      setFormData({ mandatorySkill_id: mappedData });
    }
  }, [specializationData]);

  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginLeft: "25px" }}>Specialization</h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col>
              <h6 className={classes.primaryContact}>Partner Details</h6>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Partner Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.partnerName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>GSTIN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.gstin}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Location</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.city}</p>
            </Col>
          </Row>
        </InvoiceContainer>

        {prevData?.specializationExistId === 0 && !SpecialDetail ? (
          <Row>
            <Col md={6}>
              <div className={classes.SkillStatus}>
                <OptionOrdering
                  getMandatorySkillOption={getCoreSkillOption}
                  formData={formData}
                  setFormData={setFormData}
                  coreSkillId={formData?.coreSkill_Id}
                  HandleSpecial={HandleSpecial}
                  prevDataId={prevData?.partnerId}
                  refetchViewPartner={refetchViewPartner}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <InvoiceContainer>
              <Row>
                <Col>
                  <h6 className={classes.primaryContact}>
                    Specialization Details
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <p className={classes.label}>Specialization</p>
                </Col>
                <Col md={3}>
                  <p className={classes.label}>Date</p>
                </Col>
                {isLoading && <p className={classes.no_data}>Loading .....</p>}
                {!isLoading && !isError ? (
                  specializationData?.specializationList?.length > 0 ? (
                    typeof specializationData?.specializationList !==
                    "string" &&
                    specializationData?.specializationList?.map(
                      (item, index) => {
                        return (
                          <div className={classes.td} key={item?.positionId}>
                            <Container>
                              <Row>
                                <Col md={3}>
                                  <p className={classes.tableContent}>
                                    {item?.specialization}
                                  </p>
                                </Col>
                                <Col md={3}>
                                  <p className={classes.tableContent}>
                                    {item?.date
                                      ? moment(item?.date).format("DD MMM YY")
                                      : null}
                                  </p>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <p className={classes.no_data}>No Data Found</p>
                  )
                ) : null}
              </Row>
            </InvoiceContainer>
            <div className={classes.lastBtn_item}>
              <InvoiceButton
                onClick={() => {
                  prevData?.specializationExistId === 1
                    ? setSpecialDetail(true)
                    : setSpecialDetail(false);
                }}
              >
                Change Skill
              </InvoiceButton>
              <InvoiceButton
                onClick={() => {
                  setNextScreen("PartnerList");
                  setPrevData((prevData) => ({
                    ...prevData,
                    page2: null,
                    page3: null,
                    page4: null,
                    active: "PartnerList",
                  }));
                }}
              >
                Done
              </InvoiceButton>
            </div>
          </>
        )}

        {prevData?.specializationExistId === 1 && SpecialDetail ? (
          <Row>
            <Col md={6}>
              <div className={classes.SkillStatus}>
                <OptionOrdering
                  getMandatorySkillOption={getCoreSkillOption}
                  formData={formData}
                  setFormData={setFormData}
                  coreSkillId={formData?.coreSkill_Id}
                  HandleSpecial={HandleSpecial}
                  prevDataId={prevData?.partnerId}
                  refetchViewPartner={refetchViewPartner}
                  setSpecialDetail={setSpecialDetail}
                />
              </div>
            </Col>
          </Row>
        ) : null}
      </Container>
    </>
  );
};
export default Specialization;
