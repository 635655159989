import React from "react";
import style from "./totalCount.module.css";
const TotalCount = ({ totalCount, m }) => {
  return (
    <div className={style.totalCount} style={{margin: m ? m : "0 0 0 1rem" }}>
      <span className={style.count}>{totalCount ? totalCount : 0}</span>
    </div>
  );
};

export default TotalCount;
