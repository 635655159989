import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import style from "./ETSNavigation.module.css";
import ProgressBar from "../progressbar/ProgressBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery } from "react-query";
import { getEmpRoleByEmpId } from "../../../Utils/axios/api";
// import Expense from "../pageLayout/Expense/Expense";
// import CreateExp from "../pageLayout/Expense/createExpense/CreateExpense";
// import EditExpense from "../pageLayout/Expense/editExpense/EditExpence";
// import ViewExpense from "../pageLayout/Expense/viewExpense/ViewExpence";
// import InvoiceList from "../pageLayout/Invoice/InvoiceList";
// import CreateInvoice from "../pageLayout/Invoice/createInvoice/CreateInvoice";
// import ViewInvoice from "../pageLayout/Invoice/viewInvoice/ViewInvoice";
// import EditInvoice from "../pageLayout/Invoice/editInvoice/EditInvoice";
import EmpTimeSheet from "../pageLayout/empTimeSheet/EmpTimeSheet";
import { employeeId } from "../../../Utils/constants";
export default function ETSNavigation() {
  // usequery getEmpRoleByEmpId
  const { data, isLoading, isError, error } = useQuery(
    ["getEmpRole"],
    () => getEmpRoleByEmpId(employeeId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [nextScreen, setNextScreen] = useState("expense");
  const [currentPage, setCurrentPage] = useState(1);
  const [roles, setRoles] = useState([]);
  const [prevData, setPrevData] = useState({
    page: "expense",
    sidebar: "expense",
    page1: "expense",
    active: "expense",
  });

  // useEffect(() => {
  //   setPrevData({
  //     page: roles == 4 ? "CurrentAllocation" : "positionList",
  //     sidebar: roles == 4 ? "CurrentAllocation" : "positionList",
  //     page1: roles == 4 ? "CurrentAllocation" : "positionList",
  //     active: roles == 4 ? "CurrentAllocation" : "positionList",
  //   });
  //   setNextScreen(roles == 4 ? "CurrentAllocation" : "positionList");
  // }, [roles]);

  // useEffect(() => {
  //   if (data) {
  //     setRoles(data);
  //   }
  // }, [data]);

  const sidebarHandler = () => {
    switch (nextScreen) {
      case "empTimeSheet":
        return (
          <EmpTimeSheet
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      // case "createExpence":
      //   return (
      //     <CreateExp
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );
      // case "editExpense":
      //   return (
      //     <EditExpense
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );
      // case "viewExpense":
      //   return (
      //     <ViewExpense
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );

      // case "invoiceList":
      //   return (
      //     <InvoiceList
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );
      // case "createInvoice":
      //   return (
      //     <CreateInvoice
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );
      // case "viewInvoice":
      //   return (
      //     <ViewInvoice
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );
      // case "editInvoice":
      //   return (
      //     <EditInvoice
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );

      default:
    }
  };

  const setCurrScreen = (val) => {
    setNextScreen(val);
  };

  return (
    <div className={style.ATSNav}>
      <Header />
      <div className={style.mainSection}>
        <div style={{ width: "20%" }}>
          <SideBar
            sidebarHandler={setCurrScreen}
            nextScreen={nextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            roles={roles}
          />
        </div>
        <div className={style.ATScontainer}>
          <ProgressBar prevData={prevData} />
          {sidebarHandler()}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
