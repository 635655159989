import React from "react";
import { Link } from "react-router-dom";
import style from "./Table.module.css";

const TableRow = (props) => {
  return (
    <tr
      style={{
        borderBottom: "2px solid #F0F0F0",
        textAlign: "start",
        fontSize: "11.5px",
      }}
    >
      <Link
        to={`/clientdescription?id=${props.val.clientid}`}
        className={style.backColor}
      >
        <td
          style={{
            padding: "10px",
            textTransform: "capitalize",
            fontWeight: "bolder",
            width: "35%",
            textDecorationLine: "underline",
          }}
        >
          {props.val.name}
        </td>
      </Link>
      <td style={{ width: "40%",padding:"10px" }}>
        <p className={style.domain}>{props.val.domain}</p>
      </td>

      <td
        style={{
          width: "15%",
          padding:"10px",
          color: props.val.status === "Inactive" ? "red" : "black",
        }}
      >
        {props.val.status}
      </td>
    </tr>
  );
};

export default TableRow;
