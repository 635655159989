import { useQuery } from "react-query"
import BreadCrum from "../../breadcrums/BreadCrum"
import ATSContainer from "../../customTooltip/ATSContainer"
import style from "./myinterview.module.scss"
import { getInterviewerInterviews } from "../../../../Utils/axios/api"
import { employeeId } from "../../../../Utils/constants"
import { useState } from "react"
// import Pagination from "../../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination"
import ATSCard from "../../customTooltip/ATSCard"
import CustomTooltip from "../../customTooltip/CustomTooltip"
import moment from "moment"
import CheckBoxCustom from "../../commonComponent/ElementCommon/CheckBoxCustom"
import TotalCount from "../../TotalCount/TotalCount"

const MyInterviews=({prevData,setPrevData,setNextScreen})=>{
    const [currentPage,setCurrentPage]=useState(1)
    const [all,setAll]=useState(false)
const {data,isLoading,isError}= useQuery(["getInterviewerInterviews",{all,currentPage}],()=>getInterviewerInterviews(all,employeeId,10,currentPage))
   
const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const currentDateActive=(date)=>{
    const today=moment(new Date()).format("DD MMM YY");
    const interviewDate=moment(date).format("DD MMM YY");
    if(today===interviewDate){
      return{color:"red"}
    }
    return null
  }
  const finalStatusColor=(value)=>{
 switch(value){
  case "Shortlisted":
    return {color:"green"}
  case "Rejected":
    return {color:"red"}
  default:
    return null
 }
  }

   return <>
    <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
        PlusComponent1={<CheckBoxCustom
            item="All"
            checked={all}
            onChange={(e) => setAll(!all)}
          />}
          PlusComponent={<TotalCount totalCount={data?.positionCount}/>}
      />
       <ATSContainer>
       <div>
            <div className={style.th}>
              <p style={!all?{ position: "relative",width:"16rem" }:null}>Name</p>
              <p > Code</p>
              <p >Position Name</p>
              <p >Round</p>
              <p > Type</p>
             
              <p >Interview Date</p>
              {all&&<p >Final Status</p>}
              <p >Duration</p>
            </div>
            {isError && (
              <div className={style.no_data}>Something Went Wrong</div>
            )}
            {isLoading && <div className={style.no_data}>Loading...</div>}
            {!isError && !isLoading ? (
              data?.getAllData?.length > 0 ? (
                data?.getAllData?.map((item, index) => {
                  return (
                    <div
                      className={style.td}
                      key={index}
                      style={{ position: "relative"}}
                    >
                      <p
                        className={`${style.tableContent} ${style.link}`}
                        style={!all?{ position: "relative",width:"16rem" }:null}
                        onClick={() => {
                          setNextScreen("candidateAssessmentList");
                          setPrevData((prevData) => ({
                            ...prevData,
                            sidebar: "myInterviewList",
                            candidateId: item?.candidateId,
                            page1: "myInterviews",
                            page2: "candidateAssessmentList",
                            page3: null,
                            page4: null,
                            page5: null,
                            active: "candidateAssessmentList",
                          }));
                        }}
                      >
                        {item?.candidateName}
                      </p>

                      <p className={style.tableContent}>{item?.positionCode}</p>

                      <CustomTooltip
                        value={item?.profileName}
                        index={`profileName${index}`}
                        limit={20}
                      />
                      <p> {item?.round}</p>
                      <p>{item?.interviewType}</p>
                     {/* {all&& <p className={style.tableContent}>
                        {item?.interviewerName}
                      </p>} */}
                      {/* {all&&<p> {item?.interviewer}</p>} */}
                      <p style={currentDateActive(item?.dateOfInterView)}>{moment(item?.dateOfInterView).format("DD MMM YY")}</p>
                      {all&&<p
                        className={style.tableContent}
                        style={finalStatusColor(item?.finalStatus)}
                      >
                    
                        {item?.finalStatus}
                      </p>}
                      <p className={style.tableContent}> {item?.duration}</p>
                    </div>
                  );
                })
              ) : (
                <div className={style.no_data}>No Data </div>
              )
            ) : null}
          </div>
         

       </ATSContainer> 
       <ATSCard>
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pagesCount={data?.positionCount/ 10}
            color="#65A15E"
            color2="white"
          />
          </ATSCard>
    </>
}
export default MyInterviews