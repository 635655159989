import React from "react";
import Sidebar from "../Clients/component/SideBar";
import Style from "./createProject.module.css";
import ProjectInfo from "./component/projectInformation/projectInfo";

export default function createProject() {
  return (
    <>
      <div className={Style.projectresource_mainSection}>
        <Sidebar />
        {/* tHIS IS SideBar */}
        <div className={Style.projectresource_mainsection}>
          <div style={{ padding: "23px 0px", fontWeight: "700" }}>
            {" "}
            Create Project
          </div>

          {/* main content */}
          <div className={Style.maindiv}>
            <div
              className={Style.maindivContent}
              // style={{ borderBottom: "2px solid #F5F6FA " }}
            >
              <p className={Style.span1}>Project Information</p>
            </div>

            {<ProjectInfo />}
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
