import React, { useEffect, useState } from "react";
import Header from "../../../GenericComponent/header/CommonHeader";

import SideBar from "../sidebar/SideBar";
import style from "./ClientMissingInprogress.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getClientListFilter,
  getEmployeeList,
  getClientContractMissingInprogress,
} from "../../../Utils/axios/clientContractApi";

import { path } from "../../../routes/PathNames";
import { checkIfEmpty,renderTitle, renderValue } from "../../../Utils/constants";
import Pagination from "../../ClientQuestionnaire/CommonComponent/Pagination";
import CommonDropdown from "../../../GenericComponent/ui/CommonDropdown";

function ClientContractListMissingInprogress() {
  const history = useHistory();
  const location = useLocation();


  const [filter, setFilter] = useState(
    location?.state?.ccListIms
      ? location?.state?.ccListIms
      : {
          limit: 10,
          page: 1,
          clientId: null,
          statusId: null,
          executedById: null,
        }
  );

  const { data: getAllClientContract } = useQuery(
    ["getClientContractIms", filter],
    () =>
      getClientContractMissingInprogress(
        filter.clientId,
        filter.statusId,
        filter.salesRepresentativeId,
        filter.executedById,
        filter.limit,
        filter.page
      ),
    {}
  );

  

  useEffect(() => {
    setFilter({ ...filter, page: 1 });
  }, [
    filter.clientId,
    filter.statusId,
    filter.salesRepresentativeId,
    filter.executedById,
    filter.limit,
  ]);

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setFilter((prev) => ({ ...prev, page: filter.page + 1 }));
    } else if (status === "prev") {
      setFilter((prev) => ({ ...prev, page: filter.page - 1 }));
    }
  };

  const { data: getClientListOptions } = useQuery(
    ["getClientListFilter"],
    () => getClientListFilter(),
    {}
  );

  const { data: getEmployeeListOptions } = useQuery(
    ["getEmployeeList"],
    () => getEmployeeList(),
    {}
  );

  const getStatusColor = (status) => {
    if (status === "Active") {
      return "green";
    } else if (status === "Inactive") {
      return "red";
    } else {
      return null;
    }
  };
  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <div style={{ width: "20vw" }}>
          <SideBar />
        </div>
        <div style={{ width: "80vw", padding: "1rem" }}>
          <div className={style.titleBtn}>
            <div className={style.titleBtnLeft}>
              <p className={style.title}>Missing/Inprogress Contracts</p>
              <button>{getAllClientContract?.totalDataCount}</button>
            </div>
            <div className={style.titleBtnRight}>
              {/* filter 1 */}
              <div className={style.dropdownContainer16}>
                <CommonDropdown
                  color="#562679"
                  placeholder="Sales Representative"
                  value={checkIfEmpty(filter?.salesRepresentativeId, {
                    id: filter?.salesRepresentativeId,
                    label: filter?.salesRepresentative,
                  })}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      salesRepresentativeId: e.id,
                      salesRepresentative: e.label,
                    });
                  }}
                  options={getEmployeeListOptions}
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      salesRepresentativeId: null,
                      salesRepresentative: null,
                    })
                  }
                />
              </div>
              {/* filter 2 */}
              <div className={style.dropdownContainer16}>
                <CommonDropdown
                  color="#562679"
                  placeholder="Client"
                  value={checkIfEmpty(filter?.clientId, {
                    id: filter?.clientId,
                    label: filter?.clientName,
                  })}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      clientId: e.id,
                      clientName: e.label,
                    });
                  }}
                  options={getClientListOptions}
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      clientId: null,
                      clientName: null,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className={style.table}>
            <div className={style.th}>
              <p>Client Name</p>
              <p>Sales Representative</p>
              <p>Business Type</p>
              <p>Status</p>
              <p>Contract Type</p>
              <p>Engagement Status</p>
            </div>
            <div>
              {getAllClientContract &&
                (getAllClientContract?.clientDataResponse?.length > 0 ? (
                  getAllClientContract.clientDataResponse?.map(
                    (item, index) => {
                      return (
                        <div
                          className={style.td}
                          key={item?.id}
                          style={{ position: "relative" }}
                        >
                          <p
                            className={style.link}
                            onClick={() => {
                              history.push({
                                pathname: path.cc_listMissingInprogressById
                                  .replace(":id", item?.id)
                                  .replace(":name","missing-inprogress-contracts"),
                                state: {
                                  ...location.state,
                                  page1: "Missing/Inprogress Contract",
                                  ccListIms: filter,
                                  selectedClient: false,
                                },
                              });
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                history.push({
                                  pathname: path.cc_listMissingInprogressById
                                    .replace(":id", item?.id)
                                    .replace(":name","missing-inprogress-contracts"),
                                  state: {
                                    ...location.state,
                                    page1: "Missing/Inprogress Contract",
                                    ccListIms: filter,
                                    selectedClient: false,
                                  },
                                });
                              }
                            }}
                            tabIndex={0}
                            title={renderTitle(item?.clientName, 30)}
                          >
                            {renderValue(item?.clientName, 30)}
                          </p>
                          <p title={renderTitle(item?.salesRepresentative, 30)}>
                            {renderValue(item?.salesRepresentative, 30)}
                          </p>
                          <p>{item?.businessType}</p>
                          <p>{item?.clientContractStatus}</p>
                          <p>{item?.contractType}</p>
                          <p style={{ color: getStatusColor(item?.engagementStatus) }}>{item?.engagementStatus}</p>
                        </div>
                      );
                    }
                  )
                ) : (
                  <p className={style.no_data}>No Data Found</p>
                ))}
            </div>
          </div>
          <div className={style.pagination}>
            <Pagination
              onPageChange={handlePageChange}
              currentPage={filter?.page}
              pagesCount={getAllClientContract?.totalDataCount / 10}
              color="#855ea1"
              color2="white"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientContractListMissingInprogress;
