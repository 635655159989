import React, { useEffect, useState } from "react";
import Header from "../../../GenericComponent/header/CommonHeader";

import SideBar from "../sidebar/SideBar";
import { useHistory } from "react-router-dom";
import style from "./ClientContractEdit.module.scss";
import { useLocation } from "react-router";
import { customSearch } from "../../atsDashboard/utils/style";
import Select from "react-select";
import { useMutation, useQuery } from "react-query";
import {
  createClientContract,
  getAllClientContractById,
  getApplicableContractStatusList,
  getBusinessTypeList,
  getClientList,
  // getContractGroupingList,
  getContractTypeList,
  getEmployeeList,
  getEngagementList,
  getExecutedByList,
  getAllEmployeeList,
  getCoseContractList
} from "../../../Utils/axios/clientContractApi";
import moment from "moment";
import {
  onChangeHandler,
  showErrMsg,
  checkIfEmpty,
} from "../../../Utils/constants";
import { Toastify } from "../../../App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonDropdown from "../../../GenericComponent/ui/CommonDropdown";
import CommonCalender from "../../../GenericComponent/ui/CommonCalender";
import { path } from "../../../routes/PathNames";

function ClientContractEdit() {
  const [formData, setFormData] = useState({});
  const [validation, setValidation] = useState(false);
  const [contractMonths, setContractMonths] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { data: contractTypeListOptions } = useQuery(
    ["getContractTypeList"],
    () => getContractTypeList(),{
      refetchOnWindowFocus:false

    }
  );

  const { data: getEngagementListOptions } = useQuery(
    ["getEngagementList"],
    () => getEngagementList(),{
      refetchOnWindowFocus:false
    }
  );

  const { data: getExecutedByListOptions } = useQuery(
    ["getExecutedByList"],
    () => getExecutedByList(),{
      refetchOnWindowFocus:false
    }
  );

  
  const { data: getBusinessTypeListOptions } = useQuery(
    ["getBusinessTypeList"],
    () => getBusinessTypeList(),{
      refetchOnWindowFocus:false
    }
  );

  const { data: getClientListOptions } = useQuery(["getClientList"], () =>
    getClientList(),{
      refetchOnWindowFocus:false
    }
  );

  const { data: getEmployeeListOptions } = useQuery(["getEmployeeList"], () =>
    getEmployeeList(),{
      refetchOnWindowFocus:false
    }
  );
  const { data: getAllEmployeeListOptions } = useQuery(
    ["getAllEmployeeList"],
    () => getAllEmployeeList(),{
      refetchOnWindowFocus:false
    }
  );
  const { data: getCoseContractListData } = useQuery(
    ["getCoseContractList"],
    () => getCoseContractList(),{
      refetchOnWindowFocus:false
    }
  );
  const getCoseContractListOptions = getCoseContractListData?.length>0? getCoseContractListData?.map((i) => ({
    value: i.id,
    label: i.name,
  })):[];


  const getAllEmployeeListOptions1 = getAllEmployeeListOptions?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const { data: getAllClientById } = useQuery(
    ["getAllClientContractById"],
    () => getAllClientContractById(location?.state?.clientId),{
      refetchOnWindowFocus:false
    }
  );

  const { data: getApplicableContractStatusOptions } = useQuery(
    ["getApplicableContractStatusList"],
    () => getApplicableContractStatusList(),{
      refetchOnWindowFocus:false
    }
  );
  useEffect(() => {
    if (getAllClientById && location?.state?.clientId) {
      // set data from api and clientContractAndExistingStatus according to existin or clientcontract
      setFormData({
        ...getAllClientById,
        resources: getAllClientById?.resources.map((e) => ({
          value: e?.id,
          label: e?.name,
        })),
        clientContractAndExistingStatusId:
          getAllClientById?.existingStatusId ??
          getAllClientById?.clientContractStatusId,
        clientContractAndExistingStatus:
          getAllClientById?.existingStatus ??
          getAllClientById?.clientContractStatus,
      });
    } else {
      setFormData({});
    }
  }, [getAllClientById]);

  const createClientContractMutation = useMutation(createClientContract, {
    onSuccess: (res) => {
      history.push({
        pathname:
          location?.state?.page1 === "Client Contracts"
            ? path.cc_viewById
                .replace(":id", res?.id)
                .replace(":name", "client-contracts")
            : location?.state?.page1 === "Missing/Inprogress Contract"
            ? path.cc_viewById
                .replace(":id", res?.id)
                .replace(":name", "missing-inprogress-contracts")
            : path.cc_viewById
                .replace(":id", res?.id)
                .replace(":name", "expiring-contracts"),
        state: {
          ...location?.state,
          page2: formData?.id ? "Edit Contract" : "Create Contract",
        },
      });

      Toastify(
        formData?.id
          ? "Client Contract Edited Successfully."
          : "Client Contract Created Successfully."
      );
    },
  });

  const onsubmitHandler = () => {
    if (
      // !formData?.contractGrouping ||
      !formData?.clientName ||
      !formData?.businessType ||
      !formData?.executedBy ||
      // !formData?.contractGrouping ||
      !formData?.engagementStatusId ||
      !formData?.salesRepresentativeEmpId ||
        !formData?.clientContractAndExistingStatusId ||
      moment(formData?.endDate).isBefore(formData?.startDate)
    ) {
      setValidation(true);
      return;
    }
    let payload = {
      contractTypeId: formData?.contractTypeId,
      salesRepresentativeEmpId: formData?.salesRepresentativeEmpId,
      clientId: formData?.clientId,
      businessTypeId: formData?.businessTypeId,
      executedById: formData?.executedById,
      // contractGroupingId: formData?.contractGroupingId,
      engagementStatusId: formData?.engagementStatusId,
      contractTerm: contractMonths,
      startDate: formData?.startDate,
      endDate: formData?.endDate,
      reminderDate: formData?.reminderDate,
      resourceId: formData?.resources?.map((item) => item.value),
      fileUrl: formData?.fileUrl,
      folderUrl: formData?.folderUrl,
      notes: formData?.notes,
      // applicableStatusId: formData?.applicableStatusId,
      clientContractAndExistingStatusId:
        formData?.clientContractAndExistingStatusId,
      ongoing: formData?.ongoing,
      closeCotractId: formData?.closeCotractId,
      traking: formData?.traking,
    };
    if (formData?.id) {
      // edit client
      createClientContractMutation.mutate({
        ...payload,
        id: formData?.id,
      });
    } else {
      // create client
      createClientContractMutation.mutate(payload);
    }
  };
  function calculateMonthDifference(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const monthsApart =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());

    return monthsApart;
  }

  useEffect(() => {
    const monthsDifference =
      formData.endDate &&
      calculateMonthDifference(formData.startDate, formData.endDate);
    setContractMonths(monthsDifference);
  }, [formData.endDate, formData.startDate]);
  const renderRow1 = () => {
    return (
      <div className={style.row1}>
        <div className={style.dropdownContainer}>
          <label>
            Contract Type <span className={style.mand}>*</span>
          </label>

          <CommonDropdown
            color="#562679"
            placeholder="Contract Type"
            value={checkIfEmpty(formData?.contractTypeId, {
              id: formData?.contractTypeId,
              label: formData?.contractType,
            })}
            onChange={(e) => {
              setFormData({
                ...formData,
                contractTypeId: e.id,
                contractType: e.label,
              });
            }}
            disabled={formData?.id}
            options={contractTypeListOptions}
            customInputOnClick={() =>
              setFormData({ ...formData, contractTypeId: "" })
            }
          />
          {showErrMsg(
            formData?.contractType,
            "Please Select Contract Type",
            style,
            validation
          )}
        </div>

        <div className={style.dropdownContainer}>
          <label>
            Sales Representative <span className={style.mand}>*</span>
          </label>

          <CommonDropdown
            value={checkIfEmpty(formData?.salesRepresentativeEmpId, {
              id: formData?.salesRepresentativeEmpId,
              label: formData?.salesRepresentative,
            })}
            onChange={(e) => {
              setFormData({
                ...formData,
                salesRepresentativeEmpId: e.id,
                salesRepresentative: e.label,
              });
            }}
            options={getEmployeeListOptions}
            placeholder="Sales Representative"
            color="#562679"
            customInputOnClick={() =>
              setFormData({
                ...formData,
                salesRepresentativeEmpId: "",
              })
            }
          />
          {showErrMsg(
            formData?.salesRepresentative,
            "Please Select Sales Representative",
            style,
            validation
          )}
        </div>
        <div className={style.dropdownContainer}>
          <label>
            Client Name <span className={style.mand}>*</span>
          </label>

          <CommonDropdown
            color="#562679"
            value={checkIfEmpty(formData?.clientId, {
              id: formData?.clientId,
              label: formData?.clientName,
            })}
            onChange={(e) => {
              setFormData({
                ...formData,
                clientId: e.id,
                clientName: e.label,
              });
            }}
            // disabled={formData?.id}
            options={getClientListOptions}
            customInputOnClick={() =>
              setFormData({ ...formData, clientId: "" })
            }
            placeholder="Client Name"
          />
          {showErrMsg(
            formData?.clientName,
            "Please Select Client Name",
            style,
            validation
          )}
        </div>
        <div className={style.dropdownContainer}>
          <label>
            Business Type <span className={style.mand}>*</span>
          </label>

          <CommonDropdown
            value={checkIfEmpty(formData?.businessTypeId, {
              id: formData?.businessTypeId,
              label: formData?.businessType,
            })}
            onChange={(e) => {
              setFormData({
                ...formData,
                businessTypeId: e.id,
                businessType: e.label,
              });
            }}
            disabled={formData?.id}
            options={getBusinessTypeListOptions}
            customInputOnClick={() =>
              setFormData({ ...formData, businessTypeId: "" })
            }
            placeholder="Business Type"
            color="#562679"
          />
          {showErrMsg(
            formData?.businessType,
            "Please Select Business Type",
            style,
            validation
          )}
        </div>
      </div>
    );
  };

  const renderRow2 = () => {
    return (
      <div className={style.row2}>
        <div className={style.dropdownContainer}>
          <p className={style.label}>
            Executed By <span className={style.mand}>*</span>
          </p>

          <CommonDropdown
            value={checkIfEmpty(formData?.executedById, {
              id: formData?.executedById,
              label: formData?.executedBy,
            })}
            onChange={(e) => {
              setFormData({
                ...formData,
                executedById: e.id,
                executedBy: e.label,
              });
            }}
            options={getExecutedByListOptions}
            placeholder="Executed By"
            color="#562679"
            customInputOnClick={() =>
              setFormData({ ...formData, executedById: "" })
            }
          />
          {showErrMsg(
            formData?.executedBy,
            "Please Select Executed By",
            style,
            validation
          )}
        </div>
        <div className={style.dropdownContainer}>
          {/* <p>
            Contract Grouping <span className={style.mand}>*</span>
          </p>

          <CommonDropdown
            value={checkIfEmpty(formData?.contractGroupingId, {
              id: formData?.contractGroupingId,
              label: formData?.contractGrouping,
            })}
            onChange={(e) => {
              setFormData({
                ...formData,
                contractGroupingId: e.id,
                contractGrouping: e.label,
              });
            }}
            options={getContractGroupingListOptions}
            customInputOnClick={() =>
              setFormData({ ...formData, contractGroupingId: "" })
            }
            placeholder="Contract Grouping"
            color="#562679"
          />
          {showErrMsg(
            formData?.contractGrouping,
            "Please Select Contract Grouping",
            style,
            validation
          )} */}

          <p className={style.label}>
            Engagement Status <span className={style.mand}>*</span>
          </p>
          <CommonDropdown
            value={checkIfEmpty(formData?.engagementStatusId, {
              id: formData?.engagementStatusId,
              label: formData?.engagementStatus,
            })}
            onChange={(e) => {
              setFormData({
                ...formData,
                engagementStatusId: e.id,
                engagementStatus: e.label,
              });
            }}
            options={getEngagementListOptions}
            placeholder="Engagement Status"
            customInputOnClick={() =>
              setFormData({ ...formData, engagementStatusId: "" })
            }
            color="#562679"
          />
          {showErrMsg(
            formData?.engagementStatusId,
            "Please Select Engagement Status",
            style,
            validation
          )}
        </div>
        <div className={style.dropdownContainer}>
        <p className={style.label}>Contract Terms (Month)</p>
          <input
            value={contractMonths}
            className={style.textField}
            onChange={(e) => {
              onChangeHandler(
                formData,
                setFormData,
                e,
                "contractTerm",
                "input"
              );
            }}
            // placeholder="Please Enter Contract Term"
            disabled={true}
          />
        </div>
        <div className={style.textcontainer}>
        <p className={style.label}>
            Closed Contract Reason
          </p>

          <CommonDropdown
            value={getCoseContractListOptions?.find((e) => e.value === formData?.closeCotractId) || null }
            onChange={(e) => {
              setFormData({
                ...formData,
                closeCotractId: e.value,
              });
            }}
            options={getCoseContractListOptions}
            placeholder="Closed Contract"
            customInputOnClick={() =>
              setFormData({ ...formData, closeCotractId: "" })
            }
            color="#562679"
          />
         
        </div>
      </div>
    );
  };

  const renderRow3 = () => {
    return (
      <div className={style.row3}>
        <div className={style.dropdownContainer}>
        <p className={style.label}>
            Contract Status <span className={style.mand}>*</span>
          </p>

          <CommonDropdown
            value={checkIfEmpty(formData?.clientContractAndExistingStatusId, {
              id: formData?.clientContractAndExistingStatusId,
              label: formData?.clientContractAndExistingStatus,
            })}
            onChange={(e) => {
              setFormData({
                ...formData,
                clientContractAndExistingStatusId: e.id,
                clientContractAndExistingStatus: e.label,
              });
            }}
            options={getApplicableContractStatusOptions}
            placeholder="Contract Status"
            customInputOnClick={() =>
              setFormData({
                ...formData,
                clientContractAndExistingStatusId: "",
              })
            }
            color="#562679"
          />
          {showErrMsg(
            formData?.clientContractAndExistingStatusId,
            "Please Select Contract Status",
            style,
            validation
          )}
        </div>

        <div className={style.dropdownContainer}>
        <p className={style.label}>Contract Start Date</p>
          <div className={style.date}>
            <CommonCalender
              onChange={(date) => {
                onChangeHandler(
                  formData,
                  setFormData,
                  date,
                  "startDate",
                  "date"
                );
              }}
              formData={formData}
              setFormData={setFormData}
              onCross="startDate"
              value={formData?.startDate}
              icon="purple"
            />
          </div>
          <p
            className={
              validation &&
              moment(formData?.endDate).isBefore(formData?.startDate)
                ? style.errorText
                : style.errorTextHidden
            }
          >
            "Start Date is greater than End Date"
          </p>
        </div>

        <div className={style.datePickerContainer}>
       
        <p className={style.label}>Contract End Date</p>
          <div className={style.date}>
            <CommonCalender
              formData={formData}
              setFormData={setFormData}
              value={formData?.endDate}
              onChange={(date) => {
                onChangeHandler(formData, setFormData, date, "endDate", "date");
              }}
              onCross="endDate"
              icon="purple"
            />
          </div>
          
        </div>
        <div className={style.datePickerContainer}>
        <p className={style.label}>Reminder Date</p>
          <div className={style.date}>
            <CommonCalender
              formData={formData}
              setFormData={setFormData}
              value={formData?.reminderDate}
              onChange={(date) => {
                onChangeHandler(
                  formData,
                  setFormData,
                  date,
                  "reminderDate",
                  "date"
                );
              }}
              onCross="reminderDate"
              icon="purple"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderRow4 = () => {
    return (
      <div className={style.row4}>
        <div className={style.datePickerContainer}>
        <p className={style.label}>Resource Name</p>
          <Select
            styles={customSearch}
            placeholder="Resource Name"
            isMulti
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={formData?.resources ? formData?.resources : null}
            onChange={(e) => {
              setFormData({ ...formData, resources: e });
            }}
            options={getAllEmployeeListOptions1}
          />
        </div>
        <div className={style.textcontainer}>
        <p className={style.label}>File URL</p>
          <input
            value={formData?.fileUrl}
            className={style.textField}
            onChange={(e) => {
              onChangeHandler(formData, setFormData, e, "fileUrl", "input");
            }}
            placeholder="Please Enter File Url"
          />
          <br />
        </div>
        <div className={style.textcontainer}>
        <p className={style.label}>Folder URL</p>
          <input
            value={formData?.folderUrl}
            className={style.textField}
            onChange={(e) => {
              onChangeHandler(formData, setFormData, e, "folderUrl", "input");
            }}
            placeholder="Please Enter Folder Url"
          />
        </div>
        <div className={style.textcontainer}>
          
        </div>
      </div>
    );
  };

  const renderRow5 = () => {
    return (
      <div className={style.row5}>
        <div className={style.textcontainerbig}>
          <p className={style.label}>Notes</p>
          <textarea
            value={formData?.notes}
            className={style.textFieldBig}
            onChange={(e) => {
              onChangeHandler(formData, setFormData, e, "notes", "input");
            }}
            placeholder="Please Enter Notes"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <div style={{ width: "20vw" }}>
          <SideBar />
        </div>
        <div style={{ width: "80vw", padding: "1rem" }}>
          <div className={style.titleBtn}>
            <p className={style.title}>
              <span
                style={{ color: "#562679", cursor: "pointer" }}
                onClick={() => {
                  location?.state?.page1 === "Client Contracts"
                    ? history.push({
                        pathname: path.cc_contract_ims.replace(
                          ":name",
                          "client-contracts"
                        ),
                        state: {
                          ...location.state,
                        },
                      })
                    : history.push({
                        pathname: path.cc_list.replace(
                          ":name",
                          "expiring-contracts"
                        ),
                        state: {
                          ...location.state,
                        },
                      });
                }}
              >
                {location?.state?.page1} {" > "}
              </span>
              {location?.state?.page2 && (
                <span
                  style={{ color: "#562679", cursor: "pointer" }}
                  onClick={() => {
                    history.push({
                      pathname:
                        location?.state?.page1 === "Client Contracts"
                          ? path.cc_listById
                              .replace(":id", location?.state?.clientId)
                              .replace(":name", "client-contracts")
                          : path.cc_listById
                              .replace(":id", location?.state?.clientId)
                              .replace(":name", "expiring-contracts"),
                      state: { ...location.state, page2: "" },
                    });
                  }}
                >
                  {location?.state?.page2} {" > "}
                </span>
              )}
              {formData?.id ? "Edit Client Contract" : "Create Client Contract"}
            </p>
          </div>
          <div className={style.card}>
            <p className={style.heading}>Details</p>
            <div className={style.form}>
              {renderRow1()}
              {renderRow2()}
              {renderRow3()}
              {renderRow4()}
              <div className={style.row6}>
                <input
                  type="checkbox"
                  name="checkbox"
                  checked={formData?.ongoing}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ongoing: formData?.ongoing === true ? false : true,
                    })
                  }
                  style={{ visibility: "visible", accentColor: "blue" }}
                />
                <label htmlFor={"checkbox"}>{` Ongoing Contract`} ?</label>
                <input
                  type="checkbox"
                  name="checkbox"
                  checked={formData?.traking}
                  onChange={() =>
                    setFormData((prev) => ({...prev, traking:!prev.traking}))
                  }
                  style={{ visibility: "visible", accentColor: "blue" }}
                />
                <label htmlFor={"checkbox"}>{` Tracking Checking`} ?</label>
              </div>
              {renderRow5()}
            </div>
          </div>
          <div className={style.btn}>
            <button onClick={onsubmitHandler} >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ClientContractEdit;
