import React from "react";
import AppraisalSystemSidebar from "../commonComponent/Sidebar/AppraisalSystemSidebar";
import ASEmployeeListComponent from "./ASEmployeeListComponent/ASEmployeeListComponent";
import style from "./ASEmployeeList.module.css";
import ASContext from "../commonComponent/Context/ASContext";
const ASEmployeeList = () => {
  return (
    <div className={style.mainSection}>
      <ASContext>
        <AppraisalSystemSidebar />
        <div className={style.content}>
          <ASEmployeeListComponent />
        </div>
      </ASContext>
    </div>
  );
};

export default ASEmployeeList;
