export const handleAddComment = (positionId, comment) => {
    const existingComments = JSON.parse(localStorage.getItem('unsavedComments')) || [];
    const existingCommentIndex = existingComments.findIndex(c => c.positionId === positionId);
    if (existingCommentIndex !== -1) {
        existingComments[existingCommentIndex] = { positionId, comment };
    } else {
        existingComments.push({ positionId, comment });
    }
    localStorage.setItem('unsavedComments', JSON.stringify(existingComments));
};

export const getCommentForPosition = (positionId) => {
    const existingComments = JSON.parse(localStorage.getItem('unsavedComments')) || [];
    const commentData = existingComments.find(c => c.positionId === positionId);
    return commentData ? commentData.comment : '';
};

export const handleAddComment1 = (candidateId, comment) => {
    const existingComments = JSON.parse(localStorage.getItem('unsavedComments1')) || [];
    const existingCommentIndex = existingComments.findIndex(c => c.candidateId === candidateId);
    if (existingCommentIndex !== -1) {
        existingComments[existingCommentIndex] = { candidateId, comment };
    } else {
        existingComments.push({ candidateId, comment });
    }
    localStorage.setItem('unsavedComments1', JSON.stringify(existingComments));
};

export const getCommentForCandidate = (candidateId) => {
    const existingComments = JSON.parse(localStorage.getItem('unsavedComments1')) || [];
    const commentData = existingComments.find(c => c.candidateId === candidateId);
    return commentData ? commentData.comment : '';
};

export const handleAddComment2 = (jobId, comment) => {
    const existingComments = JSON.parse(localStorage.getItem('unsavedComments2')) || [];
    const existingCommentIndex = existingComments.findIndex(c => c.jobId === jobId);
    if (existingCommentIndex !== -1) {
        existingComments[existingCommentIndex] = { jobId, comment };
    } else {
        existingComments.push({ jobId, comment });
    }
    localStorage.setItem('unsavedComments2', JSON.stringify(existingComments));
};

export const getCommentForJob = (jobId) => {
    const existingComments = JSON.parse(localStorage.getItem('unsavedComments2')) || [];
    const commentData = existingComments.find(c => c.jobId === jobId);
    return commentData ? commentData.comment : '';
};