import React, { useState, useEffect } from "react";
import style from "./EditCommentsComponent.module.css";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import cookie from "react-cookies";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import constants, { employeeId } from "../../../../Utils/constants";

const EditCommentsComponent = (props) => {
  const history = useHistory();

  const [commentData, setCommentData] = useState(props.commentData);
  const [newData, setnewData] = useState(props.taskData);
  const [updatedComment, setupdatedComment] = useState(
    props.commentData.taskComment
  );
  const [updatedCommentId, setupdatedCommentId] = useState(
    props.commentData.taskCommentId
  );
  const [dateState, setDateState] = useState(
    props.commentData.taskModifiedDate
  );
  const [error, setError] = useState(false);



  const url2 = constants.apiBaseUrl2;

  const handleUpdate = (e) => {
    e.preventDefault();
    const data1 = {
      // "taskCommentId": props.commentData.taskCommentId,
      taskCommentId: updatedCommentId,
      updatedComment: updatedComment,
    };

    axios
      .post(url2 + "/editTaskComment", data1, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })

      .then((res) => {
        if (res.data.responseData.taskCommentUpdated == true) {
          // history.push("/TaskAssignedByMeDetails", newData)
          // document.getElementById("create_task_id").reset();
          // setshowRes(true);
          // hideAlert()
          history.push({
            pathname: "/taskTracker/recurringTaskDetails",
            state: {
              // comment: comment ,
              task: newData,
              activePage: 2,
            },
          });
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  return (
    <div className={style.rightSection}>
      <div className={style.container}>
        <span>Edit Recurring Comment</span>
        <div className={style.createTask}>
          {/* {showErr &&
              <div className={style.req_msg}><p>* All fields are mandatory.</p></div>
            } */}
          <form id="create_task_id" className={style.gridContainer}>
            <label className={style.titleLabel}>Reported Date:</label>

            <label className={style.valueTitle}>
              <p>{Moment(dateState).format("DD MMM (ddd)")}</p>
              {/* <input name="task_title" type="text" id="tast_title" /> */}
            </label>
            <label className={style.userLabel}>Username:</label>
            <label className={style.valueUser}>
              <p>{commentData.name}</p>
              {/* <input name="plannedEnd" type="text" id="plannedEnd" value={Moment(dateState).format("DD MMM (ddd)")}/> */}
            </label>
            <label className={style.descLabel}>
              Comment Description:<span style={{ color: "red" }}>*</span>
            </label>

            <label className={style.areaLabel}>
              {/* <textarea name="comment" type="number" id="comment"
                      value={updatedComment} onChange={e => setupdatedComment(e.target.value)}
                      /> */}
              {/* <div className={style.scrollAdd}> */}
              <div className={style["text-area-container"]}>
                <textarea
                  className={style.taskdescription}
                  onChange={(e) => setupdatedComment(e.target.value)}
                  value={updatedComment}
                  name="task_description"
                  type="number"
                  id="task_description"
                  rows={7}
                  // className={style.scrollAdd}
                />
              </div>
              {/* </div> */}
              {error && (
                <div className={style.req_msg}>
                  <p>* Task comment can not empty.</p>
                </div>
              )}
            </label>
          </form>
        </div>
        <button className={style.submitButton} onClick={handleUpdate}>
          Update
        </button>
        <button
          className={style.backButton}
          // onClick={() => history.push("/taskTracker/TaskAssignedByMeComponent")}
          onClick={() =>
            history.push({
              pathname: "/TaskAssignedByMeDetails",
              state: {
                task: props.taskData,
                activePage: 2,
              },
            })
          }
        >
          {" "}
          Back
        </button>
      </div>
    </div>
  );
};

export default EditCommentsComponent;
