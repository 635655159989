import React from "react";

import Modal from "react-responsive-modal";
import style from "./AppraisalFilter.module.css";
import "./style.css";

function AppraisalFilter({
  setShowFilter,
  showFilter,
  firstCard,
  secondCard,
  firstCardlist,
  secondCardlist,
  thirdCard,
  thirdCardlist,
  filterData,
  setFilterData,
  filterUpdateFuc,
  filterClearAllFuc,
  forthCard,
  forthCardlist,
  fiveCardlist,
  fiveCard,
  SixCardlist,
  SixCard,
  // sevenCard,
  // sevenCardlist,
  Eightcard,
  Eightcardlist,
  NineCard,
  NineCardlist,
  TenCard,
  Tencardlist,
  filterApplied,
}) {
  const handleCheckBox = (e, status) => {
    switch (status) {
      case firstCard:
        {let data = filterData;
        if (data.status.includes(Number(e.target.value))) {
          let index = data.status.indexOf(Number(e.target.value));
          data.status.splice(index, 1);
          return setFilterData({ ...filterData, status: data.status });
        } else {
          data.status.push(Number(e.target.value));
          return setFilterData({ ...filterData, status: data.status });
        }}
      case secondCard:
        {let data1 = filterData;
        if (data1.technology.includes(Number(e.target.value))) {
          let index = data1.technology.indexOf(Number(e.target.value));
          data1.technology.splice(index, 1);
          return setFilterData({ ...filterData, technology: data1.technology });
        } else {
          data1.technology.push(Number(e.target.value));
          return setFilterData({ ...filterData, technology: data1.technology });
        }}
      case thirdCard:
        {let data3 = filterData;
        if (data3.empType.includes(Number(e.target.value))) {
          let index = data3.empType.indexOf(Number(e.target.value));
          data3?.empType?.splice(index, 1);
          return setFilterData({
            ...filterData,
            empType: data3.empType,
          });
        } else {
          data3.empType.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            empType: data3.empType,
          });
        }}
      case forthCard:
        {let data4 = filterData;
        if (data4.client.includes(Number(e.target.value))) {
          let index = data4.client.indexOf(Number(e.target.value));
          data4?.client?.splice(index, 1);
          return setFilterData({
            ...filterData,
            client: data4.client,
          });
        } else {
          data4.client.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            client: data4.client,
          });
        }}
      case fiveCard:
        {let data5 = filterData;
        if (data5?.reportingManager?.includes(Number(e.target.value))) {
          let index = data5.reportingManager.indexOf(Number(e.target.value));
          data5?.reportingManager?.splice(index, 1);
          return setFilterData({
            ...filterData,
            reportingManager: data5.reportingManager,
          });
        } else {
          data5?.reportingManager?.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            reportingManager: data5.reportingManager,
          });
        }}
      case SixCard:
       { let data6 = filterData;
        if (data6?.reward?.includes(Number(e.target.value))) {
          let index = data6.reward.indexOf(Number(e.target.value));
          data6?.reward?.splice(index, 1);
          return setFilterData({
            ...filterData,
            reward: data6.reward,
          });
        } else {
          data6?.reward?.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            reward: data6.reward,
          });
        }}
      // case sevenCard:
      //   let data7 = filterData;
      //   if (data7?.promotion?.includes(Number(e.target.value))) {
      //     let index = data7.promotion.indexOf(Number(e.target.value));
      //     data7?.promotion?.splice(index, 1);
      //     return setFilterData({
      //       ...filterData,
      //       promotion: data7.promotion,
      //     });
      //   } else {
      //     data7?.promotion?.push(Number(e.target.value));
      //     return setFilterData({
      //       ...filterData,
      //       promotion: data7.promotion,
      //     });
      //   }
        case Eightcard:
        {let data8 = filterData;
        if (data8?.appraisalStatus?.includes(Number(e.target.value))) {
          let index = data8?.appraisalStatus.indexOf(Number(e.target.value));
          data8?.appraisalStatus?.splice(index, 1);
          return setFilterData({
            ...filterData,
            appraisalStatus: data8?.appraisalStatus,
          });
        } else {
          data8?.appraisalStatus?.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            appraisalStatus: data8?.appraisalStatus,
          });
        }}
        case TenCard:
        {let data10 = filterData;
        if (data10?.location?.includes((e.target.value))) {
          let index = data10?.location.indexOf((e.target.value));
          data10?.location?.splice(index, 1);
          return setFilterData({
            ...filterData,
            location: data10?.location,
          });
        } else {
          data10?.location?.push((e.target.value));
          return setFilterData({
            ...filterData,
            location: data10?.location,
          });
        }}
        
        
      default:
        return;
    }
  };
  const handleRadioChange = (e) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      bond: e.target.value, // Update the state with the selected value
    }));
  };

  return (<Modal
        open={showFilter}
        center
        onClose={() => {setShowFilter(false)
          if(!filterApplied){
            filterClearAllFuc()
          }      
        }}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.filterModal}>
          <div className={style.filterCards}>
           {/* <div className={style.firstRow}> */}
           {secondCard && secondCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{secondCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {secondCardlist?.map((item) => {
                    return (
                      <div className={style.list} key={`${item.value}${item.label}`}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={item.value}
                          value={item.value}
                          checked={filterData?.technology?.includes(item.value)}
                          onChange={(e) => handleCheckBox(e, secondCard)}
                        />
                        <label
                          htmlFor={item.value}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {fiveCard && fiveCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{fiveCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {fiveCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={`${item.value}${item.label}`}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`F${key}`}
                          value={Number(item.value)}
                          checked={filterData?.reportingManager?.includes(
                            Number(item.value)
                          )}
                          onChange={(e) => handleCheckBox(e, fiveCard)}
                        />
                        <label
                          htmlFor={`F${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {SixCard && SixCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{SixCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {SixCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={`${item.value}${item.label}`}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`L${key}`}
                          value={Number(item.value)}
                          checked={filterData?.reward?.includes(
                            Number(item.value)
                          )}
                          onChange={(e) => handleCheckBox(e, SixCard)}
                        />
                        <label
                          htmlFor={`L${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* {sevenCard && sevenCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{sevenCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {sevenCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`CI${key}`}
                          value={Number(item.value)}
                          checked={filterData?.promotion?.includes(
                            Number(item.value)
                          )}
                          onChange={(e) => handleCheckBox(e, sevenCard)}
                        />
                        <label
                          htmlFor={`CI${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )} */}
            
           {/* </div> */}
            {/* <div className={style.secondRow}> */}
            {thirdCard && thirdCardlist && (
              <div className={style.secondCard}>
                <div className={style.filte_heading}>{thirdCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {thirdCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={`${item.value}${item.label}`}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`E${key}`}
                          value={Number(item.value)}
                          checked={filterData?.empType?.includes(
                            Number(item.value)
                          )}
                          onChange={(e) => handleCheckBox(e, thirdCard)}
                        />
                        <label
                          htmlFor={`E${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {forthCard && forthCardlist && (
              <div className={style.secondCard}>
                <div className={style.filte_heading}>{forthCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {forthCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={`${item.value}${item.label}`}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`SS${key}`}
                          value={Number(item.value)}
                          checked={filterData?.client?.includes(
                            Number(item.value)
                          )}
                          onChange={(e) => handleCheckBox(e, forthCard)}
                        />
                        <label
                          htmlFor={`SS${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {firstCard && (
              <div className={style.secondCard}>
                <div className={style.filte_heading}>{firstCard}</div>
                {firstCardlist?.map((item, key) => {
                  return (
                    <div className={style.list} key={`${item.value}${item.label}`}>
                      <input
                        type="checkbox"
                        className={style.vh}
                        id={item}
                        value={key + 2}
                        checked={filterData?.status?.includes(key + 2)}
                        onChange={(e) => handleCheckBox(e, firstCard)}
                      />
                      <label
                        htmlFor={item}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={style.label}
                      >
                        {item}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
            {Eightcard && (
              <div className={style.secondCard}>
                <div className={style.filte_heading}>{Eightcard}</div>
                {Eightcardlist?.map((item, key) => {
                  return (
                    <div className={style.list} key={`${item.value}${item.label}`}>
                      <input
                        type="checkbox"
                        className={style.vh}
                        id={`St${key}`}
                        value={key + 1}
                        checked={filterData?.appraisalStatus?.includes(key + 1)}
                        onChange={(e) => handleCheckBox(e, Eightcard)}
                      
                      />
                      <label
                        htmlFor={`St${key}`}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={style.label}
                      >
                        {item.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
            
            {TenCard && (
              <div className={style.secondCard}>
                <div className={style.filte_heading}>{TenCard}</div>
                {Tencardlist?.map((item, key) => {
                  return (
                    <div className={style.list} key={`${item.value}${item.label}`}>
                      <input
                        type="checkbox"
                        className={style.vh}
                        id={`loc${key}`}
                        value={item.value}
                        checked={filterData?.location?.includes(item.value)}
                        onChange={(e) => handleCheckBox(e, TenCard)}
                      
                      />
                      <label
                        htmlFor={`loc${key}`}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={style.label}
                      >
                        {item.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
             <div className={style.secondCard}>
              <div className={style.filte_heading}>{NineCard}</div>
              <div>
              <div className={style.listRadio}>
                <input
                  type="radio"
                  id={1}
                  value={1}
                  checked={filterData?.bond === "1"}
                  onChange={(e) => handleRadioChange(e)}
                  className={style.customRadioInput}
                />
                <label htmlFor={"No"} className={style.customRadioLabel}>
                  No 
                </label>
              </div>
              <div className={style.listRadio}>
                <input
                  type="radio"
                  id={2}
                  value={2}
                  checked={filterData?.bond === "2"}
                  onChange={(e) => handleRadioChange(e)}
                  className={style.customRadioInput}
                />
                <label htmlFor={"Active"} className={style.customRadioLabel}>
                  Yes (Active)
                </label>
              </div>
              <div className={style.listRadio}>
                <input
                  type="radio"
                  id={3}
                  value={3}
                  checked={filterData?.bond === "3"}
                  onChange={(e) => handleRadioChange(e)}
                  className={style.customRadioInput}
                />
                <label htmlFor={"In-Active"} className={style.customRadioLabel}>
                  Yes (In-Active)
                </label>
              </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
        <div className={style.filterBtn}>
          <button
            className={style.cancelBtn}
            onClick={() => {
              filterClearAllFuc();
            }}
          >
            Clear All
          </button>
          <button className={style.createBtn} onClick={() => filterUpdateFuc()}>
            Apply
          </button>
        </div>
      </Modal>
  );
}

export default AppraisalFilter;
