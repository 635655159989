import React, { useEffect, useState } from 'react'
import SideBar from '../../sidebar/SideBar'
import style from "./incentiveReport.module.scss";
import { useMutation, useQuery } from 'react-query';
import { getEmpRoleByEmpId } from '../../../../Utils/axios/umsApi';
import { IoSearchOutline } from "react-icons/io5";
import Select from "react-select";
import { employeeId } from '../../../../Utils/constants';
import searchIcon from "../../../../assets/Icon-search.png";
import { addIncentiveReport, getAllLeadList, getEmployeeNameById, getIncentiveDetails, getIncentiveHistoryDetails, getIncentiveReport } from '../../../../Utils/axios/api';
import { AiOutlineClose } from "react-icons/ai";
import ATSContainer from '../../../atsDashboard/customTooltip/ATSContainer';
import DropSwitchComm from '../commonComponent/DropSwitch/DropSwitchComm';
import moment from "moment";
import pencil1 from "../../../../assets/pencil2.png";
import Modal from 'react-responsive-modal';
import { MdHistory } from "react-icons/md";
import { IoPencil } from "react-icons/io5";
import Popup from 'reactjs-popup';
import Table from 'react-bootstrap/Table';
import { notifyError, notifySuccess } from '../../../atsDashboard/utils/notify';
import { FaEye, FaRegComments } from "react-icons/fa";
import dropdownIcon from "../../../../assets/downIcon.png";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { CgNotes } from "react-icons/cg";



export const customStyles = {
    control: (style, state) => ({
        ...style,
        boxShadow: "0px 3px 9px #00000029",
        borderRadius: "9px",
        background: state.isDisabled ? "#999" : "#f5f6fa", // Corrected this line
        // opacity: state.isDisabled ? "0.2" : "1",
        border: "none",
        // margin: "8px 1.5% 0px 1.7%",
        minHeight: "10px",
        outline: "none",
        width: "30px",
        cursor: "pointer",
    }),
    placeholder: () => ({
        display: "none",
    }),
    clearIndicator: (base) => ({
        ...base,
        display: "none",
    }),
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: "rgba(50, 205, 50, 0.5)",
        },
        backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: "999",
    }),
};

export const monthsData = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];


export const customSearch = {
    control: (base, { isDisabled }) => ({
        // margin: "0 7px",
        ...base,
        borderRadius: "5px",
        padding: "0 20px 0 10px",
        border: "1px solid #00000061",
        cursor: "pointer",
        backgroundColor: isDisabled ? "#e0dcdc" : "#f5f6fa",
    }),
    placeholder: () => ({
        // display: "none",
        marginTop: "-30px",
        color: "hsl(0, 0%, 5%);",
        fontSize: "15px"
    }),

    //  option color on hover green
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: "rgba(50, 205, 50, 0.5)",
        },
        backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
    }),
    // dropdown width
    menu: (provided) => ({
        ...provided,
        width: "92%",
        marginLeft: "0.6rem",
        zIndex: "999",
        maxHeight: "300px",
    }),
};

export const getPrviousYear = () => {
    const currentYear = new Date().getFullYear();

    // Calculate the previous year
    const previousYear = currentYear - 1;

    // Create an array with the current year and previous year
    const yearsArray = [currentYear, previousYear];
    return yearsArray;
};




const IncentiveReport = () => {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(
        location?.state?.currentPage ? location?.state?.currentPage : 1
    );
    const [selectedValue, setSelectedValue] = React.useState(false);
    const [balance, setBalance] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalCount, settotalCount] = useState();
    const [year, setYear] = useState([]);
    const [candidate, setCandidate] = useState([]);
    const [filter, setFilter] = useState()
    const [isChecked, setIsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [incentiveAmount, setIncentiveAmount] = useState(null);
    const [recoveryAmount, setRecoveryAmount] = useState(null);
    const [recoveryNote, setRecoveryNote] = useState(null);
    const [candidateId, setCandidateId] = useState(null)
    const [recruiterId, setRecruiterid] = useState(null)
    const [showEmpHistoryModal, setShowEmpHistoryModal] = useState(false);
    const [showIncentiveDetails, setShowIncentiveDetails] = useState(false)
    const [historyModalData, sethistoryModalData] = useState('');
    const [incentiveDetails, setIncentiveDetails] = useState(null)
    const [isIncentiveApplicable, setIsIncentiveApplicable] = useState(null)
    const [pageCount, setpageCount] = useState(0);
    const [myKey, setMyKey] = useState(null);
    const limit = 10;



    const toggleModal = (item) => {
        setCandidateId(item?.candidateId)
        setRecruiterid(item?.recruiterId)
        setIsIncentiveApplicable(item?.isIncentiveApplicable)
        // setIncentiveAmount(null)
        // setRecoveryAmount(null)
        // setRecoveryNote(null)
        handleIncentiveDetails(item?.recruiterId, item?.candidateId)
        setShowModal(!showModal);
    };

    const onCloseModal = () => {
        setShowModal(!showModal);
    }

    const handleSubmit = () => {
        toggleModal();
        if (candidateId === null || recruiterId === null) {
            return;
        }
        let data = {
            candidateId: candidateId,
            changeByEmpId: employeeId,
            incentiveAmount: parseFloat(incentiveAmount),
            recoveryAmount: parseFloat(recoveryAmount),
            recoveryNote,
            recruiterId: recruiterId,
            isIncentiveApplicable
        };
        addIncentiveReportMutation.mutate(data);
    };


    const handleYesNo = (item) => {
        let data = {
            candidateId: item?.candidateId,
            changeByEmpId: item?.employeeId,
            incentiveAmount: parseFloat(item?.incentiveAmount),
            recoveryAmount: parseFloat(item?.recoveryAmount),
            recoveryNote: item?.recoveryNote,
            recruiterId: item?.recruiterId,
            isIncentiveApplicable: item.isIncentiveApplicable

        }
        addIncentiveReportMutation.mutate(data);
    }




    const { data: roles } = useQuery(
        ["getEmpRole"],
        () => getEmpRoleByEmpId(),
        {
            refetchOnWindowFocus: false,
        }
    );


    useEffect(() => {
        setYear(getPrviousYear()?.map((item) => ({ label: item, value: item })));
        const currentYear = new Date().getFullYear();
        setFilter((prev) => ({
            ...prev,
            year: currentYear,
        }));

        if (roles?.includes(3)) {
            setFilter((prev) => ({
                ...prev,
                recruiterId: null,
                id: employeeId,
                year: currentYear,
            }));
        } else if (roles?.includes(4)) {
            setFilter((prev) => ({
                ...prev,
                recruiterId: employeeId,
                id: null,
                year: currentYear,
            }));
        } else if (roles?.includes(1) || roles?.includes(2)) {
            setFilter((prev) => ({
                ...prev,
                recruiterId: null,
                id: null,
                year: currentYear,
            }));
        }
    }, [roles]);


    const { data: RecuiterData } = useQuery(
        ["getEmployeeNameById"],
        () => getEmployeeNameById(employeeId),
        {
            refetchOnWindowFocus: false,
        }
    );

    const { data: getSourcingLead } = useQuery(
        ["getAllLeadList"],
        () => getAllLeadList(),
        {
            refetchOnWindowFocus: false,
        }
    );


    const addIncentiveReportMutation = useMutation(addIncentiveReport, {
        onSuccess: (res) => {
            if (res.responseStatus.statusCode === 1) {
                console.log("candiate", candidate);
                notifySuccess(res.responseData)
                const updatedData = [...candidate];
                updatedData[myKey].incentiveAmount = incentiveAmount;
                updatedData[myKey].recoveryAmount = recoveryAmount;
                updatedData[myKey].recoveryNote = recoveryNote;
                updatedData[myKey].balance = recoveryAmount > 0 ? -recoveryAmount : incentiveAmount
                setCandidate(updatedData);
                refetch();
            }
            else {
                notifyError(res.responseData.message)
            }
        },
        onError: (err) => {
            notifyError("Something went wrong !!!")
        },
    });


    const {
        data: clouserData,
        isLoading,
        isError,
        refetch,
    } = useQuery(["getIncentiveReport", filter, isChecked], () => {
        // Check if year is undefined
        if (filter?.year === undefined) {
            return;
        }

        return getIncentiveReport(filter?.id, filter?.recruiterId, filter?.year, isChecked);
    });


    const getSourcingLeadOption = roles?.includes(4)
        ? [{ value: RecuiterData?.id, label: RecuiterData?.name }] :
        roles?.includes(3) ? [{ value: RecuiterData?.id, label: RecuiterData?.name }]
            : getSourcingLead?.length > 0
                ? getSourcingLead
                    .filter((e) => e?.teamId !== 1 || e?.teamId === undefined)
                    ?.map((i) => ({
                        value: i.id,
                        label: i.name,
                    }))
                : [];

    const handlePage = (item) => {
        // console.log(item);
    }

    const fetchEmpHistory = async (recruiterId, candidateId) => {
        try {
            if (recruiterId === undefined || candidateId === undefined || recruiterId === null || candidateId === null) {
                return;
            }
            const response = await getIncentiveHistoryDetails(limit, currentPage, recruiterId, candidateId);
            sethistoryModalData(response?.incentiveHistoryData);
            setIsLoaded(false);
            settotalCount(response.count);
            setpageCount(~~(response.count / 10 + 1));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchEmpHistory(recruiterId, candidateId);
    }, [currentPage])

    const handleIncentiveDetails = async (recruiterId, candidateId) => {
        try {
            if (recruiterId === undefined || candidateId === undefined) {
                return;
            }

            const response = await getIncentiveDetails(recruiterId, candidateId);
            setIncentiveDetails(response);
            setIncentiveAmount(response.incentiveAmount);
            setRecoveryAmount(response.recoveryAmount);
            setRecoveryNote(response.recoveryNote);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const handleSelectChange = (event, index) => {
        const newValue = event.target.value === 'true';
        const updatedData = [...candidate];
        updatedData[index].isIncentiveApplicable = newValue;
        setCandidate(updatedData);
    };

    const handlePageChange = (page, status) => {
        if (status === "next") {
            setCurrentPage(page + 1);
        } else if (status === "prev") {
            setCurrentPage(page - 1);
        }
    };


    return (
        <>
            <div className={style.main_client_body}>
                <SideBar />
                <div className={style.main_create}>
                    <div className={style.table_div_head}>
                        <div
                            className={style.breadscrumb}
                            style={{ padding: "6px 0px" }}
                        ></div>
                        <div
                            className={style.second_child}
                            style={{
                                display: "flex",
                                justifyContent: "inherit",
                                width: "97%",
                            }}
                        >
                            <div
                                style={{
                                    paddingLeft: "28px",
                                    width: "30%",
                                    marginTop: "10px",
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    display: "flex",
                                }}
                            >
                                <div>Incentive Master<br></br> (HR) Report</div>
                                <div style={{ marginLeft: "2rem" }}>
                                    <DropSwitchComm
                                        isChecked={isChecked}
                                        handleChange={() => setIsChecked((prev) => !prev)}
                                        label="&nbsp; All"
                                    />
                                </div>

                            </div>

                            <div className={style.topBar}>
                                <div className={style.s2}>
                                    <Select
                                        styles={customSearch}
                                        classNamePrefix={"create_position"}
                                        placeholder="Select Year"
                                        components={{
                                            DropdownIndicator: () => {
                                                return selectedValue ? <IoSearchOutline /> : <IoSearchOutline />;
                                            },
                                            IndicatorSeparator: () => null,
                                        }}
                                        value={
                                            filter?.year
                                                ? year?.find((e) => e.value === filter?.year)
                                                : null
                                        }
                                        onChange={(e) => {
                                            setSelectedValue(!selectedValue)
                                            setFilter((prev) => ({ ...prev, year: e?.value }));
                                            setCandidate([]);
                                        }}
                                        options={year}
                                    />
                                </div>
                                <div className={style.s2}>
                                    {filter?.id === null || roles?.includes(3) || roles?.includes(4) ? (
                                        <span className={style.cp_serchIcon}>
                                            <IoSearchOutline />
                                        </span>
                                    ) : (
                                        <span
                                            className={style.cp_serchIcon}
                                            onClick={() => {
                                                setFilter({
                                                    ...filter,
                                                    id: null,
                                                });
                                            }}
                                        >
                                            <AiOutlineClose />
                                        </span>
                                    )}

                                    <Select
                                        styles={customSearch}
                                        classNamePrefix={"create_position"}
                                        placeholder="Select Lead"
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                        }}
                                        isDisabled={
                                            roles?.includes(3) || roles?.includes(4) ? true : false
                                        }
                                        value={
                                            filter?.id
                                                ? getSourcingLeadOption?.find((e) => e?.value === filter?.id)
                                                : filter?.recruiterId ? getSourcingLeadOption?.find((e) => e?.value === filter?.recruiterId) : null
                                        }
                                        onChange={(e) => {
                                            setFilter((prev) => ({ ...prev, id: e.value }));
                                            setCandidate([]);
                                        }}
                                        options={getSourcingLeadOption}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ATSContainer
                        styles={{ overflowX: "auto", maxHeight: "20rem", maxWidth: "100%" }}
                    >
                        <div className={style.caintainer}>
                            <div className={style.th}>
                                <div>Recruiter</div>
                                {clouserData?.length > 0 &&
                                    clouserData?.slice(0, 1)[0]?.monthlyReportList?.map((item) => {
                                        return <div>{monthsData[item?.month]}</div>;
                                    })}

                                <div>Grand Total</div>
                            </div>
                            {isLoading && <div className={style.no_data}>Loading...</div>}

                            {isError && <div className={style.no_data}>Something went wrong</div>}
                            {!isLoading && !isError ? (
                                clouserData?.length > 0 ? (
                                    clouserData?.map((item, key) => {
                                        return (
                                            <div className={style.td} key={key}>
                                                <div style={item?.active ? {} : { color: "red" }}>
                                                    {item?.recruiterName}
                                                </div>
                                                {item?.monthlyReportList?.map((el, key) => {
                                                    return (
                                                        <div
                                                            key={`d${key}`}
                                                            onClick={() => {
                                                                setCandidate(el?.monthlyCandidateClosureResponses)
                                                            }}
                                                        >
                                                            {el?.closure > 0 && el?.closure}
                                                        </div>
                                                    );
                                                })}

                                                <div
                                                    onClick={() =>
                                                        setCandidate(item?.candidateClosureResponse)
                                                    }
                                                >
                                                    {item?.grandTotal > 0 && item?.grandTotal}
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className={style.no_data}>No Data</div>
                                )
                            ) : null}
                        </div>
                    </ATSContainer>
                    {candidate?.length > 0 ? (
                        <ATSContainer styles={{ overflowX: "auto", maxHeight: "20rem" }}>
                            <div>
                                <div className={style.th1}>
                                    <div>S. No</div>
                                    <div>Candidate<br></br> Name</div>
                                    <div>Position</div>
                                    <div>Deployment<br></br> At</div>
                                    <div>Recruiter</div>
                                    <div>Quarter</div>
                                    <div>Month</div>
                                    <div>DOJ</div>
                                    <div>Applicable <br></br> Date</div>
                                    <div>Incentive <br></br> Paid</div>
                                    <div>Balance</div>
                                    <div>Recovery</div>
                                    <div>Recovery <br></br> Note</div>
                                    <div>Applicable <br></br> Status</div>
                                    <div></div>
                                    <div></div>
                                </div>
                                {candidate?.map((item, key) => {
                                    return (
                                        <div className={style.td1} key={key}>
                                            <div>{key + 1}</div>
                                            <div
                                                title={item?.name}
                                                onClick={() => handlePage(item)}
                                                style={{ color: item?.active !== null ? 'red' : '#666666' }}
                                            >
                                                {item?.name.length > 14 ? `${item?.name.slice(0, 14)}...` : item?.name}
                                            </div>

                                            <div title={item?.position}>{item?.position}</div>
                                            {item?.clientDeployment ? <div>Client</div> : <div>Internal</div>}
                                            <div title={item?.recruiter}>{item?.recruiter}</div>
                                            <div>{item?.quarter}</div>
                                            <div>{monthsData[item?.month]}</div>
                                            <div>{item?.joiningDate ? moment(item?.joiningDate).format("DD MMM YY") : null}</div>
                                            <div>{item?.newJoiningDate ? moment(item?.newJoiningDate).format("DD MMM YY") : null}</div>
                                            <div>{item?.incentiveAmount ? item?.incentiveAmount : "-"}</div>
                                            <div>{item?.balance ? item?.balance : "-"}</div>
                                            <div>{item?.recoveryAmount ? item?.recoveryAmount : "-"}</div>
                                            <div>
                                                <span
                                                    style={{ marginRight: "0.4rem", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setShowIncentiveDetails(true)
                                                        handleIncentiveDetails(item?.recruiterId, item?.candidateId)
                                                    }}
                                                    onKeyDown={() => {
                                                        setShowIncentiveDetails(true)
                                                        handleIncentiveDetails(item?.recruiterId, item?.candidateId)
                                                    }}
                                                    tabIndex={0}>
                                                    {item?.recoveryNote ? <CgNotes style={{ color: "#289e98" }} /> : "-"}
                                                </span>
                                            </div>

                                            <div>
                                                <select
                                                    className={style.custom}
                                                    style={{ width: "70px", color: "#666666" }}
                                                    value={item?.isIncentiveApplicable !== null ? item?.isIncentiveApplicable.toString() : ''}
                                                    onChange={(event) => {
                                                        handleSelectChange(event, key);
                                                        handleYesNo(item);
                                                    }}
                                                >{
                                                        item?.isIncentiveApplicable === null ?
                                                            <>
                                                                <option value="">Select</option>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </> : <><option value="true">Yes</option>
                                                                <option value="false">No</option></>
                                                    }

                                                </select>
                                            </div>
                                            <div>
                                                {
                                                    item?.existHistory ? <MdHistory
                                                        style={{ fontSize: '17px', color: '#289e98', cursor: 'pointer' }}
                                                        title="Incentive History"
                                                        onClick={() => {
                                                            setShowEmpHistoryModal(true)
                                                            fetchEmpHistory(item?.recruiterId, item?.candidateId);
                                                            setCandidateId(item?.candidateId)
                                                            setRecruiterid(item?.recruiterId)
                                                            setBalance(item?.balance)
                                                        }}
                                                    /> : <div></div>
                                                }
                                            </div>
                                            <div>
                                                <IoPencil
                                                    style={{ fontSize: '17px', color: '#289e98', cursor: 'pointer' }}
                                                    title="Add or Edit Incentive"
                                                    onClick={() => {
                                                        toggleModal(item);
                                                        setMyKey(key);
                                                    }}
                                                />
                                            </div>

                                            {/* <div title="Show Incentive Details">
                                                <span
                                                    className={`${style.link}`}
                                                    onClick={() => {
                                                        setShowIncentiveDetails(true)
                                                        handleIncentiveDetails(item?.recruiterId, item?.candidateId)
                                                    }}

                                                >
                                                    <FaEye />
                                                </span>
                                            </div> */}
                                        </div>
                                    );
                                })}
                                <div className={style.td1}>
                                    <div>Total</div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div>{candidate?.reduce((acc, item) => acc + (item?.balance ? parseFloat(item?.balance) : 0), 0)}</div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>

                            </div>
                        </ATSContainer>
                    ) : null}
                </div>
            </div>

            <div>
                <Popup
                    open={showEmpHistoryModal}
                    closeOnDocumentClick={false}
                // onClose={props.closeModal}
                >
                    <div className={style.modal_emp_history1}>
                        <div className={style.heading}>
                            Incentive History
                        </div>
                        <div className={style.modal_table_container}>
                            {historyModalData && historyModalData === 0 ? <p className={style.no_notes}>No Notes</p> :
                                <Table className={style.modal_table} borderless hover responsive >
                                    <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
                                        <tr>
                                            <th style={{ width: '8rem' }}>Date Modified</th>
                                            <th style={{ width: '10rem' }}>Change By</th>
                                            <th style={{ width: '10rem' }}>Fields</th>
                                            <th style={{ width: '10rem' }}>Previous Info</th>
                                            <th style={{ width: '10rem' }}>Current Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {false ? <tr>
                                            <td colSpan={3} style={{ textAlign: 'center' }}>No Data Found</td>
                                        </tr> : (
                                            historyModalData && historyModalData?.map(item => (
                                                <tr>
                                                    <td style={{ width: '8rem' }}>{moment(item.modifiedOn).format("DD MMM YY")}</td>
                                                    <td style={{ width: '10rem' }}>{item.changeByEmpName}</td>
                                                    <td>{item.field}</td>
                                                    <td style={{ width: '10rem' }}>{item.oldValue}</td>
                                                    <td>{item.newValue}</td>
                                                </tr>
                                            ))

                                        )}
                                    </tbody>
                                </Table>
                            }
                        </div>
                        <div className={style.modal_btn_container}>
                            {totalCount && totalCount > limit && pageCount > 0 && !isLoaded && (
                                <div style={{ marginRight: "15px", marginTop: "5px" }}>
                                    <Pagination
                                        pagesCount={pageCount}
                                        pageSize={10}
                                        onPageChange={handlePageChange}
                                        currentPage={currentPage}
                                        color="#289e98"
                                        color2="white"
                                    />
                                </div>
                            )}
                            <button
                                style={{ width: "90px", height: "40px", marginTop: "2px" }}
                                className={style.modal_btn}
                                onClick={() => {
                                    setShowEmpHistoryModal(false)
                                    setCurrentPage(1)
                                }}>Done</button>
                        </div>
                    </div>
                </Popup>
            </div>

            <div>
                <Popup
                    open={showIncentiveDetails}
                    closeOnDocumentClick={false}
                >
                    <div className={style.modal_emp_history}>
                        <div className={style.heading}>
                            Recovery Note
                        </div>
                        <hr></hr>
                        <div className={style.modal_table_container}>
                            {incentiveDetails?.recoveryNote === null
                                ? <p className={style.no_notes}>No Notes</p> :
                                <div>
                                    {incentiveDetails?.recoveryNote}
                                </div>
                            }
                        </div>
                        <div className={style.modal_btn_container}>
                            <button
                                className={style.modal_btn}
                                onClick={() => {
                                    setShowIncentiveDetails(false)
                                    // setNotesModalData({})
                                }}>Done</button>
                        </div>
                    </div>
                </Popup>
            </div>

            <Modal
                open={showModal}
                onClose={onCloseModal}
                center
            >
                <div style={{ width: "500px" }}>
                    {/* <h2>Modal Title</h2> */}
                    <div>
                        <p style={{ fontWeight: "500", marginBottom: "3px" }}>Incentive Paid</p>
                        <input
                            type="number"
                            id="incentiveAmount"
                            value={incentiveAmount}
                            onChange={(e) => setIncentiveAmount(e.target.value)}
                            className={style.coreSkillInput}
                        />
                    </div>
                    <div>
                        <p style={{ fontWeight: "500", marginBottom: "3px" }}>Recovery Amount</p>
                        <input
                            type="number"
                            id="recoveryAmount"
                            value={recoveryAmount}
                            onChange={(e) => setRecoveryAmount(e.target.value)}
                            className={style.coreSkillInput}
                        />
                    </div>
                    <div>
                        <p style={{
                            fontWeight: "500",
                            marginBottom: "3px"
                        }}>Note</p>
                        <textarea
                            id="note"
                            value={recoveryNote}
                            onChange={(e) => setRecoveryNote(e.target.value)}
                            className={style.coreSkillInput1}
                        />
                    </div>
                    <div style={{
                        textAlign: "right",
                        marginRight: "40px",
                        marginTop: "20px"
                    }}>
                        <button
                            onClick={handleSubmit}
                            style={{
                                backgroundColor: "#289e98",
                                color: "white",
                                padding: "10px 20px",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer"
                            }}>
                            Submit
                        </button>
                    </div>

                </div>
            </Modal>
        </>
    )
}

export default IncentiveReport
