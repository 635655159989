import React from 'react'
import Popup from 'reactjs-popup'

function ConfirmPopup(props) {
    return (
        <Popup
            open={props.open}
            closeOnDocumentClick={false}
            onClose={props.closeModal}>
            {props.children}
        </Popup>
    )
}

export default ConfirmPopup
