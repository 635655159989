import React, { useState } from "react";
import style from "./AddComment.module.css";
import { useMutation } from "react-query";
import { addPartnerLeadComment, addCommentsForPartner, addCommentsForPosition, editPartnerLeadComment } from "../../../../../Utils/axios/api";
import { notifyError, notifySuccess } from "../../../utils/notify";
import { employeeId } from "../../../../../Utils/constants";

function AddComment({
  setShowAddComment,
  obj,
  setObj,
  setShowCommentModal,
  partnerId
}) {
  const [commentData, setCommentData] = useState({
    comments: obj ? obj.comments : "",
    commentId: obj ? obj.id : null,
    commentedById: employeeId,
    partnerId: partnerId
  });
  const addCommentsForCandidateMutation = useMutation(addPartnerLeadComment, {
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 1) {
        notifySuccess("Comment Added");
      }
      else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {
      notifyError("Something went wrong");
    },
  });

  const editCommentsPartnerLead = useMutation(editPartnerLeadComment, {
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 1) {
        notifySuccess("Comment Added");
      }
      else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {
      notifyError("Something went wrong");
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(commentData)
    if (commentData.comments.trim()) {
        if(commentData?.commentId){
            editCommentsPartnerLead.mutate({
                comment: commentData?.comments,
                commentBy: employeeId,
                id: commentData?.commentId,
                commentOn: partnerId
            })
        } else{
            addCommentsForCandidateMutation.mutate({
                comment: commentData?.comments,
                commentBy: employeeId,
                id: null,
                commentOn: partnerId
              });
        }
      
      setShowAddComment(false);
      setShowCommentModal(false);
    }
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setObj(null);
    setShowAddComment(false);
  };
  const handleChange = (e) => {
    setCommentData((prev) => {
      return { ...prev, comments: e.target.value };
    });
  };

  return (
    <div className={style.main_container}>
      <p className={style.heading}>Add Comment</p>

      <form>
        <textarea
          name="description"
          id="com"
          cols="50"
          rows="10"
          className={style.textArea}
          value={commentData?.comments}
          onChange={(e) => handleChange(e)}
        ></textarea>
        <br />
        <div className={style.btn_container}>
          <button type="cancel" className={style.btn} onClick={handleCancel}>
            Cancel
          </button>
          <button type="submit" className={style.btn} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddComment;
