import React from "react";
import { AllIssueComp } from "./component/AllIssueComponent";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import style from "./AllIssue.module.css"
export const AllIssue =()=>{
    return(<>
       <Header />
      <div className={style.mainSection}>
        <SideBar />
        <AllIssueComp />
      </div>
    </>)
     
}
