import React, { useEffect,useState} from 'react';
import style from "./RewardInputForm.module.css"
import {addReward,getEmployeeProposedDetail } from '../../../../../Utils/axios/AppraisalApi';
import { useMutation, useQuery } from 'react-query';

const RewardInputForm = ({rewardList,promotionList,currentDetailsObj,modalData,setShowForm,refetchEmployeeDetails,proposedDetails}) => {
      const empId = modalData.eid;
  const [award, setAward] = useState(null);
  const [error,setError] = useState(false);
  const addRewardMutation = useMutation(addReward, {
    onMutate: () => {

        },
    onSuccess: () => {
    refetchEmployeeDetails();
    refetchEmployeeProposedDetails();
    
  },
    onError: (err) => {
    },
  });

  const { data:employeeProposedDetails, refetch: refetchEmployeeProposedDetails } = useQuery(["getEmployeeProposedDetail"], () =>
  getEmployeeProposedDetail(empId)
  );
  useEffect(() => {
    setAward(employeeProposedDetails?.rewardId);
}, [employeeProposedDetails]);

      const handleSubmit = (e) => {
        e.preventDefault();
        // Add your logic to handle form submission here
        let data = {
            "appraisalCycleId": Number(modalData.cycleId),
            "employeeId": Number(modalData.eid),
            "rewardId":award===""|| award==="Choose Reward"?null:Number(award)
          }
          if(data.rewardId===null || data.rewardId===0) {
            setError(true);
            return;
          }
        addRewardMutation.mutate(data);
        setError(false);
        setShowForm(false);
        setAward(null);
      };
    
      const handleCancel = () => {
        // Add your logic to handle form cancellation here
        setShowForm(false);
        setAward(null);
        setError(false);
      };
    
    //   Input Handler Functions
    return (
        <div className={style.container}>
        {/* <h5 className={style.title}>Appraisal Details</h5> */}
       <div className={style.form}>
       <form >
          <div className={style.form_row}>
            <div>
              <label htmlFor="Reward" className={style.label}> Choose Reward</label>
              <div>
                            <div className={style.select}>
                              <select 
                              className={style.selectStyling}
                                name="Choose Reward"
                                value={award}
                                onChange={(e) => setAward(e.target.value)}
                              >
                                <option value={null}>Choose Reward</option>
                                {rewardList?.map((item) => (
                                  <option value={item?.id} key={item?.id}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                            <span
                              className={`${style.errorText} ${
                                error?style.showError: style.hideError
                              }`}
                            >
                              *Please Choose Reward
                            </span>
                </div>
            </div>
          </div>
          {/* Submit and Cancel buttons */}
          <div className={style.buttons}>
            <button type="submit" style={{backgroundColor:"#855ea1",border:"1px solid #855ea1"}} onClick={handleSubmit}>Submit</button>
            <button type="button" style={{backgroundColor:"#855ea1",border:"1px solid #855ea1"}} onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </form>
       </div>
      </div>
    );
}

export default RewardInputForm;
