import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import moment from "moment";
import { useQuery } from "react-query";
import { expenseListDownload } from "../../../../../Utils/axios/expenceApi";

const ExcelFileDownload = ({ filterData, setFilterData, Data }) => {
  const { refetch } = useQuery(
    ["getExpenseList"],
    () => expenseListDownload(10, 1, Data),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        let data = [...res?.responseData?.expenses];
        handleDownloadButtonClick(data);
      },
      onError: (err) => {},
    }
  );

  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Invoice No.",
        "Invoice Date",
        "Bill Month",
        "Vendor Name",
        "Service Month",
        "Service Year",
        "Category",
        "Amount(Billed)",
        "Amount(Payable",
        "Payment Status",
        "Payment Due Date",
        "Payment Date",
        "Payment Mode",
        "Approved By",
      ],
      ...data?.map((item) => [
        item?.invoice,
        item?.dateOfExpense
          ? moment(item?.dateOfExpense)?.format("DD MMM YY")
          : "-",
        item?.expenseMonth,
        item?.vendorName,
        item?.serviceMonth,
        item?.serviceYear,
        item?.categoryName,
        item?.totalAmount,
        item?.amountToBePaid,
        item?.paymentStatus,
        item?.paymentDueDate
          ? moment(item?.paymentDueDate)?.format("DD MMM YY")
          : "-",
        item?.paymentDate
          ? moment(item?.paymentDate)?.format("DD MMM YY")
          : "-",
        item?.paymentMode,
        item?.approveBy,
      ]),
    ];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    return workbook;
  }

  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);
    downloadExcelFile(workbook);
  }

  return (
    <div>
      <FiDownload
        style={{
          verticalAlign: "baseline",
          width: "30px",
          height: "30px",
          cursor: "pointer",
          color: "#855ea1",
        }}
        onClick={() => {
          setFilterData({ ...filterData, download: true });
          refetch();
        }}
      />
    </div>
  );
};

export default ExcelFileDownload;
