export const customStyles = {
    control: (style, state) => ({
      ...style,
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: state.isDisabled ? "#999" : "f5f6fa",
      backgroundColor:
        // change color when disabled
        state.isDisabled ? "#999" : "#f5f6fa",
      opacity: state.isDisabled ? "0.2" : "1",
      border: "none",
      // minWidth: "inherit",
      margin: "8px 1.5% 0px 0%",
      minHeight: "38px",
      outline: "none",
      width: "100%",
      cursor: "pointer",
    }),
    placeholder: () => ({
      display: "none",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#9C57A5",
      },
      backgroundColor: state.isSelected ? "#9C57A5" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: "999",
    }),
  };
  export const customStyles2 = {
    control: (style, state) => ({
      ...style,
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: state.isDisabled ? "#999" : "f5f6fa",
      backgroundColor:
        // change color when disabled
        state.isDisabled ? "#999" : "#f5f6fa",
      border: "none",
      opacity: state.isDisabled ? "0.2" : "1",
  
      minWidth: "99%",
      margin: "8px 1.5% 0px 1.7%",
      minHeight: "38px",
      outline: "none",
      cursor: "pointer",
    }),
    placeholder: () => ({
      display: "none",
    }),
    valueContainer: (base) => ({
      ...base,
      overflowX: "scroll",
      flexWrap: "unset",
      "&::-webkit-scrollbar": {
        height: "4px",
      },
    }),
    multiValue: (base) => ({
      ...base,
      flex: "0 0 auto",
      justifyContent: "center",
    }),
    // clearIndicator
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgba(50, 205, 50, 0.5)",
      },
      backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: "999",
    }),
  };
  export const customSearch = {
    control: () => ({
      margin: "0 10px",
      borderRadius: "9px",
      padding: "0 20px 0 0px",
      border: "1px solid rgba(0, 0, 0, 0.192)",
    }),
    placeholder: () => ({
      // display: "none",
      
      marginTop: "-30px",
      color: "#9c57a5",
    }),
    //  option color on hover green
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#9c57a5",
      },
      backgroundColor: state.isSelected ? "#9c57a5" : "white",
    }),
    // dropdown width
    menu: (provided) => ({
      ...provided,
      width: "92%",
      marginLeft: "0.6rem",
    }),
  };
  
  export const customSearchPosition = {
    control: () => ({
      margin: "0 10px",
      borderRadius: "5px",
      padding: "0 20px 0 10px",
      border: "1px solid rgba(0, 0, 0, 0.192)",
      width: "96%",
      cursor: "pointer",
    }),
    placeholder: () => ({
      // display: "none",
      marginTop: "-30px",
      color: "hsl(0deg 2% 18%)",
    }),
    //  option color on hover green
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgba(50, 205, 50, 0.5)",
      },
      backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
    }),
    // dropdown width
    menu: (provided) => ({
      ...provided,
      width: "96%",
      marginLeft: "0.6rem",
      zIndex: "999",
    }),
  };
  export const customStyles3 = {
    control: (style, state) => ({
      ...style,
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: state.isDisabled ? "#999" : "#f5f6fa",
      opacity: state.isDisabled ? "0.2" : "1",
      backgroundColor: state.isDisabled ? "#999" : "#f5f6fa",
      border: "none",
      // minWidth: "inherit",
      margin: "0px 1.5% 0px 1.7%",
      height: "38px",
      outline: "none",
      width: "100%",
      cursor: "pointer",
    }),
    placeholder: () => ({
      display: "none",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgba(50, 205, 50, 0.5)",
      },
      backgroundColor: state.isSelected ? "rgba(50, 205, 50, 0.5)" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: "999",
    }),
  };
  