import React from "react";
import "./style.css";
import Modal from "react-responsive-modal";
import style from "./Filter.module.css";
import { useQuery } from "react-query";
import Select from "react-select";
import moment from "moment";
import {
  getExpenseCategory,
  getVendorName,
  getPaymentStatus,
  getPaymentDate,
  getMonth,
  getPaymentMode,
  getApproveBy,
} from "../../../Utils/axios/expenceApi.js";
import CreatableSelect from "react-select/creatable";
import { filterBox, filterBox1 } from "../utils/style";
function Filter({
  setShowFilter,
  showFilter,
  nineCard,
  firstCard,
  secondCard,
  firstCardlist,
  secondCardlist,
  thirdCard,
  thirdCardlist,
  forthCard,
  forthCardlist,
  fifthCard,
  fifthCardList,
  sixthCard,
  sixthCardList,
  filterData,
  setFilterData,
  filterUpdateFuc,
  filterClearAllFuc,
  sevenCard,
  eightCard,
}) {
  const { data: clientCategory } = useQuery(
    ["getExpenseCategory"],
    getExpenseCategory
  );
  const CategoryOption = clientCategory?.map((e) => ({
    value: e.id,
    label: e.expenseCategory,
  }));

  const { data: clientMonth } = useQuery(["getMonth"], getMonth);
  const MonthOption = clientMonth?.map((e) => ({
    value: e.id,
    label: e.month,
  }));

  const currentYear = new Date().getFullYear();
  const YearOption = [
    { value: currentYear, label: currentYear.toString() },
    { value: currentYear - 1, label: (currentYear - 1).toString() },
  ];

  const { data: Vendor } = useQuery(["getVendorName"], getVendorName);
  const VendorOption = Vendor?.map((e) => ({
    value: e.id,
    label: e.vendorName,
  }));

  const { data: Status } = useQuery(["getPaymentStatus"], getPaymentStatus);
  const StatusOption = Status?.map((e) => ({
    value: e.id,
    label: e.status,
  }));

  const { data: PaymentDate } = useQuery(["getPaymentDate"], getPaymentDate);
  const PaymentOption = PaymentDate?.map((e) => ({
    value: e.id,
    label: moment(e.paymentDate).format("YYYY-MM-DD"),
  }));

  const { data: paymentModeList } = useQuery(
    ["getPaymentModeList"],
    getPaymentMode
  );

  const paymentModeOption = paymentModeList?.map((item) => ({
    value: item?.id,
    label: item?.paymentMode,
  }));

  const { data: approveByList } = useQuery(["getApproveByList"], getApproveBy);

  const approveByOption = approveByList?.map((item, idx) => ({
    value: idx,
    label: item?.name,
  }));

  return (
    <>
      <Modal
        open={showFilter}
        center
        onClose={() => setShowFilter(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal overflow-visible-important", // Add your CSS class here
        }}
      >
        <div className={style.filterModal}>
          <div className={style.filterCards}>

            {nineCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{nineCard}</div>
                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                    position: "relative",
                  }}
                >
                  <Select
                    styles={filterBox1}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        expenseYear: e,
                      });
                    }}
                    options={YearOption}
                    value={filterData?.expenseYear}
                    isClearable={true}
                  />
                </div>
              </div>
            )}

            {firstCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{firstCard}</div>
                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                    position: "relative",
                  }}
                >
                  <CreatableSelect
                    styles={filterBox}
                    isMulti
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        expenseMonth: e,
                      });
                    }}
                    options={MonthOption}
                    value={filterData?.expenseMonth}
                  />
                </div>
              </div>
            )}
            {forthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{forthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                    position: "relative",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => {
                      setFilterData({
                        category: e,
                      });
                    }}
                    options={CategoryOption}
                    value={filterData?.category}
                  />
                </div>
              </div>
            )}

            {secondCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{secondCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                    position: "relative",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        vendor: e,
                      });
                    }}
                    options={VendorOption}
                    value={filterData?.vendor}
                  />
                </div>
              </div>
            )}
            {thirdCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{thirdCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                  />
                </div>
              </div>
            )}
            {fifthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{fifthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                    position: "relative",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        status: e,
                      });
                    }}
                    options={StatusOption}
                    value={filterData?.status}
                  />
                </div>
              </div>
            )}
            {sixthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{sixthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                    position: "relative",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        paymentDate: e,
                      });
                    }}
                    options={PaymentOption}
                    value={filterData?.paymentDate}
                  />
                </div>
              </div>
            )}
            {sevenCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{sevenCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                    position: "relative",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) =>
                      setFilterData({ ...filterData, approveBy: e })
                    }
                    options={approveByOption}
                    value={filterData?.approveBy}
                  />
                </div>
              </div>
            )}
            {eightCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{eightCard}</div>
                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                    position: "relative",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        paymentMode: e,
                      })
                    }
                    options={paymentModeOption}
                    value={filterData?.paymentMode}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={style.filterBtn}>
          <button
            className={style.cancelBtn}
            onClick={() => {
              filterClearAllFuc();
            }}
          >
            Clear All
          </button>
          <button className={style.createBtn} onClick={() => filterUpdateFuc()}>
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Filter;
