import React, { useContext, useEffect, useState } from "react";
import SideBar from "../Clients/component/SideBar";
import style from "./DashboardNew.module.css";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { DataContext } from "../CommonComponent/useContext/DataContext";
import HrIcon from "../../../assets/hr-icon.png";
import loaderImg from "../../../assets/loader.gif";
import ProjectWorkIcon from "../../../assets/project-work-icon.png";
import HandShakeIcon from "../../../assets/hand-shake-icon.png";
import { RiMapPinUserFill } from "react-icons/ri";
import { getTechnologyDashboardForOffshoreResources, getTechnologyDashboardForOnSiteResources, getTechnologyDashboardForPoolResources, getTechnologyDashboardOfferAcceptedCandidate } from "../../../Utils/axios/rmsApi";
import Loader from "../../../GenericComponent/Loader/Loader";
const DashboardNew = () => {
    const history = useHistory();
    const { data, setData } = useContext(DataContext);
    const [activeCard, setActiveCard] = useState(data?.RMSactiveCard ? data?.RMSactiveCard : '');
    const [displayData, setDisplayData] = useState({});
    const [cards, setCards] = useState([]);
    const [filter, setFilter] = useState({});
    const offShorePayload = {
        "action": [
            1
        ],
        "bandwidths": [],
        "empId": [],
        "projectId": [],
        "skillId": [],
        "salaryRange": [],
        "partners": [],
        "employeeStatus": [
            null,
            null
        ],
        "handleFilter": false,
        "partnerId": [],
        "isForcedAddedToPool": null
    };
    const onSitePayload = {
        "action": [
            1
        ],
        "clientId": [],
        "employeeId": [],
        "skill": [],
        "salaryRange": [],
        "partners": [],
        "startDateMonth": [],
        "endDateMonth": [],
        "employeeStatus": [
            null,
            null
        ],
        "quaterlyEndOfDeployment": [],
        "quaterlyDeployment": [],
        "handleFilter": false,
        "partnerId": [],
        "salesPersonId": [],
        "isForcedAddedToPool": null
    }
    const poolPayload = {
        "statusId": [],
        "empId": [],
        "skillId": [],
        "experienceRange": [],
        "salaryRange": [],
        "handleFilter": false,
        "partnerId": [],
        "skillOrder": null,
        "isRemovedFromPool": null
    };
    const offerAcceptedPayload = {
        "empId": 1459,
        "nameEmail": null,
        "status": [],
        "technology": []
    }
    const { data: offShoreResourcesCountData, isLoading: offShoreLoading, isError: offShoreError } = useQuery(
        ["getTechnologyDashboardForOffshoreResources"],
        () => getTechnologyDashboardForOffshoreResources(offShorePayload),
        { refetchOnWindowFocus: false }
    )
    const { data: onSiteResourcesCountData, isLoading: onSiteLoading, isError: onSiteError } = useQuery(
        ["getTechnologyDashboardForOnSiteResources"],
        () => getTechnologyDashboardForOnSiteResources(onSitePayload),
        { refetchOnWindowFocus: false }
    )
    const { data: poolResourcesCountData, isLoading: poolLoading, isError: poolError } = useQuery(
        ["getTechnologyDashboardForPoolResources"],
        () => getTechnologyDashboardForPoolResources(poolPayload),
        { refetchOnWindowFocus: false }
    )
    const { data: offerAcceptedCandidateData, isLoading: offerAcceptedCandidateLoading, isError: offerAcceptedCandidateError } = useQuery(
        ["getTechnologyDashboardOfferAcceptedCandidate"],
        () => getTechnologyDashboardOfferAcceptedCandidate(offerAcceptedPayload),
        { refetchOnWindowFocus: false }
    )
    const handleCountCardClick = (cardName) => {
        if (activeCard === cardName) {
            setActiveCard('')
        }
        else {
            setActiveCard(cardName)
        }
    }
    const handleCardClick = (id, keyName) => {
        console.log('id,name', id, keyName);
        let filter;
        if (activeCard === "offShore") {
            filter = offShorePayload;
            filter[keyName] = [id]
            setData({ ...data, resourceManagement: filter });
            history.push('/resourceManagement');
        }
        else if (activeCard === "onSite") {
            filter = onSitePayload;
            filter[keyName] = [id]
            setData({ ...data, ResourcesAllocationOnsite: filter });
            history.push('/ResourcesAllocationOnsite');
        }
        else if (activeCard === "pool") {
            filter = poolPayload;
            filter[keyName] = [id]
            setData({ ...data, PoolResources: filter });
            history.push('/PoolResources');
        }
        else {
            filter = offerAcceptedPayload;
            filter[keyName] = [id]
            setData({ ...data, offerAcceptedData: filter });
            history.push('/OfferAcceptedCandidate');
        }
    }
    useEffect(() => {
        setData({ ...data, RMSactiveCard: activeCard });
        if (activeCard === 'offShore') {
            setDisplayData({
                heading: 'Resources (Offshore)',
                name: 'skillId'
            })
            setCards(offShoreResourcesCountData?.responseData)
        }
        else if (activeCard === 'onSite') {
            setDisplayData({
                heading: 'Resources (Onsite)',
                name: 'skill'
            })
            setCards(onSiteResourcesCountData?.responseData)
        }
        else if (activeCard === 'pool') {
            setDisplayData({
                heading: 'Resources (Pool)',
                name: 'skillId'
            })
            setCards(poolResourcesCountData?.responseData)
        }
        else if (activeCard === 'offerAccepted') {
            setDisplayData({
                heading: 'Offer Accepted Candidates',
                name: 'technology'
            })
            setCards(offerAcceptedCandidateData?.responseData)
        }
        else {
            setDisplayData({})
            setCards([])
        }
    }, [activeCard]);
    return (
        <div className={style.main_client_body}>
            <SideBar />
            <div className={style.main_create}>
                {offShoreLoading || onSiteLoading || poolLoading || offerAcceptedCandidateLoading ?
                    <div>
                        <img
                            src={loaderImg}
                            alt="loader"
                            style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                        />
                        <p
                            style={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                paddingLeft: "38%",
                                position: "absolute",
                                color: "#FBBB08",
                                marginTop: "-4px",
                                fontStyle: "italic",
                                marginLeft: "34.5%",
                            }}
                        >
                            Loading...
                        </p>
                    </div> :
                    offShoreError || onSiteError || poolError || offerAcceptedCandidateError ?
                        <div className={style.error_container}>
                            <h5>Oops! Something went wrong</h5>
                        </div> :
                        <div className={style.dashboard_container}>
                            <div>
                                <p className={style.heading}>Technology  Dashboard</p>
                                <div className={style.count_card_container}>
                                    <div className={`${style.count_card} ${activeCard === 'offShore' ? style.count_card_active : ''}`}
                                        onClick={() => handleCountCardClick('offShore')}>
                                        <div className={style.count_card_upper}>
                                            <div className={style.count_card_img}>
                                                <img src={HrIcon} alt="this is img section" />
                                            </div>
                                            <h1>{offShoreResourcesCountData?.totalTechCount}</h1>
                                        </div>
                                        <div className={style.count_card_lower}>
                                            <span>Resources (Offshore)</span>
                                        </div>
                                    </div>
                                    <div className={`${style.count_card} ${activeCard === 'onSite' ? style.count_card_active : ''}`}
                                        onClick={() => handleCountCardClick('onSite')}>
                                        <div className={style.count_card_upper}>
                                            <div className={style.count_card_img}>
                                                <RiMapPinUserFill />
                                            </div>
                                            <h1>{onSiteResourcesCountData?.totalTechCount}</h1>
                                        </div>
                                        <div className={style.count_card_lower}>
                                            <span>Resources (Onsite)</span>
                                        </div>
                                    </div>
                                    <div className={`${style.count_card} ${activeCard === 'pool' ? style.count_card_active : ''}`}
                                        onClick={() => handleCountCardClick('pool')}>
                                        <div className={style.count_card_upper}>
                                            <div className={style.count_card_img}>
                                                <img src={ProjectWorkIcon} alt='Technlogy' />
                                            </div>
                                            <h1>{poolResourcesCountData?.totalTechCount}</h1>
                                        </div>
                                        <div className={style.count_card_lower}>
                                            <span>Resources (Pool)</span>
                                        </div>
                                    </div>
                                    <div className={`${style.count_card} ${activeCard === 'offerAccepted' ? style.count_card_active : ''}`}
                                        onClick={() => handleCountCardClick('offerAccepted')}>
                                        <div className={style.count_card_upper}>
                                            <div className={style.count_card_img}>
                                                <img src={HandShakeIcon} alt='Technology' />
                                            </div>
                                            <h1>{offerAcceptedCandidateData?.totalTechCount}</h1>
                                        </div>
                                        <div className={style.count_card_lower}>
                                            <span>Offer Accepted Candidates</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                activeCard !== '' &&
                                <div className={style.dashboard_container_section}>
                                    <p className={style.heading}>{displayData.heading}</p>
                                    {/* {isCardLoading ? */}
                                    {/* {false ?
                                        <div className={style.loading_container}>
                                            <p>Loading...</p>
                                        </div> :
                                        // isCardError ?
                                        false ?
                                            <div className={style.error_container}>
                                                <h5>Oops! Something went wrong</h5>
                                            </div> :
                                            } */}
                                    <div className={style.card_container}>
                                        {cards?.map(card => (
                                            <div className={style.card}
                                                onClick={() => handleCardClick(card.technologyId, displayData.name)}>
                                                <div className={style.card_upper}>
                                                    <h1>{card.countResourcesByTech}</h1>
                                                </div>
                                                <div className={style.card_lower}>
                                                    <span>{card.technology}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                }

            </div>
        </div>
    );
};
export default DashboardNew;
