import React from "react";
import style from "./Header.module.css";

import { useHistory } from "react-router-dom";

import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";

import cookie from "react-cookies";

const Header = () => {
  let history = useHistory();
  const profile = cookie.load("profile");
  return (
    <div className={style.header}>
      <img
        alt="DashBoardImage"
        src={InnovationMLogo}
        className={style.dashboardImage}
        onClick={() => history.push("/dashboard")}
      />
      <div className={style.profileSection}>
        <p className={style.username}>Hi, {profile?.name}</p>

        <img
          className={style.profileImage}
          alt={profile?.givenName}
          src={profile?.imageUrl}
        />
      </div>
    </div>
    //   <div className={style.header}>
    //   <img
    //       alt="DashBoardImage"
    //       src={DashboardImage}
    //       className={style.dashboardImage}
    //     />
    //     <img className={style.horizontalBar} src={Iconmaterialmenu} />
    //   <p>Hi, {profile.givenName}</p>
    //   <img
    //     className={style.profileImage}
    //     alt={profile.givenName}
    //     src={profile.imageUrl}
    //   />
    // </div>

    //   <div className={style.header}>
    //   <img alt="logo" src={logo} className={style.logo} />
    //   <div className={style.profileSection}>
    //     <img
    //       className={style.profileImage}
    //       alt={profile.givenName}
    //       src={profile.imageUrl}
    //     />
    //     <label className={style.logout} onClick={onLogout}>
    //       Logout
    //     </label>
    //   </div>
    // </div>
  );
};

export default Header;
