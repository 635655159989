import React from "react";
import style from "./AddPricing.module.css";
import CommonDropdown from "../../../../GenericComponent/ui/CommonDropdown";
import CommonCalender from "../../../../GenericComponent/ui/CommonCalender";
import { checkIfEmpty, onChangeHandler } from "../../../../Utils/constants";
import { useQuery } from "react-query";
import {
  getApproveByList,
} from "../../../../Utils/axios/priceRevisionApi";

const AddPricing = ({ formData, setFormData, recentDetails }) => {
  const revisedOption = [
    { id: 1, label: "Done" },
    { id: 2, label: "Defer" },
  ];

  const { data: approveByList } = useQuery(["getApproveByList"], () =>
    getApproveByList()
  );

  const approveByListOption = Array.isArray(approveByList)
    ? approveByList?.map((item) => {
      return {
        id: item?.id,
        label: item?.name,
      };
    })
    : [];

    const handleInputClick = (e) => {
      e.target.addEventListener("mousewheel", (ev) => {
        e.target.blur();
      });
    };

  return (
    <div className={style.main_div}>
      <p className={style.heading}>Additional Information</p>
      <div className={style.new_data_div}>
        <div className={style.first_div}>
          <div className={style.small_div}>
            <p className={style.para}>Last Client Rate</p>
            <input
              value={formData?.lastClientRate}
              onChange={(e) =>
                setFormData({ ...formData, lastClientRate: e?.target?.value })
              }
              className={style.input_box}
              type="number"
              onClick={handleInputClick}
            />
          </div>

          <div className={style.small_div}>
            <p className={style.para}>Current Client Rate</p>
            <input
              value={formData?.currentClientRate}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  currentClientRate: e?.target?.value,
                })
              }
              className={style.input_box}
              type="number"
              onClick={handleInputClick}
            />
          </div>

          <div className={style.small_div}>
            <p className={style.para}>Revised</p>
            <div>
              <CommonDropdown
                options={revisedOption}
                color="#00979d"
                placeholder="Revised"
                onChange={(e) =>
                  {setFormData({
                    ...formData,
                    isRevised: e?.label,
                  }); 
                  e?.label === "Defer" && setFormData({...formData,lastClientRate: recentDetails?.lastClientRate , currentClientRate: recentDetails?.currentClientRate, lastRevisionDate: recentDetails?.lastRevisionDate, isRevised: e?.label,})
                  formData?.isRevised && e?.label === "Done" && setFormData({...formData,lastClientRate: "" , currentClientRate: "", lastRevisionDate: "", isRevised: e?.label,})
                }}
                value={checkIfEmpty(formData?.isRevised, {
                  label: formData?.isRevised,
                })}
                customInputOnClick={() =>
                  setFormData({ ...formData, isRevised: "", lastClientRate:"",currentClientRate:"",lastRevisionDate:"" })
                }
              />
            </div>
          </div>

          <div className={style.small_div}>
            <p className={style.para}>Approved By</p>
            <div>
              <CommonDropdown
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    approveBy: e?.label,
                    approvedById: e?.id,
                  })
                }
                color="#00979d"
                placeholder="Approved By"
                value={checkIfEmpty(formData?.approveBy, {
                  id: formData?.approvedById,
                  label: formData?.approveBy,
                })}
                options={approveByListOption}
                customInputOnClick={() =>
                  setFormData({ ...formData, approveBy: "", approvedById: "" })
                }
              />
            </div>
          </div>
        </div>

        <div className={`${style.first_div} ${style.margin_top}`}>

          {/* <div className={style.small_div}>
            <p className={style.para}>Contract Type</p>
            <div>
              <CommonDropdown
                color="#00979d"
                placeholder="Contract Type"
                options={contractTypeListOption}
                value={checkIfEmpty(formData?.contractType, {
                  id: formData?.contractTypeId,
                  label: formData?.contractType,
                })}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    contractType: e?.label,
                    contractTypeId: e?.id,
                  })
                }
                customInputOnClick={() =>
                  setFormData({
                    ...formData,
                    contractType: "",
                    contractTypeId: "",
                  })
                }
              />
            </div>
          </div> */}

          <div className={style.small_div}>
            <p className={style.para}>Last Revision Date</p>
            <div className={style.input_box}>
              <CommonCalender
                value={formData?.lastRevisionDate}
                formData={formData}
                setFormData={setFormData}
                icon="cc"
                onCross="lastRevisionDate"
                onChange={(date) => {
                  onChangeHandler(
                    formData,
                    setFormData,
                    date,
                    "lastRevisionDate",
                    "date"
                  );
                }}
              />
            </div>
          </div>
          <div className={style.small_div}>
            <p className={style.para}>Review Date</p>
            <div className={style.input_box}>
              <CommonCalender
                value={formData?.reviewDateManually}
                formData={formData}
                setFormData={setFormData}
                icon="cc"
                onCross="reviewDate"
                onChange={(date) => {
                  onChangeHandler(
                    formData,
                    setFormData,
                    date,
                    "reviewDateManually",
                    "date"
                  );
                }}
              />
            </div>
          </div>
          <div className={style.small_div}>

          </div>


          <div className={style.small_div}>

          </div>
        </div>

        <div className={style.second_div}>
          <p className={style.para2}>Reason</p>
          <textarea
            onChange={(e) =>
              setFormData({ ...formData, reason: e?.target?.value })
            }
            rows={5}
            className={style.textarea}
          />
        </div>
      </div>
    </div>
  );
};

export default AddPricing;
