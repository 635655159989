import Modal from "react-responsive-modal";

export const ConfirmRMSPopup = ({
  modalIsOpen,
  setModalIsOpen,
  text,
  confirmFunction,
  cancelFunction,
  name,
}) => {
  return (
    <Modal
      open={modalIsOpen}
      center
      onClose={() => setModalIsOpen(false)}
      showCloseIcon={false}
    >
      <div
        style={{ fontSize: "13px", fontWeight: "400", fontFamily: "Poppins" }}
      >
        {name ? (
          <span
            style={{
              fontSize: "13px",
              fontWeight: "400",
              fontFamily: "Poppins",
            }}
          >
            <div>
              {text.split(",")[0]}{" "}
              <span style={{ fontWeight: "500", fontSize: "13px" }}>
                {name}
              </span>
            </div>
            <div style={{ textAlign: "center" }}>{text.split(",")[1]}</div>
          </span>
        ) : (
          text
        )}
      </div>
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <button
          onClick={() => confirmFunction()}
          style={{ marginRight: "1rem" }}
        >
          Yes
        </button>
        <button onClick={() => cancelFunction()}>No</button>
      </div>
    </Modal>
  );
};
