import React from "react";
import style from "./imageProfile.module.css";
import ConfirmPopup from "../viewUser/ConfirmPopup";
export const ImageProfileView = ({ show, image, okaybtn }) => {
  return (
    <>
      <ConfirmPopup open={show}>
        <div className={style.confirmPopup}>
          <div className={style.image}>
            <img
              id="image"
              src={image}
              alt="Userprofileimage"
              style={{
                borderRadius: "50%",
                width: "11rem",
                height: "11rem",
                border: "1px solid black",
              }}
            />
          </div>
          <div className={style.btn}>
            <button onClick={okaybtn} className={style.mark_btn}>
              Ok
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </>
  );
};
