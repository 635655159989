import React from "react";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory, history } from "react-router-dom";
import constants, { employeeId } from "../../../../Utils/constants";

const EditTask = () => {
  //  history.push("/viewTask");
  const url2 = constants.apiBaseUrl2;

  axios
    .post(url2 + "/editTask", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: employeeId,
      },
    })

    .then(() => {})
    .catch((err) => {
      return err;
    });
};

export default EditTask;
