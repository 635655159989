import React from "react";
import style from "./SideBar.module.css";
import one from "../../../assets/businessman-gear-icon.png";
import oneG from "../../../assets/businessman-gear-icon_GREEN.png";
import { useHistory } from "react-router-dom";
import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";
const SideBar = ({
  sidebarHandler,
  nextScreen,
  setPrevData,
  prevData,
  setCurrentPage,
  roles,
}) => {
  const history = useHistory();
  return (
    <div className={style.leftSection}>
      <div className={style.header}>
        <img
          alt="DashBoardImage"
          src={InnovationMLogo}
          className={style.dashboardImage}
          onClick={() => history.push("/dashboard")}
        />
      </div>
      {/* 1 */}
      <button
        onClick={() => {
          history.push("/PortalManagement");
          sidebarHandler("PartnerList");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "PartnerList",
            page: "PartnerList",
            page1: "PartnerList",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "PartnerList",
          });
        }}
        className={
          prevData?.sidebar === "PartnerList"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "PartnerList" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Partner Management</span>
        </div>
      </button>

      <button
        onClick={() => {
          history.push("/PortalManagement");
          sidebarHandler("clientList");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "clientList",
            page: "clientList",
            page1: "clientList",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "clientList",
          });
        }}
        className={
          prevData?.sidebar === "clientList"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "clientList" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Client Management</span>
        </div>
      </button>

      <button
        onClick={() => {
          history.push("/PortalManagement");
          sidebarHandler("report");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "report",
            page: "report",
            page1: "report",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "report",
          });
        }}
        className={
          prevData?.sidebar === "report" ? style.selected : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "report" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Partner Report</span>
        </div>
      </button>

      <button
        onClick={() => {
          history.push("/PortalManagement");
          sidebarHandler("activePartnerDeployment");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "activePartnerDeployment",
            page: "activePartnerDeployment",
            page1: "activePartnerDeployment",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "activePartnerDeployment",
          });
        }}
        className={
          prevData?.sidebar === "activePartnerDeployment"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "activePartnerDeployment" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Partner - Active Deployment</span>
        </div>
      </button>

      <button
        onClick={() => {
          sidebarHandler("projectManagement");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "projectManagement",
            page: "projectManagement",
            page1: "projectManagement",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "projectManagement",
          });
        }}
        className={
          prevData?.sidebar === "projectManagement"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "projectManagement" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Project Management</span>
        </div>
      </button>

      <button
        onClick={() => {
          sidebarHandler("projectLead");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "projectLead",
            page: "projectLead",
            page1: "projectLead",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "projectLead",
          });
        }}
        className={
          prevData?.sidebar === "projectLead"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "projectLead" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Partner Lead</span>
        </div>
      </button>


    </div>
  );
};

export default SideBar;
