import React, { useState } from "react";
import Sidebar from "../../Clients/component/SideBar";
import Header from "../../../taskTracker/commonComponent/Header";
import style from "./EditTask.module.css";
import EditTaskComponent from "./component/EditTaskComponent";

const EditTaskRMS = (props) => {
  const [taskData, settaskData] = useState(
    props.history?.location?.state?.task
  );
  const [returnPage, setReturnPage] = useState(
    props.history?.location?.state?.activePage
  );

  return (
    <div>
      <Header />

      <div className={style.mainSection}>
        <Sidebar />

        <div className={style.rightSection}>
          <EditTaskComponent taskData={taskData} returnPage={returnPage} />
          {/* <Filter /> */}
        </div>
      </div>
    </div>
  );
};

export default EditTaskRMS;
