import React, { useEffect, useContext, useState } from "react";
import style from "./ProjectDetails.module.css";
import { Table } from "react-bootstrap";
import TableRow from "./TableRow";
import { DataContext } from "../CommonComponent/useContext/DataContext";
import SideBar from "../Clients/component/SideBar";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Link } from "react-router-dom";
import rightTickPRM from "../../../assets/rightTickPRM.png";
import searchIcon from "../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import Pagination from "../CommonComponent/Pagination";
import Filter from "../components/Filter";
import milestone from "../../../assets/milestone.png";
import loaderImg from "../../../assets/loader.gif";
import { api } from "../CommonApi/api";
import { get, post } from "../CommonApi/axiosCall";
import { rmsAccessCode } from "../../../Utils/constants";
import useValidationAccess from "../../custumHooks/useValidationAccess";
import Select from "react-select";
import { customSearch } from "../Clients/component/style";



export default function ProjectDetails() {

  const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;
  const { checkValidateAccess } = useValidationAccess();
  const [blockProject, setBlockProject] = useState([]);
  const [blockClientList, setBlockClientList] = useState([]);
  const { data, setData } = useContext(DataContext);
  const [projectData, setProjectsList] = useState([]);
  const [projectCategoryForFilter, setProjectCategoryForFilter] = useState([]);
  const [projectForFilter, setProjectForFilter] = useState([]);
  const [clientForFilter, setClientForFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProject, settotalProject] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [applied, setApplied] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [projectCategory, setProjectCategory] = useState([]);
  const [client, setClient] = useState([]);
  const [project, setProject] = useState();
  const [filterName, setFilterName] = useState({});
  const [allData, setAllData] = useState(
    data?.ProjectDetails
      ? data?.ProjectDetails
      : {
        catogaryprojectId: [],
        clientId: [],
        statusTypeId: [],
        projectId: [],
        searchName: "",
        handleFilter: false,
      }
  );

  const getBlockResourcesClient = () => {
    // let baseURL = api.getBlockResourcesClientList;
    get(`${baseUrl}/get-clients-for-filter`)
      .then((res) => {
        setBlockClientList(res.data.responseData);
      })
      .catch(() => { });
  };

  const getOffshoreProject = () => {
    let baseURL = api.getBlockResourcesProjectList1;
    get(baseURL)
      .then((res) => {
        setBlockProject(res.data.responseData);
      })
      .catch((err) => { });
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const [FilterData] = useState({
    catogaryprojectId: [],
    clientId: [],
    projectId: [],
    statusTypeId: [],
  });

  projectForFilter?.push(...clientForFilter);

  const getClientForFilter = () => {
    let baseURL = api.searchClientandProject;

    get(baseURL)
      .then((res) => {
        setClientForFilter(res.data.responseData);
      })
      .catch((err) => { });
  };

  const getProjectTypeForFilter = () => {
    let baseURL = api.getProjectTypes;

    get(baseURL)
      .then((res) => {
        setProjectCategoryForFilter(res.data.responseData);
      })
      .catch((err) => { });
  };

  const getProjectForFilter = () => {
    let baseURL = api.getFilteredProject;

    get(baseURL)
      .then((res) => {
        setProjectForFilter(res.data.responseData);
      })
      .catch((err) => { });
  };

  const getProjectData = (clearData, currentPage) => {
    const filterData = clearData?.clientId?.length === 0 ? clearData : allData;
    setIsLoaded(true);

    let baseURL = api.getProjectList;
    if (limit) {
      baseURL += `?limit=${limit}`;
    }
    if (currentPage) {
      baseURL += `&page=${currentPage}`;
    }

    post(baseURL, filterData)
      .then((res) => {
        setIsLoaded(false);
        setProjectsList(res.data.responseData);
        settotalProject(res.data.responseData.totalproject);
        setpageCount(res.data.responseData.totalproject / 10);
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  };

  const limit = 10;
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Name":
          setAllData({ ...allData, searchName: e.target.value });
          setFilterName({ ...filterName, positionCode: e.target.value });
          break;
        default:
      }
    }
  };

  const closeFilter = () => {
    setAllData({ ...allData, handleFilter: false });

    setCurrentPage(1);
    setAllData((preValue) => {
      return {
        ...preValue,
        catogaryprojectId: [],
        clientId: [],
        projectId: [],
        statusTypeId: [],
      };
    });
    getProjectData(FilterData, 1);
    setProjectCategory([]);
    setClient([]);
    setProject([]);
    setShowFilter(false);
    setApplied(false);
  };
  useEffect(() => {
    setData({ ...data, ProjectDetails: allData });
  }, [allData]);

  useEffect(() => {
    if (data?.ProjectDetails?.handleFilter) {
      setApplied(true);
    }
    getOffshoreProject();
    getBlockResourcesClient();
    getProjectTypeForFilter();
    getClientForFilter();
    getProjectForFilter();
  }, []);
  const handleApplied = (arg) => {
    setAllData({ ...allData, handleFilter: true });

    if (projectCategory?.length !== 0 || client?.length !== 0) {
      setApplied(true);
    }
    setShowFilter(false);
    setCurrentPage(1);
    getProjectData(allData, 1);
  };

  useEffect(() => {
    getProjectData(allData, currentPage);
  }, [currentPage, allData]);

  const getClientListForFilter = blockClientList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getProjectListForFilter = blockProject?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getLabelFromValue = (value) => {
    const option = getProjectListForFilter.find(option => option.value === value);
    return option ? option.label : '';
  };

  const getLabelFromValue1 = (value) => {
    const option = getClientListForFilter.find(option => option.value === value);
    return option ? option.label : '';
  };

  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />
        {/* Table Div Content */}
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "700", width: "9%" }}
              >
                Projects
              </div>
              <div style={{ width: "15%" }}>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {totalProject}
                  </span>
                </p>
              </div>
              <div style={{ width: "31%", paddingLeft: "14%" }}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img className={style.appliedcheck} src={rightTickPRM} />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>
              <div
                className={style.s1}
                style={{ width: '17%' }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.projectId.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAllData({
                        ...allData,
                        projectId: [],
                      });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Project"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    allData.projectId.length === 0
                      ? null
                      : {
                        value: allData.projectId[0],
                        label: getLabelFromValue(allData.projectId[0]),
                      }
                  }
                  onChange={(e) => {
                    setAllData({
                      ...allData,
                      projectId: [e.value],
                    });
                    onSearchHandler(e, "Code2");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getProjectListForFilter}
                />
              </div>
              <div
                className={style.s1}
                style={{ width: "17%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.clientId.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="img" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        clientId: [],
                      });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Client"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    allData.clientId.length === 0
                      ? null
                      : {
                        value: allData.clientId[0],
                        label: getLabelFromValue1(allData.clientId[0]),
                      }
                  }
                  onChange={(e) => {
                    setAllData({
                      ...allData,
                      clientId: [e.value],
                    });
                    onSearchHandler(e, "Code3");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getClientListForFilter}
                />
              </div>
              {/* <div
                className={style.s1}
                style={{ width: "27%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
                >
                {allData?.searchName?.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        searchName: "",
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <input
                  placeholder="Client/Project Name"
                  value={allData?.searchName}
                  onChange={(e) => {
                    onSearchHandler(e, "Name");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  className={style.coreSkillInput}
                />
              </div> */}
              {checkValidateAccess(rmsAccessCode?.create_project) && (
                <div className={style.btn_add}>
                  <Link to="/projects" style={{ textDecoration: "none" }}>
                    <button className={style.create_btn}>
                      <span className={style.plus}>+</span>Create Project
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.original_table}
            style={{ backgroundColor: "white", minHeight: "27.5rem" }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : projectData.projects?.length > 0 ? (
              <table style={{ fontSize: "12px", border: "none" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "150px" }}>
                    <th style={{ padding: "10px" }}>Client Name</th>
                    <th style={{ padding: "10px" }}>Project Name</th>
                    <th style={{ padding: "10px" }}>PM/PC</th>
                    <th style={{ padding: "10px" }}>Start Date</th>
                    <th style={{ padding: "10px" }}>Type of Project</th>
                    <th style={{ padding: "10px" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {projectData?.projects.map((val) => {
                    return <TableRow enddate={val.enddate} val={val} />;
                  })}
                </tbody>
              </table>
            ) : (
              <div className={style.noData}>
                <img src={milestone} alt="milestone" />
                <h3 className={style.noData_header}>No data available</h3>
              </div>
            )}
          </div>
          {/* <Link to="/clientdescription">Click</Link> */}
          {totalProject > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          type={"project"}
          clientListProject={clientForFilter}
          projectList={projectForFilter}
          projectCategoryList={projectCategoryForFilter}
          setProject={setProject}
          setClient={setClient}
          setProjectCategory={setProjectCategory}
        />
      )}
    </>
  );
}
