import React, { useEffect, useState } from "react";
import Header from "../../../../taskTracker/commonComponent/Header";
import style from "./OffshoreHistory.module.css";
import { useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";
import TableRow from "./TableRow";
import SideBar from "../../../Clients/component/SideBar";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom";
import Pagination from "../../../CommonComponent/Pagination";
import loaderImg from "../../../../../assets/loader.gif";
import { api } from "../../../CommonApi/api";
import { get } from "../../../CommonApi/axiosCall";

export default function OffshoreHistory() {
  const history = useHistory();
  const [HistoryData, sethistoryList] = useState([]);
  const [projectCategoryForFilter, setProjectCategoryForFilter] = useState([]);
  const [projectForFilter, setProjectForFilter] = useState([]);
  const [clientForFilter, setClientForFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProject, settotalProject] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const [projectCategory, setProjectCategory] = useState([]);
  const [client, setClient] = useState([]);
  const [project, setProject] = useState([]);
  const [filterName, setFilterName] = useState({});
  const [searchData, setSearchData] = useState();
  const [allData, setAllData] = useState({
    catogaryprojectId: projectCategory,
    clientId: client,
    projectId: project,
    searchName: "",
  });

  const [FilterData, setFilterData] = useState({
    catogaryprojectId: [],
    clientId: [],
    projectId: [],
  });
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get("id");

  projectForFilter?.push(...clientForFilter);

  const goBack = () => {
    history.goBack(-1);
  };

  const getClientForFilter = () => {
    let url = api.getClientDataForFilterOfProject;

    get(url)
      .then((res) => {
        setClientForFilter(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getProjectTypeForFilter = () => {
    let url = api.getProjectTypes;
    get(url)
      .then((res) => {
        setProjectCategoryForFilter(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getProjectForFilter = () => {
    let url = api.getFilteredProject;

    get(url)
      .then((res) => {
        setProjectForFilter(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getProjectData = (clearData, currentPage) => {
    let url = api.getOffshoreResourceHistoryDetails;
    if (limit) {
      url += `?limit=${limit}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}`;
    }
    if (id) {
      url += `&resourceId=${id}`;
    }

    setIsLoaded(true);

    get(url)
      .then((res) => {
        sethistoryList(res.data.responseData);
        settotalProject(res.data.responseData.resourceCount);
        setpageCount(~~(res.data.responseData.resourceCount / 10 + 1));
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);
      });
  };

  const limit = 10;
  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  useEffect(() => {
    getProjectData(allData, currentPage);
    getProjectTypeForFilter();
    getClientForFilter();
    getProjectForFilter();
  }, [currentPage, allData]);

  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <SideBar />
        {/* Table Div Content */}
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "700", width: "30%" }}
              >
                View Offshore Resource History
              </div>
              <span
                className={style.back_btn}
                style={{ cursor: "pointer", marginLeft: "39rem" }}
                onClick={goBack}
              >
                <ArrowBackIcon />
                Back
              </span>
            </div>
          </div>

          <div
            className={style.original_table}
            style={{ backgroundColor: "white", height: "27.5rem" }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : HistoryData.getAllData ? (
              <Table borderless size="sm" style={{ fontSize: "12px" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr >
                    <th style={{ paddingBottom: "10px" ,backgroundColor:"rgb(240, 240, 240)" }}>Date Modified</th>
                    <th style={{ paddingBottom: "10px" ,backgroundColor:"rgb(240, 240, 240)" }}>Change By</th>
                    <th style={{ paddingBottom: "10px" ,backgroundColor:"rgb(240, 240, 240)" }}>Change Requested By </th>
                    <th style={{ paddingBottom: "10px" ,backgroundColor:"rgb(240, 240, 240)" }}>Fields</th>
                    <th style={{ paddingBottom: "10px" ,backgroundColor:"rgb(240, 240, 240)" }}>Changes</th>
                  </tr>
                </thead>
                <tbody>
                  {HistoryData?.getAllData.map((val) => {
                    return (
                      <TableRow
                        modifiedDate={val.modifiedDate}
                        changeBy={val.changeBy}
                        fields={val.fields}
                        changedDescription={val.changedDescription}
                        resourceId={val.resourceId}
                        changeReqBy={val.changeRequestedBy}
                      />
                    );
                  })}
                </tbody>
              </Table>
            ) : null}
          </div>
          {totalProject > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
