import * as XLSX from "xlsx";
import React from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import { downloadEmployeeBgvList } from "../../../../Utils/axios/umsApi";
import { useQuery } from "react-query";

import Loader from "../../../../GenericComponent/Loader/Loader";
const BGVReportExcelFileDownload = ({ filter }) => {
  const {isLoading, refetch } = useQuery(
    ["downloadEmployeeBgvList", { filter }],
    () => downloadEmployeeBgvList(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        handleDownloadButtonClick(res);
      },
    }
  );

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
"Employee Name",
"Employee Code",
"Company Name",
"Status",
"Positive/Negative",
"Upload Document",
"Comments",
"BGV Applicable",       
      ], // Header row
      ...data.map((val) => [
        val?.empName,
        val?.empCode,
        val?.companyName,
        val?.bgvstatus,
        val?.feedbackStatus? "Positive":val?.feedbackStatus===false ? "Negative":"",
        val?.bgvDocUrl,
        val?.comments,
        val?.bgvApplicable,
      ]), // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);
    downloadExcelFile(workbook);
  }
  return (
    <div>
    {isLoading && <Loader />}   
      <FiDownload
        style={{ verticalAlign: "baseline", cursor: "pointer",fontSize:"2rem",color: "#289e98" }}
        onClick={() => refetch()}
      />
    </div>
  );
};

export default BGVReportExcelFileDownload;
