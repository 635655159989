import React, { useState } from "react";
import SideBar from "../../sidebar/SideBar";
import style from "./ExitedEmployeeList.module.scss";
import { useMutation, useQuery } from "react-query";
import {
  editExitComment,
  getAllType,
  getLastThreeMonthExited,
} from "../../../../Utils/axios/umsApi";
import moment from "moment";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import { useHistory } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import ConfirmPopup from "../viewUser/ConfirmPopup";
import { ToastContainer } from "react-toastify";
import { getLastThreeMonths } from "../../../../Utils/constants";
import CommonDropdown from "../../../../GenericComponent/ui/CommonDropdown";
import { path } from "../../../../routes/PathNames";
import TotalCountUMS from "../commonComponent/TotalCountUMS";

const ExitedEmployeeList = () => {
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [empId, setEmpId] = useState(null);
  const [filter, setFilter] = useState({
    currentPage: 1,
  });
  const { data, isLoading, isError } = useQuery(
    ["getLastThreeMonthExited", filter],
    () => getLastThreeMonthExited(filter?.currentPage, filter?.month, filter?.typeId)
  );
  const history = useHistory();
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setFilter({ ...filter, currentPage: filter?.currentPage + 1 });
    } else if (status === "prev") {
      setFilter({ ...filter, currentPage: filter?.currentPage - 1 });
    }
  };
  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push(path.ums_userDescription, { id });
    }, 0);
  };
  const mutation = useMutation(editExitComment, {
    onSuccess: (res) => { },
    onError: () => { },
  });

  const { data: getAllTypeData } = useQuery(["getAllType"], getAllType, {
    refetchOnWindowFocus: false
  });
  const getAllTypeOption = getAllTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  })) ?? [];

  return (
    <>
      <ToastContainer />
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.topFlex}>
            <div className={style.title}>
              <div>Exited Employees</div>
              <TotalCountUMS totalCount={data?.allCount} />
            </div>
            <div className={style.containerss}>
              <div className={style.dropdownContainer1} style={{display: "block", width: "15rem"}}>
                <CommonDropdown
                  color="#289e98"
                  value={
                    getAllTypeOption.find((e) => e.value === filter?.typeId) ?? ""
                  }
                  onChange={(selectedOption) =>
                    setFilter({
                      ...filter,
                      typeId: selectedOption.value,
                      currentPage: 1,
                    })
                  }
                  options={getAllTypeOption}
                  placeholder="Select Type"
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      typeId: "",
                    })
                  }

                />

              </div>
              <div className={style.dropdownContainer1} style={{display: "block", width: "15rem"}}>
                <CommonDropdown
                  color="#289e98"
                  value={
                    getLastThreeMonths().find(
                      (month) => month.id === filter.month
                    ) ?? ""
                  }
                  onChange={(selectedOption) =>
                    setFilter({
                      ...filter,
                      month: selectedOption.id,
                      currentPage: 1,
                    })
                  }
                  options={getLastThreeMonths()}
                  placeholder="Select Month"
                  customInputOnClick={() =>
                    setFilter({
                      ...filter,
                      month: "",
                    })
                  }
                />
              </div>
            </div>

          </div>
          <div className={style.UsersList}>
            <div className={style.th}>
              <p>Emp Code</p>
              <p>Name</p>
              <p>Resignation Date</p>
              <p>Exit Date</p>
              <p></p>
            </div>
            {isLoading && <div style={{ textAlign: "center" }}>Loading...</div>}
            {isError && (
              <div style={{ textAlign: "center" }}>Something Went Wrong</div>
            )}
            {!isLoading && !isError ? (
              data?.employeeResponseModels?.length > 0 ? (
                data?.employeeResponseModels?.map((e, key) => {
                  return (
                    <div className={style.td} key={e.id}>
                      <p onClick={() => handleRedirect(e.empId)}>{e?.code}</p>
                      <p>{e?.name}</p>
                      <p>
                        {e?.resignationDate &&
                          moment(e?.resignationDate).format("DD MMM YY")}
                      </p>
                      <p>
                        {e?.exitPlanDate &&
                          moment(e?.exitPlanDate).format("DD MMM YY")}
                      </p>
                      <p
                        onClick={() => {
                          setShowNotes(true);
                          setNotes(e?.exitComments);
                          setEmpId(e?.empId);
                        }}
                      >
                        {e?.exitComments && (
                          <CgNotes style={{ color: "#289e98" }} />
                        )}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div style={{ textAlign: "center" }}>No Data</div>
              )
            ) : null}
          </div>

          <ConfirmPopup open={showNotes}>
            <div className={style.confirmPopup}>
              <div>
                <textarea
                  type="textarea"
                  className={style.textarea}
                  value={notes}
                  onChange={(e) => setNotes(e?.target.value)}
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <button
                  className={style.delete_btn}
                  onClick={() => setShowNotes(false)}
                >
                  Cancel
                </button>
                <button
                  className={style.delete}
                  onClick={() => {
                    mutation.mutate({
                      empId: empId,
                      exitComments: notes,
                    });
                    setShowNotes(false);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </ConfirmPopup>
          <div style={{ marginRight: "29px" }}>
            <Pagination
              pagesCount={data?.allCount / 10}
              onPageChange={handlePageChange}
              currentPage={filter?.currentPage}
              color="#289e98"
              color2="white"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExitedEmployeeList;
