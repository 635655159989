import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import BreadCrum from "../../../breadcrums/BreadCrum";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import Select from "react-select";
import calender from "../../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import { InvoiceStyles } from "../../../utils/style";
import dropdownIcon from "../../../../../assets/downIcon.png";
import { FaTrash } from "react-icons/fa";
import cancel from "../../../../../assets/cancell.png";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import style from "./ViewInvoiceListData.module.css";
import { getInvoiceId } from "../../../../../Utils/axios/expenceApi.js";
import { useQuery } from "react-query";
import moment from "moment";

const ViewInvoiceListData = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [lineItemArray, setLineItemArray] = useState([1]);
  const [formData, setFormData] = useState({
    lineItem: [
      {
        candidateName: "",
        invoiceMonth: "",
        invoiceYear: "",
        billingRate: "",
        workingDays: "",
        maxWorkingDay: "",
        baseAmount: "",
      },
    ],
  });

  const {
    data: InvoiceDataById,
    isLoading,
    isError,
  } = useQuery(["getInvoiceId"], () => getInvoiceId(prevData?.invoiceId), {
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 1000,
  });

  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      {/*Copy Invoice*/}
      <InvoiceContainer>
        {isLoading && <p className={style.no_data}>Loading .....</p>}

        <div className={style.copy_expence}>Copy Invoice </div>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>Copy Invoice?</div>
            <div className={style.radioitem}>
              <p className={style.values}>Yes</p>
            </div>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Client Name</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.clientName}
            </p>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
      </InvoiceContainer>
      {/*Invoice Details*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Invoice Details</div>
        {/*row 1*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Project Type</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.projectType}
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>Client Name</p>
            <p className={style.values}>InnovatioM Pvt Ltd</p>
          </div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        {/*row 2*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Invoice No</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.invoiceNumber}
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>Invoice Date </p>
            <p className={style.values}>
              {moment(InvoiceDataById && InvoiceDataById.invoiceDate).format(
                "DD MMM YY"
              )}
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>Due Date</p>
            <p className={style.values}>
              {moment(InvoiceDataById && InvoiceDataById.dueDate).format(
                "DD MMM YY"
              )}
            </p>
          </div>
          <div className={style.col4}>
            <p className={style.label}>Advance Status</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.advanceStatus}
            </p>
          </div>
        </div>
        <div className={style.border}></div>
        {/*row 3*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Currency</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.currency}
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>Conversion Rate </p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.conversionRate}
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>GSTN</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.gstNumber}
            </p>
          </div>
          <div className={style.col4}></div>
        </div>
        {/*row 4*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Invoice Amount (Base)</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.baseAmountInvoice}
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>Invoice Amount (Base in INR) </p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.baseAmountINR}
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>Total Invoice Amount</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.totalInvoiceAmount}
            </p>
          </div>
          <div className={style.col4}></div>
        </div>
        <div className={style.border}></div>
        {/*row 5*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>IGST</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.igst}
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>CGST </p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.cgst}
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>SGST</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.sgst}
            </p>
          </div>
          <div className={style.col4}>
            <p className={style.label}>TDS</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.tds}
            </p>
          </div>
        </div>
        {/*row 6*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Amount to be Received</p>
            <p className={style.values}>
              {InvoiceDataById && InvoiceDataById.amountToBeReceived}
            </p>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
      </InvoiceContainer>
      {/* Line Item Details Container */}
      <InvoiceContainer>
        <div className={style.copy_expence}>Line Item Details</div>
        {/*row 1*/}
        {/* {InvoiceDataById?.invoiceLineItemDetails?.map((item, index) => {
          return (
            <>
              <div className={style.Container} key={index}>
                <div className={style.col12_item}>
                  <p className={style.label_item}>Candidate Name</p>
                  <p className={style.values}>5234523</p>
                </div>
                <div className={style.col2_item}>
                  <p className={style.label_item}>Invoice Month</p>
                  <p className={style.values}>5234523</p>
                </div>
                <div className={style.col3_item}>
                  <p className={style.label_item}>Invoice Year</p>
                  <p className={style.values}>5234523</p>
                </div>
                <div className={style.col4_item}>
                  <p className={style.label_item}>Billing Rate</p>
                  <p className={style.values}>5234523</p>
                </div>
                <div className={style.col5_item}>
                  <p className={style.label_item}>Working Days</p>
                  <p className={style.values}>5234523</p>
                </div>
                <div className={style.col6_item}>
                  <p className={style.label_item}>Max Working Days</p>
                  <p className={style.values}>5234523</p>
                </div>
                <div className={style.col7_item}>
                  <p className={style.label_item}>Base Amount</p>
                  <p className={style.values}>5234523</p>
                </div>
              </div>
            </>
          );
        })} */}

        <div className={style.table}>
          <div className={style.th}>
            <p className={style.tableContent}>Candidate Name</p>
            <p className={style.tableContent}>Invoice Month</p>
            <p className={style.tableContent}>Invoice Year</p>
            <p className={style.tableContent}>Billing Rate</p>
            <p className={style.tableContent}>Working Days</p>
            <p className={style.tableContent}>Max Working Days</p>
            <p className={style.tableContent}>Base Amount</p>
          </div>
          <div>
            {isLoading && <p className={style.no_data}>Loading .....</p>}

            {!isLoading && !isError ? (
              InvoiceDataById?.invoiceLineItemDetails?.length > 0 ? (
                typeof data !== "string" &&
                InvoiceDataById?.invoiceLineItemDetails?.map((item) => {
                  return (
                    <div className={style.td} key={item?.positionId}>
                      <p className={style.tableContent1}>{item?.projectType}</p>
                      <p className={style.tableContent}>
                        {item?.candidateName}
                      </p>

                      <p className={style.tableContent}>{item.invoiceMonth}</p>

                      <p className={style.tableContent}>{item.invoiceYear}</p>

                      <p className={style.tableContent}>{item.billingRate}</p>
                      <p className={style.tableContent}>{item.workingDays}</p>
                      <p className={style.tableContent}>{item.maxWorkingDay}</p>
                      <p className={style.tableContent}>{item.baseAmount}</p>
                    </div>
                  );
                })
              ) : (
                <p className={style.no_data}>No Data Found</p>
              )
            ) : null}
          </div>
        </div>

        {/* <div className={style.lastBtn_item}>
          <InvoiceButton
            onClick={() => {
             
              setLineItemArray([
                ...lineItemArray,
                Math.floor(Math.random() * 100),
              ]);
              setFormData({
                ...formData,
                lineItem: [
                  ...formData?.lineItem,
                  {
                    candidateName: "",
                    invoiceMonth: "",
                    invoiceYear: "",
                    billingRate: "",
                    workingDays: "",
                    maxWorkingDays: "",
                    baseAmount: "",
                  },
                ],
              });
            }}
          >
            Add
          </InvoiceButton>
        </div> */}
      </InvoiceContainer>
      <div className={style.lastBtn}>
        {/* <InvoiceButton
          type={"cancel"}
          onClick={() => {
            setNextScreen("invoiceList");
            setPrevData((prevData) => ({
              ...prevData,
              page2: null,
              page3: null,
              page4: null,
              active: "invoiceList",
            }));
          }}
        >
          Cancel
        </InvoiceButton> */}
        <InvoiceButton
          onClick={() => {
            setNextScreen("editInvoice");
            setPrevData((prevData) => ({
              id: prevData?.invoiceId,
              ...prevData,
              page2: "editInvoice",
              page3: null,
              page4: null,
              active: "editInvoice",
              EditinvoiceId: prevData,
              clientAcknowledgementStatus:
                InvoiceDataById && InvoiceDataById.clientAcknowledgementStatus,
              bankReferenceId:
                InvoiceDataById && InvoiceDataById.bankReferenceId,
              amountReceivedInBank:
                InvoiceDataById && InvoiceDataById.amountReceivedInBank,
              reimbursableExpenses:
                InvoiceDataById && InvoiceDataById.reimbursableExpenses,
            }));
          }}
        >
          Edit
        </InvoiceButton>
      </div>
    </>
  );
};

export default ViewInvoiceListData;
