import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../ViewPartner/ViewPartner.module.css";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton1";
import { validateEmployeeAccess } from "../../../../Utils/axios/PortalManagement";
import { useQuery } from "react-query";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import { employeeId } from "../../../../Utils/constants";
import CopyEmail from "../../../../GenericComponent/CopyEmailFeature/CopyEmail";

const ViewCreateVendor = ({ setNextScreen, prevData, setPrevData }) => {
  const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;
  const [PartnerDataById, setPartnerDataById] = useState();

  const getClientDetails = (id) => {
    axios
      .get(baseUrl + `/get-vender-by-id?venderId=${prevData?.CreateId}`, {})
      .then((res) => {
        setPartnerDataById(res.data.responseData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getClientDetails(prevData?.CreateId);
  }, []); // Empty dependency array to run only once on mount

  const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(employeeId)),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const getVData =
    getValidateEmp &&
    getValidateEmp.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));

  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
      default:
        return;
    }
  };

  return (
    <>
      <ToastContainer />
      <Container>
        <div>
          <BreadCrum
            prevData={prevData}
            setPrevData={setPrevData}
            setNextScreen={setNextScreen}
          ></BreadCrum>
        </div>
        <InvoiceContainer>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Vendor Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.venderName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Address</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.addressLine1}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>City</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.city}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>State</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.state}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Pin</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.pinCode}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Country</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.country}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>GSTIN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.gstin}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>PAN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.pan}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Payment Due Days</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.pamentDueDays}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>SLA</p>
              <p className={classes.partnerlabal}>
                {checkfileType(PartnerDataById?.sla?.split(".")[5])}
                <a
                  className={classes.msaopen}
                  href={PartnerDataById?.sla}
                  target="_blank"
                  rel="noreferrer"
                >
                  {PartnerDataById?.sla.split("@")[1]}
                </a>
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>GST Applicable</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.gstApplicable}
              </p>
            </Col>
            <Col md={3}></Col>
            <Col md={3}>
              <p className={classes.label1}>TDS Applicable</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.tdsApplicable}
              </p>
            </Col>
            {PartnerDataById?.tdsApplicable === "Yes" ? (
              <Col md={3}>
                <p className={classes.label1}>TDS Section</p>
                <p className={classes.partnerlabal}>
                  {PartnerDataById?.tdsSection}
                </p>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row></Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Primary Contact</h6>
            </Col>

            <Col md={3}>
              <p className={classes.label}>Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryContactName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Email ID</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryContectEmail} <CopyEmail email={PartnerDataById?.primaryContectEmail} />
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Mobile Number</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryMobileNumber}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Secondary Contact</h6>
            </Col>

            <Col md={3}>
              <p className={classes.label}>Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyContactName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Email ID</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyContectEmail} <CopyEmail email={PartnerDataById?.seconderyContectEmail} />
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Mobile Number</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyMobileNumber}
              </p>
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>

      <div className={classes.lastBtn_item}>
        {getVData?.some((e) => e?.label === "131D1775O71917QR") && (
          <InvoiceButton
            onClick={() => {
              setNextScreen("EditVendor");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "EditVendor",
                // page1: "ViewCreateVendor",
                page3: null,
                page4: null,
                active: "EditVendor",
              }));
            }}
          >
            Edit
          </InvoiceButton>
        )}
        {getVData?.some((e) => e?.label === "131D1775O71917QR") && (
          <InvoiceButton
            onClick={() => {
              setNextScreen("PartnerList");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "PartnerList",
                page1: "ViewCreateVendor",
                page3: null,
                page4: null,
                active: "PartnerList",
              }));
            }}
          >
            Done
          </InvoiceButton>
        )}
      </div>
    </>
  );
};

export default ViewCreateVendor;
