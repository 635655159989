import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../../ViewPartner/ViewPartner.module.css";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton1";
import { FaBuilding, FaLinkedin, FaRegCopy } from "react-icons/fa";
import {
  validateEmployeeAccess,
  getIdClientById,
  changeClientStatus,
  changeClientProspect,
  deleteClient,
} from "../../../../../Utils/axios/PortalManagement";
import { useMutation, useQuery } from "react-query";
import { useEffect } from "react";
import BreadCrum from "../../../../PartnerManagement/breadcrums/BreadCrum";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import { errorToast, successToast } from "../../../../../Utils/constants";
import moment from "moment";
import CopyEmail from "../../../../../GenericComponent/CopyEmailFeature/CopyEmail";

const ViewClientList = ({ setNextScreen, prevData, setPrevData }) => {
  const { data: ClientDataById, refetch } = useQuery(
    ["getViewClient1", prevData?.clientId],
    () => getIdClientById(prevData?.clientId),
    // {
    //   onSuccess: (res) => {
    //     setTimeout(() => {
    //       refetch();
    //     }, 1000);
    //     setPrevData((prevData) => ({
    //       ...prevData,
    //       status: res?.statusId,
    //       specializationExistId: res?.specializationExist,
    //     }));
    //   },
    // }
  );

  const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(localStorage.getItem("employeeId"))),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const convertedDate = (dateString) => {
    const months = new Array(12).fill(0).map((_, index) => new Date(0, index).toLocaleString('en-US', { month: 'short' }));
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']; // Array of weekday abbreviations

    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const formattedDate = `${day} ${month} (${days[date.getDay()]})`;
    return formattedDate;
  }

  const changeStatusMutation = useMutation(changeClientStatus, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 0) {
        errorToast(res?.responseData?.message);
        return;
      }

      successToast(res?.responseData);
      setNextScreen("clientList");
      setPrevData((prevData) => ({
        ...prevData,
        page1: "clientList",
        page2: "viewClient",
        page3: null,
        page4: null,
        active: "viewClient",
      }));
    },

    onError: (err) => {
      errorToast("Something went wrong");
    },
  });

  const changeProspectMutation = useMutation(changeClientProspect, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 0) {
        errorToast(res?.responseData?.message);
        return;
      }

      successToast(res?.responseData);
      setNextScreen("clientList");
      setPrevData((prevData) => ({
        ...prevData,
        page1: "clientList",
        page2: "viewClient",
        page3: null,
        page4: null,
        active: "viewClient",
      }));
    },
    onError: (err) => {
      errorToast("Something went wrong");
    },
  });

  const deleteClientMutation = useMutation(deleteClient, {
    onSuccess: (res) => {
      if (res?.responseStatus?.statusCode === 0) {
        errorToast(res?.responseData?.message);
        return;
      }
      successToast(res?.responseData);
      setNextScreen("clientList");
      setPrevData((prevData) => ({
        ...prevData,
        page1: "clientList",
        page2: "viewClient",
        page3: null,
        page4: null,
        active: "viewClient",
      }));
    },
    onError: (err) => {
      errorToast("Something Went Wrong");
    },
  });

  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));
  useEffect(() => {
    refetch();
  }, [ClientDataById]);
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
      default:
        return;
    }
  };
  return (
    <>
      <ToastContainer />
      <Container>
        <div>
          <BreadCrum
            prevData={prevData}
            setPrevData={setPrevData}
            setNextScreen={setNextScreen}
          ></BreadCrum>
        </div>
        <InvoiceContainer>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Client Trade Name</p>
              <p className={classes.partnerlabal}>
                {ClientDataById?.clientName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Client Legal Name</p>
              <p className={classes.partnerlabal}>
                {ClientDataById?.legelClientName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Point of Contact of Client</p>
              <p className={classes.partnerlabal}>
                {ClientDataById?.pointOfContactOfClient}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Email Id</p>
              <p className={classes.partnerlabal}>{ClientDataById?.email} <CopyEmail email={ClientDataById?.email} /></p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Mobile Number</p>
              <p className={classes.partnerlabal}>{ClientDataById?.mobileNo}</p>
            </Col>

            <Col md={3}>
              <p className={classes.label1}>Sales Owner Name</p>
              <p className={classes.partnerlabal}>
                {ClientDataById?.salesOwnerName}
              </p>
            </Col>

            <Col md={3}>
              <p className={classes.label1}>Client Spoc Designation</p>
              <p className={classes.partnerlabal}>
                {ClientDataById?.spocDesignation}
              </p>
            </Col>

            <Col md={3}>
              <p className={classes.label1}>GSTIN</p>
              <p className={classes.partnerlabal}>
                {ClientDataById?.gstNumber}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>SEZ</p>
              <p className={classes.partnerlabal}>
                {ClientDataById?.sezStatus && ClientDataById?.sezStatus === true
                  ? "Yes"
                  : "No"}
              </p>
            </Col>

            <Col md={3}>
              <p className={classes.label1}>Currency</p>
              <p className={classes.partnerlabal}>{ClientDataById?.currency}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Address 1</p>
              <p className={classes.partnerlabal}>{ClientDataById?.address}</p>
            </Col>

            <Col md={3}>
              <p className={classes.label1}>Address 2</p>
              <p className={classes.partnerlabal}>{ClientDataById?.address2}</p>
            </Col>

            <Col md={3}>
              <p className={classes.label1}>State</p>
              <p className={classes.partnerlabal}>{ClientDataById?.state}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>City</p>
              <p className={classes.partnerlabal}>{ClientDataById?.city}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Pin Code</p>
              <p className={classes.partnerlabal}>{ClientDataById?.pinCode}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Country</p>
              <p className={classes.partnerlabal}>{ClientDataById?.country}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Client Domain</p>
              <p className={classes.partnerlabal}>
                {ClientDataById?.clientDomain}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Client Website</p>
              <p className={classes.partnerlabal}>
                {/* {ClientDataById?.clientwebUri} */}
                {ClientDataById?.clientwebUri && (
                  <a
                    href={ClientDataById?.clientwebUri}
                    target="_blank"
                    title="Click Here"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    <FaLinkedin size={25} color="blue" />
                  </a>
                )}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Client LinkedIn</p>
              <p className={classes.partnerlabal}>
                {ClientDataById?.clientLinKedInURi && (
                  <a
                    href={ClientDataById?.clientLinKedInURi}
                    target="_blank"
                    title="Click Here"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    <FaBuilding size={25} color="blue" />
                  </a>
                )}
                {/* {ClientDataById?.clientLinKedInURi} */}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Due Date</p>
              <p className={classes.partnerlabal}> {ClientDataById?.dueDate && moment(ClientDataById?.dueDate).format(
                "DD MMM YY"
              )}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Agreement</p>
              <p className={classes.partnerlabal}>
                {ClientDataById?.agreement?.map((ele, idx) => (
                  <div key={idx}>
                    <a
                      className={classes.msaopen}
                      href={ele}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {ele.split("@")[1]}
                    </a>
                  </div>
                ))}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Client Head-office location</p>
              <p className={classes.partnerlabal}>{ClientDataById?.clientHeadOfficeLocation}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>SPOC Current Location</p>
              <p className={classes.partnerlabal}>{ClientDataById?.spocCurrentLocation}</p>
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>

      <div className={classes.lastBtn_item}>
        <InvoiceButton
          onClick={() => {
            deleteClientMutation.mutate({ clientId: ClientDataById?.clientId });
          }}
        >
          Delete
        </InvoiceButton>
        <InvoiceButton
          onClick={() => {
            changeStatusMutation.mutate({
              clientId: ClientDataById?.clientId,
              statusId: ClientDataById?.status === "Active" ? 2 : 1,
            });
          }}
        >
          Marks as {ClientDataById?.status === "Active" ? "Inactive" : "Active"}
        </InvoiceButton>

        <InvoiceButton
          onClick={() => {
            changeProspectMutation.mutate({
              clientId: ClientDataById?.clientId,
              statusId: ClientDataById?.isProspect === true ? false : true,
            });
          }}
        >
          Marks as{" "}
          {ClientDataById?.isProspect === false
            ? "Prospect"
            : ClientDataById?.isProspect === null
              ? "Prospect"
              : "Client"}
        </InvoiceButton>

        {/* <InvoiceButton
          onClick={() => {
            setNextScreen("milestones");
            setPrevData((prevData) => ({
              ...prevData,
              page1: "clientList",
              page2: "viewClient",
              page3: "milestones",
              page4: null,
              active: "milestones",
            }));
          }}
        >
          Milestone
        </InvoiceButton> */}

        {getVData?.some((e) => {
          return e?.label === "131D1775O71917QR";
        }) && (
            <InvoiceButton
              onClick={() => {
                setNextScreen("editClient");
                setPrevData((prevData) => ({
                  ...prevData,
                  page1: "clientList",
                  page2: "viewClient",
                  page3: "editClient",
                  page4: null,
                  active: "editClient",
                }));
              }}
            >
              Edit
            </InvoiceButton>
          )}
        {getVData?.some((e) => {
          return e?.label === "131D1775O71917QR";
        }) && (
            <InvoiceButton
              onClick={() => {
                setNextScreen("clientList");
                setPrevData((prevData) => ({
                  ...prevData,
                  page1: "clientList",
                  page2: "viewClient",
                  page3: null,
                  page4: null,
                  active: "viewClient",
                }));
              }}
            >
              Done
            </InvoiceButton>
          )}
      </div>
    </>
  );
};
export default ViewClientList;
