import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MonthYearPicker = ({ monthSelected, yearSelected, onChange }) => {
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    if (monthSelected && yearSelected) {
      const date = new Date(yearSelected, monthSelected - 1, 1);
      setStartDate(date);
    }
  }, [monthSelected, yearSelected]);

  const handleChange = (date) => {
    if (date) {
      const updatedDate = new Date(date.getFullYear(), date.getMonth(), 1);
      setStartDate(updatedDate);
      onChange(date);
    } else {
      setStartDate(null); 
      onChange(null);
    }
  };

  const customInput = (
    <input
      className="form-control custom-input"
      value={monthSelected ? `${monthSelected - 1}/${yearSelected}` : ''}
      placeholder='Select the date'
      readOnly
    />
  );

  return (
    <DatePicker
    //   showIcon
      selected={startDate}
      onChange={handleChange}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      customInput={customInput}
      isClearable
      className="custom-datepicker"
      placeholderText='Select Month & Year'
    />
  );
};

export default MonthYearPicker;

