import React, { useState } from "react";
import { Link } from "react-router-dom";
import style from "./EmployDetail.module.css";
import { ImLink } from "react-icons/im";
import Modal from "react-responsive-modal";
import { FaEye, FaRegComments } from "react-icons/fa";
import ProjectComments from "../../ProjectComments/ProjectComments";
import moment from "moment";

function EmployDetail({ employeeDetails, projectDetailsComments }) {
  console.log("employeeDetails", employeeDetails);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [nextScreen, setNextScreen] = useState("EmployeePerformanceReview");
  const [currentPage, setCurrentPage] = useState(1);

  const [prevData, setPrevData] = useState({
    page: "EmployeePerformanceReview",
    sidebar: "EmployeePerformanceReview",
    page1: "EmployeePerformanceReview",
    active: "EmployeePerformanceReview",
  });
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleView = () => {
    openModal();
  };
  const titles = [
    "Code",
    "Employee Type",
    "Name",
    "Designation",
    "Department",
    "Technology",
  ];
  const Emp = [
    employeeDetails.employeeCode,
    employeeDetails.empType,
    employeeDetails.name?.length > 20 ? (
      <p title={employeeDetails.name}>{employeeDetails.name.slice(0, 18)}...</p>
    ) : (
      employeeDetails.name
    ),
    employeeDetails.designation?.length > 20 ? (
      <p title={employeeDetails.designation}>
        {employeeDetails.designation.slice(0, 18)}...
      </p>
    ) : (
      employeeDetails.designation
    ),
    employeeDetails.department,
    employeeDetails.technology,
  ];

  const titles2 = [
    "DOJ",
    "Total Exp (IM)",
    "Total Exp",
    "Bond",
  ];
  const Emp2 = [
    employeeDetails.dateOfJoining && moment(employeeDetails.dateOfJoining).format("DD MMM YY"),
    employeeDetails.totalExpWithIM,
    employeeDetails.totalExperience,
    employeeDetails.bond,
    employeeDetails.bondEndDate,
    employeeDetails.rating,
  ];
  const titles3 = [
    "Location",
    "Client Name",
    "Project Details",
    "Performance Detail",
    "Reporting Manager",
    "Last Effective From",
  ];
  const titles4 = [
    "Last Appraisal Cycle",
    "Last Increment Amount",
    "Last Increment %",
    "Last Reward",
    "Last Promotion Cycle",
    "",
  ];
  const Emp3 = [
    employeeDetails.location,
    employeeDetails.clientName?.length > 20 ? (
      <p title={employeeDetails.clientName}>
        {employeeDetails.clientName.slice(0, 18)}...
      </p>
    ) : (
      employeeDetails.clientName
    ),
    <span
      className={`${style.link}`}
      onClick={handleView}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === "Space") {
          handleView();
        }
      }}
      tabIndex={0} // Make the <span> focusable
      aria-label="View" // Provide an accessible name
    >
      <FaEye />
    </span>,
    // <a href={employeeDetails.performanceDetailsUrl} target="_blank" rel="noreferrer">
    <Link
      to={{
        pathname: "/EmployeePerformanceReview",
        state: { empId: employeeDetails?.empId }, // Pass the state while navigating
      }}
      key={"EPRLink"}
    >
      <span className={`${style.link}`}>
        <ImLink />
      </span>
    </Link>,
    // </a>,
    employeeDetails.reportingManager,
    employeeDetails.lastApplicableFrom && moment(employeeDetails.lastApplicableFrom).format("DD MMM YY"),
  ];
  const Emp4 = [
    employeeDetails.lastAppraisalCycle,
    employeeDetails.lastIncrementAmount,
    employeeDetails.lastIncrementPercentage,
    employeeDetails.lastReward && (
      <p
        title={`${employeeDetails.lastReward} (${employeeDetails.lastRewardCycle})`}
      >
        {`${employeeDetails.lastReward} (${employeeDetails.lastRewardCycle})`.slice(
          0,
          20
        )}
        ...
      </p>
    ),
    employeeDetails.lastPromotionCycle,
    "",
  ];
  // Date Converter

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  const [pcModalIsOpen, setPCModalIsOpen] = useState(false);
  const closePCModal = () => {
    setModalIsOpen(false);
  };
  const openPCModal = () => {
    setPCModalIsOpen(true);
  };
  const handleProjectComments = () => {
    openPCModal();
  };
  return (
    <div>
      <div className={style.container}>
        <div className={style.title}>Employee Details</div>
        {/* <p className={style.title}>{mainHeading}</p> */}
        <hr className={style.hr}></hr>
        <div className={style.EmpBox_row}>
          {titles.map((title, index) => {
            return (
              <div className={style.EmpBox_col} key={index}>
                <p className={style.EmpBox_label}>{title}</p>
                <p
                  className={
                    title === "Client Name"
                      ? style.EmpBox_Client
                      : style.EmpBox_field
                  }
                >
                  {Emp[index] ? Emp[index] : ""}
                </p>
              </div>
            );
          })}
        </div>
        <div className={style.EmpBox_row}>
          {titles2.map((title, index) => {
            return (
              <div className={style.EmpBox_col} key={index}>
                <p className={style.EmpBox_label}>{title}</p>
                <p
                  className={
                    title === "Client Name"
                      ? style.EmpBox_Client
                      : style.EmpBox_field
                  }
                >
                  {Emp2[index] ? Emp2[index] : ""}
                </p>
              </div>
            );
          })}
          <div className={style.EmpBox_col}>
            <p className={style.EmpBox_label}>Bond End Date</p>
            <p
              className={style.EmpBox_field}
            >
              {employeeDetails?.bondEndDate && moment(employeeDetails?.bondEndDate).format("DD MMM YY")}
            </p>
          </div>
          <div className={style.EmpBox_col}>
            <p className={style.EmpBox_label}>Rating</p>
            <p
              className={style.EmpBox_field}
              title={employeeDetails?.lastPRMonth}
              style={employeeDetails?.ratingOutOf === "1 to 5" ? { color: 'red' } : {}}
            >
              {employeeDetails?.rating}
            </p>
          </div>
        </div>
        <div className={style.EmpBox_row}>
          {titles3.map((title, index) => {
            return (
              <div className={style.EmpBox_col} key={index}>
                <p className={style.EmpBox_label}>{title}</p>
                <p className={style.EmpBox_field}>
                  {Emp3[index] ? Emp3[index] : ""}
                </p>
              </div>
            );
          })}
        </div>
        <div className={style.EmpBox_row}>
          {titles4.map((title, index) => {
            return (
              <div className={style.EmpBox_col} key={index}>
                <p className={style.EmpBox_label}>{title}</p>
                <p
                  className={
                    title === "Client Name"
                      ? style.EmpBox_Client
                      : style.EmpBox_field
                  }
                >
                  {Emp4[index] ? Emp4[index] : ""}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        open={modalIsOpen}
        onRequestClose={closeModal}
        center
        onClose={() => setModalIsOpen(false)}
        showCloseIcon={false}
      >
        <div className={style.modal}>
          <div className={style.projectHeading}>Project Details</div>
          <div
            className={style.projectComments}
            onClick={() => handleProjectComments()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Space') {
                handleProjectComments();
              }
            }}
            tabIndex={0} // Make the <div> focusable
            aria-label="Open Project Comments" // Provide an accessible name
          >
            <FaRegComments />
          </div>
          <table className={style.table}>
            <thead className={style.thead}>
              <tr className={style.trHead}>
                <th className={style.th}>Project Name</th>
                <th className={style.th}>Bandwidth</th>
                <th className={style.th}>Allocation</th>
                <th className={style.th}>DeAllocation</th>
              </tr>
            </thead>
            {employeeDetails.projectDetailObj?.length > 0 ? (
              <tbody className={style.tbody}>
                {employeeDetails.projectDetailObj.map((project) => {
                  return (
                    <tr className={style.trBody} key={project.projectId}>
                      <td className={style.td}>{project.projectName}</td>
                      <td className={style.td}>
                        {project.bandwidth === "OneFourth" ? (
                          <p>25%</p>
                        ) : project.bandwidth === "Half" ? (
                          <p>50%</p>
                        ) : project.bandwidth === "Full" ? (
                          <p>100%</p>
                        ) : (
                          <p>75%</p>
                        )}
                      </td>
                      <td className={style.td}>
                        {formatDate(project.allocationDate)}
                      </td>
                      <td className={style.td}>
                        {formatDate(project.deallocationDate)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <div className={style.noProjects}>
                Project Details are not Available
              </div>
            )}
          </table>
        </div>
      </Modal>
      <Modal
        open={pcModalIsOpen}
        onRequestClose={closePCModal}
        center
        onClose={() => setPCModalIsOpen(false)}
        showCloseIcon={false}
        className={style.modal}
      >
        <div className={style.commentModal}>
          {projectDetailsComments?.length > 0 ? (
            <ProjectComments hrHeadComment={projectDetailsComments} />
          ) : (
            "No Comments"
          )}
        </div>
      </Modal>
    </div>
  );
}

export default EmployDetail;
