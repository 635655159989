import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Pagination from "../../commonComponent/Pagination/Pagination";
import style from "./AppraisalCycles.module.css";
import AppraisalButton from "../../commonComponent/Button/AppraisalButton/AppraisalButton";
import Modal from "react-responsive-modal";
import deleteIcon from "../../assets/delete-icon.png";
import editIcon from "../../assets/Icon material-edit.png";
import { useMutation, useQuery } from "react-query";
import { format } from "date-fns";

// Integration
import {
  addNewAppraisalCycle,
  closeAppraisalCycle,
  deleteAppraisalCycle,
  editAppraisalCycle,
  getAllAppraisalCycles,
} from "../../../../Utils/axios/AppraisalApi";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify";
import AppraisalLoader from "../../commonComponent/Loader/AppraisalLoader";

const AppraisalCycles = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
  const [warningOpenCycle, setWarningOpenCycle] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [cycleList, setCycleList] = useState([]);
  const [cycleId, setCycleId] = useState(null);
  const [cycleDetailsForOpen, setCycleDetailsForOpen] = useState(null);
  const [appraisalData, setAppraisalData] = useState({
    startDate: "",
    endDate: "",
    appraisalCycleTitle: "",
    cycleType: "",
  });
  const [appraisalEditedData, setAppraisalEditedData] = useState({
    startDate: "",
    endDate: "",
    appraisalCycleTitle: "",
    cycleType: "",
    appraisalCycleId: "",
  });
  const [totalCount, setTotalCount] = useState(null);
  const [showError, setShowError] = useState({
    titleError: false,
    startDateError: false,
    endDateError: false,
  });

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const {
    data,
    isLoading,
    refetch: refetchCycles,
  } = useQuery(["getAllAppraisalCycles", currentPage], () =>
    getAllAppraisalCycles(10, currentPage)
  );
  useEffect(() => {
    setCycleList(data?.appraisalCycles);
    setTotalCount(data?.totalCount);
    setPageCount(data?.totalCount / 10);
  }, [data]);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const openEditModal = () => {
    setEditModalIsOpen(true);
  };
  const openWarningModal = () => {
    setWarningModalIsOpen(true);
  };
  const openCycle = () => {
    setWarningOpenCycle(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setShowError({
      titleError: false,
      startDateError: false,
      endDateError: false,
    });
    setAppraisalData({
      startDate: "",
      endDate: "",
      appraisalCycleTitle: "",
      cycleType: "",
    });
  };
  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setShowError({
      titleError: false,
      startDateError: false,
      endDateError: false,
    });
    setAppraisalEditedData({
      startDate: "",
      endDate: "",
      appraisalCycleTitle: "",
      cycleType: "",
      appraisalCycleId: "",
    });
  };
  const closeWarningModal = () => {
    setWarningModalIsOpen(false);
  };
  const donotOpenCycle = () => {
    setWarningOpenCycle(false);
  };

  const handleInputChange = (e) => {
    setAppraisalData({
      ...appraisalData,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case "startDate":
        e.target.value.trim() &&
          setShowError((prev) => {
            return { ...prev, startDateError: false };
          });

        break;
      case "endDate":
        e.target.value.trim() &&
          setShowError((prev) => {
            return { ...prev, endDateError: false };
          });

        break;
      case "appraisalCycleTitle":
        e.target.value.trim() &&
          setShowError((prev) => {
            return { ...prev, titleError: false };
          });

        break;

      default:
        break;
    }

    // }
  };
  const handleEditInputChange = (e) => {
    setAppraisalEditedData({
      ...appraisalEditedData,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case "startDate":
        e.target.value.trim() &&
          setShowError((prev) => {
            return { ...prev, startDateError: false };
          });

        break;
      case "endDate":
        e.target.value.trim() &&
          setShowError((prev) => {
            return { ...prev, endDateError: false };
          });

        break;
      case "appraisalCycleTitle":
        e.target.value.trim() &&
          setShowError((prev) => {
            return { ...prev, titleError: false };
          });

        break;

      default:
        break;
    }
  };

  function convertToYYYYMMDD(originalDate) {
    // Parse the original date string
    const parsedDate = new Date(originalDate);

    // Check if the parsing was successful
    if (isNaN(parsedDate.getTime())) {
      // If parsing fails, return an error or handle it as needed
      return "Invalid Date";
    }

    // Format the date as yyyy-mm-dd
    const yyyy = parsedDate.getFullYear();
    const mm = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const dd = String(parsedDate.getDate()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd}`;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !appraisalData.startDate ||
      !appraisalData.endDate ||
      !appraisalData.appraisalCycleTitle.trim()
    ) {
      if (!appraisalData.startDate)
        setShowError((prev) => {
          return { ...prev, startDateError: true };
        });
      if (!appraisalData.endDate)
        setShowError((prev) => {
          return { ...prev, endDateError: true };
        });
      if (!appraisalData.appraisalCycleTitle.trim())
        setShowError((prev) => {
          return { ...prev, titleError: true };
        });
      return;
    }
    closeModal();
    const formattedStartDate = format(
      new Date(appraisalData.startDate),
      "dd-MM-yyyy"
    );
    const formattedEndDate = format(
      new Date(appraisalData.endDate),
      "dd-MM-yyyy"
    );
    let cycleTypeId;
    if (appraisalData.cycleType === "") {
      cycleTypeId = null;
    } else {
      cycleTypeId = Number(appraisalData.cycleType);
    }
    const updatedData = {
      appraisalCycleTitle: appraisalData.appraisalCycleTitle,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      cycleTypeId: cycleTypeId,
    };
    addNewAppraisalCycleMutation.mutate(updatedData);
    setAppraisalData({
      startDate: "",
      endDate: "",
      appraisalCycleTitle: "",
      cycleType: "",
    });
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (
      !appraisalEditedData.startDate ||
      !appraisalEditedData.endDate ||
      !appraisalEditedData.appraisalCycleTitle
    ) {
      if (!appraisalEditedData.startDate)
        setShowError((prev) => {
          return { ...prev, startDateError: true };
        });
      if (!appraisalEditedData.endDate)
        setShowError((prev) => {
          return { ...prev, endDateError: true };
        });
      if (!appraisalEditedData.appraisalCycleTitle)
        setShowError((prev) => {
          return { ...prev, titleError: true };
        });
      return;
    }
    closeEditModal();
    const formattedStartDate = format(
      new Date(appraisalEditedData.startDate),
      "dd-MM-yyyy"
    );
    const formattedEndDate = format(
      new Date(appraisalEditedData.endDate),
      "dd-MM-yyyy"
    );
    let cycleTypeId;

    if (appraisalEditedData.cycleType === "") {
      cycleTypeId = null;
    } else {
      cycleTypeId = Number(appraisalEditedData.cycleType);
    }
    const updatedData = {
      appraisalCycleTitle: appraisalEditedData.appraisalCycleTitle,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      cycleTypeId: cycleTypeId,
      appraisalCycleId: appraisalEditedData.appraisalCycleId,
    };
    editAppraisalCycleMutation.mutate(updatedData);
    setAppraisalEditedData({
      startDate: "",
      endDate: "",
      appraisalCycleTitle: "",
      cycleType: "",
    });
    setShowError({
      titleError: false,
      startDateError: false,
      endDateError: false,
    });
  };
  // Closing Cycle

  const handleCloseCycle = (cycleId) => {
    closeAppraisalCycleMutation.mutate(cycleId);
    setWarningModalIsOpen(false);
  };
  const closeAppraisalCycleMutation = useMutation(closeAppraisalCycle, {
    onMutate: () => {},
    onSuccess: (res) => {
      refetchCycles();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {},
  });
  const handleDelete = (id) => {
    deleteAppraisalCycleMutation.mutate(id);
  };
  const addNewAppraisalCycleMutation = useMutation(addNewAppraisalCycle, {
    onMutate: () => {},
    onSuccess: (res) => {
      refetchCycles();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {},
  });
  const deleteAppraisalCycleMutation = useMutation(deleteAppraisalCycle, {
    onMutate: () => {},
    onSuccess: (res) => {
      refetchCycles();

      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {},
  });

  // Handling All Edits
  const handleEdit = (cycle) => {
    let cycleTypeId =
      cycle.cycleType === "Current"
        ? 1
        : cycle.cycleType === "Upcoming"
        ? 2
        : "";
    setAppraisalEditedData({
      startDate: convertToYYYYMMDD(cycle.startDate),
      endDate: convertToYYYYMMDD(cycle.endDate),
      appraisalCycleTitle: cycle.appraisalCycleTitle,
      cycleType: cycleTypeId,
      appraisalCycleId: Number(cycle.id),
    });
    openEditModal();
  };

  // Integrating Edit
  const editAppraisalCycleMutation = useMutation(editAppraisalCycle, {
    onMutate: () => {},
    onSuccess: (res) => {
      // Refetch the cycles data after a successful mutation
      refetchCycles();
      if (res.responseStatus.statusCode === 0) {
        notifySuccess(res.responseData);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {},
  });

  // Closing Cycle
  const handleStatus = (cycle) => {
    setCycleId(cycle.id);
    openWarningModal();
  };

  const handleOpen = (cycle) => {
    openCycle();
    function formatDate(inputDate) {
      // Split the inputDate into day, month, and year parts
      let parts = inputDate.split(" ");
      let day = parts[0];
      let monthAbbreviation = parts[1];
      let year = "20" + parts[2]; // Assuming it's 21st century, adjust if necessary

      // Convert the month abbreviation to a numeric month
      let monthMap = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
      };
      let month = monthMap[monthAbbreviation];

      // Format the date in DD-MM-YYYY format
      let formattedDate = day + "-" + month + "-" + year;

      return formattedDate;
    }
    let formattedStartDate = formatDate(cycle.startDate);
    let formattedEndDate = formatDate(cycle.endDate);
    const updatedData = {
      // appraisalCycleId:cycle.,
      appraisalCycleTitle: cycle.appraisalCycleTitle,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      cycleTypeId: 1,
      appraisalCycleId: cycle.id,
    };
    setCycleDetailsForOpen(updatedData);
  };
  const handleYes = () => {
    editAppraisalCycleMutation.mutate(cycleDetailsForOpen);
    donotOpenCycle();
  };
  return (
    <>
      <div>
        {/* FILTER COMPONENT */}
        <div className={style.upper}>
          <div className={style.ppTotal}>
            <span className={style.pp}>Appraisal Cycle</span>
            <div className={style.totalCount}>{totalCount}</div>
          </div>
          {!isLoading && (
            <div className={style.filteralign}>
              <div title="Add New Appraisal Cycle">
                <AppraisalButton type="plus" onClick={openModal}>
                  Add New Cycle
                </AppraisalButton>
              </div>
            </div>
          )}
        </div>

        {/* TABLE */}
        {isLoading ? (
          <AppraisalLoader />
        ) : (
          <div className={style.cycleList}>
            <table>
              <thead className={style.thead}>
                <tr className={style.tr1}>
                  {/* <tr> */}
                  <th className={style.th1}>Cycle</th>
                  <th className={style.th1}>Total Increment</th>
                  <th className={style.th1}>Average Increment</th>
                  <th className={style.th1}>Rewards</th>
                  <th className={style.th1}>Promotions</th>
                  <th className={style.th1}>Status</th>
                </tr>
              </thead>

              <tbody>
                {cycleList?.length > 0 ? (
                  cycleList.map((cycle, index) => {
                    return (
                      <tr className={style.td1} key={cycle.id}>
                        <td className={`${style.td1}`}>
                          <NavLink
                            to={`/ASDashboard/${cycle.appraisalCycleTitle}/${cycle.id}/CycleEmployeeList`}
                          >
                            <p className={style.cycle}>
                              {cycle.appraisalCycleTitle}{" "}
                              {cycle.cycleType && (
                                <span
                                  className={`${style.td1}  ${
                                    cycle.cycleType === "Next" && style.next
                                  } ${
                                    cycle.cycleType === "Past" && style.past
                                  } ${
                                    cycle.cycleType === "Current" &&
                                    style.current
                                  } } `}
                                >
                                  {" "}
                                  ({cycle.cycleType})
                                </span>
                              )}
                            </p>
                          </NavLink>
                        </td>
                        <td className={style.td1}>
                          {cycle.totalIncrement ? cycle.totalIncrement : "-"}
                        </td>
                        <td className={style.td1}>
                          {cycle.averageIncrement
                            ? cycle.averageIncrement
                            : "-"}{" "}
                          {cycle.appraisal !== 0 && (
                            <span
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              ({cycle.appraisal})
                            </span>
                          )}
                        </td>
                        <td className={style.td1}>
                          {cycle.rewards ? cycle.rewards : "-"}
                        </td>
                        <td className={style.td1}>
                          {cycle.promotions ? cycle.promotions : "-"}
                        </td>
                        <td
                          className={`${style.td1} ${
                            cycle.status === "Open" ? style.open : style.closed
                          }`}
                        >
                          {cycle.status === "Open" ? (
                            <p
                              onClick={() => handleStatus(cycle)}
                              className={style.statusClass}
                              title="Close Cycle"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === 'Space') {
                                  handleStatus(cycle);
                                }
                              }}
                              tabIndex={0} // Make the <p> focusable
                            >
                              {cycle.status}
                            </p>
                          ) : (
                            <p>{cycle.status}</p>
                          )}
                          {cycle.status === "Open" ? (
                            <div className={style.actionButtons}>
                              <div
                                className={style.delete}
                                id={cycle.id}
                                onClick={() => handleDelete(cycle.id)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' || e.key === 'Space') {
                                    handleDelete(cycle.id);
                                  }
                                }}
                                tabIndex={0} // Make the <div> focusable
                                aria-label="Delete Cycle" // Provide an accessible name
                              >
                                <img src={deleteIcon} title="Delete Cycle" />
                              </div>
                              <div
                                className={style.delete}
                                id={cycle.id}
                                onClick={() => handleEdit(cycle)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' || e.key === 'Space') {
                                    handleEdit(cycle.id);
                                  }
                                }}
                                tabIndex={0} // Make the <div> focusable
                                aria-label="Edit Cycle" // Provide an accessible name
                                
                              >
                                <img src={editIcon} title="Edit Cycle" />
                              </div>
                            </div>
                          ) : (
                            cycle.status === "Closed" &&
                            index == 0 && (
                              <div className={style.actionButtons}>
                                <div
                                  className={style.delete}
                                  id={cycle.id}
                                  onClick={() => handleOpen(cycle)}
                                  title="Click to Open Cycle"
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === 'Space') {
                                      handleOpen(cycle);
                                    }
                                  }}
                                  tabIndex={0} // Make the <div> focusable
                                  aria-label="Open Cycle" // Provide an accessible name
                                >
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "#855ea1",
                                    }}
                                  >
                                    Open
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <span className={style.noCycle}>No Cycle Present</span>
                )}
              </tbody>
            </table>
          </div>
        )}
        {!isLoading && (
          <div className={style.paginationContainer}>
            <Pagination
              pagesCount={pageCount}
              pageSize="10"
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>

      <Modal
        open={modalIsOpen}
        onRequestClose={closeModal}
        center
        onClose={() => closeModal()}
        showCloseIcon={false}
        className={style.modal}
      >
        <div className={style.modal}>
          <div className={style.heading}>Add New Cycle</div>
          <div className={style.title}>
            <label htmlFor="appraisalCycleTitle">
              Title <span className={style.star}>*</span>
            </label>{" "}
            <br />
            <input
              id="appraisalCycleTitle"
              className={style.titleIp}
              type="text" // Use "date" input type for date selection
              name="appraisalCycleTitle"
              value={appraisalData.appraisalCycleTitle}
              onChange={handleInputChange}
              autoComplete="off"
            />
            <p
              className={`${style.errorText} ${
                showError.titleError ? style.showError : style.hideError
              }`}
            >
              *Please Enter Title
            </p>
            {!showError.titleError && <br />}
            <label htmlFor="cycleType">Cycle Type</label> <br />
            <select
              name="cycleType"
              className={style.typeInput}
              onChange={handleInputChange}
            >
              <option value="">None</option>
              <option value="1">Current</option>
              <option value="2">Upcoming</option>
            </select>
          </div>

          <div className={style.date}>
            <div className={style.startDate}>
              <label htmlFor="startDate">
                Start Date <span className={style.star}>*</span>
              </label>{" "}
              <br />
              <input
                type="date" // Use "date" input type for date selection
                name="startDate"
                className={style.titleIp}
                value={appraisalData.startDate}
                onChange={handleInputChange}
                id="startDate"
              />
              <p
                className={`${style.errorText} ${
                  showError.startDateError ? style.showError : style.hideError
                }`}
              >
                *Please Enter Start Date
              </p>
            </div>
            <div className={style.endDate}>
              <label htmlFor="endDate">
                End Date <span className={style.star}>*</span>
              </label>{" "}
              <br />
              <input
                className={style.titleIp}
                type="date" // Use "date" input type for date selection
                name="endDate"
                value={appraisalData.endDate}
                onChange={handleInputChange}
                id="endDate"
              />
              <p
                className={`${style.errorText} ${
                  showError.endDateError ? style.showError : style.hideError
                }`}
              >
                *Please Enter End Date
              </p>
            </div>
          </div>

          <div className={style.button}>
            <AppraisalButton type="submit" onClick={handleSubmit}>
              Submit
            </AppraisalButton>
          </div>
        </div>
      </Modal>
      <Modal
        open={editModalIsOpen}
        onRequestClose={closeEditModal}
        center
        onClose={() => closeEditModal()}
        showCloseIcon={false}
        className={style.modal}
      >
        <div className={style.modal}>
          <div className={style.heading}>Edit Cycle</div>
          <div className={style.title}>
            <label htmlFor="appraisalCycleTitle">
              Title <span className={style.star}>*</span>
            </label>{" "}
            <br />
            <input
              id="appraisalCycleTitle"
              className={style.titleIp}
              type="text" // Use "date" input type for date selection
              name="appraisalCycleTitle"
              value={appraisalEditedData.appraisalCycleTitle}
              onChange={handleEditInputChange}
              autoComplete="off"
            />
            <p
              className={`${style.errorText} ${
                showError.titleError ? style.showError : style.hideError
              }`}
            >
              *Please Enter Appraisal Cycle Title
            </p>
            {!showError.titleError && <br />}
            <label htmlFor="cycleType">Cycle Type</label> <br />
            <select
              name="cycleType"
              className={style.typeInput}
              defaultValue={
                appraisalEditedData.cycleType === 1
                  ? "1"
                  : appraisalEditedData.cycleType === 2
                  ? "2"
                  : ""
              }
              onChange={handleEditInputChange}
            >
              <option value="">None</option>
              <option value="1" className={style.optionCurrent}>
                Current
              </option>
              <option value="2" className={style.optionUpcoming}>
                Upcoming
              </option>
            </select>
          </div>

          <div className={style.date}>
            <div className={style.startDate}>
              <label htmlFor="startDate">
                Start Date <span className={style.star}>*</span>
              </label>{" "}
              <br />
              <input
                type="date" // Use "date" input type for date selection
                name="startDate"
                className={style.titleIp}
                value={appraisalEditedData.startDate}
                onChange={handleEditInputChange}
                id="startDate"
              />
              <p
                className={`${style.errorText} ${
                  showError.startDateError ? style.showError : style.hideError
                }`}
              >
                *Please Enter Appraisal Cycle's Start Date
              </p>
            </div>
            <div className={style.endDate}>
              <label htmlFor="endDate">
                End Date <span className={style.star}>*</span>
              </label>{" "}
              <br />
              <input
                type="date" // Use "date" input type for date selection
                name="endDate"
                className={style.titleIp}
                value={appraisalEditedData.endDate}
                onChange={handleEditInputChange}
                id="endDate"
              />
              <p
                className={`${style.errorText} ${
                  showError.endDateError ? style.showError : style.hideError
                }`}
              >
                *Please Enter Appraisal Cycle's End Date
              </p>
            </div>
          </div>

          <div className={style.button}>
            <AppraisalButton type="submit" onClick={(e) => handleEditSubmit(e)}>
              Submit
            </AppraisalButton>
          </div>
        </div>
      </Modal>
      <Modal
        open={warningModalIsOpen}
        onRequestClose={closeWarningModal}
        center
        onClose={() => setWarningModalIsOpen(false)}
        showCloseIcon={false}
        className={style.closeWarningModal}
      >
        <div className={style.modal}>
          <div className={style.warningHeading}>Close Cycle</div>
          <span>Do you want to close this cycle ?</span>
          <div className={style.button}>
            <button
              className={style.appr_btn}
              onClick={() => setWarningModalIsOpen(false)}
            >
              No
            </button>
            <AppraisalButton onClick={() => handleCloseCycle(cycleId)}>
              Yes
            </AppraisalButton>
          </div>
        </div>
      </Modal>
      <Modal
        open={warningOpenCycle}
        onRequestClose={donotOpenCycle}
        center
        onClose={() => setWarningOpenCycle(false)}
        showCloseIcon={false}
        className={style.closeWarningModal}
      >
        <div className={style.modal}>
          <div className={style.warningHeading}>Open Cycle</div>
          <span>Do you want to open this cycle ?</span>
          <div className={style.button}>
            <button
              className={style.appr_btn}
              onClick={() => setWarningOpenCycle(false)}
            >
              No
            </button>
            <AppraisalButton onClick={() => handleYes()}>Yes</AppraisalButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AppraisalCycles;
