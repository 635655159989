import style from "./ProjectComments.module.css";
function ProjectComments({hrHeadComment}) {
    function formatDate(inputDate) {
        const [date] = inputDate.split('T');

        // Split the date into year, month, and day
        const [year, month, day] = date.split('-');
    
        // Convert month number to month name abbreviation
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthName = monthNames[parseInt(month) - 1];
    
        // Extract the last two digits of the year
        const yearShort = year.slice(2);
    
        // Format the date as 'DD Mon YY'
        const formattedDate = `${day} ${monthName} ${yearShort}`;
    
        return formattedDate;       
      }
  return (
    <div>
      <div className={style.title_Box}>
        <h5 className={style.title}>Comment Details</h5>
      </div>
      <table className={style.container}>
        <thead>
          <tr>
          <td className={style.td_style}>
            Comment
            Date
          </td>
          <td className={style.td_style}>Username</td>
          <td className={style.td_style}>Comment</td>
          </tr>
        </thead>
        <tbody>
          {hrHeadComment?.map((obj,index) => {
            return (
                <tr key={`${index}${obj.name}`}>
                  <td className={style.td_style2}>{formatDate(obj.date)}</td>
                  <td className={style.td_style2}>{obj.name}</td>
                  <td className={`${style.text_overflow} ${style.td_style2}`}>{obj.comment}</td>
                </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default ProjectComments;
