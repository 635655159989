import React, { useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import style from "./ExInNavigation.module.css";
import ProgressBar from "../progressbar/ProgressBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Expense from "../pageLayout/Expense/Expense";
import CreateExp from "../pageLayout/Expense/createExpense/CreateExpense";
import ViewExpense from "../pageLayout/Expense/viewExpense/ViewExpence";
import InvoiceList from "../pageLayout/Invoice/InvoiceList";
import CreateInvoice from "../pageLayout/Invoice/createInvoice/CreateInvoice";
import ViewInvoice from "../pageLayout/Invoice/viewInvoice/ViewInvoice";
import ViewInvoice1 from "../pageLayout/Invoice/viewInvoice/ViewInvoiceListData";

export default function ExInNavigation() {
  const [nextScreen, setNextScreen] = useState("expense");
  const [currentPage, setCurrentPage] = useState(1);
  const [roles, setRoles] = useState([]);
  const [prevData, setPrevData] = useState({
    page: "expense",
    sidebar: "expense",
    page1: "expense",
    active: "expense",
  });

  const sidebarHandler = () => {
    switch (nextScreen) {
      case "expense":
        return (
          <Expense
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "editExpense":
      case "createExpense":
        return (
          <CreateExp
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "viewExpense":
        return (
          <ViewExpense
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "invoiceList":
        return (
          <InvoiceList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "editInvoice":
      case "createInvoice":
        return (
          <CreateInvoice
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "viewInvoice":
        return (
          <ViewInvoice
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "viewInvoice1":
        return (
          <ViewInvoice1
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      default:
    }
  };

  const setCurrScreen = (val) => {
    setNextScreen(val);
  };

  return (
    <div className={style.ATSNav}>
      <Header />
      <div className={style.mainSection}>
        <div style={{ width: "20%" }}>
          <SideBar
            sidebarHandler={setCurrScreen}
            nextScreen={nextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            roles={roles}
          />
        </div>
        <div className={style.ATScontainer}>
          <ProgressBar prevData={prevData} />
          {sidebarHandler()}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
