import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import { useMutation, useQuery } from "react-query";
import cancel from "../../../../assets/cancell.png";
import calender from "../../../../assets/calblue.png";
import { BsFilePdf } from "react-icons/bs";
import axios from "axios";
import { ToastifyError } from "../../../../App";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineFileWord } from "react-icons/ai";
import ClearIcon from "@mui/icons-material/Clear";
import { urlValidation } from "../../../atsDashboard/utils/validation";
import { employeeId } from "../../../../Utils/constants";
import {
  getViewPartner,
  createPartner,
  getCityList,
  getWorkModeList,
  getStateList,
} from "../../../../Utils/axios/PortalManagement";
import classes from "../ViewCreatePartner/EditViewPartner.module.css";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { InvoiceStyles4 } from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import { notifyError } from "../../utils/notify";
const EditViewPartner = ({ setNextScreen, prevData }) => {
  const { data: PartnerDataById, refetch } = useQuery(
    ["getViewPartner"],
    () => getViewPartner(prevData?.CreateId),
    {}
  );
  const [formData, setFormData] = useState({
    partnerName: PartnerDataById?.partnerName,
    gstin: PartnerDataById?.gstin,
    msafileUrl: PartnerDataById?.msafileUrl,
    ndafileUrl: PartnerDataById?.ndafileUrl,
    addressLine1: PartnerDataById?.addressLine1,
    addressLine2: PartnerDataById?.addressLine2,
    country: PartnerDataById?.country,
    cityId: PartnerDataById?.cityId,
    workModeId: PartnerDataById?.workModeId,
    stateId: PartnerDataById?.stateId,
    pinCode: PartnerDataById?.pinCode,
    linkedinLink: PartnerDataById?.linkedinLink,
    primaryContactName: PartnerDataById?.primaryContactName,
    primaryContectEmail: PartnerDataById?.primaryContectEmail,
    primaryMobileNumber: PartnerDataById?.primaryMobileNumber,
    seconderyContactName: PartnerDataById?.seconderyContactName,
    seconderyContectEmail: PartnerDataById?.seconderyContectEmail,
    seconderyMobileNumber: PartnerDataById?.seconderyMobileNumber,
    id: prevData?.CreateId,
    empId: Number(employeeId),
    paymentDueDays: PartnerDataById?.pamentDueDays,
    paymentDueDaysAsPrPartnerRequest:
      PartnerDataById?.paymentDueDaysAsPrPartnerRequest,
  });
  const [validate, setValidate] = React.useState(false);
  const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;

  useEffect(() => {
    setFormData((prevValue) => ({
      ...prevValue,
      partnerName: PartnerDataById?.partnerName,
      gstin: PartnerDataById?.gstin,
      addressLine1: PartnerDataById?.addressLine1,
      addressLine2: PartnerDataById?.addressLine2,
      country: PartnerDataById?.country,
      msafileUrl: PartnerDataById?.msafileUrl,
      ndafileUrl: PartnerDataById?.ndafileUrl,
      workModeId: PartnerDataById?.workModeId,
      cityId: PartnerDataById?.cityId,
      stateId: PartnerDataById?.stateId,
      pinCode: PartnerDataById?.pinCode,
      linkedinLink: PartnerDataById?.linkedinLink,
      primaryContactName: PartnerDataById?.primaryContactName,
      primaryContectEmail: PartnerDataById?.primaryContectEmail,
      primaryMobileNumber: PartnerDataById?.primaryMobileNumber,
      seconderyContactName: PartnerDataById?.seconderyContactName,
      seconderyContectEmail: PartnerDataById?.seconderyContectEmail,
      seconderyMobileNumber: PartnerDataById?.seconderyMobileNumber,
      paymentDueDays: PartnerDataById?.pamentDueDays,
      id: prevData?.CreateId,
      empId: Number(employeeId),
      companySize: PartnerDataById?.companySize,
      paymentDueDaysAsPrPartnerRequest:
        PartnerDataById?.paymentDueDaysAsPrPartnerRequest,
    }));
  }, [PartnerDataById]);

  const mutation = useMutation(createPartner, {
    onSuccess: () => {
      setNextScreen("ViewCreatePartner");
    },
    onError: () => {
      notifyError("Something went wrong!");
    },
  });
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const HandlerEditPartner = () => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (
      formData?.partnerName.trim() !== "" &&
      // formData?.gstin.trim() !== "" &&
      formData?.addressLine1.trim() !== "" &&
      formData?.addressLine2.trim() !== "" &&
      formData?.country.trim() !== "" &&
      formData?.primaryContactName.trim() !== "" &&
      formData?.pinCode.trim() !== "" &&
      linkendInUrl(formData?.linkedinLink) &&
      formData?.linkedinLink.trim() &&
      formData?.msafileUrl !== "" &&
      formData?.ndafileUrl !== "" &&
      formData.workModeId !== "" &&
      formData?.companySize.trim() !== "" &&
      formData?.primaryContectEmail !== "" &&
      isValidEmail(formData?.primaryContectEmail) &&
      formData?.primaryMobileNumber !== "" &&
      formData.primaryMobileNumber.length >= 10
    ) {
      setValidate(false);
      refetch();
      mutation.mutate(formData);
    } else {
      setValidate(true);
    }
  };

  const { data: GetCityTypeData } = useQuery(["getCityList"], getCityList);
  const GetCityDataOption = GetCityTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const linkendInUrl = (value) => {
    if (!value) {
      return true;
    } else if (urlValidation(value)) {
      return true;
    } else {
      return false;
    }
  };
  const { data: GetStateTypeData } = useQuery(["getStateList"], getStateList);

  const GetStateDataOption = GetStateTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  async function handleFileUpload(file) {
    setFormData({ ...formData, ndafileUrl: file.name });
    const formDataa = new FormData();
    formDataa.append("file", file);

    try {
      const response = await axios.post(baseUrl + "/upload-file", formDataa);
      if (response.status === 200) {
        setFormData({ ...formData, ndafileUrl: response.data.responseData });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  async function handleFileUpload1(file) {
    setFormData({ ...formData, msafileUrl: file.name });
    const formDataa = new FormData();
    formDataa.append("file", file);

    try {
      const response = await axios.post(baseUrl + "/upload-file", formDataa);
      if (response.status === 200) {
        setFormData({ ...formData, msafileUrl: response.data.responseData });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  async function handleFileDeletion() {
    try {
      const response = await axios.put(
        baseUrl + `/delete-file-url?url=${formData.ndafileUrl}`
      );
      if (response.status === 200) {
        setFormData({ ...formData, ndafileUrl: "" });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  async function handleFileDeletion1() {
    try {
      const response = await axios.put(
        baseUrl + `/delete-file-url?url=${formData.msafileUrl}`
      );
      if (response.status === 200) {
        setFormData({ ...formData, msafileUrl: "" });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
    }
  };
  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          alt="cancel"
          width="10px"
          height="10px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
        />
      </div>
    );
  });

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          alt="calender"
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
        />
      </div>
    );
  });
  const { data: GetWorkModeData } = useQuery(
    ["getWorkModeList"],
    getWorkModeList
  );
  GetWorkModeData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  const GetWorkMode = GetWorkModeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "15px", marginLeft: "25px" }}>
              Edit Partner
            </h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col md={4}>
              <p className={classes.label}>
                Partner Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.partnerName}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    partnerName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.partnerName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Partner Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>GSTIN</p>
              <input
                type="text"
                value={formData?.gstin}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    gstin: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.gstin.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter GSTIN
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Company Size <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.companySize}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companySize: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.companySize.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Company Size
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Address 1 <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.addressLine1}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    addressLine1: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.addressLine1.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address 1
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Address 2 <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.addressLine2}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    addressLine2: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.addressLine2.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address 2
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Payment Due Days (As per Agreement)
              </p>

              <input
                type="text"
                className={classes.smallInput}
                value={formData?.paymentDueDays}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    paymentDueDays: Number(e.target.value),
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Payment Due Days (As per Partner Requested)
              </p>

              <input
                type="text"
                className={classes.smallInput}
                value={formData?.paymentDueDaysAsPrPartnerRequest}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    paymentDueDaysAsPrPartnerRequest: Number(e.target.value),
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                City <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetCityDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={GetCityDataOption?.find(
                  (e) => e.value === formData?.cityId
                )}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    cityId: e?.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                State <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetStateDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={GetStateDataOption?.find(
                  (e) => e.value === formData?.stateId
                )}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    stateId: e?.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Country <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.country}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    country: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.country.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Country
              </p>
            </Col>

            <Col md={4}>
              <p className={classes.label1}>
                Pin Code <span style={{ color: "red" }}>*</span>
              </p>
              <input
                value={formData?.pinCode}
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pinCode:
                      e.target.value && /^\d{1,6}$/.test(e.target.value)
                        ? e.target.value
                        : "",
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.pinCode.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Pin Code
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                LinkedIn Url <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.linkedinLink}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    linkedinLink: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                  (!formData?.linkedinLink.trim() ||
                    !linkendInUrl(formData?.linkedinLink))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter LinkedIn Url
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Upload MSA <span style={{ color: "red" }}>*</span>{" "}
              </p>
              <div className={classes.chooseFile}>
                <div>
                  {formData?.msafileUrl ? (
                    <div
                      className={classes.rmpdf}
                      style={{ paddingTop: "3px" }}
                    >
                      {
                        <>
                          {checkfileType(formData?.msafileUrl?.split(".")[1])}
                          &nbsp;
                          <span>
                            {formData?.msafileUrl &&
                            formData?.msafileUrl.length > 25
                              ? `${formData?.msafileUrl
                                  ?.split("@")[1]
                                  ?.substring(0, 25)}...`
                              : formData?.msafileUrl}
                          </span>
                        </>
                      }
                      <ClearIcon
                        onClick={handleFileDeletion1}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : (
                    <input
                      id="file-upload"
                      accept="image/*, .pdf, .doc, .docx"
                      type="file"
                      className={classes.upload_resume}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const fileSize = file.size / 1024 / 1024; // File size in MB
                          if (fileSize <= 10) {
                            handleFileUpload1(file);
                          } else {
                            ToastifyError(
                              "File size exceeds the limit of 10MB."
                            );
                            e.target.value = null; // Reset the file input value
                            return;
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <p
                className={
                  validate && !formData?.msafileUrl
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Upload MSA
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Upload NDA <span style={{ color: "red" }}>*</span>
              </p>
              <div className={classes.chooseFile}>
                <div>
                  {formData?.ndafileUrl ? (
                    <div
                      className={classes.rmpdf}
                      style={{ paddingTop: "3px" }}
                    >
                      {
                        <>
                          {checkfileType(formData?.ndafileUrl?.split(".")[1])}
                          &nbsp;
                          <span>
                            {formData?.ndafileUrl &&
                            formData?.ndafileUrl.length > 25
                              ? `${formData?.ndafileUrl
                                  ?.split("@")[1]
                                  ?.substring(0, 25)}...`
                              : formData?.ndafileUrl}
                          </span>
                        </>
                      }
                      <ClearIcon
                        onClick={handleFileDeletion}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : (
                    <input
                      id="file-upload"
                      accept="image/*, .pdf, .doc, .docx"
                      type="file"
                      className={classes.upload_resume}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const fileSize = file.size / 1024 / 1024; // File size in MB
                          if (fileSize <= 10) {
                            handleFileUpload(file);
                          } else {
                            ToastifyError(
                              "File size exceeds the limit of 10MB."
                            );
                            e.target.value = null; // Reset the file input value
                            return;
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <p
                className={
                  validate && !formData?.ndafileUrl
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Upload NDA
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Work Mode <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetWorkMode}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={GetWorkMode?.find(
                  (e) => e.value === formData?.workModeId
                )}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    workModeId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.workModeId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Work Mode
              </p>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>
                Primary Contact <span style={{ color: "red" }}>*</span>
              </h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                value={formData?.primaryContactName}
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryContactName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.primaryContactName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Email ID <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.primaryContectEmail}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryContectEmail: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                  (!formData?.primaryContectEmail ||
                    !isValidEmail(formData?.primaryContectEmail))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {validate &&
                (!formData?.primaryContectEmail ||
                  !isValidEmail(formData?.primaryContectEmail))
                  ? "Please Enter a Valid Email"
                  : "Please Enter Name"}
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Mobile Number <span style={{ color: "red" }}>*</span>
              </p>
              <input
                value={formData?.primaryMobileNumber}
                type="number"
                className={classes.smallInput}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.length > 10) {
                    inputVal = inputVal.slice(0, 10);
                  }
                  setFormData({
                    ...formData,
                    primaryMobileNumber: inputVal,
                  });
                }}
                maxLength={10}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  (e.keyCode === 38 ||
                    e.keyCode === 40 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onKeyPress={(e) =>
                  ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                    e.which > 57 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.prevent
                }
              />
              <p
                className={
                  validate &&
                  (!formData?.primaryMobileNumber ||
                    formData.primaryMobileNumber.length < 10)
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {formData?.primaryMobileNumber &&
                formData.primaryMobileNumber.length < 10
                  ? "Mobile number should have at least 10 digits"
                  : "Please Enter Mobile Number"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.SecondaryContact}>Secondary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Name</p>
              <input
                type="text"
                value={formData?.seconderyContactName}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContactName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Email ID</p>
              <input
                value={formData?.seconderyContectEmail}
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContectEmail: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Mobile Number</p>
              <input
                type="text"
                value={formData?.seconderyMobileNumber}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyMobileNumber: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>
      <button className={classes.buttonAdd} onClick={HandlerEditPartner}>
        Update
      </button>
    </>
  );
};
export default EditViewPartner;
