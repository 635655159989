
import ETSNavigation from "../Pages/EmployeeTimeSheet/navigation/ETSNavigation.js";
import EmployeeTimeSheet from "../Pages/NewEmployeeTimeSheet/navigation/ETSNavigation.js";

// function EmployeeTimeSheetRoute() {
//   return (
//     <Route>
//       <Route exact path="/ets-navigation" component={ETSNavigation} />
//       <Route exact path="/EmpolyeeTimeSheet" component={EmployeeTimeSheet} />
//     </Route>
//   );
// }
const EmployeeTimeSheetRoute = [
  { path: "/ets-navigation", component: ETSNavigation },
  { path: "/EmpolyeeTimeSheet", component: EmployeeTimeSheet }
];
export default EmployeeTimeSheetRoute;
