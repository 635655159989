import * as XLSX from "xlsx";
import React from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import { getAllExitEmployeeReport } from "../../../../../Utils/axios/umsApi";
import moment from "moment";
import { useQuery } from "react-query";
const CommonExcelFileDownload = ({ limit }) => {
  const { refetch } = useQuery(
    ["getAllUsersWithFilter1", { limit }],
    () => getAllExitEmployeeReport(limit, 1, "",""),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        handleDownloadButtonClick(res?.employeeResponseModels);
      },
    }
  );

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      ["Emp Code", "Name", "Resignation Date", "Exit Date",
      "Notes"], // Header row
      ...data.map((val) => [
        val?.code,
        val?.name,
        val?.resignationDate ? moment(val?.resignationDate).format("DD MMM YY"): "",
        val?.lastWorkingDayDate ? moment(val?.lastWorkingDayDate).format("DD MMM YY") : "",
        val?.exitComment

      ]), // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);
    downloadExcelFile(workbook);
  }
  return (
    <div>
      <FiDownload
        style={{
          verticalAlign: "baseline",
          color: "#289e98",
          fontSize: "2rem",
          cursor:"pointer"
        }}
        onClick={() => refetch()}
      />
    </div>
  );
};

export default CommonExcelFileDownload;
